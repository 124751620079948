import React from 'react';
import { IMenuItem } from '../../../../interfaces/layout/IMenu.interfaces';
import { SkeletonImageIcon } from '../SkeletonImageIcon';
import { Link } from 'react-router-dom';
import { RightOutlined, WarningOutlined } from '@ant-design/icons';
import './style.scss';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store/reducers/rootReducer';
interface SubMenuProps {
  position: string;
  data: IMenuItem;
}

const DefaultSubMenu: React.FC<SubMenuProps> = ({ data }) => {
  return (
    <React.Fragment>
      <Link to={data.path} className="txt-path-link">
        <SkeletonImageIcon
          srcName={data.icon}
          alt=""
          className="menu-icon-sub-first "
        />
        <span className="txt-menu-title">{data.title}</span>
      </Link>
    </React.Fragment>
  );
};

type SubMenuLogoutProps = SubMenuProps;

const DefaultSubMenuLogout: React.FC<SubMenuLogoutProps> = ({
  data,
  position,
}) => {
  return (
    <React.Fragment>
      <div className="txt-path-link">
        <SkeletonImageIcon
          srcName={data.icon}
          alt=""
          className="menu-icon-sub-first "
          width={40}
          key={position}
        />
        <span className="txt-menu-title">{data.title}</span>
      </div>
    </React.Fragment>
  );
};
const DefaultSubMenuWarning: React.FC<SubMenuLogoutProps> = ({
  data,
  position,
}) => {
  const loading = useSelector((state: AppState) => state.isLoading);
  const profile = useSelector((state: AppState) => state.myPage.profile.data);
  const { user_profile } = profile;

  return (
    <React.Fragment>
      <Link to={data.path} className="txt-path-link">
        <SkeletonImageIcon
          srcName={data.icon}
          alt=""
          className="menu-icon-sub-first "
          width={40}
          key={position}
        />
        <span className="txt-menu-title">{data.title}</span>
        {!loading && !user_profile?.email && (
          <Tooltip
            title="重要なご連絡はメールにてお知らせいたしますので、メールアドレスを登録してください。"
            placement="right"
            className="tooltip-warning"
          >
            <WarningOutlined />
          </Tooltip>
        )}
      </Link>
    </React.Fragment>
  );
};

const ParentMenu: React.FC<SubMenuProps> = ({ data, position }) => {
  return (
    <React.Fragment>
      <Link to={data.path} className="txt-path-link">
        <SkeletonImageIcon
          srcName={data.icon}
          width={40}
          className="menu-icon-first"
          key={position}
        />
        <span className="txt-menu-title d-none">{data.title}</span>
      </Link>
      <RightOutlined className="small-icon" />
    </React.Fragment>
  );
};

export {
  DefaultSubMenu,
  DefaultSubMenuLogout,
  ParentMenu,
  DefaultSubMenuWarning,
};
