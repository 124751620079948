import { IParamsListArchivedPage } from '../../../Clients/funnels/interface';
import { ESortType } from '../../../constants/app.constants';

export interface ParamsFilterTemplate {
  perPage: number;
  currentPage: number;
  funnelStepSubCategoryId: number;
  option: number;
  textFilterName: string;
  totalItem: number;
  totalPage: number;
}

export const paramsFilterListTemplateDefault: ParamsFilterTemplate = {
  perPage: 24,
  currentPage: 1,
  funnelStepSubCategoryId: 0,
  option: 0,
  textFilterName: '',
  totalItem: 0,
  totalPage: 0,
};

interface StepSettingParams {
  og_facebook_title: string;
  og_facebook_description: string;
  og_facebook_image: string;
  og_twitter_title: string;
  og_twitter_description: string;
  og_twitter_image: string;
}
const stepSettingParamsInitial = {
  og_facebook_title: '',
  og_facebook_description: '',
  og_facebook_image: '',
  og_twitter_title: '',
  og_twitter_description: '',
  og_twitter_image: '',
};

interface ListRestore {
  landing_file_url: string;
  name: string;
  page_template_id: number;
  funnel_step_subcategory_id: number;
  position?: number;
  restores: any[];
  uuid: string;
}

const listRestoreInitial = {
  landing_file_url: '',
  name: '',
  page_template_id: 1,
  funnel_step_subcategory_id: 1,
  position: 1,
  restores: [],
  uuid: '',
};

interface ListFunnelAccess {
  access: any[];
  domain: any;
  name: string;
  sub_domain: string;
  uuid: string;
}

const initialListFunnelAccess = {
  access: [],
  domain: null,
  name: '',
  sub_domain: '',
  uuid: '',
};

export enum FunnelDetailLoading {
  HIDE = 0,
  EDIT_STEP = 1,
  LIST_TEMPLATE = 2,
  SETTING_ADVANCE = 3,
  AFFILIATE = 4,
  PAGE_VERSIONS = 5,
}

export interface IFunnelStep {
  uuid: string;
  name: string;
  path: string;
  page: {
    uuid: string;
    name: string;
    path: string;
  };
}

export interface IFunnel {
  funnel_uuid: string;
  funnel_name: string;
  funnel_steps: IFunnelStep[];
}

export interface IGroupFunnel {
  group_name: string;
  group_uuid: string;
  funnels: Array<{ uuid: string; name: string }>;
}
export interface IArchivedPageType {
  uuid: string;
  name: string;
  path: string;
  landing_file_url: string;
  image: string;
  funnel_uuid: string;
  funnel_step_uuid: string;
  is_variation: number;
  page_template_id: number;
  page_sections: any[];
  timer_settings: any[];
  created_at: string;
  deleted_at: string;
}
export interface IListArchivedPageType {
  data: IArchivedPageType[];
  meta: {
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
  };
}

export const defaultListArchivedPage: IListArchivedPageType = {
  data: [],
  meta: {
    current_page: 1,
    last_page: 1,
    per_page: 20,
    total: 0,
  },
};

export const paramsFilterListArchivedPage: IParamsListArchivedPage = {
  page: 1,
  per_page: 20,
  sort_type: ESortType.BLANK,
  sort_by: '',
  uuid: '',
};

export interface FunnelManagement {
  paramsFilter: ParamsFilterTemplate;
  listTemplate: any;
  funnelDetail: {
    name: string;
    uuid: string;
    channels: any;
    funnel_domain: string;
    sub_domain: string;
    steps: any[];
    has_affiliate_funnel: boolean;
  };
  stepFunnelDetail: any;
  stepSettingParams: StepSettingParams;
  listRestores: ListRestore;
  loading: boolean;
  loadingComponent: FunnelDetailLoading;
  listFunnelAccess: ListFunnelAccess;
  loadingModalAccessLink: boolean;
  isOpenModalAccessLink: boolean;
  affiliateFunnel: any;
  productFunnel: any;
  productPlan: any[];
  funnelStepUuidSelected: string;
  allFunnelStepByGroupFunnel: {
    groups: IGroupFunnel[];
    funnels: any;
  };
  listArchivedPage: IListArchivedPageType;
  paramsFilterListArchivedPage: IParamsListArchivedPage;
}

export const dataFunnelDetailDefault = {
  name: '',
  uuid: '',
  funnel_domain: '',
  sub_domain: '',
  channels: [],
  steps: [],
  has_affiliate_funnel: false,
};

export const initialFunnelManagement: FunnelManagement = {
  paramsFilter: paramsFilterListTemplateDefault,
  listTemplate: [],
  funnelDetail: dataFunnelDetailDefault,
  stepFunnelDetail: null,
  listRestores: listRestoreInitial,
  stepSettingParams: stepSettingParamsInitial,
  loading: false,
  loadingComponent: FunnelDetailLoading.HIDE,
  listFunnelAccess: initialListFunnelAccess,
  loadingModalAccessLink: false,
  isOpenModalAccessLink: false,
  affiliateFunnel: {},
  productFunnel: {},
  productPlan: [],
  funnelStepUuidSelected: '',
  allFunnelStepByGroupFunnel: {
    groups: [],
    funnels: {},
  },
  listArchivedPage: defaultListArchivedPage,
  paramsFilterListArchivedPage: paramsFilterListArchivedPage,
};
