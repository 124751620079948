import { Col, Row } from 'antd';
import React from 'react';
import LinkOuterIcon from '../../../../../assets/img/menu-icons/link-outer.png';
import { getJapanDate } from '../../../../helper/commonTS.helper';
import './style.scss';

interface ItemNotificationProps {
  data: any;
}

const ItemNotification: React.FC<ItemNotificationProps> = ({ data }) => {
  return (
    <React.Fragment>
      <div className="notification__header">
        <span>{data?.category}</span> {/* TODO category */}
        <span className="date-start">
          {getJapanDate(data?.start_time) || ''}
        </span>
      </div>
      <div className="notification__content">
        <Row>
          <Col className="notification__title">
            <p
              title={data?.title}
              className={`notification-filter__search-box-text`}
            >
              {data?.title}
            </p>
          </Col>
          <Col className="notification__icon">
            <a href={`${data.content}`} target="_blank" rel="noreferrer">
              <img src={LinkOuterIcon} alt="" />
            </a>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ItemNotification;
