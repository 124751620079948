const URL_LOGIN_PAGE = `${process.env.APP_DOMAIN}/signin`;
const API_BASE_URL = process.env.APP_ENDPOINT;

const TIMEOUT_REQUEST_SERVER = 60 * 1000;
const TIMEOUT_REQUEST_MESSAGE = 'TIMEOUT_LIMITED';

export {
  URL_LOGIN_PAGE,
  API_BASE_URL,
  TIMEOUT_REQUEST_SERVER,
  TIMEOUT_REQUEST_MESSAGE,
};
