import { useHistory } from 'react-router-dom';

import dotIcon from '../../../../../../assets/img/menu-icons/dot-icon.png';
import { ItemContentSearch } from '../../config';

interface SearchContentProps {
  type: string;
  data: ItemContentSearch[];
}

const SearchContent: React.FC<SearchContentProps> = ({ type, data }) => {
  const history = useHistory();

  return (
    <div className="search-funnel-container">
      <div className="search-funnel-container-title">
        <span>{type}</span>
      </div>
      {data?.map((item: ItemContentSearch, index: number) => (
        <div
          onClick={() => {
            history.push(item.url);
          }}
          className="search-funnel-item"
          key={index}
        >
          <div className="search-funnel-icon">
            <img src={dotIcon} alt="Icon funnel" />
          </div>
          <div className="search-funnel-content">
            <div className="search-funnel-content-name w-100 over-text-dot dot-1">
              <p>{item.content}</p>
            </div>
          </div>
          {item.subContent && (
            <div className="search-funnel-content-sub-content over-text-dot dot-1">
              <p>{item.subContent}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SearchContent;
