import React from 'react';
import './TInputField.scss';
import { Field, getIn } from 'formik';

export interface TInputFieldProps {
  name: string;
  type: 'text' | 'password' | 'email' | 'number';
  className?: string;
  placeholder?: string;
  maxLength?: number;
  disable?: boolean;
}
function getStyles(errors: any, fieldName: any) {
  if (getIn(errors, fieldName)) {
    return true;
  } else {
    return false;
  }
}
const InputRaw = (props: any) => {
  const { field, disable, className, ...rest } = props;
  const getError = getStyles(rest.form.errors, field.name);
  return (
    <input
      disabled={disable}
      className={`form-control input__customize ${className} ${
        getError && rest.form.touched[field.name] ? 'error' : ''
      }`}
      {...field}
      {...rest}
    />
  );
};

const TInputField: React.FC<TInputFieldProps & Record<string, any>> = ({
  name,
  type,
  placeholder,
  maxLength,
  disable = false,
  className = '',
  ...rest
}) => {
  return (
    <Field
      type={type}
      name={name}
      placeholder={placeholder}
      component={InputRaw}
      maxLength={maxLength}
      disabled={disable}
      className={className}
      {...rest}
    />
  );
};

export default TInputField;
