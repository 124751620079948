import { getPath } from '../helper';

export const endPoints = {
  getSynthesisReports: () => getPath(`funnel/top`),
  getRangeDateTimeReports: (id: string) =>
    getPath(`funnel/first-time?funnels_uuids=${id}`),
  uploadImageBase64: () => getPath(`upload/image/base64`),
  getFunnelFilterActions: () => getPath(`funnels/by-sys-user`),
  GetReportFunnelByFilters: () => getPath(`funnel/stats`),
  searchAllSystem: (textSearch: string) => getPath(`search?name=${textSearch}`),
  uploadImageOPG: () => getPath('upload/image'),
};
