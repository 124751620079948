import React from 'react';
import classnames from 'classnames';
import './TMarkLoading.scss';

export interface TMarkLoadingProps {
  loading: boolean;
}

const TMarkLoading: React.FC<TMarkLoadingProps> = ({ loading = false }) => {
  return (
    <div id="mark-loading-component">
      <div
        className={classnames('', { '': !loading, 'wrapper-loading': loading })}
      >
        {loading ? (
          <div className="loadingio-spinner-double-ring-8wv2xowyob7 drop-shadow">
            <div className="ldio-7imfh2rvpyx">
              <div></div>
              <div></div>
              <div>
                <div></div>
              </div>
              <div>
                <div></div>
              </div>
            </div>
            <div className="funecy-icons-mask-loading">F</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TMarkLoading;
