export enum AlginTypes {
  CENTER = 'center',
  RIGHT = 'right',
  LEFT = 'left',
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
  BLANK = '',
}

export enum SortField {
  COURSE_NAME = 'course_name',
  DATETIME = 'datetime',
  GROUP_NAME = 'group_name',
  BLANK = '',
}

type TableColumnBase = {
  id?: string | number;
  pageId?: string;
  width?: string;
  align?: AlginTypes;
  name?: string | number | React.ReactNode;
};

export type TableProps = {
  columns: TableColumn[];
  data: boolean;
  keyField?: string; // key field col table t-head assign t-body table;
  pageField?: string;
  noDataString?: string; // content no data when listing no data
  selectedColumn?: 'course_name' | 'datetime' | 'group_name' | '';
  onSortFunction?: (
    sortField: SortField,
    sortDirection: SortOrder,
    sortPage: string
  ) => void;
  children: React.ReactNode;
};

export interface TableColumn extends TableColumnBase {
  cell?: 'col' | 'row';
  span?: number;
  onSortFunction?: (
    sortField: SortField | any,
    sortDirection: SortOrder,
    sortPage: string
  ) => void;
  sortField?: SortField; // sort with column cell
  sortDirection?: SortOrder;
  sortable: boolean; // enable sort options
  sortPage?: string; // sort with group id
  selectedColumn?: 'course_name' | 'datetime' | 'group_name' | '';
}

declare const Placements: [
  'topLeft',
  'topCenter',
  'topRight',
  'bottomLeft',
  'bottomCenter',
  'bottomRight'
];
declare type Placement = typeof Placements[number];

interface DropdownOverlayProperties {
  trigger?: ('click' | 'hover' | 'contextMenu')[];
  arrow?: boolean;
  placement?: Placement;
}
export const PropertyOverlay: DropdownOverlayProperties = {
  trigger: ['click'],
  placement: 'topRight',
  arrow: true,
};
