import * as Types from './types';
import {
  IPayloadConnectStripe,
  IUnivapayAccount,
} from '../../../Clients/mypage/interfaces';

interface ActionsCallBack {
  success: (data: any) => void;
  error: (data: any) => void;
}

export const getCompanyById = (data: string, actions: ActionsCallBack) => ({
  type: Types.GET_COMPANY_BY_ID,
  data,
  onSuccess: actions.success,
  onError: actions.error,
});

export const getAllCompany = (actions: ActionsCallBack) => ({
  type: Types.GET_ALL_COMPANY,
  onSuccess: actions.success,
  onError: actions.error,
});

export const updateCompanySetting = (data: any, actions: ActionsCallBack) => ({
  type: Types.UPDATE_COMPANY_SETTING,
  data,
  onSuccess: actions.success,
  onError: actions.error,
});

export const swapCompany = (data: string, actions: ActionsCallBack) => ({
  type: Types.SWAP_COMPANY,
  data,
  onSuccess: actions.success,
  onError: actions.error,
});

export const getPaymentSetting = (onSuccess: (data?: any) => void) => ({
  type: Types.GET_PAYMENT_SETTING,
  onSuccess,
});

export const getDetailPaymentSetting = (
  payload: string,
  onSuccess: (data?: any) => void,
  onError: (data?: any) => void
) => ({
  type: Types.GET_DETAIL_PAYMENT_SETTING,
  payload,
  onSuccess,
  onError,
});

export const deletePaymentSetting = (
  payload: any,
  onSuccess: (data?: any) => void
) => ({
  type: Types.DELETE_PAYMENT_SETTING,
  payload,
  onSuccess,
});

export const connectStripeAccount = (
  payload: IPayloadConnectStripe,
  onSuccess: (data?: any) => void
) => ({
  type: Types.CONNECT_STRIPE_ACCOUNT,
  payload,
  onSuccess,
});

export const updateMethodStripe = (
  payload: IPayloadConnectStripe,
  onSuccess: () => void
) => ({
  type: Types.UPDATE_METHOD_STRIPE,
  payload,
  onSuccess,
});

export const createGMOAccount = (payload: any, onSuccess: () => void) => ({
  type: Types.CREATE_GMO_ACCOUNT,
  payload,
  onSuccess,
});

export const updateMethodGMO = (payload: any, onSuccess: () => void) => ({
  type: Types.UPDATE_METHOD_GMO,
  payload,
  onSuccess,
});

export const createPaypalAccount = (payload: any, onSuccess: () => void) => ({
  type: Types.CREATE_PAYPAL_ACCOUNT,
  payload,
  onSuccess,
});

export const updateMethodPaypal = (payload: any, onSuccess: () => void) => ({
  type: Types.UPDATE_METHOD_PAYPAL,
  payload,
  onSuccess,
});

export const createUnivapayAccount = (
  payload: IUnivapayAccount,
  onSuccess: (data?: any) => void
) => ({
  type: Types.CREATE_UNIVAPAY_ACCOUNT,
  payload,
  onSuccess,
});

export const updateMethodUnivapay = (
  payload: IUnivapayAccount,
  onSuccess: (data?: any) => void
) => ({
  type: Types.UPDATE_METHOD_UNIVAPAY,
  payload,
  onSuccess,
});
