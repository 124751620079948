import { call, put, takeLatest } from 'redux-saga/effects';
import * as Types from '../actionTypes/upload';
import * as ActionTypes from '../actionTypes/loadingActionTypes';
import {
  callUploadImageBase64,
  callUploadImageOPG,
} from '../../Clients/reports';
import { EStatusHttp } from '../../Clients/interfaces';
import { handleMessage } from '../../utilities/common.utilities';

interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
}

function* uploadImageBase64(action: any) {
  const { image, onSuccess, onError } = action;
  try {
    const formData = new FormData();
    formData.append('base64', image);
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(callUploadImageBase64, {
      base64: image,
    });
    yield put({ type: ActionTypes.HIDE_LOADING });

    if (response.status === EStatusHttp.HTTP_OK) {
      onSuccess(response.data);
      return;
    }
    onError();
  } catch (e) {
    yield put({ type: ActionTypes.HIDE_LOADING });
    onError();
  }
}

function* uploadImageOPG(action: {
  type: string;
  image: any;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  const { image, onSuccess } = action;
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(callUploadImageOPG, image);
    if (response.status === EStatusHttp.HTTP_OK) {
      onSuccess(response);
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

export default function* uploadSaga() {
  yield takeLatest(Types.UPLOAD_IMAGE_BASE64, uploadImageBase64);
  yield takeLatest(Types.UPLOAD_IMAGE_OPG, uploadImageOPG);
}
