import { call, put, takeLatest } from 'redux-saga/effects';

import * as Types from '../actions/timer/types';
import * as timerService from '../../Clients/timer';
import * as TypesLoading from './../actionTypes/loadingActionTypes';
import { handleMessage } from '../../utilities/common.utilities';
import { EStatusHttp } from '../../Clients/interfaces';
import {
  IParamsCreateTimer,
  IParamsDeleteGroupTimer,
  IParamsDuplicateGroupTimer,
  IParamsGetListTimer,
  IParamsPublishTimer,
  IParamsRenameGroupTimer,
  IParamsRenameTimer,
  IParamsResetTracking,
  IParamsUpdateWhitelistUser,
} from '../../Clients/timer/interface';

interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
  error?: any;
}

function* getListGroupTimer() {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      timerService.getListGroupTimer
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_LIST_GROUP_TIMER_SUCCESS,
        data: response.data?.data,
      });
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getTimerDetail(action: {
  type: string;
  uuid: string;
  onSuccess: (data?: any) => void;
  onError: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      timerService.getTimerDetail,
      action.uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data.data);
    }
  } catch (error: any) {
    action.onError();
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* createTimer(action: {
  type: string;
  data: IParamsCreateTimer;
  onSuccess: (data?: any) => void;
  onError: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      timerService.createTimer,
      action.data
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response);
    }
  } catch (error: any) {
    handleMessage(error?.response);
    action.onError();
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* updateTimer(action: {
  type: string;
  data: IParamsCreateTimer;
  onSuccess: (data?: any) => void;
  onError: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      timerService.updateTimer,
      action.data
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response);
    }
  } catch (error: any) {
    handleMessage(error?.response);
    action.onError();
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getWhitelistUser() {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(timerService.getWhitelistUser);
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_WHITELIST_USER_SUCCESS,
        data: response.data?.data,
      });
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* updateWhitelistUser(action: {
  type: string;
  params: IParamsUpdateWhitelistUser;
  onSuccess: (data?: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      timerService.updateWhitelistUser,
      action.params
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getCurrentMyIP(action: {
  type: string;
  onSuccess: (data?: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(timerService.getCurrentMyIP);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data.data);
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getListTimer(action: {
  type: string;
  params: IParamsGetListTimer;
  onSuccess: (data?: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      timerService.getListTimer,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_LIST_TIMER_SUCCESS,
        data: response.data.data,
      });
      action.onSuccess();
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* publishTimer(action: {
  type: string;
  timer_uuid: string;
  params: IParamsPublishTimer;
  onSuccess: (data?: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      timerService.publishTimer,
      action.timer_uuid,
      action.params
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response);
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* duplicateTimer(action: {
  type: string;
  timer_uuid: string;
  onSuccess: (data?: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      timerService.duplicateTimer,
      action.timer_uuid
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response);
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* deleteTimer(action: {
  type: string;
  timer_uuid: string;
  onSuccess: (data?: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      timerService.deleteTimer,
      action.timer_uuid
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response);
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* renameTimer(action: {
  type: string;
  timer_uuid: string;
  params: IParamsRenameTimer;
  onSuccess: (data?: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      timerService.renameTimer,
      action.timer_uuid,
      action.params
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response);
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* deleteGroupTimer(action: {
  type: string;
  params: IParamsDeleteGroupTimer;
  onSuccess: (data?: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      timerService.deleteGroupTimer,
      action.params
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response);
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* duplicateGroupTimer(action: {
  type: string;
  params: IParamsDuplicateGroupTimer;
  onSuccess: (data?: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      timerService.duplicateGroupTimer,
      action.params
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response);
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* renameGroupTimer(action: {
  type: string;
  params: IParamsRenameGroupTimer;
  onSuccess: (data?: any) => void;
  onError: (data?: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      timerService.renameGroupTimer,
      action.params
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response);
    }
  } catch (error: any) {
    handleMessage(error?.response);
    action.onError(error);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* resetTracking(action: {
  type: string;
  params: IParamsResetTracking;
  onSuccess: (data?: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      timerService.resetTracking,
      action.params
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response);
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

export default function* timerSaga() {
  yield takeLatest(Types.GET_LIST_GROUP_TIMER, getListGroupTimer);
  yield takeLatest(Types.GET_TIMER_DETAIL, getTimerDetail);
  yield takeLatest(Types.CREATE_TIMER, createTimer);
  yield takeLatest(Types.UPDATE_TIMER, updateTimer);
  yield takeLatest(Types.GET_WHITELIST_USER, getWhitelistUser);
  yield takeLatest(Types.UPDATE_WHITELIST_USER, updateWhitelistUser);
  yield takeLatest(Types.GET_CURRENT_MY_IP, getCurrentMyIP);
  yield takeLatest(Types.GET_LIST_TIMER, getListTimer);
  yield takeLatest(Types.DUPLICATE_TIMER, duplicateTimer);
  yield takeLatest(Types.PUBLISH_TIMER, publishTimer);
  yield takeLatest(Types.DELETE_TIMER, deleteTimer);
  yield takeLatest(Types.RENAME_TIMER, renameTimer);

  yield takeLatest(Types.DELETE_GROUP_TIMER, deleteGroupTimer);
  yield takeLatest(Types.DUPLICATE_GROUP_TIMER, duplicateGroupTimer);
  yield takeLatest(Types.RENAME_GROUP_TIMER, renameGroupTimer);

  yield takeLatest(Types.RESET_TRACKING, resetTracking);
}
