// Project: 7612770651107e1659371833c56a9b25e733c4615968bb7c5582cdc92aa6249b
export const RENAME_FUNNEL_GROUP = 'RENAME_FUNNEL_GROUP';
export const RENAME_FUNNEL_LIST_ITEM = 'RENAME_FUNNEL_LIST_ITEM';
export const FETCH_LIST_FUNNEL = 'FETCH_LIST_FUNNEL';

export const FETCH_SUMMARY_FUNNEL = 'FETCH_SUMMARY_FUNNEL';
export const FETCH_SUMMARY_FUNNEL_SUCCESS = 'FETCH_SUMMARY_FUNNEL_SUCCESS';
export const FETCH_SUMMARY_FUNNEL_FAILS = 'FETCH_SUMMARY_FUNNEL_FAILS';

export const FETCH_THE_FUNNEL_LIST = 'FETCH_THE_FUNNEL_LIST';
export const FETCH_THE_FUNNEL_LIST_SUCCESS = 'FETCH_THE_FUNNEL_LIST_SUCCESS';
export const FETCH_THE_FUNNEL_LIST_FAILED = 'FETCH_THE_FUNNEL_LIST_FAILED';

export const FETCH_THE_GROUP_FUNNEL_LIST = 'FETCH_THE_GROUP_FUNNEL_LIST';
export const FETCH_THE_GROUP_FUNNEL_LIST_SUCCESS =
  'FETCH_THE_GROUP_FUNNEL_LIST_SUCCESS';
export const FETCH_THE_GROUP_FUNNEL_LIST_FAILED =
  'FETCH_THE_GROUP_FUNNEL_LIST_FAILED';

export const FETCH_SELECT_GROUP_FUNNEL = 'FETCH_SELECT_GROUP_FUNNEL';
export const FETCH_ALL_TEMPLATES = 'FETCH_ALL_TEMPLATES';
export const FETCH_ALL_TEMPLATES_SUCCESS = 'FETCH_ALL_TEMPLATES_SUCCESS';
export const FETCH_ALL_TEMPLATES_FAILED = 'FETCH_ALL_TEMPLATES_FAILED';
export const FETCH_SELECT_GROUP_MEMBERSITE = 'FETCH_SELECT_GROUP_MEMBERSITE';
export const FETCH_SELECT_GROUP_FUNNEL_SUCCESS =
  'FETCH_SELECT_GROUP_FUNNEL_SUCCESS';
export const FETCH_SELECT_GROUP_FUNNEL_FAILED =
  'FETCH_SELECT_GROUP_FUNNEL_FAILED';

export const FETCH_PAGE_LIST_FUNNEL = 'FETCH_PAGE_LIST_FUNNEL';
export const FETCH_PAGE_LIST_FUNNEL_SUCCESS = 'FETCH_PAGE_LIST_FUNNEL_SUCCESS';
export const FETCH_PAGE_LIST_FUNNEL_FAILED = 'FETCH_PAGE_LIST_FUNNEL_FAILED';

export const FETCH_FUNNEL_STEP_PAGE_VERSIONS_REQUESTED =
  'FETCH_FUNNEL_STEP_PAGE_VERSIONS_REQUESTED';
export const FETCH_FUNNEL_STEP_PAGE_VERSIONS_SUCCESS =
  'FETCH_FUNNEL_STEP_PAGE_VERSIONS_SUCCESS';
export const FETCH_FUNNEL_STEP_PAGE_VERSIONS_FAILED =
  'FETCH_FUNNEL_STEP_PAGE_VERSIONS_FAILED';

export const APPLY_FUNNEL_STEP_PAGE_VERSIONS_REQUESTED =
  'APPLY_FUNNEL_STEP_PAGE_VERSIONS_REQUESTED';
export const APPLY_FUNNEL_STEP_PAGE_VERSIONS_SUCCESS =
  'APPLY_FUNNEL_STEP_PAGE_VERSIONS_SUCCESS';
export const APPLY_FUNNEL_STEP_PAGE_VERSIONS_FAILED =
  'APPLY_FUNNEL_STEP_PAGE_VERSIONS_FAILED';
export const CLEAR_PAGE_VERSIONS = 'CLEAR_PAGE_VERSIONS';
export const FETCH_FUNNEL_STEP_METADATA_REQUESTED =
  'FETCH_FUNNEL_STEP_METADATA_REQUESTED';
export const FETCH_FUNNEL_STEP_METADATA_SUCCESS =
  'FETCH_FUNNEL_STEP_METADATA_SUCCESS';
export const FETCH_FUNNEL_STEP_METADATA_FAILED =
  'FETCH_FUNNEL_STEP_METADATA_FAILED';

export const UPDATE_FUNNEL_STEP_METADATA_REQUESTED =
  'UPDATE_FUNNEL_STEP_METADATA_REQUESTED';
export const UPDATE_FUNNEL_STEP_METADATA_SUCCESS =
  'UPDATE_FUNNEL_STEP_METADATA_SUCCESS';
export const UPDATE_FUNNEL_STEP_METADATA_FAILED =
  'UPDATE_FUNNEL_STEP_METADATA_FAILED';

export const FETCH_MEMBERSITE_METADATA_REQUESTED =
  'FETCH_MEMBERSITE_METADATA_REQUESTED';
export const FETCH_MEMBERSITE_METADATA_SUCCESS =
  'FETCH_MEMBERSITE_METADATA_SUCCESS';
export const FETCH_MEMBERSITE_METADATA_FAILED =
  'FETCH_MEMBERSITE_METADATA_FAILED';

export const UPDATE_MEMBERSITE_METADATA_REQUESTED =
  'UPDATE_MEMBERSITE_METADATA_REQUESTED';
export const UPDATE_MEMBERSITE_METADATA_SUCCESS =
  'UPDATE_MEMBERSITE_METADATA_SUCCESS';
export const UPDATE_MEMBERSITE_METADATA_FAILED =
  'UPDATE_MEMBERSITE_METADATA_FAILED';
