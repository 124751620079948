import { toast } from 'react-toastify';
import {
  EStatusCodeData,
  EStatusHttp,
  IHttpResponseError,
} from '../interfaces';
import { IOptions } from './interface';
import { store } from '../../store/configureStore';
import { hideLoading } from '../../store/actions/loadingAction';
import { handleMessage } from '../../utilities/common.utilities';

interface IBaseResponse {
  message?: string;
  status: EStatusCodeData;
  error?: any;
  data: any;
}
interface IResponse {
  status: EStatusHttp;
  data?: IBaseResponse;
}

export function completedResponse(response: IResponse, options?: IOptions) {
  if (options?.isStopAllLoading) {
    store.dispatch(hideLoading());
  }

  if (options?.isAlertMessage) {
    handleMessage(response);
  }

  return {
    data: response.data?.data || response.data,
    status: response.status,
    message: response.data?.message,
  };
}

export function errorResponse(
  response: IHttpResponseError,
  options?: IOptions
): IHttpResponseError {
  if (options?.isStopAllLoading) {
    store.dispatch(hideLoading());
  }
  if (!response) {
    return {
      status: 2,
    };
  }

  if (options?.isAlertMessage) {
    handleMessage(response);
  }

  return {
    status: response.status,
    data: response.data,
  };
}
