import { Col, Row, Space } from 'antd';
import React, { useMemo } from 'react';

import { TableOptions } from '../../../../Funnel/List/@DataTables/Utils/index.utils';
import { IPageTemplates } from '../../../../../Clients/templates/interface';
import ContentPageSize from '../../../../../components/organisms/CommonList/PageSize/ContentPageSize';
import {
  OPTIONS_FILTER_TEMPLATE_TYPE,
  TemplateTypeId,
} from '../../../../../constants/app.constants';
import { pageSizesCard } from '../../../../../config/common.config';
import SelectedOption from './SingleSelect';

interface OptionFiltersProps {
  onChangePageSize: (pageSize: number) => void;
  onChangeTemplateTypes: (pageType: TemplateTypeId) => void;
  option: TableOptions;
  data: IPageTemplates;
}

const FilterHeaderTemplates: React.FC<OptionFiltersProps> = ({
  onChangePageSize,
  option,
  onChangeTemplateTypes,
  data,
}) => {
  const templateTypeOptions = useMemo(() => {
    const options = OPTIONS_FILTER_TEMPLATE_TYPE.filter(
      (value) => value.value !== TemplateTypeId.systemTemplate
    ).map((item) => ({ title: item.title, value: item.value }));
    return options;
  }, []);

  return (
    <Row justify="space-between" className="mt-3">
      <Col>
        <Space>
          <SelectedOption
            value={data.page_template_type || TemplateTypeId.allTemplate}
            width={250}
            data={templateTypeOptions}
            onChange={onChangeTemplateTypes}
          />
        </Space>
      </Col>
      <ContentPageSize
        className="ms-auto"
        data={option}
        handleChangePageSize={onChangePageSize}
        total={option.totalPage}
        pageSizesCustom={pageSizesCard}
      />
    </Row>
  );
};
export default FilterHeaderTemplates;
