import {
  AFFILIATE,
  AUTHEN_PAGE,
  CALENDAR,
  FUNNEL,
  LINK_SETTING_ACCOUNT,
  LINK_USER,
  MAIL,
  MAIL_PATH,
  MEMBERSITE,
  MY_PAGE,
  PRODUCT,
  REMIND_MAIL,
  REPORT_PATH,
} from './../../../constants/menus';
export const getScreenName = (path: string) => {
  return SCREENS.find((item: any) => item.path === path)?.name || '';
};

export const SCREENS = [
  {
    path: '/',
    name: 'a001',
  },
  {
    path: AUTHEN_PAGE.SIGN_IN,
    name: 'a001',
  },
  {
    path: AUTHEN_PAGE.FORGOT_PASSWORD,
    name: 'a002',
  },
  {
    path: AUTHEN_PAGE.CREATE_NEW_PASSWORD,
    name: '',
  },
  {
    path: AUTHEN_PAGE.REGISTER,
    name: 'a003',
  },
  {
    path: AUTHEN_PAGE.VERIFY_ACCOUNT_SUCCESS,
    name: '',
  },
  {
    path: AUTHEN_PAGE.DASHDOARD,
    name: 'b002',
  },
  {
    path: REPORT_PATH.SYNTHESIS,
    name: 'i001',
  },
  {
    path: FUNNEL.GROUP,
    name: 'c001',
  },
  {
    path: FUNNEL.LIST,
    name: 'c001',
  },
  {
    path: FUNNEL.RECYCLE,
    name: 'c002',
  },
  {
    path: FUNNEL.CREATE,
    name: 'c003',
  },
  {
    path: FUNNEL.FUNNEL_DETAIL,
    name: 'c004',
  },
  {
    path: FUNNEL.SETTING,
    name: 'c006',
  },
  {
    path: FUNNEL.SHARED_FUNNEL,
    name: '',
  },
  {
    path: FUNNEL.FUNNEL_DETAIL,
    name: 'c008',
  },
  {
    path: FUNNEL.SETTING_AFFILIATE,
    name: 'c009',
  },
  {
    path: FUNNEL.LIST_TEMPLATE,
    name: 'c010',
  },
  {
    path: FUNNEL.LIST_BLOCK_TEMPLATE,
    name: 'c011',
  },
  {
    path: FUNNEL.BLOCK_TEMPLATE,
    name: 'c011_1',
  },
  {
    path: FUNNEL.LIST_TIMER,
    name: 'r001',
  },
  {
    path: FUNNEL.CREATE_TIMER,
    name: 'r002',
  },
  {
    path: FUNNEL.EDIT_TIMER,
    name: 'r003',
  },
  {
    path: PRODUCT.GROUP,
    name: 'f001',
  },
  {
    path: PRODUCT.REGISTER,
    name: 'f002',
  },
  {
    path: MAIL_PATH.CREATE_STEP_MAIL,
    name: 'g003',
  },
  {
    path: MAIL_PATH.MAIL_LIST,
    name: 'g009',
  },
  {
    path: MAIL_PATH.LIST_STEP_MAIL,
    name: 'g001',
  },
  {
    path: MAIL_PATH.DETAIL_STEP_MAIL,
    name: 'g004',
  },
  {
    path: MAIL_PATH.STEP_MAIL_VIEW,
    name: 'g002',
  },
  {
    path: MY_PAGE.MANAGE_PAYMENT_GATEWAY,
    name: 'j008',
  },
  {
    path: MAIL_PATH.MAIL_LIST_BY_NAME,
    name: 'g010',
  },
  {
    path: MAIL_PATH.MAIL_LIST_CREATE_BY_NAME,
    name: 'g013',
  },
  {
    path: MAIL_PATH.MAIL_LIST_CREATE,
    name: 'g011',
  },
  {
    path: '/mail-lists/import-csv',
    name: 'g012',
  },
  {
    path: MY_PAGE.MANAGE_PAYMENT_SETTING,
    name: 'j007',
  },
  {
    path: LINK_SETTING_ACCOUNT.PAYMENT_INFORMATION,
    name: 'j007',
  },
  {
    path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_CREATE_TYPE,
    name: '',
  },
  {
    path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_GMO,
    name: '',
  },
  {
    path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_PAYPAL,
    name: '',
  },
  {
    path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_UNIVAPAY,
    name: '',
  },
  {
    path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_STRIPE,
    name: '',
  },
  {
    path: LINK_SETTING_ACCOUNT.EMAIL_INTEGRATIONS_SELECT,
    name: '',
  },
  {
    path: LINK_SETTING_ACCOUNT.EMAIL_INTEGRATIONS_LIST,
    name: 'j009',
  },
  {
    path: LINK_SETTING_ACCOUNT.EMAIL_INTEGRATIONS_TRANSITION_AND_MARKETING,
    name: '',
  },
  {
    path: LINK_SETTING_ACCOUNT.EMAIL_INTEGRATIONS_TRANSITION_ONLY,
    name: '',
  },
  {
    path: LINK_SETTING_ACCOUNT.LIST_DOMAIN,
    name: 'j010',
  },
  {
    path: LINK_SETTING_ACCOUNT.CREATE_DOMAIN,
    name: 'j011',
  },
  {
    path: LINK_SETTING_ACCOUNT.DETAIL_DOMAIN,
    name: '',
  },
  {
    path: LINK_SETTING_ACCOUNT.EDIT_EMAIL_INTEGRATIONS_TRANSITION_AND_MARKETING,
    name: '',
  },
  {
    path: MAIL_PATH.DETAIL,
    name: '',
  },
  {
    path: MAIL_PATH.SENT_MAIL_LIST,
    name: 'g005',
  },
  {
    path: MAIL_PATH.SENT_MAIL_DETAIL,
    name: '',
  },
  {
    path: MAIL_PATH.BULK_MAIL_CREATE,
    name: 'g006',
  },
  {
    path: MAIL_PATH.BULK_MAIL_COPY,
    name: '',
  },
  {
    path: MAIL_PATH.BULK_MAIL_COPY,
    name: '',
  },
  {
    path: MAIL.ALTERNATIVE_CHARACTER_CHANGE_REGISTERED_EMAIL,
    name: '',
  },
  {
    path: MAIL.ALTERNATIVE_CANCEL_SCHEDULE,
    name: '',
  },
  {
    path: MAIL.ALTERNATIVE_SCHEDULE_CHANGE,
    name: '',
  },
  {
    path: MAIL.ALTERNATIVE_CHARACTER_CANCEL_RECURRING_PAYMENT,
    name: '',
  },
  {
    path: MAIL.ALTERNATIVE_CHARACTER_CHANGE_CREDIT_CARD,
    name: '',
  },
  {
    path: MAIL.ALTERNATIVE_CHARACTER_PAYMENT_ORDER,
    name: '',
  },
  {
    path: LINK_USER.LIST_USER,
    name: '',
  },
  {
    path: LINK_USER.MAIL_LIST,
    name: 'h004',
  },
  {
    path: LINK_USER.USER_DETAIL,
    name: 'h002',
  },
  {
    path: LINK_USER.EDIT_USER,
    name: 'h005',
  },
  {
    path: LINK_USER.STATISTICS,
    name: 'h003',
  },
  {
    path: LINK_USER.WHITELIST_USER,
    name: 'h010',
  },
  {
    path: LINK_USER.BLACKLIST_USER,
    name: 'h006',
  },
  {
    path: LINK_USER.USER_MEMBERSHIP,
    name: 'h011',
  },
  {
    path: MY_PAGE.PROFILE,
    name: 'j001',
  },
  {
    path: MY_PAGE.PROFILE_EDIT_INFO,
    name: 'j002',
  },
  {
    path: MY_PAGE.PROFILE_EDIT_PASSWORD,
    name: 'j003',
  },
  {
    path: MY_PAGE.PROFILE_EMAIL_CHANGE,
    name: '',
  },
  {
    path: MY_PAGE.ACCOUNT_SETTING_SUB_USER_INVITATION,
    name: '',
  },
  {
    path: MY_PAGE.ACCOUNT_SETTING_SUB_USER,
    name: 'j006',
  },
  {
    path: MAIL_PATH.BULK_MAIL_DETAIL,
    name: '',
  },
  {
    path: PRODUCT.UPDATE,
    name: 'f002',
  },
  {
    path: REPORT_PATH.DETAIL,
    name: 'i003',
  },
  {
    path: MAIL_PATH.STEP_MAIL_UPDATE,
    name: 'g003',
  },
  {
    path: MAIL_PATH.MAIL_FIXED_PHRASE,
    name: 'g016',
  },
  {
    path: MY_PAGE.MY_ACCOUNT_SETTING_DETAIL,
    name: 'j005',
  },
  {
    path: LINK_SETTING_ACCOUNT.DETAIL_DOMAIN,
    name: '',
  },
  {
    path: MAIL_PATH.MAIL_LIST_USER,
    name: 'g017',
  },
  {
    path: MAIL_PATH.MAIL_FIXED_PHRASE_LIST,
    name: 'g016',
  },
  {
    path: MAIL_PATH.MAIL_LIST_IMPORT_CSV,
    name: 'g012',
  },
  {
    path: AFFILIATE.SETTING,
    name: 'n001',
  },
  {
    path: AFFILIATE.PARTNER,
    name: 'n002',
  },
  {
    path: AFFILIATE.PARTNER_DETAIL,
    name: 'n002-1',
  },
  {
    path: AFFILIATE.SUBSCRIBER,
    name: 'n003',
  },
  {
    path: AFFILIATE.BONUS,
    name: 'n004',
  },

  //Tag
  {
    path: LINK_USER.CREATE_TAG,
    name: 'h007',
  },
  {
    path: LINK_USER.EDIT_TAG,
    name: 'h007',
  },
  {
    path: LINK_USER.LIST_TAG,
    name: 'h008',
  },
  {
    path: LINK_USER.LIST_TAG_USER,
    name: 'h009',
  },
  //Remail mail
  {
    path: REMIND_MAIL.CREATE_REMIND_MAIL,
    name: 'g018',
  },
  {
    path: REMIND_MAIL.REMIND_MAIL_UPDATE,
    name: 'g018',
  },
  {
    path: REMIND_MAIL.LIST_REMIND_MAIL,
    name: 'g019',
  },
  {
    path: REMIND_MAIL.DETAIL_REMIND_MAIL,
    name: 'g020',
  },
  {
    path: REMIND_MAIL.EDIT_MAIL,
    name: 'g021',
  },
  {
    path: CALENDAR.CREATE,
    name: 'q001',
  },
  {
    path: CALENDAR.LIST,
    name: 'q002',
  },
  {
    path: CALENDAR.SETTING_SCHEDULE,
    name: 'q003',
  },
  {
    path: CALENDAR.SETTING_BASIC,
    name: 'q004',
  },
  //Membersite
  {
    path: MEMBERSITE.GROUP_COURSE,
    name: 'u005',
  },
  {
    path: MEMBERSITE.LIST_COURSE,
    name: 'u005',
  },
  {
    path: MEMBERSITE.RECYCLE_COURSE,
    name: 'u005',
  },
  {
    path: MEMBERSITE.SETTING,
    name: 'u006',
  },
  {
    path: MEMBERSITE.SETTING_COURSE,
    name: 'u007',
  },
  {
    path: MEMBERSITE.SELECT,
    name: 'u008',
  },
  {
    path: MEMBERSITE.LIST_USER,
    name: 'u009',
  },
  {
    path: MEMBERSITE.LIST_MEMBERSITE_TEMPLATE,
    name: 'u010',
  },
];
