import { Button, Card, Col, Row, Tag } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import WrappedFieldName from './FieldName';
import { fetchUrlTemplate } from '../../../../../utilities/handleOpenEditPage';
import { TemplateTypeId } from '../../../../../constants/app.constants';
import { IDataTemplate } from '../../../../../store/reducers/membersiteManagement/interface';
import { IChangeNameTemplates } from '../../../../../Clients/templates/interface';

interface ElementCardProps {
  data: IDataTemplate;
  updateNameTemplate: (value: IChangeNameTemplates) => void;
}

const ElementCardTemplate: React.FC<ElementCardProps> = ({
  updateNameTemplate,
  data,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const [eventOutside, setEventOutSide] = useState<boolean>(false);

  const handlePreview = () => {
    const linkPreview =
      window.location.origin.replace('app.', '') +
      `/page-template/preview?uuid=${data.uuid}`;
    window.open(linkPreview, '_blank');
  };

  const handleUpdateTemplate = () => {
    history.push(`/course-templates/${data.uuid}`);
  };

  const renderTemplateType = () => {
    switch (data.type) {
      case TemplateTypeId.myTemplate:
        return <Tag color="green">{t('templateType.myTemplate')}</Tag>;
      case TemplateTypeId.shareTemplate:
        return <Tag color="geekblue">{t('templateType.shareTemplate')}</Tag>;
      default:
        return '';
    }
  };

  return (
    <Col span={6} sm={{ span: 8 }} xxl={{ span: 6 }}>
      <WrapperCard
        style={{ width: '100%', marginTop: 16, borderRadius: 15 }}
        bodyStyle={{ padding: 7 }}
        actions={[
          <Button
            type="default"
            shape="round"
            size="middle"
            key={1}
            className="btn-preview"
            onClick={handlePreview}
          >
            {t(
              'funnel.selectedTemplate.selectTemplateStructure.template.buttonPreviewTemplate'
            )}
          </Button>,
          <Button
            type="default"
            shape="round"
            size="middle"
            key={3}
            className="btn-setting"
            onClick={handleUpdateTemplate}
          >
            {t('funnel.headerContent.titleButtonSettingFunnel')}
          </Button>,
        ]}
      >
        <Row align="middle" justify="space-between" className="header-element">
          <Col flex="180px">
            <WrappedFieldName
              data={{ id: data.uuid, name: data.name }}
              evenOutside={eventOutside}
              handleOnBlurOutSide={(e) => setEventOutSide(e)}
              updateNameTemplate={updateNameTemplate}
            />
          </Col>
          <Col>{renderTemplateType()}</Col>
        </Row>
        <Row>
          <Col span={24} className="content-iframe mt-3">
            <iframe
              title="YouTube video player"
              src={fetchUrlTemplate(data.landing_file_url)}
              scrolling="no"
              height="100%"
              width="100%"
              aria-disabled="true"
            ></iframe>
          </Col>
        </Row>
      </WrapperCard>
    </Col>
  );
};

const WrapperCard = styled(Card)`
  .ant-card-actions {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 0 20px;
  }
  .ant-card-actions > li:first-child span {
    text-align: start;
  }
  .ant-card-actions > li:last-child span {
    text-align: end;
  }

  .ant-card-actions > li:not(:last-child) {
    border-right: none;
  }
  .header-element {
    margin: 5px 0;
  }
  span {
    &.txt-page-type {
      background-color: var(--t-main-color-light);
      padding: 0 5px;
    }
  }
  .btn-redirect-preview {
    border: none;
    outline: none;
    background-color: transparent;
  }
  .content-iframe {
    height: 300px;
    border: 1px solid var(--t-border-color);
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    iframe {
      pointer-events: none;
      width: 1080px;
      height: 810px;
      padding: 15px;
      -webkit-transform: scale(0.33);
      -ms-transform: scale(0.33);
      transform: scale(0.33);
      -webkit-transform-origin: 0px 0px;
      -ms-transform-origin: 0px 0px;
      transform-origin: 0px 0px;
    }
  }
  .ant-btn-default {
    min-width: 110px;
  }

  .btn-preview {
    color: var(--t-main-color);
    background: var(--t-white-color);
    border: 1px solid var(--t-main-color);
    &:hover {
      background-color: var(--t-main-color);
      color: var(--t-white-color);
    }
  }

  .btn-setting {
    color: var(--t-white-color);
    border-color: transparent;
    background: var(--t-main-color);
    &:hover {
      background-color: var(--t-white-color);
      color: var(--t-main-color);
      border: 1px solid var(--t-main-color);
    }
  }
`;

export default ElementCardTemplate;
