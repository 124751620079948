import * as actions from '../actionTypes/showPopupSectionTypes';

interface PopupActionState {
  status: boolean;
  message: string;
}

const initialState = {
  status: false,
  message: '',
};

export const globalPopupReducer = (
  state: PopupActionState = initialState,
  action: actions.PopupAction
): PopupActionState => {
  switch (action.type) {
    case actions.SHOW_POPUP: {
      return {
        message: action.message,
        status: true,
      };
    }
    case actions.HIDE_POPUP: {
      return {
        status: false,
        message: '',
      };
    }
    default: {
      return state;
    }
  }
};
