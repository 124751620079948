import { Drawer, Spin } from 'antd';
import React from 'react';
import { SettingOutlined } from '@ant-design/icons';

import './style.scss';
import { useTranslation } from 'react-i18next';

interface SettingUserProps {
  children: React.ReactNode;
  loading: boolean;
  toggleDrawer: boolean;
  handleOpenAndClose: () => void;
}

const DrawerSettingComponent: React.FC<SettingUserProps> = ({
  children,
  loading,
  handleOpenAndClose,
  toggleDrawer,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <SettingOutlined className="setting-icon" onClick={handleOpenAndClose} />
      <Drawer
        title={t('global.common.setting')}
        closable={true}
        onClose={handleOpenAndClose}
        visible={toggleDrawer}
        width={300}
        bodyStyle={{ paddingBottom: 80 }}
        className="drawer-setting-user"
      >
        <Spin
          tip="Loading..."
          spinning={loading}
          style={{ color: 'var(--t-main-color)' }}
        >
          {children}
        </Spin>
      </Drawer>
    </>
  );
};

export default DrawerSettingComponent;
