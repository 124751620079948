import {
  AFFILIATE,
  CALENDAR,
  FUNNEL,
  LINK_SETTING_ACCOUNT,
  LINK_USER,
  MAIL_PATH,
  MEMBERSITE,
  MY_PAGE,
  PRODUCT,
  REMIND_MAIL,
} from './../constants/menus';
import { TITLE_MENU } from '../constants/app.constants';

interface roleUser {
  permission_id: number;
  policy_id: number;
}

interface roleCurrentUser {
  isOwner: boolean;
  isFullRole: boolean;
  role: any[];
}

export const checkRoleCurrentUser = (role: roleUser) => {
  switch (role.permission_id) {
    case 1:
      return TITLE_MENU.FUNNEL;
    case 2:
      return TITLE_MENU.REGISTER_PRODUCT;
    case 3:
      return TITLE_MENU.MAIL;
    case 4:
      return TITLE_MENU.USER;
    case 5:
      return TITLE_MENU.REPORT;
    case 6:
      return TITLE_MENU.MY_PAGE;
    case 7:
      return TITLE_MENU.MEMBERSITE;
    case 8:
      return TITLE_MENU.AFFILIATE;
    case 9:
      return TITLE_MENU.CALENDAR;
    default:
      return '';
  }
};

const checkPathNameByRole = (path: string) => {
  switch (path) {
    case FUNNEL.GROUP:
    case FUNNEL.LIST:
    case FUNNEL.FUNNEL_DETAIL:
    case FUNNEL.LIST_TEMPLATE_PARAMS_DEFAULT:
    case FUNNEL.LIST_TEMPLATE:
    case FUNNEL.TEMPLATE:
    case FUNNEL.LIST_BLOCK_TEMPLATE:
    case FUNNEL.LIST_BLOCK_TEMPLATE_PARAMS_DEFAULT:
    case FUNNEL.BLOCK_TEMPLATE:
    case FUNNEL.SHARED_FUNNEL:
    case FUNNEL.SHARED_TEMPLATE:
      return TITLE_MENU.FUNNEL;
    case MEMBERSITE.GROUP_COURSE:
    case MEMBERSITE.LIST_COURSE:
    case MEMBERSITE.SELECT:
    case MEMBERSITE.LIST_MEMBERSITE_TEMPLATE_PARAMS_DEFAULT:
    case MEMBERSITE.LIST_MEMBERSITE_TEMPLATE:
    case MEMBERSITE.MEMBERSITE_TEMPLATE:
    case MEMBERSITE.LIST_USER:
    case MEMBERSITE.SHARED_COURSE:
    case MEMBERSITE.SHARED_TEMPLATE:
      return TITLE_MENU.MEMBERSITE;
    case MEMBERSITE.COURSE_CREATE_BY_NAME:
    case MEMBERSITE.COURSE_IMPORT_CSV:
    case PRODUCT.GROUP:
      return TITLE_MENU.REGISTER_PRODUCT;
    case MAIL_PATH.LIST_STEP_MAIL:
    case REMIND_MAIL.LIST_REMIND_MAIL:
    case MAIL_PATH.SENT_MAIL_LIST:
    case MAIL_PATH.MAIL_LIST:
    case MAIL_PATH.MAIL_FIXED_PHRASE:
    case MAIL_PATH.MAIL_LIST_IMPORT_CSV:
    case MAIL_PATH.MAIL_LIST_CREATE_BY_NAME:
      return TITLE_MENU.MAIL;
    case MY_PAGE.MANAGE_PAYMENT_GATEWAY:
    case LINK_SETTING_ACCOUNT.EMAIL_INTEGRATIONS_LIST:
    case LINK_SETTING_ACCOUNT.LIST_DOMAIN:
    case LINK_SETTING_ACCOUNT.API_INTEGRATIONS:
    case MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_GMO:
    case MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_PAYPAL:
    case MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_UNIVAPAY:
    case MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_STRIPE:
    case LINK_SETTING_ACCOUNT.ACCOUNT_SETTING_DOMAIN:
    case LINK_SETTING_ACCOUNT.DETAIL_DOMAIN:
      return TITLE_MENU.MY_PAGE;
    case AFFILIATE.PARTNER:
      return TITLE_MENU.AFFILIATE;
    case LINK_USER.LIST_TAG:
      return TITLE_MENU.USER;
    case CALENDAR.CREATE:
    case CALENDAR.LIST:
    case CALENDAR.SETTING_BASIC:
    case CALENDAR.SETTING_SCHEDULE:
    case FUNNEL.LIST_TIMER:
      return TITLE_MENU.CALENDAR;
    default:
      return;
  }
};

export const checkRoleEditButton = (
  roleCurrentUser: roleCurrentUser,
  pathName: string
) => {
  if (roleCurrentUser.isOwner || roleCurrentUser.isFullRole) {
    return false;
  }
  let isEdit = false;
  roleCurrentUser.role?.forEach((role) => {
    const isCheck =
      checkRoleCurrentUser(role) === checkPathNameByRole(pathName);
    if (isCheck && role.policy_id === 1) {
      isEdit = true;
    }
  });
  return isEdit;
};

export const checkRoleButtonShare = (
  roleCurrentUser: roleCurrentUser,
  pathName: string
) => {
  if (roleCurrentUser.isOwner || roleCurrentUser.isFullRole) {
    return false;
  }
  let isRole = false;
  roleCurrentUser.role?.forEach((role) => {
    const isCheck =
      checkRoleCurrentUser(role) === checkPathNameByRole(pathName);
    if (isCheck && role.policy_id === 1) {
      return true;
    }
    if (isCheck) isRole = isCheck;
  });
  if (!isRole) return true;

  return false;
};
