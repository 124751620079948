import * as Types from '../../actions/tagManagement/types';
import { IManagerTag, initialState } from './interface';

export default function tagReducer(
  state: IManagerTag = initialState,
  action: any
): IManagerTag {
  switch (action.type) {
    case Types.GET_DETAIL_TAG_SUCCESS:
      return {
        ...state,
        tagDetail: action.data,
      };
    case Types.GET_LIST_GROUP_TAG_SUCCESS:
      return {
        ...state,
        listGroupTag: action.data,
      };
    case Types.GET_LIST_TAG_SUCCESS:
      return {
        ...state,
        listTagByGroup: action.data,
      };
    case Types.GET_LIST_TAG_USER_SUCCESS:
      return {
        ...state,
        listTagUser: action.data,
      };
    case Types.GET_ALL_TAGS_SUCCESS:
      return {
        ...state,
        allTag: action.data,
      };
    case Types.GET_USER_DETAIL_TAGS_SUCCESS:
      return {
        ...state,
        listTagOfUser: action.data,
      };
    default:
      return state;
  }
}
