import React from 'react';
import { Select } from 'antd';
const { Option } = Select;
import './styles.scss';

const OPTION_STYLE = {
  color: 'var(--t-main-color)',
};

export interface SelectOption {
  title: string;
  value: string | number;
}

interface FieldSelectProps {
  options: SelectOption[];
  className?: string;
  setValue: (value: any) => void;
  value: any;
}

const SingleSelect: React.FC<FieldSelectProps & Record<string, any>> = (
  props
) => {
  const { setValue, value, options = [], className, ...other } = props;
  return (
    <div className="field-select__wrapper">
      <Select
        className={`field-select ${className ? className : ''}`}
        onChange={(newValue) => setValue(newValue)}
        value={value}
        defaultValue={value}
        filterOption={(input: any, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        showSearch
        {...other}
        style={{ width: 250 }}
        notFoundContent="データがありません。"
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value} style={OPTION_STYLE}>
            {option.title}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default SingleSelect;
