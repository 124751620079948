import * as Types from '../../actions/membersite/types';
import { IStatePageListMembersite } from '../../actions/membersite/pageList';
import {
  SortField,
  SortOrder,
} from '../../../containers/Membersite/@DataTables/Components/types';

export const initialPageListMembersite: IStatePageListMembersite = {
  meta: {
    total: 0,
    current_page: 1,
    last_page: 2,
    per_page: 20,
  },
  payload: {
    page: 1,
    perPage: 20,
    group_uuids: '',
    flag_favorite: 0,
    sort_type: SortOrder.DESC,
    sort_name: SortField.DATETIME,
    type: 1,
  },
  data: [],
  error: '',
  domain: '',
};

export default function pageListMembersiteReducer(
  state: IStatePageListMembersite = initialPageListMembersite,
  action: any
): IStatePageListMembersite {
  switch (action.type) {
    case Types.FETCH_PAGE_LIST_MEMBERSITE_FAILED: {
      return { ...state };
    }
    case Types.FETCH_PAGE_LIST_MEMBERSITE_SUCCESS: {
      return {
        ...state,
        ...action.data,
      };
    }
    case Types.FETCH_PAGE_LIST_MEMBERSITE_FAILED: {
      return {
        ...state,
        error: action.error,
      };
    }
    default:
      return { ...state };
  }
}
