export interface OptionPackageDetail {
  numberOfFunnelsCreated: any;
  numberOfFunnelStepCreated: any;
  numberOfFunnelStepAccesses: string;
  numberOfPaymentChannels: number;
  numberOfAvailableDomains: number;
  numberOfMemberSystem: any;
  numberOfSubUser: string;
  funnelCopyFunction: boolean;
  oneClickUpSell: boolean;
  accessAnalysis: boolean;
  emailSupport: boolean;
  chatSupport: any;
  spotConsultation: string;
  orderBump: boolean;
  stepMailSupport: boolean;
  numberOfAccessFunnel: any;
  affiliate: any;
  numberOfTag: number;
  numberOfRemindMail: number;
  numberOfTimer: any;
  numberOfCalendar: number;
}

export interface PackagePlanDetail {
  uuid: string;
  name: string;
  description: string;
  price: number;
  type: number;
  detail: OptionPackageDetail;
  currency: string;
}

export interface PackagePlanDetailHigh {
  uuid: string;
  name: string;
  description: string;
  price: number;
  type: number;
  detail?: OptionPackageDetail;
  currency: string;
}

export const DEFAULT_PACKAGE: PackagePlanDetailHigh = {
  uuid: '',
  name: '',
  description: '',
  price: 0,
  type: 0,
  detail: undefined,
  currency: '',
};
export interface DataPackageDetail {
  monthly_package: PackagePlanDetail[];
  yearly_package: PackagePlanDetail[];
  current_plan_uuid?: any;
  basic_discount: number;
  premium_discount: number;
}

export enum TypePlanFunecy {
  BasicMonth = 1,
  BasicYear = 2,
  PreMiumMonth = 3,
  PreMiumYear = 4,
}

export enum TypeSubscription {
  Waiting = 3,
  WaitingTrial = 7,
}
