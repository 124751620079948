import moment from 'moment';
import i18next from 'i18next';

import { DateFormat } from '../../../constants/app.constants';
import { ISubscriberItem } from '../../../store/reducers/affiliate/interface';
import _ from 'lodash';

export enum RanksType {
  ALL = '',
  GENERAL = 1,
  VIP = 2,
  SVIP = 3,
}

export const RANKS_TYPE_LIST = [
  {
    value: RanksType.ALL,
    title: i18next.t('affiliate.subscriber.filter.rank.all'),
  },
  {
    value: RanksType.GENERAL,
    title: i18next.t('affiliate.subscriber.filter.rank.general'),
  },
  {
    value: RanksType.VIP,
    title: i18next.t('affiliate.subscriber.filter.rank.vip'),
  },
  {
    value: RanksType.SVIP,
    title: i18next.t('affiliate.subscriber.filter.rank.svip'),
  },
];

export enum BonusType {
  ALL = '',
  ONE_TIME = 1,
  SUBCRIPTION = 2,
}

export const BONUS_TYPE_LIST = [
  {
    value: BonusType.ALL,
    title: i18next.t('affiliate.subscriber.filter.bonusType.all'),
  },
  {
    value: BonusType.ONE_TIME,
    title: i18next.t('affiliate.subscriber.filter.bonusType.oneTime'),
  },
  {
    value: BonusType.SUBCRIPTION,
    title: i18next.t('affiliate.subscriber.filter.bonusType.subcription'),
  },
];

export enum ApproveStatusType {
  ALL = '',
  WAITING = 1,
  APPROVED = 2,
  REJECTED = 3,
}

export const APPROVE_STATUS_TYPE_LIST = [
  {
    value: ApproveStatusType.ALL,
    title: i18next.t('affiliate.subscriber.filter.approveStatus.all'),
  },
  {
    value: ApproveStatusType.WAITING,
    title: i18next.t('affiliate.subscriber.filter.approveStatus.waiting'),
  },
  {
    value: ApproveStatusType.APPROVED,
    title: i18next.t('affiliate.subscriber.filter.approveStatus.approved'),
  },
  {
    value: ApproveStatusType.REJECTED,
    title: i18next.t('affiliate.subscriber.filter.approveStatus.rejected'),
  },
];

export enum PaymentBonusStatusType {
  ALL = '',
  UNPAID = 1,
  PAID = 2,
}

export const PAYMENT_BONUS_STATUS_TYPE_LIST = [
  {
    value: PaymentBonusStatusType.ALL,
    title: i18next.t('affiliate.subscriber.filter.paymentStatusBonus.all'),
  },
  {
    value: PaymentBonusStatusType.UNPAID,
    title: i18next.t('affiliate.subscriber.filter.paymentStatusBonus.unpaid'),
  },
  {
    value: PaymentBonusStatusType.PAID,
    title: i18next.t('affiliate.subscriber.filter.paymentStatusBonus.paid'),
  },
];

export interface IDataColumn {
  name: string;
  hasFixed?: boolean;
}

export const HEADER_COLUMNS: Array<IDataColumn> = [
  {
    name: i18next.t('affiliate.subscriber.table.header.email'),
    hasFixed: true,
  },
  {
    name: i18next.t('affiliate.subscriber.table.header.endusername'),
  },
  {
    name: i18next.t('affiliate.subscriber.table.header.time'),
  },
  {
    name: i18next.t('affiliate.subscriber.table.header.funnelName'),
  },
  {
    name: i18next.t('affiliate.subscriber.table.header.productName'),
  },
  {
    name: i18next.t('affiliate.subscriber.table.header.partnerName'),
  },
  {
    name: i18next.t('affiliate.subscriber.table.header.rank'),
  },
  {
    name: i18next.t('affiliate.subscriber.table.header.bonusType'),
  },
  {
    name: i18next.t('affiliate.subscriber.table.header.countPaid'),
  },
  {
    name: i18next.t('affiliate.subscriber.table.header.bonusReceive'),
  },
  {
    name: i18next.t('affiliate.subscriber.table.header.approveStatus'),
  },
  {
    name: i18next.t('affiliate.subscriber.table.header.paymentBonusStatus'),
  },
];

export interface DateTimeFilter {
  dateType?: number;
  dateFrom?: moment.Moment | string;
  dateTo?: moment.Moment | string;
}

export const FILTER_TIME: DateTimeFilter = {
  dateType: 0,
  dateTo: moment().format(DateFormat),
  dateFrom: moment().format(DateFormat),
};

export const getDefaultOptionApproveStatusValue = (
  value: ApproveStatusType
) => {
  switch (value) {
    case ApproveStatusType.WAITING:
      return ApproveStatusType.APPROVED;
    case ApproveStatusType.APPROVED:
      return ApproveStatusType.WAITING;
    case ApproveStatusType.REJECTED:
      return ApproveStatusType.WAITING;
    default:
      return 0;
  }
};

export const converDataSubscriberList = (data: ISubscriberItem[]) => {
  if (!data || !data?.length) return [];
  return data?.map((item: ISubscriberItem) => ({
    ...item,
    is_checked: false,
  }));
};

export const filterParamsNotValue = (obj: any) => {
  for (const key in obj) {
    if (
      obj[key] === '' ||
      obj[key] === undefined ||
      (typeof obj[key] === 'object' && _.isEmpty(obj[key])) ||
      obj[key] === null
    ) {
      delete obj[key];
    }
  }
  return obj;
};

export enum EPartnerHasBankInfo {
  HAS_NOT_BANK_INFO = 0,
  HAS_BANK_INFO = 1,
}
