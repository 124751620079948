import { toast } from 'react-toastify';
import { getIn } from 'formik';
import i18next from 'i18next';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';

import { IMultipleSelectedProps } from '../components/atoms/TMultipleSelected/TMultipleSelected';
import { IMenuItem } from './../interfaces/layout/IMenu.interfaces';
import { ICategoriesPageTemplate } from '../store/reducers/common/interface';

import { getCookiesByName, SYSTEM_USER_IDENTIFY } from './cookies';
import {
  CACHE_TYPES,
  DateFormat,
  DEFAULT_PAGE_TEMPLATE_TYPE,
} from './../constants/app.constants';

export const getRootSubMenuKeys = (menus: Array<IMenuItem>) => {
  const listSubMenu: string[] = [];
  menus.forEach((item: IMenuItem) => {
    if (item.children) {
      listSubMenu.push(item.path);
    }
  });
  return listSubMenu;
};

//merged data to multiple seleted
export const mapDataMSelected = (data: any) => {
  if (!data) return [];
  const funnels: Array<IMultipleSelectedProps> = [];
  data.map((item: any) => {
    const { uuid, name } = item;
    funnels.push({
      value: uuid,
      label: name,
      title: name,
    });
  });
  return funnels;
};

export const handleAllFilter = (
  value: any,
  presentFilter: any,
  selectedAllItem: any
) => {
  if (value.includes('All')) {
    if (value.length === presentFilter.length + 1) {
      return [...selectedAllItem];
    }
    if (value.length + 1 === selectedAllItem.length) {
      const response = value.filter((x: any) => x !== 'All');
      return [...response];
    }
  } else {
    if (value.length + 1 === selectedAllItem.length) {
      if (presentFilter.includes('All')) {
        return [];
      }
      return [...selectedAllItem];
    }
    return value;
  }
};

export const calcNextPrevPage = (
  totalPage: number,
  pageSize: number,
  type: 'next' | 'prev',
  currentPage: number
) => {
  const calcPage = totalPage / pageSize;
  let prevORNextPage = Math.floor(calcPage);
  if (calcPage > prevORNextPage) {
    prevORNextPage += 1;
  }
  if (
    (currentPage === 1 && type === 'prev') ||
    (currentPage === prevORNextPage && type === 'next')
  ) {
    return currentPage;
  } else {
    return type === 'next' ? currentPage + 1 : currentPage - 1;
  }
};

export const mapOptionsToSeleted = (data: any) => {
  if (!data) return [];

  const res: any[] = [];

  data.map((item: any) => {
    res.push({
      title: item.name,
      value: item.uuid,
    });
  });
  return res;
};

export function checkValidation(errors: any, fieldName: any) {
  if (getIn(errors, fieldName)) {
    return true;
  } else {
    return false;
  }
}

export function formatSendTime(data: string) {
  const tmp: any[] = data.split(':');

  return `${parseInt(tmp[0]) ? tmp[0] + '日' : ''}${
    parseInt(tmp[1]) ? tmp[1] + '時間' : ''
  }${parseInt(tmp[2]) ? tmp[2] + '分' : ''}${
    parseInt(tmp[3]) ? tmp[3] + '秒' : ''
  }`;
}

export function formatSendTimeSecond(data: string) {
  const tmp: any[] = data.split(':');

  return `${parseInt(tmp[0]) ? tmp[0] + '日' : ''}${
    parseInt(parseInt(tmp[0]) > 0 && tmp[1]) > 0 ||
    (parseInt(tmp[0]) > 0 && parseInt(tmp[2]) > 0)
      ? 'と'
      : ''
  }${parseInt(tmp[1]) ? tmp[1] + '時間' : ''}${
    parseInt(tmp[2]) ? tmp[2] + '分' : ''
  }${parseInt(tmp[3]) ? tmp[3] + '秒' : ''}`;
}

export function formatSendTimeFlash(data: string) {
  const tmp: any[] = data.split(':');
  return `${tmp[0] + i18next.t('stepMail.formatTime.afterDay')}${
    (Number(tmp[1]) >= 10
      ? tmp[1]
      : '0' + (tmp[1] === '0' ? '0' : tmp[1]?.replace('0', ''))) +
    i18next.t('stepMail.formatTime.time')
  }${
    (Number(tmp[2]) >= 10
      ? tmp[2]
      : '0' + (tmp[2] === '0' ? '0' : tmp[2]?.replace('0', ''))) +
    i18next.t('stepMail.formatTime.Minutes')
  }`;
}

export function trimInput(input: string) {
  return input?.trim().replace(/\s\s+/g, ' ');
}

export function handleMessage(res: any) {
  const { status, error, message } = res.data;
  if (res.status !== 401) {
    switch (status) {
      case 'fails':
        const getError = Object.keys(error);
        toast.error(error[getError[0]][0]);
        break;
      case 'error':
        toast.error(error);
        break;
      case 'success':
        toast.success(message);
        break;
      default:
        return;
    }
  }
}

export function validationTime(
  value: any,
  name: string,
  max: number,
  setFieldValue: any
) {
  if (value.includes('-') === false) {
    setFieldValue(name, value.length > 1 ? value.replace(/^0+/, '') : value);
  } else {
    setFieldValue(name, max);
  }
}

export const calcNextPrevPageUtilities = (data: any, type: 'next' | 'prev') => {
  const calcPage = data.totalPage / data.pageSize;
  let prevORNextPage = Math.floor(calcPage);
  if (calcPage > prevORNextPage) {
    prevORNextPage += 1;
  }
  if (
    (data.currentPage === 1 && type === 'prev') ||
    (data.currentPage === prevORNextPage && type === 'next')
  ) {
    return data.currentPage;
  } else {
    return type === 'next' ? data.currentPage + 1 : data.currentPage - 1;
  }
};

export const GetRangeDateTime = (type: any) => {
  switch (type) {
    case 0: {
      return {
        dateTo: moment(new Date()).format(DateFormat),
        dateFrom: moment(new Date()).format(DateFormat),
      };
    }
    case 1: {
      return {
        dateTo: moment(new Date()).format(DateFormat),
        dateFrom: moment(new Date()).format(DateFormat),
      };
    }
    case 2: {
      return {
        dateFrom: moment().subtract(6, 'day').format(DateFormat),
        dateTo: moment().format(DateFormat),
      };
    }
    case 3: {
      return {
        dateFrom: moment()
          .subtract(30 - 1, 'day')
          .startOf('day')
          .format(DateFormat),
        dateTo: moment().subtract(0, 'day').endOf('day').format(DateFormat),
      };
    }
    case 4: {
      return {
        dateFrom: moment()
          .subtract(1, 'month')
          .startOf('month')
          .format(DateFormat),
        dateTo: moment().subtract(1, 'month').endOf('month').format(DateFormat),
      };
    }
    case 5: {
      return {
        dateFrom: moment()
          .subtract(3, 'month')
          .startOf('month')
          .format(DateFormat),
        dateTo: moment().subtract(1, 'month').endOf('month').format(DateFormat),
      };
    }
    case 6: {
      return {
        dateFrom: moment()
          .subtract(6, 'month')
          .startOf('month')
          .format(DateFormat),
        dateTo: moment().subtract(1, 'month').endOf('month').format(DateFormat),
      };
    }
    case 7: {
      return {
        dateTo: moment().subtract(1, 'month').endOf('month').format(DateFormat),
        dateFrom: moment()
          .subtract(1, 'year')
          .startOf('month')
          .format(DateFormat),
      };
    }
    case 9: {
      return {
        dateFrom: moment().subtract(1, 'day').format(DateFormat),
        dateTo: moment().subtract(1, 'day').endOf('day').format(DateFormat),
      };
    }
  }
};

export function formatSendTimeBulkMail(time: any) {
  const YMD: any = time.split(' ')[0];
  const HM: any = time.split(' ')[1];

  const tmpYMD: any = YMD.split('-');
  const tmpHM: any = HM.split(':');

  return `${tmpYMD[0]}年${tmpYMD[1]}月${tmpYMD[2]}日 ${tmpHM[0]}時${tmpHM[1]}分`;
}

export function convertLocalToUTC(dt: any, dtFormat: string) {
  return moment(dt, dtFormat).utc().format(dtFormat);
}
export function convertLocalToUTCStart(dt: any, dtFormat: string) {
  return moment(new Date(dt + 'Z').toString())
    .utc()
    .format(dtFormat);
}
export function convertLocalTime(date: Date, dtFormat: string) {
  return moment(new Date(date + 'Z').toString()).format(dtFormat);
}

export function convertLocalTimeNotZ(date: Date, dtFormat: string) {
  return moment(new Date(date).toString()).format(dtFormat);
}

export const scrollTop = () => {
  window.scrollTo({
    top: 70,
    left: 100,
    behavior: 'smooth',
  });
};

export const FORMAT_DATE_JP = 'YYYY年MM月DD日 HH時mm分';
export const FORMAT_DATA_JP_NOT_SPACE = 'YYYY年MM月DD日HH時mm分';

export const convertTimeFromUTCToCurrentTimeZone = (date: Date | string) => {
  const currentTimeZone = getCurrentTimeZone();
  return momentTimeZone.utc(date).tz(currentTimeZone).format(FORMAT_DATE_JP);
};

export const convertToTimeJPNotSpace = (date: moment.Moment) =>
  moment(date).format(FORMAT_DATA_JP_NOT_SPACE);

export const convertTimeLocalJapanToMinute = (date: Date | string) => {
  return moment.utc(date).local().format(FORMAT_DATE_JP);
};

export const convertTimeLocalJapanToDate = (date: Date | string) => {
  return moment.utc(date).local().format('YYYY年MM月DD日');
};

export const convertTimeJapanToMinute = (date: Date | string) => {
  return moment.utc(date).format(FORMAT_DATE_JP);
};

export const changeNameEmailInReducer = (
  data: any,
  uuidStepMail: string,
  newNameEmail: string
) => {
  const itemMail = data.find((e: any) => e.uuid === uuidStepMail);
  const indexItemMail = data.findIndex((e: any) => e.uuid === uuidStepMail);

  data.splice(indexItemMail, 1, {
    ...itemMail,
    email: newNameEmail,
  });

  return data;
};

export const deleteEmailIntegrationsInReducer = (
  data: any,
  uuidMail: string
) => {
  const indexItemMail = data.findIndex((e: any) => e.uuid === uuidMail);

  data.splice(indexItemMail, 1);

  return data;
};

export const deleteMailToBlackListInReducer = (data: any, uuidMail: string) => {
  const indexItemTransactions = data.findIndex((e: any) => e.uuid === uuidMail);

  data.splice(indexItemTransactions, 1);

  return data;
};

export const changeObjectStepName = (
  data: any,
  uuid: string,
  name: string,
  funnel_step_subcategory_id?: string
) => {
  const index = data.findIndex((el: any) => el.uuid === uuid);
  data[index].name = name;
  if (funnel_step_subcategory_id) {
    data[index].funnel_step_subcategory_id = funnel_step_subcategory_id;
  }
  return data;
};

export const handleCache = (data: any, key: string) => {
  if (!localStorage) return;

  localStorage.setItem(key, JSON.stringify(data));
};

export const getCurrentTimeZone = () => {
  const currentTimeZone = localStorage.getItem(CACHE_TYPES.CURRENT_TIMEZONE);
  return currentTimeZone || 'Asia/Tokyo';
};

export const handleClearCache = (key: string) => {
  if (!localStorage) return;

  localStorage.removeItem(key);
};

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function toFixedCurrency(num: any) {
  return new Intl.NumberFormat('ja-JP').format(num);
}

export const isAllSpecificKeyEqual = (array: any[], key: string) => {
  let firstNonNull: any = null;
  return array.every((element: any) => {
    const value = element[key];
    if (value === null) {
      return true;
    }
    if (firstNonNull === null) {
      firstNonNull = value;
      return true;
    }
    return value === firstNonNull;
  });
};

export const convertDataSelect = (data: any) =>
  data.map((item: any) => ({
    value: item.uuid,
    title: item.name,
  })) || [];

export const windowScrollTo = (top?: number, left?: number) => {
  window.scrollTo({
    top: top || 70,
    left: left || 100,
    behavior: 'smooth',
  });
};

// include: funnel and membersite
export const handleGetSubCategoryPageTemplateTypeIdDefault = (
  funnelStepSubcategoryId: number,
  categoriesPageTemplate: ICategoriesPageTemplate[]
) => {
  return funnelStepSubcategoryId === DEFAULT_PAGE_TEMPLATE_TYPE
    ? categoriesPageTemplate[0]?.subcategories[0]?.id
    : funnelStepSubcategoryId;
};

export const handleGetInfoPageTemplateBySubCategoryId = (
  funnelStepSubCategoryId: number,
  categoriesPageTemplate: ICategoriesPageTemplate[]
) => {
  for (let i = 0; i < categoriesPageTemplate.length; i++) {
    for (let j = 0; j < categoriesPageTemplate[i].subcategories.length; j++) {
      if (
        funnelStepSubCategoryId ===
        categoriesPageTemplate[i]?.subcategories[j]?.id
      ) {
        const { subcategories, ...filteredParams } = categoriesPageTemplate[i];
        return {
          ...filteredParams,
          subcategory: subcategories[j],
        };
      }
    }
  }

  const { subcategories, ...filteredParams } = categoriesPageTemplate[0];
  return {
    ...filteredParams,
    subcategory: subcategories[0],
  };
};

export const getInfoCategoryPageTemplate = (
  funnelStepSubCategoryId: number,
  categoriesPageTemplate: ICategoriesPageTemplate[]
) => {
  for (let i = 0; i < categoriesPageTemplate.length; i++) {
    for (let j = 0; j < categoriesPageTemplate[i].subcategories.length; j++) {
      if (
        funnelStepSubCategoryId ===
        categoriesPageTemplate[i]?.subcategories[j]?.id
      ) {
        return categoriesPageTemplate[i];
      }
    }
  }

  return categoriesPageTemplate[0];
};

//<----------------------------------------------------------------->
export const encodeHtml = (str: string) => {
  const map = new Map<string, string>([
    ['&', '&amp;'],
    ['<', '&lt;'],
    ['>', '&gt;'],
    ['<', '&lt;'],
    ['"', '&quot;'],
    ["'", '&#039;'],
    ['/', '&frasl;'],
    ['–', '&ndash;'],
    ['—', '&mdash;'],
  ]);

  return str.replace(/[&<>"'/–—]/g, (m: string) => map.get(m) ?? m);
};

export const getMinute = (hour: string, minute: string) => {
  return parseInt(hour) * 60 + parseInt(minute);
};

export const getFormatNumber = (value: string, min: number, max: number) => {
  if (Number(value) < min) {
    return min.toString();
  }

  if (Number(value) > max) {
    return max.toString();
  }
  return value;
};

export const handlePostPreviewPage = (url: string) => {
  const mapForm: any = document.createElement('form');
  mapForm.target = '_blank';
  mapForm.method = 'POST';
  mapForm.action = `${url}/preview`;
  // Create an input
  const mapInput: any = document.createElement('input');
  mapInput.type = 'hidden';
  mapInput.name = 'is_preview';
  mapInput.value = getCookiesByName(SYSTEM_USER_IDENTIFY);

  // Add the input to the form
  mapForm.appendChild(mapInput);

  // Add the form to dom
  document.body.appendChild(mapForm);

  // Just submit
  mapForm.submit();
  document.body.removeChild(mapForm);
};
