import * as Types from './types';
import {
  ProfileEdit,
  ProfileEditPassword,
} from '../../reducers/myPage/interface';

interface ActionCallback {
  success: (data: any) => void;
  error: (data: string) => void;
}

const fetchUserProfile = (success?: (data: any) => void) => ({
  type: Types.FETCH_USER_PROFILE,
  success,
});

const fetchPrefectures = () => ({
  type: Types.FETCH_PREFECTURES,
});

const updateUserProfile = (data: ProfileEdit, onAction: ActionCallback) => ({
  type: Types.UPDATE_USER_PROFILE,
  data,
  onSuccess: onAction.success,
  onError: onAction.error,
});

const updateProfilePassword = (
  data: ProfileEditPassword,
  onAction: ActionCallback
) => ({
  type: Types.UPDATE_PROFILE_PASSWORD,
  data,
  onSuccess: onAction.success,
  onError: onAction.error,
});

const sendMailToResetPassword = (
  data: { email?: string },
  onAction: ActionCallback
) => ({
  type: Types.SEND_MAIL_TO_RESET_PASSWORD,
  data,
  onSuccess: onAction.success,
  onError: onAction.error,
});

const verifyChangeEmail = (
  data: { token: string },
  onAction: ActionCallback
) => ({
  type: Types.VERIFY_CHANGE_EMAIL,
  data,
  onSuccess: onAction.success,
  onError: onAction.error,
});

const updateUnreadNotifications = (data: any) => ({
  type: Types.UPDATE_UNREAD_NOTIFICATIONS,
  data,
});

export {
  fetchUserProfile,
  fetchPrefectures,
  updateUserProfile,
  updateProfilePassword,
  sendMailToResetPassword,
  verifyChangeEmail,
  updateUnreadNotifications,
};
