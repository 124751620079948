import React, { useEffect, useState } from 'react';
import { AntDrawerNavigationProps, listCollapsed } from './config';
import { Menu, Layout } from 'antd';
import { useLocation, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getRootSubMenuKeys } from '../../../utilities/common.utilities';
import { redirectWhenLogout } from '../../../config/common.config';
import RenderAllImageSidebar from '../atoms/RenderAllImages';
import {
  DefaultSubMenu,
  DefaultSubMenuLogout,
  DefaultSubMenuWarning,
  ParentMenu,
} from '../atoms/SubMenuItem';
import { SkeletonImageIcon } from '../atoms/SkeletonImageIcon';
import SubMenu from 'antd/lib/menu/SubMenu';
import { hideLoading, showLoading } from '../../../store/actions/loadingAction';
import './style.scss';
import { callLogout } from '../../../Clients/authentication';
import { AppState } from '../../../store/reducers/rootReducer';
import BlockWarningNotCard, {
  BlockCaredWarningMini,
} from '../atoms/BlockWarningNotCard';
import { MY_PAGE } from '../../../constants/menus';
const { Sider } = Layout;

const ArticleSidebar: React.FC<AntDrawerNavigationProps> = ({ menus }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const rootSubMenuKeys = getRootSubMenuKeys(menus);
  const [openKeys, setOpenKeys] = useState(['']);
  const [activeKey, setActiveKey] = useState('');
  const profile = useSelector((state: AppState) => state.myPage.profile.data);
  const loading = useSelector((state: AppState) => state.isLoading);
  const [hasHeader, setHasHeader] = useState<boolean>(false);

  const { user_profile, current_company, role } = profile;
  useEffect(() => {
    menus.forEach((item) => {
      if (item.children?.length && item.children.length > 0) {
        item.children.forEach((child) => {
          if (child.path === location.pathname) {
            setOpenKeys([item.path]);
            setActiveKey(child.path);
          } else {
            if (child.pathChildren?.find((x) => x.path === match.path)) {
              setOpenKeys([item.path]);
              setActiveKey(child.path);
            }
          }
        });
      } else {
        if (item.path === location.pathname) {
          setOpenKeys([item.path]);
          setActiveKey(item.path);
        }
      }
    });
  }, [menus]);

  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    if (rootSubMenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const requestLogout = async () => {
    try {
      dispatch(showLoading());
      const res = await callLogout();
      if (res.status === 200) {
        redirectWhenLogout();
      } else {
        dispatch(hideLoading());
      }
    } catch (error) {
      dispatch(hideLoading());
      throw error;
    }
  };

  useEffect(() => {
    if (current_company?.card_last_four !== undefined) {
      setHasHeader(current_company?.card_last_four ? false : true);
    }
  }, [current_company?.card_last_four]);

  return (
    <>
      <Sider
        className={`drawer-navigation ${
          listCollapsed.includes(match.path) ? 'collapsed' : ''
        }`}
      >
        <RenderAllImageSidebar />
        <Menu
          theme="dark"
          mode="inline"
          onOpenChange={onOpenChange}
          openKeys={openKeys}
          selectedKeys={[activeKey]}
        >
          {menus.map((item, index) => {
            if (item.children) {
              return (
                <SubMenu
                  key={item.path}
                  className="noselect"
                  icon={
                    <SkeletonImageIcon
                      srcName={item.icon}
                      width={40}
                      className="menu-icon-first"
                    />
                  }
                  title={item.title}
                >
                  {item.children.length &&
                    item.children.map((child, indexChild) => {
                      if (child.path === '/logout') {
                        return (
                          <Menu.Item
                            key={child.path}
                            onClick={() => {
                              requestLogout();
                            }}
                            className="noselect"
                          >
                            <DefaultSubMenuLogout
                              position={child.path + indexChild}
                              data={child}
                            />
                          </Menu.Item>
                        );
                      } else if (
                        child.path === MY_PAGE.PROFILE &&
                        !user_profile?.email
                      ) {
                        return (
                          <Menu.Item key={child.path} className="noselect">
                            <DefaultSubMenuWarning
                              position={child.path + indexChild}
                              data={child}
                            />
                          </Menu.Item>
                        );
                      } else {
                        return (
                          <Menu.Item key={child.path} className="noselect">
                            <DefaultSubMenu
                              data={child}
                              position={child.path + indexChild}
                            />
                          </Menu.Item>
                        );
                      }
                    })}
                </SubMenu>
              );
            } else {
              return (
                <Menu.Item key={item.path} className="noselect">
                  <ParentMenu data={item} position={item.path + index} />
                </Menu.Item>
              );
            }
          })}
        </Menu>
        {hasHeader &&
          role?.is_owner &&
          !loading &&
          (listCollapsed.includes(match.path) ? (
            <BlockCaredWarningMini />
          ) : (
            <BlockWarningNotCard />
          ))}
      </Sider>
    </>
  );
};

export default ArticleSidebar;
