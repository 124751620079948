import { call, put, takeLatest } from 'redux-saga/effects';
import * as Types from '../actions/membersite/types';
import * as ActionTypes from '../actions/funnel/types';
import { handleMessage } from '../../utilities/common.utilities';
import { EStatusHttp, IResponse } from '../../Clients/interfaces';
import {
  getMembersiteMetadata,
  renameGroupFunnel,
  updateMembersiteMetadata,
} from '../../Clients/funnels';
import { showMessageResponse } from '../../Clients/helper';
import { IMultipleSelectedProps } from '../../components/atoms/TMultipleSelected/TMultipleSelected';
import { DEFAULT_MEMBERSITE_GROUPS } from '../../constants/app.constants';
import { HIDE_LOADING, SHOW_LOADING } from '../actionTypes/loadingActionTypes';
import { fetchSelectedGroupFunnelSuccess } from '../actions/funnel/group';
import {
  changeNameCourse,
  getAllGroupOfCourse,
  getHtmlTemplate,
  getPageListGroupCourses,
  getPageListCourse,
  getSummaryCourse,
  ICourse,
  createCourse,
  updateCourse,
  createUserByImportCSV,
  ICreateUserByImportCSV,
  getSettingCourse,
  createUserInCourse,
  ICreateUserInCourse,
  getAllGroupCourse,
  getCourseById,
  shareCourse,
  getListSignature,
  getSetting,
  updateMembersite,
  IMembersiteSetting,
  getSettingMail,
  updateMail,
  updateSendTestMail,
} from '../../Clients/membersites';
import { fetchSummaryMembersiteSuccess } from '../actions/membersite/membersiteList';
import {
  fetchGroupMembersiteListSuccess,
  IPayLoadGroupCourseList,
} from '../actions/membersite/group';
import {
  fetchPageListMembersiteSuccess,
  IDataPageList,
  PayloadPageListCourse,
} from '../actions/membersite/pageList';
import { initialPageListMembersite } from '../reducers/membersite/pageList';
import {
  fetchMembersiteMetadataFailed,
  fetchMembersiteMetadataSuccess,
  updateMembersiteMetadataFailed,
  updateMembersiteMetadataSuccess,
} from '../actions/funnel/funnelList';
import { initialGroupCourses } from '../reducers/membersite/interfaces';
import {
  SendTestMailProps,
  SettingMailProps,
} from '../../containers/Membersite/Settings/MailWelcome/config';
interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
}
interface APIResponseCourse {
  uuid: string;
  name: string;
}
interface APIResponseListCourse {
  uuid: string;
  name: string;
  path: string;
  group_uuid: string;
  group_name: string;
  flag_favorite: number;
  flag_publish: number;
  last_updated_time: string;
}

function* getSettingMembersite(action: {
  type: string;
  onSuccess: (data: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: IResponse<any> = yield call(getSetting);
    if (response && response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data?.data);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* postUpdateMembersite(action: {
  type: string;
  data: IMembersiteSetting;
  onSuccess: () => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: IResponse<any> = yield call(updateMembersite, action.data);
    if (response && response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* fetchListSignature(action: {
  type: string;
  onSuccess: (data: any) => void;
}) {
  try {
    const response: IResponse<any> = yield call(getListSignature);
    if (response && response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data?.data);
    } else {
      handleMessage(response);
    }
  } catch (error: any) {
    handleMessage(error.response);
  }
}

function* fetchSettingMail(action: {
  type: string;
  onSuccess: (data: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: IResponse<any> = yield call(getSettingMail);
    if (response && response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data?.data);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* postUpdateMail(action: {
  type: string;
  data: SettingMailProps;
  onSuccess: () => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: IResponse<any> = yield call(updateMail, action.data);
    if (response && response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* postUpdateSendTestMail(action: {
  type: string;
  data: SendTestMailProps;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: IResponse<any> = yield call(
      updateSendTestMail,
      action.data
    );
    if (response && response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* renameFunnelGroup(action: {
  type: string;
  data: any;
  onSuccess: () => void;
  onError: () => void;
}) {
  try {
    const response: APIResponseType = yield call(
      renameGroupFunnel,
      action.data
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
    } else {
      action.onError();
      handleMessage(response);
    }
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
    action.onError();
  }
}

function* renameCourseListItem(action: {
  type: string;
  data: APIResponseCourse;
  onSuccess: (data: any) => void;
  onError: () => void;
}) {
  try {
    const response: APIResponseType = yield call(changeNameCourse, action.data);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data);
    }
  } catch (error: any) {
    handleMessage(error.response);
    action.onError();
  }
}

function* fetchListCourse(action: {
  type: string;
  data: any;
  onSuccess: (data: any) => void;
  onError: () => void;
}) {
  try {
    const response: APIResponseType = yield call(
      getPageListCourse,
      action.data
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data.data);
    } else {
      action.onError();
    }
  } catch (error) {
    action.onError();
  }
}

function* fetchSummaryCourse() {
  try {
    const response: IResponse<any> = yield call(getSummaryCourse);
    if (response && response.status === EStatusHttp.HTTP_OK) {
      yield put(fetchSummaryMembersiteSuccess(response.data?.data));
    } else {
      showMessageResponse(response);
    }
  } catch (error: any) {
    showMessageResponse(error.response);
  }
}

function* fetchSelectedGroupCourse() {
  const response: IResponse<any> = yield call(getAllGroupOfCourse);
  try {
    if (response && response.status === EStatusHttp.HTTP_OK) {
      const data: IMultipleSelectedProps[] = DEFAULT_MEMBERSITE_GROUPS.concat(
        response.data?.data.map((el: APIResponseCourse) => {
          return {
            value: el.uuid,
            title: el.name,
            label: el.name,
          };
        })
      );
      yield put(fetchSelectedGroupFunnelSuccess(data));
    }
  } catch (error: any) {
    showMessageResponse(error.response);
  }
}

function* fetchGroupCourseList(action: {
  type: string;
  payload: IPayLoadGroupCourseList;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const response: IResponse<any> = yield call(() =>
      getPageListGroupCourses(action.payload)
    );
    yield put({ type: HIDE_LOADING });
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put(
        fetchGroupMembersiteListSuccess({
          ...initialGroupCourses,
          payload: action.payload,
          groups: response.data?.data.groups,
          meta: response.data?.data.meta,
          domain: response.data?.data?.domain,
        })
      );
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    yield put(
      fetchGroupMembersiteListSuccess({
        ...initialGroupCourses,
        groups: [],
      })
    );
    throw error;
  }
}

function* fetchPageListCourse(action: {
  type: string;
  payload: PayloadPageListCourse;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const response: IResponse<any> = yield call(() =>
      getPageListCourse(action.payload)
    );
    yield put({ type: HIDE_LOADING });
    if (response.status === EStatusHttp.HTTP_OK) {
      const membersites: IDataPageList[] = response.data?.data.data.map(
        (el: APIResponseListCourse) => {
          return {
            group_name: el.group_name,
            uuid: el.uuid,
            path: el.path,
            flag_favorite: el.flag_favorite,
            flag_publish: el.flag_publish,
            name: el.name,
            group_uuid: el.group_uuid,
            last_updated_time: el.last_updated_time,
          };
        }
      );
      yield put(
        fetchPageListMembersiteSuccess({
          ...initialPageListMembersite,
          payload: action.payload,
          meta: response.data?.data.meta,
          data: membersites,
          domain: response.data?.data?.domain,
        })
      );
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    yield put(
      fetchPageListMembersiteSuccess({
        ...initialPageListMembersite,
        data: [],
      })
    );
    throw error;
  }
}

function* fetchHtmlPageTemplates(action: {
  type: string;
  data: string;
  onSuccess: (data: any) => void;
  onError: () => void;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(getHtmlTemplate, action.data);
    action.onSuccess(result.data);
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    action.onError();
    yield put({ type: HIDE_LOADING });
  }
}

function* fetchMembersiteMetadataSaga({ payload }: any) {
  try {
    const response: APIResponseType = yield call(
      getMembersiteMetadata,
      payload
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put(fetchMembersiteMetadataSuccess(response.data.data));
    } else {
      yield put(fetchMembersiteMetadataFailed(payload));
    }
  } catch (error: any) {
    handleMessage(error.message);
    yield put(fetchMembersiteMetadataFailed(payload));
  }
}

function* updateMembersiteMetadataSaga({ payload }: any) {
  try {
    const response: APIResponseType = yield call(
      updateMembersiteMetadata,
      payload
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put(updateMembersiteMetadataSuccess(response.data.data));
      handleMessage(response);
    } else {
      yield put(updateMembersiteMetadataFailed(payload));
    }
  } catch (error: any) {
    handleMessage(error.response);
    yield put(updateMembersiteMetadataFailed(payload));
  }
}

function* postCreateCourse(action: {
  type: string;
  payload: ICourse;
  onSuccess: (data: any) => void;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(createCourse, action.payload);
    if (result.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(result?.data?.data);
      handleMessage(result);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* postUpdateCourse(action: {
  type: string;
  payload: ICourse;
  onSuccess: (data: any) => void;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(updateCourse, action.payload);
    if (result.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(result?.data?.data);
      handleMessage(result);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* postImpostCSV(action: {
  type: string;
  payload: ICreateUserByImportCSV;
  onSuccess: (data: any) => void;
  onError: () => void;
}) {
  try {
    const result: IResponse<any> = yield call(
      createUserByImportCSV,
      action.payload
    );
    if (result.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(result?.data?.data);
    }
  } catch (error: any) {
    handleMessage(error.response);
    action.onError();
  }
}

function* getSettingCourses(action: {
  type: string;
  uuid: string;
  onSuccess: (data: any) => void;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(getSettingCourse, action.uuid);
    if (result.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(result?.data?.data);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* postAddUserCourse(action: {
  payload: ICreateUserInCourse;
  type: string;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(
      createUserInCourse,
      action.payload
    );
    if (result.status === EStatusHttp.HTTP_OK) {
      handleMessage(result);
      return;
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* getListGroupCourse(action: {
  type: string;
  onSuccess: (data?: any) => void;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(getAllGroupCourse);
    if (result.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(result?.data?.data);
      return;
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* getDataCourseId(action: {
  type: string;
  onSuccess: (data?: any) => void;
  uuid: string;
  onError: () => void;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(getCourseById, action.uuid);
    if (result.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(result?.data?.data);
      return;
    }
  } catch (error: any) {
    handleMessage(error.response);
    action.onError();
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* postSharedCourse(action: {
  type: string;
  onSuccess: (data?: any) => void;
  uuid: string;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(shareCourse, {
      uuid: action.uuid,
    });
    if (result.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(result?.data?.data);
      handleMessage(result);
      return;
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

export default function* membersitePageSaga() {
  yield takeLatest(Types.GET_SETTING_MEMBERSITE, getSettingMembersite);
  yield takeLatest(Types.POST_UPDATE_MEMBERSITE, postUpdateMembersite);
  yield takeLatest(Types.FETCH_LIST_SIGNATURE, fetchListSignature);
  yield takeLatest(Types.FETCH_SETTING_MAIL, fetchSettingMail);
  yield takeLatest(Types.POST_UPDATE_MAIL, postUpdateMail);
  yield takeLatest(Types.POST_UPDATE_SEND_TEST_MAIL, postUpdateSendTestMail);
  yield takeLatest(Types.RENAME_MEMBERSITE_GROUP, renameFunnelGroup);
  yield takeLatest(Types.RENAME_MEMBERSITE_LIST_ITEM, renameCourseListItem);
  yield takeLatest(Types.FETCH_LIST_MEMBERSITE, fetchListCourse);
  yield takeLatest(Types.FETCH_SUMMARY_MEMBERSITE, fetchSummaryCourse);
  yield takeLatest(
    Types.FETCH_SELECT_GROUP_MEMBERSITE,
    fetchSelectedGroupCourse
  );
  yield takeLatest(Types.FETCH_THE_GROUP_MEMBERSITE_LIST, fetchGroupCourseList);
  yield takeLatest(Types.FETCH_PAGE_LIST_MEMBERSITE, fetchPageListCourse);
  yield takeLatest(Types.FETCH_HTML_PAGE_TEMPLATE, fetchHtmlPageTemplates);
  yield takeLatest(
    ActionTypes.FETCH_MEMBERSITE_METADATA_REQUESTED,
    fetchMembersiteMetadataSaga
  );
  yield takeLatest(
    ActionTypes.UPDATE_MEMBERSITE_METADATA_REQUESTED,
    updateMembersiteMetadataSaga
  );
  yield takeLatest(Types.POST_CREATE_COURSE, postCreateCourse);
  yield takeLatest(Types.POST_UPDATE_COURSE, postUpdateCourse);
  yield takeLatest(Types.POST_IMPORT_CSV, postImpostCSV);
  yield takeLatest(Types.GET_SETTING_COURSE, getSettingCourses);
  yield takeLatest(Types.POST_ADD_USER_COURSE, postAddUserCourse);
  yield takeLatest(Types.GET_LIST_GROUP_COURSE, getListGroupCourse);
  yield takeLatest(Types.GET_DATA_COURSE_ID, getDataCourseId);
  yield takeLatest(Types.POST_SHARED_COURSE, postSharedCourse);
}
