import {
  SendTestMailProps,
  SettingMailProps,
} from '../../../containers/Membersite/Settings/MailWelcome/config';
import { ISummaryCourse } from '../../reducers/membersite/interfaces';
import * as types from './types';

const getSettingMembersite = (onSuccess: (data: any) => void) => ({
  type: types.GET_SETTING_MEMBERSITE,
  onSuccess,
});

const postUpdateMembersite = (data: any, onSuccess?: () => void) => ({
  type: types.POST_UPDATE_MEMBERSITE,
  data,
  onSuccess,
});

const fetchListSignature = (onSuccess: (data: any) => void) => ({
  type: types.FETCH_LIST_SIGNATURE,
  onSuccess,
});

const fetchSettingMail = (onSuccess: (data: any) => void) => ({
  type: types.FETCH_SETTING_MAIL,
  onSuccess,
});

const postUpdateMail = (data: SettingMailProps, onSuccess?: () => void) => ({
  type: types.POST_UPDATE_MAIL,
  data,
  onSuccess,
});

const postUpdateSendTestMail = (data: SendTestMailProps) => ({
  type: types.POST_UPDATE_SEND_TEST_MAIL,
  data,
});

const renameMembersitePage = (
  data: any,
  onSuccess: (data: any) => void,
  onError: (err: string) => void
) => ({
  type: types.RENAME_MEMBERSITE_GROUP,
  data,
  onSuccess,
  onError,
});

const renameMembersiteListItem = (
  data: any,
  onSuccess?: (data: any) => void,
  onError?: (err: string) => void
) => ({
  type: types.RENAME_MEMBERSITE_LIST_ITEM,
  data,
  onSuccess,
  onError,
});

const fetchListMembersite = (
  data: any,
  onSuccess: (data: any) => void,
  onError: (err: string) => void
) => ({
  type: types.FETCH_LIST_MEMBERSITE,
  data,
  onSuccess,
  onError,
});

const fetchSummaryMembersite = () => ({
  type: types.FETCH_SUMMARY_MEMBERSITE,
});

const fetchSummaryMembersiteSuccess = (payload: ISummaryCourse) => ({
  type: types.FETCH_SUMMARY_MEMBERSITE_SUCCESS,
  payload: payload,
});

const fetchSummaryMembersiteFails = (payload: string) => ({
  type: types.FETCH_SUMMARY_MEMBERSITE_FAILS,
  error: payload,
});

export {
  getSettingMembersite,
  postUpdateMembersite,
  fetchListSignature,
  fetchSettingMail,
  postUpdateMail,
  postUpdateSendTestMail,
  renameMembersitePage,
  renameMembersiteListItem,
  fetchListMembersite,
  fetchSummaryMembersite,
  fetchSummaryMembersiteSuccess,
  fetchSummaryMembersiteFails,
};
