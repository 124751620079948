import * as actions from '../actionTypes/showPopupSectionTypes';

export const showPopupAction = (message: string): actions.ShowPopupAction => ({
  type: actions.SHOW_POPUP,
  message,
});

export const hidePopupAction = (): actions.HidePopupAction => ({
  type: actions.HIDE_POPUP,
});
