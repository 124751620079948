export interface ITimerDetail {
  uuid: string;
  name: string;
  group_uuid?: string;
  is_publish: number;
  timer_type: number;
  limit_access: number;
  expired_time: string;
  expired_time_type: number;
  redirect_url: string | null;
  has_revisit_action: number | null;
  CV_updated_at: string;
  start_pages: IStartPage[];
}

export interface IStartPage {
  funnel_uuid: string;
  page_uuid: string;
}

export interface IMeta {
  current_page: number;
  last_page: number;
  per_page: number;
  total: number;
}

export interface IGroupTimer {
  uuid: string;
  name: string;
  timers?: ITimerDetail[];
}

export interface IWhitelistUser {
  is_self_apply_timer: number;
  whitelists: Array<{ ip_address: string; description?: string }>;
}

export interface IManagerTimer {
  listGroupTimer: IGroupTimer[];
  whitelistUser: IWhitelistUser;
  listTimerByGroup: {
    groups: IGroupTimer[];
    meta: IMeta;
  };
}

export const defaultTimerDetail = {
  uuid: '',
  name: '',
  group_uuid: '',
  is_publish: 0,
  timer_type: 0,
  limit_access: 0,
  expired_time: '',
  expired_time_type: 0,
  redirect_url: '',
  has_revisit_action: 0,
  CV_updated_at: '',
  start_pages: [
    {
      funnel_uuid: '',
      page_uuid: '',
    },
  ],
};

export const initialState: IManagerTimer = {
  listGroupTimer: [],
  whitelistUser: {
    is_self_apply_timer: 0,
    whitelists: [],
  },
  listTimerByGroup: {
    groups: [],
    meta: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  },
};
