import { API_BASE_URL } from '../config';

export const timerAPI = {
  getListGroupTimer: `${API_BASE_URL}/group/list/TIMER`,
  createTimer: `${API_BASE_URL}/timers`,
  getTimerDetail: (timer_uuid: string) =>
    `${API_BASE_URL}/timers/${timer_uuid}/show`,
  updateTimer: `${API_BASE_URL}/timers`,
  getWhitelistUser: `${API_BASE_URL}/timers/whitelist`,
  updateWhitelistUser: `${API_BASE_URL}/timers/whitelist`,
  getCurrentMyIP: `${API_BASE_URL}/timers/my-ip`,
  getListTimer: `${API_BASE_URL}/timers`,
  publishTimer: (timer_uuid: string) =>
    `${API_BASE_URL}/timers/${timer_uuid}/publish`,
  duplicateTimer: (timer_uuid: string) =>
    `${API_BASE_URL}/timers/${timer_uuid}/copy`,
  deleteTimer: (timer_uuid: string) => `${API_BASE_URL}/timers/${timer_uuid}`,
  renameTimer: (timer_uuid: string) =>
    `${API_BASE_URL}/timers/${timer_uuid}/rename`,

  deleteGroupTimer: `${API_BASE_URL}/group/delete`,
  duplicateGroupTimer: `${API_BASE_URL}/group/copy`,
  renameGroupTimer: `${API_BASE_URL}/group/rename`,

  resetTracking: `${API_BASE_URL}/timers/reset-tracking`,
};
