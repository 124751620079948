import { IRegisterAccount } from './interface';
import authAPI from './api';
import axiosClient from '../';

export const login = (data: any) => {
  const { email, password, reRememberMe } = data;
  const requestData = {
    email: email,
    password: password,
    remember_me: reRememberMe,
  };
  return axiosClient.post(authAPI.login, requestData);
};

export const loginTwoAuthen = (
  token: string,
  provider: any,
  url_register: string,
  plan_uuid?: string
) => {
  const requestData = {
    token: token,
    provider: provider,
    plan_uuid: plan_uuid,
    url_register: url_register,
  };
  return axiosClient.post(authAPI.loginTwoAuthen, requestData);
};

export const reSendVerifyAccount = (emails: string) => {
  const requestData = {
    email: emails,
  };
  return axiosClient.post(authAPI.reSentVerifyAccount, requestData);
};

export const registerAccount = (params: IRegisterAccount) =>
  axiosClient.post(authAPI.signup, params);

export interface IForgotPassword {
  email: string;
}
export const forgotPassword = (params: IForgotPassword) => {
  return axiosClient.post(authAPI.forgotPassword, params);
};

export const verifyTokenInvalid = (params: any) => {
  return axiosClient.post(authAPI.checkTokenInvalid, params);
};

interface IChangeNewPassword {
  password: string;
  confirm_password: string;
  token: string;
}

export const changeNewPassword = (params: IChangeNewPassword) => {
  return axiosClient.post(authAPI.changeNewPassword, params);
};

interface IActiveAccount {
  token: string;
}

export const activeAccount = (params: IActiveAccount) => {
  return axiosClient.post(authAPI.activeAccount, params);
};

export const callLogout = () => {
  return axiosClient.post(authAPI.logout);
};

export const getPackagePlans = () => {
  return axiosClient.get(authAPI.getPackagePlans);
};

interface IncomingInvoice {
  plan_uuid: string;
}
export const getIncomingInvoice = (params: IncomingInvoice) => {
  return axiosClient.get(authAPI.getIncomingInvoice, { params });
};

interface SubscriptionPlan {
  plan_uuid: string;
  subscription_proration_date?: string;
}

export const changeSubscriptionPlan = (params: SubscriptionPlan) => {
  return axiosClient.post(authAPI.changeSubscriptionPlan, params);
};

interface CancelSubscription {
  reason: string[];
}

export const changeCancelSubscriptionPlan = (params: CancelSubscription) => {
  return axiosClient.post(authAPI.cancelSubscriptionPlan, params);
};

export const changeCancelSubscriptionPlanNext = () => {
  return axiosClient.post(authAPI.cancelSubscriptionPlanNext);
};

export const getMaintenanceDetail = () =>
  axiosClient.get(authAPI.getMaintenanceDetail);
