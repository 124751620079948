import { InitialCommonManagement, initialCommonManagement } from './interface';
import * as Types from '../../actions/common/types';

export default function commonReducer(
  state: InitialCommonManagement = initialCommonManagement,
  action: any
) {
  switch (action.type) {
    case Types.GET_ALL_CATEGORIES_PAGE_TEMPLATE_SUCCESS:
      return {
        ...state,
        categoriesPageTemplate: action.data,
      };
    case Types.HANDLE_CURRENT_CATEGORY_AND_SUBCATEGORY:
      return {
        ...state,
        currentCategoryAndSubCategory: action.data,
      };
    default:
      return { ...state };
  }
}
