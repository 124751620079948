import axiosClient from '..';
import { FilterFunnelSteps } from '../../containers/FunnelReports/page/config';
import { endPoints } from './config';
// Query String Parameters
export interface ISaleReportFunnels {
  funnels_uuid: string;
  start_date: string;
  end_date: string;
  action: number;
  filter_deleted?: number;
}

export interface IFunnelFilters {
  page: number;
  per_page: number;
  keyWord: string;
}

export interface IFunnelReportFilters {
  funnel_uuid: string;
  device_category: number;
  date_from: string;
  date_to: string;
  access_source: string;
  filter_deleted?: number;
  funnel_steps?: FilterFunnelSteps[];
}

export const getSynthesisReports = (params: ISaleReportFunnels) => {
  return axiosClient.get(endPoints.getSynthesisReports(), { params });
};

export const getRangeDateTimeReports = (params: string) => {
  return axiosClient.get(endPoints.getRangeDateTimeReports(params));
};

export const callUploadImageBase64 = (params: any) => {
  return axiosClient.post(endPoints.uploadImageBase64(), params);
};

interface FunnelFilterActions {
  page: number;
  per_page: number;
  search: string;
}

export const getFunnelFilterActions = (params: FunnelFilterActions) => {
  return axiosClient.get(endPoints.getFunnelFilterActions(), { params });
};

export const GetReportFunnelByFilters = (params: IFunnelReportFilters) => {
  return axiosClient.post(endPoints.GetReportFunnelByFilters(), params);
};

export const searchAllSystem = (textSearch: string) => {
  return axiosClient.post(endPoints.searchAllSystem(textSearch));
};

export const callUploadImageOPG = (params: any) => {
  return axiosClient.post(endPoints.uploadImageOPG(), params);
};
