import * as Types from '../../actions/funnel/types';

export default function allTemplateReducer(state: [], action: any): any {
  switch (action.type) {
    case Types.FETCH_ALL_TEMPLATES: {
      return { ...state };
    }
    case Types.FETCH_ALL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case Types.FETCH_ALL_TEMPLATES_FAILED: {
      return {
        ...state,
        error: action.error,
      };
    }
    default:
      return { ...state };
  }
}
