import styled from 'styled-components';

interface ButtonActionProps {
  icon: any;
  title: string;
  onChangeEvent: (params: any) => void;
  action: 'deleted' | 'clone' | string;
  disabled?: boolean;
}

const ButtonAction = ({
  icon,
  title,
  action,
  onChangeEvent,
  disabled = false,
}: ButtonActionProps): JSX.Element => {
  const CLASS_NAME = `btn btn-lead-${action} ${disabled ? 'disabled' : ''}`;
  const ALT_IMG = `img-${action}`;

  return (
    <WrapperButton
      className={CLASS_NAME}
      onClick={onChangeEvent}
      disabled={disabled}
    >
      <img className={action} src={icon} alt={ALT_IMG} />
      <span>{title}</span>
    </WrapperButton>
  );
};

const WrapperButton = styled.button`
  width: 100%;
  pointer-events: auto !important;
  &.disabled {
    &:hover {
      cursor: no-drop;
      span,
      img {
        cursor: no-drop;
      }
    }
  }
`;

export default ButtonAction;
