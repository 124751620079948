import {
  IParamsInstallTemplate,
  IParamsUpdateLesson,
  IPayloadChangeActive,
  IPayloadDownLoadCSV,
  IPramsDataTemplate,
  IPramsListUserCourse,
} from '../../../Clients/membersites/interface';
import {
  IChangeNameTemplates,
  IPageTemplates,
} from '../../../Clients/templates/interface';
import { IStatusImport } from '../../../containers/Membersite/Course/Upload/UploadCourse/page/config';
import { FunnelDetailLoading } from '../../reducers/funnelManagement/interface';
import {
  IParamsFilterListUserCourse,
  MembersiteDetailLoading,
} from '../../reducers/membersiteManagement/interface';
import * as Types from './types';

const handleRenameMembersite = (name: string) => ({
  type: Types.RENAME_MEMBERSITE_SUCCESS,
  name,
});

const installTemplate = (
  params: IParamsInstallTemplate,
  loadingComponent?: MembersiteDetailLoading
) => ({
  type: Types.INSTALL_TEMPLATE_MEMBERSITE,
  params,
  loadingComponent,
});

interface RestoresSettingStepParams {
  uuidStep: string;
  uuidRestore: string;
}

const restoresSettingStep = (
  params: RestoresSettingStepParams,
  loadingComponent?: FunnelDetailLoading
) => ({
  type: Types.RESTORES_STEP_SETTING_MEMBERSITE,
  params,
  loadingComponent,
});

const fetchListRestores = (
  uuidStep: string,
  loadingComponent?: FunnelDetailLoading
) => ({
  type: Types.FETCH_LIST_RESTORES_MEMBERSITE,
  uuidStep,
  loadingComponent,
});

const fetchListMembersiteAccess = (uuidMembersite: string) => ({
  type: Types.FETCH_LIST_ACCESS_MEMBERSITE,
  uuidMembersite,
});

interface AccessLinkParams {
  funnel_uuid: string;
  name: string;
  description: string;
  path: string;
}

const createAccessMembersite = (
  params: AccessLinkParams,
  onSuccess: () => void
) => ({
  type: Types.ADD_LINK_ACCESS_MEMBERSITE,
  params,
  onSuccess,
});

interface ParamsUpdateAccessLink {
  access_membersite_uuid: string;
  name: string;
  description: string;
  path: string;
}

const updateAccessMembersite = (
  params: ParamsUpdateAccessLink,
  onSuccess: () => void
) => ({
  type: Types.UPDATE_LINK_ACCESS_MEMBERSITE,
  params,
  onSuccess,
});

const toggleModalAccessLink = (flag: boolean) => ({
  type: Types.TOGGLE_MODAL_SETTING_ACCESS_LINK_MEMBERSITE,
  flag,
});

const handleMembersiteStepUuidSelected = (
  membersiteStepUuidSelected: string
) => ({
  type: Types.MEMBERSITE_STEP_UUID_SELECTED_SUCCESS,
  membersiteStepUuidSelected,
});

export const detailCourse = (
  uuid: string,
  loadingComponent?: MembersiteDetailLoading
) => ({
  type: Types.GET_DETAIL_COURSE,
  loadingComponent,
  uuid,
});

export const updateSection = (
  courseUuid: string,
  sectionUuid: string,
  payload: string
) => ({
  type: Types.POST_UPDATE_SECTION,
  payload,
  courseUuid,
  sectionUuid,
});

export const deleteSection = (courseUuid: string, sectionUuid: string) => ({
  type: Types.POST_DELETE_SECTION,
  courseUuid,
  sectionUuid,
});

export const createSection = (
  courseUuid: string,
  payload: { name: string }
) => ({
  type: Types.POST_CREATE_SECTION,
  payload,
  courseUuid,
});

export const createLessons = (
  courseUuid: string,
  sectionUuid: string,
  payload: { name: string }
) => ({
  type: Types.POST_CREATE_LESSONS,
  payload,
  courseUuid,
  sectionUuid,
});

export const showLessons = (
  courseUuid: string,
  sectionUuid: string,
  lessonUuid: string,
  onSuccess: () => void
) => ({
  type: Types.GET_SHOW_LESSONS,
  lessonUuid,
  courseUuid,
  sectionUuid,
  onSuccess,
});

export const updateLessons = (
  courseUuid: string,
  sectionUuid: string,
  lessonUuid: string,
  payload: IParamsUpdateLesson
) => ({
  type: Types.POST_UPDATE_LESSONS,
  lessonUuid,
  courseUuid,
  sectionUuid,
  payload,
});

export const duplicateLessons = (
  courseUuid: string,
  sectionUuid: string,
  lessonUuid: string
) => ({
  type: Types.POST_DUPLICATE_LESSONS,
  lessonUuid,
  courseUuid,
  sectionUuid,
});

export const deleteLessons = (
  courseUuid: string,
  sectionUuid: string,
  lessonUuid: string
) => ({
  type: Types.POST_DELETE_LESSONS,
  lessonUuid,
  courseUuid,
  sectionUuid,
});

export const changePositionSection = (
  courseUuid: string,
  sectionUuid: string,
  newPosition: number
) => ({
  type: Types.POST_CHANGE_POSITION_SECTION,
  newPosition,
  courseUuid,
  sectionUuid,
});

export const changePositionLesson = (
  courseUuid: string,
  sectionUuid: string,
  lessonUuid: string,
  newPosition: number
) => ({
  type: Types.POST_CHANGE_POSITION_LESSON,
  newPosition,
  courseUuid,
  lessonUuid,
  sectionUuid,
});

export const dataTemplate = (prams: IPramsDataTemplate) => ({
  type: Types.GET_DATA_TEMPLATE,
  prams,
});

export const listUserCourse = (prams: IPramsListUserCourse) => ({
  type: Types.GET_LIST_USER_COURSE,
  prams,
});

export const updateFilterListUserCourse = (
  data: IParamsFilterListUserCourse
) => ({
  type: Types.UPDATE_PARAMS_FILTER_LIST_USER_COURSE,
  data,
});

export const changeActive = (payLoad: IPayloadChangeActive) => ({
  type: Types.POST_CHANGE_ACTIVE,
  payLoad,
});

export const downLoadCSV = (
  payLoad: IPayloadDownLoadCSV,
  onSuccess: (value: any) => void
) => ({
  type: Types.POST_DOWNLOAD_CSV,
  payLoad,
  onSuccess,
});

export const getListCompanyTemplates = (params: IPageTemplates) => ({
  type: Types.GET_LIST_COMPANY_TEMPLATE,
  params,
});

export const updateTemplates = (
  payload: IChangeNameTemplates,
  onSuccess?: (value?: any) => void
) => ({
  type: Types.POST_UPDATE_TEMPLATE,
  payload,
  onSuccess,
});

export const showDetailTemplate = (
  uuid: string,
  onSuccess: (value: any) => void,
  onError?: () => void
) => ({
  type: Types.GET_SHOW_DETAIL_TEMPLATE,
  uuid,
  onSuccess,
  onError,
});

export const deleteTemplate = (
  uuid: string,
  onSuccess?: (value: any) => void
) => ({
  type: Types.POST_DELETE_TEMPLATE,
  uuid,
  onSuccess,
});

export const getSharedTemPlate = (
  uuid: string,
  onSuccess?: (value: any) => void,
  onError?: () => void
) => ({
  type: Types.GET_SHARED_TEMPLATE,
  uuid,
  onSuccess,
  onError,
});

export const postSharedTemPlate = (
  uuid: string,
  onSuccess?: (value: any) => void
) => ({
  type: Types.POST_SHARED_TEMPLATE,
  uuid,
  onSuccess,
});

export const statusImportCSV = (
  token: string,
  onSuccess?: (value: IStatusImport) => void
) => ({
  type: Types.GET_STATUS_IMPORT_CSV_COURSE,
  onSuccess,
  token,
});

export {
  handleRenameMembersite,
  installTemplate,
  restoresSettingStep,
  fetchListRestores,
  fetchListMembersiteAccess,
  createAccessMembersite,
  updateAccessMembersite,
  toggleModalAccessLink,
  handleMembersiteStepUuidSelected,
};
