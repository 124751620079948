import { IPageTemplates } from '../../../Clients/templates/interface';

export interface ParamsFilterTemplate {
  per_page: number;
  page: number;
  page_template_type: number;
  name: string;
  total: number;
}

export const paramsFilterListTemplateDefault: ParamsFilterTemplate = {
  per_page: 24,
  page: 1,
  page_template_type: 0,
  name: '',
  total: 0,
};

interface StepSettingParams {
  og_facebook_title: string;
  og_facebook_description: string;
  og_facebook_image: string;
  og_twitter_title: string;
  og_twitter_description: string;
  og_twitter_image: string;
}
const stepSettingParamsInitial = {
  og_facebook_title: '',
  og_facebook_description: '',
  og_facebook_image: '',
  og_twitter_title: '',
  og_twitter_description: '',
  og_twitter_image: '',
};

interface ListRestore {
  landing_file_url: string;
  name: string;
  page_template_id: number;
  funnel_step_subcategory_id: number;
  position?: number;
  restores: any[];
  uuid: string;
}

const listRestoreInitial = {
  landing_file_url: '',
  name: '',
  page_template_id: 1,
  funnel_step_subcategory_id: 1,
  position: 1,
  restores: [],
  uuid: '',
};

interface ListMembersiteAccess {
  access: any[];
  domain: any;
  name: string;
  sub_domain: string;
  uuid: string;
}

const initialListMembersiteAccess = {
  access: [],
  domain: null,
  name: '',
  sub_domain: '',
  uuid: '',
};

export enum MembersiteDetailLoading {
  HIDE = 0,
  EDIT_STEP = 1,
  LIST_TEMPLATE = 2,
  SETTING_ADVANCE = 3,
}

export interface IPageLessons {
  uuid: string;
  name: string;
  path: string;
  html_content_url: string;
  position: number;
}

export interface IPageSection {
  uuid: string;
  name: string;
  position: number;
  course_lessons: IPageLessons[];
}

export interface IDetailCourse {
  uuid: string;
  name: string;
  landing_file_url: string;
  course_sections: IPageSection[];
  domain: string;
}

export interface IDetailLessons {
  uuid: string;
  name: string;
  landing_file_url: string;
  position: string;
  path: string;
}

export interface IFunnelStepSubcategories {
  id: number;
  name: string;
  description: string | null;
}
export interface IDataTemplate {
  uuid: string;
  name: string;
  landing_file_url: string;
  funnel_step_subcategories: IFunnelStepSubcategories[];
  type: number;
}

export interface IParamsFilterListUserCourse {
  page: number;
  perPage: number;
  uuid: string;
  search: string;
  CV_start_date: string;
  CV_end_date: string;
  total: number;
  typeFilter: number;
}

export interface IMembersiteUsers {
  uuid: string;
  user_uuid: string;
  full_name: string;
  email: string;
  created_at: string;
  active: number;
}

export interface IListUserCourse {
  paramsFilter: IParamsFilterListUserCourse;
  listUser: {
    uuid: string;
    name: string;
    membersite_users: IMembersiteUsers[];
  };
}

const initialListUserCourse = {
  paramsFilter: {
    page: 1,
    perPage: 20,
    uuid: '',
    search: '',
    CV_start_date: '',
    CV_end_date: '',
    total: 0,
    typeFilter: 0,
  },
  listUser: {
    uuid: '',
    name: '',
    membersite_users: [],
  },
};

interface IListCompanyTemplates {
  data: IDataTemplate[];
  total: number;
  params: IPageTemplates;
}

export interface MembersiteManagement {
  paramsFilter: ParamsFilterTemplate;
  stepSettingParams: StepSettingParams;
  listRestores: ListRestore;
  loading: boolean;
  loadingComponent: MembersiteDetailLoading;
  listMembersiteAccess: ListMembersiteAccess;
  loadingModalAccessLink: boolean;
  isOpenModalAccessLink: boolean;
  membersiteStepUuidSelected: string;
  detailCourse: IDetailCourse;
  detailLessons: IDetailLessons;
  skeleton: boolean;
  dataTemplate: IDataTemplate[];
  listUserCourse: IListUserCourse;
  listCompanyTemplates: IListCompanyTemplates;
}

export const dataMembersiteDetailDefault = {
  name: '',
  uuid: '',
  funnel_domain: '',
  sub_domain: '',
  channels: [],
  steps: [],
};

export const initialMembersiteManagement: MembersiteManagement = {
  paramsFilter: paramsFilterListTemplateDefault,
  listRestores: listRestoreInitial,
  stepSettingParams: stepSettingParamsInitial,
  loading: false,
  loadingComponent: MembersiteDetailLoading.HIDE,
  listMembersiteAccess: initialListMembersiteAccess,
  loadingModalAccessLink: false,
  isOpenModalAccessLink: false,
  membersiteStepUuidSelected: '',
  detailCourse: {
    uuid: '',
    name: '',
    landing_file_url: '',
    course_sections: [],
    domain: '',
  },
  detailLessons: {
    uuid: '',
    name: '',
    landing_file_url: '',
    position: '',
    path: '',
  },
  skeleton: false,
  dataTemplate: [],
  listUserCourse: initialListUserCourse,
  listCompanyTemplates: {
    data: [],
    total: 0,
    params: {
      page_template_type: 0,
      page: 1,
      per_page: 24,
      name: '',
    },
  },
};
