// Project: 7612770651107e1659371833c56a9b25e733c4615968bb7c5582cdc92aa6249b
import { combineReducers } from 'redux';
import { loadingReducer } from './loadingReducers';
import myPageReducer from './myPage';
import accountSubUserReducer from './myPage/accountSubUser';
import manageUserReducer from './manageUser';
import accountSettingReducer from './accountSetting';
import alternativeCharacterReducer from './mail/alternativeCharacter';
import conditionMailReducer from './mail/conditionReducer';
import funnelManagementReducer from './funnelManagement';
import summaryFunnelReducer from './funnel/summaryFunnel';
import summaryMembersiteReducer from './membersite/summaryMembersite';
import selectedGroupFunnelReducer from './funnel/selectedGroup';
import groupFunnelReducer from './funnel/groupFunnels';
import pageListFunnelReducer from './funnel/pageList';
import pageListMembersiteReducer from './membersite/pageList';
import { globalPopupReducer } from './popupActionReducers';
import allTemplateReducer from './funnel/allTemplates';
import groupMembersiteReducer from './membersite/groupMembersites';
import membersiteManagementReducer from './membersiteManagement';
import affiliateManagementReducer from './affiliate';
import productManagementReducer from './product';
import accessFunnelReducer from './accessFunnel';
import tagManagementReducer from './tagManagement';
import commonReducer from './common';
import templateReducer from './template';
import timerReducer from './timer';
import eventReducer from './event';
import pageVerionsReducer from './funnel/pageVersions';
import funnelStepMetadataReducer from './funnel/funnelStepMetadata';

const rootReducer = combineReducers({
  isLoading: loadingReducer,
  myPage: myPageReducer,
  manageUser: manageUserReducer,
  accountSetting: accountSettingReducer,
  accountSubUser: accountSubUserReducer,
  alternativeCharacter: alternativeCharacterReducer,
  conditionMail: conditionMailReducer,
  funnel: funnelManagementReducer,
  membersite: membersiteManagementReducer,
  summaryFunnel: summaryFunnelReducer,
  summaryMembersite: summaryMembersiteReducer,
  selectedGroupFunnel: selectedGroupFunnelReducer,
  groupFunnelsList: groupFunnelReducer,
  groupMembersitesList: groupMembersiteReducer,
  pageListFunnel: pageListFunnelReducer,
  pageListMembersite: pageListMembersiteReducer,
  globalPopupReducer: globalPopupReducer,
  allTemplates: allTemplateReducer,
  affiliate: affiliateManagementReducer,
  product: productManagementReducer,
  accessFunnel: accessFunnelReducer,
  tag: tagManagementReducer,
  common: commonReducer,
  template: templateReducer,
  timer: timerReducer,
  event: eventReducer,
  pageVersions: pageVerionsReducer,
  funnelStepMetadata: funnelStepMetadataReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
