import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { NotFoundPage, AccessDenied } from '../containers/ErrorPage';
import { ROUTER } from './router';
import { TMarkLoading } from '../components/molecules/TMarkLoading';

interface RouterComponent {
  exact: boolean;
  path: string;
  component: any;
  isPrivate: boolean;
  key: string;
}

const RouteWithSubRoutes: React.FC<RouterComponent> = (props) => {
  const { exact, path, component, isPrivate } = props;
  if (isPrivate) {
    return <PrivateRoute exact={exact} path={path} component={component} />;
  }
  return <Route exact={exact} path={path} component={component} />;
};

const RenderRouter = () => {
  return (
    <Suspense fallback={<TMarkLoading loading />}>
      <Switch>
        {ROUTER.map((route) => (
          <RouteWithSubRoutes {...route} key={route.path} />
        ))}
        <Route path="/no-right">
          <AccessDenied />
        </Route>

        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default RenderRouter;
