import { API } from './api';
import axiosInstance from '../';
import { ParamsListProduct } from './interface';

export const getPayMethod = () => {
  return axiosInstance.get(API.getPayMethod());
};

export const getGroupName = () => {
  return axiosInstance.get(API.getGroupList());
};

export const createProduct = (data: any) => {
  return axiosInstance.post(API.createProduct(), data);
};

export const getListGroupProducts = (params: ParamsListProduct) => {
  const { currentPage, pageSize, groupUuids, sortName, sortType, sortGroup } =
    params;
  return axiosInstance.get(
    API.getListGroupProducts(
      currentPage,
      pageSize,
      groupUuids,
      sortName,
      sortType,
      sortGroup
    )
  );
};

export const deleteProduct = (params: string) => {
  const parameter = {
    product_uuid: params,
  };
  return axiosInstance.post(API.deleteProduct(), parameter);
};

export const deleteGroupProduct = (params: string) => {
  return axiosInstance.post(API.deleteGroupProduct(), params);
};

export const copyGroupProduct = (params: string) => {
  return axiosInstance.post(API.copyGroupProduct(), params);
};

export const copyProduct = (params: string) => {
  return axiosInstance.post(API.copyProduct(), params);
};

export const renameGroupProduct = (params: any) => {
  return axiosInstance.post(API.renameGroupProduct(), params);
};

export const renameProduct = (params: any) => {
  return axiosInstance.post(API.renameProduct(), params);
};

export const getDetailProductByUUID = (uuid: string) =>
  axiosInstance.get(API.getDetailProductByUUID(uuid));

export const updateProductByUUID = (data: any) =>
  axiosInstance.post(API.updateProductByUUID(), data);

export const getStripePricesProduct = () =>
  axiosInstance.get(API.getStripePricesProduct());

export const getThinkificWithProduct = () => {
  return axiosInstance.get(API.getThinkificWithProduct());
};

export const getBundleByThinkificId = (id: string) => {
  return axiosInstance.get(API.getBundleByThinkificId(id));
};

export const getOptionMemberSite = () => {
  return axiosInstance.get(API.getOptionMemberSite());
};
