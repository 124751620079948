export interface ISystemDomain {
  sub_domain: string;
  uuid: string;
}

export interface IPages {
  name: string;
  uuid: string;
}

export interface IAllPageFunnel {
  name: string;
  uuid: string;
  pages: IPages[];
}

export interface ZoneRecord {
  id?: number;
  type: string;
  name: string;
  content: string;
  priority: string;
  ttl?: number;
  parent_id?: string | null;
  created_at: string;
  regions: string[];
  system_record: boolean;
  updated_at: string;
  zone_id: string;
}

export interface AccountSetting {
  allPageFunnel: IAllPageFunnel[];
  listEmailIntegrations: {
    currentPage: number;
    totalPage: number;
    totalItemInPage: number;
    totalItem: number;
    data: any;
    emailIntegrationsDetail: any;
  };
  listFunnelDomain: {
    loading: boolean;
    currentPage: number;
    totalPage: number;
    totalItemInPage: number;
    totalItem: number;
    data: any;
    system_domain: ISystemDomain;
  };
  paymentHistory: {
    loading: boolean;
    data: any;
    currentPage: number;
    totalPage: number;
    totalItemInPage: number;
    totalItem: number;
    sortType: 'desc' | 'asc' | '';
    sortName: 'datetime' | '';
  };
  funnelDomainDetail: any;
  error: {
    status: boolean;
    message: string;
  };
  listFacebookIntegration: any[];
  listFacebookPixel: any[];
  domainDetailRecord: {
    records: ZoneRecord[];
    isLoading: boolean;
  };
}

export const initialState: AccountSetting = {
  listEmailIntegrations: {
    currentPage: 1,
    totalPage: 10,
    totalItemInPage: 20,
    totalItem: 60,
    data: [],
    emailIntegrationsDetail: undefined,
  },
  listFunnelDomain: {
    loading: false,
    currentPage: 1,
    totalPage: 10,
    totalItemInPage: 20,
    totalItem: 60,
    data: [],
    system_domain: {
      sub_domain: '',
      uuid: '',
    },
  },
  paymentHistory: {
    loading: false,
    data: [],
    currentPage: 1,
    totalPage: 10,
    totalItemInPage: 20,
    totalItem: 60,
    sortName: 'datetime',
    sortType: 'desc',
  },
  funnelDomainDetail: null,
  allPageFunnel: [],
  error: {
    status: false,
    message: '',
  },
  listFacebookIntegration: [],
  listFacebookPixel: [],
  domainDetailRecord: {
    records: [],
    isLoading: false,
  },
};
