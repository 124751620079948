import i18next from 'i18next';

export interface DataListUserBasic {
  data: any[];
  meta: {
    current_page: number;
    per_page: number;
    total: number;
  };
}

export enum TypeGroupListDataUser {
  All = '',
  Product = 'PRODUCT',
  MailList = 'MAIL_LIST',
}

export const LINK_BY_PAYMENT_METHODS = {
  ONE_TIME: 'ONE_TIME',
  MULTI_TIME: 'SUBSCRIPTION',
  INSTALLMENT: 'INSTALLMENT',
};

export enum STATUS_FORM_POPUP {
  EDIT_PAYMENT_METHOD = 'EDIT_PAYMENT_METHOD',
  EDIT_PAYMENT_STATUS = 'EDIT_PAYMENT_STATUS',
}

interface ListOption {
  value: string;
  title: string;
  disabled?: boolean;
}
export interface TypeDataFormPopup {
  isShow: boolean;
  status: STATUS_FORM_POPUP;
  listOptions: ListOption[];
  titleModal: string;
  initialValues: ValuesFormPopup;
  name: string;
  idProduct: number;
}

export interface ValuesFormPopup {
  paymentMethod: string;
  paymentStatus: string;
}
interface ItemDataPaymentMethod {
  id: number;
  name: string;
  payment_method?: any;
}

export const dataPaymentMethodToParams = (data: any) => {
  if (data.length > 0) {
    return data.map((item: ItemDataPaymentMethod) => {
      return {
        value: item.id,
        title: item?.payment_method?.name,
      };
    });
  }
  return [];
};

export const LIST_PAYMENT_METHOD: ListOption[] = [
  { value: 'paypal', title: 'Paypal' },
  { value: 'banking', title: 'Banking' },
  { value: 'GMO', title: 'GMO' },
  { value: 'stripe', title: 'Stripe' },
];

export const LIST_PAYMENT_STATUS: ListOption[] = [
  { value: 'SUCCESS', title: i18next.t('success') },
  { value: 'NEW', title: i18next.t('new') },
];

export const dataPaymentStatusToParams = (value: string) => {
  return LIST_PAYMENT_STATUS.map((item: ListOption) => {
    if (value === item.value)
      return {
        ...item,
        disabled: true,
      };
    return item;
  });
};

export const getTextPaymentStatus = (status: string) => {
  return LIST_PAYMENT_STATUS.findIndex((e: ListOption) => e.value === status);
};

export const initialDataFormPopup: TypeDataFormPopup = {
  isShow: false,
  status: STATUS_FORM_POPUP.EDIT_PAYMENT_METHOD,
  listOptions: LIST_PAYMENT_METHOD,
  titleModal: '',
  initialValues: {
    paymentMethod: '',
    paymentStatus: '',
  },
  name: 'paymentMethod',
  idProduct: 1,
};
export const LINK_BY_MAIL_USER = {
  LIST: 'MAIL_LIST',
  STEP: 'STEP_MAIL',
  REMIND: 'REMIND_MAIL',
};

export const convertMoneyJapan = (moneyData: any) => {
  const money = moneyData || 0;
  if (money >= 10000) {
    if (money % 10000 === 0) {
      return {
        value: convertCurrencyYen(Number(money / 10000).toFixed(1)),
        unit: i18next.t('manageUser.unitMoney.tenThousandYen'),
      };
    }
    const newMoney = Number(money / 10000).toFixed(1);
    return {
      value: convertCurrencyYen(newMoney),
      unit: i18next.t('manageUser.unitMoney.tenThousandYen'),
    };
  }
  return {
    value: convertCurrencyYen(money),
    unit: i18next.t('manageUser.unitMoney.OneYen'),
  };
};

export const convertCurrencyYen = (value: any) => {
  return new Intl.NumberFormat('en-IN').format(value);
};

export const formatMoney = (value: any) => {
  if (!value) return 'ー';
  return new Intl.NumberFormat('en-US', { style: 'decimal' }).format(value);
};
