import {
  changeNameEmailInReducer,
  deleteMailToBlackListInReducer,
} from '../../../utilities/common.utilities';
import * as Types from '../../actions/manageUser/types';
import { ManageUser, initialState } from './interface';

export default function manageUserReducer(
  state: ManageUser = initialState,
  action: any
): ManageUser {
  switch (action.type) {
    case Types.FETCH_USER_ON_UPDATE_PAGE_SUCCESS:
      return {
        ...state,
        userInfoUpdate: {
          ...state.userInfoUpdate,
          data: action.data,
        },
        error: {
          status: false,
          message: '',
        },
      };
    case Types.FETCH_USER_ON_UPDATE_PAGE_ERROR:
      return {
        ...state,
        userInfoUpdate: {
          ...state.userInfoUpdate,
          error: action.error,
          messageError: action.messageError,
        },
      };
    case Types.FETCH_STATISTICS_USER_SUCCESS:
      return {
        ...state,
        statisticsUser: {
          ...state.statisticsUser,
          currentPage: action.data.meta.current_page,
          totalPage: action.data.meta.last_page,
          totalItemInPage: action.data.meta.per_page,
          totalItem: action.data.meta.total,
          data: action.data.data.transactions,
          typePage: action.data.typePage,
          userInfo: action.data.data.user,
        },
        error: {
          status: false,
          message: '',
        },
      };
    case Types.FETCH_MAIL_LIST_SUCCESS:
      return {
        ...state,
        mailList: {
          ...state.mailList,
          currentPage: action.data.meta.current_page,
          totalPage: action.data.meta.last_page,
          totalItemInPage: action.data.meta.per_page,
          totalItem: action.data.meta.total,
          data: action.data.data.data,
          typePage: action.data.typePage,
          userInfo: action.data.data.user,
        },
        error: {
          status: false,
          message: '',
        },
      };
    case Types.FETCH_ALL_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        methodPayment: action.data,
        error: {
          status: false,
          message: '',
        },
      };

    case Types.EDIT_NAME_MAIL_SUCCESS:
      return {
        ...state,
        mailList: {
          ...state.mailList,
          data: changeNameEmailInReducer(
            state.mailList.data,
            action.data.uuidStepMail,
            action.data.newNameEmail
          ),
        },
        error: {
          status: false,
          message: '',
        },
      };
    case Types.REMOVE_USER_FORM_MAIL_LIST_SUCCESS:
      return {
        ...state,
        error: {
          status: false,
          message: '',
        },
      };
    case Types.STOP_SEND_STEP_MAIL_SUCCESS:
      return {
        ...state,
        error: {
          status: false,
          message: '',
        },
      };
    case Types.FETCH_DATA_BLACK_LIST_SUCCESS:
      return {
        ...state,
        blackList: {
          ...state.blackList,
          data: action.data,
        },
        error: {
          status: false,
          message: '',
        },
      };
    case Types.DELETE_MAIL_TO_BLACK_LIST_SUCCESS:
      return {
        ...state,
        blackList: {
          ...state.blackList,
          data: deleteMailToBlackListInReducer(
            state.blackList.data,
            action.data.uuidMail
          ),
        },
        error: {
          status: false,
          message: '',
        },
      };
    case Types.ADD_MAIL_TO_BLACK_LIST_SUCCESS:
      return {
        ...state,
        popupErrorAddEmailBlackList: {
          isShow: action.emailFails.length > 0,
          emailFails: action.emailFails,
          numberEmail: action.numberEmail,
        },
      };
    case Types.CLOSE_POPUP_ERROR_ADD_EMAIL_BLACKLIST:
      return {
        ...state,
        popupErrorAddEmailBlackList: {
          isShow: false,
          emailFails: [],
          numberEmail: 0,
        },
      };
    case Types.SHOW_LOADING_GET_PAYMENT_METHOD:
      return {
        ...state,
        loadingGetPaymentMethod: true,
      };
    case Types.HIDE_LOADING_GET_PAYMENT_METHOD:
      return {
        ...state,
        loadingGetPaymentMethod: false,
      };
    case Types.GET_LIST_PRODUCTS_SUCCESS:
      return {
        ...state,
        listProduct: action.data,
      };
    case Types.GET_MAILS_LIST_SUCCESS:
      return {
        ...state,
        mailsList: action.data,
      };

    case Types.GET_GROUP_NAME_MAIL_SUCCESS:
      return {
        ...state,
        groupNameMail: action.data,
      };

    case Types.GET_GROUP_NAME_PRODUCT_SUCCESS:
      return {
        ...state,
        groupNameProduct: action.data,
      };

    case Types.SET_LIST_ITEM_SELECT:
      return {
        ...state,
        itemSelectFilterUser: action.data,
      };
    case Types.CALL_API_ERROR:
      return {
        ...state,
        error: {
          status: true,
          message: action.error,
        },
      };
    case Types.GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: action.data,
      };
    default:
      return state;
  }
}
