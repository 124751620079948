// Project: 7612770651107e1659371833c56a9b25e733c4615968bb7c5582cdc92aa6249b
// use library node_modules
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

// use helper and config, services
import { renameFunnelListItem } from '../../../../../../../store/actions/funnel/funnelList';
import { Link } from 'react-router-dom';
import {
  fetchPageListFunnel,
  TypePageList,
} from '../../../../../../../store/actions/funnel/pageList';
import { usePageListFunnel } from '../../../../../../../hooks';
import { SortField } from '../../types';

export type SelectedData = {
  id: string;
  childId: string;
  name: string;
};

interface EditInlineProps {
  data: SelectedData;
  evenOutside: boolean;
  onChangeEvent: () => void;
}

const FieldEditInLine: React.FC<EditInlineProps> = ({
  data,
  evenOutside,
  onChangeEvent,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [pageListFunnel] = usePageListFunnel();
  const { payload } = pageListFunnel;
  const [eventInside, setEventInside] = useState<boolean>(false);
  const focusRef = useRef<HTMLInputElement>(null);
  const [currentName, setCurrentName] = useState<string>(data.name);

  useEffect(() => {
    setCurrentName(data.name);
  }, [data.name]);

  useEffect(() => {
    if (eventInside) {
      focusRef.current?.focus();
    }
  }, [eventInside]);

  useEffect(() => {
    if (evenOutside) {
      setEventInside(true);
    }
  }, [evenOutside]);

  const onchangeCurrentName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCurrentName(value);
  };

  const handleFlagEvent = async () => {
    setEventInside(false);
    if (isEmpty(currentName)) {
      toast.error(t('funnel.list.message.funnelNameCannotBeLeftBlank'));
      setCurrentName(data.name);
    } else {
      if (currentName != data.name) {
        dispatch(
          renameFunnelListItem(
            {
              name: currentName,
              uuid: data.id,
            },
            (res) => {
              toast.success(res.message);
              window.scrollTo({
                top: 70,
                left: 100,
                behavior: 'smooth',
              });
              setCurrentName(currentName);
              onChangeEvent();
              dispatch(
                fetchPageListFunnel({
                  ...payload,
                  type: TypePageList.PAGE_LIST,
                  page: 1,
                  perPage: 20,
                  group_uuids: '',
                  flag_favorite: '',
                  sort_name: SortField.DATETIME,
                })
              );
            },
            () => {
              setCurrentName(data.name);
            }
          )
        );
      }
    }
  };

  const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFlagEvent();
    event.preventDefault();
  };

  return (
    <>
      {eventInside && (
        <WrapperInput
          className="form-control"
          autoComplete="off"
          tabIndex={-1}
          type="text"
          value={currentName}
          ref={focusRef}
          onBlur={(e: any) => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              handleOnBlur(e);
            }
          }}
          onChange={onchangeCurrentName}
        />
      )}

      {!eventInside && (
        <WrapperElement
          title={data.name}
          className="over-text-dot dot-1"
          to={`/funnel-detail/${data.id}/edit`}
        >
          {currentName}
        </WrapperElement>
      )}
    </>
  );
};

const WrapperElement = styled(Link)`
  width: auto;
  height: 35px;
  line-height: 35px;
  text-decoration: none;
  color: var(--t-text-color);
  &:hover {
    color: var(--t-main-color);
    text-decoration: underline;
  }
`;

const WrapperInput = styled.input`
  left: 40px;
  width: 75%;
  top: 26px;
  height: 34px;
  padding: 0px 7px 1px 5px;
  background-color: #fff;
  border: 1px solid var(--t-main-color) !important;
  &:hover {
    border: 1px solid var(--t-main-color) !important;
  }
  &:focus {
    box-shadow: 0 0 0 -0.8rem #81d1bf !important;
  }
`;

export default FieldEditInLine;
