export const RENAME_PRODUCT_LIST = 'RENAME_PRODUCT_LIST';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_GROUP_PRODCUT = 'DELETE_GROUP_PRODCUT';
export const COPY_GROUP_PRODCUT = 'COPY_GROUP_PRODUCT';
export const COPY_PRODUCT = 'COPY_PRODUCT';
export const RENAME_GROUP_PRODUCT = 'RENAME_GROUP_PRODUCT';
export const UPDATE_PRODUCT_BY_UUID = 'UPDATE_PRODUCT_BY_UUID';
export const GET_STRIPE_PRICES_PRODUCT = 'GET_STRIPE_PRICES_PRODUCT';
export const GET_PAY_METHOR = 'GET_PAY_METHOR';
export const GET_GROUP_NAME = 'GET_GROUP_NAME';
export const GET_LIST_GROUP_PRODUCT = 'GET_LIST_GROUP_PRODUCT';

export const GET_DETAIL_PRODUCT_BY_UUID = 'GET_DETAIL_PRODUCT_BY_UUID';
export const GET_DETAIL_PRODUCT_SUCCESS = 'GET_DETAIL_PRODUCT_SUCCESS';
