import { call, put, takeLatest } from 'redux-saga/effects';
import * as myPageService from '../../Clients/mypage';
import * as Types from '../actions/myPage/types';
import * as ActionTypes from '../actionTypes/loadingActionTypes';
import { SubUserInvite } from '../reducers/myPage/accountSubUserInterface';
import { getErrorResponse } from '../../helper/httpClient.helper';

interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
}

function* getSubUsers() {
  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(myPageService.getSubUsers);
    yield put({ type: ActionTypes.HIDE_LOADING });

    yield put({
      type: Types.GET_SUB_USERS_SUCCESS,
      data: response.data.data,
    });
  } catch (e: any) {
    yield put({ type: ActionTypes.HIDE_LOADING });
    yield put({
      type: Types.GET_SUB_USERS_ERROR,
      error: getErrorResponse(e.response),
    });
  }
}

function* getAllPermissions() {
  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      myPageService.getSubUserPermissions
    );
    yield put({ type: ActionTypes.HIDE_LOADING });

    yield put({
      type: Types.SUB_USER_GET_ALL_PERMISSIONS_SUCCESS,
      data: response.data.data,
    });
  } catch (e: any) {
    yield put({ type: ActionTypes.HIDE_LOADING });
    yield put({
      type: Types.SUB_USER_GET_ALL_PERMISSIONS_ERROR,
      error: getErrorResponse(e.response),
    });
  }
}

function* getPermissionsForSubUser(action: {
  type: string;
  data: { uuid: string };
}) {
  const { data } = action;
  try {
    yield put({
      type: Types.SUB_USER_FOCUS_GET_PERMISSIONS_LOADING,
    });
    const response: APIResponseType = yield call(
      myPageService.getSubUserPermissions,
      data
    );

    yield put({
      type: Types.SUB_USER_FOCUS_GET_PERMISSIONS_SUCCESS,
      data: response.data.data,
    });
  } catch (e: any) {
    yield put({
      type: Types.SUB_USER_FOCUS_GET_PERMISSIONS_ERROR,
      error: getErrorResponse(e.response),
    });
  }
}

function* updatePermissionsForSubUser(action: {
  type: string;
  data: { uuid: string; role: any[]; is_full_role: boolean };
  onSuccess: (data: any) => void;
  onError: (data: string) => void;
}) {
  const { data } = action;
  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      myPageService.updatePermissionsForSubUser,
      data
    );

    yield put({ type: ActionTypes.HIDE_LOADING });
    action.onSuccess(response.data.data);
  } catch (e: any) {
    const error = getErrorResponse(e.response);
    action.onError(error.message || e.message || '');
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* removeSubUser(action: {
  type: string;
  data: { uuid: string };
  onSuccess: (data: any) => void;
  onError: (data: string) => void;
}) {
  const { data } = action;
  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      myPageService.removeSubUser,
      data
    );

    yield put({ type: ActionTypes.HIDE_LOADING });
    action.onSuccess(response.data.data);
  } catch (e: any) {
    yield put({ type: ActionTypes.HIDE_LOADING });
    const error = getErrorResponse(e.response);
    action.onError(error.message || e.message || '');
  }
}

function* sendInviteSubUser(action: {
  type: string;
  data: SubUserInvite;
  onSuccess: (data: any) => void;
  onError: (data: string) => void;
}) {
  const { data } = action;
  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      myPageService.inviteSubUser,
      data
    );

    yield put({ type: ActionTypes.HIDE_LOADING });
    action.onSuccess(response.data.data);
  } catch (e: any) {
    yield put({ type: ActionTypes.HIDE_LOADING });
    const error = getErrorResponse(e.response);
    action.onError(error.message || e.message || '');
  }
}

function* resendInviteSubUser(action: {
  type: string;
  data: { uuid: string };
  onSuccess: (data: any) => void;
  onError: (data: string) => void;
}) {
  const { data } = action;
  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      myPageService.resendInviteSubUser,
      data
    );

    yield put({ type: ActionTypes.HIDE_LOADING });
    action.onSuccess(response.data.data);
  } catch (e: any) {
    yield put({ type: ActionTypes.HIDE_LOADING });
    const error = getErrorResponse(e.response);
    action.onError(error.message || e.message || '');
  }
}

function* checkInvitationTokenValid(action: {
  type: string;
  data: { invitation_token: string };
  onSuccess: (data: any) => void;
  onError: (data: string) => void;
}) {
  const { data } = action;
  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      myPageService.checkInvitationTokenValid,
      data
    );

    yield put({ type: ActionTypes.HIDE_LOADING });
    action.onSuccess(response.data.data);
  } catch (e: any) {
    yield put({ type: ActionTypes.HIDE_LOADING });
    const error = getErrorResponse(e.response);
    action.onError(error.message || e.message || '');
  }
}

function* getSubUserInformationInvite(action: {
  type: string;
  data: { invitation_token: string };
}) {
  const { data } = action;

  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      myPageService.getSubUserInformationInvite,
      data
    );
    yield put({ type: ActionTypes.HIDE_LOADING });

    yield put({
      type: Types.GET_SUB_USERS_INFORMATION_INVITE_SUCCESS,
      data: response.data.data,
    });
  } catch (e: any) {
    yield put({ type: ActionTypes.HIDE_LOADING });
    yield put({
      type: Types.GET_SUB_USERS_INFORMATION_INVITE_ERROR,
      error: getErrorResponse(e.response) || e.message,
    });
  }
}

function* acceptSubUserInvitation(action: {
  type: string;
  data: { invitation_token: string };
  onSuccess: (data: any) => void;
  onError: (data: string) => void;
}) {
  const { data } = action;
  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      myPageService.acceptSubUserInvitation,
      data
    );

    yield put({ type: ActionTypes.HIDE_LOADING });
    action.onSuccess(response.data.data);
  } catch (e: any) {
    yield put({ type: ActionTypes.HIDE_LOADING });
    const error = getErrorResponse(e.response);
    action.onError(error.message || e.message || '');
  }
}

function* signUpSubUser(action: {
  type: string;
  data: {
    user_name: string;
    email: string;
    password: string;
    url_register: string;
  };
  onSuccess: (data: any) => void;
  onError: (data: string) => void;
}) {
  const { data } = action;
  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      myPageService.signUpSubUser,
      data
    );

    yield put({ type: ActionTypes.HIDE_LOADING });
    action.onSuccess(response.data.data);
  } catch (e: any) {
    yield put({ type: ActionTypes.HIDE_LOADING });
    const error = getErrorResponse(e.response);
    action.onError(error.message || e.message || '');
  }
}

function* logout(action: {
  type: string;
  onSuccess: (data: any) => void;
  onError: (data: string) => void;
}) {
  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(myPageService.logout);

    yield put({ type: ActionTypes.HIDE_LOADING });
    action.onSuccess(response.data.data);
  } catch (e: any) {
    yield put({ type: ActionTypes.HIDE_LOADING });
    const error = getErrorResponse(e.response);
    action.onError(error.message || e.message || '');
  }
}

function* getRoleCurrentUser(action: {
  type: string;
  onSuccess: (data: any) => void;
  onError: (data: string) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      myPageService.getRoleCurrentUser
    );
    yield put({
      type: Types.SET_ROLE_CURRENT_USER,
      data: response.data.data,
    });
    action.onSuccess(response.data.data);
  } catch (error: any) {
    action.onError(error.response);
  }
}

export default function* accountSubUserSaga() {
  yield takeLatest(Types.GET_SUB_USERS, getSubUsers);
  yield takeLatest(Types.SUB_USER_GET_ALL_PERMISSIONS, getAllPermissions);
  yield takeLatest(
    Types.SUB_USER_FOCUS_GET_PERMISSIONS,
    getPermissionsForSubUser
  );
  yield takeLatest(
    Types.UPDATE_PERMISSIONS_SUB_USER,
    updatePermissionsForSubUser
  );
  yield takeLatest(Types.REMOVE_SUB_USER, removeSubUser);
  yield takeLatest(Types.SEND_INVITE_SUB_USER, sendInviteSubUser);
  yield takeLatest(Types.RESEND_INVITE_SUB_USER, resendInviteSubUser);
  yield takeLatest(
    Types.CHECK_INVITATION_TOKEN_VALID,
    checkInvitationTokenValid
  );
  yield takeLatest(
    Types.GET_SUB_USERS_INFORMATION_INVITE,
    getSubUserInformationInvite
  );
  yield takeLatest(Types.SUB_USER_ACCEPT_INVITATION, acceptSubUserInvitation);
  yield takeLatest(Types.SUB_USER_SIGN_UP, signUpSubUser);
  yield takeLatest(Types.GET_ROLE_CURRENT_USER, getRoleCurrentUser);
  yield takeLatest(Types.LOGOUT, logout);
}
