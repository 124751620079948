import { put, takeLatest, call } from 'redux-saga/effects';
import * as commonServices from '../../Clients/common';
import * as Types from '../actions/common/types';
import * as TypesLoading from './../actionTypes/loadingActionTypes';
import { handleMessage } from '../../utilities/common.utilities';
import { EStatusHttp } from '../../Clients/interfaces';

interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
}

// include: categories of funnel, categories of membersite
function* getAllCategoriesPageTemplate(action: any) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(() =>
      commonServices.getAllCategoriesPageTemplate(action.params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_ALL_CATEGORIES_PAGE_TEMPLATE_SUCCESS,
        data: response.data.data,
      });
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

export default function* authSaga() {
  yield takeLatest(
    Types.GET_ALL_CATEGORIES_PAGE_TEMPLATE,
    getAllCategoriesPageTemplate
  );
}
