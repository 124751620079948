import { IPayLoadGroupFunnelList } from '../../store/actions/funnel/group';
import { PayloadPageListFunnel } from '../../store/actions/funnel/pageList';
import { getPath, getQueryParams } from '../helper';

export const endPoints = {
  getAllGroup: () => getPath(`funnel/groups?type_id=1`),
  createFunnel: () => getPath(`funnel/create`),
  createMembersite: () => getPath(`membersite/create`),
  getSetting: (id?: string) => getPath(`funnel/setting/${id}`),
  delete: () => getPath(`funnel/delete`),
  update: () => getPath(`funnel/setting`),
  getSummaryFunnel: () => getPath(`summary/group/funnels?type_id=1`),
  createFunnelGroup: () => getPath(`group/create`),
  createCourseGroup: () => getPath(`group/create?type=COURSE`),
  getPageListGroupFunnels: (params: IPayLoadGroupFunnelList) =>
    getPath(`group/funnels?${getQueryParams(params)}`),
  changeNameGroupFunnel: () => getPath(`group/rename`),
  changeNameFunnel: () => getPath(`funnel/rename`),
  cloneGroupFunnel: () => getPath(`group/copy`),
  deleteGroupFunnel: () => getPath(`group/delete`),
  changeStatusFavorite: () => getPath(`funnel/favorite`),
  changeStatusPublicFunnel: () => getPath(`funnel/publish`),
  cloneFunnelElement: () => getPath(`funnel/copy`),
  getPageListFunnel: (params: PayloadPageListFunnel) =>
    getPath(`funnels/list?${getQueryParams(params)}`),
  restoreFunnel: () => getPath(`funnel/restore`),
  sharedFunnel: () => getPath(`funnel/share`),
  getFunnelById: (id: string) => getPath(`funnel/detail/${id}`),
  getFunnelByGroupDeleted: () => getPath(`funnel/groups/garbage`),
};
