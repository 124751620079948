import axiosClient from '..';
import { tagAPI } from './api';
import {
  IParamsAddUsersIntoTag,
  IParamsCreateTag,
  IParamsDeleteGroupTag,
  IParamsDeleteUserFromTag,
  IParamsDownloadCSVListTagUser,
  IParamsDuplicateGroupTag,
  IParamsEditGroupTagName,
  IParamsFilterList,
  IParamsFitlerListTagUser,
  IParamsGetUsersNoTag,
  IParamsUpdateTagOfEndUser,
  IParamsGetUserDetailTags,
} from './interface';

export const createTag = (params: IParamsCreateTag) =>
  axiosClient.post(tagAPI.createTag, params);
export const editTag = (params: IParamsCreateTag) =>
  axiosClient.post(tagAPI.editTag, params);
export const getDetailTag = (uuid: string) =>
  axiosClient.get(tagAPI.getDetailTag, { params: { uuid: uuid } });
export const getListGroupTag = () => axiosClient.get(tagAPI.getListGroupTag);
export const getListTag = (params: IParamsFilterList) =>
  axiosClient.get(tagAPI.getListTag, { params });
export const renameGroupTagName = (params: IParamsEditGroupTagName) =>
  axiosClient.post(tagAPI.renameGroupTagName, params);
export const deleteGroupTag = (params: IParamsDeleteGroupTag) =>
  axiosClient.post(tagAPI.deleteGroupTag, params);
export const duplicateGroupTag = (params: IParamsDuplicateGroupTag) =>
  axiosClient.post(tagAPI.duplicateGroupTag, params);
export const duplicateTag = (uuid: string) =>
  axiosClient.post(tagAPI.duplicateTag, { uuid: uuid });
export const deleteTag = (uuid: string) =>
  axiosClient.post(tagAPI.deleteTag, { uuid: uuid });

export const updateTagOfEndUser = (params: IParamsUpdateTagOfEndUser) =>
  axiosClient.post(tagAPI.updateTagOfEndUser, params);
export const getListTagUser = (
  tag_uuid: string,
  params: IParamsFitlerListTagUser
) => axiosClient.get(tagAPI.getListTagUser(tag_uuid), { params });
export const deleteUserFromTag = (params: IParamsDeleteUserFromTag) =>
  axiosClient.post(tagAPI.deleteUserFromTag, params);
export const downloadCSVListTagUser = (
  tag_uuid: string,
  params: IParamsDownloadCSVListTagUser
) => axiosClient.post(tagAPI.downloadCSVListTagUser(tag_uuid), params);
export const getAllTags = () => axiosClient.get(tagAPI.getAllTags);
export const getUsersNoTag = (params: IParamsGetUsersNoTag) =>
  axiosClient.get(tagAPI.getUsersNoTag, { params });

export const addUsersIntoTag = (
  tag_uuid: string,
  params: IParamsAddUsersIntoTag
) => axiosClient.post(tagAPI.addUsersIntoTag(tag_uuid), params);

export const getUserDetailTags = (
  user_uuid: string,
  params: IParamsGetUserDetailTags
) => axiosClient.get(tagAPI.getUserDetailTags(user_uuid), { params });
