import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/reducers/rootReducer';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const GlobalPopupContainer = (): JSX.Element => {
  const hasPopup = useSelector((state: AppState) => state.globalPopupReducer);
  const [countDown, setCountDown] = useState(60);
  const { t } = useTranslation();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountDown((t) => t - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (countDown === 0 && hasPopup.status) {
      location.reload();
    }
  }, [countDown]);

  useEffect(() => {
    if (hasPopup.status) {
      setCountDown(60);
    }
  }, [hasPopup.status]);

  return (
    <>
      {hasPopup.status && (
        <Wrapped open={hasPopup.status ? 1 : 0}>
          <div id="popup1" className="overlay">
            <div className="popup">
              <div className="card">
                <div className="card-header">
                  {t('global.common.requestTimeoutTitle')}
                </div>
                <div className="card-body">
                  <p className="card-text">
                    {t('global.common.contentTimeout', {
                      countDown: countDown,
                    })}
                  </p>
                  <div className="w-100 text-right">
                    <button
                      className="btn btn-primary btn-lead"
                      onClick={() => location.reload()}
                    >
                      {t('global.common.oke.request')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Wrapped>
      )}
    </>
  );
};

const Wrapped = styled.div`
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: ${(props: any) => (props.open ? 'visible' : 'hidden')};
    opacity: ${(props: any) => (props.open ? 1 : 0)};
    z-index: 9999999999999;
  }
  .overlay:target {
    visibility: visible;
    opacity: 1;
  }

  .popup {
    margin: 15% auto;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    position: relative;
    transition: all 5s ease-in-out;
  }
  .text-right {
    text-align: right;
  }
  .btn-lead {
    background-color: var(--t-main-color);
    border: 1px solid var(--t-main-color);
  }
  .card-header {
    background-color: var(--t-main-color);
    color: white;
    font-size: 500;
  }
`;

export default GlobalPopupContainer;
