import { call, put, select, takeLatest, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as accountServices from './../../Clients/mypage';
import * as domainService from '../../Clients/domains';
import * as Types from '../actions/accountSetting/types';
import * as TypesLoading from './../actionTypes/loadingActionTypes';
import {
  infoTransactionMarketingToParams,
  paramsToInfoTransactionMarketing,
} from '../../components/pages/SettingAccount/common';
import { AccountSetting } from '../reducers/accountSetting/interfaces';
import { AppState } from '../reducers/rootReducer';
import { handleMessage } from '../../utilities/common.utilities';
import { getAllRecordForDomains } from '../../Clients/domains';

interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
  error?: any;
}

function* fetchListEmailIntegrations(action: any) {
  const { params } = action;

  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      accountServices.getDataListEmailIntegrations(params)
    );

    yield put({
      type: Types.FETCH_DATA_LIST_EMAIL_INTEGRATIONS_SUCCESS,
      data: response.data.data,
    });
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    toast.error(e.response.data.message || e.response.data.error);
  }
}

function* fetchListFunnelDomain(action: any) {
  const { params } = action;
  if (params?.isLoading) yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(() =>
      accountServices.getDataFunnelDomain(params)
    );

    if (params.isLoadMore) {
      yield put({
        type: Types.LOAD_MORE_FUNNEL_DOMAIN_SUCCESS,
        data: response.data.data,
      });
      return;
    }
    yield put({
      type: Types.FETCH_DATA_FUNNEL_DOMAIN_SUCCESS,
      data: response.data.data,
    });
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    toast.error(e.response.data.message || e.response.data.error);
  }
}

function* createEmailIntegrations(action: any) {
  const { params, callBackSuccess, callBackFail } = action;

  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      accountServices.createEmailIntegrations(
        infoTransactionMarketingToParams(params)
      )
    );

    yield callBackSuccess(response.data.message);
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    callBackFail(paramsToInfoTransactionMarketing(e.response.data.error));
    toast.error(e.response.data.message || e.response.data.error);
  }
}

function* deleteMailIntegrations(action: any) {
  const accountSetting: AccountSetting = yield select(
    (state: AppState) => state.accountSetting
  );
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(() =>
      accountServices.deleteEmailIntegrations({ uuid: action.uuid })
    );

    if (accountSetting.listEmailIntegrations.data.length > 1) {
      yield put({
        type: Types.DELETE_EMAIL_INTEGRATIONS_SUCCESS,
        data: {
          uuidMail: action.uuid,
        },
      });
    } else {
      yield put({
        type: Types.FETCH_DATA_LIST_EMAIL_INTEGRATIONS,
        params: {
          page: 1,
          perPage: 20,
        },
      });
    }

    toast.success(response.data.message);
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    toast.error(e.response.data.message || e.response.data.error);
  }
}

function* fetchEmailIntegrationsDetail(action: any) {
  const { uuid } = action;

  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      accountServices.getDataEmailIntegrationsDetail(uuid)
    );

    yield put({
      type: Types.FETCH_DATA_EMAIL_INTEGRATIONS_DETAIL_SUCCESS,
      data: response.data.data,
    });
    yield put({
      type: Types.LOAD_MORE_FUNNEL_DOMAIN_SUCCESS_IN_UPDATE,
      data: response.data.data.domain ? [{ ...response.data.data.domain }] : [],
    });
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    toast.error(e.response.data.message || e.response.data.error);
  }
}

function* updateEmailIntegrations(action: any) {
  const { params, uuid, callBackSuccess, callBackFail } = action;

  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      accountServices.updateEmailIntegrations({
        ...infoTransactionMarketingToParams(params),
        uuid,
      })
    );

    yield put({
      type: Types.CALL_API_ERROR,
      error: response.data.error,
    });
    yield callBackSuccess(response.data.message);
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    callBackFail(paramsToInfoTransactionMarketing(e.response.data.error));
    toast.error(e.response.data.message || e.response.data.error);
  }
}

function* fetchPaymentInformation(actions: any) {
  const { onSuccess, onError } = actions;
  try {
    const res: APIResponseType = yield call(() =>
      accountServices.fetchPaymentInformation()
    );

    onSuccess(res.data.data);
  } catch (e: any) {
    onError();
  }
}

function* updatePaymentInformation(action: any) {
  const { params, onSuccess, onError } = action;
  try {
    const res: APIResponseType = yield call(() =>
      accountServices.updatePaymentInformation(params)
    );

    toast.success(res.data.message);
    onSuccess();
  } catch (e: any) {
    onError(e.response.data.error);
  }
}

function* fetchPaymentHistory(action: any) {
  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const res: APIResponseType = yield call(() =>
      accountServices.fetchPaymentHistory(action.params)
    );
    yield put({ type: TypesLoading.HIDE_LOADING });

    yield put({
      type: Types.FETCH_PAYMENT_HISTORY_SUCCESS,
      data: res.data.data,
    });
  } catch (e: any) {
    yield put({
      type: Types.FETCH_PAYMENT_HISTORY_ERROR,
    });
    handleMessage(e.response);
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* fetchAllPage() {
  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      accountServices.getAllPage()
    );

    yield put({
      type: Types.GET_ALL_PAGE_SUCCESS,
      data: response.data.data,
    });
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    toast.error(e.response.data.message || e.response.data.error);
  }
}

function* fetchFunnelDomainDetail(action: any) {
  const { uuid, onSuccess, onError } = action;

  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      accountServices.getFunnelDetail(uuid)
    );
    yield put({
      type: Types.GET_FUNNEL_DOMAIN_DETAIL_SUCCESS,
      data: response.data.data,
    });
    onSuccess && onSuccess(response);
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    onError && onError(e);
    toast.error(e.response.data.message || e.response.data.error);
  }
}

function* deleteFunnelDomain(action: any) {
  const { uuid, callbackSuccess } = action;
  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      accountServices.deleteFunnelDomain({ uuid })
    );

    delay(1000);
    toast.success(response.data.message);
    callbackSuccess();
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    toast.error(e.response.data.message || e.response.data.error);
  }
}

function* removeFacebookPixel(action: any) {
  const { uuid } = action;
  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      accountServices.removeFacebookPixel({ uuid })
    );

    yield put({
      type: Types.GET_FUNNEL_DOMAIN_DETAIL,
      uuid,
    });
    toast.success(response.data.message);
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    toast.error(e.response.data.message || e.response.data.error);
  }
}

function* verifyFunnelDomain(action: any) {
  const { uuid, callbackSuccess } = action;
  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      accountServices.verifyFunnelDomain({ uuid })
    );

    toast.success(response.data.message);
    callbackSuccess();
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    const textError = e.response.data.message || e.response.data.error;
    const textErrorArray = textError.split('\\n');
    const textRender = textErrorArray.map((text: string) => (
      <p key={text}> {text} </p>
    ));
    toast.error(<div> {textRender} </div>);
    callbackSuccess();
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* verifyFunnelDomainSSL(action: any) {
  const { uuid, callbackSuccess } = action;
  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      accountServices.verifyFunnelDomainSSL({ uuid })
    );

    toast.success(response.data.message);
    callbackSuccess();
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    callbackSuccess();
    toast.error(e.response.data.message || e.response.data.error);
  }
}

function* updateFunnelDomain(action: any) {
  const { params } = action;
  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      accountServices.updateFunnelDomain({
        uuid: params.uuid,
        default_page_uuid: params.defaultPage,
        not_found_page_uuid: params.notFoundPage,
        pixel_id: params.pixelId,
        token: params.token,
        pixel_name: params.pixelName,
        integration_uuid: params.facebookIntegration,
        is_default: params.is_default,
      })
    );

    yield put({
      type: Types.GET_FUNNEL_DOMAIN_DETAIL,
      uuid: params.uuid,
    });
    toast.success(response.data.message);
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    toast.error(e.response.data.message || e.response.data.error);
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

export function* createFunnelDomain(actions: any) {
  const { params, onSuccess, onError } = actions;
  try {
    const response: APIResponseType = yield call(() =>
      accountServices.createFunnelDomain(params)
    );

    toast.success(response.data.message);
    onSuccess(response.data.data);
  } catch (e: any) {
    onError();
    if (e.response) {
      handleMessage(e.response);
    }
  }
}

export function* associateFunnelToDomain(actions: any) {
  const { params, onSuccess, onError } = actions;
  try {
    const response: APIResponseType = yield call(() =>
      accountServices.associateFunnelToDoMain(params)
    );

    toast.success(response.data.message);
    onSuccess();
  } catch (e: any) {
    onError();
    toast.error(e.response.data.message);
  }
}

export function* getAllFunnel(actions: any) {
  const { onSuccess } = actions;
  try {
    const response: APIResponseType = yield call(() =>
      accountServices.getAllFunnel()
    );
    onSuccess(response.data.data.data);
  } catch (e: any) {
    toast.error(e.response.data.message);
  }
}

export function* getListFacebookIntegration() {
  try {
    const response: APIResponseType = yield call(
      accountServices.getListFacebookIntegration
    );
    yield put({
      type: Types.GET_LIST_FACEBOOK_INTEGRATION_SUCCESS,
      data: response.data.data,
    });
    yield put({
      type: Types.GET_LIST_FACEBOOK_PIXEL_SUCCESS,
      data: [],
    });
  } catch (e: any) {
    toast.error(e.response.data.message);
  }
}

export function* getListFacebookPixel(actions: any) {
  const { uuid, onSuccess, onError } = actions;

  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(() =>
      accountServices.getListFacebookPixel(uuid)
    );
    yield put({
      type: Types.GET_LIST_FACEBOOK_PIXEL_SUCCESS,
      data: response.data.data,
      facebookIntegration: uuid,
    });
    onSuccess();
  } catch (e: any) {
    onError(e);
    toast.error(e.response.data.message);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* updateSubDomain(action: {
  type: string;
  params: any;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(
      domainService.updateSubDomainApi,
      action.params
    );

    yield put({ type: TypesLoading.HIDE_LOADING });
    handleMessage(response);
    action.onSuccess(response.data);
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    action.onError(e.response);
  }
}

function* companyIntegrations(action: {
  type: string;
  onSuccess: (data: any) => void;
  onError: (data?: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      accountServices.getCompanyIntegrations
    );
    action.onSuccess(response.data);
  } catch (e: any) {
    handleMessage(e.response);
    action.onError();
  }
}

function* createIntegration(action: {
  type: string;
  params: any;
  onSuccess: (data?: any) => void;
  onError: (data?: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      accountServices.createIntegration,
      action.params
    );

    handleMessage(response);
    action.onSuccess();
  } catch (e: any) {
    handleMessage(e.response);
    action.onError();
  }
}

function* detailIntegration(action: {
  type: string;
  params: string;
  onSuccess: (data?: any) => void;
  onError: (data?: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      accountServices.getDetailIntegration,
      action.params
    );
    action.onSuccess(response.data.data);
  } catch (error: any) {
    action.onError();
    handleMessage(error.response);
  }
}

function* updateIntegration(action: {
  type: string;
  uuid: string;
  params: any;
  onSuccess: (data?: any) => void;
  onError: (data?: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      accountServices.updateIntegration,
      action.params,
      action.uuid
    );
    action.onSuccess();
    handleMessage(response);
  } catch (error: any) {
    action.onError();
    handleMessage(error.response);
  }
}

function* getDomainRecords(action: { type: string; uuid: string }) {
  yield put({
    type: Types.UPDATE_LOADING_DOMAIN_RECORDS,
    isLoading: true,
  });
  try {
    const response: APIResponseType = yield call(
      getAllRecordForDomains,
      action.uuid
    );

    yield put({
      type: Types.GET_DOMAIN_RECORDS_SUCCESS,
      data: Object.values(response.data.data),
    });
    yield put({
      type: Types.UPDATE_LOADING_DOMAIN_RECORDS,
      isLoading: false,
    });
  } catch (error: any) {
    yield put({
      type: Types.UPDATE_LOADING_DOMAIN_RECORDS,
      isLoading: false,
    });
    handleMessage(error.response);
  }
}

export function* getStripePromise(actions: any) {
  const { onSuccess } = actions;

  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      accountServices.getStripePromise
    );
    onSuccess(response?.data?.data);
  } catch (e: any) {
    handleMessage(e.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

export function* postStripePromise(actions: any) {
  const { onSuccess, payload, onError } = actions;

  try {
    const response: APIResponseType = yield call(
      accountServices.postStripePromise,
      payload
    );
    onSuccess();
    handleMessage(response);
  } catch (e: any) {
    onError(e.response.data.error);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

export default function* accountSettingSaga() {
  yield takeLatest(
    Types.FETCH_DATA_LIST_EMAIL_INTEGRATIONS,
    fetchListEmailIntegrations
  );
  yield takeLatest(Types.FETCH_DATA_FUNNEL_DOMAIN, fetchListFunnelDomain);
  yield takeLatest(Types.ADD_EMAIL_INTEGRATIONS, createEmailIntegrations);
  yield takeLatest(Types.DELETE_EMAIL_INTEGRATIONS, deleteMailIntegrations);
  yield takeLatest(Types.UPDATE_EMAIL_INTEGRATIONS, updateEmailIntegrations);
  yield takeLatest(
    Types.FETCH_DATA_EMAIL_INTEGRATIONS_DETAIL,
    fetchEmailIntegrationsDetail
  );
  yield takeLatest(Types.FETCH_PAYMENT_INFORMATION, fetchPaymentInformation);
  yield takeLatest(Types.UPDATE_PAYMENT_INFORMATION, updatePaymentInformation);
  yield takeLatest(Types.FETCH_PAYMENT_HISTORY, fetchPaymentHistory);
  yield takeLatest(Types.GET_ALL_PAGE, fetchAllPage);
  yield takeLatest(Types.GET_FUNNEL_DOMAIN_DETAIL, fetchFunnelDomainDetail);
  yield takeLatest(Types.DELETE_FUNNEL_DOMAIN, deleteFunnelDomain);
  yield takeLatest(Types.VERIFY_FUNNEL_DOMAIN, verifyFunnelDomain);
  yield takeLatest(Types.UPDATE_FUNNEL_DOMAIN, updateFunnelDomain);
  yield takeLatest(Types.VERIFY_FUNNEL_DOMAIN_SSL, verifyFunnelDomainSSL);
  yield takeLatest(Types.CREATE_FUNNEL_DOMAIN, createFunnelDomain);
  yield takeLatest(Types.ASSOCIATE_FUNNEL_TO_DOMAIN, associateFunnelToDomain);
  yield takeLatest(Types.GET_ALL_FUNNEL, getAllFunnel);
  yield takeLatest(
    Types.GET_LIST_FACEBOOK_INTEGRATION,
    getListFacebookIntegration
  );
  yield takeLatest(Types.GET_LIST_FACEBOOK_PIXEL, getListFacebookPixel);
  yield takeLatest(Types.REMOVE_FACEBOOK_PIXEL, removeFacebookPixel);
  yield takeLatest(Types.UPDATE_SUB_DOMAIN, updateSubDomain);
  yield takeLatest(Types.COMPANY_INTEGRATIONS, companyIntegrations);
  yield takeLatest(Types.CREATE_INTEGRATION, createIntegration);
  yield takeLatest(Types.GET_DETAIL_INTEGRATION, detailIntegration);
  yield takeLatest(Types.UPDATE_INTEGRATION, updateIntegration);
  yield takeLatest(Types.GET_DOMAIN_RECORDS, getDomainRecords);
  yield takeLatest(Types.GET_STRIPE_PROMISE, getStripePromise);
  yield takeLatest(Types.POST_STRIPE_PAYMENT, postStripePromise);
}
