import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArticleLayoutWrapped from '../article';
import { Menus } from '../config/Menus.config';
import { checkRoleCurrentUser } from '../helper/roleUser';
import { ROLE_MENU_ITEM, MY_PAGE, MENU_TILES } from '../constants/menus';
import useCheckRole from '../hooks/useCheckRole';

import myPage from '../../assets/img/menu-icons/my-page-icon.png';
import logoutIcon from '../../assets/img/menu-icons/logout-icon.png';

export interface ConnectAuth {
  children: any;
}

export const ConnectAuthen: React.FC<ConnectAuth> = ({ children }) => {
  const { t } = useTranslation();
  const { typePlan, ETypePlanFunecy, role } = useCheckRole();

  const [menus, setMenus] = useState<any>([
    {
      path: MENU_TILES.MY_PAGE,
      title: t('menu.page'),
      icon: myPage,
      children: [
        {
          icon: logoutIcon,
          path: MY_PAGE.LOGOUT,
          isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
          title: t('menu.page.logout'),
        },
      ],
    },
  ]);

  useEffect(() => {
    if (role) {
      renderMenu(role?.is_full_role || role?.is_owner, role?.role);
    }
  }, [role]);

  const renderMenu = (isFullRole: boolean, role: any) => {
    const isBasicPlan =
      !typePlan ||
      typePlan === ETypePlanFunecy.BasicMonth ||
      typePlan === ETypePlanFunecy.BasicYear;

    if (isFullRole) {
      if (isBasicPlan) {
        return setMenus(
          Menus.filter((menu) => menu.path !== MENU_TILES.AFFILIATE)
        );
      }
      setMenus(Menus);
      return;
    }
    const newMenu: any[] = [];

    Menus.forEach((menu) => {
      if (menu.path !== MENU_TILES.MY_PAGE) {
        const roleUser = role.find(
          (role: any) => checkRoleCurrentUser(role) === menu.path
        );
        if (roleUser && menu.children) {
          role.forEach((role: any) => {
            const canFix = menu.children?.filter(
              (child) =>
                checkRoleCurrentUser(role) === menu.path &&
                role.policy_id === child.isEdit
            );
            const canRole = menu.children?.find(
              (child) =>
                checkRoleCurrentUser(role) === menu.path && child.isEdit
            );
            if (canRole) {
              if (role.policy_id === ROLE_MENU_ITEM.EDIT) {
                newMenu.push(menu);
              } else {
                const newMenuRoleView = { ...menu, children: canFix };
                newMenu.push(newMenuRoleView);
              }
            }
          });
        }
      } else {
        const roleUser = role.find(
          (role: any) => checkRoleCurrentUser(role) === menu.path
        );
        if (roleUser) {
          newMenu.push(menu);
        } else {
          const newMyAccountSetting = menu.children?.filter(
            (child) => child.path !== MY_PAGE.MY_ACCOUNT_SETTING_DETAIL
          );
          const newMenuItem = { ...menu, children: newMyAccountSetting };
          newMenu.push(newMenuItem);
        }
      }
    });
    setMenus(newMenu);
  };
  return <ArticleLayoutWrapped menus={menus}>{children}</ArticleLayoutWrapped>;
};
