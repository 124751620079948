import { getPath } from '../helper';

export const endPoints = {
  filterAllDomains: () => getPath(`domain/available`),
  purchaseDomain: () => getPath(`domain/purchase`),
  getAllRecordForDomains: (domain_id: string) =>
    getPath(`domain/${domain_id}/records`),
  createRecordForDomains: (domain_id: string) =>
    getPath(`domain/${domain_id}/record/create`),
  updateRecordForDomains: (domain_id: string) =>
    getPath(`domain/${domain_id}/record/update`),
  deleteRecordForDomains: (domain_id: string) =>
    getPath(`domain/${domain_id}/record/delete`),
  reNewDomainSystem: (domain_id: string) =>
    getPath(`domain/${domain_id}/update-renew`),
  updateSubDomainUrl: () => getPath(`change-sub-domain`),
};
