import { EStatusHttp } from './../../../Clients/interfaces';
import { toast } from 'react-toastify';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import * as Types from '../../actions/membersiteManagement/types';
import * as ActionTypes from '../../actionTypes/loadingActionTypes';
import { handleMessage } from '../../../utilities/common.utilities';
import { AppState } from '../../reducers/rootReducer';
import { handleErrorMessage } from './config';
import {
  getDataTemplate,
  getDetailCourse,
  getListUserCourse,
  getShowLessons,
  getStatusImportCSV,
  membersiteServices,
  postChangeActive,
  postChangePositionLesson,
  postChangePositionSection,
  postCreateLessons,
  postCreateSection,
  postDeleteLessons,
  postDeleteSection,
  postDownLoadCSV,
  postDuplicateLessons,
  postUpdateLessons,
  postUpdateSection,
} from './../../../Clients/membersites';
import {
  IParamsFilterListUserCourse,
  MembersiteDetailLoading,
  MembersiteManagement,
} from '../../reducers/membersiteManagement/interface';
import {
  IParamsUpdateLesson,
  IPayloadChangeActive,
  IPayloadDownLoadCSV,
  IPramsDataTemplate,
  IPramsListUserCourse,
} from '../../../Clients/membersites/interface';
import {
  IChangeNameTemplates,
  IPageTemplates,
} from '../../../Clients/templates/interface';
import {
  changeNameMembersiteTemplates,
  deleteMembersiteTemplateById,
  getMembersiteTemplateById,
  getPageListMembersiteTemplates,
  getTemplateByIdMembersite,
  sharedMembersiteTemplates,
} from '../../../Clients/templates';
import { IStatusImport } from '../../../containers/Membersite/Course/Upload/UploadCourse/page/config';

interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
}

function* handleInstallTemplate(action: any) {
  const { params, loadingComponent } = action;
  const uuidMembersite: MembersiteManagement = yield select(
    (state: AppState) => state.membersite.detailCourse.uuid
  );
  if (loadingComponent)
    yield put({
      type: Types.SHOW_lOADING,
      data: loadingComponent,
    });
  try {
    const response: APIResponseType = yield call(() =>
      membersiteServices.installTemplate(params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_DETAIL_COURSE,
        uuid: uuidMembersite,
      });
      return;
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* restoresSettingStep(action: any) {
  const { params, loadingComponent } = action;
  const newParams = {
    page_uuid: params.uuidStep,
    restore_uuid: params.uuidRestore,
  };
  if (loadingComponent)
    yield put({
      type: Types.SHOW_lOADING,
      data: loadingComponent,
    });
  //TODO
  try {
    const response: APIResponseType = yield call(() =>
      membersiteServices.restoresSettingStep(newParams)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      toast.success(response.data.message);

      yield put({ type: ActionTypes.HIDE_LOADING });
      return;
    }
    toast.error(response.data.error);
    yield put({ type: ActionTypes.HIDE_LOADING });
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

// Access link funnel management
function* fetchListAccessLink(action: any) {
  const { uuidMembersite } = action;
  yield put({ type: Types.SHOW_LOADING_ACCESS_LINK_MEMBERSITE });
  try {
    const response: APIResponseType = yield call(() =>
      membersiteServices.fetchListAccessLink(uuidMembersite)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.FETCH_LIST_ACCESS_MEMBERSITE_SUCCESS,
        data: response.data.data,
      });
      yield put({ type: Types.HIDDEN_LOADING_ACCESS_LINK_MEMBERSITE });
      return;
    }
    toast.error(response.data.error);
    yield put({ type: Types.HIDDEN_LOADING_ACCESS_LINK_MEMBERSITE });
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
    yield put({ type: Types.HIDDEN_LOADING_ACCESS_LINK_MEMBERSITE });
  }
}

function* createAccessLink(action: any) {
  const { params, onSuccess } = action;
  //TODO
  yield put({ type: Types.SHOW_LOADING_ACCESS_LINK_MEMBERSITE });
  try {
    const response: APIResponseType = yield call(() =>
      membersiteServices.createAccessLink(params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      toast.success(response.data.message);
      onSuccess();
      delay(500);
      yield put({
        type: Types.FETCH_LIST_ACCESS_MEMBERSITE,
        uuidMembersite: params.funnel_uuid,
      });
      return;
    }
    handleErrorMessage(response.data.error);
    yield put({ type: Types.HIDDEN_LOADING_ACCESS_LINK_MEMBERSITE });
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
    yield put({ type: Types.HIDDEN_LOADING_ACCESS_LINK_MEMBERSITE });
  }
}

function* detailCourse(action: {
  type: string;
  uuid: string;
  loadingComponent?: MembersiteDetailLoading;
}) {
  yield put({
    type: Types.SET_SKELETON,
    data: true,
  });
  if (action.loadingComponent)
    yield put({
      type: Types.SHOW_lOADING,
      data: action.loadingComponent,
    });
  try {
    const response: APIResponseType = yield call(getDetailCourse, {
      uuid: action.uuid,
    });
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_DETAIL_COURSE_SUCCESS,
        data: response.data.data,
      });

      if (response.data.data.course_sections.length === 0) {
        yield put({
          type: Types.GET_DATA_TEMPLATE,
          prams: { page_template_type: 0, per_page: 24, name: '', page: 1 },
        });
      }
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: Types.HIDE_LOADING });
    yield put({
      type: Types.SET_SKELETON,
      data: false,
    });
  }
}

function* updateSection(action: {
  type: string;
  courseUuid: string;
  sectionUuid: string;
  payload: string;
}) {
  yield put({
    type: Types.SHOW_lOADING,
  });
  try {
    const response: APIResponseType = yield call(
      postUpdateSection,
      action.courseUuid,
      action.sectionUuid,
      { name: action.payload }
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      delay(500);
      yield put({
        type: Types.GET_DETAIL_COURSE,
        uuid: action.courseUuid,
      });
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: Types.HIDE_LOADING });
  }
}

function* deleteSection(action: {
  type: string;
  courseUuid: string;
  sectionUuid: string;
}) {
  yield put({
    type: Types.SHOW_lOADING,
  });
  try {
    const response: APIResponseType = yield call(
      postDeleteSection,
      action.courseUuid,
      action.sectionUuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      delay(500);
      yield put({
        type: Types.GET_DETAIL_COURSE,
        uuid: action.courseUuid,
      });
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: Types.HIDE_LOADING });
  }
}

function* createSection(action: {
  type: string;
  courseUuid: string;
  payload: { name: string };
}) {
  yield put({
    type: ActionTypes.SHOW_LOADING,
  });
  try {
    const response: APIResponseType = yield call(
      postCreateSection,
      action.courseUuid,
      action.payload
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      delay(500);
      yield put({
        type: Types.GET_DETAIL_COURSE,
        uuid: action.courseUuid,
      });
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* createLessons(action: {
  type: string;
  courseUuid: string;
  sectionUuid: string;
  payload: { name: string };
}) {
  yield put({
    type: ActionTypes.SHOW_LOADING,
  });
  try {
    const response: APIResponseType = yield call(
      postCreateLessons,
      action.courseUuid,
      action.sectionUuid,
      action.payload
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      delay(500);
      yield put({
        type: Types.GET_DETAIL_COURSE,
        uuid: action.courseUuid,
      });
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* showLessons(action: {
  type: string;
  courseUuid: string;
  sectionUuid: string;
  lessonUuid: string;
  onSuccess: () => void;
}) {
  try {
    const response: APIResponseType = yield call(
      getShowLessons,
      action.courseUuid,
      action.sectionUuid,
      action.lessonUuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_SHOW_LESSONS_SUCCESS,
        data: response?.data?.data,
      });
      action.onSuccess();
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  }
}

function* updateLessons(action: {
  type: string;
  courseUuid: string;
  sectionUuid: string;
  lessonUuid: string;
  payload: IParamsUpdateLesson;
}) {
  try {
    const response: APIResponseType = yield call(
      postUpdateLessons,
      action.courseUuid,
      action.sectionUuid,
      action.lessonUuid,
      action.payload
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      delay(500);
      yield put({
        type: Types.GET_DETAIL_COURSE,
        uuid: action.courseUuid,
      });
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  }
}

function* duplicateLessons(action: {
  type: string;
  courseUuid: string;
  sectionUuid: string;
  lessonUuid: string;
}) {
  try {
    const response: APIResponseType = yield call(
      postDuplicateLessons,
      action.courseUuid,
      action.sectionUuid,
      action.lessonUuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      delay(500);
      yield put({
        type: Types.GET_DETAIL_COURSE,
        uuid: action.courseUuid,
      });
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  }
}

function* deleteLessons(action: {
  type: string;
  courseUuid: string;
  sectionUuid: string;
  lessonUuid: string;
}) {
  try {
    const response: APIResponseType = yield call(
      postDeleteLessons,
      action.courseUuid,
      action.sectionUuid,
      action.lessonUuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      delay(500);
      yield put({
        type: Types.GET_DETAIL_COURSE,
        uuid: action.courseUuid,
      });
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  }
}

function* changePositionSection(action: {
  type: string;
  courseUuid: string;
  sectionUuid: string;
  newPosition: number;
}) {
  try {
    const response: APIResponseType = yield call(
      postChangePositionSection,
      action.courseUuid,
      action.sectionUuid,
      action.newPosition
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      delay(500);
      yield put({
        type: Types.GET_DETAIL_COURSE,
        uuid: action.courseUuid,
      });
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  }
}

function* changePositionLesson(action: {
  type: string;
  courseUuid: string;
  sectionUuid: string;
  lessonUuid: string;
  newPosition: number;
}) {
  try {
    const response: APIResponseType = yield call(
      postChangePositionLesson,
      action.courseUuid,
      action.sectionUuid,
      action.lessonUuid,
      action.newPosition
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      delay(500);
      yield put({
        type: Types.GET_DETAIL_COURSE,
        uuid: action.courseUuid,
      });
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  }
}

function* dataTemplate(action: { type: string; prams: IPramsDataTemplate }) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(getDataTemplate, action.prams);
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_DATA_TEMPLATE_SUCCESS,
        data: response?.data?.data?.data,
        params: {
          ...action.prams,
          total: response?.data?.data?.meta?.total,
        },
      });
      return;
    } else {
      handleMessage(response);
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* listUserCourse(action: {
  type: string;
  prams: IPramsListUserCourse;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      getListUserCourse,
      action.prams
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_LIST_USER_COURSE_SUCCESS,
        data: response?.data?.data?.data,
        params: {
          ...action.prams,
          total: response?.data?.data?.meta?.total,
        },
      });
      return;
    } else {
      handleMessage(response);
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* changeActive(action: {
  type: string;
  payLoad: IPayloadChangeActive;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  const paramsFilter: IParamsFilterListUserCourse = yield select(
    (state: AppState) => state.membersite.listUserCourse.paramsFilter
  );
  try {
    const response: APIResponseType = yield call(
      postChangeActive,
      action.payLoad
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      delay(500);
      yield put({
        type: Types.GET_LIST_USER_COURSE,
        prams: paramsFilter,
      });

      return;
    } else {
      handleMessage(response);
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* downLoadCSV(action: {
  type: string;
  payLoad: IPayloadDownLoadCSV;
  onSuccess: (data: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      postDownLoadCSV,
      action.payLoad
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      action.onSuccess(response?.data);
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* getListCompanyTemplates(action: {
  type: string;
  params: IPageTemplates;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      getPageListMembersiteTemplates,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_LIST_COMPANY_TEMPLATE_SUCCESS,
        data: response?.data?.data?.data,
        total: response?.data?.data?.meta?.total,
        params: action.params,
      });
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* updateTemplates(action: {
  type: string;
  payload: IChangeNameTemplates;
  onSuccess: (value?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      changeNameMembersiteTemplates,
      action.payload
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      action.onSuccess();
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* showDetailTemplate(action: {
  type: string;
  uuid: string;
  onSuccess: (value?: any) => void;
  onError: () => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(getMembersiteTemplateById, {
      uuid: action.uuid,
    });
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response?.data?.data);
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
    action.onError();
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* deleteTemplate(action: {
  type: string;
  uuid: string;
  onSuccess: (value?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(deleteMembersiteTemplateById, {
      uuid: action.uuid,
    });
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* getSharedTemPlate(action: {
  type: string;
  uuid: string;
  onSuccess: (value?: any) => void;
  onError: () => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(getTemplateByIdMembersite, {
      uuid: action.uuid,
    });
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response?.data?.data);
      return;
    } else {
      action.onError();
      handleMessage(response);
    }
  } catch (error: any) {
    handleMessage(error?.response);
    action.onError();
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* postSharedTemPlate(action: {
  type: string;
  uuid: string;
  onSuccess: (value?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(sharedMembersiteTemplates, {
      uuid: action.uuid,
    });
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response?.data?.data);
      handleMessage(response);
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* statusImportCSV(action: {
  type: string;
  token: string;
  onSuccess: (value?: IStatusImport) => void;
}) {
  try {
    const response: APIResponseType = yield call(getStatusImportCSV, {
      token: action.token,
    });
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response?.data);
      return;
    }
  } catch (error: any) {
    handleMessage(error?.response);
  }
}

export default function* membersiteManagement() {
  yield takeLatest(Types.INSTALL_TEMPLATE_MEMBERSITE, handleInstallTemplate);

  yield takeLatest(Types.RESTORES_STEP_SETTING_MEMBERSITE, restoresSettingStep);

  yield takeLatest(Types.FETCH_LIST_ACCESS_MEMBERSITE, fetchListAccessLink);
  yield takeLatest(Types.ADD_LINK_ACCESS_MEMBERSITE, createAccessLink);
  yield takeLatest(Types.GET_DETAIL_COURSE, detailCourse);
  yield takeLatest(Types.POST_UPDATE_SECTION, updateSection);
  yield takeLatest(Types.POST_DELETE_SECTION, deleteSection);
  yield takeLatest(Types.POST_CREATE_SECTION, createSection);
  yield takeLatest(Types.POST_CREATE_LESSONS, createLessons);
  yield takeLatest(Types.GET_SHOW_LESSONS, showLessons);
  yield takeLatest(Types.POST_UPDATE_LESSONS, updateLessons);
  yield takeLatest(Types.POST_DUPLICATE_LESSONS, duplicateLessons);
  yield takeLatest(Types.POST_DELETE_LESSONS, deleteLessons);
  yield takeLatest(Types.POST_CHANGE_POSITION_SECTION, changePositionSection);
  yield takeLatest(Types.POST_CHANGE_POSITION_LESSON, changePositionLesson);
  yield takeLatest(Types.GET_DATA_TEMPLATE, dataTemplate);
  yield takeLatest(Types.GET_LIST_USER_COURSE, listUserCourse);
  yield takeLatest(Types.POST_CHANGE_ACTIVE, changeActive);
  yield takeLatest(Types.POST_DOWNLOAD_CSV, downLoadCSV);
  yield takeLatest(Types.GET_LIST_COMPANY_TEMPLATE, getListCompanyTemplates);
  yield takeLatest(Types.POST_UPDATE_TEMPLATE, updateTemplates);
  yield takeLatest(Types.GET_SHOW_DETAIL_TEMPLATE, showDetailTemplate);
  yield takeLatest(Types.POST_DELETE_TEMPLATE, deleteTemplate);
  yield takeLatest(Types.GET_SHARED_TEMPLATE, getSharedTemPlate);
  yield takeLatest(Types.POST_SHARED_TEMPLATE, postSharedTemPlate);
  yield takeLatest(Types.GET_STATUS_IMPORT_CSV_COURSE, statusImportCSV);
}
