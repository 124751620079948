import * as Types from './../../actions/membersiteManagement/types';
import {
  initialMembersiteManagement,
  MembersiteDetailLoading,
  MembersiteManagement,
} from './interface';

export default function membersiteManagementReducer(
  state: MembersiteManagement = initialMembersiteManagement,
  action: any
): MembersiteManagement {
  switch (action.type) {
    case Types.SHOW_lOADING:
      return {
        ...state,
        loadingComponent: action.data,
      };

    case Types.HIDE_LOADING:
      return {
        ...state,
        loadingComponent: MembersiteDetailLoading.HIDE,
      };

    case Types.RENAME_MEMBERSITE_SUCCESS:
      return {
        ...state,
        detailCourse: {
          ...state.detailCourse,
          name: action.name,
        },
      };

    // access link funnel
    case Types.FETCH_LIST_ACCESS_MEMBERSITE_SUCCESS:
      return {
        ...state,
        listMembersiteAccess: action.data,
      };
    case Types.SHOW_LOADING_ACCESS_LINK_MEMBERSITE:
      return {
        ...state,
        loadingModalAccessLink: true,
      };

    case Types.HIDDEN_LOADING_ACCESS_LINK_MEMBERSITE:
      return {
        ...state,
        loadingModalAccessLink: false,
      };
    case Types.TOGGLE_MODAL_SETTING_ACCESS_LINK_MEMBERSITE:
      return {
        ...state,
        isOpenModalAccessLink: action.flag,
      };
    case Types.MEMBERSITE_STEP_UUID_SELECTED_SUCCESS:
      return {
        ...state,
        membersiteStepUuidSelected: action.membersiteStepUuidSelected,
      };
    //course
    case Types.GET_DETAIL_COURSE_SUCCESS:
      return {
        ...state,
        detailCourse: action.data,
      };
    case Types.GET_SHOW_LESSONS_SUCCESS:
      return {
        ...state,
        detailLessons: action.data,
      };
    case Types.SET_SKELETON:
      return {
        ...state,
        skeleton: action.data,
      };
    case Types.GET_DATA_TEMPLATE_SUCCESS:
      return {
        ...state,
        dataTemplate: action.data,
        paramsFilter: action.params,
      };
    case Types.GET_LIST_USER_COURSE_SUCCESS:
      return {
        ...state,
        listUserCourse: {
          ...state.listUserCourse,
          paramsFilter: action.params,
          listUser: action.data,
        },
      };
    case Types.UPDATE_PARAMS_FILTER_LIST_USER_COURSE:
      return {
        ...state,
        listUserCourse: {
          ...state.listUserCourse,
          paramsFilter: action.data,
        },
      };
    case Types.GET_LIST_COMPANY_TEMPLATE_SUCCESS:
      return {
        ...state,
        listCompanyTemplates: {
          ...state.listCompanyTemplates,
          data: action.data,
          total: action.total,
          params: action.params,
        },
      };
    default:
      return state;
  }
}
