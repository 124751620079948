import { API_BASE_URL } from '../config';

const API = {
  // list template membersite
  installTemplate: `${API_BASE_URL}/membersite/course/install-template`,
  getAllTemplates: `${API_BASE_URL}/funnel/get-all-template`,

  renameMembersite: `${API_BASE_URL}/membersite/rename`,
  renameStep: `${API_BASE_URL}/membersite/step/rename`,
  deleteStep: `${API_BASE_URL}/membersite/step/delete`,
  createStep: `${API_BASE_URL}/membersite/step/create`,

  handleChangeTemplateURL: (uuid: string) =>
    `${API_BASE_URL}/membersite/step/update/${uuid}`,

  submitSettingStepMembersite: `${API_BASE_URL}/membersite/course/settings`,

  fetchStepMembersiteDetailSetting: `${API_BASE_URL}/membersite/course/settings`,

  restoresSettingStep: `${API_BASE_URL}/membersite/page/restores`,

  fetchListRestores: (uuidStep: string) =>
    `${API_BASE_URL}/membersite/page/restores?uuid=${uuidStep}`,

  // access link
  fetchListAccessLink: (uuidMembersite: string) =>
    `${API_BASE_URL}/access-membersite?uuid=${uuidMembersite}`,
  createAccessLink: `${API_BASE_URL}/access-membersite/create`,

  // get html template
  fetchHtmlTemplate: (landingFileUrl: string) =>
    `${window.location.origin}/${landingFileUrl}`,

  //course
  getDetail: `${API_BASE_URL}/membersite/course/details`,
  postUpdateSection: (courseUuid: string, sectionUuid: string) =>
    `${API_BASE_URL}/membersite/course/${courseUuid}/sections/update/${sectionUuid}`,
  postDeleteSection: (courseUuid: string, sectionUuid: string) =>
    `${API_BASE_URL}/membersite/course/${courseUuid}/sections/delete/${sectionUuid}`,
  postCreateSection: (courseUuid: string) =>
    `${API_BASE_URL}/membersite/course/${courseUuid}/sections/create`,
  postCreateLessons: (courseUuid: string, sectionUuid: string) =>
    `${API_BASE_URL}/membersite/course/${courseUuid}/section/${sectionUuid}/lessons/create`,
  getShowLessons: (
    courseUuid: string,
    sectionUuid: string,
    lessonUuid: string
  ) =>
    `${API_BASE_URL}/membersite/course/${courseUuid}/section/${sectionUuid}/lessons/${lessonUuid}`,
  postUpdateLessons: (
    courseUuid: string,
    sectionUuid: string,
    lessonUuid: string
  ) =>
    `${API_BASE_URL}/membersite/course/${courseUuid}/section/${sectionUuid}/lessons/update/${lessonUuid}`,
  postDuplicateLessons: (
    courseUuid: string,
    sectionUuid: string,
    lessonUuid: string
  ) =>
    `${API_BASE_URL}/membersite/course/${courseUuid}/section/${sectionUuid}/lessons/duplicate/${lessonUuid}`,
  postDeleteLessons: (
    courseUuid: string,
    sectionUuid: string,
    lessonUuid: string
  ) =>
    `${API_BASE_URL}/membersite/course/${courseUuid}/section/${sectionUuid}/lessons/delete/${lessonUuid}`,

  postChangePositionSection: (
    courseUuid: string,
    sectionUuid: string,
    newPosition: number
  ) =>
    `${API_BASE_URL}/membersite/course/${courseUuid}/sections/change-position/${sectionUuid}/${newPosition}`,

  postChangePositionLesson: (
    courseUuid: string,
    sectionUuid: string,
    lessonUuid: string,
    newPosition: number
  ) =>
    `${API_BASE_URL}/membersite/course/${courseUuid}/section/${sectionUuid}/lessons/change-position/${lessonUuid}/${newPosition}`,

  getDataTemplate: `${API_BASE_URL}/membersite/course/templates`,

  getListUserCourse: `${API_BASE_URL}/membersite/course/list-user-enrolled`,

  postChangeActive: `${API_BASE_URL}/membersite/course/change-active`,

  postDownLoadCSV: `${API_BASE_URL}/membersite/course/download-csv`,

  getStatusImportCSV: `${API_BASE_URL}/membersite/course/status-import`,
};

export default API;
