import * as Types from '../../actions/funnel/types';
import { initialSummaryFunnel, IStateSummaryFunnel } from './interfaces';

export default function summaryFunnelReducer(
  state: IStateSummaryFunnel = initialSummaryFunnel,
  action: any
): IStateSummaryFunnel {
  switch (action.type) {
    case Types.FETCH_SUMMARY_FUNNEL: {
      return { ...state };
    }
    case Types.FETCH_SUMMARY_FUNNEL_SUCCESS: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case Types.FETCH_SUMMARY_FUNNEL_FAILS: {
      return {
        ...state,
        error: action.error,
      };
    }
    default:
      return { ...state };
  }
}
