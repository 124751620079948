import { Col, Row } from 'antd';
import { ErrorMessage } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TInputField } from '../../../../../components/atoms/TInputField';

interface VFieldInputProps {
  name: string;
  placeholder: string;
  hasDisable?: boolean;
  label: string;
  hasShared?: boolean;
}

const VFieldInput: React.FC<VFieldInputProps> = ({
  name,
  placeholder,
  hasDisable = false,
  label,
  hasShared = false,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Row justify="start" align="middle" className="mt-3">
        <Col className="label" xs={24} sm={24} md={24} lg={5}>
          <span>{label}</span>
        </Col>
        <Col xs={24} sm={24} md={24} lg={19}>
          {hasShared && <p>{t('templateDetail.share.anonation')}</p>}
          <TInputField
            name={name}
            type="text"
            placeholder={placeholder}
            disable={hasDisable}
          />
        </Col>
      </Row>
      <Row justify="start" align="middle">
        <Col xs={24} sm={24} md={24} lg={5}></Col>
        <Col xs={24} sm={24} md={24} lg={19}>
          <ErrorMessage
            name={name}
            component="div"
            className="errors-text-formik"
          />
        </Col>
      </Row>
    </>
  );
};

export default VFieldInput;
