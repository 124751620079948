export const SHOW_POPUP = 'showPopupSectionTypes/SHOW_POPUP';
export interface ShowPopupAction {
  type: typeof SHOW_POPUP;
  message: string;
}

export const HIDE_POPUP = 'showPopupSectionTypes/HIDE_POPUP';
export interface HidePopupAction {
  type: typeof HIDE_POPUP;
}

export type PopupAction = ShowPopupAction | HidePopupAction;
