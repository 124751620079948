import { CALENDAR, FUNNEL, MEMBERSITE } from './../../../constants/menus';
import { IMenuItem } from '../../../interfaces/layout/IMenu.interfaces';

export const listCollapsed = [
  FUNNEL.FUNNEL_DETAIL,
  MEMBERSITE.SELECT,
  CALENDAR.SETTING_SCHEDULE,
];

export interface AntDrawerNavigationProps {
  menus: Array<IMenuItem>;
}
