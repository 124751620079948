export const LOGIN = 'LOGIN';
export const LOGIN_TOW_AUTHEN = 'LOGIN_TOW_AUTHEN';
export const RESEND_VERIFY_ACCOUNT = 'RESEND_VERIFY_ACCOUNT';

export const REGISTER_ACCOUNT = 'REGISTER_ACCOUNT';
export const VERIFY_TOKEN_REGISTER = 'VERIFY_TOKEN_REGISTER';

export const GET_MAINTENANCE_DETAIL = 'GET_MAINTENANCE_DETAIL';

export const VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS';
export const GET_SELECTION_PLAN = 'GET_SELECTION_PLAN';
