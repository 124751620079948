// Project: 7612770651107e1659371833c56a9b25e733c4615968bb7c5582cdc92aa6249b
import axiosClient from '..';
import { IPayLoadGroupFunnelList } from '../../store/actions/funnel/group';
import { PayloadPageListFunnel } from '../../store/actions/funnel/pageList';
import { showLoading } from '../../store/actions/loadingAction';
import { store } from '../../store/configureStore';
import { completedResponse } from '../helper';
import { IBaseParameter } from '../interfaces';
import API from './api';
import { endPoints } from './config';
import {
  IParamsCreatePageVariation,
  IFunnelStepMetadataPayload,
  IParamsFetchListFunnel,
  IParamsInstallTemplate,
  IParamsListArchivedPage,
  IParamsRenameStep,
  IParamsUpdateStepFunnel,
  IParamsUpdateTraffic,
} from './interface';

export interface IGroupFunnels {
  uuid: string;
  name: string;
}

export interface IFunnel {
  name: string;
  group_name?: string;
  group_uuid?: string;
  funnel_type_id?: string;
}

export async function getAllGroup() {
  const response = await axiosClient.get(endPoints.getAllGroup());
  return completedResponse<IGroupFunnels[]>(response);
}

export async function createFunnel(params: IBaseParameter<IFunnel>) {
  params.hasLoading && store.dispatch(showLoading());
  const response = await axiosClient.post(
    endPoints.createFunnel(),
    params.params
  );
  return completedResponse<any>(response, { ...params });
}

export async function createMembersite(params: IBaseParameter<IFunnel>) {
  params.hasLoading && store.dispatch(showLoading());
  const response = await axiosClient.post(
    endPoints.createMembersite(),
    params.params
  );
  return completedResponse<any>(response, { ...params });
}

export async function getSetting(params: IBaseParameter<IGetElementById>) {
  params.hasLoading && store.dispatch(showLoading());
  const response = await axiosClient.get(
    endPoints.getSetting(params.params?.uuid)
  );
  return completedResponse<any>(response, { ...params });
}

export interface IGetElementById {
  uuid: string;
}

export async function deleteFunnel(params: IBaseParameter<IGetElementById>) {
  params.hasLoading && store.dispatch(showLoading());
  const response = await axiosClient.post(endPoints.delete(), params.params);
  return completedResponse<any>(response, { ...params });
}

export interface IFunnelSetting {
  uuid?: string;
  name: string;
  group_name?: string;
  group_uuid?: string;
  domain_uuid?: string;
  favicon_url: string;
  head_tracking_code: string;
  body_tracking_code: string;
  share_url: string;
}

export async function updateFunnel(params: IBaseParameter<IFunnelSetting>) {
  params.hasLoading && store.dispatch(showLoading());
  const response = await axiosClient.post(endPoints.update(), params.params);
  return completedResponse<any>(response, { ...params });
}

const fetchListTemplate = (params: IParamsFetchListFunnel) => {
  return axiosClient.get(API.fetchListTemplate, { params });
};

const installTemplate = (params: IParamsInstallTemplate) =>
  axiosClient.post(API.installTemplate, params);

// funnel detail
const fetchFunnelDetail = (uuid: string, filterDeleted?: number) =>
  axiosClient.get(API.fetchFunnelDetail(uuid, filterDeleted));

// step funnel detail
const fetchStepFunnelDetail = (uuid: string) =>
  axiosClient.get(API.fetchStepFunnelDetail(uuid));

interface ParamsRenameFunnel {
  uuid: string;
  name: string;
}
const renameFunnel = (params: ParamsRenameFunnel) =>
  axiosClient.post(API.renameFunnel, params);

const getAllTemplates = () => axiosClient.get(API.getAllTemplates);

const renameStep = (params: IParamsRenameStep) =>
  axiosClient.post(API.renameStep, params);

interface ParamsCreateStepFunnel {
  funnel_uuid: string;
  name: string;
}
const createStepFunnel = (params: ParamsCreateStepFunnel) =>
  axiosClient.post(API.createStep, params);

interface ParamsDeleteStepFunnel {
  funnel_step_uuid: string;
}
const removeStepFunnel = (params: ParamsDeleteStepFunnel) =>
  axiosClient.post(API.deleteStep, params);

interface ParamsChangeTemplate {
  name: string;
  path: string;
}
const handleChangeTemplateURL = (uuid: string, params: ParamsChangeTemplate) =>
  axiosClient.post(API.handleChangeTemplateURL(uuid), params);

const handleRenameFormFunnel = (params: any) =>
  axiosClient.post(API.handleRenameFormFunnel, params);

const submitSettingStepFunnel = (params: any) =>
  axiosClient.post(API.submitSettingStepFunnel, params);

const fetchStepFunnelDetailSetting = (uuid: string) =>
  axiosClient.get(API.fetchStepFunnelDetailSetting(uuid));

interface ParamsRestoresSettingStep {
  page_uuid: string;
  restore_uuid: string;
}
const restoresSettingStep = (params: ParamsRestoresSettingStep) =>
  axiosClient.post(API.restoresSettingStep, params);

const fetchListAccessLink = (uuid: string) =>
  axiosClient.get(API.fetchListAccessLink(uuid));

interface ParamsCreateAccessLink {
  description: string;
  funnel_uuid: string;
  name: string;
  path: string;
}
const createAccessLink = (params: ParamsCreateAccessLink) =>
  axiosClient.post(API.createAccessLink, params);

const removeAccessLink = (uuid: string) =>
  axiosClient.post(API.removeAccessLink(uuid));

const updateAccessLink = (params: ParamsCreateAccessLink) =>
  axiosClient.post(API.updateAccessLink, params);

// slider bar
interface ParamsChangePosition {
  funnel_step_uuid: string;
  position: string;
}
const changePosition = (params: ParamsChangePosition) =>
  axiosClient.post(API.changePosition, params);

export const getSummaryFunnel = () =>
  axiosClient.get(endPoints.getSummaryFunnel());

export interface ICreateGroup {
  type: 'FUNNEL' | 'COURSE';
  name: string;
}

export async function createGroupFunnels(params: IBaseParameter<ICreateGroup>) {
  const response = await axiosClient.post(
    endPoints.createFunnelGroup(),
    params.params
  );
  return completedResponse<any>(response, { ...params });
}

export async function createGroupMembersites(
  params: IBaseParameter<ICreateGroup>
) {
  const response = await axiosClient.post(
    endPoints.createCourseGroup(),
    params.params
  );
  return completedResponse<any>(response, { ...params });
}

export const getAllGroupFunnel = () => axiosClient.get(endPoints.getAllGroup());

export const getPageListGroupFunnels = (params: IPayLoadGroupFunnelList) => {
  return axiosClient.get(endPoints.getPageListGroupFunnels(params));
};

export interface ChangeNameGroupFunnel {
  uuid: string;
  name: string;
  type: string;
}

export const changeNameGroupFunnel = async (
  params: IBaseParameter<ChangeNameGroupFunnel>
) => {
  const response = await axiosClient.post(
    endPoints.changeNameGroupFunnel(),
    params.params
  );
  return completedResponse<any>(response, { ...params });
};

export const renameGroupFunnel = (params: ChangeNameGroupFunnel) => {
  return axiosClient.post(endPoints.changeNameGroupFunnel(), params);
};

export const changeNameFunnel = (params: any) => {
  return axiosClient.post(endPoints.changeNameFunnel(), params);
};

export interface CloneGroup {
  uuid: string;
}

export const cloneGroupFunnel = async (params: IBaseParameter<CloneGroup>) => {
  params.hasLoading && store.dispatch(showLoading());
  const response = await axiosClient.post(
    endPoints.cloneGroupFunnel(),
    params.params
  );
  return completedResponse(response, { ...params });
};

export const deleteGroupFunnel = async (params: IBaseParameter<CloneGroup>) => {
  params.hasLoading && store.dispatch(showLoading());
  const response = await axiosClient.post(
    endPoints.deleteGroupFunnel(),
    params.params
  );
  return completedResponse(response, { ...params });
};

export interface StatusFavorite {
  uuid: string;
  favorite: number;
}

export const changeStatusFavorite = async (
  params: IBaseParameter<StatusFavorite>
) => {
  params.hasLoading && store.dispatch(showLoading());
  const response = await axiosClient.post(
    endPoints.changeStatusFavorite(),
    params.params
  );
  return completedResponse(response, { ...params });
};

export interface StatusPublic {
  flag_publish: number;
  uuid: string;
}

export const changeStatusPublicFunnel = async (
  params: IBaseParameter<StatusPublic>
) => {
  params.hasLoading && store.dispatch(showLoading());
  const response = await axiosClient.post(
    endPoints.changeStatusPublicFunnel(),
    params.params
  );
  return completedResponse(response, {
    ...params,
    notification: { success: true },
  });
};

export interface CloneFunnel {
  uuid: string;
}

export const cloneFunnelElement = async (
  params: IBaseParameter<CloneFunnel>
) => {
  params.hasLoading && store.dispatch(showLoading());
  const response = await axiosClient.post(
    endPoints.cloneFunnelElement(),
    params.params
  );
  return completedResponse(response, { ...params });
};

export const getPageListFunnel = (params: PayloadPageListFunnel) => {
  return axiosClient.get(endPoints.getPageListFunnel(params));
};

export const restoreFunnel = async (
  params: IBaseParameter<IGetElementById>
) => {
  params.hasLoading && store.dispatch(showLoading());
  const response = await axiosClient.post(
    endPoints.restoreFunnel(),
    params.params
  );
  return completedResponse(response, { ...params });
};

export const sharedFunnel = (params: IGetElementById) => {
  return axiosClient.post(endPoints.sharedFunnel(), params);
};

export const getFunnelById = (params: string) => {
  return axiosClient.get(endPoints.getFunnelById(params));
};

export const getFunnelByGroupDeleted = () => {
  return axiosClient.get(endPoints.getFunnelByGroupDeleted());
};

// affiliate funnel
const fetchFunnelProductAPI = (funnel_step_uuid: string) =>
  axiosClient.get(API.fetchFunnelProduct(funnel_step_uuid));
const fetchAffiliateFunnelAPI = (uuid: string) =>
  axiosClient.get(API.affiliateFunnel(uuid));
const updateAffiliateFunnelAPI = (uuid: string, params: any) =>
  axiosClient.post(API.affiliateFunnel(uuid), params, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
const fetchProductPlanAPI = () => axiosClient.get(API.fetchProductPlan);

export const getAllFunnelStepByGroupFunnel = () =>
  axiosClient.get(API.getAllFunnelStepByGroupFunnel);
// A/B test

const postCreatePageVariation = (params: IParamsCreatePageVariation) =>
  axiosClient.post(API.postCreatePageVariation, params);

const postDeclarePageWinner = (uuid: string) =>
  axiosClient.post(API.postDeclarePageWinner, { page_uuid: uuid });

const getListArchivedPage = (params: IParamsListArchivedPage) =>
  axiosClient.get(API.getListArchivedPage, { params });

const postUpdateStepFunnel = (uuid: string, params: IParamsUpdateStepFunnel) =>
  axiosClient.post(API.postUpdateStepFunnel(uuid), params);

const postRestorePage = (uuid: string) =>
  axiosClient.post(API.postRestorePage, { page_uuid: uuid });

const postUpdateTraffic = (params: IParamsUpdateTraffic) =>
  axiosClient.post(API.postUpdateTraffic, params);

export const postStartABTest = (uuid: string) =>
  axiosClient.post(API.postStartABTest, { funnel_step_uuid: uuid });

export const postDeletePage = (uuid: string) =>
  axiosClient.post(API.postDeletePage, { page_uuid: uuid });
export const getFunnelStepPageVersions = (funnel_step_uuid: string) =>
  axiosClient.get(API.fetchFunnelStepPageVersions(funnel_step_uuid));

export const postFunnelStepPageVersions = (payload: { uuid: string }) =>
  axiosClient.post(API.applyFunnelStepPageVersions, payload);

export const getFunnelStepMetadata = (funnel_step_uuid: string) =>
  axiosClient.get(API.fetchFunnelStepMetadata(funnel_step_uuid));

export const updateFunnelStepMetadata = (
  metadataPayload: IFunnelStepMetadataPayload
) => axiosClient.post(API.updateFunnelStepMetadata, metadataPayload);

export const getMembersiteMetadata = (funnel_step_uuid: string) =>
  axiosClient.get(API.fetchMembersiteMetadata(funnel_step_uuid));

export const updateMembersiteMetadata = (
  metadataPayload: IFunnelStepMetadataPayload
) => axiosClient.post(API.updateMembersiteMetadata, metadataPayload);

export const funnelServices = {
  createFunnel,
  updateFunnel,
  deleteFunnel,
  getSetting,
  getAllGroup,
  // list template
  fetchListTemplate,
  installTemplate,
  fetchFunnelDetail,
  // step funnel detail
  fetchStepFunnelDetail,
  renameFunnel,
  getAllTemplates,
  renameStep,
  createStepFunnel,
  removeStepFunnel,
  handleChangeTemplateURL,
  handleRenameFormFunnel,
  submitSettingStepFunnel,
  fetchStepFunnelDetailSetting,
  restoresSettingStep,
  fetchListAccessLink,
  createAccessLink,
  removeAccessLink,
  updateAccessLink,
  // slider bar
  changePosition,
  // affiliate
  fetchFunnelProductAPI,
  fetchAffiliateFunnelAPI,
  updateAffiliateFunnelAPI,
  fetchProductPlanAPI,
  postCreatePageVariation,
  postDeclarePageWinner,
  getListArchivedPage,
  postUpdateStepFunnel,
  postRestorePage,
  postUpdateTraffic,
  postStartABTest,
  postDeletePage,
};
