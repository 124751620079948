import {
  InitialTemplateManagement,
  initialTemplateManagement,
} from './interface';
import * as Types from '../../actions/template/types';

export default function commonReducer(
  state: InitialTemplateManagement = initialTemplateManagement,
  action: any
) {
  switch (action.type) {
    case Types.GET_ALL_CATEGORIES_BLOCK_TEMPLATE_SUCCESS:
      return {
        ...state,
        listCategoriesBlockTemplate: action.data,
      };
    default:
      return { ...state };
  }
}
