import i18n from '../../../../../i18n/i18n';
import { convertPartnerType } from '../../Detail/config';
import { RanksType, StatusIdentify } from '../config';

export const setColor = (status?: StatusIdentify) => {
  switch (status) {
    case StatusIdentify.PENDING:
      return 'var(--t-text-color)';
    case StatusIdentify.RE_UPLOAD:
      return 'var(--t-error-color)';
    case StatusIdentify.APPROVED:
      return 'var(--t-main-color)';
    default:
      return 'var(--t-text-color)';
  }
};

export const getListStatus = (status: StatusIdentify) => {
  switch (status) {
    case StatusIdentify.PENDING:
      return [
        {
          title: i18n.t('affiliate.partner.list.identifyPatch.approved'),
          value: StatusIdentify.APPROVED,
        },
        {
          title: i18n.t('affiliate.partner.list.identifyPatch.reUpload'),
          value: StatusIdentify.RE_UPLOAD,
        },
      ];
    case StatusIdentify.RE_UPLOAD:
      return [
        {
          title: i18n.t('affiliate.partner.list.identifyPatch.pending'),
          value: StatusIdentify.PENDING,
        },
        {
          title: i18n.t('affiliate.partner.list.identifyPatch.approved'),
          value: StatusIdentify.APPROVED,
        },
      ];
    case StatusIdentify.APPROVED:
      return [
        {
          title: i18n.t('affiliate.partner.list.identifyPatch.pending'),
          value: StatusIdentify.PENDING,
        },
        {
          title: i18n.t('affiliate.partner.list.identifyPatch.reUpload'),
          value: StatusIdentify.RE_UPLOAD,
        },
      ];
    default:
      return [];
  }
};

export const getListRanks = (ranks: string) => {
  switch (ranks) {
    case i18n.t('affiliate.partner.list.rank.general'):
      return [
        {
          title: i18n.t('affiliate.partner.list.rank.vip'),
          value: RanksType.VIP,
        },
        {
          title: i18n.t('affiliate.partner.list.rank.svip'),
          value: RanksType.SVIP,
        },
      ];
    case i18n.t('affiliate.partner.list.rank.vip'):
      return [
        {
          title: i18n.t('affiliate.partner.list.rank.general'),
          value: RanksType.GENERAL,
        },
        {
          title: i18n.t('affiliate.partner.list.rank.svip'),
          value: RanksType.SVIP,
        },
      ];
    case i18n.t('affiliate.partner.list.rank.svip'):
      return [
        {
          title: i18n.t('affiliate.partner.list.rank.general'),
          value: RanksType.GENERAL,
        },
        {
          title: i18n.t('affiliate.partner.list.rank.vip'),
          value: RanksType.VIP,
        },
      ];
    default:
      return [];
  }
};

export const convertDataPartnerList = (data: any) => {
  if (!data || !data?.length) return [];

  return data?.map((item: any) => ({
    ...item,
    email: item.email,
    first_name_kanji: item.first_name_kanji,
    last_name_kanji: item.last_name_kanji,
    full_name: item.full_name?.trim() || 'ー',
    created_at: item.created_at,
    deleted_at: item.deleted_at,
    affiliate_rank: item.affiliate_rank,
    partner_site: item.partner_site,
    partner_type: convertPartnerType(item.partner_type),
    front_identify_path: item.front_identify_path,
    reverse_identify_path: item.reverse_identify_path,
    status_identify: item.status_identify,
    is_checked: false,
  }));
};

export const saveFile = (urlDownload: string, fileName: string) => {
  fetch(urlDownload)
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.setAttribute('download', `${fileName}.jpg`);
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
};
