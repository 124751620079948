import moment from 'moment';

import { ETypeOptionRanger } from '../../../components/molecules/RangerPickerMinus/config';
import { StatusType } from '../../../components/organisms/Affiliate/PopupBatchUpdate/config';
import { ESortType } from '../../../constants/app.constants';
import { TableDetailPartner } from '../../../containers/Affiliate/Partner/Detail/config';
import { DeletedAtType } from '../../../containers/Affiliate/Partner/List/config';
import {
  ApproveStatusType,
  BonusType,
  PaymentBonusStatusType,
  RanksType,
} from '../../../containers/Affiliate/Subscriber/config';

export interface AffiliateSettingType {
  name: string;
  logo_path: string;
  domain_uuid: string;
  mail_integration_uuid: string;
  term: string;
  sender_mail: string;
  sender_name: string;
}

export interface IAffiliateSetting {
  affiliate_setting: AffiliateSettingType;
  domains: Array<any>;
  mail_integrations: Array<any>;
}

export const initialAffiliateSetting: IAffiliateSetting = {
  affiliate_setting: {
    name: '',
    logo_path: '',
    domain_uuid: '',
    mail_integration_uuid: '',
    term: '',
    sender_mail: '',
    sender_name: '',
  },
  domains: [],
  mail_integrations: [],
};

interface DataAffiliateList {
  uuid: string;
  email: string;
  full_name: string;
  first_name_kanji: string;
  last_name_kanji: string;
  created_at: string;
  deleted_at: string;
  affiliate_rank: string;
  partner_site: string;
  partner_type: number;
  front_identify_path: string;
  reverse_identify_path: string;
  status_identify: number;
  is_checked?: boolean;
}

interface MetaAffiliateList {
  currentPage: number;
  from: number;
  pageSize: number;
  totalPage: number;
  lastPage: number;
}

export interface IPartnerList {
  data: Array<DataAffiliateList>;
  meta: MetaAffiliateList;
}

export const initialPartnerList: IPartnerList = {
  data: [],
  meta: {
    currentPage: 1,
    from: 1,
    pageSize: 20,
    totalPage: 1,
    lastPage: 1,
  },
};

export interface ParamsFetchAffiliateList {
  search: string;
  affiliate_rank_id: RanksType;
  page: number;
  per_page: number;
  filter_deleted_at: DeletedAtType;
}
export interface ParamsSubmitAffiliateList {
  partner_uuids: string[];
  affiliate_rank_id: number;
  status_identify: number;
}
export interface ParamsDeleteAffiliateList {
  partner_uuids: string[];
}

export interface IDataPartnerUpdateFailure {
  email: string;
  partner_name?: string;
  funnel_name?: string;
  error: string;
}

/* ---------------------------- screen N004 - affiliate bonus ---------------------- */
export interface IParamsFetchAffiliateBonus {
  search: string;
  affiliate_rank_id: RanksType;
  sort_name: string;
  sort_type: string;
  /* ------------ params status partner deleted ----------- */
  filter_deleted_at: DeletedAtType;
  /*--------- data page ---------*/
  page: number;
  per_page: number;
  total: number;
  current_page: number;
  last_page: number;
  /*---------- filter date send start_date, end_date format YYYY-mm-dd HH:mm:00 --- */
  start_date: string;
  end_date: string;
  typeFilterDate: ETypeOptionRanger;
  rangeFilter: [moment.Moment, moment.Moment];
  /*------------ filter funnel send funnel_uuids*/
  funnel_uuids: string[];
  funnels: any[];
}

export interface IItemBonus {
  uuid: string;
  email: string;
  full_name: string;
  deleted_at: any;
  affiliate_rank: string;
  total_purchase: number;
  total_purchase_display: string;
  affiliate_rate: number;
  affiliate_rate_display: string;
  remuneration_amount: string;
  bank_intelligence: string;
  subscribe_purchase_display: string;
  tax_code: string;
}

interface IAllDataBonus {
  data: IItemBonus[];
  information: IParamsFetchAffiliateBonus;
}

const initDataBonus: IItemBonus[] = [];

export const initParamsBonus: IParamsFetchAffiliateBonus = {
  search: '',
  funnel_uuids: [],
  funnels: [],
  start_date: '',
  end_date: '',
  affiliate_rank_id: RanksType.ALL,
  sort_name: '',
  sort_type: ESortType.BLANK,
  page: 1,
  per_page: 20,
  total: 0,
  current_page: 1,
  last_page: 1,
  typeFilterDate: ETypeOptionRanger.AllTime,

  filter_deleted_at: DeletedAtType.ALL,
  rangeFilter: [moment(), moment()],
};
/* ---------------------------- ---------------------- ---------------------- */
/* ---------------------------- screen N003 - affiliate subscriber ---------------------- */
export interface IParamsFetchAffiliateSubscriber {
  search?: string;
  CV_start_date?: string;
  CV_end_date?: string;
  bonus_type?: BonusType;
  approve_status?: ApproveStatusType;
  bonus_payment_status?: PaymentBonusStatusType;
  affiliate_rank_id?: RanksType;
  funnel_uuids?: Array<string>;
  page?: number;
  per_page?: number;
}

export interface IParamsUpdateSubscriber {
  purchase_ids?: Array<number | string>;
  approve_status?:
    | RanksType
    | StatusType
    | ApproveStatusType
    | PaymentBonusStatusType;
  bonus_payment_status?:
    | StatusType
    | ApproveStatusType
    | PaymentBonusStatusType
    | RanksType;
}

export interface ISubscriberItem {
  id: number;
  email: string;
  uuid: string;
  submit_time: string;
  funnel_uuid: string;
  funnel_name: string;
  product_uuid: string;
  product_name: string;
  partner_name: string;
  affiliate_rank_id: number;
  bonus_type: number;
  bonus_status: number;
  times: number;
  affiliate_bonus: number;
  approve_status: number;
  bonus_payment_status: number;
  is_free_form: number;
  plan_id: any;
  has_bank_info: number;
  name: string;
  is_checked?: boolean;
}

export interface ISubscriberList {
  data: Array<ISubscriberItem>;
  meta: MetaAffiliateList;
}

export const initialSubscriberList: ISubscriberList = {
  data: [],
  meta: {
    currentPage: 1,
    from: 1,
    lastPage: 1,
    pageSize: 20,
    totalPage: 0,
  },
};

export interface InitialAffiliateManagement {
  affiliateSetting: IAffiliateSetting;
  partnerList: IPartnerList;
  dataUpdateFailure: IDataPartnerUpdateFailure[];
  detailPartnerList: TableDetailPartner;
  dataBonus: IAllDataBonus;
  subscriberList: ISubscriberList;
}

export const initialAffiliateManagement: InitialAffiliateManagement = {
  affiliateSetting: initialAffiliateSetting,
  partnerList: initialPartnerList,
  detailPartnerList: {},
  dataUpdateFailure: [],
  dataBonus: {
    data: initDataBonus,
    information: initParamsBonus,
  },
  subscriberList: initialSubscriberList,
};
