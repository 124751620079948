import { API_BASE_URL } from '../config';

export const tagAPI = {
  createTag: `${API_BASE_URL}/tag/create`,
  editTag: `${API_BASE_URL}/tag/update`,
  getDetailTag: `${API_BASE_URL}/tag/detail`,
  getListGroupTag: `${API_BASE_URL}/group/list/TAG`,
  getListTag: `${API_BASE_URL}/tag/list`,
  renameGroupTagName: `${API_BASE_URL}/group/rename`,
  deleteGroupTag: `${API_BASE_URL}/group/delete`,
  duplicateGroupTag: `${API_BASE_URL}/group/copy`,
  duplicateTag: `${API_BASE_URL}/tag/copy`,
  deleteTag: `${API_BASE_URL}/tag/delete`,

  updateTagOfEndUser: `${API_BASE_URL}/tag/user/update-tag`,
  getListTagUser: (tag_uuid: string) =>
    `${API_BASE_URL}/tag/${tag_uuid}/list-user`,
  deleteUserFromTag: `${API_BASE_URL}/tag/user/delete`,
  downloadCSVListTagUser: (tag_uuid: string) =>
    `${API_BASE_URL}/tag/${tag_uuid}/list-user/download`,
  getAllTags: `${API_BASE_URL}/tag/list-all`,
  getUsersNoTag: `${API_BASE_URL}/tag/user/no-tag`,

  addUsersIntoTag: (tag_uuid: string) =>
    `${API_BASE_URL}/tag/${tag_uuid}/add-user`,
  getUserDetailTags: (user_uuid: string) =>
    `${API_BASE_URL}/customer/${user_uuid}/tags`,
};
