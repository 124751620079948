import * as Types from '../../actions/mail/types';

interface AlternativeCharacterState {
  alternativeCharacters: any;
}

const initialState: AlternativeCharacterState = {
  alternativeCharacters: {
    data: undefined,
    error: undefined,
  },
};

export default function alternativeCharacterReducer(
  state: AlternativeCharacterState = initialState,
  action: any
): AlternativeCharacterState {
  switch (action.type) {
    // Get alternative characters
    case Types.GET_ALTERNATIVE_CHARACTERS_SUCCESS:
      return {
        ...state,
        alternativeCharacters: {
          ...state.alternativeCharacters,
          data: action.data,
        },
      };
    case Types.GET_ALTERNATIVE_CHARACTERS_ERROR:
      return {
        ...state,
        alternativeCharacters: {
          ...state.alternativeCharacters,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
