import React from 'react';
import { Rate } from 'antd';
import './TRate.scss';

export interface TRateProps {
  count: number;
  value: number;
  className?: string;
  onChange: () => void;
}

const TRate: React.FC<TRateProps> = ({ count, value, className, onChange }) => (
  <Rate
    onChange={onChange}
    count={count}
    defaultValue={value}
    value={value}
    className={`t-rate ${className}`}
  />
);

export default TRate;
