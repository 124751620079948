import ReactDOM from 'react-dom';
import { App } from './App';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { GoogleOAuthProvider } from '@react-oauth/google';

const store = configureStore();

ReactDOM.render(
  <GoogleOAuthProvider clientId={`${process.env.APP_GOOGLE_CLIENT_ID}`}>
    <Provider store={store}>
      <App />
    </Provider>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);
