import { EStatusHttp } from './../../../Clients/interfaces';
import { toast } from 'react-toastify';
import {
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import i18next from 'i18next';
import * as Types from '../../actions/funnelManagement/types';
import * as ActionTypes from '../../actionTypes/loadingActionTypes';
import * as CommonTypes from '../../actions/common/types';
import * as funnel from './../../../Clients/funnels';
import {
  handleGetInfoPageTemplateBySubCategoryId,
  handleGetSubCategoryPageTemplateTypeIdDefault,
  handleMessage,
} from '../../../utilities/common.utilities';
import {
  FunnelDetailLoading,
  FunnelManagement,
  paramsFilterListTemplateDefault,
  ParamsFilterTemplate,
} from '../../reducers/funnelManagement/interface';
import { AppState } from '../../reducers/rootReducer';
import { handleErrorMessage } from './config';
import { funnelServices } from './../../../Clients/funnels';
import { store } from '../../configureStore';
import {
  IParamsCreatePageVariation,
  IParamsListArchivedPage,
  IParamsUpdateStepFunnel,
  IParamsUpdateTraffic,
} from '../../../Clients/funnels/interface';

interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
}

// list template funnel
function* fetchListTemplate(action: {
  type: string;
  params: ParamsFilterTemplate;
  loadingComponent: any;
}) {
  const { params, loadingComponent } = action;

  const queryParams = {
    funnel_step_subcategory_id: params.funnelStepSubCategoryId,
    page_template_type: params.option,
    per_page: params.perPage,
    name: params.textFilterName,
    page: params.currentPage,
  };

  if (loadingComponent)
    yield put({
      type: Types.SHOW_lOADING,
      data: loadingComponent,
    });
  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      funnelServices.fetchListTemplate(queryParams)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.FETCH_LIST_TEMPLATE_SUCCESS,
        data: response.data.data.data,
        params: {
          ...params,
          totalItem: response.data.data.meta.total,
          totalPage: response.data.data.meta.last_page,
        },
      });

      if (loadingComponent) yield put({ type: Types.HIDE_LOADING });

      yield put({ type: ActionTypes.HIDE_LOADING });
      return;
    }
    toast.error(response.data.error);
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* handleInstallTemplate(action: any) {
  const { params, loadingComponent } = action;
  const uuidFunnel: FunnelManagement = yield select(
    (state: AppState) => state.funnel.funnelDetail.uuid
  );

  if (loadingComponent)
    yield put({
      type: Types.SHOW_lOADING,
      data: loadingComponent,
    });
  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.installTemplate(params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.FETCH_FUNNEL_DETAIL,
        uuid: uuidFunnel,
      });
      toast.success(response.data.message);

      yield put({ type: ActionTypes.HIDE_LOADING });
      return;
    }
    toast.error(response.data.error);
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

// funnel detail
function* fetchFunnelDetail(action: any) {
  const { uuid, loadingComponent } = action;
  if (loadingComponent)
    yield put({
      type: Types.SHOW_lOADING,
      data: loadingComponent,
    });
  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.fetchFunnelDetail(uuid)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.FETCH_FUNNEL_DETAIL_SUCCESS,
        data: response.data.data,
      });

      yield put({ type: ActionTypes.HIDE_LOADING });
      return;
    }
    toast.error(response.data.error);
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);

    if (error?.response.status === EStatusHttp.HTTP_NOT_FOUND) {
      window.location.href = '/not-found';
    }
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

// step funnel detail
function* fetchStepFunnelDetail(action: any) {
  const { uuid, loadingComponent } = action;
  if (loadingComponent)
    yield put({
      type: Types.SHOW_lOADING,
      data: loadingComponent,
    });
  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.fetchStepFunnelDetail(uuid)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      const screenEl: any = document.querySelector('.debug-screen-id');
      if (response.data.data?.pages[0]?.landing_file_url?.length) {
        screenEl.innerText = 'c005';
      } else {
        screenEl.innerText = 'c004';
      }

      yield put({
        type: Types.FETCH_STEP_FUNNEL_DETAIL_SUCCESS,
        data: response.data.data,
      });
      if (response.data.data?.pages[0]?.landing_file_url === '') {
        action.onSuccess(response.data.data?.pages[0]?.landing_file_url);
        const categoriesPageTemplate =
          store.getState().common.categoriesPageTemplate;
        const funnelStepUuidSelected =
          store.getState().funnel.funnelStepUuidSelected;
        const funnelSteps = store.getState().funnel.funnelDetail.steps;
        const funnelStepSelected = funnelSteps.find(
          (step: any) => step?.uuid === funnelStepUuidSelected
        );
        const funnelStepSubcategoryId =
          handleGetSubCategoryPageTemplateTypeIdDefault(
            funnelStepSelected?.funnel_step_subcategory_id,
            categoriesPageTemplate
          );
        yield put({
          type: CommonTypes.HANDLE_CURRENT_CATEGORY_AND_SUBCATEGORY,
          data: handleGetInfoPageTemplateBySubCategoryId(
            funnelStepSubcategoryId,
            categoriesPageTemplate
          ),
        });
        yield put({
          type: Types.FETCH_LIST_TEMPLATE,
          params: {
            ...paramsFilterListTemplateDefault,
            funnelStepSubCategoryId: funnelStepSubcategoryId,
          },
          loadingComponent: FunnelDetailLoading.LIST_TEMPLATE,
        });
        return;
      }

      yield put({ type: Types.HIDE_LOADING });
      return;
    }
    toast.error(response.data.error);
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* handleRenameFunnel(action: any) {
  const { params, loadingComponent } = action;
  if (loadingComponent)
    yield put({
      type: Types.SHOW_lOADING,
      data: loadingComponent,
    });
  const value = {
    uuid: params.uuid,
    name: params.newName,
  };
  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.renameFunnel(value)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      toast.success(response.data.message);
      yield put({
        type: Types.RENAME_FUNNEL_SUCCESS,
        name: response.data.data.name,
      });

      yield put({ type: ActionTypes.HIDE_LOADING });
      return;
    }
    toast.error(response.data.error);
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* renameStepFunnel(action: any) {
  const { params, onSuccess } = action;
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.renameStep(params)
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.RENAME_STEP_FUNNEL_SUCCESS,
        name: response.data.data.name,
        uuid: response.data.data.uuid,
        funnel_step_subcategory_id: params?.funnel_step_subcategory_id,
      });
      onSuccess();
      return;
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* createStepFunnel(action: any) {
  const { params, loadingComponent } = action;
  const value = {
    funnel_uuid: params.uuid,
    name: params.newName,
  };
  if (loadingComponent)
    yield put({
      type: Types.SHOW_lOADING,
      data: loadingComponent,
    });
  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.createStepFunnel(value)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      if (params.onSuccess) params.onSuccess(response.data.data);
      yield put({
        type: Types.FETCH_FUNNEL_DETAIL,
        uuid: params.uuid,
      });
    } else {
      yield put({ type: ActionTypes.HIDE_LOADING });
    }
    handleMessage(response);
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* deleteStepFunnel(action: any) {
  const { uuid, onSuccess, uuidFunnel, loadingComponent } = action;
  const value = {
    funnel_step_uuid: uuid,
  };
  if (loadingComponent)
    yield put({
      type: Types.SHOW_lOADING,
      data: loadingComponent,
    });
  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.removeStepFunnel(value)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.FETCH_FUNNEL_DETAIL,
        uuid: uuidFunnel,
      });
      toast.success(response.data.message);
      onSuccess();

      yield put({ type: ActionTypes.HIDE_LOADING });
      return;
    }
    toast.error(response.data.error);
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
    yield put({ type: ActionTypes.HIDE_LOADING });
    yield put({
      type: Types.FETCH_FUNNEL_DETAIL,
      uuid: uuidFunnel,
    });
  }
}

function* changeTemplateURL(action: any) {
  const { params, loadingComponent } = action;

  if (loadingComponent)
    yield put({
      type: Types.SHOW_lOADING,
      data: loadingComponent,
    });
  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.handleChangeTemplateURL(params.uuid, {
        path: params.url,
        name: params.name,
      })
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.FETCH_STEP_FUNNEL_DETAIL,
        uuid: params.uuid,
      });
      toast.success(response.data.message);
      return;
    }
    toast.error(response.data.error);
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: Types.HIDE_LOADING });
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* renameFormFunnel(action: any) {
  const { params, loadingComponent } = action;
  if (loadingComponent)
    yield put({
      type: Types.SHOW_lOADING,
      data: loadingComponent,
    });
  //TODO
  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.handleRenameFormFunnel(params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.RENAME_FORM_FUNNEL_SUCCESS,
        form_name: response.data.data.form_name,
      });
      toast.success(
        i18next.t(
          'funnel.detail.saga.funnelManagement.message.updateFormNameSuccess'
        )
      );

      yield put({ type: ActionTypes.HIDE_LOADING });
      return;
    }
    toast.error(response.data.error);
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* submitSettingStepFunnel(action: any) {
  const { params, loadingComponent } = action;
  if (loadingComponent)
    yield put({
      type: Types.SHOW_lOADING,
      data: loadingComponent,
    });
  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.submitSettingStepFunnel(params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.FETCH_STEP_SETTING,
        uuidStep: params.funnel_step_uuid,
      });
      toast.success(response.data.message);

      yield put({ type: ActionTypes.HIDE_LOADING });
      return;
    }
    toast.error(response.data.error);
    yield put({ type: ActionTypes.HIDE_LOADING });
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* fetchStepSetting(action: any) {
  const { uuidStep, loadingComponent } = action;

  if (loadingComponent)
    yield put({
      type: Types.SHOW_lOADING,
      data: loadingComponent,
    });
  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.fetchStepFunnelDetailSetting(uuidStep)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.FETCH_STEP_SETTING_SUCCESS,
        data: response.data.data,
      });

      if (loadingComponent) yield put({ type: Types.HIDE_LOADING });
      yield put({ type: ActionTypes.HIDE_LOADING });
      return;
    }
    toast.error(response.data.message);
    yield put({ type: ActionTypes.HIDE_LOADING });
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* restoresSettingStep(action: any) {
  const { params, loadingComponent } = action;
  const newParams = {
    page_uuid: params.uuidStep,
    restore_uuid: params.uuidRestore,
  };
  if (loadingComponent)
    yield put({
      type: Types.SHOW_lOADING,
      data: loadingComponent,
    });
  //TODO
  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.restoresSettingStep(newParams)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      toast.success(response.data.message);

      yield put({ type: ActionTypes.HIDE_LOADING });
      return;
    }
    toast.error(response.data.error);
    yield put({ type: ActionTypes.HIDE_LOADING });
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

// Access link funnel management
function* fetchListAccessLink(action: { type: string; uuidFunnel: string }) {
  const { uuidFunnel } = action;
  yield put({ type: Types.SHOW_LOADING_ACCESS_LINK });
  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.fetchListAccessLink(uuidFunnel)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.FETCH_LIST_ACCESS_FUNNEL_SUCCESS,
        data: response.data.data,
      });
      yield put({ type: Types.HIDDEN_LOADING_ACCESS_LINK });
      return;
    }
    toast.error(response.data.error);
    yield put({ type: Types.HIDDEN_LOADING_ACCESS_LINK });
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
    yield put({ type: Types.HIDDEN_LOADING_ACCESS_LINK });
  }
}

function* createAccessLink(action: any) {
  const { params, onSuccess } = action;
  //TODO
  yield put({ type: Types.SHOW_LOADING_ACCESS_LINK });
  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.createAccessLink(params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      toast.success(response.data.message);
      onSuccess();
      delay(500);
      yield put({
        type: Types.FETCH_LIST_ACCESS_FUNNEL,
        uuidFunnel: params.funnel_uuid,
      });
      return;
    }
    handleErrorMessage(response.data.error);
    yield put({ type: Types.HIDDEN_LOADING_ACCESS_LINK });
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
    yield put({ type: Types.HIDDEN_LOADING_ACCESS_LINK });
  }
}

function* removeAccessLink(action: any) {
  const { uuidLinkAccess } = action;
  const uuidFunnel: FunnelManagement = yield select(
    (state: AppState) => state.funnel.funnelDetail.uuid
  );

  yield put({ type: Types.SHOW_LOADING_ACCESS_LINK });

  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.removeAccessLink(uuidLinkAccess)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      toast.success(response.data.message);
      delay(500);
      yield put({
        type: Types.FETCH_LIST_ACCESS_FUNNEL,
        uuidFunnel,
      });
      return;
    }
    toast.error(response.data.error);
    yield put({ type: Types.HIDDEN_LOADING_ACCESS_LINK });
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
    yield put({ type: Types.HIDDEN_LOADING_ACCESS_LINK });
  }
}

function* updateAccessLink(action: any) {
  const { params, onSuccess } = action;
  const uuidFunnel: FunnelManagement = yield select(
    (state: AppState) => state.funnel.funnelDetail.uuid
  );
  yield put({ type: Types.SHOW_LOADING_ACCESS_LINK });

  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.updateAccessLink(params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      onSuccess();
      toast.success(response.data.message);
      delay(500);
      yield put({
        type: Types.FETCH_LIST_ACCESS_FUNNEL,
        uuidFunnel,
      });
      return;
    }
    handleErrorMessage(response.data.error);
    yield put({ type: Types.HIDDEN_LOADING_ACCESS_LINK });
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
    yield put({ type: Types.HIDDEN_LOADING_ACCESS_LINK });
  }
}

function* fetchProductFunnelAction(action: any) {
  const { funnel_step_uuid } = action;
  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.fetchFunnelProductAPI(funnel_step_uuid)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_PRODUCT_FUNNEL_COMPLETE,
        productFunnel: response.data.data,
      });
    } else {
      yield put({
        type: Types.GET_PRODUCT_FUNNEL_COMPLETE,
        productFunnel: {},
      });
      handleMessage(response);
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  }
}

// affiliate funnel
function* fetchAffiliateFunnelAction(action: any) {
  const { uuid, loadingComponent } = action;
  yield put({ type: Types.SHOW_lOADING, data: loadingComponent });

  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.fetchAffiliateFunnelAPI(uuid)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_AFFILIATE_FUNNEL_COMPLETE,
        affiliateFunnel: response.data.data,
      });
    } else {
      yield put({
        type: Types.GET_AFFILIATE_FUNNEL_COMPLETE,
        affiliateFunnel: {},
      });
      handleMessage(response);
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: Types.HIDE_LOADING });
  }
}

function* submitAffiliateFunnelSetting(action: any) {
  const { uuid, payload, onSuccess } = action;
  yield put({ type: ActionTypes.SHOW_LOADING });

  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.updateAffiliateFunnelAPI(uuid, payload)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({ type: Types.SUBMIT_AFFILIATE_FUNNEL_COMPLETE });
      onSuccess();
    }
    handleMessage(response);
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* fetchProductPlanAction() {
  try {
    const response: APIResponseType = yield call(() =>
      funnelServices.fetchProductPlanAPI()
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_PRODUCT_PLAN_COMPLETE,
        productPlan: response.data.data,
      });
    } else {
      yield put({
        type: Types.GET_PRODUCT_PLAN_COMPLETE,
        productPlan: [],
      });
      handleMessage(response);
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  }
}

function* getAllFunnelStepByGroupFunnel() {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(() =>
      funnel.getAllFunnelStepByGroupFunnel()
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_ALL_FUNNEL_STEP_BY_GROUP_FUNNEL_SUCCESS,
        data: response.data?.data,
      });
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}
// A/B test

function* createPageVariation(action: {
  type: string;
  params: IParamsCreatePageVariation;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      funnelServices.postCreatePageVariation,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
      return;
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* getListArchivedPage(action: {
  type: string;
  params: IParamsListArchivedPage;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      funnelServices.getListArchivedPage,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.FETCH_LIST_ARCHIVED_PAGE,
        data: response.data?.data,
        params: { ...action.params },
      });
      return;
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* declarePageWinner(action: {
  type: string;
  uuid: string;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      funnelServices.postDeclarePageWinner,
      action.uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
      return;
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* updateFunnelStep(action: {
  type: string;
  uuid: string;
  params: IParamsUpdateStepFunnel;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      funnelServices.postUpdateStepFunnel,
      action.uuid,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
      return;
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* restorePage(action: {
  type: string;
  uuid: string;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      funnelServices.postRestorePage,
      action.uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
      return;
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* updateTraffic(action: {
  type: string;
  params: IParamsUpdateTraffic;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      funnelServices.postUpdateTraffic,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
      return;
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* postStartABTest(action: { type: string; uuid: string }) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      funnelServices.postStartABTest,
      action.uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      yield put({
        type: Types.POST_START_AB_TEST_SUCCESS,
      });
      yield put({
        type: Types.FETCH_STEP_FUNNEL_DETAIL,
        uuid: action.uuid,
      });
      return;
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* postDeletePage(action: {
  type: string;
  uuid: string;
  funnel_uuid: string;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      funnelServices.postDeletePage,
      action.uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      yield put({
        type: Types.FETCH_STEP_FUNNEL_DETAIL,
        uuid: action.funnel_uuid,
      });
      return;
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

//component unmounted

export function* watchFetchListAccessLink() {
  let task: any;
  yield takeLatest(
    Types.FETCH_LIST_ACCESS_FUNNEL,
    function* (action: { type: string; uuidFunnel: string }) {
      if (task) {
        yield cancel(task);
      }
      task = yield fork(fetchListAccessLink, action);
    }
  );

  yield take(Types.COMPONENT_UNMOUNTED);
  if (task) {
    yield cancel(task);
  }
}

export default function* funnelManagement() {
  yield takeLatest(Types.FETCH_LIST_TEMPLATE, fetchListTemplate);
  yield takeLatest(Types.FETCH_FUNNEL_DETAIL, fetchFunnelDetail);
  yield takeLatest(Types.FETCH_STEP_FUNNEL_DETAIL, fetchStepFunnelDetail);

  yield takeLatest(Types.RENAME_FUNNEL, handleRenameFunnel);
  yield takeLatest(Types.RENAME_STEP_FUNNEL, renameStepFunnel);
  yield takeLatest(Types.CREATE_STEP_FUNNEL, createStepFunnel);
  yield takeLatest(Types.DELETE_STEP_FUNNEL, deleteStepFunnel);

  yield takeLatest(Types.INSTALL_TEMPLATE, handleInstallTemplate);
  yield takeLatest(Types.CHANGE_TEMPLATE_URL, changeTemplateURL);
  yield takeLatest(Types.RENAME_FORM_FUNNEL, renameFormFunnel);

  yield takeLatest(Types.SUBMIT_SETTING_STEP_FUNNEL, submitSettingStepFunnel);
  yield takeLatest(Types.FETCH_STEP_SETTING, fetchStepSetting);
  yield takeLatest(Types.RESTORES_STEP_SETTING, restoresSettingStep);
  yield fork(watchFetchListAccessLink);
  yield takeLatest(Types.ADD_LINK_ACCESS_FUNNEL, createAccessLink);
  yield takeLatest(Types.DELETE_LINK_ACCESS, removeAccessLink);
  yield takeLatest(Types.UPDATE_LINK_ACCESS_FUNNEL, updateAccessLink);

  yield takeLatest(Types.GET_PRODUCT_FUNNEL, fetchProductFunnelAction);
  yield takeLatest(Types.GET_AFFILIATE_FUNNEL, fetchAffiliateFunnelAction);
  yield takeLatest(Types.SUBMIT_AFFILIATE_FUNNEL, submitAffiliateFunnelSetting);
  yield takeLatest(Types.GET_PRODUCT_PLAN, fetchProductPlanAction);

  yield takeLatest(
    Types.GET_ALL_FUNNEL_STEP_BY_GROUP_FUNNEL,
    getAllFunnelStepByGroupFunnel
  );
  yield takeLatest(Types.CREATE_PAGE_VARIATION, createPageVariation);
  yield takeLatest(Types.DECLARE_PAGE_WINNER, declarePageWinner);
  yield takeLatest(Types.LIST_ARCHIVED_PAGE, getListArchivedPage);
  yield takeLatest(Types.UPDATE_FUNNEL_STEP, updateFunnelStep);
  yield takeLatest(Types.RESTORE_PAGE, restorePage);
  yield takeLatest(Types.UPDATE_TRAFFIC, updateTraffic);
  yield takeLatest(Types.POST_START_AB_TEST, postStartABTest);
  yield takeLatest(Types.POST_DELATE_PAGE, postDeletePage);
}
