// Project: 7612770651107e1659371833c56a9b25e733c4615968bb7c5582cdc92aa6249b
// summary funnels
interface ISummaryFunnel {
  funnelTrash: number;
  funnels: number;
  groupTrash: number;
  groups: number;
}

interface IStateSummaryFunnel {
  data: ISummaryFunnel;
  error: string;
}

const initialSummaryFunnel: IStateSummaryFunnel = {
  data: {
    funnelTrash: 0,
    funnels: 0,
    groupTrash: 0,
    groups: 0,
  },
  error: '',
};

export type { ISummaryFunnel, IStateSummaryFunnel };

export { initialSummaryFunnel };
