import funnel from '../../../../../assets/img/menu-icons/funnel.png';
import createFunnel from '../../../../../assets/img/menu-icons/create-funnel.png';
import listFunnel from '../../../../../assets/img/menu-icons/list-funnel.png';
import productR from '../../../../../assets/img/menu-icons/product-r.png';
import email from '../../../../../assets/img/menu-icons/email.png';
import manageUser from '../../../../../assets/img/menu-icons/manage-user.png';
import sale from '../../../../../assets/img/menu-icons/sale.png';
import myPage from '../../../../../assets/img/menu-icons/my-page-icon.png';
import stepMailCreateIcon from '../../../../../assets/img/menu-icons/create-step-mail.png';
import stepMailListIcon from '../../../../../assets/img/menu-icons/step-mail-list.png';
import mailListAllIcon from '../../../../../assets/img/menu-icons/mail-list-all.png';
import createNewMailListIcon from '../../../../../assets/img/menu-icons/create-new-mail-list.png';
import listMailListIcon from '../../../../../assets/img/menu-icons/list-mail-list.png';
import productIcon from '../../../../../assets/img/menu-icons/product-icon.png';
import createdProductIcon from '../../../../../assets/img/menu-icons/create-product-icon.png';
import accountSetting from '../../../../../assets/img/menu-icons/account-setting.png';
import logoutIcon from '../../../../../assets/img/menu-icons/logout-icon.png';
import userIcon from '../../../../../assets/img/menu-icons/user-icon-1.png';
import userIconMenuH001 from '../../../../../assets/img/menu-icons/icon-menu-user-h001.png';
import userIconMenuBlacklist from './../../../../../assets/img/menu-icons/icon-menu-user-blacklist.png';
import synthesisReports from './../../../../../assets/img/menu-icons/synthesis-reports.png';
import reportIcon from '../../../../../assets/img/menu-icons/report-icon.png';

import fixedPhraseIcon from '../../../../../assets/img/menu-icons/icon-fixed-phrase.svg';

const RenderAllImageSidebar = () => {
  return (
    <div className="d-none">
      <img src={funnel} alt="" />
      <img src={createFunnel} alt="" />
      <img src={listFunnel} alt="" />
      <img src={productR} alt="" />
      <img src={email} alt="" />
      <img src={manageUser} alt="" />
      <img src={sale} alt="" />
      <img src={myPage} alt="" />
      <img src={stepMailCreateIcon} alt="" />
      <img src={stepMailListIcon} alt="" />
      <img src={mailListAllIcon} alt="" />
      <img src={createNewMailListIcon} alt="" />
      <img src={listMailListIcon} alt="" />
      <img src={productIcon} alt="" />
      <img src={createdProductIcon} alt="" />
      <img src={accountSetting} alt="" />
      <img src={logoutIcon} alt="" />
      <img src={userIcon} alt="" />
      <img src={userIconMenuH001} alt="" />
      <img src={userIconMenuBlacklist} alt="" />
      <img src={synthesisReports} alt="" />
      <img src={reportIcon} alt="" />
      <img src={fixedPhraseIcon} alt="" />
    </div>
  );
};
export default RenderAllImageSidebar;
