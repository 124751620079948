import axiosClient from '..';
import { endPoints } from './config';

interface DomainParams {
  domain: string;
}

export const filterAllDomains = (params: DomainParams) => {
  return axiosClient.get(endPoints.filterAllDomains(), { params });
};

export const purchaseDomains = (params: DomainParams) => {
  return axiosClient.post(endPoints.purchaseDomain(), params);
};

export const getAllRecordForDomains = (domain_id: string) => {
  return axiosClient.get(endPoints.getAllRecordForDomains(domain_id));
};

interface ICreateRecordDNS {
  name: string;
  type: string;
  content: string;
  priority: any;
}

export const createRecordForDomains = (
  domain_id: string,
  params: ICreateRecordDNS
) => {
  return axiosClient.post(endPoints.createRecordForDomains(domain_id), {
    ...params,
    priority: 0,
  });
};

interface UpdateRecord extends ICreateRecordDNS {
  id_record: number;
}

export const updateRecordForDomains = (
  domain_id: string,
  params: UpdateRecord
) => {
  return axiosClient.post(endPoints.updateRecordForDomains(domain_id), {
    ...params,
    priority: 0,
  });
};
interface DeleteRecord {
  id_record: number;
}

export const deleteRecordForDomains = (
  domain_id: string,
  params: DeleteRecord
) => {
  return axiosClient.post(endPoints.deleteRecordForDomains(domain_id), {
    ...params,
    priority: 0,
  });
};

interface ReNewDomain {
  auto_renew: boolean;
}

export const reNewDomainSystem = (domain_id: string, params: ReNewDomain) => {
  return axiosClient.post(endPoints.reNewDomainSystem(domain_id), {
    ...params,
  });
};

export const updateSubDomainApi = (params: any) => {
  return axiosClient.post(endPoints.updateSubDomainUrl(), {
    ...params,
  });
};
