import axiosClient from '..';
import { eventAPI } from './api';
import {
  IParamCreateEvent,
  IParamGetSchedulesByWeek,
  IParamSaveSchedule,
  IParamsCopyGroupEvent,
  IParamsDeleteGroupEvent,
  IParamsGetListEvent,
  IParamsRenameGroupEvent,
} from './interface';

export const createEvent = (params: IParamCreateEvent) =>
  axiosClient.post(eventAPI.createEvent, params);

export const getListEvent = (params: IParamsGetListEvent) =>
  axiosClient.get(eventAPI.getListEvent, { params });

export const getListGroup = () => axiosClient.get(eventAPI.getListGroup);

export const copyGroupEvent = (params: IParamsCopyGroupEvent) =>
  axiosClient.post(eventAPI.copyGroupEvent, params);

export const renameGroupEvent = (params: IParamsRenameGroupEvent) =>
  axiosClient.post(eventAPI.renameGroupEvent, params);

export const deleteGroupEvent = (params: IParamsDeleteGroupEvent) =>
  axiosClient.post(eventAPI.deleteGroupEvent, params);

export const copyItemEvent = (uuid: string) =>
  axiosClient.post(eventAPI.copyItemEvent(uuid));

export const deleteItemEvent = (uuid: string) =>
  axiosClient.delete(eventAPI.detailEvent(uuid));

export const publishEvent = (uuid: string) =>
  axiosClient.post(eventAPI.publishEvent(uuid));

export const getDetailEvent = (uuid: string) =>
  axiosClient.get(eventAPI.detailEvent(uuid));

export const updateDetailEvent = (uuid: string, params: IParamCreateEvent) =>
  axiosClient.put(eventAPI.detailEvent(uuid), params);

export const getListEventByGroups = (groupsUuid: string) =>
  axiosClient.get(eventAPI.getListEventByGroups(groupsUuid));

export const saveSchedule = (groupsUuid: string, params: IParamSaveSchedule) =>
  axiosClient.post(eventAPI.saveSchedule(groupsUuid), params);

export const getSchedulesByWeek = (
  groupsUuid: string,
  params: IParamGetSchedulesByWeek
) => axiosClient.post(eventAPI.getSchedulesByWeek(groupsUuid), params);

export const deleteSchedules = (groupsUuid: string) =>
  axiosClient.delete(eventAPI.deleteSchedules(groupsUuid));

export const getListPageConnectEvents = (event_uuid: string) =>
  axiosClient.get(eventAPI.getListPageConnectEvents(event_uuid));
