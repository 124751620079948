import * as Types from '../../actions/funnel/types';

export interface IFunnelStepMetadata {
  noindex: boolean;
  nofollow: boolean;
  title: string;
  description: string;
  isUpdated?: boolean;
}

export const funnelStepMetadataInitialsState: IFunnelStepMetadata = {
  noindex: false,
  nofollow: false,
  title: '',
  description: '',
  isUpdated: false,
};

export default function funnelStepMetadataReducer(
  state: IFunnelStepMetadata = funnelStepMetadataInitialsState,
  action: any
): IFunnelStepMetadata {
  switch (action.type) {
    case Types.FETCH_MEMBERSITE_METADATA_REQUESTED:
    case Types.FETCH_FUNNEL_STEP_METADATA_REQUESTED: {
      return { ...state, isUpdated: false };
    }
    case Types.UPDATE_MEMBERSITE_METADATA_SUCCESS:
    case Types.UPDATE_FUNNEL_STEP_METADATA_SUCCESS:
    case Types.FETCH_MEMBERSITE_METADATA_SUCCESS:
    case Types.FETCH_FUNNEL_STEP_METADATA_SUCCESS: {
      return {
        ...state,
        noindex: action.payload.noindex,
        nofollow: action.payload.nofollow,
        title: action.payload.title,
        description: action.payload.description,
      };
    }
    case Types.APPLY_FUNNEL_STEP_PAGE_VERSIONS_FAILED:
    case Types.FETCH_MEMBERSITE_METADATA_FAILED:
    case Types.UPDATE_MEMBERSITE_METADATA_REQUESTED:
    case Types.UPDATE_FUNNEL_STEP_METADATA_REQUESTED:
    case Types.FETCH_FUNNEL_STEP_METADATA_FAILED:
    case Types.UPDATE_FUNNEL_STEP_METADATA_FAILED:
    default:
      return { ...state };
  }
}
