import { Col, Row, Space } from 'antd';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router';
import { useDispatch } from 'react-redux';

import {
  getTextTemplateType,
  INITIAL_VALUES,
  TemplatePages,
  validationSchema,
} from './config';
import VFieldInput from './commons/VFieldInput';
import HeaderPageTemplates from './commons/Header';
import { TConfirmPopup } from '../../../../components/atoms/TConfirmPopup';
import { fetchUrlTemplate } from '../../../../utilities/handleOpenEditPage';
import { ConnectAuthen } from '../../../../hoc/ConnectAuthContainer';
import { PAGE_TYPE } from '../../../../constants/app.constants';
import { getAllCategoriesPageTemplate } from '../../../../store/actions/common';
import { TSingleSelected } from '../../../../components/atoms/TSingleSelected';
import {
  deleteTemplate,
  showDetailTemplate,
  updateTemplates,
} from '../../../../store/actions/membersiteManagement';
import { MEMBERSITE } from '../../../../constants/menus';

interface RouteParams {
  id: string;
}

export type TemplateDetailProps = RouteComponentProps<RouteParams>;

const MembersiteTemplateDetailContainer: React.FC<TemplateDetailProps> = ({
  match,
  history,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [initialValues, setInitialValues] =
    useState<TemplatePages>(INITIAL_VALUES);

  useEffect(() => {
    dispatch(getAllCategoriesPageTemplate({ type: PAGE_TYPE.MEMBERSITE }));
    fetchTemplateDetails();
  }, []);

  const fetchTemplateDetails = () => {
    dispatch(
      showDetailTemplate(
        match.params.id,
        (res) => {
          setInitialValues((pre) => {
            return {
              ...pre,
              shared_url: `${process.env.APP_DOMAIN}/shared/course-templates/${match.params.id}`,
              name: res.name,
              funnel_step_categories: 0,
              type: getTextTemplateType(res.type),
              landing_file_url: res.landing_file_url,
            };
          });
        },
        () => {
          history.push(MEMBERSITE.LIST_MEMBERSITE_TEMPLATE);
        }
      )
    );
  };

  const handleCopyLinkShared = () => {
    navigator.clipboard.writeText(
      `${process.env.APP_DOMAIN}/shared/course-templates/${match.params.id}`
    );
    toast.success(t('templateDetail.memberSite.copyLinkShared'));
  };

  const handleConfirmAction = () => {
    TConfirmPopup(
      t('templateDetail.memberSite.confirmDelete'),
      'DELETE',
      {},
      handleOkeConfirm
    );
  };

  const handleOkeConfirm = async (_item: any, type: string) => {
    if (type === 'DELETE') {
      dispatch(
        deleteTemplate(match.params.id, () => {
          history.push(MEMBERSITE.LIST_MEMBERSITE_TEMPLATE);
        })
      );
    }
  };

  const handleUpdateTemplate = async (values: TemplatePages) => {
    dispatch(
      updateTemplates({ uuid: match.params.id, name: values.name }, () => {
        history.push(MEMBERSITE.LIST_MEMBERSITE_TEMPLATE);
      })
    );
  };

  const linkPreview =
    window.location.origin.replace('app.', '') +
    `/page-template/preview?uuid=${match.params.id}`;

  return (
    <ConnectAuthen>
      <HeaderPageTemplates />
      <FormControl className="mt-3" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col
          md={{ span: 24 }}
          lg={{ span: 24 }}
          sm={{ span: 24 }}
          xxl={{ span: 8 }}
          className="mt-3"
        >
          <Row justify="center">
            <ViewTemPlate>
              <div className="wrapper">
                <iframe
                  className="edit-page__iframe"
                  scrolling="no"
                  src={fetchUrlTemplate(initialValues.landing_file_url)}
                  title="edit-page"
                  height="100%"
                  width="100%"
                ></iframe>
                <Row className="btn-group" justify="center" gutter={[10, 10]}>
                  <Space direction="vertical" style={{ width: 140 }}>
                    <button className="w-100">
                      <a href={linkPreview} target="_blank" rel="noreferrer">
                        {t(
                          'funnel.selectedTemplate.selectTemplateStructure.template.buttonPreviewTemplate'
                        )}
                      </a>
                    </button>
                  </Space>
                </Row>
              </div>
            </ViewTemPlate>
          </Row>
        </Col>
        <Col
          md={{ span: 24 }}
          lg={{ span: 24 }}
          sm={{ span: 24 }}
          xxl={{ span: 16 }}
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleUpdateTemplate}
          >
            {({ handleSubmit, values, setValues, setFieldValue }) => {
              return (
                <Form
                  onSubmit={handleSubmit}
                  onBlur={() => {
                    setValues({
                      ...values,
                      name: values.name.trim(),
                    });
                  }}
                  className="mt-4"
                >
                  <VFieldInput
                    name="name"
                    placeholder={t('templateDetail.name.label')}
                    label={t('templateDetail.name.label')}
                  />

                  <VFieldInput
                    name="type"
                    placeholder={t('templateDetail.type.label')}
                    label={t('templateDetail.type.label')}
                    hasDisable
                  />

                  <Row justify="start" align="middle" className="mt-3">
                    <Col className="label" xs={24} sm={24} md={24} lg={5}>
                      <span>
                        {t('templateDetail.memberSite.category.label')}
                      </span>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={19}>
                      <TSingleSelected
                        disable
                        data={[
                          {
                            title: t(
                              'templateDetail.memberSite.categoryOption'
                            ),
                            value: 0,
                          },
                        ]}
                        className="custom-field-selected"
                        onChange={(value) => {
                          setFieldValue('funnel_step_categories', value);
                          setInitialValues({
                            ...values,
                            funnel_step_categories: value,
                          });
                        }}
                        defaultValue={values.funnel_step_categories}
                      />
                    </Col>
                  </Row>

                  <Row justify="start" align="middle" className="mt-3">
                    <Col className="label" xs={24} sm={24} md={24} lg={5}>
                      <span>{t('templateDetail.share.label')}</span>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={19}>
                      <small>{t('templateDetail.share.anonation')}</small>
                    </Col>
                  </Row>
                  <VFieldInput
                    name="shared_url"
                    placeholder={''}
                    label=""
                    hasDisable
                  />
                  <Row justify="start" align="middle" className="mt-3">
                    <Col className="label" xs={24} sm={24} md={24} lg={5}></Col>
                    <Col xs={24} sm={24} md={24} lg={19}>
                      <Space>
                        <Button
                          borderSmall
                          type="button"
                          onClick={handleCopyLinkShared}
                        >
                          {t('funnel.setting.copyFunnel')}
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                  <Row justify="end" align="middle" className="mt-3">
                    <Space>
                      <ButtonDeleted
                        onClick={handleConfirmAction}
                        type="button"
                      >
                        {t('common.text.deleteFull')}
                      </ButtonDeleted>
                      <Button>{t('common.button.saveFull')}</Button>
                    </Space>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Col>
      </FormControl>
    </ConnectAuthen>
  );
};

const Button = styled.button`
  border: 1px solid var(--t-border-color);
  padding: 5px 10px;
  background-color: var(--t-main-color);
  color: white;
  border-radius: ${(props: any) => (props.borderSmall ? '5px' : '20px')};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    border: 1px solid var(--t-main-color);
    background-color: white;
    color: var(--t-main-color);
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const ButtonDeleted = styled.button`
  border: 1px solid var(--t-border-color);
  padding: 5px 10px;
  background-color: var(--t-error-color);
  color: white;
  border-radius: 20px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    border: 1px solid var(--t-error-color);
    background-color: white;
    color: var(--t-error-color);
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const FormControl = styled(Row)`
  background-color: white;
  border-radius: 15px;
  padding: 15px 15px;
  .label {
    font-size: 0.85rem;
    font-weight: bold;
  }
  .custom-field-selected {
    width: 100% !important;
  }
  .custom-field-selected.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-radius: 8px !important;
    min-height: 40px !important;
    border: 1px solid #d9d9d9 !important;
    &:hover {
      border-color: var(--t-main-color) !important;
    }
  }
  .custom-field-selected.ant-select-single
    .ant-select-selector
    .ant-select-selection-item {
    line-height: 40px !important;
  }
  .custom-field-selected.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: #212529;
    background-color: #fff;
    border-color: var(--t-main-color);
    outline: 0;
    box-shadow: 0 0 0 1px var(--t-main-color);
    border-radius: 8px !important;

    .ant-select-arrow .anticon {
      svg {
        color: var(--t-main-color);
      }
    }
  }
`;

const ViewTemPlate = styled.div`
  padding: 15px;
  border-radius: 15px;
  height: 330px;
  .wrapper {
    width: 400px;
    height: 300px;
    border: 1px solid var(--t-border-color);
    border-radius: 15px;
    transition: ease-in-out 0.5s;
    overflow: hidden;
    position: relative;
  }
  .edit-page__iframe {
    width: 1204px;
    height: 900px;
    transform: scale(0.33);
    transform-origin: 0px 0px;
    pointer-events: none;
  }
  .btn-group {
    position: absolute;
    top: 40%;
    width: 400px;
    left: 4px;
    visibility: hidden;
    button {
      border-radius: 20px;
      width: 250px;
      padding: 7px 5px;
      font-size: 0.9rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &:disabled {
        cursor: not-allowed;
      }
      text-decoration: none;
      color: var(--t-main-color);
      background-color: transparent;
      a {
        text-decoration: none;
        color: var(--t-main-color);
        background-color: transparent;
      }

      border: 1px solid var(--t-main-color);
      background-color: white;
      &:hover {
        border: 1px solid var(--t-main-color);
        background-color: var(--t-main-color);
        color: white;
        a {
          color: white;
          background-color: transparent;
        }
      }
    }
  }
  &::before {
    width: 400px;
    height: 300px;
    top: 15px;
    bottom: 0;
    content: '';
    z-index: 2;
    position: absolute;
  }
  &:hover {
    &::before {
      background-color: var(--t-main-color);
      opacity: 0.4;
      border-radius: 15px;
    }
    .btn-group {
      visibility: visible;
      transition: ease-in-out 0.5s;
      z-index: 3;
    }
  }
`;

export default MembersiteTemplateDetailContainer;
