export const GET_MAIL_LIST_USER = 'GET_MAIL_LIST_USER';
export const GET_MAIL_LIST_USER_SUCCESS = 'GET_MAIL_LIST_USER_SUCCESS';

export const GET_FUNNELS_BY_MAIL_LIST = 'GET_FUNNELS_BY_MAIL_LIST';
export const FETCH_FUNNELS_BY_MAIL_LIST_SUCCESS =
  'FETCH_FUNNELS_BY_MAIL_LIST_SUCCESS';
export const SCROLL_FUNNELS_BY_MAIL_LIST_SUCCESS =
  'SCROLL_FUNNELS_BY_MAIL_LIST_SUCCESS';

export const GET_ACCESS_FUNNEL_BY_FUNNEL = 'GET_ACCESS_FUNNEL_BY_FUNNEL';
export const FETCH_ACCESS_FUNNEL_BY_FUNNEL_SUCCESS =
  'FETCH_ACCESS_FUNNEL_BY_FUNNEL_SUCCESS';
export const SCROLL_ACCESS_FUNNEL_BY_FUNNEL_SUCCESS =
  'SCROLL_ACCESS_FUNNEL_BY_FUNNEL_SUCCESS';
export const HIDE_LOADING_GET_DATA_COMPONENT =
  'HIDE_LOADING_GET_DATA_COMPONENT';

export const DELETE_USER_MAIL_LIST = 'DELETE_USER_MAIL_LIST';
export const GET_EXPORT_CONFIG = 'GET_EXPORT_CONFIG';
export const UPDATE_EXPORT_CONFIG = 'UPDATE_EXPORT_CONFIG';
export const EXPORT_USER_MAIL_LIST_CSV = 'EXPORT_USER_MAIL_LIST_CSV';
export const CHANGE_PURCHASE_DATE = 'CHANGE_PURCHASE_DATE';
export const GET_LIST_EVENT_DATE = 'GET_LIST_EVENT_DATE';
export const PUT_LIST_EVENT_DATE = 'PUT_LIST_EVENT_DATE';
export const LIST_EVENT_DATE_FILTER = 'LIST_EVENT_DATE_FILTER';

export const GET_DATE_CALENDAR = 'GET_DATE_CALENDAR';
export const GET_OPTIONS_CALENDAR = 'GET_OPTIONS_CALENDAR';
export const PUT_SCHEDULE = 'PUT_SCHEDULE';
export const GET_SCHEDULES_BY_TOKEN = 'GET_SCHEDULES_BY_TOKEN';
