import { API_BASE_URL } from '../config';
import {
  IParamsDeleteUserMailListItemInURL,
  ParamEditStatusPaymentMethodURL,
  ParamsEditPaymentMethodURL,
  ParamsGetPaymentMethodByUser,
  ParamsUpdateUserMailListItemInURL,
  ParamsConditionItemPageForm,
} from './interface';

const API = {
  // create mail list
  CREATE_MAIL_LIST: `${API_BASE_URL}/mail-list/create`,
  FETCH_MAIL_LIST: `${API_BASE_URL}/mail-list/create`,
  CREATE_USER_IN_MAIL_LIST: `${API_BASE_URL}/mail-list/contact/add`,
  // import csv
  GET_ALL_STEP_MAILS: (uuid: string) =>
    `${API_BASE_URL}/mail-list/step-mail?uuid=${uuid}`,
  GET_MAIL_LIST_BY_ID: (uuid: string) =>
    `${API_BASE_URL}/mail-list/update?uuid=${uuid}`,
  GET_ALL_FORM_BY_ID: (uuid: string) =>
    `${API_BASE_URL}/mail-list/list-form?uuid=${uuid}`,
  GET_STATUS_IMPORT: (uuid: string) =>
    `${API_BASE_URL}/mail-list/status-import/${uuid}`,
  DOWN_LOAD_MAIL_LIST: (uuid: string) =>
    `${API_BASE_URL}/mail-list/${uuid}/export-csv-form`,
  IMPORT_CSV_CREATE_USER_MAIL_LIST: `${API_BASE_URL}/mail-list/contact/import`,
  // list mail list
  fetchListMailList: `${API_BASE_URL}/mail-list/list`,
  fetchListAllMailList: `${API_BASE_URL}/mail-lists`,
  fetchListAllMailListWithTrashed: `${API_BASE_URL}/mail-lists-with-trashed`,
  fetchAllGroupMailList: `${API_BASE_URL}/group/list/MAIL_LIST`,
  removeGroupMailList: `${API_BASE_URL}/group/delete`,
  copyGroup: `${API_BASE_URL}/group/copy`,
  removeMailList: `${API_BASE_URL}/mail-list/delete`,
  copyMailList: `${API_BASE_URL}/mail-list/copy`,
  getListFunnelSteps: (uuid: string) =>
    `${API_BASE_URL}/mail-list/${uuid}/funnel-steps`,
  getListEventDate: (uuid: string) =>
    `${API_BASE_URL}/mail-list/list-event-date/${uuid}`,
  //mail list detail
  updateMailList: `${API_BASE_URL}/mail-list/update`,
  // create step mail
  createStepMail: `${API_BASE_URL}/step-mail/create`,
  updateStepMail: `${API_BASE_URL}/step-mail/update`,
  fetchAllGroupStepMail: `${API_BASE_URL}/group/list/STEP_MAIL`,
  fetchStepMailDetail: `${API_BASE_URL}/step-mail/mails`,
  getMailIntegration: `${API_BASE_URL}/send-mails/mail-integration`,
  // list step mail list
  getListStepMail: `${API_BASE_URL}/step-mails`,
  publishStepMail: `${API_BASE_URL}/step-mail/publish`,
  copyStepMail: `${API_BASE_URL}/step-mail/copy`,
  renameGroup: `${API_BASE_URL}/group/rename`,
  deleteStepMail: `${API_BASE_URL}/step-mail/delete`,
  // mail
  copyMail: `${API_BASE_URL}/mails/copy`,
  fetchListMail: `${API_BASE_URL}/list/step-mails`,
  updateMail: `${API_BASE_URL}/step-mail/item/update`,
  removeMail: `${API_BASE_URL}/mails/delete`,
  copyStepMailItem: `${API_BASE_URL}/step-mail/item/copy`,
  removeStepMailItem: `${API_BASE_URL}/step-mail/remove/connect`,
  createMail: `${API_BASE_URL}/mails/create`,
  connectStepMail: `${API_BASE_URL}/step-mail/connect`,
  // mail detail
  mailDetail: (uuid: string) => `${API_BASE_URL}/mails/${uuid}`,
  fetchListSignature: `${API_BASE_URL}/signature`,
  saveMail: `${API_BASE_URL}/mails`,
  fetchFixedPhrase: `${API_BASE_URL}/fixed-phrase/get`,
  // list mail bulk
  fetchListMailBulk: `${API_BASE_URL}/send-mails`,
  removeMailBulk: `${API_BASE_URL}/send-mails/delete`,
  // detail bulk mail
  fetchDetailBulkMail: (uuid: string, type: string) =>
    `${API_BASE_URL}/send-mails/${uuid}/${type}`,
  fetchDataMailListInPageCreateBulkMail: `${API_BASE_URL}/email-lists`,
  // create bulk mail
  createBulkMail: `${API_BASE_URL}/send-mails/create`,
  fetchListBlackListUser: `${API_BASE_URL}/black-lists`,
  sendMailTest: `${API_BASE_URL}/send-mails/test-send-mail`,
  updateMailBulk: `${API_BASE_URL}/send-mails/update`,
  sendNowStatus: (uuid: string) =>
    `${API_BASE_URL}/send-mails/${uuid}/send-now-status`,
  //create remind mail
  fetchAllGroupRemindMail: `${API_BASE_URL}/group/list/REMIND_MAIL`,
  fetchRemindMailDetail: `${API_BASE_URL}/remind-mail/mails`,
  createRemindMail: `${API_BASE_URL}/remind-mail/create`,
  updateRemindMail: `${API_BASE_URL}/remind-mail/update`,
  //list remind mail
  getListRemindMail: `${API_BASE_URL}/remind-mails`,
  copyRemindMail: `${API_BASE_URL}/remind-mail/copy`,
  deleteRemindMail: `${API_BASE_URL}/remind-mail/delete`,
  publishRemindMail: `${API_BASE_URL}/remind-mail/publish`,
  //remind mail item
  createRemindMailItem: `${API_BASE_URL}/remind-mail/item/create`,
  updateRemindMailItem: `${API_BASE_URL}/remind-mail/item/update`,
  copyRemindMailItem: `${API_BASE_URL}/remind-mail/item/copy`,
  deleteRemindMailItem: `${API_BASE_URL}/remind-mail/item/delete`,
  // remind mail content
  remindMailContentDetail: (uuid: string) =>
    `${API_BASE_URL}/remind-mail/item/${uuid}`,
  submitRemindMailContent: `${API_BASE_URL}/remind-mail/item/content`,
  // fixed phrase
  fetchListFixedPhase: `${API_BASE_URL}/fixed-phrase`,
  fetchFixedPhaseDetail: (uuid: string) =>
    `${API_BASE_URL}/fixed-phrase/${uuid}`,
  createFixedPhrase: `${API_BASE_URL}/fixed-phrase/create`,
  updateFixedPhrase: `${API_BASE_URL}/fixed-phrase/update`,
  deleteFixedPhrase: `${API_BASE_URL}/fixed-phrase/delete`,
  // Alternative character
  getUserFormValidToken: (token: string) =>
    `${API_BASE_URL}/mailers/user-form/valid-token/${token}`,
  updateUserFormValidToken: (token: string) =>
    `${API_BASE_URL}/mailers/user-form/${token}`,
  getPaymentOrderInfo: (token: string) =>
    `${API_BASE_URL}/mailers/user-form/complete-payment/${token}`,
  cancelScheduleEventDate: (token: string) =>
    `${API_BASE_URL}/mailers/user-form/${token}`,
  updatePaymentUnivaPay: (orderID: string) =>
    `${API_BASE_URL}/univapay/complete-payment/${orderID}/capture`,
  /*-------------------------- user mail list -----------------------------*/
  getUserMailList: (uuid: string) => `${API_BASE_URL}/mail-list/${uuid}`,
  updateMailSubcribe: `${API_BASE_URL}/mail-list/stop-mail`,
  getPaymentMethodByUser: ({
    userUUID,
    transactionID,
  }: ParamsGetPaymentMethodByUser) =>
    `${API_BASE_URL}/customer/${userUUID}/transactions/${transactionID}`,
  deleteUserMailListItem: ({
    uuid,
    mailListUserID,
  }: IParamsDeleteUserMailListItemInURL) =>
    `${API_BASE_URL}/mail-list/${uuid}/delete/${mailListUserID}`,
  configExportGoogleSheet: (uuid: string) =>
    `${API_BASE_URL}/mail-list/${uuid}/export-google-sheet`,
  exportUserMailListCSV: (uuid: string) =>
    `${API_BASE_URL}/mail-list/${uuid}/export`,
  updateUserMailListItem: ({
    uuid,
    userUUID,
  }: ParamsUpdateUserMailListItemInURL) =>
    `${API_BASE_URL}/mail-list/${uuid}/update/${userUUID}`,
  editPaymentMethodByUser: ({
    userUUID,
    orderID,
  }: ParamsEditPaymentMethodURL) =>
    `${API_BASE_URL}/customer/${userUUID}/transactions/${orderID}/change-payment-method`,
  editPaymentStatusByUser: ({
    userUUID,
    orderID,
    newStatus,
  }: ParamEditStatusPaymentMethodURL) =>
    `${API_BASE_URL}/customer/${userUUID}/transactions/${orderID}/set-status/${newStatus}`,
  getGenerateCompletePaymentLink: (mailListUserId: any) =>
    `${API_BASE_URL}/signature/generate-complete-payment-link/${mailListUserId}`,
  getDataMailListAndConditionMailRequest: `${API_BASE_URL}/data-mail-lists`,
  getConditionItemPageForm: (params: ParamsConditionItemPageForm) => {
    const itemCodeTypeQuery = params.type ? `&type=${params.type}` : '';
    const itemCodeQuery = params.item_code
      ? `?item_code=${params.item_code}`
      : '';
    const nameQuery = params.name ? `&name=${params.name}` : '';
    return `${API_BASE_URL}/condition/item/${params.mailListUuid}${itemCodeQuery}${itemCodeTypeQuery}${nameQuery}`;
  },
  getFunnelsByMailListURL: `${API_BASE_URL}/mail-list/funnels`,
  getAccessFunnelsByFunnelURL: (uuid: string) =>
    `${API_BASE_URL}/funnel/${uuid}/access-funnels`,
  postChangePurchaseDate: `${API_BASE_URL}/customer/change-purchase-date`,
  mailListUsersSetting: (uuid: string) =>
    `${API_BASE_URL}/mail-list-users/${uuid}/settings/event-date`,
  mailListUsersSettingCalendar: (uuid: string) =>
    `${API_BASE_URL}/mail-list-users/${uuid}/settings/calendar`,
  mailListUsersSchedules: (uuid: string) =>
    `${API_BASE_URL}/mail-list-users/${uuid}/settings/calendar/reservations`,
  getSchedulesByToken: () => `${API_BASE_URL}/reservations`,
};

export default API;
