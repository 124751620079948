export enum EStatusHttp {
  HTTP_TOKEN_INVALID = 99,
  HTTP_FORBIDDEN = 403,
  HTTP_UNAUTHORIZED = 401,
  HTTP_OK = 200,
  HTTP_TOO_MANY_REQUESTS = 429,
  HTTP_NOT_FOUND = 404,
  HTTP_BAD_REQUEST = 400,
  HTTP_INTERNAL_SERVER_ERROR = 500,
  HTTP_CONFLICT = 409,
  HTTP_UN_PROCESSABLE_ENTITY = 422,
  HTTP_SERVICE_UNAVAILABLE = 503,
}

export enum EStatusCodeData {
  FAILS = 'fails',
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface IOptions {
  /*
   * option show toast message when call stack fails
   */
  error?: boolean;
  /*
   * option show toast message when call stack success
   */
  success?: boolean;
  /*
   * option show type LOADING_PAGE Redux
   */
  loading?: boolean;
}
export interface IBaseResponse<T> {
  message?: string;
  status: EStatusCodeData;
  error?: any;
  data: T;
}

export interface IResponse<T> {
  /*
   *HTTP response status codes indicate whether a specific HTTP request has been successfully completed.
   */
  status: EStatusHttp;
  /*
   * This makes the type parameter visible to all the other members of the interface.
   */
  data?: IBaseResponse<T>;
}

export interface IHttpResponse<T> {
  status: EStatusHttp | number;
  data?: T;
  message?: string;
  error?: any;
}

export interface IHttpResponseError {
  status: EStatusHttp | number;
  data?: {
    status?: string;
    message?: string;
    error?: string | string[];
  };
}

export interface INotification {
  success?: boolean;
  error?: boolean;
}

export interface IBaseErrorParameter {
  hasLoading?: boolean;
  notification?: INotification;
}

export interface IBaseParameter<T> {
  hasLoading?: boolean;
  notification?: INotification;
  params?: T;
}
