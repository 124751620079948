import axiosClient from '..';
import { API } from './api';
import { ICompany } from './interface';

export const getCompanyById = (companyId: string) =>
  axiosClient.get(API.getCompanyDetail(companyId));

export const updateCompanySetting = (query: ICompany) =>
  axiosClient.post(API.updateCompanySetting(query.uuid), query);

export const getAllCompanies = () => axiosClient.get(API.getAllCompanies());

export const swapCompany = (uuid: string) =>
  axiosClient.post(API.swapCompany(uuid));
