import moment from 'moment';
import { EOptionRangeType, optionRangeTypes } from '../config/common.config';
import {
  DateFormat,
  DFormat,
  MAX_PASSWORD,
  MIN_PASSWORD,
} from '../constants/app.constants';
import { DateTimeFilterProps } from '../interfaces/common/useMostInterface';
import i18n from '../i18n/i18n';

export function convertDateTimeJA(date: any) {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);

  return `${year}年${month}月${day}日`;
}

export function getRangeDateTimeToString(value: DateTimeFilterProps) {
  if (value.typeFilter !== 8) {
    const data = optionRangeTypes.find(
      (x) => x.typeFilter === value.typeFilter
    );
    return data?.title;
  } else {
    return `${convertDateTimeJA(value.dateFrom)} ~ ${convertDateTimeJA(
      value.dateTo
    )}`;
  }
}

export function formatDateFilterForType(params: any) {
  if (params.typeFilter === 0) {
    return {
      dFrom: '',
      dTo: '',
    };
  } else {
    const dateFormat = formatDateFilter(params);

    return {
      dFrom: dateFormat.dFrom,
      dTo: dateFormat.dTo,
      dateFormat,
    };
  }
}

export function formatDateFilterForTypeUTC(params: any) {
  if (params.typeFilter === 0) {
    return {
      dFrom: '',
      dTo: '',
    };
  } else {
    const dateFormat = formatDateFilter(params);
    return {
      dFrom: dateFormat.dFrom,
      dTo: dateFormat.dTo,
      dateFormat,
    };
  }
}

export function formatDateFilter(params: any) {
  return {
    dFrom: moment(params.dateFrom).startOf('day').format(DFormat),
    dTo: moment(params.dateTo).endOf('day').startOf('second').format(DFormat),
  };
}

export function formatDateFilterFormat(params: any) {
  return {
    dFrom: moment(params.dateFrom).startOf('day').format(DateFormat),
    dTo: moment(params.dateTo)
      .endOf('day')
      .startOf('second')
      .format(DateFormat),
  };
}

export function getJapanDate(date: string) {
  if (!date) return '';
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);

  return `${year}年${month}月${day}日 `;
}

export const getStringTimeFilter = (value: any) => {
  if (value.typeFilter !== EOptionRangeType.CUSTOM) {
    const data = optionRangeTypes.find(
      (x) => x.typeFilter === value.typeFilter
    );
    return data?.title;
  } else {
    return `${getJapanDate(value.dateFrom)} ~ ${getJapanDate(value.dateTo)}`;
  }
};

export const clipboard = (function (window, document, navigator) {
  let textArea: any;

  function isOS() {
    return navigator.userAgent.match(/ipad|iphone/i);
  }

  function createTextArea(text: any) {
    textArea = document.createElement('textArea');
    textArea.value = text;
    document.body.appendChild(textArea);
  }

  function selectText() {
    let range, selection;

    if (isOS()) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  }

  function copyToClipboard() {
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }

  return {
    copy: function (text: string) {
      createTextArea(text);
      selectText();
      copyToClipboard();
    },
  };
})(window, document, navigator);

export const isDevelopmentEnv = () => process.env.NODE_ENV === 'development';

export const messErrorPassword = (password: string) => {
  if (!password) return i18n.t('manageUser.editUserInfo.Form.error.required');
  if (password.length < MIN_PASSWORD)
    return i18n.t('myPage.profile.setting.edit.passwordMin', {
      min: MIN_PASSWORD,
    });

  if (password.length > MAX_PASSWORD)
    return i18n.t('funnel.list.message.funnelNameMaxLength', {
      maxLength: MAX_PASSWORD,
    });

  return '';
};
