import i18next from 'i18next';
import { IMultipleSelectedProps } from '../components/atoms/TMultipleSelected/TMultipleSelected';

export const API_ERROR = {
  TOKEN_INVALID: 99,
  ACCESS_DENIED: 403,
  TOKEN_EXPIRED: 401,
};

export const DFormat = 'YYYY-MM-DD HH:mm:ss';
export const DTimeFormat = 'YYYY-MM-DD HH:mm:00';
export const DTimeFormatMinus = 'YYYY-MM-DD HH:mm';
export const DateFormat = 'YYYY-MM-DD';
export const DTimeFormatHM = 'HH:mm';

export const FBAppId = '902230173445867';
export const GGClientId =
  '280432883949-src9gcsd191bac6js7ajo1cr6taktluv.apps.googleusercontent.com';

export const STATUS_API = {
  FAIL: 'fail',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const MAIL_BULK = {
  SENT: 'SENT',
  SENDING: 'SENDING',
  SCHEDULE: 'SCHEDULE',
  DELETE: 'DELETE',
};

export const TYPE_DETIAL_BULK_MAIL = {
  COPY: 'copy',
  SHOW: 'show',
};

export const GROUP_TYPE = {
  FUNNEL: 'FUNNEL',
  FORM: 'FORM',
  MAIL: 'MAIL',
  PRODUCT: 'PRODUCT',
  STEP_MAIL: 'STEP_MAIL',
  MAIL_LIST: 'MAIL_LIST',
  TAG: 'TAG',
  REMIND_MAIL: 'REMIND_MAIL',
  TIMER: 'TIMER',
  CALENDAR: 'CALENDAR',
};

export const SEND_MAIL_STATUS = {
  SENDING: 'SENDING',
  SENT: 'SENT',
  SCHEDULE: 'SCHEDULE',
  FAILED: 'FAILED',
};
export const MINE_TYPE = [
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  '.csv',
  'text/csv',
];

export const PAYMENT_STATUS = {
  FAIL: 'FAIL',
  SUCCESS: 'SUCCESS',
};

export const ACCEPT_CSV = '.csv, text/csv, application/vnd.ms-excel';
export const ACCEPT_FILE_TYPE = 'video/*,image/*,.pdf';
export const ACCEPT_IMAGE_ONLY = 'image/*';
export const MAX_FILE_SIZE = 12 * 1024 * 1024; // byte;
export const NONE_SELECT_DROPDOWN = -1;

export const COL_SIZE = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  TWELVE: 12,
  THIRTEEN: 13,
  FOURTEEN: 14,
  FIFTEEN: 15,
  SIXTEEN: 16,
  SEVENTEEN: 17,
  EIGHTEEN: 18,
  NINETEEN: 19,
  TWENTY: 20,
  TWENTY_ONE: 21,
  TWENTY_TWO: 22,
  TWENTY_THREE: 23,
  TWENTY_FOUR: 24,
};

export const FIXED_PHRASE = {
  COPY: 'COPY',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
};

export const BUTTON_TYPE = {
  DEFAULT: 'button',
  SUBMIT: 'submit',
  TEXT: 'text',
};

export const TIME_ZONES = [
  {
    offset: 'GMT-12:00',
    name: 'Etc/GMT-12',
  },
  {
    offset: 'GMT-11:00',
    name: 'Etc/GMT-11',
  },
  {
    offset: 'GMT-11:00',
    name: 'Pacific/Midway',
  },
  {
    offset: 'GMT-10:00',
    name: 'America/Adak',
  },
  {
    offset: 'GMT-09:00',
    name: 'America/Anchorage',
  },
  {
    offset: 'GMT-09:00',
    name: 'Pacific/Gambier',
  },
  {
    offset: 'GMT-08:00',
    name: 'America/Dawson_Creek',
  },
  {
    offset: 'GMT-08:00',
    name: 'America/Ensenada',
  },
  {
    offset: 'GMT-08:00',
    name: 'America/Los_Angeles',
  },
  {
    offset: 'GMT-07:00',
    name: 'America/Chihuahua',
  },
  {
    offset: 'GMT-07:00',
    name: 'America/Denver',
  },
  {
    offset: 'GMT-06:00',
    name: 'America/Belize',
  },
  {
    offset: 'GMT-06:00',
    name: 'America/Cancun',
  },
  {
    offset: 'GMT-06:00',
    name: 'America/Chicago',
  },
  {
    offset: 'GMT-06:00',
    name: 'Chile/EasterIsland',
  },
  {
    offset: 'GMT-05:00',
    name: 'America/Bogota',
  },
  {
    offset: 'GMT-05:00',
    name: 'America/Havana',
  },
  {
    offset: 'GMT-05:00',
    name: 'America/New_York',
  },
  {
    offset: 'GMT-04:30',
    name: 'America/Caracas',
  },
  {
    offset: 'GMT-04:00',
    name: 'America/Campo_Grande',
  },
  {
    offset: 'GMT-04:00',
    name: 'America/Glace_Bay',
  },
  {
    offset: 'GMT-04:00',
    name: 'America/Goose_Bay',
  },
  {
    offset: 'GMT-04:00',
    name: 'America/Santiago',
  },
  {
    offset: 'GMT-04:00',
    name: 'America/La_Paz',
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Argentina/Buenos_Aires',
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Montevideo',
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Araguaina',
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Godthab',
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Miquelon',
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Sao_Paulo',
  },
  {
    offset: 'GMT-03:30',
    name: 'America/St_Johns',
  },
  {
    offset: 'GMT-02:00',
    name: 'America/Noronha',
  },
  {
    offset: 'GMT-01:00',
    name: 'Atlantic/Cape_Verde',
  },
  {
    offset: 'GMT',
    name: 'Europe/Belfast',
  },
  {
    offset: 'GMT',
    name: 'Africa/Abidjan',
  },
  {
    offset: 'GMT',
    name: 'Europe/Dublin',
  },
  {
    offset: 'GMT',
    name: 'Europe/Lisbon',
  },
  {
    offset: 'GMT',
    name: 'Europe/London',
  },
  {
    offset: 'UTC',
    name: 'UTC',
  },
  {
    offset: 'GMT+01:00',
    name: 'Africa/Algiers',
  },
  {
    offset: 'GMT+01:00',
    name: 'Africa/Windhoek',
  },
  {
    offset: 'GMT+01:00',
    name: 'Atlantic/Azores',
  },
  {
    offset: 'GMT+01:00',
    name: 'Atlantic/Stanley',
  },
  {
    offset: 'GMT+01:00',
    name: 'Europe/Amsterdam',
  },
  {
    offset: 'GMT+01:00',
    name: 'Europe/Belgrade',
  },
  {
    offset: 'GMT+01:00',
    name: 'Europe/Brussels',
  },
  {
    offset: 'GMT+02:00',
    name: 'Africa/Cairo',
  },
  {
    offset: 'GMT+02:00',
    name: 'Africa/Blantyre',
  },
  {
    offset: 'GMT+02:00',
    name: 'Asia/Beirut',
  },
  {
    offset: 'GMT+02:00',
    name: 'Asia/Damascus',
  },
  {
    offset: 'GMT+02:00',
    name: 'Asia/Gaza',
  },
  {
    offset: 'GMT+02:00',
    name: 'Asia/Jerusalem',
  },
  {
    offset: 'GMT+03:00',
    name: 'Africa/Addis_Ababa',
  },
  {
    offset: 'GMT+03:00',
    name: 'Asia/Riyadh89',
  },
  {
    offset: 'GMT+03:00',
    name: 'Europe/Minsk',
  },
  {
    offset: 'GMT+03:30',
    name: 'Asia/Tehran',
  },
  {
    offset: 'GMT+04:00',
    name: 'Asia/Dubai',
  },
  {
    offset: 'GMT+04:00',
    name: 'Asia/Yerevan',
  },
  {
    offset: 'GMT+04:00',
    name: 'Europe/Moscow',
  },
  {
    offset: 'GMT+04:30',
    name: 'Asia/Kabul',
  },
  {
    offset: 'GMT+05:00',
    name: 'Asia/Tashkent',
  },
  {
    offset: 'GMT+05:30',
    name: 'Asia/Kolkata',
  },
  {
    offset: 'GMT+05:45',
    name: 'Asia/Katmandu',
  },
  {
    offset: 'GMT+06:00',
    name: 'Asia/Dhaka',
  },
  {
    offset: 'GMT+06:00',
    name: 'Asia/Yekaterinburg',
  },
  {
    offset: 'GMT+06:30',
    name: 'Asia/Rangoon',
  },
  {
    offset: 'GMT+07:00',
    name: 'Asia/Bangkok',
  },
  {
    offset: 'GMT+07:00',
    name: 'Asia/Novosibirsk',
  },
  {
    offset: 'GMT+08:00',
    name: 'Etc/GMT+8',
  },
  {
    offset: 'GMT+08:00',
    name: 'Asia/Hong_Kong',
  },
  {
    offset: 'GMT+08:00',
    name: 'Asia/Krasnoyarsk',
  },
  {
    offset: 'GMT+08:00',
    name: 'Australia/Perth',
  },
  {
    offset: 'GMT+08:45',
    name: 'Australia/Eucla',
  },
  {
    offset: 'GMT+09:00',
    name: 'Asia/Irkutsk',
  },
  {
    offset: 'GMT+09:00',
    name: 'Asia/Yakutsk',
  },
  {
    offset: 'GMT+09:00',
    name: 'Asia/Seoul',
  },
  {
    offset: 'GMT+09:00',
    name: 'Asia/Tokyo',
  },
  {
    offset: 'GMT+09:30',
    name: 'Australia/Adelaide',
  },
  {
    offset: 'GMT+09:30',
    name: 'Australia/Darwin',
  },
  {
    offset: 'GMT+09:30',
    name: 'Pacific/Marquesas',
  },
  {
    offset: 'GMT+10:00',
    name: 'Etc/GMT+10',
  },
  {
    offset: 'GMT+10:00',
    name: 'Australia/Brisbane',
  },
  {
    offset: 'GMT+10:00',
    name: 'Australia/Hobart',
  },
  {
    offset: 'GMT+10:30',
    name: 'Australia/Lord_Howe',
  },
  {
    offset: 'GMT+11:00',
    name: 'Asia/Vladivostok',
  },
  {
    offset: 'GMT+11:30',
    name: 'Pacific/Norfolk',
  },
  {
    offset: 'GMT+12:00',
    name: 'Etc/GMT+12',
  },
  {
    offset: 'GMT+12:00',
    name: 'Asia/Anadyr',
  },
  {
    offset: 'GMT+12:00',
    name: 'Asia/Magadan',
  },
  {
    offset: 'GMT+12:00',
    name: 'Pacific/Auckland',
  },
  {
    offset: 'GMT+12:45',
    name: 'Pacific/Chatham',
  },
  {
    offset: 'GMT+13:00',
    name: 'Pacific/Tongatapu',
  },
  {
    offset: 'GMT+14:00',
    name: 'Pacific/Kiritimati',
  },
];

export const CACHE_TYPES = {
  CREATE_BULK_MAIL: 'CREATE_BULK_MAIL',
  CREATE_BULK_MAIL_SENDER_FORM: 'CREATE_BULK_MAIL_SENDER_FORM',
  CREATE_BULK_MAIL_SPECIFY_MAIL: 'CREATE_BULK_MAIL_SPECIFY_MAIL',
  IS_SPECIFY_MAIL: 'IS_SPECIFY_MAIL',
  CREATE_BULK_MAIL_SPECIFY_MAIL_COPY: 'CREATE_BULK_MAIL_SPECIFY_MAIL_COPY',
  IS_SPECIFY_MAIL_COPY: 'IS_SPECIFY_MAIL_COPY',
  CURRENT_TIMEZONE: 'CURRENT_TIMEZONE',
  CREATE_TAG: 'CREATE_TAG',
  CREATE_REMIND_MAIL: 'CREATE_REMIND_MAIL',
};

export const CACHE_TIME_OUT = 250;

export const TITLE_MENU = {
  FUNNEL: 'funnel',
  REGISTER_PRODUCT: 'register-product',
  MAIL: 'mail',
  USER: 'user',
  REPORT: 'report',
  MY_PAGE: 'mypage',
  MEMBERSITE: 'membersite',
  AFFILIATE: 'affiliate',
  CALENDAR: 'calendar',
};

export const CURRENT_DOMAIN = window.location.origin;
export const GET_LOCALHOST = `https://localhost:8080`;

export const OPTION_FILTER_GROUP = {
  ALL: 'all',
  RATE: 'rate',
};

// group funnels
export const DEFAULT_GROUPS: IMultipleSelectedProps[] = [
  {
    label: '全てのグループ',
    title: '全てのグループ',
    value: OPTION_FILTER_GROUP.ALL,
  },
  {
    label: 'お気に入りファネル',
    title: 'お気に入りファネル',
    value: OPTION_FILTER_GROUP.RATE,
  },
];

// group funnels
export const DEFAULT_MEMBERSITE_GROUPS: IMultipleSelectedProps[] = [
  {
    label: i18next.t('membersite.common.filter.allCourse'),
    title: i18next.t('membersite.common.filter.allCourse'),
    value: OPTION_FILTER_GROUP.ALL,
  },
  {
    label: i18next.t('membersite.common.filter.favoriteCourse'),
    title: i18next.t('membersite.common.filter.favoriteCourse'),
    value: OPTION_FILTER_GROUP.RATE,
  },
];

export const DEVICE_LOGO = `${process.env.APP_DOMAIN}/upload-files/images/logo/logos-01-01.jpg`;

export const DEFAULT_OPTIONS = {
  page: 1,
  per_page: 20,
};

export const MAX_CHANNEL_ACCESS_STANDARD = {
  access: 6,
  type_day: 1,
  type_week: 2,
};

export enum ESortType {
  ASC = 'asc',
  DESC = 'desc',
  BLANK = '',
}

export const DEFAULT_PAGE_TEMPLATE_TYPE = 0;

export enum PAGE_TYPE {
  FUNNEL = 1,
  MEMBERSITE = 2,
}

export enum TemplateTypeId {
  allTemplate = 0,
  systemTemplate = 1,
  myTemplate = 2,
  shareTemplate = 3,
}

export const OPTIONS_FILTER_TEMPLATE_TYPE = [
  {
    label: i18next.t('templateType.allTemplate'),
    title: i18next.t('templateType.allTemplate'), // All template
    value: TemplateTypeId.allTemplate,
  },
  {
    label: i18next.t('templateType.systemTemplate'),
    title: i18next.t('templateType.systemTemplate'), // System template
    value: TemplateTypeId.systemTemplate,
  },
  {
    label: i18next.t('templateType.myTemplate'),
    title: i18next.t('templateType.myTemplate'), // My template
    value: TemplateTypeId.myTemplate,
  },
  {
    label: i18next.t('templateType.shareTemplate'),
    title: i18next.t('templateType.shareTemplate'), // Shared template
    value: TemplateTypeId.shareTemplate,
  },
];

export interface iColorEvent {
  name: string;
  hex: HEX_COLOR;
}

export enum HEX_COLOR {
  TOMATO = '#D50000',
  FLAMINGO = '#E67C73',
  TANGERINE = '#F4511E',
  BANANA = '#F6BF26',
  SAGE = '#33B679',
  BASIL = '#0B8043',
  PEACOCK = '#039BE5',
  BLUEBERRY = '#3F51B5',
  LAVENDER = '#7986CB',
  GRAPE = '#8E24AA',
  GRAPHITE = '#616161',
  MAIN = '#2cb596',
  EMPEROR = '#515151',
}

export const COLORS_EVENT: iColorEvent[] = [
  { name: 'Tomato', hex: HEX_COLOR.TOMATO },
  { name: 'Flamingo', hex: HEX_COLOR.FLAMINGO },
  { name: 'Tangerine', hex: HEX_COLOR.TANGERINE },
  { name: 'Banana', hex: HEX_COLOR.BANANA },
  { name: 'Sage', hex: HEX_COLOR.SAGE },
  { name: 'Basil', hex: HEX_COLOR.BASIL },
  { name: 'Peacock', hex: HEX_COLOR.PEACOCK },
  { name: 'Blueberry', hex: HEX_COLOR.BLUEBERRY },
  { name: 'Lavender', hex: HEX_COLOR.LAVENDER },
  { name: 'Grape', hex: HEX_COLOR.GRAPE },
  { name: 'Graphite', hex: HEX_COLOR.GRAPHITE },
  { name: 'Emperor', hex: HEX_COLOR.EMPEROR },
];

export const COLORS_MEMBER_SITE: iColorEvent[] = [
  ...COLORS_EVENT,
  {
    name: 'Maim',
    hex: HEX_COLOR.MAIN,
  },
];

export enum ETypePlan {
  STANDARD_MONTH = 1,
  STANDARD_YEAR = 2,
  PREMIUM_MONTH = 3,
  PREMIUM_YEAR = 4,
}

export const MIN_PASSWORD = 8;
export const MAX_PASSWORD = 100;
