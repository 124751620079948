import i18next from 'i18next';
import {
  createValidationSchema,
  inputValidation,
} from '../../../../config/validations.config';
import { TemplateTypeId } from '../../../../constants/app.constants';

export interface TemplatePages {
  name: string;
  landing_file_url: string;
  funnel_step_categories: number;
  type: string;
  shared_url: string;
  idPageType: number;
}

export const INITIAL_VALUES: TemplatePages = {
  name: '',
  landing_file_url: '',
  funnel_step_categories: 0,
  type: '',
  shared_url: '',
  idPageType: 1,
};

export const validationSchema = createValidationSchema({
  name: inputValidation({}),
});

export const getTextTemplateType = (type: number) => {
  switch (type) {
    case TemplateTypeId.myTemplate:
      return i18next.t('templateType.myTemplate');
    case TemplateTypeId.shareTemplate:
      return i18next.t('templateType.shareTemplate');
    default:
      return '';
  }
};

export const SPLIT_KEY = '&&&&&';
