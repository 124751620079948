export const DATA_TYPES = {
  FUNNEL: 'ファネル',
  SEND_MAIL: '一括配信メール',
  STEP_MAIL: 'ステップメール',
  FUNNEL_STEP: 'ファネルステップ',
  USER: 'ユーザー',
  PRODUCT: '商品',
  MAIL_LIST: 'メールリスト',
  TAG: 'タグ',
};

export interface ItemContentSearch {
  url: string;
  content: string;
  subContent?: string;
}
