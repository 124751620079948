import {
  SortField,
  SortOrder,
} from '../../../containers/Funnel/List/@DataTables/Components/types';
import { IStatePageListFunnel } from '../../actions/funnel/pageList';
import * as Types from '../../actions/funnel/types';

export const initialPageListFunnel: IStatePageListFunnel = {
  meta: {
    total: 0,
    current_page: 1,
    last_page: 2,
    per_page: 20,
  },
  payload: {
    page: 1,
    perPage: 20,
    group_uuids: '',
    flag_favorite: 0,
    sort_type: SortOrder.DESC,
    sort_name: SortField.DATETIME,
    type: 1,
  },
  data: [],
  error: '',
};

export default function pageListFunnelReducer(
  state: IStatePageListFunnel = initialPageListFunnel,
  action: any
): IStatePageListFunnel {
  switch (action.type) {
    case Types.FETCH_PAGE_LIST_FUNNEL_FAILED: {
      return { ...state };
    }
    case Types.FETCH_PAGE_LIST_FUNNEL_SUCCESS: {
      return {
        ...state,
        ...action.data,
      };
    }
    case Types.FETCH_PAGE_LIST_FUNNEL_FAILED: {
      return {
        ...state,
        error: action.error,
      };
    }
    default:
      return { ...state };
  }
}
