import React from 'react';
import './TDropdown.scss';
import smallDown from '../../../../assets/img/menu-icons/small-down-white.png';
import smallUp from '../../../../assets/img/menu-icons/small-down.png';
import { Dropdown } from 'antd';

interface TDropdownProps {
  title?: string;
  isOpen: boolean;
  onClick: (value: boolean) => void;
  className?: string;
  handleOvelay: any;
  popupPlacement?: any;
  iconButton?: any;
  isNotMaxWidth?: boolean;
}
const TDropdown: React.FC<TDropdownProps> = ({
  onClick,
  title,
  isOpen,
  className,
  handleOvelay,
  iconButton,
  popupPlacement = 'bottomRight',
  isNotMaxWidth,
}) => {
  const classTitle = `txt-title ${isNotMaxWidth ? '' : 'max-width'}`;
  return (
    <Dropdown
      trigger={['click']}
      overlay={handleOvelay}
      visible={isOpen}
      onVisibleChange={onClick}
      placement={popupPlacement}
    >
      {iconButton ? (
        iconButton
      ) : (
        <button
          type="button"
          className={`t-dropdown-mkt d-flex ${isOpen && 'active'} ${className}`}
        >
          <span className={classTitle}>{title}</span>
          <img
            src={isOpen ? smallDown : smallUp}
            className="small-down"
            alt="small-down"
          />
        </button>
      )}
    </Dropdown>
  );
};

export default TDropdown;
