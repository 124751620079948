import { toast } from 'react-toastify';
import { hideLoading } from '../store/actions/loadingAction';
import { store } from '../store/configureStore';

export const STATUS_CODE = {
  SUCCESS: 200,
  NOT_FOUND: 400,
  NO_ACCESS_TOKEN: 99,
  TOKEN_INVALID: 401,
};

export enum StatusResponse {
  SUCCESS = 0,
  ERROR = 1,
  INTERNAL_SERVER_ERROR = 2,
  EXPORT_SERVER = 3,
}

export enum ResponseStatus {
  FAILS = 'fails',
  ERROR = 'error',
  SUCCESS = 'success',
  SERVER_ERROR = 'serverError',
}

export interface IHttpResponse<T> {
  status: StatusResponse;
  data?: T;
  message?: string;
  statusCode: number;
}

export interface IShowError {
  error?: boolean;
  success?: boolean;
  loading?: boolean;
}

export function handleMessage(res: any) {
  const { status, error, message } = res.data;
  switch (status) {
    case ResponseStatus.FAILS:
      const getError = Object.keys(error);
      toast.error(error[getError[0]][0]);
      break;
    case ResponseStatus.ERROR:
      toast.error(error);
      break;
    case ResponseStatus.SUCCESS:
      toast.success(message);
      break;
    default:
      return;
  }
}

export function getErrorResponse<T>(res: any): IHttpResponse<T> {
  const { status, error } = res.data;
  switch (status) {
    case ResponseStatus.FAILS:
      const getError = Object.keys(error);
      return {
        message: error[getError[0]][0],
        status: StatusResponse.ERROR,
        statusCode: res.data.code,
      };
    case ResponseStatus.ERROR:
      return {
        message: error,
        status: StatusResponse.ERROR,
        statusCode: res.data.code,
      };
    case ResponseStatus.SERVER_ERROR: {
      handleMessage({
        data: { status: ResponseStatus.SERVER_ERROR, error: error.message },
      });
      return {
        status: StatusResponse.INTERNAL_SERVER_ERROR,
        statusCode: 500,
      };
    }
    default:
      return {
        status: StatusResponse.EXPORT_SERVER,
        statusCode: res.status,
        data: res.data,
      };
  }
}

export function handleResponse<T>(
  res: any,
  showError: IShowError = {
    success: false,
    error: false,
    loading: false,
  }
): IHttpResponse<T> {
  if (res.status === 200 && res.data.code === 200) {
    if (showError.loading) {
      store.dispatch(hideLoading());
    }
    if (showError.success) handleMessage(res);
    return {
      data: res.data.data,
      status: StatusResponse.SUCCESS,
      message: res.data.message,
      statusCode: res.data.code,
    };
  } else {
    if (showError.loading) {
      store.dispatch(hideLoading());
    }
    if (showError.error) handleMessage(res);
    return getErrorResponse<T>(res);
  }
}
