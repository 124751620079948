export interface IMeta {
  current_page: number;
  per_page: number;
  total: number;
}

export interface IFunnelByMailList {
  data: any[];
  meta: IMeta;
}

export interface IAccessFunnelByFunnel {
  data: any[];
  meta: IMeta;
}

export interface IAccessChannelState {
  isLoading: boolean;
  funnel: IFunnelByMailList;
  access_funnel: IAccessFunnelByFunnel;
}

export const initialState: IAccessChannelState = {
  isLoading: false,
  funnel: {
    data: [],
    meta: {
      current_page: 1,
      per_page: 20,
      total: 1,
    },
  },
  access_funnel: {
    data: [],
    meta: {
      current_page: 1,
      per_page: 20,
      total: 1,
    },
  },
};
