export const CREATE_EVENT = 'CREATE_EVENT';
export const GET_LIST_GROUP = 'GET_LIST_GROUP';
export const GET_LIST_GROUP_SUCCESS = 'GET_LIST_GROUP_SUCCESS';
export const GET_LIST_EVENT = 'GET_LIST_EVENT';
export const GET_LIST_EVENT_SUCCESS = 'GET_LIST_EVENT_SUCCESS';
export const COPY_GROUP_EVENT = 'COPY_GROUP_EVENT';
export const RENAME_GROUP_EVENT = 'RENAME_GROUP_EVENT';
export const DELETE_GROUP_EVENT = 'DELETE_GROUP_EVENT';
export const COPY_ITEM_EVENT = 'COPY_ITEM_EVENT';
export const DELETE_ITEM_EVENT = 'DELETE_ITEM_EVENT';
export const PUBLISH_EVENT = 'PUBLISH_EVENT';
export const GET_DETAIL_EVENT = 'GET_DETAIL_EVENT';
export const PUT_DETAIL_EVENT = 'PUT_DETAIL_EVENT';
export const GET_LIST_EVENT_BY_GROUPS = 'GET_LIST_EVENT_BY_GROUPS';
export const GET_SCHEDULES_BY_WEEK = 'GET_SCHEDULES_BY_WEEK';
export const DELETE_SCHEDULES = 'DELETE_SCHEDULES';
export const GET_LIST_EVENT_BY_GROUPS_SUCCESS =
  'GET_LIST_EVENT_BY_GROUPS_SUCCESS';
export const GET_SCHEDULES_BY_WEEK_SUCCESS = 'GET_SCHEDULES_BY_WEEK_SUCCESS';
export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
export const MOVE_SCHEDULE = 'MOVE_SCHEDULE';
export const MOVE_SCHEDULE_FAIL = 'MOVE_SCHEDULE_FAIL';
export const MOVE_SCHEDULE_SUCCESS = 'MOVE_SCHEDULE_SUCCESS';
export const ADD_SCHEDULE_SUCCESS = 'ADD_SCHEDULE_SUCCESS';
export const DELETE_SCHEDULE_LOCAL = 'DELETE_SCHEDULE_LOCAL';
export const GET_LIST_PAGES_CONNECT_EVENT = 'GET_LIST_PAGES_CONNECT_EVENT';
export const CREATE_SCHEDULE_OVER_DAY = 'CREATE_SCHEDULE_OVER_DAY';
export const CREATE_SCHEDULE_OVER_DAY_SUCCESS =
  'CREATE_SCHEDULE_OVER_DAY_SUCCESS';
export const CREATE_SCHEDULE_OVER_DAY_FAIL = 'CREATE_SCHEDULE_OVER_DAY_FAIL';
export const MOVE_SCHEDULE_OVER_DAY = 'MOVE_SCHEDULE_OVER_DAY';
export const MOVE_SCHEDULE_OVER_DAY_FAIL = 'MOVE_SCHEDULE_OVER_DAY_FAIL';
export const MOVE_SCHEDULE_OVER_DAY_SUCCESS = 'MOVE_SCHEDULE_OVER_DAY_SUCCESS';
export const HIDE_REST_SCHEDULE_OVER_DAY = 'HIDE_REST_SCHEDULE_OVER_DAY';
export const SHOW_REST_SCHEDULE_OVER_DAY = 'SHOW_REST_SCHEDULE_OVER_DAY';
export const CLEAR_SETTING_SCHEDULE = 'CLEAR_SETTING_SCHEDULE';
