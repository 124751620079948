import { convertDataPartnerList } from '../../../containers/Affiliate/Partner/List/helper';
import { converDataSubscriberList } from '../../../containers/Affiliate/Subscriber/config';
import * as Types from './../../actions/affiliate/types';
import {
  initialAffiliateManagement,
  InitialAffiliateManagement,
} from './interface';

export default function affiliateManagementReducer(
  state: InitialAffiliateManagement = initialAffiliateManagement,
  action: any
) {
  switch (action.type) {
    // Affiliate Setting n001
    case Types.FETCH_AFFILIATE_SETTING_SUCCESS:
      return {
        ...state,
        affiliateSetting: {
          affiliate_setting: {
            ...action.detailSetting.affiliate_setting,
            domain_uuid:
              action.detailSetting.affiliate_setting.domain_uuid || 'default',
          },
          domains: action.detailSetting.domains,
          mail_integrations: action.detailSetting.mail_integrations,
        },
      };

    // Affiliate Partner List n002
    case Types.FETCH_PARTNER_LIST_SUCCESS:
      return {
        ...state,
        partnerList: {
          data: convertDataPartnerList(action.affiliateList.data),
          meta: {
            currentPage: action.affiliateList.meta.current_page,
            from: action.affiliateList.meta.from,
            pageSize: action.affiliateList.meta.per_page,
            totalPage: action.affiliateList.meta.total,
            lastPage: action.affiliateList.meta.last_page,
          },
        },
      };
    case Types.SUBMIT_PARTNER_LIST_SUCCESS:
      return {
        ...state,
        dataUpdateFailure: action.dataUpdateFailure,
      };

    case Types.UPDATE_PARTNER_LIST:
      return {
        ...state,
        data: action.data,
      };

    // Affiliate Detail n002-1
    case Types.FETCH_DETAIL_PARTNER_LIST_SUCCESS:
      return {
        ...state,
        detailPartnerList: action.detailPartnerList,
      };

    /* --------------- affiliate bonus n004 ------------------ */
    case Types.FETCH_DATA_BONUS_SUCCESS: {
      return {
        ...state,
        dataBonus: {
          data: action.data.data,
          information: {
            ...state.dataBonus.information,
            ...action.data.information,
          },
        },
      };
    }

    /* --------------- affiliate subscriber n003 ------------------ */
    case Types.FETCH_SUBSCRIBER_LIST_SUCCESS:
      return {
        ...state,
        subscriberList: {
          data: converDataSubscriberList(action.subscriberList.data),
          meta: {
            currentPage: action.subscriberList.meta.current_page,
            from: action.subscriberList.meta.from,
            pageSize: Number(action.subscriberList.meta.per_page),
            totalPage: action.subscriberList.meta.total,
            lastPage: action.subscriberList.meta.last_page,
          },
        },
      };

    case Types.UPDATE_SUBSCRIBER_LIST:
      return {
        ...state,
        subscriberList: {
          data: action.params,
          meta: {
            ...state.subscriberList.meta,
          },
        },
      };
    default:
      return { ...state };
  }
}
