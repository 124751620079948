export const FETCH_LIST_TEMPLATE_MEMBERSITE = 'FETCH_LIST_TEMPLATE_MEMBERSITE';

export const RENAME_MEMBERSITE = 'RENAME_MEMBERSITE';
export const RENAME_MEMBERSITE_SUCCESS = 'RENAME_MEMBERSITE_SUCCESS';

export const RENAME_STEP_MEMBERSITE = 'RENAME_STEP_MEMBERSITE';

export const DELETE_STEP_FUNNEL_SUCCESS = 'DELETE_STEP_FUNNEL_SUCCESS';

export const CREATE_STEP_MEMBERSITE_SUCCESS = 'CREATE_STEP_MEMBERSITE_SUCCESS';

export const INSTALL_TEMPLATE_MEMBERSITE = 'INSTALL_TEMPLATE_MEMBERSITE';
export const INSTALL_TEMPLATE_SUCCESS = 'INSTALL_TEMPLATE_SUCCESS';

export const CHANGE_TEMPLATE_URL_MEMBERSITE = 'CHANGE_TEMPLATE_URL_MEMBERSITE';
export const CHANGE_TEMPLATE_URL_MEMBERSITE_SUCCESS =
  'CHANGE_TEMPLATE_URL_MEMBERSITE_SUCCESS';

export const RENAME_FORM_MEMBERSITE = 'RENAME_FORM_MEMBERSITE';

export const RESTORES_STEP_SETTING_MEMBERSITE =
  'RESTORES_STEP_SETTING_MEMBERSITE';
export const RESTORES_STEP_SETTING_MEMBERSITE_SUCCESS =
  'RESTORES_STEP_SETTING_MEMBERSITE_SUCCESS';

export const FETCH_LIST_RESTORES_MEMBERSITE = 'FETCH_LIST_RESTORES_MEMBERSITE';
export const FETCH_LIST_RESTORES_MEMBERSITE_SUCCESS =
  'FETCH_LIST_RESTORES_MEMBERSITE_SUCCESS';

export const SHOW_lOADING = 'SHOW_lOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const FETCH_LIST_ACCESS_MEMBERSITE = 'FETCH_LIST_ACCESS_MEMBERSITE';
export const FETCH_LIST_ACCESS_MEMBERSITE_SUCCESS =
  'FETCH_LIST_ACCESS_MEMBERSITE_SUCCESS';

export const ADD_LINK_ACCESS_MEMBERSITE = 'ADD_LINK_ACCESS_MEMBERSITE';
export const ADD_LINK_ACCESS_MEMBERSITE_SUCCESS =
  'ADD_LINK_ACCESS_MEMBERSITE_SUCCESS';

export const DELETE_LINK_ACCESS_MEMBERSITE = 'DELETE_LINK_ACCESS_MEMBERSITE';
export const DELETE_LINK_ACCESS_MEMBERSITE_SUCCESS =
  'DELETE_LINK_ACCESS_MEMBERSITE_SUCCESS';

export const UPDATE_LINK_ACCESS_MEMBERSITE = 'UPDATE_LINK_ACCESS_MEMBERSITE';
export const UPDATE_LINK_ACCESS_MEMBERSITE_SUCCESS =
  'UPDATE_LINK_ACCESS_MEMBERSITE_SUCCESS';

export const SHOW_LOADING_ACCESS_LINK_MEMBERSITE =
  'SHOW_LOADING_ACCESS_LINK_MEMBERSITE';
export const HIDDEN_LOADING_ACCESS_LINK_MEMBERSITE =
  'HIDDEN_LOADING_ACCESS_LINK_MEMBERSITE';

export const TOGGLE_MODAL_SETTING_ACCESS_LINK_MEMBERSITE =
  'TOGGLE_MODAL_SETTING_ACCESS_LINK';
export const HIDDEN_MODAL_SETTING_ACCESS_LINK_MEMBERSITE =
  'HIDDEN_MODAL_SETTING_ACCESS_LINK';

export const MEMBERSITE_STEP_UUID_SELECTED_SUCCESS =
  'MEMBERSITE_STEP_UUID_SELECTED_SUCCESS';

// course
export const GET_DETAIL_COURSE = 'GET_DETAIL_COURSE';
export const GET_DETAIL_COURSE_SUCCESS = 'GET_DETAIL_COURSE_SUCCESS';
export const POST_UPDATE_SECTION = 'POST_UPDATE_SECTION';
export const POST_DELETE_SECTION = 'POST_DELETE_SECTION';
export const POST_CREATE_SECTION = 'POST_CREATE_SECTION';
export const POST_CREATE_LESSONS = 'POST_CREATE_LESSONS';
export const GET_SHOW_LESSONS = 'GET_SHOW_LESSONS';
export const GET_SHOW_LESSONS_SUCCESS = 'GET_SHOW_LESSONS_SUCCESS';
export const POST_UPDATE_LESSONS = 'POST_UPDATE_LESSONS';
export const POST_DUPLICATE_LESSONS = 'POST_DUPLICATE_LESSONS';
export const POST_DELETE_LESSONS = 'POST_DELETE_LESSONS';
export const POST_CHANGE_POSITION_SECTION = 'POST_CHANGE_POSITION_SECTION';
export const POST_CHANGE_POSITION_LESSON = 'POST_CHANGE_POSITION_LESSON';
export const SET_SKELETON = 'SET_SKELETON';
export const GET_DATA_TEMPLATE = 'GET_DATA_TEMPLATE';
export const GET_DATA_TEMPLATE_SUCCESS = 'GET_DATA_TEMPLATE_SUCCESS';
export const GET_LIST_USER_COURSE = 'GET_LIST_USER_COURSE';
export const GET_LIST_USER_COURSE_SUCCESS = 'GET_LIST_USER_COURSE_SUCCESS';
export const UPDATE_PARAMS_FILTER_LIST_USER_COURSE =
  'UPDATE_PARAMS_FILTER_LIST_USER_COURSE';
export const POST_CHANGE_ACTIVE = 'POST_CHANGE_ACTIVE';
export const POST_DOWNLOAD_CSV = 'POST_DOWNLOAD_CSV';
export const GET_LIST_COMPANY_TEMPLATE = 'GET_LIST_COMPANY_TEMPLATE';
export const GET_LIST_COMPANY_TEMPLATE_SUCCESS =
  'GET_LIST_COMPANY_TEMPLATE_SUCCESS';
export const POST_UPDATE_TEMPLATE = 'POST_UPDATE_TEMPLATE';
export const GET_SHOW_DETAIL_TEMPLATE = 'GET_SHOW_DETAIL_TEMPLATE';
export const POST_DELETE_TEMPLATE = 'POST_DELETE_TEMPLATE';
export const GET_SHARED_TEMPLATE = 'GET_SHARED_TEMPLATE';
export const POST_SHARED_TEMPLATE = 'POST_SHARED_TEMPLATE';
export const GET_STATUS_IMPORT_CSV_COURSE = 'GET_STATUS_IMPORT_CSV_COURSE';
