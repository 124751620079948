export interface ProductState {
  product_detail: IDetailProduct;
}

export interface IDetailProduct {
  description: string;
  flag_publish: number;
  group_uuid: string;
  has_affiliate_funnel: boolean;
  is_bump_product: number;
  name: string;
  name_display: string;
  payment_methods: IPaymentMethod[];
  uuid: string;
}

export interface IPaymentMethod {
  description: string;
  detail: string;
  is_installment: number;
  payment_method_id: number;
  price: number;
  sys_user_payment_gateway_id: number;
}

export const initialProductState: ProductState = {
  product_detail: {
    description: '',
    flag_publish: 0,
    group_uuid: '',
    has_affiliate_funnel: false,
    is_bump_product: 0,
    name: '',
    name_display: '',
    payment_methods: [],
    uuid: '',
  },
};
