import './TConfirmPopup.scss';
import { Modal } from 'antd';
import { ReactNode } from 'react';

function TConfirmPopup(
  title: string,
  type: string,
  data: any,
  handleOkeConfirm: (data: any, type: string) => void,
  content?: string | ReactNode,
  handleCancel?: () => void,
  cancelText = 'キャンセル',
  okText = 'OK'
) {
  Modal.confirm({
    title: (
      <div
        className="modal-title"
        dangerouslySetInnerHTML={{ __html: title }}
      />
    ),
    content: content,
    icon: null,
    okText: okText,
    cancelText: cancelText,
    onOk: () => handleOkeConfirm(data, type),
    className: 't-confirm-popup',
    onCancel: handleCancel,
  });
}

export default TConfirmPopup;
