import React from 'react';
import './TSwitch.scss';
import { Switch } from 'antd';

export interface TSwitchProps {
  checked: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: () => void;
  size?: 'small' | 'default';
}

const TSwitch: React.FC<TSwitchProps> = ({
  checked,
  className,
  onChange,
  disabled,
  size = 'default',
}) => (
  <Switch
    disabled={disabled}
    checked={checked}
    className={`${className}`}
    onChange={onChange}
    size={size}
  />
);

export default TSwitch;
