import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  COL_SIZE,
  CURRENT_DOMAIN,
  GET_LOCALHOST,
} from '../../../constants/app.constants';
import { swapCompany } from '../../../store/actions/myPage/company';
import {
  getAuthenticatorCookies,
  setAuthenticatorCookies,
  setAuthenticatorCookiesLocal,
} from '../../../utilities/cookies';
import DrawerSettingComponent from './components/DrawerSetting';
import SingleSelect, { SelectOption } from './components/SingleSelect';
import './style.scss';
import { hideLoading } from '../../../store/actions/loadingAction';
import { handleMessage } from '../../../utilities/common.utilities';
import { AppState } from '../../../store/reducers/rootReducer';
import { AUTHEN_PAGE } from '../../../constants/menus';

const SettingUserComponent: React.FC<any> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [optionsCompanies, setCompanies] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [isOpenDrawer, setOpenDrawer] = useState<boolean>(false);

  let auth = getAuthenticatorCookies();

  const profile = useSelector((state: AppState) => state.myPage.profile);

  const { companies } = profile.data;

  const handleSwapCompany = async (value: any) => {
    setLoading(true);
    dispatch(
      swapCompany(value, {
        success: (res) => {
          dispatch(hideLoading());
          if (CURRENT_DOMAIN === GET_LOCALHOST) {
            setAuthenticatorCookiesLocal(res.data);
          } else {
            setAuthenticatorCookies(res.data);
          }
          setTimeout(() => {
            auth = getAuthenticatorCookies();
            if (auth.current_company_uuid === res.data.current_company_uuid) {
              window.location.reload();
            }
          }, 1000);
          history.push(AUTHEN_PAGE.DASHDOARD);
        },
        error: (res) => {
          setLoading(false);
          handleMessage(res);
        },
      })
    );
  };

  useEffect(() => {
    if (companies) {
      fetchCompanies();
    }
  }, [companies]);

  const fetchCompanies = async () => {
    const options: SelectOption[] = companies.map((el: any) => {
      return {
        value: el.uuid,
        title: el.name,
      };
    });
    setCompanies(options);
    setLoading(false);
  };

  return (
    <>
      <DrawerSettingComponent
        toggleDrawer={isOpenDrawer}
        handleOpenAndClose={() => setOpenDrawer(!isOpenDrawer)}
        loading={loading}
      >
        <Row className="wrapper-setting">
          <Col span={COL_SIZE.TWENTY_FOUR} className="wrapper-setting_label">
            {t('global.common.settingAccount')}
          </Col>
          <Col span={COL_SIZE.TWENTY_FOUR} className="wrapper-setting_selected">
            <SingleSelect
              options={optionsCompanies}
              value={auth.current_company_uuid}
              setValue={handleSwapCompany}
            />
          </Col>
        </Row>
      </DrawerSettingComponent>
    </>
  );
};

export default SettingUserComponent;
