export const LINK_USER = {
  LIST_USER: '/manage-user/list',
  BLACKLIST_USER: '/manage-user/blacklist',
  USER_DETAIL: '/manage-user/list/:uuid',
  EDIT_USER: '/manage-user/list/:uuid/edit',
  MAIL_LIST: '/manage-user/list/:uuid/mail/:typeMail',
  STATISTICS: '/manage-user/list/:uuid/statistics-user/:methodPayment',
  USER_MEMBERSHIP: '/manage-user/list/:uuid/detail-course',
  USER_DETAIL_TAG: '/manage-user/list/:uuid/detail-tag',
  CREATE_TAG: '/tags/create',
  EDIT_TAG: '/tags/edit/:uuid',
  LIST_TAG: '/tags/list',
  LIST_TAG_USER: '/tags/list-user/:uuid',
  WHITELIST_USER: '/whitelist-user',
};

export const LINK_SETTING_ACCOUNT = {
  EMAIL_INTEGRATIONS_LIST: '/manage-payment-gateways/email-integrations/list',
  EMAIL_INTEGRATIONS_SELECT:
    '/manage-payment-gateways/email-integrations/select',
  EMAIL_INTEGRATIONS_TRANSITION_ONLY:
    '/manage-payment-gateways/email-integrations/transactional-only',
  EMAIL_INTEGRATIONS_TRANSITION_AND_MARKETING:
    '/manage-payment-gateways/email-integrations/transactional-marketing',
  EDIT_EMAIL_INTEGRATIONS_TRANSITION_AND_MARKETING:
    '/manage-payment-gateways/email-integrations/transactional-marketing/:uuid/update',
  LIST_DOMAIN: '/domain/list',
  DETAIL_DOMAIN: '/domain/list/:uuid',
  ADD_DOMAIN: '/account-setting/domain',
  PAYMENT_INFORMATION: '/payment/:type',
  CREATE_DOMAIN: '/domain/create',
  ACCOUNT_SETTING_DOMAIN: '/account-setting/domain',
  API_INTEGRATIONS: '/integrations',
  ADD_INTEGRATION: '/integration/create/:type/:id?/:status?',
  UPDATE_CONVERTKIT: '/integration/update/convertKit/:uuid/:type',
  UPDATE_MAILCHIMP: '/integration/update/mailChimp/:uuid/:type',
  UPDATE_THINKIFIC: '/integration/update/thinkific/:uuid/:type',
  UPDATE_TEACHABLE: '/integration/update/teachable/:uuid/:type',
  UPDATE_FACEBOOK: '/integration/update/facebook/:uuid/:type/:token?/:status?',
  UPDATE_MYASP: '/integration/update/myasp/:type',
  UPDATE_GOOGLE_SHEET: '/integration/update/googleSheet/:type',
  VERIFY_INTERGRATIONS: '/verify-integration',
};

export const MAIL = {
  ALTERNATIVE_CHARACTER_VERIFY_UNSUBSCRIBE_STEP_MAIL:
    '/step-mail-unsubscribe/verify',
  ALTERNATIVE_CHARACTER_VERIFY_UNSUBSCRIBE_MAIL: '/mail-unsubscribe/verify',
  ALTERNATIVE_CHARACTER_CHANGE_REGISTERED_EMAIL:
    '/change-registered-email/verify',
  ALTERNATIVE_CHARACTER_CANCEL_RECURRING_PAYMENT:
    '/cancel_recurring_payment/verify',
  ALTERNATIVE_CHARACTER_CHANGE_CREDIT_CARD: '/change-credit-card/verify',
  ALTERNATIVE_CHARACTER_PAYMENT_ORDER: '/payment-order/verify',
  ALTERNATIVE_CANCEL_SCHEDULE: '/remind-mail-unsubscribe/verify',
  ALTERNATIVE_SCHEDULE_CHANGE: '/change-schedule/verify',
};

export const MY_PAGE = {
  PROFILE: '/profile',
  PROFILE_EDIT_INFO: '/profile/edit-info',
  PROFILE_EDIT_PASSWORD: '/profile/edit-password',
  PROFILE_EMAIL_CHANGE: '/profile/email-change',
  ACCOUNT_SETTING_SUB_USER: '/account-setting/sub-user',
  ACCOUNT_SETTING_SUB_USER_INVITATION:
    '/account-setting/invitation/registration',
  MY_ACCOUNT_SETTING_DETAIL: '/my-account-setting/detail',
  MANAGE_PAYMENT_GATEWAY_CREATE:
    '/manage-payment-gateways/create/:type&uuid=:uuid',
  MANAGE_PAYMENT_GATEWAY_UPDATE_GMO:
    '/manage-payment-gateways/update/gmo/:uuid',
  MANAGE_PAYMENT_GATEWAY_UPDATE_PAYPAL:
    '/manage-payment-gateways/update/paypal/:uuid',
  MANAGE_PAYMENT_GATEWAY_UPDATE_UNIVAPAY:
    '/manage-payment-gateways/update/univapay/:uuid',
  MANAGE_PAYMENT_GATEWAY_UPDATE_STRIPE:
    '/manage-payment-gateways/update/stripe/:type&uuid=:uuid',
  MANAGE_PAYMENT_GATEWAY_CREATE_TYPE:
    '/manage-payment-gateways/create/:type/:uuid/:code',
  MANAGE_PAYMENT_GATEWAY_CREATE_SELECTED_METHOD:
    '/manage-payment-gateways/create/selected-method/pays/code',
  MANAGE_PAYMENT_GATEWAY: '/manage-payment-gateways',
  MANAGE_PAYMENT_SETTING: '/payment/setting',
  LOGOUT: '/logout',
};

export const FUNNEL = {
  CREATE: '/funnel/create',
  GROUP: '/funnel/group',
  SELECT: '/funnel-detail/:uuidFunnel/:typePage/:uuidPage?',
  SETTING: '/funnel/setting/:id&type=:type',
  LIST: '/funnel/list',
  RECYCLE: '/funnel/recycle',
  LIST_TEMPLATE_PARAMS_DEFAULT:
    '/templates?page=1&per_page=24&page_template_type=0&funnel_step_category_id=0&funnel_step_subcategory_id=0&name=',
  LIST_TEMPLATE: '/templates',
  TEMPLATE: '/templates/:id',
  FUNNEL_DETAIL: '/funnel-detail/:uuidFunnel/:typePage/:uuidPage?',
  SHARED_FUNNEL: '/shared/funnel/:id',
  SHARED_TEMPLATE: '/shared/templates/:id',
  LIST_BLOCK_TEMPLATE: '/block-templates',
  LIST_BLOCK_TEMPLATE_PARAMS_DEFAULT:
    '/block-templates?page=1&per_page=24&section_template_category_id=&name=',
  BLOCK_TEMPLATE: '/block-templates/:uuid',
  CREATE_TIMER: '/timers/create',
  EDIT_TIMER: '/timers/edit/:uuid',
  LIST_TIMER: '/timers/list',
};

export const CALENDAR = {
  CREATE: '/event/create',
  LIST: '/event/list',
  SETTING_SCHEDULE: '/event/setting-schedule/:groupsUuid',
  SETTING_SCHEDULE_STATIC: '/event/setting-schedule',
  SETTING_BASIC: '/event/edit/:uuid',
  SETTING_BASIC_STATIC: '/event/edit',
};

export const MEMBERSITE = {
  SETTING: '/membersite/setting',
  CREATE_COURSE: '/membersite/course/create',
  GROUP_COURSE: '/membersite/course/group',
  LIST_COURSE: '/membersite/course/list',
  RECYCLE_COURSE: '/membersite/course/recycle',
  SELECT: '/course-detail/:uuidMembersite/:typePage',
  SETTING_COURSE: '/membersite/course/setting/:id',
  LIST_MEMBERSITE_TEMPLATE_PARAMS_DEFAULT:
    '/course-templates?page=1&per_page=24&page_template_type=0&funnel_step_category_id=0&funnel_step_subcategory_id=0&name=',
  LIST_MEMBERSITE_TEMPLATE: '/course-templates',
  MEMBERSITE_TEMPLATE: '/course-templates/:id',
  SHARED_TEMPLATE: '/shared/course-templates/:id',
  SHARED_COURSE: '/shared/membersite/course/:id',
  COURSE_EDIT: '/course-detail/:uuidMembersite/edit',
  COURSE_SETTING: '/course-detail/:uuidMembersite/setting',
  LIST_USER: '/membersite/course/list-user/:uuid',
  COURSE_CREATE_BY_NAME: '/membersite/course/create/:id&type=:type',
  COURSE_IMPORT_CSV: '/membersite/course/import-csv/:uuid',
};

export const PRODUCT = {
  REGISTER: '/product/register',
  GROUP: '/product/group',
  UPDATE: '/product/:uuid/update',
};

export const MAIL_PATH = {
  CREATE_STEP_MAIL: '/create-step-mail',
  LIST_STEP_MAIL: '/step-mail/list',
  DETAIL_STEP_MAIL: '/step-mail/:uuid',
  STEP_MAIL_VIEW: '/step-mail/view/:uuid',
  DETAIL: '/mail/:uuid',
  STEP_MAIL_UPDATE: '/step-mail/:uuid/update',
  SENT_MAIL_LIST: '/sent-mail/list',
  SENT_MAIL_DETAIL: '/sent-mail/detail/:uuid',
  BULK_MAIL_CREATE: '/create-bulk-mail',
  BULK_MAIL_COPY: '/copy-bulk-mail/:uuid',
  BULK_MAIL_DETAIL: '/bulk-mail/:type/:uuid',
  MAIL_LIST_CREATE: '/create-mail-list',
  MAIL_LIST: '/mail-list',
  MAIL_LIST_BY_NAME: '/mail-list/:name',
  MAIL_LIST_CREATE_BY_NAME: '/create-mail-list/:name',
  MAIL_LIST_IMPORT_CSV: '/mail-lists/import-csv/:uuid',
  MAIL_LIST_USER: '/user-mail/list/:uuid',
  MAIL_FIXED_PHRASE: '/fixed-phrase/list',
  MAIL_FIXED_PHRASE_LIST: '/fixed-phrase/list/:title',
};

export const REPORT_PATH = {
  SELECT: '/funnel-reports/selected-funnel',
  DETAIL: '/funnel-reports/:uuid',
  SYNTHESIS: '/synthesis-reports',
};

export const ROLE_MENU_ITEM = {
  ONLY_VIEW: 1,
  EDIT: 2,
};

export const MENU_TILES = {
  FUNNEL: 'funnel',
  REGISTER_PRODUCT: 'register-product',
  MAIL: 'mail',
  USER: 'user',
  REPORT: 'report',
  MY_PAGE: 'mypage',
  MEMBERSITE: 'membersite',
  AFFILIATE: 'affiliate',
  CALENDAR: 'calendar',
};

export const AUTHEN_PAGE = {
  VERIFY_COOKIE: '/verifyAuthen',
  VERIFY_NOT_CARD: '/expire-trial-verify-card',
  SIGN_IN: '/signin',
  FORGOT_PASSWORD: '/forgot-password',
  REGISTER: '/register-account',
  CREATE_NEW_PASSWORD: '/create-new-password',
  VERIFY_ACCOUNT_SUCCESS: '/verify-account-success',
  DASHDOARD: '/dashboard',
  PLAN_SELECTION: '/selection-plan',
};

export const HELPER_PAGE = {
  TERM_SERVICE: '/term-service',
  PRIVACY: '/privacy',
  CONTACT_US: '/contact-us',
  HELPER_PAGE: '/nopage',
};

export const AFFILIATE = {
  SETTING: '/affiliate/setting',
  TERM_SERVICE: '/affiliate/term-service',
  PARTNER: '/affiliate/partner',
  PARTNER_DETAIL: '/affiliate/detail-partner',
  SUBSCRIBER: '/affiliate/subscriber',
  BONUS: '/affiliate/bonus',
  FUNNEL_INTRODUCE: '/affiliate/funnel-introduce-sample',
};

export const NOT_FOUND = {
  PAGE_NOT_FOUND: '/404-not-found',
};

export const MAINTENANCE = {
  PAGE_MAINTAINANCE: '/maintenance',
};

export const REMIND_MAIL = {
  CREATE_REMIND_MAIL: '/create-remind-mail',
  REMIND_MAIL_UPDATE: '/remind-mail/:uuid/update',
  LIST_REMIND_MAIL: '/remind-mail/list',
  DETAIL_REMIND_MAIL: '/remind-mail/:uuid',
  EDIT_MAIL: '/edit-mail/:uuid',
};
