export interface InfoEmailIntegrationsTransactionMarketing {
  title: string;
  smtpSever: string;
  smtpPort: string;
  smtpUser: string;
  smtpPassword: string;
  smtpDomain: string;
  typeId: number;
  fromName: string;
  fromEmail: string;
  flagDefault: boolean;
  smtpFooter: string;
}

export const infoEmailIntegrationsTransactionMarketing: InfoEmailIntegrationsTransactionMarketing =
  {
    title: '',
    smtpSever: '',
    smtpPort: '',
    smtpUser: '',
    smtpPassword: '',
    smtpDomain: '',
    typeId: 1,
    fromName: '',
    fromEmail: '',
    flagDefault: true,
    smtpFooter: '',
  };

export const infoTransactionMarketingToParams = (
  data: InfoEmailIntegrationsTransactionMarketing
) => {
  return {
    title: data.title,
    smtp_server: data.smtpSever,
    smtp_port: Number(data.smtpPort) || data.smtpPort,
    smtp_user: data.smtpUser,
    smtp_password: data.smtpPassword,
    smtp_domain: data.smtpDomain,
    type_id: Number(data.typeId),
    from_name: data.fromName,
    from_email: data.fromEmail,
    flag_default: data.flagDefault,
    footer: data.smtpFooter,
  };
};

export const paramsToInfoTransactionMarketing = (data: any) => {
  return {
    title: data.title || '',
    smtpSever: data.smtp_server || '',
    smtpPort: data.smtp_port || '',
    smtpUser: data.smtp_user || '',
    smtpPassword: data.smtp_password || '',
    smtpDomain: data.smtp_domain || '',
    domainUuid: data.domain_uuid || '',
    fromName: data.from_name || '',
    fromEmail: data.from_email || '',
    flagDefault: data.flag_default || '',
    smtpFooter: data.footer || '',
    typeId: 1,
  };
};
export const dataToInfoTransactionMarketingForm = (data: any) => {
  return {
    title: data.title || '',
    smtpSever: data.smtp_server || '',
    smtpPort: data.smtp_port || '',
    smtpUser: data.smtp_user || '',
    smtpPassword: data.smtp_password || '',
    smtpDomain: data.smtp_domain || '',
    domainUuid: data.domain?.uuid || '',
    fromName: data.from_name || '',
    fromEmail: data.from_email || '',
    flagDefault: data.flag_default || false,
    smtpFooter: data.footer || '',
    typeId: 1,
  };
};
export const optionsSmtpSeverMail = [
  {
    value: 'smtp.sendgrid.net',
    title: 'smtp.sendgrid.net',
  },
  {
    value: 'smtp.sparkpostmail.com',
    title: 'smtp.sparkpostmail.com',
  },
  {
    value: 'smtp.mailgun.org',
    title: 'smtp.mailgun.org',
  },
  {
    value: 'smtp.mandrillapp.com',
    title: 'smtp.mandrillapp.com',
  },
];
