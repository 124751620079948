// use library node_modules
import { toast } from 'react-toastify';
import { Dropdown, Col, Menu, Row } from 'antd';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { RightOutlined } from '@ant-design/icons';

// use components common
import { TConfirmPopup } from '../../../../../../../components/atoms/TConfirmPopup';
import { ButtonAction } from '../..';

// use helper and config, services
import {
  copyIcon,
  copyShareIcon,
  deleteIcon,
  shareTargetIcon,
} from '../../icons';
import {
  COL_SIZE,
  DEFAULT_OPTIONS,
} from '../../../../../../../constants/app.constants';
import { PropertyOverlay } from '../../types';
import { IFunnel } from '../../../../../../../store/reducers/funnel/groupFunnels';
import { calcCurrentPage, getURLShareFunnel } from '../../../Utils/index.utils';
import {
  CloneFunnel,
  cloneFunnelElement,
  deleteFunnel,
  IGetElementById,
} from '../../../../../../../Clients/funnels';
import {
  EStatusHttp,
  IBaseParameter,
} from '../../../../../../../Clients/interfaces';
import { errorResponse } from '../../../../../../../Clients/helper';
import { fetchGroupFunnelList } from '../../../../../../../store/actions/funnel/group';
import { useGroupFunnelsList } from '../../../../../../../hooks';
import { ButtonWrapper, WrapperDropdown, WrapperMenu } from './styled';
import { scrollTop } from '../../../../../../../utilities/common.utilities';
import { fetchSummaryFunnel } from '../../../../../../../store/actions/funnel/funnelList';

interface DropdownOverlayProps {
  hasVisible: boolean;
  onCannelEvent: (flag: boolean) => void;
  handleFocusEdit: () => void;
  data: IFunnel;
}

const DropdownOverlay: React.FC<DropdownOverlayProps> = ({
  hasVisible,
  onCannelEvent,
  handleFocusEdit,
  data,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [groupFunnels] = useGroupFunnelsList();
  const sharedUrl = getURLShareFunnel(data.first_step);

  const handleCopyPathShareFunnel = () => {
    toast.success(t('funnel.setting.copyUrlShareFunnelSuccess'));
    navigator.clipboard.writeText(sharedUrl);
    onCannelEvent(false);
  };

  const handleOkeConfirm = async (_item: any, type: string) => {
    if (type === 'DELETE') {
      const params: IBaseParameter<IGetElementById> = {
        hasLoading: true,
        params: {
          uuid: data.uuid,
        },
        notification: {
          success: true,
        },
      };
      try {
        const response = await deleteFunnel(params);
        if (response.status === EStatusHttp.HTTP_OK) {
          dispatch(fetchSummaryFunnel());
          const currentPage = calcCurrentPage(
            groupFunnels.meta.total,
            groupFunnels.payload.perPage,
            groupFunnels.payload.page
          );
          dispatch(
            fetchGroupFunnelList({
              ...groupFunnels.payload,
              page: currentPage,
            })
          );
        }
      } catch (error: any) {
        errorResponse(error.response, {
          notification: { error: true },
          hasLoading: false,
        });
      }
    }
  };

  const handleConfirmAction = () => {
    onCannelEvent(false);
    TConfirmPopup(
      t('funnel.list.confirm.delete', { name: data.name }),
      'DELETE',
      {},
      handleOkeConfirm
    );
  };

  const handleCloneFunnel = async () => {
    onCannelEvent(false);
    const params: IBaseParameter<CloneFunnel> = {
      hasLoading: false,
      params: {
        uuid: data.uuid,
      },
      notification: {
        success: true,
      },
    };

    try {
      const response = await cloneFunnelElement(params);
      if (response.status === EStatusHttp.HTTP_OK) {
        dispatch(fetchSummaryFunnel());
        dispatch(
          fetchGroupFunnelList({
            ...groupFunnels.payload,
            page: DEFAULT_OPTIONS.page,
          })
        );
        scrollTop();
      }
    } catch (error: any) {
      errorResponse(error.response, {
        hasLoading: false,
        notification: { error: true },
      });
    }
  };

  const handleRedirectFunnelStetting = () => {
    onCannelEvent(false);
    history.push(`/funnel/setting/${data.uuid}&type=group`);
  };

  const handleRedirectFunnelReport = () => {
    onCannelEvent(false);
    history.push(`/funnel-reports/${data.uuid}`);
  };

  const ListMenuActions = () => (
    <WrapperMenu>
      {/* ----------------------------------------------------------------------------------- */}
      <Menu.Item className="menu" key="menu-1">
        <Row className="menu__title">
          {t('funnel.listing.common.titleMenu')}
        </Row>
        <Row className="mt-1">
          {/* ----------------------------------------------------------------------------------- */}
          <Col flex="auto">
            <input
              style={{ height: 30 }}
              type="text"
              className="form-control menu__input"
              value={sharedUrl}
              title={sharedUrl}
              readOnly
            />
          </Col>

          {/* ----------------------------------------------------------------------------------- */}
          <Col flex="40px" className="pl-10">
            <img
              className="menu__icon"
              src={copyShareIcon}
              onClick={handleCopyPathShareFunnel}
              alt=""
            />
            <a
              href={sharedUrl}
              target="_blank"
              className="menu__share"
              rel="noreferrer"
            >
              <img className="menu__icon" src={shareTargetIcon} alt="" />
            </a>
          </Col>
        </Row>
      </Menu.Item>

      {/* ----------------------------------------------------------------------------------- */}
      <Menu.Divider />
      {/* ----------------------------------------------------------------------------------- */}

      <Menu.Item className="action" key="menu-2">
        <Row>
          {/* ----------------------------------------------------------------------------------- */}
          <Col span={COL_SIZE.TWELVE} className="px-1">
            <ButtonAction
              icon={copyIcon}
              title={t('funnel.list.popup.copy')}
              onChangeEvent={handleCloneFunnel}
              action="clone"
            />
          </Col>

          {/* ----------------------------------------------------------------------------------- */}
          <Col span={COL_SIZE.TWELVE} className="px-1">
            <ButtonAction
              icon={deleteIcon}
              title={t('funnel.list.popup.delete')}
              onChangeEvent={handleConfirmAction}
              action="deleted"
            />
          </Col>
        </Row>
      </Menu.Item>

      {/* ----------------------------------------------------------------------------------- */}
      <Menu.Item className="path" key="menu-3">
        <ButtonWrapper
          className="btn btn-default w-100"
          onClick={handleFocusEdit}
        >
          <Row>
            <Col flex="auto">{t('funnel.list.popup.changeFunnelName')}</Col>
            <Col flex="20px" className="pl-10">
              <RightOutlined />
            </Col>
          </Row>
        </ButtonWrapper>
      </Menu.Item>

      {/* ----------------------------------------------------------------------------------- */}
      <Menu.Item className="path" key="menu-4">
        <ButtonWrapper
          className="btn btn-default w-100"
          onClick={handleRedirectFunnelStetting}
        >
          <Row>
            <Col flex="auto">{t('funnel.list.popup.changeFunnelSettings')}</Col>
            <Col flex="20px" className="pl-10">
              <RightOutlined />
            </Col>
          </Row>
        </ButtonWrapper>
      </Menu.Item>

      {/* ----------------------------------------------------------------------------------- */}
      <Menu.Item className="path" key="menu-5">
        <ButtonWrapper
          className="btn btn-default w-100"
          onClick={handleRedirectFunnelReport}
        >
          <Row>
            <Col flex="auto">{t('funnel.listing.common.funnelReports')}</Col>
            <Col flex="20px" className="pl-10">
              <RightOutlined />
            </Col>
          </Row>
        </ButtonWrapper>
      </Menu.Item>
    </WrapperMenu>
  );

  return (
    <Dropdown
      overlay={ListMenuActions}
      visible={hasVisible}
      onVisibleChange={onCannelEvent}
      {...PropertyOverlay}
    >
      <WrapperDropdown />
    </Dropdown>
  );
};

export default DropdownOverlay;
