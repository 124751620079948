import * as Types from '../../actions/mail/types';

interface ConditionMailState {
  mailListFullOptions: Record<string, any>;
}

const initialState: ConditionMailState = {
  mailListFullOptions: {
    data: undefined,
    error: undefined,
  },
};

export default function conditionReducer(
  state: ConditionMailState = initialState,
  action: Record<string, any> = {}
): ConditionMailState {
  switch (action.type) {
    case Types.GET_LIST_ALL_MAIL_LIST_WITH_TRASHED_SUCCESS:
      return {
        ...state,
        mailListFullOptions: {
          ...state.mailListFullOptions,
          data: action.data.map((data: Record<string, any>) => ({
            label: data.name_group,
            options: data.mail_list_in_group.map((mail: any) => ({
              label: mail.name,
              value: mail.uuid,
              isDeleted: !!mail.is_deleted_at,
            })),
          })),
        },
      };
    case Types.GET_LIST_ALL_MAIL_LIST_WITH_TRASHED_ERROR:
      return {
        ...state,
        mailListFullOptions: {
          ...state.mailListFullOptions,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
