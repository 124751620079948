import { API_BASE_URL } from '../config';

export const eventAPI = {
  createEvent: `${API_BASE_URL}/events`,
  getListGroup: `${API_BASE_URL}/group/list/CALENDAR`,
  getListEvent: `${API_BASE_URL}/groups/events`,
  copyGroupEvent: `${API_BASE_URL}/group/copy`,
  renameGroupEvent: `${API_BASE_URL}/group/rename`,
  deleteGroupEvent: `${API_BASE_URL}/group/delete`,
  publishEvent: (uuid: string) => `${API_BASE_URL}/events/${uuid}/publish`,
  copyItemEvent: (uuid: string) => `${API_BASE_URL}/events/${uuid}/copy`,
  detailEvent: (uuid: string) => `${API_BASE_URL}/events/${uuid}`,
  getListEventByGroups: (uuid: string) =>
    `${API_BASE_URL}/groups/${uuid}/events`,
  saveSchedule: (uuid: string) =>
    `${API_BASE_URL}/groups/${uuid}/schedules/save`,
  getSchedulesByWeek: (uuid: string) =>
    `${API_BASE_URL}/groups/${uuid}/schedules`,
  deleteSchedules: (uuid: string) => `${API_BASE_URL}/schedules/${uuid}`,
  getListPageConnectEvents: (uuid: string) =>
    `${API_BASE_URL}/events/${uuid}/pages`,
};
