import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { TypePlanFunecy } from '../containers/PaymentInformation/Components/plan-selection/PackagePlan/types';
import { checkRoleEditButton } from '../helper/roleUser';
import { AppState } from '../store/reducers/rootReducer';

const useCheckRole = () => {
  const match = useRouteMatch();
  const profile = useSelector((state: AppState) => state.myPage.profile.data);
  const { role, current_plan } = profile;
  const isNotEdit =
    role &&
    checkRoleEditButton(
      {
        isFullRole: role?.is_full_role,
        isOwner: role?.is_owner,
        role: role?.role,
      },
      match.path
    );

  return {
    isNotEdit,
    typePlan: current_plan?.type,
    ETypePlanFunecy: TypePlanFunecy,
    role,
  };
};

export default useCheckRole;
