import { Menu } from 'antd';
import styled from 'styled-components';
export const ButtonWrapper = styled.button`
  width: 100%;
  cursor: pointer;
  height: 32px;
  line-height: 17px;
  text-decoration: none;
  font-size: 12px;
  color: var(--t-main-color);
  text-align: left;
  padding-left: 10px;
  &:focus {
    box-shadow: none;
  }
`;
export const WrapperDropdown = styled.span`
  font-size: 1.2rem;
  cursor: pointer;
  &:before {
    content: '...';
    color: gray;
  }
`;
export const WrapperMenu = styled(Menu)`
  margin-top: 15px;
  width: 260px;
  height: 235px;
  .menu,
  .action {
    cursor: default;
    &:hover {
      background: transparent;
    }
  }
  .menu {
    padding: 5px 15px;
    &__title {
      font-size: 0.75rem;
      font-weight: 600;
    }
    &__input {
      width: 185px;
    }
    &__share {
      width: 30px;
      margin-left: 5px;
    }
    &__icon {
      cursor: pointer;
    }
  }

  .action {
    .btn-lead-clone,
    .btn-lead-deleted {
      border-radius: 17px;
      padding: 6px 0px;
      border: 1px solid var(--t-border-color);
      font-size: 12px;
      font-weight: 500;

      &:focus {
        box-shadow: none;
        outline: none;
      }

      &:hover {
        opacity: 0.8;
      }

      img {
        &.deleted {
          margin: 0 3px 4px 0px;
        }

        &.clone {
          margin: 0 5px 4px 0px;
        }
      }
    }

    .btn-lead-clone {
      background-color: transparent;
      color: var(--t-text-color);
    }
    .btn-lead-deleted {
      background-color: #d7555b;
      color: var(--t-white-color);
    }
  }
  .path {
    .pl-10 {
      padding-left: 10px;
    }
    cursor: pointer;
    height: 32px;
    line-height: 17px;
    text-decoration: none;
    font-size: 12px;
    color: var(--t-main-color);
    text-align: left;
    padding-left: 10px;
    &:hover {
      background-color: var(--t-border-color);
    }
    span {
      svg {
        color: var(--t-main-color);
        margin-top: -6px;
        font-size: 10px;
      }
    }
  }
`;
