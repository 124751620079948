import i18n from '../i18n/i18n';
import { ISelectedProps } from '../components/atoms/TSingleSelected/TSingleSelected';
import { FilterDateProps } from '../interfaces/common/useMostInterface';
import { AUTHEN_PAGE, MAINTENANCE } from '../constants/menus';
import { ESortType } from '../constants/app.constants';

export const SYMBOL_ARR = ['e', 'E'];
export const SYMBOL_ARR_NUMBER = ['e', 'E', '.', ',', '-', '+'];

export const pageSizes: ISelectedProps[] = [
  {
    title: '20',
    value: 20,
  },
  {
    title: '40',
    value: 40,
  },
  {
    title: '60',
    value: 60,
  },
];

export const pageSizesNoGroup: ISelectedProps[] = [
  {
    title: '20',
    value: 20,
  },
  {
    title: '50',
    value: 50,
  },
  {
    title: '100',
    value: 100,
  },
  {
    title: '200',
    value: 200,
  },
];

export const pageSizesCard: ISelectedProps[] = [
  {
    title: '24',
    value: 24,
  },
  {
    title: '36',
    value: 36,
  },
  {
    title: '48',
    value: 48,
  },
];

export const validationTypeCSV = ['ms-excel', 'text/plain', 'text/csv'];

export enum EOptionRangeType {
  ALL_PERIOD = 0,
  TODAY = 1,
  LAST_7_DAY = 2,
  FOR_THE_LAST_30_DAY = 3,
  LAST_MONTH = 4,
  LAST_3_MONTH = 5,
  LAST_6_MONTH = 6,
  LAST_YEAR = 7,
  CUSTOM = 8,
  YESTERDAY = 9,
}

export const optionRangeTypes: FilterDateProps[] = [
  {
    title: i18n.t('common.list.filterDate.today'),
    typeFilter: EOptionRangeType.TODAY,
  },
  {
    title: i18n.t('common.list.filterDate.yesterday'),
    typeFilter: EOptionRangeType.YESTERDAY,
  },
  {
    title: i18n.t('common.list.filterDate.last7Days'),
    typeFilter: EOptionRangeType.LAST_7_DAY,
  },
  {
    title: i18n.t('common.list.filterDate.forTheLast30Days'),
    typeFilter: EOptionRangeType.FOR_THE_LAST_30_DAY,
  },
  {
    title: i18n.t('common.list.filterDate.lastMonth'),
    typeFilter: EOptionRangeType.LAST_MONTH,
  },
  {
    title: i18n.t('common.list.filterDate.last3Months'),
    typeFilter: EOptionRangeType.LAST_3_MONTH,
  },
  {
    title: i18n.t('common.list.filterDate.last6Months'),
    typeFilter: EOptionRangeType.LAST_6_MONTH,
  },
  {
    title: i18n.t('common.list.filterDate.lastYear'),
    typeFilter: EOptionRangeType.LAST_YEAR,
  },
  {
    title: i18n.t('common.list.filterDate.allPeriod'),
    typeFilter: EOptionRangeType.ALL_PERIOD,
  },
];

export const ORIGIN_HOST = window.location.origin;
export const GET_LOCALHOST = 'https://localhost:8080';
export const ENV_DEVELOPMENT = 'development';

export const getChildDomainSite = (domain: string) => {
  return `https://${domain}.${process.env.APP_CHILD_DOMAIN}`;
};

export const redirectWhenAuthenSuccess = (domain: string) => {
  window.location.href = domain + AUTHEN_PAGE.DASHDOARD;
};
export const redirectWhenLoginIsSubDomain = () => {
  if (process.env.APP_ENV === ENV_DEVELOPMENT) {
    window.location.href = GET_LOCALHOST + AUTHEN_PAGE.SIGN_IN;
  } else {
    window.location.href = `${process.env.APP_DOMAIN}/signin`;
  }
};

export const redirectWhenLogout = () => {
  if (process.env.APP_ENV === ENV_DEVELOPMENT) {
    window.location.href = GET_LOCALHOST + AUTHEN_PAGE.VERIFY_COOKIE;
  } else {
    window.location.href = `${process.env.APP_DOMAIN}${AUTHEN_PAGE.VERIFY_COOKIE}`;
  }
};

export const redirectWhenExpire = () => {
  if (process.env.APP_ENV === ENV_DEVELOPMENT) {
    window.location.href = GET_LOCALHOST + AUTHEN_PAGE.VERIFY_NOT_CARD;
  } else {
    window.location.href = `${process.env.APP_DOMAIN}${AUTHEN_PAGE.VERIFY_NOT_CARD}`;
  }
};

export const redirectWhenMaintain = () => {
  if (process.env.APP_ENV === ENV_DEVELOPMENT) {
    window.location.href = GET_LOCALHOST + MAINTENANCE.PAGE_MAINTAINANCE;
  } else {
    window.location.href = `${process.env.APP_DOMAIN}${MAINTENANCE.PAGE_MAINTAINANCE}`;
  }
};

export const checkCurrentDomainSite = (domain: string) => {
  return ORIGIN_HOST !== domain && ORIGIN_HOST !== GET_LOCALHOST;
};

export const redirectWhenLoginSuccess = (domain: string, path: string) => {
  window.location.href = `${domain}${path}`;
};

export const redirectStateWhenAuthenSuccess = (
  domain: string,
  pathName: string
) => {
  window.location.href = domain + pathName;
};

export const setSortType = (sortType: string) => {
  switch (sortType) {
    case ESortType.BLANK:
      return ESortType.ASC;
    case ESortType.ASC:
      return ESortType.DESC;
    case ESortType.DESC:
    default:
      return ESortType.BLANK;
  }
};

export const MAX_TEXT_INPUT = {
  URL: 255,
  TEXT: 100,
};
export enum TYPE_SEND_MAIL_TEST {
  STEP_MAIL = 1,
  BULK_MAIL = 2,
  REMIND_MAIL = 3,
}

export const LINK_UNSUBSCRIBE_MAIL = '%link_unsubscribe_mail%';

export const SCOP_GOOGLE_SHEET = 'https://www.googleapis.com/auth/spreadsheets';
