import React from 'react';
import { Layout } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import ArticleHeader from './components/header';
import ArticleSidebar from './components/sidebar';
import { IMenuItem } from '../interfaces/layout/IMenu.interfaces';
import ArticleFooter from './components/footer';
import './style.scss';
import { listCollapsed } from './components/sidebar/config';

const { Content } = Layout;

export interface ArticleLayoutProps {
  children: any;
  menus: Array<IMenuItem>;
}
const ArticleLayoutWrapped: React.FC<ArticleLayoutProps> = ({
  children,
  menus,
}) => {
  const match = useRouteMatch();

  return (
    <div className="w-100 main-layout">
      <div className="header-layout">
        <ArticleHeader />
      </div>
      <Layout className="wrapper-layout">
        <ArticleSidebar menus={menus} />
        <Layout className={`content`}>
          <Content className={`wrapper-content `}>
            <div
              className={`content-background ${
                listCollapsed.includes(match.path) ? 'collapsed' : ''
              }`}
            >
              {children}
            </div>
          </Content>
        </Layout>
      </Layout>
      <ArticleFooter />
    </div>
  );
};

export default ArticleLayoutWrapped;
