import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import moment from 'moment';

import * as TypesLoading from './../actionTypes/loadingActionTypes';
import * as Types from '../actions/event/types';
import * as eventService from '../../Clients/event';
import { EStatusHttp } from '../../Clients/interfaces';
import {
  IParamCreateEvent,
  IParamGetSchedulesByWeek,
  IParamsCopyGroupEvent,
  IParamsDeleteGroupEvent,
  IParamsGetListEvent,
  IParamsRenameGroupEvent,
} from '../../Clients/event/interface';
import { ISchedule } from '../reducers/event/interface';
import { getScheduleUuidOriginal } from '../../containers/Calendar/SettingSchedule/config';
import { DateFormat } from '../../constants/app.constants';

import { handleMessage } from '../../utilities/common.utilities';

interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
  error?: any;
}

function* createEvent(action: {
  type: string;
  data: IParamCreateEvent;
  onSuccess: (data: any) => void;
  onError: (err: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      eventService.createEvent,
      action.data
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      if (action.onSuccess) action.onSuccess(response.data);
    }
    handleMessage(response);
  } catch (error: any) {
    if (action.onError) action.onError(error);
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getListEvent(action: { type: string; params: IParamsGetListEvent }) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      eventService.getListEvent,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_LIST_EVENT_SUCCESS,
        data: response.data.data,
      });
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getListGroup(action: {
  type: string;
  onSuccess: () => void;
  onError: (err: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(eventService.getListGroup);
    if (response.status === EStatusHttp.HTTP_OK) {
      if (action.onSuccess) action.onSuccess();
      yield put({
        type: Types.GET_LIST_GROUP_SUCCESS,
        data: response.data.data,
      });
    }
  } catch (error: any) {
    if (action.onError) action.onError(error);
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* copyGroupEvent(action: {
  type: string;
  params: IParamsCopyGroupEvent;
  onSuccess: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      eventService.copyGroupEvent,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
    handleMessage(response);
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* renameGroupEvent(action: {
  type: string;
  params: IParamsRenameGroupEvent;
  onSuccess: () => void;
  onError: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      eventService.renameGroupEvent,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
    handleMessage(response);
  } catch (error: any) {
    handleMessage(error?.response);
    action.onError();
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* deleteGroupEvent(action: {
  type: string;
  params: IParamsDeleteGroupEvent;
  onSuccess: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      eventService.deleteGroupEvent,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
    handleMessage(response);
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* copyItemEvent(action: {
  type: string;
  uuid: string;
  onSuccess: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      eventService.copyItemEvent,
      action.uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
    handleMessage(response);
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* deleteItemEvent(action: {
  type: string;
  uuid: string;
  onSuccess: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      eventService.deleteItemEvent,
      action.uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
    handleMessage(response);
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* publishEvent(action: { uuid: string; onSuccess: () => void }) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      eventService.publishEvent,
      action.uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
    handleMessage(response);
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getDetailEvent(action: {
  uuid: string;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      eventService.getDetailEvent,
      action.uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data.data);
    }
  } catch (error: any) {
    handleMessage(error?.response);
    action.onError(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* putDetailEvent(action: {
  uuid: string;
  params: IParamCreateEvent;
  onSuccess: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      eventService.updateDetailEvent,
      action.uuid,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      if (action.onSuccess) action.onSuccess();
      handleMessage(response);
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getListEventByGroups(action: { groupsUuid: string }) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      eventService.getListEventByGroups,
      action.groupsUuid
    );
    yield put({
      type: Types.GET_LIST_EVENT_BY_GROUPS_SUCCESS,
      data: response.data.data,
    });
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getSchedulesByWeek(action: {
  groupsUuid: string;
  params: IParamGetSchedulesByWeek;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  const originalStartDate = action.params.start_date;
  action.params.start_date = moment(originalStartDate)
    .add(-2, 'day')
    .format(DateFormat);
  try {
    const response: APIResponseType = yield call(
      eventService.getSchedulesByWeek,
      action.groupsUuid,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      const listScheduleByTime = response.data.data.filter(
        (schedule: ISchedule) =>
          moment(schedule.start_date).isSameOrAfter(originalStartDate, 'day') ||
          moment(schedule.end_date).isSameOrAfter(originalStartDate, 'day')
      );
      yield put({
        type: Types.GET_SCHEDULES_BY_WEEK_SUCCESS,
        data: listScheduleByTime,
        startWeek: originalStartDate,
        endWeek: action.params.end_date,
      });
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* deleteSchedules(action: {
  schedule_uuid: string;
  onSuccess: () => void;
  onError: (err: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      eventService.deleteSchedules,
      action.schedule_uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.DELETE_SCHEDULE_LOCAL,
        schedule_uuid: action.schedule_uuid,
      });
      if (action.onSuccess) action.onSuccess();
    }
    handleMessage(response);
  } catch (error: any) {
    if (action.onError) action.onError(error);
    handleMessage(error?.response);
  }
}

function* createSchedule(action: {
  timeDrop: string;
  schedule: ISchedule;
  groupsUuid: string;
}) {
  const { timeDrop, schedule, groupsUuid } = action;
  try {
    const response: APIResponseType = yield call(
      eventService.saveSchedule,
      groupsUuid,
      {
        event_uuid: schedule.event_uuid,
        start_date: timeDrop,
      }
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
    }
    yield put({
      type: Types.ADD_SCHEDULE_SUCCESS,
      old_schedule_uuid: schedule.schedule_uuid,
      new_schedule_uuid: response.data.data.uuid,
      participants: response.data.data.count_participants,
    });
  } catch (error: any) {
    yield put({
      type: Types.DELETE_SCHEDULE_LOCAL,
      schedule_uuid: schedule.schedule_uuid,
    });
    handleMessage(error?.response);
  }
}

function* moveSchedule(action: {
  timeDrop: string;
  schedule: ISchedule;
  groupsUuid: string;
  oldTimeStart: string;
  oldTimeEnd: string;
}) {
  const { timeDrop, schedule, groupsUuid, oldTimeStart, oldTimeEnd } = action;

  try {
    const response: APIResponseType = yield call(
      eventService.saveSchedule,
      groupsUuid,
      {
        uuid: schedule.schedule_uuid,
        start_date: timeDrop,
      }
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      schedule.count_user_calendars = 0;
      handleMessage(response);
      yield put({
        type: Types.MOVE_SCHEDULE_SUCCESS,
        schedule_uuid: schedule.schedule_uuid,
      });
    }
  } catch (error: any) {
    yield put({
      type: Types.MOVE_SCHEDULE_FAIL,
      schedule_uuid: schedule.schedule_uuid,
      oldTimeStart: oldTimeStart,
      oldTimeEnd: oldTimeEnd,
    });
    handleMessage(error?.response);
  }
}

function* getListPagesConnectEvent(action: {
  schedule_uuid: string;
  onSuccess: (data: any) => void;
  onError: (err: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      eventService.getListPageConnectEvents,
      action.schedule_uuid
    );
    if (action.onSuccess) action.onSuccess(response.data);
  } catch (error: any) {
    if (action.onError) action.onError(error);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* createScheduleOverDay(action: {
  timeDrop: string;
  listChildSchedule: ISchedule[];
  groupsUuid: string;
}) {
  const { timeDrop, listChildSchedule, groupsUuid } = action;

  try {
    const response: APIResponseType = yield call(
      eventService.saveSchedule,
      groupsUuid,
      {
        event_uuid: listChildSchedule[0].event_uuid,
        start_date: timeDrop,
      }
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      yield put({
        type: Types.CREATE_SCHEDULE_OVER_DAY_SUCCESS,
        old_schedule_uuid: listChildSchedule[0].schedule_uuid,
        new_schedule_uuid: response.data.data.uuid,
        participants: response.data.data.count_participants,
      });
    }
  } catch (error: any) {
    yield put({
      type: Types.CREATE_SCHEDULE_OVER_DAY_FAIL,
      schedule_uuid: listChildSchedule[0].schedule_uuid,
    });
    handleMessage(error?.response);
  }
}

function* moveScheduleOverDay(action: {
  timeDrop: string;
  listChildSchedule: ISchedule[];
  groupsUuid: string;
  listOldChildSchedule: ISchedule[];
}) {
  const { timeDrop, listChildSchedule, groupsUuid, listOldChildSchedule } =
    action;

  try {
    const response: APIResponseType = yield call(
      eventService.saveSchedule,
      groupsUuid,
      {
        uuid: getScheduleUuidOriginal(listChildSchedule[0].schedule_uuid),
        start_date: timeDrop,
      }
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      listChildSchedule.map((el) => (el.count_user_calendars = 0));
      handleMessage(response);
      yield put({
        type: Types.MOVE_SCHEDULE_OVER_DAY_SUCCESS,
        schedule_uuid: listChildSchedule[0].schedule_uuid,
      });
    }
  } catch (error: any) {
    yield put({
      type: Types.MOVE_SCHEDULE_OVER_DAY_FAIL,
      schedule_uuid: listChildSchedule[0].schedule_uuid,
      listOldChildSchedule: listOldChildSchedule,
    });
    handleMessage(error?.response);
  }
}

export default function* eventSaga() {
  yield takeLatest(Types.CREATE_EVENT, createEvent);
  yield takeLatest(Types.GET_LIST_EVENT, getListEvent);
  yield takeLatest(Types.COPY_GROUP_EVENT, copyGroupEvent);
  yield takeLatest(Types.RENAME_GROUP_EVENT, renameGroupEvent);
  yield takeLatest(Types.DELETE_GROUP_EVENT, deleteGroupEvent);
  yield takeLatest(Types.COPY_ITEM_EVENT, copyItemEvent);
  yield takeLatest(Types.DELETE_ITEM_EVENT, deleteItemEvent);
  yield takeLatest(Types.GET_LIST_GROUP, getListGroup);
  yield takeLatest(Types.PUBLISH_EVENT, publishEvent);
  yield takeLatest(Types.GET_DETAIL_EVENT, getDetailEvent);
  yield takeLatest(Types.PUT_DETAIL_EVENT, putDetailEvent);
  yield takeLatest(Types.GET_LIST_EVENT_BY_GROUPS, getListEventByGroups);
  yield takeLatest(Types.GET_SCHEDULES_BY_WEEK, getSchedulesByWeek);
  yield takeEvery(Types.DELETE_SCHEDULES, deleteSchedules);
  yield takeEvery(Types.CREATE_SCHEDULE, createSchedule);
  yield takeEvery(Types.MOVE_SCHEDULE, moveSchedule);
  yield takeLatest(
    Types.GET_LIST_PAGES_CONNECT_EVENT,
    getListPagesConnectEvent
  );
  yield takeLatest(Types.CREATE_SCHEDULE_OVER_DAY, createScheduleOverDay);
  yield takeLatest(Types.MOVE_SCHEDULE_OVER_DAY, moveScheduleOverDay);
}
