import { IMultipleSelectedProps } from '../../../components/atoms/TMultipleSelected/TMultipleSelected';
import { IStateGroupFunnels } from '../../reducers/funnel/groupFunnels';
import * as types from './types';

export interface IPayLoadGroupFunnelList {
  page: number;
  perPage: number;
  group_uuids: string;
  flag_favorite: string;
  sort_group: string;
  sort_type: 'desc' | 'asc' | '';
  sort_name: 'funnel_name' | 'datetime' | 'group_name' | '';
}

const fetchGroupFunnelList = (payload: IPayLoadGroupFunnelList) => ({
  type: types.FETCH_THE_GROUP_FUNNEL_LIST,
  payload,
});

const fetchGroupFunnelListSuccess = (data: IStateGroupFunnels) => ({
  type: types.FETCH_THE_GROUP_FUNNEL_LIST_SUCCESS,
  data: data,
});

const fetchGroupFunnelListFailed = (error: string) => ({
  type: types.FETCH_THE_GROUP_FUNNEL_LIST_FAILED,
  error: error,
});

const fetchSelectedGroupFunnel = () => ({
  type: types.FETCH_SELECT_GROUP_FUNNEL,
});

const fetchSelectedGroupMembersite = () => ({
  type: types.FETCH_SELECT_GROUP_MEMBERSITE,
});

const fetchSelectedGroupFunnelSuccess = (data: IMultipleSelectedProps[]) => ({
  type: types.FETCH_SELECT_GROUP_FUNNEL_SUCCESS,
  data,
});
const fetchSelectedGroupFunnelFailed = (error: string) => ({
  type: types.FETCH_SELECT_GROUP_FUNNEL_FAILED,
  error,
});

export {
  fetchGroupFunnelList,
  fetchGroupFunnelListSuccess,
  fetchGroupFunnelListFailed,
  fetchSelectedGroupFunnel,
  fetchSelectedGroupFunnelSuccess,
  fetchSelectedGroupFunnelFailed,
  fetchSelectedGroupMembersite,
};
