import i18n from '../../../../i18n/i18n';

export enum RanksType {
  ALL = '',
  GENERAL = 1,
  VIP = 2,
  SVIP = 3,
}

export enum DeletedAtType {
  ALL = 1,
  NOT_DELETE = 2,
  DELETED = 3,
}

export enum StatusIdentify {
  UPLOADING = 1,
  PENDING = 2,
  RE_UPLOAD = 3,
  APPROVED = 4,
}

export const RANKS = [
  {
    type: RanksType.ALL,
    title: i18n.t('affiliate.partner.list.rank.allRank'),
  },
  {
    type: RanksType.GENERAL,
    title: i18n.t('affiliate.partner.list.rank.general'),
  },
  {
    type: RanksType.VIP,
    title: i18n.t('affiliate.partner.list.rank.vip'),
  },
  {
    type: RanksType.SVIP,
    title: i18n.t('affiliate.partner.list.rank.svip'),
  },
];

export const DELETED_AT = [
  {
    type: DeletedAtType.ALL,
    title: '全てのパートナー',
  },
  {
    type: DeletedAtType.NOT_DELETE,
    title: '稼働中のパートナー',
  },
  {
    type: DeletedAtType.DELETED,
    title: '削除済のパートナー',
  },
];
export interface IGuideType {
  title: string;
  content?: string;
}
export interface DataTableList {
  uuid: string;
  email: string;
  full_name: string;
  first_name_kanji: any;
  last_name_kanji: any;
  created_at: string;
  deleted_at: any;
  affiliate_rank: string;
  partner_site: string;
  partner_type: any;
  front_identify_path: any;
  reverse_identify_path: any;
  status_identify: number;
  is_checked?: boolean;
}

export const SORT_TYPE = {
  ASC: 1,
  DESC: 2,
  TEXT_DESC: 'DESC',
  TEXT_ASC: 'ASC',
};

export interface ColumnAffiliatePartnerList {
  name: any;
  minWidth?: number;
  maxWidth?: number;
  width?: number;
  isSort?: boolean;
  sortName?: string;
  isGuide?: boolean;
  guide?: IGuideType;
  sortType?: string | number;
  isFirstField?: boolean;
  isSecondField?: boolean;
  isLastField?: boolean;
  key?: string;
  isAction?: boolean;
}

export const COLUMNS_AFFILIATE_PARTNER_LIST: ColumnAffiliatePartnerList[] = [
  {
    name: 'メールアドレス',
    width: 200,
    minWidth: 100,
    maxWidth: 200,
    isSort: false,
    isGuide: false,
    isFirstField: true,
    isSecondField: true,
    sortType: SORT_TYPE.TEXT_DESC,
    isAction: true,
  },
  {
    name: '名前',
    width: 200,
    minWidth: 100,
    maxWidth: 200,
    isSort: false,
    isGuide: false,
    sortType: SORT_TYPE.TEXT_DESC,
  },
  {
    name: '登録日時',
    width: 200,
    minWidth: 100,
    maxWidth: 200,
    isSort: false,
    isGuide: false,
    sortType: SORT_TYPE.TEXT_DESC,
  },
  {
    name: '個人・法人',
    width: 200,
    minWidth: 100,
    maxWidth: 200,
    isSort: false,
    isGuide: false,
    sortType: SORT_TYPE.TEXT_DESC,
  },
  {
    name: '紹介ランク',
    width: 200,
    minWidth: 100,
    maxWidth: 200,
    isSort: false,
    isGuide: false,
    sortType: SORT_TYPE.TEXT_DESC,
  },
  {
    name: '紹介パートナーサイト',
    width: 200,
    minWidth: 100,
    maxWidth: 200,
    isSort: false,
    isGuide: false,
    sortType: SORT_TYPE.TEXT_DESC,
  },
  {
    name: 'パートナー詳細情報',
    width: 200,
    minWidth: 100,
    maxWidth: 200,
    isSort: false,
    isGuide: false,
    sortType: SORT_TYPE.TEXT_DESC,
  },
  {
    name: '本人確認資料データ	',
    width: 200,
    minWidth: 100,
    maxWidth: 200,
    isSort: false,
    isGuide: false,
    sortType: SORT_TYPE.TEXT_DESC,
  },
  {
    name: '本人確認ステータス	',
    width: 200,
    minWidth: 100,
    maxWidth: 200,
    isSort: false,
    isGuide: false,
    sortType: SORT_TYPE.TEXT_DESC,
  },
];
export interface ParamFilterPartner {
  search: string;
  affiliate_rank_id: RanksType;
  page: number;
  per_page: number;
  filter_deleted_at: DeletedAtType;
}

export const paramAllFilter: ParamFilterPartner = {
  search: '',
  affiliate_rank_id: RanksType.ALL,
  page: 1,
  per_page: 20,
  filter_deleted_at: DeletedAtType.ALL,
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 70,
    left: 100,
    behavior: 'smooth',
  });
};

export const DELETE_TYPE = {
  type: 'DELETE',
  title: i18n.t('affiliate.partner.list.delete'),
};

export interface ListRank {
  title: any;
  value: RanksType | StatusIdentify;
}

export const getFieldFilterPartner = (List: ListRank[], type: any) =>
  List.find((item) => item.value === type)?.value;
export const getValuePartner = (List: ListRank[], type: any) =>
  List.find((item) => item.value === type)?.title;

export const getNameStatus = (status: number) => {
  switch (status) {
    case StatusIdentify.UPLOADING:
      return i18n.t('affiliate.partner.list.identifyPatch.noUpload');
    case StatusIdentify.PENDING:
      return i18n.t('affiliate.partner.list.identifyPatch.pending');
    case StatusIdentify.RE_UPLOAD:
      return i18n.t('affiliate.partner.list.identifyPatch.reUpload');
    case StatusIdentify.APPROVED:
      return i18n.t('affiliate.partner.list.identifyPatch.approved');
    default:
      return '';
  }
};

export const defaultRank = (rank: string) => {
  return rank === i18n.t('affiliate.partner.list.rank.general')
    ? RanksType.VIP
    : RanksType.GENERAL;
};
export const defaultStatus = (rank: number) => {
  return rank === StatusIdentify.PENDING
    ? StatusIdentify.APPROVED
    : StatusIdentify.PENDING;
};
