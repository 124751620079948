import React from 'react';

export interface TButtonProps {
  type?: 'button' | 'submit';
  className?: string;
  disable?: boolean;
  title: string;
  onClick?: (e: any) => void;
}

const TButton: React.FC<TButtonProps> = ({
  className,
  disable = false,
  title,
  type,
  onClick,
}) => (
  <button
    type={type}
    className={`btn ${className}`}
    disabled={disable}
    onClick={onClick}
  >
    {title}
  </button>
);

export default TButton;
