// Project: 7612770651107e1659371833c56a9b25e733c4615968bb7c5582cdc92aa6249b
import { API_BASE_URL } from '../config';

const API = {
  // list template funnel
  fetchListTemplate: `${API_BASE_URL}/funnel/step/page/templates`,
  installTemplate: `${API_BASE_URL}/funnel/step/page/install-template`,
  getAllTemplates: `${API_BASE_URL}/funnel/get-all-template`,

  // funnel detail
  fetchFunnelDetail: (uuid: string, filterDeleted?: number) =>
    `${API_BASE_URL}/funnel/steps?funnel_uuid=${uuid}&filter_deleted=${
      filterDeleted ?? ''
    }`,
  // step funnel detail
  fetchStepFunnelDetail: (uuid: string) =>
    `${API_BASE_URL}/funnel/step/pages?funnel_step_uuid=${uuid}`,

  //step funnel metadata
  fetchFunnelStepMetadata: (uuid: string) =>
    `${API_BASE_URL}/funnel/step/meta?funnel_step_uuid=${uuid}`,

  updateFunnelStepMetadata: `${API_BASE_URL}/funnel/step/meta/save`,

  // membersite metadata
  fetchMembersiteMetadata: (uuid: string) =>
    `${API_BASE_URL}/membersite/step/meta?funnel_step_uuid=${uuid}`,

  updateMembersiteMetadata: `${API_BASE_URL}/membersite/step/meta/save`,

  renameFunnel: `${API_BASE_URL}/funnel/rename`,
  renameStep: `${API_BASE_URL}/funnel/step/rename`,
  deleteStep: `${API_BASE_URL}/funnel/step/delete`,
  createStep: `${API_BASE_URL}/funnel/step/create`,

  handleChangeTemplateURL: (uuid: string) =>
    `${API_BASE_URL}/funnel/step/update/${uuid}`,
  handleRenameFormFunnel: `${API_BASE_URL}/funnel/step/form/setting/rename`,

  submitSettingStepFunnel: `${API_BASE_URL}/funnel/step/setting`,
  fetchStepFunnelDetailSetting: (uuidStep: string) =>
    `${API_BASE_URL}/funnel/step/setting?funnel_step_uuid=${uuidStep}`,
  restoresSettingStep: `${API_BASE_URL}/funnel/page/restores`,
  fetchListRestores: (uuidStep: string) =>
    `${API_BASE_URL}/funnel/page/restores?page_uuid=${uuidStep}`,

  // access link
  fetchListAccessLink: (uuidFunnel: string) =>
    `${API_BASE_URL}/access-funnel?funnel_uuid=${uuidFunnel}`,
  createAccessLink: `${API_BASE_URL}/access-funnel/create`,
  removeAccessLink: (uuidAccessLink: string) =>
    `${API_BASE_URL}/access-funnel/delete/${uuidAccessLink}`,
  updateAccessLink: `${API_BASE_URL}/access-funnel/update`,
  // slider bar
  changePosition: `${API_BASE_URL}/funnel/step/change-position`,

  // affiliate
  fetchFunnelProduct: (funnel_step_uuid: string) =>
    `${API_BASE_URL}/funnel/step/form/setting/${funnel_step_uuid}`,
  affiliateFunnel: (funnel_uuid: string) =>
    `${API_BASE_URL}/affiliate/company/funnel/${funnel_uuid}/setting`,
  fetchProductPlan: `${API_BASE_URL}/product-plan`,
  getAllFunnelStepByGroupFunnel: `${API_BASE_URL}/funnel/funnel-step/group-funnel`,
  // A/B test
  postCreatePageVariation: `${API_BASE_URL}/funnel/step/page/create`,
  postDeclarePageWinner: `${API_BASE_URL}/funnel/step/page/declare-page-winner`,
  getListArchivedPage: `${API_BASE_URL}/funnel/step/page/archived-pages`,
  postUpdateStepFunnel: (uuid: string) =>
    `${API_BASE_URL}/funnel/step/page/update/${uuid}`,
  postRestorePage: `${API_BASE_URL}/funnel/step/page/restore-page`,
  postUpdateTraffic: `${API_BASE_URL}/funnel/step/traffic/update`,
  postStartABTest: `${API_BASE_URL}/funnel/step/ab-test/start`,
  postDeletePage: `${API_BASE_URL}/funnel/step/page/delete`,
  fetchFunnelStepPageVersions: (uuid: string) =>
    `${API_BASE_URL}/funnel/step/page/versions?page_uuid=${uuid}`,

  applyFunnelStepPageVersions: `${API_BASE_URL}/funnel/step/page/version/restore`,
};

export default API;
