import { toast } from 'react-toastify';
import { redirectWhenLoginIsSubDomain } from '../config/common.config';
import { hideLoading } from '../store/actions/loadingAction';
import { store } from '../store/configureStore';
import {
  AUTHEN_MKT_KEY,
  SYSTEM_USER_IDENTIFY,
  removeCookiesByName,
  removeCookiesByNameLocal,
} from '../utilities/cookies';
import {
  IHttpResponse,
  EStatusCodeData,
  EStatusHttp,
  IResponse,
  IHttpResponseError,
  IBaseErrorParameter,
} from './interfaces';

export const handleExpire = () => {
  if (process.env.APP_ENV === 'development') {
    removeCookiesByNameLocal(AUTHEN_MKT_KEY);
    removeCookiesByNameLocal(SYSTEM_USER_IDENTIFY);
  } else {
    removeCookiesByName(AUTHEN_MKT_KEY);
    removeCookiesByName(SYSTEM_USER_IDENTIFY);
  }
  setTimeout(() => {
    redirectWhenLoginIsSubDomain();
  }, 700);
};

export function getPath(url: string) {
  return `${process.env.APP_ENDPOINT}/${url}`;
}

export function getQueryParams(parameter: any): string {
  return Object.getOwnPropertyNames(parameter)
    .map((k: any) => {
      return `${k}=${parameter[k]}`;
    })
    .join('&');
}

/**
 * NOTE: get headers call api
 * @param {string}
 * @returns {object}
 */
export const GetHeadersAxios = (token: string) => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
};

export function showMessageResponse(res: any) {
  const { error, message, status } = res.data;
  if (res.status !== EStatusHttp.HTTP_TOO_MANY_REQUESTS) {
    switch (status) {
      case EStatusCodeData.FAILS:
        const getError = Object.keys(error);
        toast.error(error[getError[0]][0]);
        break;
      case EStatusCodeData.ERROR:
        toast.error(error);
        break;
      case EStatusCodeData.SUCCESS:
        toast.success(message);
        break;
      default:
        return;
    }
  }
}

/**
 * NOTE: Handle throw error with call api catch
 * @param {string}
 * @returns {void}
 */

export function completedResponse<T>(
  response: IResponse<T>,
  options?: IBaseErrorParameter
): IHttpResponse<T> {
  options?.hasLoading && store.dispatch(hideLoading());
  options?.notification?.success && showMessageResponse(response);

  if (response.status === EStatusHttp.HTTP_OK) {
    return {
      data: response.data?.data,
      status: response.status,
      message: response.data?.message,
    };
  }

  return {
    data: response.data?.data,
    status: response.status,
    message: response.data?.message,
  };
}

export function errorResponse(
  error: IHttpResponseError,
  options: IBaseErrorParameter
): IHttpResponseError {
  options?.notification?.error && showMessageResponse(error);
  !options?.hasLoading && store.dispatch(hideLoading());
  switch (error.status) {
    case EStatusHttp.HTTP_TOKEN_INVALID:
    case EStatusHttp.HTTP_UNAUTHORIZED:
      console.log('to do');
      break;
    case EStatusHttp.HTTP_FORBIDDEN:
      console.log('to do');
      break;
    default:
      break;
  }

  return {
    status: error.status,
    data: error.data,
  };
}
