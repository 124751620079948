import { API } from './api';
import axiosInstance from '../';
import {
  ProfileEditInfo,
  SignUpSubUser,
  DataFunnelDomain,
  IUnivapayAccount,
  IPayloadStripePromise,
  IPayloadConnectStripe,
} from './interfaces';

// Profile
export const getUserProfile = () => axiosInstance.get(API.getUserProfile());

export const getPrefectures = () => axiosInstance.get(API.getPrefectures());

export const updateUserProfile = (data: ProfileEditInfo) =>
  axiosInstance.post(API.updateUserProfile(), data);

export const updateProfilePassword = (data: any) =>
  axiosInstance.post(API.updateProfilePassword(), data);

export const sendMailToResetPassword = (data: { email: string }) =>
  axiosInstance.post(API.sendMailToResetPassword(), data);

export const verifyChangeEmail = (data: { token: string }) =>
  axiosInstance.post(API.verifyChangeEmail(), data);

// Sub User setting
export const getSubUsers = () => axiosInstance.get(API.getSubUsers());

export const getSubUserPermissions = (data?: { uuid: string }) =>
  axiosInstance.get(API.getSubUserPermissions(data?.uuid));

export const getRoleCurrentUser = () =>
  axiosInstance.get(API.getRoleCurrentuser());

export const inviteSubUser = (data: {
  email: string;
  role: string[];
  is_full_role: boolean;
}) => axiosInstance.post(API.inviteSubUser(), data);

export const resendInviteSubUser = (data: { uuid: string }) =>
  axiosInstance.post(API.resendInviteSubUser(), data);

export const removeSubUser = (data: { uuid: string }) =>
  axiosInstance.post(API.removeSubUser(), data);

export const updatePermissionsForSubUser = (data: {
  uuid: string;
  role: any[];
  is_full_role: boolean;
}) => axiosInstance.post(API.updatePermissionsForSubUser(), data);

export const checkInvitationTokenValid = (data: { invitation_token: string }) =>
  axiosInstance.post(API.checkInvitationTokenValid(), data);

export const getSubUserInformationInvite = (data: {
  invitation_token: string;
}) => axiosInstance.get(API.getSubUserInformationInvite(data.invitation_token));

export const acceptSubUserInvitation = (data: { invitation_token: string }) =>
  axiosInstance.post(API.acceptSubUserInvitation(), data);

export const signUpSubUser = (data: SignUpSubUser) =>
  axiosInstance.post(API.signUpSubUser(), data);

export const logout = () => axiosInstance.post(API.logout());

// Account Setting
export const getDataListEmailIntegrations = (params: {
  page: number;
  perPage: number;
}) => axiosInstance.get(API.getDataListEmailIntegrations(params));

export const getDataFunnelDomain = (params: DataFunnelDomain) =>
  axiosInstance.get(API.getDataFunnelDomain(params));

export const createEmailIntegrations = (params: any) =>
  axiosInstance.post(API.createEmailIntegrations(), params);

export const deleteEmailIntegrations = (params: { uuid: string }) =>
  axiosInstance.post(API.deleteEmailIntegrations(), params);

export const updateEmailIntegrations = (params: any) =>
  axiosInstance.post(API.updateEmailIntegrations(), params);

export const getDataEmailIntegrationsDetail = (uuid: string) =>
  axiosInstance.get(API.getDataEmailIntegrationsDetail(uuid));

export const fetchPaymentInformation = () =>
  axiosInstance.get(API.fetchPaymentInformation());

export const updatePaymentInformation = (params: any) =>
  axiosInstance.post(API.updatePaymentInformation(), params);

export const fetchPaymentHistory = (params: {
  perPage: number;
  page: number;
  sortName: string;
  sortType: string;
}) => axiosInstance.get(API.fetchPaymentHistory(params));

export const getFunnelDetail = (uuid: string) =>
  axiosInstance.get(API.getFunnelDetail(uuid));

export const getAllPage = () => axiosInstance.get(API.getAllPage());

export const updateFunnelDomain = (params: any) =>
  axiosInstance.post(API.updateFunnelDomain(), params);

export const verifyFunnelDomain = (params: { uuid: string }) =>
  axiosInstance.post(API.verifyFunnelDomain(), params);

export const verifyFunnelDomainSSL = (params: { uuid: string }) =>
  axiosInstance.post(API.verifyFunnelDomainSSL(), params);

export const deleteFunnelDomain = (params: { uuid: string }) =>
  axiosInstance.post(API.deleteFunnelDomain(), params);

export const createFunnelDomain = (params: { domain: string }) =>
  axiosInstance.post(API.createFunnelDomain(), params);

export const associateFunnelToDoMain = (params: {
  domain_uuid: string;
  funnel_uuid: string;
}) => axiosInstance.post(API.associateFunnelToDoMain(), params);

export const getAllFunnel = () =>
  axiosInstance.get(API.getAllFunnelInPageDomain());

export const getPaymentSetting = () => {
  return axiosInstance.get(API.getPaymentSetting());
};

export const createPaypalAccount = (params: any) =>
  axiosInstance.post(API.createPaypalAccount(), params);

export const createGMOAccount = (params: any) =>
  axiosInstance.post(API.createGMOAccount(), params);

export const createUnivapayAccount = (params: IUnivapayAccount) =>
  axiosInstance.post(API.createUnivapayAccount(), params);

export const connectStripeAccount = (params: { setting_uuid: string }) => {
  return axiosInstance.post(API.connectStripeAccount(), params);
};

export const deletePaymentSetting = (params: any) => {
  return axiosInstance.post(API.deletePaymentSetting(), params);
};

export const updateMethodGMO = (params: any) =>
  axiosInstance.post(API.updateMethodGMO(), params);

export const getDetailPaymentSetting = (uuid: string) =>
  axiosInstance.get(API.getDetailPaymentSetting(uuid));

export const updateMethodPaypal = (params: any) =>
  axiosInstance.post(API.updateMethodPaypal(), params);

export const updateMethodUnivapay = (params: IUnivapayAccount) =>
  axiosInstance.post(API.updateMethodUnivapay(), params);

// facebook pixel
export const getListFacebookIntegration = () =>
  axiosInstance.get(API.getListFacebookIntegration());

export const getListFacebookPixel = (uuid: string) =>
  axiosInstance.get(API.getListFacebookPixel(uuid));

export const removeFacebookPixel = (params: { uuid: string }) =>
  axiosInstance.post(API.deleteFacebookPixel(), params);

// integrations
export const getCompanyIntegrations = () =>
  axiosInstance.get(API.getCompanyIntegrations());

export const deleteIntegration = (uuid: string) =>
  axiosInstance.post(API.deleteIntegration(uuid));

export const createIntegration = (params: any) => {
  const { integration_id, name, api_key, subdomain, code } = params;
  const res = {
    integration_id,
    name,
    api_key,
    subdomain,
    code,
  };
  return axiosInstance.post(API.createIntegration(), res);
};

export const updateIntegration = (
  params: any,
  company_integration_uuid: string
) =>
  axiosInstance.post(API.updateIntegration(company_integration_uuid), params);

export const getDetailIntegration = (uuid: string) =>
  axiosInstance.get(API.getDetailIntegration(uuid));

export const createFacebookIntegration = (params: any) =>
  axiosInstance.post(API.createFacebookIntegration(), params);

export const updateFacebookIntegration = (uuid: string, params: any) =>
  axiosInstance.post(API.updateFacebookIntegration(uuid), params);

export const getDetailFacebookIntegration = (uuid: string) =>
  axiosInstance.get(API.getDetailFacebookIntegration(uuid));

export const downLoadInvoicePdf = (uuid: string) =>
  axiosInstance.get(API.downLoadInvoicePdf(uuid), {
    responseType: 'blob',
    headers: {
      Accept: 'application/octet-stream',
    },
  });

// stripe
export const getStripePromise = () => axiosInstance.get(API.getStripePromise());

export const postStripePromise = (payload: IPayloadStripePromise) =>
  axiosInstance.post(API.getStripePromise(), payload);

export const postConnectStripe = (payload: IPayloadConnectStripe) =>
  axiosInstance.post(API.postConnectStripe(), payload);

export const updateMethodStripe = (payload: IPayloadConnectStripe) =>
  axiosInstance.post(API.updateMethodStripe(), payload);
