import { getPath } from '../helper';

export const usersAPI = {
  getListUser: () => getPath(`customer/search`),
  deleteUser: (uuid: string) => getPath(`customer/delete/${uuid}`),
  getDataUserDetail: (uuid?: string) => getPath(`customer/${uuid}`),
  getUserUpdate: (uuid: string) => getPath(`customer/update/${uuid}`),
  updateInfoUser: (uuid: string) => getPath(`customer/update/${uuid}`),
  getStatisticsUser: (params: any) =>
    getPath(
      `customer/${params.uuid}/transactions?type=${params.type}&perPage=${params.perPage}&page=${params.page}`
    ),
  getDataMailListInPageManageUser: (params: any) =>
    getPath(
      `customer/${params.uuid}/mails?type=${params.type}&perPage=${params.perPage}&page=${params.page}`
    ),
  getAllPaymentMethod: (id: any, idUser: any) =>
    getPath(`customer/${idUser}/transactions/${id}`),
  editPaymentMethod: (params: any) =>
    getPath(
      `customer/${params.uuid}/transactions/${params.id}/change-payment-method`
    ),
  editPaymentStatus: (params: any) =>
    getPath(
      `customer/${params.uuid}/transactions/${params.id}/set-status/${params.newStatus}`
    ),
  removeUserFormMailList: (params: any) =>
    getPath(`mail-list/${params.uuid}/remove/${params.user_uuid}`),
  stopSendStepMail: () => getPath(`mail-list/stop/send-step-mail`),
  getBlackListUser: (params: any) =>
    getPath(`customer/blacklist?perPage=${params.perPage}&page=${params.page}`),
  addMailToBlackListUser: () => getPath(`customer/blacklist/add`),
  deleteMailToBlackListUser: (params: any) =>
    getPath(`customer/blacklist/delete/${params.mail_uuid}`),
  getMailsList: () => getPath(`mail-lists`),
  getListProduct: () => getPath(`list/products`),
  getMailListPageManageUser: (uuid: string) =>
    getPath(`mail-list/list?group_uuids=${uuid}`),
  getProductListPageManageUser: (uuid: string) =>
    getPath(`products?group_uuids=${uuid}`),
  editNameMail: () => getPath(`mail-list/change/mail`),
  getPageListUserMemberShip: () => getPath(`customer/membersite/enrolled`),
  deleteUserMemberShip: () => getPath(`customer/membersite/destroy`),
  updateExpiredDate: () => getPath(`customer/update-expired-number`),
  verifyRegisterToken: () => getPath(`register-link/check-link`),
  postStopRemindMail: () => getPath(`mail-list/stop/send-remind-mail`),
};
