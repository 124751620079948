import * as Types from '../../actions/timer/types';
import { IManagerTimer, initialState } from './interface';

export default function timerReducer(
  state: IManagerTimer = initialState,
  action: any
): IManagerTimer {
  switch (action.type) {
    case Types.GET_LIST_GROUP_TIMER_SUCCESS:
      return {
        ...state,
        listGroupTimer: action.data,
      };
    case Types.GET_WHITELIST_USER_SUCCESS:
      return {
        ...state,
        whitelistUser: action.data,
      };
    case Types.GET_LIST_TIMER_SUCCESS:
      return {
        ...state,
        listTimerByGroup: action.data,
      };
    default:
      return state;
  }
}
