import axiosClient from '..';
import { timerAPI } from './api';
import {
  IParamsCreateTimer,
  IParamsDeleteGroupTimer,
  IParamsDuplicateGroupTimer,
  IParamsGetListTimer,
  IParamsPublishTimer,
  IParamsRenameGroupTimer,
  IParamsRenameTimer,
  IParamsResetTracking,
  IParamsUpdateWhitelistUser,
} from './interface';

export const getListGroupTimer = () =>
  axiosClient.get(timerAPI.getListGroupTimer);
export const createTimer = (params: IParamsCreateTimer) =>
  axiosClient.post(timerAPI.createTimer, params);
export const getTimerDetail = (timer_uuid: string) =>
  axiosClient.get(timerAPI.getTimerDetail(timer_uuid));
export const updateTimer = (params: IParamsCreateTimer) =>
  axiosClient.put(timerAPI.updateTimer, params);
export const getWhitelistUser = () =>
  axiosClient.get(timerAPI.getWhitelistUser);
export const updateWhitelistUser = (params: IParamsUpdateWhitelistUser) =>
  axiosClient.post(timerAPI.updateWhitelistUser, params);
export const getCurrentMyIP = () => axiosClient.get(timerAPI.getCurrentMyIP);
export const getListTimer = (params: IParamsGetListTimer) =>
  axiosClient.get(timerAPI.getListTimer, { params });
export const publishTimer = (timer_uuid: string, params: IParamsPublishTimer) =>
  axiosClient.patch(timerAPI.publishTimer(timer_uuid), params);
export const duplicateTimer = (timer_uuid: string) =>
  axiosClient.post(timerAPI.duplicateTimer(timer_uuid));
export const deleteTimer = (timer_uuid: string) =>
  axiosClient.delete(timerAPI.deleteTimer(timer_uuid));
export const renameTimer = (timer_uuid: string, params: IParamsRenameTimer) =>
  axiosClient.patch(timerAPI.renameTimer(timer_uuid), params);

export const deleteGroupTimer = (params: IParamsDeleteGroupTimer) =>
  axiosClient.post(timerAPI.deleteGroupTimer, params);
export const duplicateGroupTimer = (params: IParamsDuplicateGroupTimer) =>
  axiosClient.post(timerAPI.duplicateGroupTimer, params);
export const renameGroupTimer = (params: IParamsRenameGroupTimer) =>
  axiosClient.post(timerAPI.renameGroupTimer, params);

export const resetTracking = (params: IParamsResetTracking) =>
  axiosClient.post(timerAPI.resetTracking, params);
