import { getPath } from '../helper';

export const endPoints = {
  getPageListTemplates: () =>
    getPath(`funnel/step/page/templates/company-templates`),
  duplicateTemplates: () => getPath(`funnel/step/page/templates/duplicate`),
  changeNameTemplates: () => getPath(`funnel/step/page/templates/update`),
  getTemplateById: () => getPath(`funnel/step/page/templates/show`),
  deleteTemplateById: () => getPath(`funnel/step/page/templates/destroy`),
  sharedTemplates: () => getPath(`funnel/step/page/templates/share`),

  getPageListMembersiteTemplates: () =>
    getPath(`membersite/course/templates/company-templates`),
  duplicateMembersiteTemplates: () =>
    getPath(`membersite/course/templates/duplicate`),
  changeNameMembersiteTemplates: () =>
    getPath(`membersite/course/templates/update`),
  getMembersiteTemplateById: () => getPath(`membersite/course/templates/show`),
  deleteMembersiteTemplateById: () =>
    getPath(`membersite/course/templates/destroy`),
  sharedMembersiteTemplates: () => getPath(`membersite/course/templates/share`),

  // Block template
  getListBlockTemplates: () => getPath('sections'),
  getAllCategoriesBlockTemplate: () => getPath('section/categories'),
  updateBlockTemplate: () => getPath('section/update'),
  getBlockTemplateDetail: (uuid: string) => getPath(`section/${uuid}/show`),
  deleteBlockTemplate: (uuid: string) => getPath(`section/${uuid}/delete`),
};
