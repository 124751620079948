import * as Types from '../../actions/myPage/types';
import { ProfileReducer, PrefecturesReducer } from './interface';

interface MyPageState {
  profile: ProfileReducer;
  prefectures: PrefecturesReducer;
}

const initialState: MyPageState = {
  profile: {
    isLoading: false,
    data: {
      user_profile: {
        user_name: '',
        email: 'no-email',
        previous_email: undefined,
        email_confirm_status: undefined,
        login_id: undefined,
        password_require: undefined,
        zip_code: undefined,
        prefecture_id: undefined,
        city: undefined,
        building: undefined,
        room_number: undefined,
      },
      role: {
        role: [],
        is_full_role: false,
        is_owner: true,
      },
      companies: [],
      current_company: {
        address: '',
        zip_code: '',
        city: '',
        area: '',
        building: '',
        room_number: '',
        card_brand: '',
        card_last_four: undefined,
        time_zone: '',
      },
      current_plan: {
        currency: 1,
        description: '',
        name: '',
        price: 0,
        type: undefined,
        uuid: '',
      },
      unread_notification: 0,
      has_card_status: true,
      company_sell_funecy: false,
      flag_setting_affiliate: false,
    },
    error: undefined,
  },
  prefectures: {
    data: [],
    error: undefined,
  },
};

export default function myPageReducer(
  state: MyPageState = initialState,
  action: any
): MyPageState {
  switch (action.type) {
    // Get user profile
    case Types.FETCH_USER_PROFILE_LOADING:
      return {
        ...state,
        profile: {
          ...state.profile,
          isLoading: true,
        },
      };
    case Types.FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          isLoading: false,
          data: action.data,
        },
      };
    case Types.FETCH_USER_PROFILE_ERROR:
      return {
        ...state,
        profile: {
          ...state.profile,
          isLoading: false,
          error: action.error,
        },
      };
    case Types.UPDATE_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        profile: {
          ...state.profile,
          data: action.data,
        },
      };
    // Get prefectures
    case Types.FETCH_PREFECTURES_SUCCESS:
      const prefectures = action.data.map((prefecture: any) => ({
        title: prefecture.kanji_name,
        value: prefecture.id,
      }));
      return {
        ...state,
        prefectures: {
          ...state.prefectures,
          data: prefectures,
        },
      };
    case Types.FETCH_PREFECTURES_ERROR:
      return {
        ...state,
        prefectures: {
          ...state.prefectures,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
