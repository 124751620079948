import { Row } from 'antd';
import styled from 'styled-components';

const PaginationWrapper = styled(Row)`
  .ant-pagination-item-active {
    font-weight: 500;
    background: var(--t-main-color);
    border-radius: 50%;
    color: white;
    border: none;
  }

  .pagination-icon {
    color: var(--t-main-color) !important;
    text-decoration: none;
    &.next {
      padding-left: 25px;
    }
    &.prev {
      padding-right: 25px;
    }
    svg {
      font-size: 13px;
      margin-bottom: 4px;
      color: var(--t-main-color);
    }
  }
  .pagination-icon[disabled] {
    color: gray !important;
    svg {
      color: gray !important;
    }
  }

  .ant-pagination-prev {
    &.ant-pagination-disabled {
      display: none;
    }
  }
  .ant-pagination-item-link-icon {
    svg {
      margin-bottom: 6px;
      color: var(--t-main-color);
    }
  }
`;

const NextPrevWrapper = styled.a`
  color: var(--t-main-color) !important;
  text-decoration: none;

  &.next {
    padding-left: 15px;
  }
  &.prev {
    padding-right: 15px;
  }
  svg {
    font-size: 13px;
    margin-bottom: 4px;
    color: var(--t-main-color);
  }
`;

export { NextPrevWrapper, PaginationWrapper };
