// use library node_modules
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

// use helper and config, services
import { SortField, SortOrder, TableColumn } from '../types';
import { useGroupFunnelsList } from '../../../../../../hooks';

const TableCol: React.FC<TableColumn> = ({
  name,
  cell,
  pageId = '',
  sortDirection = SortOrder.BLANK,
  sortField = SortField.BLANK,
  sortable,
  width,
  sortPage = '',
  onSortFunction = () => {},
  selectedColumn,
}) => {
  const [hasSelected, setHasSelected] = useState<boolean>(false);
  const [groupFunnels] = useGroupFunnelsList();

  useEffect(() => {
    if (!isEmpty(sortField) && sortField !== selectedColumn) {
      setHasSelected(false);
    }
  }, [selectedColumn]);

  useEffect(() => {
    if (!isEmpty(sortPage) && sortPage !== pageId) {
      setHasSelected(false);
    }
  }, [sortPage]);

  const handleSortChange = () => {
    if (!sortable) return;

    setHasSelected(!hasSelected);

    const sortDirection = hasSelected ? SortOrder.DESC : SortOrder.ASC;
    if (
      selectedColumn === sortField &&
      sortDirection === SortOrder.ASC &&
      pageId === groupFunnels.payload.sort_group
    ) {
      setHasSelected(false);
      onSortFunction(SortField.BLANK, SortOrder.BLANK, pageId);
    } else {
      onSortFunction(sortField, sortDirection, pageId);
    }
  };

  const renderCustomSortIcon = () => {
    const sortIcon = hasSelected ? <CaretUpOutlined /> : <CaretDownOutlined />;
    return (
      <span className={[sortDirection, 'custom_sort_icon'].join(' ')}>
        {sortIcon}
      </span>
    );
  };

  return (
    <ColumnWrapper
      scope={cell}
      widthFlex={width}
      sortable={sortable}
      selected={hasSelected}
      onClick={handleSortChange}
      sortField={sortField}
      selectedColumn={selectedColumn}
      pageId={pageId}
      pageIdSort={groupFunnels.payload.sort_group}
    >
      {name}
      {sortable && renderCustomSortIcon()}
    </ColumnWrapper>
  );
};

const ColumnWrapper = styled.td`
  font-weight: 600;
  cursor: ${(props: any) => (props.sortable ? 'pointer' : 'default')};
  color: ${(props: any) =>
    props.sortField !== SortField.BLANK &&
    props.selectedColumn === props.sortField &&
    props.pageId === props.pageIdSort
      ? 'var(--t-main-color)'
      : 'var(--t-text-color)'};
  width: ${(props: any) => (props.widthFlex ? props.widthFlex : 'auto')};
  .custom_sort_icon {
    svg {
      margin-bottom: 5px;
      height: 12px;
      color: ${(props: any) =>
        props.columnName !== SortField.BLANK &&
        props.selectedColumn === props.sortField &&
        props.pageId === props.pageIdSort
          ? 'var(--t-main-color)'
          : 'gray'};
    }
  }
`;

export default TableCol;
