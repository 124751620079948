export interface IMeta {
  current_page: number;
  last_page: number;
  per_page: number;
  total: number;
}

export interface ICategoryBlockTemplate {
  id: number;
  name: string;
  description: string;
}

export interface IBlockTemplate {
  uuid: string;
  name: string;
  type: number;
  thumbnail: string;
  content: string;
  html_file: string;
  section_template_categories: ICategoryBlockTemplate[];
}

export interface IListBlockTemplates {
  data: IBlockTemplate[];
  meta: IMeta;
}

export interface InitialTemplateManagement {
  listBlockTemplates: IListBlockTemplates;
  listCategoriesBlockTemplate: ICategoryBlockTemplate[];
  blockTemplateDetail: IBlockTemplate;
}

export const initialTemplateManagement: InitialTemplateManagement = {
  listBlockTemplates: {
    data: [],
    meta: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  },
  listCategoriesBlockTemplate: [],
  blockTemplateDetail: {
    uuid: '',
    name: '',
    type: 0,
    thumbnail: '',
    content: '',
    html_file: '',
    section_template_categories: [],
  },
};
