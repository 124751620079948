import React, { useState } from 'react';

import classnames from 'classnames';
import { Col, Spin } from 'antd';

import './style.scss';
import searchIcon from '../../../../assets/img/menu-icons/search-icon.png';
import closeIcon from '../../../../assets/img/menu-icons/close-icon.png';

import InputSearch from './InputSearch';
import SearchViewSwitch from './SearchView/SearchViewSwitch';

import { searchAllSystem } from '../../../Clients/reports';
import { useTranslation } from 'react-i18next';

export interface SearchAllContainerProps {
  setOnOffSearch: any;
  handleOffMenu: any;
  onOffSearch: boolean;
}

const SearchAllContainer: React.FC<SearchAllContainerProps> = ({
  setOnOffSearch,
  onOffSearch,
  handleOffMenu,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNotFound, setIsNotFound] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [isClearInput, setIsClearInput] = useState<boolean>(false);
  const [textSearch, setTextSearch] = useState<string>('');
  const [isFirst, setIsFirst] = useState<boolean>(true);

  const handleSearch = async (textFilter: string) => {
    setIsLoading(true);
    setIsClearInput(false);
    setTextSearch(textFilter);
    setIsFirst(false);
    try {
      const res = await searchAllSystem(textFilter);

      if (res.status === 200) {
        setData(res.data.data);
        setIsNotFound(false);
      } else {
        setIsNotFound(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsNotFound(true);
      setData([]);
      setIsLoading(false);
    }
  };

  return (
    <Col
      span={8}
      className="p-2 wrap-search-all"
      tabIndex={-1}
      onBlur={(e: any) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setOnOffSearch(false);
          setIsClearInput(true);
        }
      }}
    >
      <div className="row-search">
        <div className="content-before" onClick={handleOffMenu}>
          <img className="search-icon" src={searchIcon} alt="search-icon" />
          <span>検索</span>
        </div>
        <div
          className={classnames(
            'content-search-modal shadow-sm bg-white rounded',
            { show: onOffSearch },
            { hide: !onOffSearch }
          )}
        >
          <div className="content-after">
            <Spin
              tip="Loading..."
              spinning={isLoading}
              style={{ color: 'var(--t-main-color)' }}
            >
              <InputSearch
                onSubmit={handleSearch}
                isClearInput={isClearInput}
              />
            </Spin>
            <img
              className="close-icon"
              src={closeIcon}
              alt="close-icon"
              onClick={() => {
                setOnOffSearch(!onOffSearch);
                setIsClearInput(true);
              }}
              tabIndex={-1}
            />
          </div>
          {isNotFound ? (
            <div className="data-not-found">
              {!isFirst && !isLoading && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: textSearch
                      ? t('searchSystem.notFound', {
                          key_word: textSearch,
                        })
                      : '',
                  }}
                />
              )}
            </div>
          ) : (
            <div className="main-scroll">
              <div className="content-main">
                <p className="m-0 p-0 main-title"></p>
                <ul className="main-item">
                  {data?.map((item: any, index: number) => (
                    <SearchViewSwitch
                      key={`search-switch-${index}`}
                      data={item}
                    />
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </Col>
  );
};

export default SearchAllContainer;
