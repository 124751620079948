import { ETypeEvent } from '../../../containers/Calendar/CreateEvent/config';

export interface IMetaGroupEvent {
  current_page: number;
  last_page: number;
  per_page: number;
  total: number;
}

export interface IEvent {
  uuid: string;
  name: string;
  type: number;
  is_publish: number;
  updated_at: Date;
}

export interface IGroup {
  uuid: string;
  name: string;
}

export interface IListGroupEvent {
  uuid: string;
  name: string;
  events?: IEvent[];
}

export interface IListEvent {
  meta: IMetaGroupEvent;
  data: {
    groups: IListGroupEvent[];
  };
}

export interface IEventOnSchedule {
  uuid: string;
  name: string;
  type: ETypeEvent;
  participant: number;
  duration_hour: string;
  duration_minute: string;
  created_at: string;
  updated_at: string;
  color: string;
}

export interface IListEventByGroups {
  data: IEventOnSchedule[];
  current_page: number;
  last_page: number;
}

export interface ISchedule {
  schedule_uuid: string;
  color: string;
  isWaiting: boolean;
  isHide?: boolean;
  name: string;
  start_date: string;
  end_date: string;
  localStartDate?: string;
  localEndDate?: string;
  event_uuid: string;
  count_user_calendars?: number;
  participants?: number;
  zIndex?: number;
  width?: number;
}

export interface IManagerEvent {
  listEvent: IListEvent;
  listGroup: IGroup[];
  nameGroup: string;
  listEventByGroups: IListEventByGroups;
  listSchedules: ISchedule[];
  startWeek: string;
  endWeek: string;
}

export interface IParamsAddScheduleSuccess {
  type: string;
  old_schedule_uuid: string;
  new_schedule_uuid: string;
}

export interface IParamsSchedulesUuid {
  type: string;
  schedule_uuid: string;
}

export interface IParamsListSchedule {
  type: string;
  listSchedules: string[];
}

export interface IParamsMoveScheduleOverDayFail {
  type: string;
  schedule_uuid: string;
  listOldChildSchedule: ISchedule[];
}

export interface IParamsMoveScheduleFail {
  type: string;
  schedule_uuid: string;
  oldTimeStart: string;
  oldTimeEnd: string;
}

export const initialState: IManagerEvent = {
  listEvent: {
    meta: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
    data: {
      groups: [],
    },
  },
  listGroup: [],
  nameGroup: '',
  listEventByGroups: {
    current_page: 1,
    last_page: 1,
    data: [],
  },
  listSchedules: [],
  startWeek: '',
  endWeek: '',
};
