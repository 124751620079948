import {
  LINK_SETTING_ACCOUNT,
  LINK_USER,
  MY_PAGE,
  ROLE_MENU_ITEM,
  MENU_TILES,
  FUNNEL,
  PRODUCT,
  MAIL_PATH,
  REPORT_PATH,
  MEMBERSITE,
  AFFILIATE,
  REMIND_MAIL,
  CALENDAR,
} from './../constants/menus';
import { IMenuItem } from '../interfaces/layout/IMenu.interfaces';
import i18n from '../i18n/i18n';
import accountSetting from '../../assets/img/menu-icons/account-setting.png';
import affiliateIcon from '../../assets/img/menu-icons/affiliate-icon.png';
import createdProductIcon from '../../assets/img/menu-icons/create-product-icon.png';
import createFunnel from '../../assets/img/menu-icons/create-funnel.png';
import createNewMailListIcon from '../../assets/img/menu-icons/create-new-mail-list.png';
import email from '../../assets/img/menu-icons/email.png';
import fixedPhraseIcon from '../../assets/img/menu-icons/icon-fixed-phrase.svg';
import funnel from '../../assets/img/menu-icons/funnel.png';
import listFunnel from '../../assets/img/menu-icons/list-funnel.png';
import listMailListIcon from '../../assets/img/menu-icons/list-mail-list.png';
import logoutIcon from '../../assets/img/menu-icons/logout-icon.png';
import mailListAllIcon from '../../assets/img/menu-icons/mail-list-all.png';
import manageUser from '../../assets/img/menu-icons/manage-user.png';
import myPage from '../../assets/img/menu-icons/my-page-icon.png';
import productIcon from '../../assets/img/menu-icons/product-icon.png';
import productR from '../../assets/img/menu-icons/product-r.png';
import reportIcon from './../../assets/img/menu-icons/report-icon.png';
import sale from '../../assets/img/menu-icons/sale.png';
import stepMailCreateIcon from '../../assets/img/menu-icons/create-step-mail.png';
import stepMailListIcon from '../../assets/img/menu-icons/step-mail-list.png';
import synthesisReports from './../../assets/img/menu-icons/synthesis-reports.png';
import userIcon from '../../assets/img/menu-icons/user-icon-1.png';
import userIconMenuBlacklist from './../../assets/img/menu-icons/icon-menu-user-blacklist.png';
import userIconMenuH001 from './../../assets/img/menu-icons/icon-menu-user-h001.png';
import plusTagIconMenuH007 from '../../assets/img/menu-icons/icon-plus-tag.svg';
import tagIconMenuG005 from '../../assets/img/menu-icons/icon-tag.svg';
import addTimerIcon from '../../assets/img/menu-icons/add-timer-icon.png';
import listTimerIcon from '../../assets/img/menu-icons/timer-list-icon.png';
import createEvent from '../../assets/img/menu-icons/create-event.png';
import listEvent from '../../assets/img/menu-icons/list-event.png';

export const Menus: IMenuItem[] = [
  {
    path: MENU_TILES.FUNNEL,
    title: i18n.t('menu.funnel'),
    icon: funnel,
    styleIcon: 4,
    children: [
      {
        path: FUNNEL.CREATE,
        title: i18n.t('menu.funnel.create'),
        icon: createFunnel,
        isEdit: ROLE_MENU_ITEM.EDIT,
        pathChildren: [],
      },
      {
        path: FUNNEL.GROUP,
        title: i18n.t('menu.funnel.list'),
        icon: listFunnel,
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        pathChildren: [
          {
            path: FUNNEL.LIST,
          },
          {
            path: FUNNEL.RECYCLE,
          },
          {
            path: FUNNEL.SETTING,
          },
          {
            path: FUNNEL.FUNNEL_DETAIL,
          },
        ],
      },
      {
        title: i18n.t('menu.funnel.template'),
        path: FUNNEL.LIST_TEMPLATE_PARAMS_DEFAULT,
        icon: listFunnel,
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        pathChildren: [
          {
            path: FUNNEL.TEMPLATE,
          },
          { path: FUNNEL.SHARED_TEMPLATE },
          {
            path: FUNNEL.LIST_TEMPLATE,
          },
        ],
      },
      {
        title: i18n.t('menu.blockTemplate.title'),
        path: FUNNEL.LIST_BLOCK_TEMPLATE_PARAMS_DEFAULT,
        icon: listFunnel,
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        pathChildren: [
          {
            path: FUNNEL.LIST_BLOCK_TEMPLATE,
          },
          {
            path: FUNNEL.BLOCK_TEMPLATE,
          },
        ],
      },
    ],
  },
  {
    path: MENU_TILES.CALENDAR,
    title: i18n.t('menu.calendar'),
    icon: listEvent,
    styleIcon: 4,
    children: [
      {
        path: CALENDAR.CREATE,
        title: i18n.t('menu.calendar.createEvent'),
        icon: createEvent,
        isEdit: ROLE_MENU_ITEM.EDIT,
        pathChildren: [],
      },
      {
        path: CALENDAR.LIST,
        title: i18n.t('menu.calendar.listEvent'),
        icon: listEvent,
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        pathChildren: [
          {
            path: CALENDAR.SETTING_SCHEDULE,
          },
          {
            path: CALENDAR.SETTING_BASIC,
          },
        ],
      },
      {
        title: i18n.t('timer.create.title'),
        path: FUNNEL.CREATE_TIMER,
        icon: addTimerIcon,
        isEdit: ROLE_MENU_ITEM.EDIT,
        pathChildren: [],
      },
      {
        title: i18n.t('timer.list.title'),
        path: FUNNEL.LIST_TIMER,
        icon: listTimerIcon,
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        pathChildren: [{ path: FUNNEL.EDIT_TIMER }],
      },
      {
        icon: listTimerIcon,
        path: LINK_USER.WHITELIST_USER,
        title: i18n.t('timer.whitelistUser.title'),
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
      },
    ],
  },
  {
    path: MENU_TILES.REGISTER_PRODUCT,
    title: i18n.t('menu.registerProduct'),
    icon: productR,
    children: [
      {
        icon: createdProductIcon,
        path: PRODUCT.REGISTER,
        title: i18n.t('menu.registerProduct.create'),
        isEdit: ROLE_MENU_ITEM.EDIT,
      },
      {
        icon: productIcon,
        path: PRODUCT.GROUP,
        title: i18n.t('menu.registerProduct.list'),
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        pathChildren: [
          {
            path: PRODUCT.UPDATE,
          },
        ],
      },
    ],
  },
  {
    path: MENU_TILES.MAIL,
    title: i18n.t('menu.mail'),
    icon: email,
    children: [
      {
        icon: createNewMailListIcon,
        path: MAIL_PATH.MAIL_LIST_CREATE,
        title: i18n.t('menu.mail.createListEmailList'),
        isEdit: ROLE_MENU_ITEM.EDIT,
      },
      {
        icon: listMailListIcon,
        path: MAIL_PATH.MAIL_LIST,
        title: i18n.t('menu.mail.listEmailList'),
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        pathChildren: [
          {
            path: MAIL_PATH.MAIL_LIST_BY_NAME,
          },
          {
            path: MAIL_PATH.MAIL_LIST_CREATE_BY_NAME,
          },
          {
            path: MAIL_PATH.MAIL_LIST_IMPORT_CSV,
          },
          {
            path: MAIL_PATH.MAIL_LIST_USER,
          },
        ],
      },
      {
        icon: mailListAllIcon,
        path: MAIL_PATH.SENT_MAIL_LIST,
        title: i18n.t('menu.mail.sendMailList'),
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        pathChildren: [
          {
            path: MAIL_PATH.SENT_MAIL_DETAIL,
          },
          {
            path: MAIL_PATH.BULK_MAIL_CREATE,
          },
          {
            path: MAIL_PATH.BULK_MAIL_COPY,
          },
          {
            path: MAIL_PATH.BULK_MAIL_DETAIL,
          },
        ],
      },
      {
        icon: stepMailCreateIcon,
        path: MAIL_PATH.CREATE_STEP_MAIL,
        title: i18n.t('menu.mail.createStep'),
        isEdit: ROLE_MENU_ITEM.EDIT,
      },
      {
        icon: stepMailListIcon,
        path: MAIL_PATH.LIST_STEP_MAIL,
        title: i18n.t('menu.mail.listStep'),
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        pathChildren: [
          {
            path: MAIL_PATH.DETAIL_STEP_MAIL,
          },
          {
            path: MAIL_PATH.STEP_MAIL_VIEW,
          },
          {
            path: MAIL_PATH.DETAIL,
          },
          {
            path: MAIL_PATH.STEP_MAIL_UPDATE,
          },
        ],
      },
      {
        icon: stepMailCreateIcon,
        path: REMIND_MAIL.CREATE_REMIND_MAIL,
        title: i18n.t('menu.mail.createRemindMail'),
        isEdit: ROLE_MENU_ITEM.EDIT,
      },
      {
        icon: stepMailListIcon,
        path: REMIND_MAIL.LIST_REMIND_MAIL,
        title: i18n.t('menu.mail.listRemindMail'),
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        pathChildren: [
          {
            path: REMIND_MAIL.DETAIL_REMIND_MAIL,
          },
          {
            path: REMIND_MAIL.REMIND_MAIL_UPDATE,
          },
          {
            path: REMIND_MAIL.EDIT_MAIL,
          },
        ],
      },
      {
        icon: fixedPhraseIcon,
        path: MAIL_PATH.MAIL_FIXED_PHRASE,
        title: i18n.t('menu.fixed.list'),
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        pathChildren: [{ path: MAIL_PATH.MAIL_FIXED_PHRASE_LIST }],
      },
    ],
  },
  {
    path: MENU_TILES.USER,
    title: i18n.t('menu.user'),
    icon: manageUser,
    children: [
      {
        icon: userIconMenuH001,
        path: LINK_USER.LIST_USER,
        title: i18n.t('menu.user.list'),
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        pathChildren: [
          {
            path: LINK_USER.USER_DETAIL,
          },
          {
            path: LINK_USER.EDIT_USER,
          },
          {
            path: LINK_USER.MAIL_LIST,
          },
          {
            path: LINK_USER.STATISTICS,
          },
          {
            path: LINK_USER.USER_MEMBERSHIP,
          },
          {
            path: LINK_USER.USER_DETAIL_TAG,
          },
        ],
      },
      {
        icon: userIconMenuBlacklist,
        path: LINK_USER.BLACKLIST_USER,
        title: i18n.t('menu.user.manageBackList'),
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
      },
      {
        icon: plusTagIconMenuH007,
        path: LINK_USER.CREATE_TAG,
        title: i18n.t('menu.user.createTag'),
        isEdit: ROLE_MENU_ITEM.EDIT,
      },
      {
        icon: tagIconMenuG005,
        path: LINK_USER.LIST_TAG,
        title: i18n.t('menu.user.listTag'),
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        pathChildren: [
          {
            path: LINK_USER.LIST_TAG_USER,
          },
          {
            path: LINK_USER.EDIT_TAG,
          },
        ],
      },
    ],
  },
  {
    path: MENU_TILES.MEMBERSITE,
    title: i18n.t('menu.membersite'),
    icon: funnel,
    children: [
      {
        path: MEMBERSITE.CREATE_COURSE,
        title: i18n.t('menu.membersite.create'),
        icon: createFunnel,
        isEdit: ROLE_MENU_ITEM.EDIT,
        pathChildren: [],
      },
      {
        path: MEMBERSITE.GROUP_COURSE,
        title: i18n.t('menu.membersite.list'),
        icon: listFunnel,
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        pathChildren: [
          {
            path: MEMBERSITE.LIST_COURSE,
          },
          {
            path: MEMBERSITE.RECYCLE_COURSE,
          },
          {
            path: MEMBERSITE.SETTING_COURSE,
          },
          {
            path: MEMBERSITE.SELECT,
          },
          {
            path: MEMBERSITE.SETTING,
          },
          {
            path: MEMBERSITE.LIST_USER,
          },
          {
            path: MEMBERSITE.COURSE_IMPORT_CSV,
          },
          {
            path: MEMBERSITE.COURSE_CREATE_BY_NAME,
          },
        ],
      },
      {
        title: i18n.t('menu.membersite.template'),
        path: MEMBERSITE.LIST_MEMBERSITE_TEMPLATE_PARAMS_DEFAULT,
        icon: listFunnel,
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        pathChildren: [
          {
            path: MEMBERSITE.MEMBERSITE_TEMPLATE,
          },
          { path: MEMBERSITE.SHARED_TEMPLATE },
          {
            path: MEMBERSITE.LIST_MEMBERSITE_TEMPLATE,
          },
        ],
      },
    ],
  },
  {
    path: MENU_TILES.AFFILIATE,
    title: i18n.t('menu.affiliate'),
    icon: affiliateIcon,
    children: [
      {
        icon: affiliateIcon,
        path: AFFILIATE.SETTING,
        title: i18n.t('menu.affiliate.setting'),
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
      },
      {
        icon: affiliateIcon,
        path: AFFILIATE.PARTNER,
        title: i18n.t('menu.affiliate.partner'),
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        pathChildren: [
          {
            path: AFFILIATE.PARTNER_DETAIL,
          },
        ],
      },
      {
        icon: affiliateIcon,
        path: AFFILIATE.SUBSCRIBER,
        title: i18n.t('menu.affiliate.subscriber'),
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
      },
      {
        icon: affiliateIcon,
        path: AFFILIATE.BONUS,
        title: i18n.t('menu.affiliate.bonus'),
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
      },
    ],
  },
  {
    path: MENU_TILES.REPORT,
    title: i18n.t('menu.sale'),
    icon: sale,
    children: [
      {
        icon: synthesisReports,
        path: REPORT_PATH.SYNTHESIS,
        title: i18n.t('menu.sale.synthesisReport'),
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
      },
      {
        icon: reportIcon,
        path: REPORT_PATH.SELECT,
        title: i18n.t('menu.sale.reportByProduct'),
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        pathChildren: [
          {
            path: REPORT_PATH.DETAIL,
          },
        ],
      },
    ],
  },
  {
    path: MENU_TILES.MY_PAGE,
    title: i18n.t('menu.page'),
    icon: myPage,
    children: [
      {
        icon: userIcon,
        path: MY_PAGE.PROFILE,
        title: i18n.t('menu.page.profileSetting'),
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        pathChildren: [
          {
            path: MY_PAGE.PROFILE_EDIT_INFO,
          },
          {
            path: MY_PAGE.PROFILE_EDIT_PASSWORD,
          },
        ],
      },
      {
        icon: accountSetting,
        path: MY_PAGE.MY_ACCOUNT_SETTING_DETAIL,
        isEdit: ROLE_MENU_ITEM.EDIT,
        title: i18n.t('menu.page.myAccountSetting'),
        pathChildren: [
          {
            path: MY_PAGE.ACCOUNT_SETTING_SUB_USER,
          },
          {
            path: LINK_SETTING_ACCOUNT.PAYMENT_INFORMATION,
          },
          {
            path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_CREATE,
          },
          {
            path: LINK_SETTING_ACCOUNT.EMAIL_INTEGRATIONS_LIST,
          },
          {
            path: LINK_SETTING_ACCOUNT.EMAIL_INTEGRATIONS_TRANSITION_ONLY,
          },
          {
            path: LINK_SETTING_ACCOUNT.EMAIL_INTEGRATIONS_TRANSITION_AND_MARKETING,
          },
          {
            path: LINK_SETTING_ACCOUNT.EMAIL_INTEGRATIONS_SELECT,
          },
          {
            path: LINK_SETTING_ACCOUNT.EDIT_EMAIL_INTEGRATIONS_TRANSITION_AND_MARKETING,
          },
          {
            path: LINK_SETTING_ACCOUNT.DETAIL_DOMAIN,
          },
          {
            path: LINK_SETTING_ACCOUNT.LIST_DOMAIN,
          },
          {
            path: LINK_SETTING_ACCOUNT.CREATE_DOMAIN,
          },
          {
            path: MY_PAGE.MY_ACCOUNT_SETTING_DETAIL,
          },
          {
            path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_GMO,
          },
          {
            path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_PAYPAL,
          },
          {
            path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_UNIVAPAY,
          },
          {
            path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_STRIPE,
          },
          {
            path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_CREATE_TYPE,
          },
          {
            path: MY_PAGE.MANAGE_PAYMENT_GATEWAY,
          },
          {
            path: LINK_SETTING_ACCOUNT.API_INTEGRATIONS,
          },
          {
            path: LINK_SETTING_ACCOUNT.ADD_INTEGRATION,
          },
          {
            path: LINK_SETTING_ACCOUNT.UPDATE_CONVERTKIT,
          },
          {
            path: LINK_SETTING_ACCOUNT.UPDATE_FACEBOOK,
          },
          {
            path: LINK_SETTING_ACCOUNT.UPDATE_MAILCHIMP,
          },
        ],
      },
      {
        icon: logoutIcon,
        path: MY_PAGE.LOGOUT,
        isEdit: ROLE_MENU_ITEM.ONLY_VIEW,
        title: i18n.t('menu.page.logout'),
      },
    ],
  },
];
