import {
  AFFILIATE,
  CALENDAR,
  FUNNEL,
  LINK_SETTING_ACCOUNT,
  LINK_USER,
  MAIL_PATH,
  MEMBERSITE,
  MENU_TILES,
  MY_PAGE,
  PRODUCT,
  REMIND_MAIL,
  REPORT_PATH,
} from '../constants/menus';

interface pathName {
  key: string;
  pathChildren: any[];
}

export const PERMISSIONS = {
  FUNNEL: 1,
  PRODUCT: 2,
  MAIL: 3,
  USER: 4,
  REPORT: 5,
  MY_PAGE: 6,
};

export const ROLE_OF_PAGE = {
  ONLY_VIEW: 1,
  EDIT: 2,
};

export const pathNameAll: pathName[] = [
  {
    key: MENU_TILES.FUNNEL,
    pathChildren: [
      {
        path: FUNNEL.GROUP,
      },
      {
        path: FUNNEL.CREATE,
      },
      {
        path: FUNNEL.LIST,
      },
      {
        path: FUNNEL.RECYCLE,
      },
      {
        path: FUNNEL.SELECT,
      },
      {
        path: FUNNEL.SETTING,
      },
      {
        path: '/funnel-detail/:uuidFunnel/setting',
      },
      {
        path: FUNNEL.LIST_TEMPLATE,
      },
      {
        path: FUNNEL.TEMPLATE,
      },
      {
        path: FUNNEL.LIST_BLOCK_TEMPLATE,
      },
      {
        path: FUNNEL.BLOCK_TEMPLATE,
      },
    ],
  },
  {
    key: MENU_TILES.CALENDAR,
    pathChildren: [
      {
        path: CALENDAR.CREATE,
      },
      {
        path: CALENDAR.LIST,
      },
      {
        path: CALENDAR.SETTING_BASIC,
      },
      {
        path: CALENDAR.SETTING_SCHEDULE,
      },
      {
        path: FUNNEL.CREATE_TIMER,
      },
      {
        path: LINK_USER.WHITELIST_USER,
      },
      {
        path: FUNNEL.EDIT_TIMER,
      },
      {
        path: FUNNEL.LIST_TIMER,
      },
    ],
  },
  {
    key: MENU_TILES.CALENDAR,
    pathChildren: [
      {
        path: CALENDAR.CREATE,
      },
      {
        path: CALENDAR.LIST,
      },
      {
        path: CALENDAR.SETTING_BASIC,
      },
      {
        path: CALENDAR.SETTING_SCHEDULE,
      },
      {
        path: FUNNEL.CREATE_TIMER,
      },
      {
        path: LINK_USER.WHITELIST_USER,
      },
      {
        path: FUNNEL.EDIT_TIMER,
      },
      {
        path: FUNNEL.LIST_TIMER,
      },
    ],
  },
  {
    key: MENU_TILES.MEMBERSITE,
    pathChildren: [
      {
        path: MEMBERSITE.CREATE_COURSE,
      },
      {
        path: MEMBERSITE.GROUP_COURSE,
      },
      {
        path: MEMBERSITE.LIST_COURSE,
      },
      {
        path: MEMBERSITE.RECYCLE_COURSE,
      },
      {
        path: MEMBERSITE.COURSE_EDIT,
      },
      {
        path: MEMBERSITE.COURSE_SETTING,
      },
      {
        path: MEMBERSITE.LIST_MEMBERSITE_TEMPLATE,
      },
      {
        path: MEMBERSITE.MEMBERSITE_TEMPLATE,
      },
      {
        path: MEMBERSITE.LIST_USER,
      },
      {
        path: MEMBERSITE.COURSE_IMPORT_CSV,
      },
      {
        path: MEMBERSITE.COURSE_CREATE_BY_NAME,
      },
    ],
  },
  {
    key: MENU_TILES.REGISTER_PRODUCT,
    pathChildren: [
      {
        path: PRODUCT.GROUP,
      },
      {
        path: PRODUCT.REGISTER,
      },
      {
        path: PRODUCT.UPDATE,
      },
    ],
  },
  {
    key: MENU_TILES.MAIL,
    pathChildren: [
      {
        path: MAIL_PATH.CREATE_STEP_MAIL,
      },
      {
        path: MAIL_PATH.MAIL_LIST,
      },
      {
        path: MAIL_PATH.LIST_STEP_MAIL,
      },
      {
        path: MAIL_PATH.DETAIL_STEP_MAIL,
      },
      {
        path: MAIL_PATH.STEP_MAIL_VIEW,
      },
      {
        path: MAIL_PATH.MAIL_LIST_BY_NAME,
      },
      {
        path: MAIL_PATH.MAIL_LIST_CREATE_BY_NAME,
      },
      {
        path: MAIL_PATH.MAIL_LIST_CREATE,
      },
      {
        path: MAIL_PATH.MAIL_LIST_IMPORT_CSV,
      },
      {
        path: MAIL_PATH.DETAIL,
      },
      {
        path: MAIL_PATH.SENT_MAIL_LIST,
      },
      {
        path: MAIL_PATH.SENT_MAIL_DETAIL,
      },
      {
        path: MAIL_PATH.BULK_MAIL_CREATE,
      },
      {
        path: MAIL_PATH.BULK_MAIL_COPY,
      },
      {
        path: MAIL_PATH.BULK_MAIL_DETAIL,
      },
      {
        path: MAIL_PATH.STEP_MAIL_UPDATE,
      },
      {
        path: MAIL_PATH.MAIL_FIXED_PHRASE,
      },
      {
        path: MAIL_PATH.MAIL_FIXED_PHRASE_LIST,
      },
      {
        path: REMIND_MAIL.CREATE_REMIND_MAIL,
      },
      {
        path: REMIND_MAIL.REMIND_MAIL_UPDATE,
      },
      {
        path: REMIND_MAIL.DETAIL_REMIND_MAIL,
      },
      {
        path: REMIND_MAIL.EDIT_MAIL,
      },
    ],
  },
  {
    key: MENU_TILES.USER,
    pathChildren: [
      {
        path: LINK_USER.LIST_USER,
      },
      {
        path: LINK_USER.BLACKLIST_USER,
      },
      {
        path: LINK_USER.USER_DETAIL,
      },
      {
        path: LINK_USER.EDIT_USER,
      },
      {
        path: LINK_USER.MAIL_LIST,
      },
      {
        path: LINK_USER.STATISTICS,
      },
      {
        path: LINK_USER.CREATE_TAG,
      },
      {
        path: LINK_USER.EDIT_TAG,
      },
      {
        path: LINK_USER.LIST_TAG,
      },
      {
        path: LINK_USER.LIST_TAG_USER,
      },
    ],
  },
  {
    key: MENU_TILES.AFFILIATE,
    pathChildren: [
      {
        path: AFFILIATE.SETTING,
      },
      {
        path: AFFILIATE.PARTNER,
      },
      {
        path: AFFILIATE.PARTNER_DETAIL,
      },
      {
        path: AFFILIATE.SUBSCRIBER,
      },
      {
        path: AFFILIATE.BONUS,
      },
    ],
  },
  {
    key: MENU_TILES.REPORT,
    pathChildren: [
      {
        path: REPORT_PATH.SELECT,
      },
      {
        path: REPORT_PATH.DETAIL,
      },
      {
        path: REPORT_PATH.SYNTHESIS,
      },
    ],
  },
  {
    key: MENU_TILES.MY_PAGE,
    pathChildren: [
      {
        path: LINK_SETTING_ACCOUNT.EMAIL_INTEGRATIONS_LIST,
      },
      {
        path: MY_PAGE.MANAGE_PAYMENT_GATEWAY,
      },
      {
        path: LINK_SETTING_ACCOUNT.EMAIL_INTEGRATIONS_SELECT,
      },
      {
        path: LINK_SETTING_ACCOUNT.EMAIL_INTEGRATIONS_TRANSITION_ONLY,
      },
      {
        path: LINK_SETTING_ACCOUNT.EMAIL_INTEGRATIONS_TRANSITION_AND_MARKETING,
      },
      {
        path: LINK_SETTING_ACCOUNT.EDIT_EMAIL_INTEGRATIONS_TRANSITION_AND_MARKETING,
      },
      {
        path: LINK_SETTING_ACCOUNT.LIST_DOMAIN,
      },
      {
        path: LINK_SETTING_ACCOUNT.DETAIL_DOMAIN,
      },
      {
        path: LINK_SETTING_ACCOUNT.PAYMENT_INFORMATION,
      },
      {
        path: LINK_SETTING_ACCOUNT.CREATE_DOMAIN,
      },
      {
        path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_CREATE_TYPE,
      },
      {
        path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_GMO,
      },
      {
        path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_PAYPAL,
      },
      {
        path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_UNIVAPAY,
      },
      {
        path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_STRIPE,
      },
      {
        path: MY_PAGE.MY_ACCOUNT_SETTING_DETAIL,
      },
    ],
  },
];
