// include: categories page template of funnel, categories page template of membersite
export interface ISubCategory {
  id: number;
  name: string;
  description: string;
}

export interface ICategoriesPageTemplate {
  id: number;
  name: string;
  description: string;
  type: number;
  subcategories: ISubCategory[];
}

export interface ICurrentCategoryAndSubCategory {
  id: number;
  name: string;
  description?: string;
  type?: number;
  subcategory: ISubCategory;
}

export interface InitialCommonManagement {
  categoriesPageTemplate: ICategoriesPageTemplate[];
  currentCategoryAndSubCategory: ICurrentCategoryAndSubCategory;
}

export const initialCommonManagement: InitialCommonManagement = {
  categoriesPageTemplate: [],
  currentCategoryAndSubCategory: {
    id: 0,
    name: '',
    description: '',
    type: 0,
    subcategory: {
      id: 0,
      name: '',
      description: '',
    },
  },
};
// -------------------------------------------------------------
