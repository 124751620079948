import { IPayLoadGroupCourseList } from '../../actions/membersite/group';

// summary course
interface ISummaryCourse {
  trashed: number;
  courses: number;
  groupTrash: number;
  groups: number;
}

interface IStateSummaryCourse {
  data: ISummaryCourse;
  error: string;
}

const initialSummaryCourse: IStateSummaryCourse = {
  data: {
    trashed: 0,
    courses: 0,
    groupTrash: 0,
    groups: 0,
  },
  error: '',
};

export interface ICourse {
  uuid: string;
  flag_favorite: number;
  flag_publish: number;
  name: string;
  path: string;
  total_section: number;
  total_lesson: number;
  updated_at: string;
}

interface IGroupCourse {
  name: string;
  uuid: string;
  courses: ICourse[];
}

export interface IMeta {
  current_page: number;
  last_page: number;
  per_page: number;
  total: number;
}

export interface IStateGroupCourses {
  meta: IMeta;
  groups: IGroupCourse[];
  payload: IPayLoadGroupCourseList;
  domain: string;
}

export const initialGroupCourses: IStateGroupCourses = {
  meta: {
    current_page: 1,
    last_page: 1,
    per_page: 1,
    total: 1,
  },
  groups: [],
  payload: {
    page: 1,
    perPage: 20,
    sort_type: 'desc',
    sort_name: '',
    sort_group: '',
    group_uuids: '',
    flag_favorite: '',
  },
  domain: '',
};

export type { ISummaryCourse, IStateSummaryCourse };

export { initialSummaryCourse };
