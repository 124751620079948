import i18next from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';

import * as affiliateServices from '../../../Clients/affiliate';
import {
  downloadDataBonusRanking,
  fetchDataBonusApi,
} from '../../../Clients/affiliate';
import { EStatusHttp } from '../../../Clients/interfaces';
import { downloadCSVData } from '../../../containers/MailList/UploadMailList/page/helper';
import { handleMessage } from '../../../utilities/common.utilities';
import { getTimeTitleDownloadCSV } from '../../../utilities/csv';
import {
  IActionFetchDataBonus,
  ParamsUpdateAffiliateSetting,
} from '../../actions/affiliate';
import * as Types from '../../actions/affiliate/types';
import * as ActionTypes from '../../actionTypes/loadingActionTypes';
import {
  ParamsDeleteAffiliateList,
  ParamsFetchAffiliateList,
  ParamsSubmitAffiliateList,
} from '../../reducers/affiliate/interface';

interface APIResponseType {
  data: any;
  message: string;
  status: number;
}

// Affiliate setting n001
function* fetchAffiliateSetting() {
  yield put({ type: ActionTypes.SHOW_LOADING });

  try {
    const response: APIResponseType = yield call(() =>
      affiliateServices.fetchAffiliateSettingApi()
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.FETCH_AFFILIATE_SETTING_SUCCESS,
        detailSetting: response.data.data,
      });
    } else {
      yield put({
        type: Types.FETCH_AFFILIATE_SETTING_SUCCESS,
        detailSetting: {},
      });
      handleMessage(response);
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* submitAffiliateSetting(action: {
  type: string;
  params: ParamsUpdateAffiliateSetting;
  onSuccess: () => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });

  try {
    const response: APIResponseType = yield call(() =>
      affiliateServices.submitAffiliateSettingApi(action.params)
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* submitAffSettingUpdateRank(action: {
  type: string;
  params: number;
  onSuccess: () => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });

  try {
    const response: APIResponseType = yield call(() =>
      affiliateServices.submitSettingUpdateRankApi(action.params)
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

// Affiliate partner n002
function* fetchPartnerList(action: {
  type: string;
  params: ParamsFetchAffiliateList;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(() =>
      affiliateServices.getPartnerListApi(action.params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.FETCH_PARTNER_LIST_SUCCESS,
        affiliateList: response.data.data,
      });
    } else {
      yield put({
        type: Types.FETCH_PARTNER_LIST_SUCCESS,
        affiliateList: {},
      });
      handleMessage(response);
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* submitPartnerList(action: {
  type: string;
  params: ParamsSubmitAffiliateList;
  onSuccess: (params: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });

  try {
    const response: APIResponseType = yield call(() =>
      affiliateServices.updatePartnerApi(action.params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      const data = response.data.data;
      if (!data.partner_errors.length) {
        handleMessage(response);
      } else {
        yield put({
          type: Types.SUBMIT_PARTNER_LIST_SUCCESS,
          dataUpdateFailure: data.partner_errors,
        });
      }
      action.onSuccess(data.partner_errors);
    } else {
      handleMessage(response);
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* deletePartnerList(action: {
  type: string;
  params: ParamsDeleteAffiliateList;
  onSuccess: () => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });

  try {
    const response: APIResponseType = yield call(() =>
      affiliateServices.deletePartnerApi(action.params)
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* redirectAffiliatePartnerSite(action: {
  type: string;
  params: string;
  onSuccess: (data: any) => void;
  onError: (err: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });

  try {
    const response: APIResponseType = yield call(() =>
      affiliateServices.redirectAffiliatePartnerSiteApi(action.params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response?.data?.data);
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

// Detail Partner n002-1
function* fetchDetailPartnerList(action: { type: string; params: string }) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(() =>
      affiliateServices.getDetailPartnerApi(action.params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.FETCH_DETAIL_PARTNER_LIST_SUCCESS,
        detailPartnerList: response.data.data,
      });
    } else {
      yield put({
        type: Types.FETCH_DETAIL_PARTNER_LIST_SUCCESS,
        detailPartnerList: {},
      });
      handleMessage(response);
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

/* -------------------------- M006 -- screen affiliate bonus ----------------------- */
interface IFetchDataBonusSaga extends IActionFetchDataBonus {
  type: string;
}

function* fetchDataBonusSaga(action: IFetchDataBonusSaga) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      fetchDataBonusApi,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.FETCH_DATA_BONUS_SUCCESS,
        data: {
          data: response.data.data.data,
          information: {
            ...action.params,
            ...response.data.data.meta,
          },
        },
      });
    } else {
      handleMessage(response);
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

// Affiliate subscriber n003
function* fetchSubscriberList(action: { type: string; params: any }) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(() =>
      affiliateServices.getAffiliateSubscribersApi(action.params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.FETCH_SUBSCRIBER_LIST_SUCCESS,
        subscriberList: response.data.data,
      });
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* updateSubscriber(action: {
  type: string;
  params: any;
  onSuccess: (data: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(() =>
      affiliateServices.updateAffiliateSubscriberApi(action.params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      const data = response.data.data;
      if (!data.purchase_errors.length) {
        handleMessage(response);
      }
      action.onSuccess(data.purchase_errors);
    } else {
      handleMessage(response);
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* downloadDataSubscriberPurcharse(action: {
  type: string;
  params: any;
  onSuccess: (data: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(() =>
      affiliateServices.downloadDataSubscriberCSVApi(action.params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data);
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* downloadDataBonusRankingSaga(action: IFetchDataBonusSaga) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      downloadDataBonusRanking,
      action.params
    );

    if (response.status === EStatusHttp.HTTP_OK) {
      downloadCSVData(
        response.data,
        `${i18next.t('affiliate.bonus.filename.ranking', {
          time: getTimeTitleDownloadCSV(
            action.params.start_date,
            action.params.end_date
          ),
        })}.txt`
      );
    } else {
      handleMessage(response);
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* downloadDataBonusCSVSaga(action: IFetchDataBonusSaga) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      affiliateServices.downloadDataBonusCSV,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      downloadCSVData(
        response.data,
        `${i18next.t('affiliate.bonus.filename.CSV', {
          time: getTimeTitleDownloadCSV(
            action.params.start_date,
            action.params.end_date
          ),
        })}.csv`
      );
    } else {
      handleMessage(response);
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* downloadIdentify(action: {
  type: string;
  params: string;
  onSuccess: (data: any) => void;
  onError: (err: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });

  try {
    const response: APIResponseType = yield call(() =>
      affiliateServices.downloadIdentifyApi(action.params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response?.data);
    }
  } catch (error: any) {
    if (error?.response) handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

export default function* affiliateManagement() {
  yield takeLatest(Types.FETCH_AFFILIATE_SETTING, fetchAffiliateSetting);
  yield takeLatest(Types.SUBMIT_AFFILIATE_SETTING, submitAffiliateSetting);
  yield takeLatest(
    Types.SUBMIT_AFF_SETTING_UPDATE_RANK,
    submitAffSettingUpdateRank
  );
  yield takeLatest(Types.FETCH_PARTNER_LIST, fetchPartnerList);
  yield takeLatest(Types.SUBMIT_PARTNER_LIST, submitPartnerList);
  yield takeLatest(Types.DELETE_PARTNER_LIST, deletePartnerList);
  yield takeLatest(Types.FETCH_DETAIL_PARTNER_LIST, fetchDetailPartnerList);
  yield takeLatest(
    Types.REDIRECT_AFFILIATE_PARTNER_SITE,
    redirectAffiliatePartnerSite
  );
  /* -------------------------- M006 -- screen affiliate bonus ----------------------- */
  yield takeLatest(Types.FETCH_DATA_BONUS, fetchDataBonusSaga);
  yield takeLatest(Types.DOWNLOAD_RANKING, downloadDataBonusRankingSaga);
  yield takeLatest(Types.DOWNLOAD_CSV, downloadDataBonusCSVSaga);
  yield takeLatest(Types.DOWNLOAD_IDENTIFY, downloadIdentify);

  yield takeLatest(Types.FETCH_SUBSCRIBER_LIST, fetchSubscriberList);
  yield takeLatest(Types.UPDATE_SUBSCRIBER, updateSubscriber);
  yield takeLatest(
    Types.DOWNLOAD_SUBSCRIBER_PURCHARSE_CSV,
    downloadDataSubscriberPurcharse
  );
}
