import { call, cancel, fork, take, takeLatest } from 'redux-saga/effects';
import { EStatusHttp } from '../../../Clients/interfaces';
import * as Types from '../../actions/reports/types';
import * as reportService from './../../../Clients/reports';
import {
  IFunnelReportFilters,
  ISaleReportFunnels,
} from './../../../Clients/reports';
import { funnelServices } from '../../../Clients/funnels';
import { handleMessage } from '../../../utilities/common.utilities';

interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
}

function* getRangeDateTimeReports(action: {
  type: string;
  values: any;
  onSuccess: (result: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      reportService.getRangeDateTimeReports,
      action.values
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      const rangeFromDate = Date.parse(
        response.data.data.funnel_first_time.slice(0, 10)
      );
      action.onSuccess(rangeFromDate);
    }
  } catch (e: any) {}
}

export function* watchGetRangeDateTimeReports() {
  let task: any;
  yield takeLatest(
    Types.GET_RANGE_DATE_TIME_REPORT,
    function* (action: {
      type: string;
      values: any;
      onSuccess: (result: any) => void;
    }) {
      if (task) {
        yield cancel(task);
      }
      task = yield fork(getRangeDateTimeReports, action);
    }
  );

  yield take(Types.COMPONENT_UNMOUNTED);
  if (task) {
    yield cancel(task);
  }
}

//funnel report

function* reportFunnelByFilters(action: {
  type: string;
  params: IFunnelReportFilters;
  onSuccess: (result: any) => void;
  onError: () => void;
}) {
  try {
    const response: APIResponseType = yield call(
      reportService.GetReportFunnelByFilters,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data.data);
    }
  } catch (e: any) {
    action.onError();
  }
}

export function* watchReportFunnelByFilters() {
  let task: any;
  yield takeLatest(
    Types.REPORT_FUNNEL_BY_FILTERS,
    function* (action: {
      type: string;
      params: IFunnelReportFilters;
      onSuccess: (result: any) => void;
      onError: () => void;
    }) {
      if (task) {
        yield cancel(task);
      }
      task = yield fork(reportFunnelByFilters, action);
    }
  );

  yield take(Types.COMPONENT_UNMOUNTED);
  if (task) {
    yield cancel(task);
  }
}

// synthesis reports

function* getSynthesisReports(action: {
  type: string;
  params: ISaleReportFunnels;
  onSuccess: (result: any) => void;
  onError: () => void;
}) {
  try {
    const response: APIResponseType = yield call(
      reportService.getSynthesisReports,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data.data);
    }
  } catch (e: any) {
    action.onError();
  }
}

export function* watchGetSynthesisReports() {
  let task: any;
  yield takeLatest(
    Types.GET_SYNTHESIS_REPORT,
    function* (action: {
      type: string;
      params: ISaleReportFunnels;
      onSuccess: (result: any) => void;
      onError: () => void;
    }) {
      if (task) {
        yield cancel(task);
      }
      task = yield fork(getSynthesisReports, action);
    }
  );

  yield take(Types.COMPONENT_UNMOUNTED);
  if (task) {
    yield cancel(task);
  }
}

function* getListFunnelStep(action: {
  type: string;
  uuid: string;
  filterDeleted: number;
  onSuccess: (result: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      funnelServices.fetchFunnelDetail,
      action.uuid,
      action.filterDeleted
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data.data.steps);
    }
  } catch (e: any) {
    handleMessage(e.response);
  }
}

export function* watchGetListFunnelStep() {
  let task: any;
  yield takeLatest(
    Types.GET_LIST_FUNNEL_STEP,
    function* (action: {
      type: string;
      uuid: string;
      filterDeleted: number;
      onSuccess: (result: any) => void;
    }) {
      if (task) {
        yield cancel(task);
      }
      task = yield fork(getListFunnelStep, action);
    }
  );

  yield take(Types.COMPONENT_UNMOUNTED);
  if (task) {
    yield cancel(task);
  }
}

export default function* reportsSaga() {
  yield fork(watchReportFunnelByFilters);
  yield fork(watchGetRangeDateTimeReports);
  yield fork(watchGetSynthesisReports);
  yield fork(watchGetListFunnelStep);
}
