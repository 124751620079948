import { toast } from 'react-toastify';

export const handleErrorMessage = (error: any) => {
  switch (typeof error) {
    case 'string':
      toast.error(error);
      break;
    case 'object':
      const firstValue: any = Object.values(error)[0];
      toast.error(firstValue[0]);
      break;
    default:
      break;
  }
};
