import { useEffect } from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import {
  ENV_DEVELOPMENT,
  getChildDomainSite,
  ORIGIN_HOST,
  redirectStateWhenAuthenSuccess,
  redirectWhenAuthenSuccess,
} from '../config/common.config';
import { getAuthenticatorCookies } from '../utilities/cookies';
import { pathNameAll } from '../constants/routers';
import { checkRoleCurrentUser } from '../helper/roleUser';
import { ROUTER } from './router';
import { AUTHEN_PAGE, LINK_USER, MENU_TILES } from '../constants/menus';
import useCheckRole from '../hooks/useCheckRole';

interface PrivateRouteProps extends RouteProps {
  component: any;
  state?: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const authMkt = getAuthenticatorCookies();
  const location = useLocation();
  const { typePlan, ETypePlanFunecy, role } = useCheckRole();

  const checkPermissionRouter = () => {
    /* ---------- check router affiliate with plan basic ------------- */
    const pathAffiliate = pathNameAll.find(
      (item) => item.key === MENU_TILES.AFFILIATE
    );
    if (
      (typePlan === ETypePlanFunecy.BasicMonth ||
        typePlan === ETypePlanFunecy.BasicYear) &&
      pathAffiliate?.pathChildren.find((item) => item.path === rest.path)
    ) {
      return true;
    }

    if (!role?.is_owner && !role?.is_full_role && role?.role.length === 0) {
      return false;
    }
    if (role?.is_owner || role?.is_full_role) {
      return false;
    }
    if (rest.path !== AUTHEN_PAGE.DASHDOARD) {
      let isPermission = false;
      const roleUserByPage: any[] = [];
      const currentPage: any[] = [];
      pathNameAll.forEach((path: any) => {
        const isPath = path.pathChildren.find(
          (item: any) => item.path === rest.path
        );
        const roleUser = role?.role?.find(
          (r: any) => checkRoleCurrentUser(r) === path.key
        );
        if (isPath) {
          if (roleUser) {
            const currentRouter = ROUTER.find(
              (item) => item.path === rest.path
            );
            roleUserByPage.push(roleUser);
            currentPage.push(currentRouter);
          } else {
            isPermission = true;
          }
        }
      });
      if (
        currentPage[0]?.role === 2 &&
        roleUserByPage[0]?.policy_id === 1 &&
        rest.path !== LINK_USER.WHITELIST_USER
      ) {
        isPermission = true;
      }
      return isPermission;
    }
    return false;
  };

  const handleRedirectSubdomain = () => {
    const SUB_DOMAIN = getChildDomainSite(authMkt?.company?.sub_domain);
    if (
      !!authMkt?.company &&
      process.env.APP_ENV !== ENV_DEVELOPMENT &&
      ORIGIN_HOST !== SUB_DOMAIN
    ) {
      if (location.pathname) {
        redirectStateWhenAuthenSuccess(SUB_DOMAIN, location.pathname);
      } else {
        redirectWhenAuthenSuccess(SUB_DOMAIN);
      }
    }
  };

  useEffect(() => {
    handleRedirectSubdomain();
  }, []);

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!!authMkt?.company) {
          if (checkPermissionRouter()) {
            return (
              <Redirect
                to={{
                  pathname: '/no-right',
                  state: { from: routeProps.location },
                }}
              />
            );
          }
          return <Component {...routeProps} />;
        }
        return (
          <Redirect
            to={{
              pathname: AUTHEN_PAGE.SIGN_IN,
              state: { from: routeProps.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
