import Cookies from 'js-cookie';
import { ENV_DEVELOPMENT } from '../config/common.config';

export const FUNNEL_STEP_SUBCATEGORY_ID = {
  MemberShip: {
    Access: 26,
    Area: 27,
  },
};

const getPageTypeQueryParam = (funnelStepSubCategoryId: number) => {
  switch (funnelStepSubCategoryId) {
    case FUNNEL_STEP_SUBCATEGORY_ID.MemberShip.Access:
      return '&type=member_access';
    case FUNNEL_STEP_SUBCATEGORY_ID.MemberShip.Area:
      return '&type=member_area';
    default:
      return '';
  }
};

interface OpenEditPage {
  uuid: string;
  funnel_step_subcategory_id: number;
  step_uuid?: string;
  isTemplate?: boolean;
  isNotRedirect?: boolean;
  isMembersite?: boolean;
}

const handleOpenEditPage = (data: OpenEditPage) => {
  const paramsIsTemplate = data.isTemplate ? '&is_template=1' : '';
  const stepUuid = data.step_uuid ? `&step_uuid=${data.step_uuid}` : '';
  const cookie = Cookies.get('mkt-authen') || '';
  const authenCookie = JSON.parse(cookie);
  const env = process.env.APP_ENV;
  const editPageUrlBase =
    env === ENV_DEVELOPMENT
      ? `https://${authenCookie?.company?.sub_domain}.app.dev.mkt.hblab.dev`
      : window.location.origin;

  const urlTemplate = `${editPageUrlBase}/page-editor/editor.html?uuid=${
    data.uuid
  }${stepUuid}${paramsIsTemplate}&url_page_comeback=${encodeURIComponent(
    window.location.href
  )}&funnel_step_subcategory_id=${
    data.funnel_step_subcategory_id
  }${getPageTypeQueryParam(data.funnel_step_subcategory_id)}&membersite=${
    data.isMembersite
  }`;

  if (data.isNotRedirect) {
    return urlTemplate;
  }

  window.location.href = urlTemplate;
};

const fetchUrlTemplate = (landingFileUrl: string) => {
  if (!landingFileUrl.trim()) return '';

  const env = process.env.APP_ENV;
  const currentTime = new Date().getTime();
  if (env === ENV_DEVELOPMENT) {
    return `https://dev.mkt.hblab.dev/${landingFileUrl}?timestamp=${currentTime}`;
  }
  return `${window.location.origin}/${landingFileUrl}?timestamp=${currentTime}`;
};

export { handleOpenEditPage, fetchUrlTemplate };
