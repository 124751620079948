import React from 'react';

import { ROLE_OF_PAGE } from '../constants/routers';
import {
  LINK_SETTING_ACCOUNT,
  LINK_USER,
  MAIL,
  MY_PAGE,
  AUTHEN_PAGE,
  FUNNEL,
  MEMBERSITE,
  REPORT_PATH,
  PRODUCT,
  MAIL_PATH,
  HELPER_PAGE,
  AFFILIATE,
  MAINTENANCE,
  REMIND_MAIL,
  CALENDAR,
} from '../constants/menus';

// comment hide router purchase domains
import PageListMembersiteTemplates from '../containers/Membersite/ManageTemplates/PageList';
import MembersiteTemplateDetailContainer from '../containers/Membersite/ManageTemplates/Details';
import SharedMembersiteTemplate from '../containers/Membersite/ManageTemplates/SharedTemplates';

const RegisterAccount = React.lazy(
  () => import('../containers/Authen/RegisterAccount')
);

const VerifyAccountSuccess = React.lazy(
  () => import('../components/pages/Authen/VerifyAccountSuccess')
);

const CreateNewPassword = React.lazy(
  () => import('../components/pages/Authen/CreateNewPassword')
);

const SelectedTemplate = React.lazy(
  () => import('../containers/Funnel/Detail')
);

const ProductRegisterNew = React.lazy(
  () =>
    import(
      '../containers/ProductRegister/NewProductRegister/NewProductRegister'
    )
);

const UnsubscribeLinkForStepMail = React.lazy(
  () =>
    import(
      '../containers/Mail/pages/EmailAlternativeCharacterVerify/UnsubscribeLinkForStepMail'
    )
);

const ChangeRegisteredEmail = React.lazy(
  () =>
    import(
      '../containers/Mail/pages/EmailAlternativeCharacterVerify/ChangeRegisteredEmail'
    )
);

const CancelRecurringPayment = React.lazy(
  () =>
    import(
      '../containers/Mail/pages/EmailAlternativeCharacterVerify/CancelRecurringPayment'
    )
);

const ChangeCreditCard = React.lazy(
  () =>
    import(
      '../containers/Mail/pages/EmailAlternativeCharacterVerify/ChangeCreditCard'
    )
);

const PaymentOrder = React.lazy(
  () =>
    import(
      '../containers/Mail/pages/EmailAlternativeCharacterVerify/PaymentOrder'
    )
);

const ScheduleCancel = React.lazy(
  () =>
    import(
      '../containers/Mail/pages/EmailAlternativeCharacterVerify/ScheduleCancel'
    )
);

const ScheduleChange = React.lazy(
  () =>
    import(
      '../containers/Mail/pages/EmailAlternativeCharacterVerify/ScheduleChange'
    )
);

const CreateStepMailContainer = React.lazy(
  () => import('../containers/MailList/CreateStepMail')
);

const StepMailList = React.lazy(
  () => import('../containers/StepMail/Listing/Group/StepMailGroup')
);

const EditStepMailContainer = React.lazy(
  () => import('../containers/StepMail/EditStepMailContainer')
);

const SharedFunnel = React.lazy(
  () => import('../containers/Funnel/SharedFunnel/SharedFunnel')
);

const MailList = React.lazy(
  () => import('../containers/MailList/MailList/MailList')
);

const UpdateMailList = React.lazy(
  () => import('../containers/MailList/UpdateMailList/UpdateMailList')
);

const ListProductRegister = React.lazy(
  () =>
    import(
      '../containers/ProductRegister/ListProductRegister/ListProductRegister'
    )
);
const ManagePaymentGateways = React.lazy(
  () => import('../containers/ManagePaymentGateways/Group')
);

const CreateMailList = React.lazy(
  () => import('../containers/MailList/CreateMailList/CreateMailList')
);

const CreatePersonMail = React.lazy(
  () => import('../containers/MailList/CreatePersonMail/CreatePersonMail')
);

const AddPaymentGateway = React.lazy(
  () => import('../containers/ManagePaymentGateways/Add/AddPaymentGateway')
);

const EditMailContainer = React.lazy(
  () => import('../containers/StepMail/EditMailContainer')
);

const ListSentMailBulk = React.lazy(
  () => import('../containers/SentEmailInBulk/List/ListSentMailBulk')
);

const StepMailDetailContainer = React.lazy(
  () => import('../containers/StepMail/StepMailDetailContainer')
);

const CreateBulkMailContainer = React.lazy(
  () => import('../containers/SentEmailInBulk/CreateBulkMailContainer')
);

const CopyBulkMailContainer = React.lazy(
  () => import('../containers/SentEmailInBulk/CopyBulkMailContainer')
);

const ListUser = React.lazy(() => import('../containers/ManageUser/ListUser'));

const UserDetail = React.lazy(
  () => import('../containers/ManageUser/UserDetail')
);
const Profile = React.lazy(() => import('../containers/MyPage/pages/Profile'));

const EmailChangeVerify = React.lazy(
  () => import('../containers/MyPage/pages/EmailChangeVerify')
);

const SubUserInvitationVerify = React.lazy(
  () => import('../containers/MyPage/pages/SubUserInvitationVerify')
);

const AccountSettingSubUser = React.lazy(
  () => import('../containers/MyPage/pages/AccountSettingSubUser')
);

const EditUserInfo = React.lazy(
  () => import('../containers/ManageUser/EditUserInfo')
);
const StatisticsUser = React.lazy(
  () => import('../containers/ManageUser/StatisticsUser')
);
const UserMembership = React.lazy(
  () => import('../containers/ManageUser/UserMembership')
);
const MailListInManageUser = React.lazy(
  () => import('../containers/ManageUser/MailList')
);

const UserDetailTag = React.lazy(
  () => import('../containers/ManageUser/UserDetailTag')
);

const SynthesisReports = React.lazy(
  () => import('../containers/SynthesisReports')
);

const DashBoard = React.lazy(() => import('../containers/DashBoard'));

const ContainerListEvent = React.lazy(
  () => import('../containers/Calendar/ListEvent')
);

const ContainerSettingScheduleEvent = React.lazy(
  () => import('../containers/Calendar/SettingSchedule')
);

const ContainerFunnelReports = React.lazy(
  () => import('../containers/FunnelReports/page')
);

const BlackList = React.lazy(
  () => import('../containers/ManageUser/BlackList')
);

const FixedPhraseListContainer = React.lazy(
  () => import('../containers/FixedPhraseContainer/List')
);

const EmailIntegrationsSelect = React.lazy(
  () => import('../containers/SettingAccount/EmailIntegrations/Select')
);

const TransactionalMarketing = React.lazy(
  () =>
    import(
      '../containers/SettingAccount/EmailIntegrations/TransactionalMarketing'
    )
);

const TransactionalOnly = React.lazy(
  () =>
    import('../containers/SettingAccount/EmailIntegrations/TransactionalOnly')
);

const EmailIntegrationsList = React.lazy(
  () => import('../containers/SettingAccount/EmailIntegrations/List')
);

const ListFunnelDomain = React.lazy(
  () => import('../containers/SettingAccount/Domain/List')
);

const ForgotPasswordContainer = React.lazy(
  () => import('../containers/Authen/ForgotPassword')
);

const TransactionalMarketingUpdate = React.lazy(
  () =>
    import(
      '../containers/SettingAccount/EmailIntegrations/TransactionalMarketingUpdate'
    )
);

const MyAccountSettingDetail = React.lazy(
  () => import('../containers/MyAccountSetting/Page/AccountDetails')
);

const PaymentInformation = React.lazy(
  () => import('../containers/PaymentInformation/Page')
);

const DetailSubDomain = React.lazy(
  () => import('../containers/SettingAccount/Domain/detail')
);

const createFunnelDomain = React.lazy(
  () => import('../containers/SettingAccount/Domain/FunnelDomain')
);

const UserMailListContainer = React.lazy(
  () => import('../containers/UserMailList/List')
);

const UploadMailList = React.lazy(
  () => import('../containers/MailList/UploadMailList/page')
);

const PrivacyContainer = React.lazy(() => import('../containers/Privacy'));

const ManageIntegrations = React.lazy(
  () => import('../containers/ManageIntegrations/Group/ManageIntegrations')
);

const AddIntegrations = React.lazy(
  () => import('../containers/ManageIntegrations/Add/AddIntegrations')
);

const UpdateGMOPayment = React.lazy(
  () =>
    import('../containers/ManagePaymentGateways/Update/GMO/UpdateGMOPayment')
);

const UpdatePaypalPayment = React.lazy(
  () =>
    import(
      '../containers/ManagePaymentGateways/Update/Paypal/UpdatePaypalPayment'
    )
);

const UpdateUnivapayPayment = React.lazy(
  () =>
    import(
      '../containers/ManagePaymentGateways/Update/Univapay/UpdateUnivapayPayment'
    )
);

const UpdateStripePayment = React.lazy(
  () =>
    import(
      '../containers/ManagePaymentGateways/Update/Stripe/UpdateStripePayment'
    )
);

const UpdateConvertKit = React.lazy(
  () =>
    import(
      '../containers/ManageIntegrations/Update/ConvertKit/UpdateConvertKit'
    )
);

const UpdateMailChimp = React.lazy(
  () =>
    import('../containers/ManageIntegrations/Update/MailChimp/UpdateMailChimp')
);

const FacebookIntegration = React.lazy(
  () => import('../containers/VerifyThirdParty/FacebookIntegration')
);

const UpdateFacebook = React.lazy(
  () =>
    import('../containers/ManageIntegrations/Update/Facebook/UpdateFacebook')
);

const VerifyFailed = React.lazy(
  () =>
    import('../containers/VerifyThirdParty/FacebookIntegration/VerifyFailed')
);

const VerifyAuthen = React.lazy(
  () => import('../containers/Authen/VerifyAuthen')
);

const CheckingAccessingDomain = React.lazy(
  () => import('../containers/CheckingAccessing')
);

const ContainerCreateFunnel = React.lazy(
  () => import('../containers/Funnel/Create')
);

const SettingFunnelContainer = React.lazy(
  () => import('../containers/Funnel/Settings')
);

const GroupFunnelContainer = React.lazy(
  () => import('../containers/Funnel/List/pages/Group')
);

const PageListFunnelContainer = React.lazy(
  () => import('../containers/Funnel/List/pages/PageList')
);

const ContainerCreateEvent = React.lazy(
  () => import('../containers/Calendar/CreateEvent')
);

const PageRecycleContainer = React.lazy(
  () => import('../containers/Funnel/List/pages/PageRecycle')
);

const MailBulkDetail = React.lazy(
  () => import('../containers/SentEmailInBulk/Detail')
);

const TermServiceContainer = React.lazy(
  () => import('../containers/TermService')
);

const GroupMemberContainer = React.lazy(
  () => import('../containers/Membersite/pages/Group')
);

const RecycleMembersiteContainer = React.lazy(
  () => import('../containers/Membersite/pages/PageRecycle')
);

const ListMembersiteContainer = React.lazy(
  () => import('../containers/Membersite/pages/PageList')
);

const ContainerCreateMembersite = React.lazy(
  () => import('../containers/Membersite/Course/Create')
);

const UploadCourseContainer = React.lazy(
  () => import('../containers/Membersite/Course/Upload/UploadCourse/page')
);

const CreatePersonCourse = React.lazy(
  () => import('../containers/Membersite/Course/Upload/CreatePersonCourse')
);

const ExpireTrialVerifyCard = React.lazy(
  () => import('../containers/ExpireTrialVerifyCard')
);

const SignInContainer = React.lazy(() => import('../containers/Authen/SignIn'));

const PlanSelection = React.lazy(
  () => import('../components/pages/PackagePlan')
);

const SettingMembersiteContainer = React.lazy(
  () => import('../containers/Membersite/Settings')
);

const SettingCourseContainer = React.lazy(
  () => import('../containers/Membersite/Course/Settings')
);

const SelectedTemplateMembersite = React.lazy(
  () => import('../containers/Membersite/Detail')
);

const ListUserCourseContainer = React.lazy(
  () => import('../containers/Membersite/ListUserCourse')
);

const CreateDomainContainer = React.lazy(
  () => import('../containers/SettingAccount/Domain/Create')
);

const TemplateDetailContainer = React.lazy(
  () => import('../containers/ManageTemplates/Details')
);

const SharedCourse = React.lazy(
  () => import('../containers/Membersite/Course/SharedCourse')
);

const PageListTemplates = React.lazy(
  () => import('../containers/ManageTemplates/PageList')
);

const SharedTemplate = React.lazy(
  () => import('../containers/ManageTemplates/SharedTemplates')
);

const ContentUpdateThinkific = React.lazy(
  () => import('../components/pages/Integrations/Update/Thinkific')
);

const ContentUpdateTeachable = React.lazy(
  () =>
    import('../containers/ManageIntegrations/Update/Teachable/UpdateTeachable')
);

const ContentMyASP = React.lazy(
  () => import('../components/pages/Integrations/Update/myasp')
);

const ContentGoogleSheet = React.lazy(
  () => import('../components/pages/Integrations/Update/GoogleSheet')
);

const ContactUsContainer = React.lazy(() => import('../containers/ContactUs'));

const AffiliateCenterContainer = React.lazy(
  () => import('../containers/Affiliate/Setting')
);

const DetailPartnerContainer = React.lazy(
  () => import('../containers/Affiliate/Partner/Detail')
);

const BonusContainer = React.lazy(
  () => import('../containers/Affiliate/Bonus')
);

const SubcriberContainer = React.lazy(
  () => import('../containers/Affiliate/Subscriber')
);

const AffiliateSettingTermServiceContainer = React.lazy(
  () => import('../containers/Affiliate/Setting/TermService')
);
const AffiliateProductIntroduce = React.lazy(
  () =>
    import(
      '../containers/Funnel/Detail/templates/SettingAffiliate/component/IntroduceProduct'
    )
);

const MaintenanceContainer = React.lazy(
  () => import('../containers/Maintenance')
);

const CreateOrEditTagContainer = React.lazy(
  () => import('../containers/Tags/CreateOrEdit')
);

const ListTagContainer = React.lazy(() => import('../containers/Tags/List'));

const ListTagUserContainer = React.lazy(
  () => import('../containers/Tags/ListTagUser')
);

const ListPartnerContainer = React.lazy(
  () => import('../containers/Affiliate/Partner/List')
);

//Remind Email
const CreateRemindMailContainer = React.lazy(
  () => import('../containers/RemindMail/CreateRemindMail')
);

const RemindMailList = React.lazy(
  () => import('../containers/RemindMail/ListRemindMail/ListRemindMail')
);

const EditRemindMailContainer = React.lazy(
  () => import('../containers/RemindMail/EditRemindMailContainer')
);

const EditRemindMailContent = React.lazy(
  () => import('../containers/RemindMail/EditRemindMailContent')
);

const ListBlockTemplatesContainer = React.lazy(
  () => import('../containers/ManageBlockTemplates/List')
);

const BlockTemplateDetailContainer = React.lazy(
  () => import('../containers/ManageBlockTemplates/Detail')
);

const CreateOrTimerContainer = React.lazy(
  () => import('../containers/Timer/CreateOrEdit')
);

const WhitelistUserContainer = React.lazy(
  () => import('../containers/Timer/WhitelistUser')
);

const ListTimerContainer = React.lazy(() => import('../containers/Timer/List'));

export const ROUTER = [
  // comment hide router purchase domains
  {
    path: LINK_SETTING_ACCOUNT.ADD_DOMAIN,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: CreateDomainContainer,
  },
  /* Login */
  {
    path: AUTHEN_PAGE.VERIFY_NOT_CARD,
    isPrivate: true,
    role: true,
    exact: true,
    component: ExpireTrialVerifyCard,
  },
  {
    path: '/',
    isPrivate: false,
    role: true,
    exact: true,
    component: SignInContainer,
  },
  {
    path: HELPER_PAGE.TERM_SERVICE,
    isPrivate: false,
    role: true,
    exact: true,
    component: TermServiceContainer,
  },
  {
    path: '/transit-station/:type',
    isPrivate: false,
    role: true,
    exact: true,
    component: CheckingAccessingDomain,
  },
  {
    path: AUTHEN_PAGE.SIGN_IN,
    isPrivate: false,
    role: true,
    exact: true,
    component: SignInContainer,
  },
  {
    path: AUTHEN_PAGE.VERIFY_COOKIE,
    isPrivate: false,
    role: true,
    exact: true,
    component: VerifyAuthen,
  },
  {
    path: AUTHEN_PAGE.FORGOT_PASSWORD,
    isPrivate: false,
    role: true,
    exact: true,
    component: ForgotPasswordContainer,
  },
  {
    path: AUTHEN_PAGE.CREATE_NEW_PASSWORD,
    isPrivate: false,
    role: true,
    exact: true,
    component: CreateNewPassword,
  },
  {
    path: AUTHEN_PAGE.REGISTER,
    isPrivate: false,
    role: true,
    exact: true,
    component: RegisterAccount,
  },
  {
    path: AUTHEN_PAGE.VERIFY_ACCOUNT_SUCCESS,
    isPrivate: false,
    role: true,
    exact: true,
    component: VerifyAccountSuccess,
  },
  {
    path: AUTHEN_PAGE.PLAN_SELECTION,
    isPrivate: false,
    role: true,
    exact: true,
    component: PlanSelection,
  },

  /* DASHBOARD */
  {
    path: AUTHEN_PAGE.DASHDOARD,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: DashBoard,
  },
  {
    path: REPORT_PATH.SYNTHESIS,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: SynthesisReports,
  },

  /* FUNNEL */
  {
    path: FUNNEL.GROUP,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: GroupFunnelContainer,
  },
  {
    path: FUNNEL.LIST,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: PageListFunnelContainer,
  },
  {
    path: FUNNEL.RECYCLE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: PageRecycleContainer,
  },
  {
    path: FUNNEL.CREATE,
    isPrivate: true,
    role: ROLE_OF_PAGE.EDIT,
    exact: true,
    component: ContainerCreateFunnel,
  },
  {
    path: FUNNEL.FUNNEL_DETAIL,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: false,
    component: SelectedTemplate,
  },
  {
    path: FUNNEL.SETTING,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: SettingFunnelContainer,
  },
  {
    path: FUNNEL.SHARED_FUNNEL,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: SharedFunnel,
  },
  {
    path: FUNNEL.SHARED_TEMPLATE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: SharedTemplate,
  },
  {
    path: REPORT_PATH.DETAIL,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: ContainerFunnelReports,
  },

  /* Timer */
  {
    path: FUNNEL.CREATE_TIMER,
    isPrivate: true,
    role: ROLE_OF_PAGE.EDIT,
    exact: true,
    component: CreateOrTimerContainer,
  },
  {
    path: FUNNEL.EDIT_TIMER,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: CreateOrTimerContainer,
  },
  {
    path: LINK_USER.WHITELIST_USER,
    isPrivate: true,
    role: ROLE_OF_PAGE.EDIT,
    exact: true,
    component: WhitelistUserContainer,
  },
  {
    path: FUNNEL.LIST_TIMER,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: ListTimerContainer,
  },
  /* CALENDAR */
  {
    path: CALENDAR.CREATE,
    isPrivate: true,
    role: ROLE_OF_PAGE.EDIT,
    exact: true,
    component: ContainerCreateEvent,
  },
  {
    path: CALENDAR.LIST,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: ContainerListEvent,
  },
  {
    path: CALENDAR.SETTING_SCHEDULE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: ContainerSettingScheduleEvent,
  },
  {
    path: CALENDAR.SETTING_BASIC,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: ContainerCreateEvent,
  },

  /* MEMBERSITE */
  {
    path: MEMBERSITE.GROUP_COURSE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: GroupMemberContainer,
  },
  {
    path: MEMBERSITE.LIST_COURSE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: ListMembersiteContainer,
  },
  {
    path: MEMBERSITE.RECYCLE_COURSE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: RecycleMembersiteContainer,
  },
  {
    path: MEMBERSITE.CREATE_COURSE,
    isPrivate: true,
    role: ROLE_OF_PAGE.EDIT,
    exact: true,
    component: ContainerCreateMembersite,
  },
  {
    path: MEMBERSITE.SELECT,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: false,
    component: SelectedTemplateMembersite,
  },
  {
    path: MEMBERSITE.SETTING,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: SettingMembersiteContainer,
  },
  {
    path: MEMBERSITE.SETTING_COURSE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: SettingCourseContainer,
  },
  {
    path: MEMBERSITE.SHARED_TEMPLATE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: SharedMembersiteTemplate,
  },
  {
    path: MEMBERSITE.SHARED_COURSE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: SharedCourse,
  },
  {
    path: MEMBERSITE.LIST_USER,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: ListUserCourseContainer,
  },
  {
    path: MEMBERSITE.COURSE_IMPORT_CSV,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: UploadCourseContainer,
  },
  {
    path: MEMBERSITE.COURSE_CREATE_BY_NAME,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: CreatePersonCourse,
  },

  /* PRODUCT */
  {
    path: PRODUCT.GROUP,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: ListProductRegister,
  },
  {
    path: PRODUCT.REGISTER,
    isPrivate: true,
    role: ROLE_OF_PAGE.EDIT,
    exact: true,
    component: ProductRegisterNew,
  },
  {
    path: PRODUCT.UPDATE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: ProductRegisterNew,
  },
  {
    path: FUNNEL.LIST_TEMPLATE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: PageListTemplates,
  },
  {
    path: FUNNEL.LIST_BLOCK_TEMPLATE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: ListBlockTemplatesContainer,
  },
  {
    path: FUNNEL.BLOCK_TEMPLATE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: BlockTemplateDetailContainer,
  },
  {
    path: MEMBERSITE.LIST_MEMBERSITE_TEMPLATE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: PageListMembersiteTemplates,
  },
  {
    path: FUNNEL.TEMPLATE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: TemplateDetailContainer,
  },
  {
    path: MEMBERSITE.MEMBERSITE_TEMPLATE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: MembersiteTemplateDetailContainer,
  },
  /* Mail */
  {
    path: MAIL_PATH.CREATE_STEP_MAIL,
    isPrivate: true,
    role: ROLE_OF_PAGE.EDIT,
    exact: true,
    component: CreateStepMailContainer,
  },
  {
    path: MAIL_PATH.MAIL_LIST,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: MailList,
  },
  {
    path: MAIL_PATH.LIST_STEP_MAIL,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: StepMailList,
  },
  {
    path: MAIL_PATH.DETAIL_STEP_MAIL,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: EditStepMailContainer,
  },
  {
    path: MAIL_PATH.STEP_MAIL_VIEW,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: StepMailDetailContainer,
  },
  {
    path: MY_PAGE.MANAGE_PAYMENT_GATEWAY,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: ManagePaymentGateways,
  },
  {
    path: MAIL_PATH.MAIL_LIST_BY_NAME,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: UpdateMailList,
  },
  {
    path: MAIL_PATH.MAIL_LIST_CREATE_BY_NAME,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: CreatePersonMail,
  },
  {
    path: MAIL_PATH.MAIL_LIST_CREATE,
    isPrivate: true,
    role: ROLE_OF_PAGE.EDIT,
    exact: true,
    component: CreateMailList,
  },
  {
    path: MAIL_PATH.MAIL_LIST_IMPORT_CSV,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: UploadMailList,
  },
  {
    path: MAIL_PATH.DETAIL,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: EditMailContainer,
  },
  {
    path: MAIL_PATH.SENT_MAIL_LIST,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: ListSentMailBulk,
  },
  {
    path: MAIL_PATH.SENT_MAIL_DETAIL,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: MailBulkDetail,
  },
  {
    path: MAIL_PATH.BULK_MAIL_CREATE,
    isPrivate: true,
    role: ROLE_OF_PAGE.EDIT,
    exact: true,
    component: CreateBulkMailContainer,
  },
  {
    path: MAIL_PATH.BULK_MAIL_COPY,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: CopyBulkMailContainer,
  },
  {
    path: MAIL_PATH.STEP_MAIL_UPDATE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: CreateStepMailContainer,
  },
  {
    path: MAIL_PATH.BULK_MAIL_DETAIL,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: CopyBulkMailContainer,
  },
  {
    path: MAIL_PATH.MAIL_LIST_USER,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: UserMailListContainer,
  },
  {
    path: MAIL_PATH.MAIL_FIXED_PHRASE_LIST,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: FixedPhraseListContainer,
  },
  {
    path: MAIL_PATH.MAIL_FIXED_PHRASE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: FixedPhraseListContainer,
  },

  /* USER */
  {
    path: LINK_USER.LIST_USER,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: ListUser,
  },
  {
    path: LINK_USER.MAIL_LIST,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: MailListInManageUser,
  },
  {
    path: LINK_USER.USER_DETAIL_TAG,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: UserDetailTag,
  },
  {
    path: LINK_USER.USER_DETAIL,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: UserDetail,
  },
  {
    path: LINK_USER.EDIT_USER,
    isPrivate: true,
    role: ROLE_OF_PAGE.EDIT,
    exact: true,
    component: EditUserInfo,
  },
  {
    path: LINK_USER.STATISTICS,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: StatisticsUser,
  },
  {
    path: LINK_USER.USER_MEMBERSHIP,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: UserMembership,
  },
  {
    path: LINK_USER.BLACKLIST_USER,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: BlackList,
  },

  // Tag
  {
    path: LINK_USER.CREATE_TAG,
    isPrivate: true,
    role: ROLE_OF_PAGE.EDIT,
    exact: true,
    component: CreateOrEditTagContainer,
  },
  {
    path: LINK_USER.EDIT_TAG,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: CreateOrEditTagContainer,
  },
  {
    path: LINK_USER.LIST_TAG,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: ListTagContainer,
  },
  {
    path: LINK_USER.LIST_TAG_USER,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: ListTagUserContainer,
  },

  /* MY PAGE */
  /* ACCOUNT SETTING */
  {
    path: LINK_SETTING_ACCOUNT.PAYMENT_INFORMATION,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: PaymentInformation,
  },
  {
    path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_CREATE_TYPE,
    isPrivate: true,
    role: ROLE_OF_PAGE.EDIT,
    exact: true,
    component: AddPaymentGateway,
  },
  {
    path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_GMO,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: UpdateGMOPayment,
  },
  {
    path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_PAYPAL,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: UpdatePaypalPayment,
  },
  {
    path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_UNIVAPAY,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: UpdateUnivapayPayment,
  },
  {
    path: MY_PAGE.MANAGE_PAYMENT_GATEWAY_UPDATE_STRIPE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: UpdateStripePayment,
  },

  {
    path: LINK_SETTING_ACCOUNT.EMAIL_INTEGRATIONS_SELECT,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: EmailIntegrationsSelect,
  },
  {
    path: LINK_SETTING_ACCOUNT.EMAIL_INTEGRATIONS_LIST,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: EmailIntegrationsList,
  },
  {
    path: LINK_SETTING_ACCOUNT.EMAIL_INTEGRATIONS_TRANSITION_AND_MARKETING,
    isPrivate: true,
    role: ROLE_OF_PAGE.EDIT,
    exact: true,
    component: TransactionalMarketing,
  },
  {
    path: LINK_SETTING_ACCOUNT.EMAIL_INTEGRATIONS_TRANSITION_ONLY,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: TransactionalOnly,
  },
  {
    path: LINK_SETTING_ACCOUNT.LIST_DOMAIN,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: ListFunnelDomain,
  },
  {
    path: LINK_SETTING_ACCOUNT.CREATE_DOMAIN,
    isPrivate: true,
    role: ROLE_OF_PAGE.EDIT,
    exact: true,
    component: createFunnelDomain,
  },
  {
    path: LINK_SETTING_ACCOUNT.EDIT_EMAIL_INTEGRATIONS_TRANSITION_AND_MARKETING,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: TransactionalMarketingUpdate,
  },
  {
    path: MAIL.ALTERNATIVE_CHARACTER_VERIFY_UNSUBSCRIBE_STEP_MAIL,
    isPrivate: false,
    role: true,
    exact: true,
    component: UnsubscribeLinkForStepMail,
  },
  {
    path: MAIL.ALTERNATIVE_CHARACTER_VERIFY_UNSUBSCRIBE_MAIL,
    isPrivate: false,
    role: true,
    exact: true,
    component: UnsubscribeLinkForStepMail,
  },
  {
    path: MAIL.ALTERNATIVE_CHARACTER_CHANGE_REGISTERED_EMAIL,
    isPrivate: false,
    role: true,
    exact: true,
    component: ChangeRegisteredEmail,
  },
  {
    path: MAIL.ALTERNATIVE_CHARACTER_CANCEL_RECURRING_PAYMENT,
    isPrivate: false,
    role: true,
    exact: true,
    component: CancelRecurringPayment,
  },
  {
    path: MAIL.ALTERNATIVE_CHARACTER_CHANGE_CREDIT_CARD,
    isPrivate: false,
    role: true,
    exact: true,
    component: ChangeCreditCard,
  },
  {
    path: MAIL.ALTERNATIVE_CHARACTER_PAYMENT_ORDER,
    isPrivate: false,
    role: true,
    exact: true,
    component: PaymentOrder,
  },
  {
    path: MAIL.ALTERNATIVE_CANCEL_SCHEDULE,
    isPrivate: false,
    role: true,
    exact: true,
    component: ScheduleCancel,
  },
  {
    path: MY_PAGE.PROFILE,
    isPrivate: true,
    role: true,
    exact: true,
    component: Profile,
  },
  {
    path: MY_PAGE.PROFILE_EDIT_INFO,
    isPrivate: true,
    role: true,
    exact: true,
    component: Profile,
  },
  {
    path: MY_PAGE.PROFILE_EDIT_PASSWORD,
    isPrivate: true,
    role: true,
    exact: true,
    component: Profile,
  },
  {
    path: MY_PAGE.PROFILE_EMAIL_CHANGE,
    isPrivate: false,
    role: true,
    exact: true,
    component: EmailChangeVerify,
  },
  {
    path: MY_PAGE.ACCOUNT_SETTING_SUB_USER_INVITATION,
    isPrivate: false,
    role: true,
    exact: true,
    component: SubUserInvitationVerify,
  },
  {
    path: MY_PAGE.ACCOUNT_SETTING_SUB_USER,
    isPrivate: true,
    role: true,
    exact: true,
    component: AccountSettingSubUser,
  },
  {
    path: MY_PAGE.MY_ACCOUNT_SETTING_DETAIL,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: MyAccountSettingDetail,
  },
  {
    path: LINK_SETTING_ACCOUNT.DETAIL_DOMAIN,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: DetailSubDomain,
  },

  //A002 - PRIVACY
  {
    path: HELPER_PAGE.PRIVACY,
    isPrivate: false,
    role: true,
    exact: true,
    component: PrivacyContainer,
  },
  //CONTACT - US
  {
    path: HELPER_PAGE.CONTACT_US,
    isPrivate: false,
    role: true,
    exact: true,
    component: ContactUsContainer,
  },

  //INTEGRATION
  {
    path: LINK_SETTING_ACCOUNT.VERIFY_INTERGRATIONS,
    isPrivate: false,
    role: true,
    exact: true,
    component: FacebookIntegration,
  },
  {
    path: HELPER_PAGE.HELPER_PAGE,
    isPrivate: false,
    role: true,
    exact: true,
    component: VerifyFailed,
  },
  {
    path: LINK_SETTING_ACCOUNT.API_INTEGRATIONS,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: false,
    component: ManageIntegrations,
  },
  {
    path: LINK_SETTING_ACCOUNT.ADD_INTEGRATION,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: false,
    component: AddIntegrations,
  },
  {
    path: LINK_SETTING_ACCOUNT.UPDATE_CONVERTKIT,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: false,
    component: UpdateConvertKit,
  },
  {
    path: LINK_SETTING_ACCOUNT.UPDATE_MAILCHIMP,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: false,
    component: UpdateMailChimp,
  },
  {
    path: LINK_SETTING_ACCOUNT.UPDATE_THINKIFIC,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: false,
    component: ContentUpdateThinkific,
  },
  {
    path: LINK_SETTING_ACCOUNT.UPDATE_TEACHABLE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: false,
    component: ContentUpdateTeachable,
  },
  {
    path: LINK_SETTING_ACCOUNT.UPDATE_FACEBOOK,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: false,
    component: UpdateFacebook,
  },

  // Affiliate
  {
    path: AFFILIATE.SETTING,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: false,
    component: AffiliateCenterContainer,
  },
  {
    path: AFFILIATE.PARTNER,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: ListPartnerContainer,
  },
  {
    path: AFFILIATE.PARTNER_DETAIL,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: DetailPartnerContainer,
  },
  {
    path: AFFILIATE.SUBSCRIBER,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: SubcriberContainer,
  },
  {
    path: AFFILIATE.BONUS,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: BonusContainer,
  },
  {
    path: AFFILIATE.TERM_SERVICE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: AffiliateSettingTermServiceContainer,
  },
  {
    path: AFFILIATE.FUNNEL_INTRODUCE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: AffiliateProductIntroduce,
  },
  {
    path: LINK_SETTING_ACCOUNT.UPDATE_MYASP,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: false,
    component: ContentMyASP,
  },
  {
    path: LINK_SETTING_ACCOUNT.UPDATE_GOOGLE_SHEET,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: false,
    component: ContentGoogleSheet,
  },
  {
    path: MAINTENANCE.PAGE_MAINTAINANCE,
    isPrivate: false,
    role: true,
    exact: true,
    component: MaintenanceContainer,
  },
  //RemindEmail
  {
    path: REMIND_MAIL.CREATE_REMIND_MAIL,
    isPrivate: true,
    role: ROLE_OF_PAGE.EDIT,
    exact: true,
    component: CreateRemindMailContainer,
  },
  {
    path: REMIND_MAIL.REMIND_MAIL_UPDATE,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: CreateRemindMailContainer,
  },
  {
    path: REMIND_MAIL.LIST_REMIND_MAIL,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: RemindMailList,
  },
  {
    path: REMIND_MAIL.DETAIL_REMIND_MAIL,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: EditRemindMailContainer,
  },
  {
    path: REMIND_MAIL.EDIT_MAIL,
    isPrivate: true,
    role: ROLE_OF_PAGE.ONLY_VIEW,
    exact: true,
    component: EditRemindMailContent,
  },
  {
    path: MAIL.ALTERNATIVE_SCHEDULE_CHANGE,
    isPrivate: false,
    role: true,
    exact: true,
    component: ScheduleChange,
  },
];
