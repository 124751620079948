export const CREATE_TAG = 'CREATE_TAG';
export const EDIT_TAG = 'EDIT_TAG';
export const GET_DETAIL_TAG = 'GET_DETAIL_TAG';
export const GET_DETAIL_TAG_SUCCESS = 'GET_DETAIL_TAG_SUCCESS';
export const GET_LIST_GROUP_TAG = 'GET_LIST_GROUP_TAG';
export const GET_LIST_GROUP_TAG_SUCCESS = 'GET_LIST_GROUP_TAG_SUCCESS';
export const GET_LIST_TAG = 'GET_LIST_TAG';
export const GET_LIST_TAG_SUCCESS = 'GET_LIST_TAG_SUCCESS';
export const RENAME_GROUP_TAG_NAME = 'RENAME_GROUP_TAG_NAME';
export const DELETE_GROUP_TAG = 'DELETE_GROUP_TAG';
export const DUPLICATE_GROUP_TAG = 'DUPLICATE_GROUP_TAG';
export const DUPLICATE_TAG = 'DUPLICATE_TAG';
export const DELETE_TAG = 'DELETE_TAG';
export const GET_ALL_TAGS = 'GET_ALL_TAGS';
export const GET_ALL_TAGS_SUCCESS = 'GET_ALL_TAGS_SUCCESS';

export const UPDATE_TAG_OF_ENDUSER = 'UPDATE_TAG_OF_ENDUSER';
export const GET_LIST_TAG_USER = 'GET_LIST_TAG_USER';
export const GET_LIST_TAG_USER_SUCCESS = 'GET_LIST_TAG_USER_SUCCESS';
export const DELETE_USER_FROM_TAG = 'DELETE_USER_FROM_TAG';
export const DOWNLOAD_CSV_LIST_TAG_USER = 'DOWNLOAD_CSV_LIST_TAG_USER';
export const GET_USERS_NO_TAG = 'GET_USERS_NO_TAG';

export const ADD_USERS_INTO_TAG = 'ADD_USERS_INTO_TAG';

export const GET_USER_DETAIL_TAGS = 'GET_USER_DETAIL_TAGS';
export const GET_USER_DETAIL_TAGS_SUCCESS = 'GET_USER_DETAIL_TAGS_SUCCESS';
