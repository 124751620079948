import { EStatusHttp } from './../../../Clients/interfaces';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as Types from '../../actions/mail/types';
import * as ActionTypes from '../../actionTypes/loadingActionTypes';
import { getErrorResponse } from '../../../helper/httpClient.helper';
import * as mailService from './../../../Clients/mail';
import { handleMessage } from '../../../utilities/common.utilities';

interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
}

function* getListAllMailListWithTrashed() {
  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      mailService.fetchListAllMailListWithTrashed
    );

    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_LIST_ALL_MAIL_LIST_WITH_TRASHED_SUCCESS,
        data: response.data.data,
      });
      return;
    }
  } catch (e: any) {
    if (e.response) {
      yield put({
        type: Types.GET_LIST_ALL_MAIL_LIST_WITH_TRASHED_ERROR,
        error: getErrorResponse(e.response),
      });
    }
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* getDataMailListAndConditionMail(action: {
  type: string;
  params: any;
  onSuccess: (data: any) => void;
}) {
  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      mailService.getDataMailListAndConditionMail,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data);
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

export default function* mailConditionSaga() {
  yield takeLatest(
    Types.GET_LIST_ALL_MAIL_LIST_WITH_TRASHED,
    getListAllMailListWithTrashed
  );
  yield takeLatest(
    Types.GET_DATA_MAIL_LIST_AND_CONDITION_MAIL,
    getDataMailListAndConditionMail
  );
}
