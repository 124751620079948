import { DATA_TYPES, ItemContentSearch } from '../../config';
import SearchContent from '../Content';

interface SearchViewSwitchProps {
  data: any;
}

const SearchViewSwitch: React.FC<SearchViewSwitchProps> = ({ data }) => {
  const renderMethod = (type: any) => {
    switch (type) {
      case DATA_TYPES.FUNNEL:
        const convertedFunnelData: ItemContentSearch[] = data?.data?.map(
          (item: any) => ({
            url: `/funnel-detail/${item.uuid}/edit`,
            content: item.name,
          })
        );
        return <SearchContent type={data.title} data={convertedFunnelData} />;
      case DATA_TYPES.FUNNEL_STEP:
        const convertedFunnelStepData: ItemContentSearch[] = data?.data?.map(
          (item: any) => ({
            url: `/funnel-detail/${item.funnel_uuid}/edit/${item.uuid}`,
            content: item.name,
          })
        );
        return (
          <SearchContent type={data.title} data={convertedFunnelStepData} />
        );
      case DATA_TYPES.PRODUCT:
        const convertedProductData: ItemContentSearch[] = data?.data?.map(
          (item: any) => ({
            url: `/product/${item.uuid}/update`,
            content: item.name,
            subContent: item.description,
          })
        );
        return <SearchContent type={data.title} data={convertedProductData} />;
      case DATA_TYPES.STEP_MAIL:
        const convertedStepMailData: ItemContentSearch[] = data?.data?.map(
          (item: any) => ({
            url: `/step-mail/view/${item.uuid}`,
            content: item.name,
          })
        );
        return <SearchContent type={data.title} data={convertedStepMailData} />;
      case DATA_TYPES.SEND_MAIL:
        const convertedSendMailData: ItemContentSearch[] = data?.data?.map(
          (item: any) => ({
            url: `/sent-mail/detail/${item.uuid}`,
            content: item.title,
            subContent: item.content,
          })
        );
        return <SearchContent type={data.title} data={convertedSendMailData} />;
      case DATA_TYPES.USER:
        const convertedUserData: ItemContentSearch[] = data?.data?.map(
          (item: any) => ({
            url: `/manage-user/list/${item.uuid}`,
            content: item.full_name,
            subContent: item.email,
          })
        );
        return <SearchContent type={data.title} data={convertedUserData} />;
      case DATA_TYPES.MAIL_LIST:
        const convertedMailListData: ItemContentSearch[] = data?.data?.map(
          (item: any) => ({
            url: `/mail-list/${item.uuid}`,
            content: item?.name,
          })
        );
        return <SearchContent type={data.title} data={convertedMailListData} />;
      case DATA_TYPES.TAG:
        const convertedTagData: ItemContentSearch[] = data?.data?.map(
          (item: any) => ({
            url: `/tags/list-user/${item.uuid}`,
            content: item?.name,
          })
        );
        return <SearchContent type={data.title} data={convertedTagData} />;
      default:
        return <></>;
    }
  };

  return renderMethod(data.title);
};

export default SearchViewSwitch;
