import axiosClient from '..';
import {
  SendTestMailProps,
  SettingMailProps,
} from '../../containers/Membersite/Settings/MailWelcome/config';
import { showLoading } from '../../store/actions/loadingAction';
import { IPayLoadGroupCourseList } from '../../store/actions/membersite/group';
import { PayloadPageListCourse } from '../../store/actions/membersite/pageList';
import { store } from '../../store/configureStore';
import { completedResponse } from '../helper';
import { IBaseParameter } from '../interfaces';
import API from './api';
import { endPoints } from './config';
import {
  IParamsCourse,
  IParamsInstallTemplate,
  IParamsRenameStep,
  IParamsUpdateLesson,
  IPayloadChangeActive,
  IPayloadDownLoadCSV,
  IPramsDataTemplate,
  IPramsListUserCourse,
} from './interface';

export const getSetting = () => axiosClient.get(endPoints.getSetting());

export interface IMembersiteSetting {
  uuid?: string;
  name: string;
  domain?: string;
  domain_uuid: string;
  favicon_url: string;
  share_url?: string;
  color: string;
  logo_path?: string;
  logo_file?: string;
  description: string;
  color_footer?: string;
  text_footer?: string;
  active_send_mail: number;
}

export const updateMembersite = (payload: IMembersiteSetting) =>
  axiosClient.post(endPoints.update(), payload);

export const getListSignature = () =>
  axiosClient.get(endPoints.getListSignature());

export const getSettingMail = () => axiosClient.get(endPoints.getSettingMail());

export const updateMail = (payload: SettingMailProps) =>
  axiosClient.post(endPoints.updateMail(), payload);

export const updateSendTestMail = (payload: SendTestMailProps) =>
  axiosClient.post(endPoints.updateSendTestMail(), payload);
export interface IGroupCourses {
  uuid: string;
  name: string;
}

export const getAllGroupCourse = () =>
  axiosClient.get(endPoints.getAllGroupCourse());

export interface ICourse {
  uuid: string;
  name: string;
  thumbnail?: string;
  thumbnail_url?: string;
  file?: string;
  group_uuid: string;
  group_name?: string;
  head_tracking_code?: string;
  body_tracking_code?: string;
}

export const createCourse = (payLoad: ICourse) =>
  axiosClient.post(endPoints.createCourse(), payLoad);

export const updateCourse = (payLoad: ICourse) =>
  axiosClient.post(endPoints.updateCourse(), payLoad);

export const getSettingCourse = (uuid: string) =>
  axiosClient.get(endPoints.getSettingCourse(uuid));

export interface IGetElementById {
  uuid: string;
}

export async function deleteCourse(params: IBaseParameter<IGetElementById>) {
  params.hasLoading && store.dispatch(showLoading());
  const response = await axiosClient.post(endPoints.delete(), params.params);
  return completedResponse<any>(response, { ...params });
}

export interface ICreateUserInCourse {
  uuid: string;
  first_name: string;
  last_name: string;
  email: string;
}

export const createUserInCourse = (payLoad?: ICreateUserInCourse) =>
  axiosClient.post(endPoints.createUserInCourse(), payLoad);

export interface ICreateUserByImportCSV {
  uuid: string;
  file_csv: any;
  overwrite: number;
  token: string;
}

export const createUserByImportCSV = (payLoad?: ICreateUserByImportCSV) =>
  axiosClient.post(endPoints.createUserByImportCSV(), payLoad);

// list template funnel

const installTemplate = (params: IParamsInstallTemplate) =>
  axiosClient.post(API.installTemplate, params);

interface ParamsRenameFunnel {
  uuid: string;
  name: string;
}
const renameMembersite = (params: ParamsRenameFunnel) =>
  axiosClient.post(API.renameMembersite, params);

const getAllTemplates = () => axiosClient.get(API.getAllTemplates);

const renameStep = (params: IParamsRenameStep) =>
  axiosClient.post(API.renameStep, params);

interface ParamsCreateStepMembersite {
  uuid: string;
  name: string;
}
const createStepMembersite = (params: ParamsCreateStepMembersite) =>
  axiosClient.post(API.createStep, params);

interface ParamsDeleteStepMembersite {
  funnel_step_uuid: string;
}
const removeStepMembersite = (params: ParamsDeleteStepMembersite) =>
  axiosClient.post(API.deleteStep, params);

interface ParamsChangeTemplate {
  name: string;
  path: string;
}
const handleChangeTemplateURL = (uuid: string, params: ParamsChangeTemplate) =>
  axiosClient.post(API.handleChangeTemplateURL(uuid), params);

const submitSettingStepMembersite = (params: any) =>
  axiosClient.post(API.submitSettingStepMembersite, params);

export const fetchStepMembersiteDetailSetting = (params: { uuid: string }) =>
  axiosClient.get(API.fetchStepMembersiteDetailSetting, { params });

interface ParamsRestoresSettingStep {
  page_uuid: string;
  restore_uuid: string;
}
const restoresSettingStep = (params: ParamsRestoresSettingStep) =>
  axiosClient.post(API.restoresSettingStep, params);

const fetchListAccessLink = (uuid: string) =>
  axiosClient.get(API.fetchListAccessLink(uuid));

interface ParamsCreateAccessLink {
  description: string;
  funnel_uuid: string;
  name: string;
  path: string;
}
const createAccessLink = (params: ParamsCreateAccessLink) =>
  axiosClient.post(API.createAccessLink, params);

export const getSummaryCourse = () =>
  axiosClient.get(endPoints.getSummaryCourse());

export interface ICreateGroup {
  type: 'FUNNEL' | 'COURSE';
  name: string;
}

export async function createGroupMembersites(
  params: IBaseParameter<ICreateGroup>
) {
  const response = await axiosClient.post(
    endPoints.createMembersiteGroup(),
    params.params
  );
  return completedResponse<any>(response, { ...params });
}

export const getAllGroupOfCourse = () =>
  axiosClient.get(endPoints.getAllGroupCourse());

export const getPageListGroupCourses = (params: IPayLoadGroupCourseList) => {
  return axiosClient.get(endPoints.getPageListGroupCourses(params));
};

export interface ChangeNameGroupMembersite {
  uuid: string;
  name: string;
  type: string;
}

export const changeNameGroupMembersite = async (
  params: IBaseParameter<ChangeNameGroupMembersite>
) => {
  const response = await axiosClient.post(
    endPoints.changeNameGroupMembersite(),
    params.params
  );
  return completedResponse<any>(response, { ...params });
};

export const renameGroupFunnel = (params: ChangeNameGroupMembersite) => {
  return axiosClient.post(endPoints.changeNameGroupMembersite(), params);
};

export const changeNameCourse = (params: IParamsCourse) => {
  return axiosClient.post(endPoints.changeNameCourse(), params);
};

export interface CloneGroup {
  uuid: string;
}

export const cloneGroupMembersite = async (
  params: IBaseParameter<CloneGroup>
) => {
  params.hasLoading && store.dispatch(showLoading());
  const response = await axiosClient.post(
    endPoints.cloneGroupMembersite(),
    params.params
  );
  return completedResponse(response, { ...params });
};

export const deleteGroupMembersite = async (
  params: IBaseParameter<CloneGroup>
) => {
  params.hasLoading && store.dispatch(showLoading());
  const response = await axiosClient.post(
    endPoints.deleteGroupMembersite(),
    params.params
  );
  return completedResponse(response, { ...params });
};

export interface StatusFavorite {
  uuid: string;
  favorite: number;
}

export const changeStatusFavorite = async (
  params: IBaseParameter<StatusFavorite>
) => {
  params.hasLoading && store.dispatch(showLoading());
  const response = await axiosClient.post(
    endPoints.changeStatusFavorite(),
    params.params
  );
  return completedResponse(response, { ...params });
};

export interface StatusPublic {
  flag_publish: number;
  uuid: string;
}

export const changeStatusPublicCourse = async (
  params: IBaseParameter<StatusPublic>
) => {
  params.hasLoading && store.dispatch(showLoading());
  const response = await axiosClient.post(
    endPoints.changeStatusPublicCourse(),
    params.params
  );
  return completedResponse(response, {
    ...params,
    notification: { success: true },
  });
};

export interface CloneCourse {
  uuid: string;
}

export const cloneCourse = async (params: IBaseParameter<CloneCourse>) => {
  params.hasLoading && store.dispatch(showLoading());
  const response = await axiosClient.post(
    endPoints.cloneCourse(),
    params.params
  );
  return completedResponse(response, { ...params });
};

export const getPageListCourse = (params: PayloadPageListCourse) => {
  return axiosClient.get(endPoints.getPageListCourse(params));
};

export const restoreCourse = async (
  params: IBaseParameter<IGetElementById>
) => {
  params.hasLoading && store.dispatch(showLoading());
  const response = await axiosClient.post(
    endPoints.restoreCourse(),
    params.params
  );
  return completedResponse(response, { ...params });
};

export const shareCourse = (params: IGetElementById) => {
  return axiosClient.post(endPoints.shareCourse(), params);
};

export const getCourseById = (params: string) => {
  return axiosClient.get(endPoints.getCourseDetail(params));
};

// Membership

export const getCourseByGroupDeleted = () => {
  return axiosClient.get(endPoints.getCourseByGroupGarbage());
};

export const getHtmlTemplate = (landingFileUrl: string) => {
  return axiosClient.get(API.fetchHtmlTemplate(landingFileUrl));
};

export const getDetailCourse = (params: { uuid: string }) =>
  axiosClient.get(API.getDetail, { params });

export const postUpdateSection = (
  courseUuid: string,
  sectionUuid: string,
  payload: { name: string }
) => {
  return axiosClient.post(
    API.postUpdateSection(courseUuid, sectionUuid),
    payload
  );
};

export const postDeleteSection = (courseUuid: string, sectionUuid: string) => {
  return axiosClient.post(API.postDeleteSection(courseUuid, sectionUuid));
};

export const postCreateSection = (
  courseUuid: string,
  payload: { name: string }
) => {
  return axiosClient.post(API.postCreateSection(courseUuid), payload);
};

export const postCreateLessons = (
  courseUuid: string,
  sectionUuid: string,
  payload: { name: string }
) => {
  return axiosClient.post(
    API.postCreateLessons(courseUuid, sectionUuid),
    payload
  );
};

export const getShowLessons = (
  courseUuid: string,
  sectionUuid: string,
  lessonUuid: string
) => {
  return axiosClient.get(
    API.getShowLessons(courseUuid, sectionUuid, lessonUuid)
  );
};

export const postUpdateLessons = (
  courseUuid: string,
  sectionUuid: string,
  lessonUuid: string,
  payload: IParamsUpdateLesson
) => {
  return axiosClient.post(
    API.postUpdateLessons(courseUuid, sectionUuid, lessonUuid),
    payload
  );
};

export const postDuplicateLessons = (
  courseUuid: string,
  sectionUuid: string,
  lessonUuid: string
) => {
  return axiosClient.post(
    API.postDuplicateLessons(courseUuid, sectionUuid, lessonUuid)
  );
};

export const postDeleteLessons = (
  courseUuid: string,
  sectionUuid: string,
  lessonUuid: string
) => {
  return axiosClient.post(
    API.postDeleteLessons(courseUuid, sectionUuid, lessonUuid)
  );
};

export const postChangePositionSection = (
  courseUuid: string,
  sectionUuid: string,
  newPosition: number
) => {
  return axiosClient.post(
    API.postChangePositionSection(courseUuid, sectionUuid, newPosition)
  );
};

export const postChangePositionLesson = (
  courseUuid: string,
  sectionUuid: string,
  lessonUuid: string,
  newPosition: number
) => {
  return axiosClient.post(
    API.postChangePositionLesson(
      courseUuid,
      sectionUuid,
      lessonUuid,
      newPosition
    )
  );
};

export const getDataTemplate = (params: IPramsDataTemplate) =>
  axiosClient.get(API.getDataTemplate, { params });

export const getListUserCourse = (params: IPramsListUserCourse) =>
  axiosClient.get(API.getListUserCourse, { params });

export const postChangeActive = (payLoad: IPayloadChangeActive) =>
  axiosClient.post(API.postChangeActive, payLoad);

export const postDownLoadCSV = (payLoad: IPayloadDownLoadCSV) =>
  axiosClient.post(API.postDownLoadCSV, payLoad);

export const getStatusImportCSV = (params: { token: string }) =>
  axiosClient.get(API.getStatusImportCSV, { params });

export const membersiteServices = {
  updateMembersite,
  deleteCourse,
  getSetting,
  installTemplate,
  renameMembersite,
  getAllTemplates,
  renameStep,
  createStepMembersite,
  removeStepMembersite,
  handleChangeTemplateURL,
  submitSettingStepMembersite,
  restoresSettingStep,
  fetchListAccessLink,
  createAccessLink,
  //get html page template
  getHtmlTemplate,
};
