import styled from 'styled-components';

const WrapperTable = styled.table`
  .thead {
    tr {
      border-bottom: 1px solid var(--t-border-color) !important;
      margin-top: 5px;
    }
  }
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  color: var(--t-text-color);

  tr {
    padding: 0.35em;
    border-bottom: 1px solid transparent !important;
    &:not(:last-child) {
      border-bottom: 1px solid var(--t-border-color) !important;
    }
  }

  th,
  td {
    padding: 0.625em;
    font-size: 0.85em;
    text-align: left;
    border-color: var(--t-border-color) !important;
  }

  th {
    font-size: 0.85em;
    letter-spacing: 0.1em;
  }

  @media screen and (max-width: 600px) {
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
    }
    td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 0.8em;
      text-align: left;
      &:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
`;

const ResponsiveWrapper = styled.div`
  width: 100%;
  padding: 5px 10px;

  .anticon-star {
    svg {
      margin: -3px 0 0 0;
    }
  }
`;

export { WrapperTable, ResponsiveWrapper };
