import React, { useEffect, useState } from 'react';

import './style.scss';
import IconSearch from './../../../../../assets/img/menu-icons/search-icon.png';

const ENTER_KEY = 'Enter';

interface InputSearchProps {
  onSubmit: (textSearch: string) => void;
  isClearInput: boolean;
}

const InputSearch: React.FC<InputSearchProps> = ({
  onSubmit,
  isClearInput,
}) => {
  const [value, setValue] = useState<string>('');
  const [preTextSearch, setPreTextSearch] = useState<any>('');

  const handleSearch = () => {
    if (preTextSearch !== value.trim()) {
      onSubmit(value || '');
    }

    setPreTextSearch(value?.trim());
  };

  useEffect(() => {
    if (isClearInput) {
      setPreTextSearch(undefined);
    }
  }, [isClearInput]);

  return (
    <div className="d-flex align-item-center wrapped-element input-filter search-input-all">
      <div className="search-input-content">
        <img onClick={handleSearch} src={IconSearch} alt="" />
        <input
          type="text"
          value={value}
          onChange={(e: any) => {
            setValue(e.target.value);
          }}
          onKeyPress={(e: any) => {
            if (e.key === ENTER_KEY) {
              handleSearch();
              setValue(e.target.value?.trim());
            }
          }}
          placeholder="検索"
        />
      </div>
    </div>
  );
};

export default InputSearch;
