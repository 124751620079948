import moment from 'moment';
import { DTimeFormatMinus } from '../../../constants/app.constants';
import {
  IEventOnSchedule,
  ISchedule,
} from '../../../store/reducers/event/interface';

export interface ISettingScheduleProps {
  listEvent: IEventOnSchedule[];
  startWeek: string;
  endWeek: string;
  setTime: (type: ESelectTime) => void;
  handleCopyImportGoogle: () => void;
}

export interface IDropCalendarProps {
  startWeek: string;
  endWeek: string;
}

export interface IDragScheduleProps {
  scheduleUuid: string;
  left: number;
  top: number;
  width?: number | string;
  zIndex?: number;
  isDisable: boolean;
  getFirstCell: () => ICoordinateFirstCell;
}

export interface IPositionsPopover {
  left: number;
  top: number;
}

export interface IDragEventProps {
  eventUuid: string;
}

export enum EItemTypes {
  EVENT_TO_CALENDAR = 'EVENT_TO_CALENDAR',
  SCHEDULE_TO_CALENDAR = 'SCHEDULE_TO_CALENDAR',
}

export enum ESelectTime {
  NOW = 'NOW',
  PREV = 'PREV',
  NEXT = 'NEXT',
}

export enum ESchedule {
  NEW = 'NEW',
  OLD = 'OLD',
}

export interface ICoordinateFirstCell {
  x: number;
  y: number;
  top: number;
  left: number;
  bottom: number;
  right: number;
  width: number;
  height: number;
}

export interface ICoordinate {
  x: number;
  y: number;
}

export interface dateMenu {
  date: string;
  index: number;
}

export const dateWeek: dateMenu[] = [
  {
    date: 'sunday',
    index: 0,
  },
  {
    date: 'monday',
    index: 1,
  },
  {
    date: 'tuesday',
    index: 2,
  },
  {
    date: 'wednesday',
    index: 3,
  },
  {
    date: 'thursday',
    index: 4,
  },
  {
    date: 'friday',
    index: 5,
  },
  {
    date: 'saturday',
    index: 6,
  },
];

export enum ESuffixOverDay {
  FIRST = '_d1',
  SECOND = '_d2',
  THIRD = '_d3',
}

export function getDayOfWeek(startWeek?: string): string[] {
  const startOfWeek = startWeek ? moment(startWeek) : moment().startOf('week'); //return sunday
  const dateOfWeek = [startOfWeek.format(DTimeFormatMinus)];
  for (let i = 0; i < 6; i++) {
    dateOfWeek.push(startOfWeek.add(1, 'days').format(DTimeFormatMinus));
  }
  return dateOfWeek;
}

export const DEFAULT_CELL = {
  height: 50,
  widthRatio: 13.5,
  widthHourRatio: 5.5,
  perMinute: 60,
  perMinuteGrid: 15,
  scrollToHour: 14,
  zIndex: 50,
};

export function getGrid(
  distanceBorder: number | undefined | null,
  currentCoordinate: number | undefined | null,
  lengthGrid: number | undefined | null
): number {
  if (
    typeof distanceBorder === 'number' &&
    typeof currentCoordinate === 'number' &&
    typeof lengthGrid === 'number'
  ) {
    return (
      Math.floor((currentCoordinate - distanceBorder) / lengthGrid) *
        lengthGrid +
      distanceBorder
    );
  }
  return 0;
}

export function getWidthCellFromFirstCell(
  widthFirstCell: number | undefined | null
): number {
  if (widthFirstCell)
    return (
      (widthFirstCell * DEFAULT_CELL.widthRatio) / DEFAULT_CELL.widthHourRatio
    );
  return 0;
}

export function getDayAndHourFromCoordinate(
  width: number,
  distanceX: number,
  distanceY: number,
  currentX: number,
  currentY: number
): { day: number; hour: number } {
  const x = Math.floor((currentX + 1 - distanceX) / width);
  const y =
    Math.floor(
      ((currentY - distanceY) *
        (DEFAULT_CELL.perMinute / DEFAULT_CELL.perMinuteGrid)) /
        DEFAULT_CELL.height
    ) /
    (DEFAULT_CELL.perMinute / DEFAULT_CELL.perMinuteGrid);
  return { day: x, hour: y };
}

interface IParamGetTimeByCoordinate {
  width: number;
  distanceX: number;
  distanceY: number;
  currentX: number;
  currentY: number;
  startWeek: string;
}

export function getTimeByCoordinate({
  width,
  distanceX,
  distanceY,
  currentX,
  currentY,
  startWeek,
}: IParamGetTimeByCoordinate): string {
  const { day, hour } = getDayAndHourFromCoordinate(
    width,
    distanceX,
    distanceY,
    currentX,
    currentY
  );
  return moment(startWeek)
    .add(day, 'day')
    .add(hour, 'hour')
    .format(DTimeFormatMinus);
}

export const getDayAndHourByTime = (
  time: string | Date
): { day: number; hour: number } => {
  const dateStart = moment(time);
  const day = dateStart.day();
  const hour = dateStart.hour() + dateStart.minute() / DEFAULT_CELL.perMinute;
  return { day, hour };
};

export const getGridFromCoordinate = (
  distanceBorder: number,
  currentCoordinate: number,
  lengthGrid: number
): number => currentCoordinate * lengthGrid + distanceBorder;

export const isSmallerDayOfTime = (
  firstTime: string,
  secondTime: string
): boolean => moment(firstTime).isBefore(secondTime, 'date');

export const splitScheduleRecursive = (
  listChildSchedule: ISchedule[],
  schedule: ISchedule,
  index: number,
  startWeek: string,
  endWeek: string
): ISchedule[] => {
  // this function check final childSchedule not origin schedule was initially passed in
  // when childSchedule over week don't need make more childSchedule
  // and childSchedule ends at 00h00 -> schedule ends at 23h59 previous day
  if (
    listChildSchedule.length &&
    schedule?.localEndDate &&
    (moment(schedule.localEndDate).isSame(schedule.end_date, 'day') ||
      moment(schedule.localEndDate).isSame(endWeek, 'day') ||
      (moment(schedule.end_date).hour() === 0 &&
        moment(schedule.end_date).minute() === 0 &&
        moment(schedule.localEndDate)
          .add(1, 'minute')
          .isSame(schedule.end_date)))
  ) {
    return listChildSchedule;
  }

  // add new child schedule
  const newSchedule = { ...schedule };
  switch (index) {
    case 0:
      newSchedule.schedule_uuid =
        getScheduleUuidOriginal(schedule.schedule_uuid) + ESuffixOverDay.FIRST;
      break;
    case 1:
      newSchedule.schedule_uuid =
        getScheduleUuidOriginal(schedule.schedule_uuid) + ESuffixOverDay.SECOND;
      break;
    case 2:
      newSchedule.schedule_uuid =
        getScheduleUuidOriginal(schedule.schedule_uuid) + ESuffixOverDay.THIRD;
      break;
    default:
      return listChildSchedule;
  }

  newSchedule.localStartDate = moment(schedule.start_date)
    .add(index, 'day')
    .startOf('day')
    .format(DTimeFormatMinus);
  if (moment(newSchedule.localStartDate).isSame(schedule.start_date, 'day'))
    newSchedule.localStartDate = schedule.start_date;

  newSchedule.localEndDate = moment(schedule.start_date)
    .add(index, 'day')
    .endOf('day')
    .format(DTimeFormatMinus);
  if (moment(newSchedule.localEndDate).isSame(schedule.end_date, 'day'))
    newSchedule.localEndDate = schedule.end_date;

  if (moment(newSchedule.localStartDate).isSameOrAfter(startWeek, 'day'))
    listChildSchedule.push(newSchedule);
  return splitScheduleRecursive(
    listChildSchedule,
    newSchedule,
    index + 1,
    startWeek,
    endWeek
  );
};

export const splitSchedule = (
  schedule: ISchedule,
  startWeek: string,
  endWeek: string
) => {
  if (moment(schedule.start_date).isSame(schedule.end_date, 'day'))
    return [{ ...schedule }];
  else return splitScheduleRecursive([], schedule, 0, startWeek, endWeek);
};

export const getHeightSchedule = (
  start_date: string,
  end_date: string
): string =>
  (moment(end_date).diff(start_date, 'minute') * DEFAULT_CELL.height) /
    DEFAULT_CELL.perMinute +
  'px';

export const isScheduleOverDay = (uuid: string) =>
  uuid.includes(ESuffixOverDay.FIRST) ||
  uuid.includes(ESuffixOverDay.SECOND) ||
  uuid.includes(ESuffixOverDay.THIRD);

export const getScheduleUuidOriginal = (uuid: string): string => {
  if (isScheduleOverDay(uuid)) {
    return getScheduleUuidOriginal(uuid.slice(0, -3));
  }
  return uuid;
};

export function deleteSchedulesById(
  schedule_uuid: string,
  listSchedules: ISchedule[]
) {
  for (let i = listSchedules.length - 1; i >= 0; i -= 1) {
    if (listSchedules[i].schedule_uuid.includes(schedule_uuid)) {
      listSchedules.splice(i, 1);
    }
  }
}

export const getScheduleByDay = (listSchedules: ISchedule[]) => {
  const listSchedulesByDay: ISchedule[][] = [];
  listSchedules.forEach((schedule: ISchedule) => {
    const { day } = getDayAndHourByTime(
      schedule.localStartDate || schedule.start_date
    );
    if (!listSchedulesByDay[day]) listSchedulesByDay[day] = [];
    listSchedulesByDay[day].push(schedule);
  });
  return listSchedulesByDay;
};

export const sortScheduleByStartDate = (listSchedules: ISchedule[][]) => {
  const newListSchedules = structuredClone(listSchedules);
  newListSchedules.forEach((eachListSchedule: ISchedule[]) => {
    return eachListSchedule.sort((a: ISchedule, b: ISchedule) => {
      const diffTimeStart =
        new Date(a.localStartDate || a.start_date).getTime() -
        new Date(b.localStartDate || b.start_date).getTime();
      if (diffTimeStart !== 0) return diffTimeStart;
      return -(
        new Date(a.localEndDate || a.end_date).getTime() -
        new Date(b.localEndDate || b.end_date).getTime()
      );
    });
  });
  return newListSchedules;
};

export const mergeSchedule = (listSchedules: ISchedule[][]) => {
  const newListSchedules = structuredClone(listSchedules);
  const newListSchedulesMerged: ISchedule[] = [];
  newListSchedules.forEach((eachListSchedule: ISchedule[]) => {
    eachListSchedule.forEach((schedule: ISchedule) => {
      newListSchedulesMerged.push(schedule);
    });
  });
  return newListSchedulesMerged;
};

export interface IIndexCluster {
  indexStart: number;
  indexEnd: number;
}

export const findCluster = (listSchedule: ISchedule[]) => {
  const listCluster: IIndexCluster[] = [];
  let indexStart = 0;
  let index = 0;
  let indexEnd = 1;
  let maxTimeEnd = 0;
  while (index < listSchedule.length && indexEnd <= listSchedule.length) {
    if (indexEnd === listSchedule.length) {
      if (indexStart !== indexEnd - 1)
        listCluster.push({ indexStart, indexEnd: index });
      break;
    }

    const newTimeEnd = new Date(
      listSchedule[index]?.localEndDate || listSchedule[index].end_date
    ).getTime();
    if (maxTimeEnd < newTimeEnd) maxTimeEnd = newTimeEnd;
    if (
      maxTimeEnd >
      new Date(
        listSchedule[indexEnd]?.localStartDate ||
          listSchedule[indexEnd].start_date
      ).getTime()
    ) {
      indexEnd += 1;
      index += 1;
      continue;
    }

    if (indexStart !== indexEnd - 1)
      listCluster.push({ indexStart, indexEnd: index });

    indexStart = indexEnd;
    index = indexEnd;
    indexEnd += 1;
  }

  return listCluster;
};

export const calculateWidthAndZIndexByCluster = (
  listSchedules: ISchedule[][]
) => {
  const newListSchedules = structuredClone(listSchedules);
  newListSchedules.forEach((eachListSchedule: ISchedule[]) => {
    if (eachListSchedule.length === 1) return;
    const listCluster = findCluster(eachListSchedule);
    listCluster.map((cluster: IIndexCluster) => {
      const length = cluster.indexEnd - cluster.indexStart + 1;
      for (let i = cluster.indexStart; i <= cluster.indexEnd; i += 1) {
        eachListSchedule[i].width =
          (cluster.indexEnd - i + 1 + 1) / (length + 1);
        eachListSchedule[i].zIndex =
          cluster.indexEnd - cluster.indexStart + i + DEFAULT_CELL.zIndex;
      }
    });
  });
  return newListSchedules;
};
