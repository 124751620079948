import moment from 'moment';
import * as Types from '../../actions/event/types';
import {
  IManagerEvent,
  IParamsAddScheduleSuccess,
  IParamsSchedulesUuid,
  IParamsMoveScheduleFail,
  ISchedule,
  initialState,
} from './interface';
import {
  deleteSchedulesById,
  getScheduleUuidOriginal,
  splitSchedule,
} from '../../../containers/Calendar/SettingSchedule/config';

export default function eventReducer(
  state: IManagerEvent = initialState,
  action: any
): IManagerEvent {
  switch (action.type) {
    case Types.CREATE_SCHEDULE:
      return {
        ...state,
        listSchedules: [...state.listSchedules, action.schedule],
      };

    case Types.MOVE_SCHEDULE:
      const newListSchedule = structuredClone(state.listSchedules);
      const index = newListSchedule.findIndex(
        (thisSchedule) =>
          thisSchedule.schedule_uuid === action.schedule.schedule_uuid
      );
      newListSchedule.splice(index, 1, action.schedule);
      return {
        ...state,
        listSchedules: [...newListSchedule],
      };

    case Types.ADD_SCHEDULE_SUCCESS: {
      action as IParamsAddScheduleSuccess;
      const newListSchedule = structuredClone(state.listSchedules);
      const updateSchedule = newListSchedule.find(
        (thisSchedule) =>
          thisSchedule.schedule_uuid === action.old_schedule_uuid
      );
      if (updateSchedule) {
        updateSchedule.isWaiting = false;
        updateSchedule.schedule_uuid = action.new_schedule_uuid;
        updateSchedule.participants = action.participants;
        updateSchedule.count_user_calendars = 0;
      }
      return {
        ...state,
        listSchedules: [...newListSchedule],
      };
    }

    case Types.MOVE_SCHEDULE_SUCCESS: {
      action as IParamsAddScheduleSuccess;
      const newListSchedule = structuredClone(state.listSchedules);
      const updateSchedule = newListSchedule.find(
        (thisSchedule) => thisSchedule.schedule_uuid === action.schedule_uuid
      );
      if (updateSchedule) {
        updateSchedule.isWaiting = false;
      }
      return {
        ...state,
        listSchedules: [...newListSchedule],
      };
    }

    case Types.DELETE_SCHEDULE_LOCAL: {
      action as IParamsSchedulesUuid;
      const newListSchedule = structuredClone(state.listSchedules);
      deleteSchedulesById(action.schedule_uuid, newListSchedule);
      return {
        ...state,
        listSchedules: [...newListSchedule],
      };
    }

    case Types.MOVE_SCHEDULE_FAIL: {
      action as IParamsMoveScheduleFail;
      const newListSchedule = structuredClone(state.listSchedules);
      const updateSchedule = newListSchedule.find(
        (thisSchedule) => thisSchedule.schedule_uuid === action.schedule_uuid
      );
      if (updateSchedule) {
        updateSchedule.isWaiting = false;
        updateSchedule.start_date = action.oldTimeStart;
        updateSchedule.end_date = action.oldTimeEnd;
      }
      return {
        ...state,
        listSchedules: [...newListSchedule],
      };
    }

    case Types.GET_LIST_GROUP_SUCCESS:
      return {
        ...state,
        listGroup: action.data,
      };
    case Types.GET_LIST_EVENT_SUCCESS:
      return {
        ...state,
        listEvent: action.data,
      };
    case Types.GET_LIST_EVENT_BY_GROUPS_SUCCESS:
      return {
        ...state,
        nameGroup: action.data.name,
        listEventByGroups: action.data,
      };
    case Types.GET_SCHEDULES_BY_WEEK_SUCCESS: {
      const newListSchedule: ISchedule[] = [];
      action.data.map((schedule: ISchedule) => {
        if (moment(schedule.start_date).isSame(schedule.end_date, 'day'))
          return newListSchedule.push(schedule);
        return newListSchedule.push(
          ...splitSchedule(schedule, action.startWeek, action.endWeek)
        );
      });
      return {
        ...state,
        listSchedules: newListSchedule.map((schedule: ISchedule) => ({
          isWaiting: false,
          ...schedule,
        })),
        startWeek: action.startWeek,
        endWeek: action.endWeek,
      };
    }

    case Types.CREATE_SCHEDULE_OVER_DAY:
      return {
        ...state,
        listSchedules: [...state.listSchedules, ...action.listChildSchedule],
      };
    case Types.CREATE_SCHEDULE_OVER_DAY_SUCCESS: {
      action as IParamsAddScheduleSuccess;
      const newListSchedule = structuredClone(state.listSchedules);
      const old_schedule_uuid = action.old_schedule_uuid.slice(0, -3);

      newListSchedule.forEach((thisSchedule) => {
        if (thisSchedule.schedule_uuid.includes(old_schedule_uuid)) {
          thisSchedule.isWaiting = false;
          const suffix = thisSchedule.schedule_uuid.slice(-3);
          thisSchedule.schedule_uuid = action.new_schedule_uuid + suffix;
          thisSchedule.participants = action.participants;
          thisSchedule.count_user_calendars = 0;
        }
      });
      return {
        ...state,
        listSchedules: [...newListSchedule],
      };
    }
    case Types.CREATE_SCHEDULE_OVER_DAY_FAIL: {
      action as IParamsSchedulesUuid;
      const newListSchedule = structuredClone(state.listSchedules);
      const schedule_uuid = action.schedule_uuid.slice(0, -3);
      deleteSchedulesById(schedule_uuid, newListSchedule);
      return {
        ...state,
        listSchedules: [...newListSchedule],
      };
    }

    case Types.HIDE_REST_SCHEDULE_OVER_DAY: {
      action as IParamsSchedulesUuid;
      const newListSchedule = structuredClone(state.listSchedules);
      const schedule_uuid = action.schedule_uuid.slice(0, -3);
      newListSchedule.forEach((schedule: ISchedule) => {
        if (
          schedule.schedule_uuid.includes(schedule_uuid) &&
          schedule.schedule_uuid !== action.schedule_uuid
        ) {
          schedule.isHide = true;
        }
      });
      return {
        ...state,
        listSchedules: [...newListSchedule],
      };
    }
    case Types.SHOW_REST_SCHEDULE_OVER_DAY: {
      action as IParamsSchedulesUuid;
      const newListSchedule = structuredClone(state.listSchedules);
      const schedule_uuid = action.schedule_uuid.slice(0, -3);
      newListSchedule.forEach((schedule: ISchedule) => {
        if (
          schedule.schedule_uuid.includes(schedule_uuid) &&
          schedule.schedule_uuid !== action.schedule_uuid
        ) {
          schedule.isHide = false;
        }
      });
      return {
        ...state,
        listSchedules: [...newListSchedule],
      };
    }

    case Types.MOVE_SCHEDULE_OVER_DAY: {
      const newListSchedule = structuredClone(state.listSchedules);
      const schedule_uuid = getScheduleUuidOriginal(
        action.listChildSchedule[0].schedule_uuid
      );
      deleteSchedulesById(schedule_uuid, newListSchedule);
      newListSchedule.push(...action.listChildSchedule);
      return {
        ...state,
        listSchedules: [...newListSchedule],
      };
    }

    case Types.MOVE_SCHEDULE_OVER_DAY_SUCCESS: {
      action as IParamsSchedulesUuid;
      const newListSchedule = structuredClone(state.listSchedules);
      const old_schedule_uuid = getScheduleUuidOriginal(action.schedule_uuid);

      newListSchedule.forEach((thisSchedule) => {
        if (thisSchedule.schedule_uuid.includes(old_schedule_uuid)) {
          thisSchedule.isWaiting = false;
        }
      });
      return {
        ...state,
        listSchedules: [...newListSchedule],
      };
    }
    case Types.MOVE_SCHEDULE_OVER_DAY_FAIL: {
      action as IParamsSchedulesUuid;
      const newListSchedule = structuredClone(state.listSchedules);
      const schedule_uuid = getScheduleUuidOriginal(action.schedule_uuid);
      deleteSchedulesById(schedule_uuid, newListSchedule);
      newListSchedule.push(...action.listOldChildSchedule);
      return {
        ...state,
        listSchedules: [...newListSchedule],
      };
    }
    case Types.CLEAR_SETTING_SCHEDULE: {
      return {
        ...state,
        listSchedules: initialState.listSchedules,
        listEventByGroups: initialState.listEventByGroups,
      };
    }
    default:
      return state;
  }
}
