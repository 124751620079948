import * as Types from '../../actions/membersite/types';
import { initialSummaryCourse, IStateSummaryCourse } from './interfaces';

export default function summaryMembersiteReducer(
  state: IStateSummaryCourse = initialSummaryCourse,
  action: any
): IStateSummaryCourse {
  switch (action.type) {
    case Types.FETCH_SUMMARY_MEMBERSITE: {
      return { ...state };
    }
    case Types.FETCH_SUMMARY_MEMBERSITE_SUCCESS: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case Types.FETCH_SUMMARY_MEMBERSITE_FAILS: {
      return {
        ...state,
        error: action.error,
      };
    }
    default:
      return { ...state };
  }
}
