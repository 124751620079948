export const FETCH_USER_ON_UPDATE_PAGE = 'FETCH_USER_ON_UPDATE_PAGE';
export const FETCH_USER_ON_UPDATE_PAGE_SUCCESS =
  'FETCH_USER_ON_UPDATE_PAGE_SUCCESS';
export const FETCH_USER_ON_UPDATE_PAGE_ERROR =
  'FETCH_USER_ON_UPDATE_PAGE_ERROR';

export const UPDATE_INFO_USER = 'UPDATE_INFO_USER';
export const UPDATE_INFO_USER_SUCCESS = 'UPDATE_INFO_USER_SUCCESS';
export const UPDATE_INFO_USER_ERROR = 'UPDATE_INFO_USER_ERROR';

export const FETCH_STATISTICS_USER = 'FETCH_STATISTICS_USER';
export const FETCH_STATISTICS_USER_SUCCESS = 'FETCH_STATISTICS_USER_SUCCESS';
export const FETCH_STATISTICS_USER_ERROR = 'FETCH_STATISTICS_USER_ERROR';

export const FETCH_MAIL_LIST = 'FETCH_MAIL_LIST';
export const FETCH_MAIL_LIST_SUCCESS = 'FETCH_MAIL_LIST_SUCCESS';
export const FETCH_MAIL_LIST_ERROR = 'FETCH_MAIL_LIST_ERROR';

export const FETCH_ALL_PAYMENT_METHOD = 'FETCH_ALL_PAYMENT_METHOD';
export const FETCH_ALL_PAYMENT_METHOD_SUCCESS =
  'FETCH_ALL_PAYMENT_METHOD_SUCCESS';
export const FETCH_ALL_PAYMENT_METHOD_ERROR = 'FETCH_ALL_PAYMENT_METHOD_ERROR';

export const EDIT_PAYMENT_METHOD = 'EDIT_PAYMENT_METHOD';
export const EDIT_PAYMENT_METHOD_SUCCESS = 'EDIT_PAYMENT_METHOD_SUCCESS';
export const EDIT_PAYMENT_METHOD_ERROR = 'EDIT_PAYMENT_METHOD_ERROR';

export const EDIT_PAYMENT_STATUS = 'EDIT_PAYMENT_STATUS';
export const EDIT_PAYMENT_STATUS_SUCCESS = 'EDIT_PAYMENT_STATUS_SUCCESS';
export const EDIT_PAYMENT_STATUS_ERROR = 'EDIT_PAYMENT_STATUS_ERROR';

export const EDIT_NAME_MAIL = 'EDIT_NAME_MAIL';
export const EDIT_NAME_MAIL_SUCCESS = 'EDIT_NAME_MAIL_SUCCESS';
export const EDIT_NAME_MAIL_ERROR = 'EDIT_NAME_MAIL_ERROR';

export const REMOVE_USER_FORM_MAIL_LIST = 'REMOVE_USER_FORM_MAIL_LIST';
export const REMOVE_USER_FORM_MAIL_LIST_SUCCESS =
  'REMOVE_USER_FORM_MAIL_LIST_SUCCESS';
export const REMOVE_USER_FORM_MAIL_LIST_ERROR =
  'REMOVE_USER_FORM_MAIL_LIST_ERROR';

export const STOP_SEND_STEP_MAIL = 'STOP_SEND_STEP_MAIL';
export const STOP_SEND_STEP_MAIL_SUCCESS = 'STOP_SEND_STEP_MAIL_SUCCESS';
export const STOP_SEND_STEP_MAIL_ERROR = 'STOP_SEND_STEP_MAIL_ERROR';

export const FETCH_DATA_BLACK_LIST = 'FETCH_DATA_BLACK_LIST';
export const FETCH_DATA_BLACK_LIST_SUCCESS = 'FETCH_DATA_BLACK_LIST_SUCCESS';
export const FETCH_DATA_BLACK_LIST_ERROR = 'FETCH_DATA_BLACK_LIST_ERROR';

export const DELETE_MAIL_TO_BLACK_LIST = 'DELETE_MAIL_TO_BLACK_LIST';
export const DELETE_MAIL_TO_BLACK_LIST_SUCCESS =
  'DELETE_MAIL_TO_BLACK_LIST_SUCCESS';
export const DELETE_MAIL_TO_BLACK_LIST_ERROR =
  'DELETE_MAIL_TO_BLACK_LIST_ERROR';

export const ADD_MAIL_TO_BLACK_LIST = 'ADD_MAIL_TO_BLACK_LIST';
export const ADD_MAIL_TO_BLACK_LIST_SUCCESS = 'ADD_MAIL_TO_BLACK_LIST_SUCCESS';
export const ADD_MAIL_TO_BLACK_LIST_ERROR = 'ADD_MAIL_TO_BLACK_LIST_ERROR';

export const CALL_API_ERROR = 'CALL_API_ERROR';

export const CLOSE_POPUP_ERROR_ADD_EMAIL_BLACKLIST =
  'CLOSE_POPUP_ERROR_ADD_EMAIL_BLACKLIST';

export const SHOW_LOADING_GET_PAYMENT_METHOD =
  'SHOW_LOADING_GET_PAYMENT_METHOD';
export const HIDE_LOADING_GET_PAYMENT_METHOD =
  'HIDE_LOADING_GET_PAYMENT_METHOD';

export const GET_MAILS_LIST = 'GET_MAILS_LIST';
export const GET_MAILS_LIST_SUCCESS = 'GET_MAILS_LIST_SUCCESS';

export const GET_LIST_PRODUCTS = 'GET_LIST_PRODUCTS';
export const GET_LIST_PRODUCTS_SUCCESS = 'GET_LIST_PRODUCTS_SUCCESS';

export const GET_GROUP_NAME_PRODUCT = 'GET_GROUP_NAME_PRODUCT';
export const GET_GROUP_NAME_PRODUCT_SUCCESS = 'GET_GROUP_NAME_PRODUCT_SUCCESS';

export const GET_GROUP_NAME_MAIL = 'GET_GROUP_NAME_MAIL';
export const GET_GROUP_NAME_MAIL_SUCCESS = 'GET_GROUP_NAME_MAIL_SUCCESS';

export const GET_LIST_PRODUCT_BY_GROUP = 'GET_LIST_PRODUCT_BY_GROUP';
export const GET_LIST_PRODUCT_BY_GROUP_SUCCESS =
  'GET_LIST_PRODUCT_BY_GROUP_SUCCESS';

export const GET_LIST_MAIL_BY_GROUP = 'GET_LIST_MAIL_BY_GROUP';
export const GET_LIST_MAIL_BY_GROUP_SUCCESS = 'GET_LIST_MAIL_BY_GROUP_SUCCESS';

export const SET_LIST_ITEM_SELECT = 'SET_LIST_ITEM_SELECT';
export const GENERATE_COMPLETE_PAYMENT_LINK = 'GENERATE_COMPLETE_PAYMENT_LINK';

export const GET_LIST_USER = 'GET_LIST_USER';

export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
export const STOP_REMIND_MAIL = 'STOP_REMIND_MAIL';
