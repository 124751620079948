import { Col, Row, Input, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import './style.scss';

interface HeaderTemplatesProps {
  handleFilterByName: (keyWord: string) => void;
  valueNameDefault: string;
}

const HeaderPageTemplates: React.FC<HeaderTemplatesProps> = ({
  handleFilterByName,
  valueNameDefault,
}) => {
  const { t } = useTranslation();
  const [keyWord, setKeyWord] = useState<string>(valueNameDefault);

  useEffect(() => {
    setKeyWord(valueNameDefault);
  }, [valueNameDefault]);

  const handleOnSearch = (params: string) => {
    handleFilterByName(params);
  };
  return (
    <Wrapper align="middle">
      <Col flex="auto" className="header">
        <div className="header_element">
          <div>{t('membersiteTemplate.list.title')}</div>
          <div>Course template management</div>
        </div>
      </Col>
      <Col flex="250px" className="filter-name">
        <Space direction="vertical" className="w-100">
          <Input
            value={keyWord}
            placeholder={t(
              'templateDetail.memberSite.list.placeholderSearchName'
            )}
            allowClear
            prefix={
              <SearchOutlined
                className="site-form-item-icon"
                onClick={() => handleOnSearch(keyWord)}
              />
            }
            onChange={(e) => {
              setKeyWord(e.target.value);
            }}
            onPressEnter={(e: any) => handleOnSearch(e.target.value)}
            autoComplete="off"
          />
        </Space>
      </Col>
    </Wrapper>
  );
};

const Wrapper = styled(Row)`
  background-color: white;
  border-radius: 15px;
  padding-right: 10px;
  .header {
    padding: 15px 0;
    &_element {
      padding: 0px 10px;
      border-left: 4px solid var(--t-main-color);
      div {
        color: var(--t-main-color);
        font-weight: bold;
        :last-child {
          font-size: 0.85rem;
        }
      }
    }
  }
  .filter-name {
    padding-left: 10px;
    .ant-input-affix-wrapper {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid var(--t-main-color);
    }
    .ant-input-affix-wrapper > input.ant-input {
      background-color: transparent;
    }
    svg {
      color: var(--t-main-color);
    }
    .ant-input::placeholder {
      color: var(--t-main-color);
    }
  }
`;

export default HeaderPageTemplates;
