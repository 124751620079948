import {
  dataToParamsEditNameMailList,
  dataToParamsRemoveUserFormMailList,
  dataToParamsStopSendStepMail,
} from './../../components/pages/ManageUser/MailList/Body/config';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  dataUserToParams,
  responseToDataUser,
} from './../../containers/ManageUser/EditUserInfo/contants';
import * as userServices from './../../Clients/users';
import * as mailService from './../../Clients/mail';
import * as productService from './../../Clients/products';
import * as Types from '../actions/manageUser/types';
import * as TypesLoading from './../actionTypes/loadingActionTypes';
import { TypeErrorUser } from '../reducers/manageUser/interface';
import { toast } from 'react-toastify';
import {
  handleDataSelectMulti,
  TYPE_FILTER,
} from '../../components/pages/ManageUser/ListUser/FilterListUser/config';
import { handleMessage } from '../../utilities/common.utilities';
import { EStatusHttp } from '../../Clients/interfaces';

interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
  error?: any;
}

const handleErrorUpdateUser = (error: any) => {
  switch (typeof error) {
    case 'string':
      toast.error(error);
      break;
    case 'object':
      toast.error(error.email[0]);
      break;
    default:
      break;
  }
};

function* fetchUserInfoUpdate(action: any) {
  const { uuid } = action;
  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      userServices.getUserUpdate(uuid)
    );
    const data = responseToDataUser(response.data.data);
    yield put({ type: TypesLoading.HIDE_LOADING });
    yield put({
      type: Types.FETCH_USER_ON_UPDATE_PAGE_SUCCESS,
      data: data,
      error: TypeErrorUser.NOT_ERROR,
    });
  } catch (e: any) {
    yield put({
      type: Types.FETCH_USER_ON_UPDATE_PAGE_ERROR,
      error: TypeErrorUser.FETCH_DATA,
      messageError: e.response.data.message,
    });
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* updateInfoUser(action: any) {
  const { params, callbackSuccess } = action;
  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      userServices.updateInfoUser(dataUserToParams(params))
    );

    yield put({ type: TypesLoading.HIDE_LOADING });
    yield put({
      type: Types.FETCH_USER_ON_UPDATE_PAGE_SUCCESS,
      data: params,
      error: TypeErrorUser.NOT_ERROR,
    });
    callbackSuccess(response.data.message);
  } catch (e: any) {
    handleMessage(e.response);
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* fetchDataStatisticsUser(action: any) {
  const { params, notLoading } = action;

  try {
    if (!notLoading) yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      userServices.getStatisticsUser(params)
    );

    yield put({
      type: Types.FETCH_STATISTICS_USER_SUCCESS,
      data: {
        ...response.data.data,
        typePage: params.type,
      },
    });
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    toast.error(e.response.data.message);
  }
}

function* fetchDataMailList(action: any) {
  const { params } = action;

  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      userServices.getMailList(params)
    );

    yield put({
      type: Types.FETCH_MAIL_LIST_SUCCESS,
      data: {
        ...response.data.data,
        typePage: params.type,
      },
    });
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    toast.error(e.response.data.message);
  }
}

function* fetchDataPaymentMethod(action: any) {
  const { id, idUser } = action;
  yield put({ type: Types.SHOW_LOADING_GET_PAYMENT_METHOD });

  try {
    const response: APIResponseType = yield call(() =>
      userServices.getAllPaymentMethod(id, idUser)
    );

    yield put({
      type: Types.FETCH_ALL_PAYMENT_METHOD_SUCCESS,
      data: response.data.data,
    });
    yield put({ type: Types.HIDE_LOADING_GET_PAYMENT_METHOD });
  } catch (e: any) {
    yield put({ type: Types.HIDE_LOADING_GET_PAYMENT_METHOD });
    toast.error(e.response.data.message);
  }
}

function* editPaymentMethod(action: any) {
  const { params, callbackSuccess } = action;

  const data = {
    pm_id: params.paymentMethodId,
    ppm_id: params.productPaymentMethodId,
  };
  yield put({ type: Types.SHOW_LOADING_GET_PAYMENT_METHOD });
  try {
    const response: APIResponseType = yield call(() =>
      userServices.editPaymentMethod(params, data)
    );

    toast.success(response.data.message);
    callbackSuccess();
    yield put({ type: Types.HIDE_LOADING_GET_PAYMENT_METHOD });
  } catch (e: any) {
    yield put({ type: Types.HIDE_LOADING_GET_PAYMENT_METHOD });
    toast.error(e.response.data.message || e.response.data.error);
  }
}

function* editPaymentStatus(action: any) {
  const { params, callbackSuccess } = action;

  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      userServices.editPaymentStatus(params)
    );

    callbackSuccess();
    toast.success(response.data.message);
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    toast.error(e.response.data.message);
  }
}

function* changeNameMailList(action: any) {
  const { params, fetchDataListMail } = action;
  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      userServices.editNameMail(dataToParamsEditNameMailList(params))
    );

    fetchDataListMail();
    toast.success(response.data.message);
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    handleErrorUpdateUser(e.response.data.error);
  }
}

function* removeUserFormMailList(action: any) {
  const { params, fetchDataListMail } = action;

  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      userServices.removeUserFormMailList(
        dataToParamsRemoveUserFormMailList(params)
      )
    );

    fetchDataListMail();
    toast.success(response.data.message);
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    toast.error(e.response.data.message);
  }
}

function* stopSendStepMail(action: any) {
  const { params, onSuccess } = action;
  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      userServices.stopSendStepMail(dataToParamsStopSendStepMail(params))
    );

    yield put({
      type: Types.STOP_SEND_STEP_MAIL_SUCCESS,
      data: {
        idStepMail: params.stepMailUuid,
      },
    });
    onSuccess();
    toast.success(response.data.message);
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    toast.error(e.response.data.message);
  }
}

function* fetchBlackList(action: any) {
  const { params } = action;
  try {
    if (!action.noLoading) yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      userServices.getBlackListUser(params)
    );

    yield put({
      type: Types.FETCH_DATA_BLACK_LIST_SUCCESS,
      data: response.data.data,
    });
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    toast.error(e.response.data.message);
  }
}

function* addMailToBlackList(action: any) {
  const { params } = action;
  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      userServices.addMailToBlackListUser(params)
    );
    yield put({ type: TypesLoading.HIDE_LOADING });
    yield put({
      type: Types.FETCH_DATA_BLACK_LIST,
      params: {
        page: 1,
        perPage: 20,
      },
      noLoading: true,
    });
    yield put({
      type: Types.ADD_MAIL_TO_BLACK_LIST_SUCCESS,
      numberEmail: response.data.data.rows,
      emailFails: response.data.data.failed,
    });
    if (response.data.data.failed.length === 0)
      toast.success(response.data.message);
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    if (e.response.data.error) {
      const arrayError = Object.values(e.response.data.error);
      arrayError.forEach((err: any) => {
        toast.error(err[0]);
      });
    }
  }
}

function* deleteMailToBlackList(action: any) {
  const { params } = action;

  try {
    yield put({ type: TypesLoading.SHOW_LOADING });
    const response: APIResponseType = yield call(() =>
      userServices.deleteMailToBlackListUser({ mail_uuid: params.uuidMail })
    );

    yield put({
      type: Types.FETCH_DATA_BLACK_LIST,
      params: {
        page: 1,
      },
      noLoading: true,
    });
    toast.success(response.data.message);
    yield put({ type: TypesLoading.HIDE_LOADING });

    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    yield put({ type: Types.CALL_API_ERROR, error: e.response.data.message });
  }
}

function* fetchMailsList() {
  try {
    const response: APIResponseType = yield call(() =>
      userServices.getMailsList()
    );

    yield put({
      type: Types.GET_MAILS_LIST_SUCCESS,
      data: response.data.data,
    });
  } catch (e: any) {
    toast.error(e.response.data.error);
  }
}

function* fetchListProducts() {
  try {
    const response: APIResponseType = yield call(() =>
      userServices.getProductList()
    );
    yield put({
      type: Types.GET_LIST_PRODUCTS_SUCCESS,
      data: response.data.data,
    });
  } catch (e: any) {
    toast.error(e.response.data.error);
  }
}

function* fetchGroupNameProduct() {
  try {
    const response: APIResponseType = yield call(() =>
      productService.getGroupName()
    );

    yield put({
      type: Types.GET_GROUP_NAME_PRODUCT_SUCCESS,
      data: response.data.data,
    });
  } catch (e: any) {
    toast.error(e.response.data.error);
  }
}

function* fetchGroupNameMail() {
  try {
    const response: APIResponseType = yield call(() =>
      mailService.fetchAllGroupMailList()
    );

    yield put({
      type: Types.GET_GROUP_NAME_MAIL_SUCCESS,
      data: response.data,
    });
  } catch (e: any) {
    toast.error(e.response.data.error);
  }
}

function* getMailListPageManageUser(action: any) {
  const { uuid } = action;
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(() =>
      userServices.getMailListPageManageUser(uuid)
    );

    yield put({
      type: Types.SET_LIST_ITEM_SELECT,
      data: handleDataSelectMulti(
        response.data.data.data.groups[0].mails_list,
        TYPE_FILTER.FUNNEL
      ),
    });
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({
      type: Types.SET_LIST_ITEM_SELECT,
      data: [],
    });
    toast.error(e.response.data.error);
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getProductListPageManageUser(action: any) {
  const { uuid } = action;
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(() =>
      userServices.getProductListPageManageUser(uuid)
    );

    yield put({
      type: Types.SET_LIST_ITEM_SELECT,
      data: handleDataSelectMulti(
        response.data.data.products[0]?.products,
        TYPE_FILTER.PRODUCT
      ),
    });
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (e: any) {
    yield put({
      type: Types.SET_LIST_ITEM_SELECT,
      data: [],
    });
    toast.error(e.response.data.error);
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* generateCompletePaymentLink(action: {
  type: string;
  data: any;
  onSuccess: (data: any) => void;
  onError: () => void;
}) {
  try {
    const response: APIResponseType = yield call(
      mailService.getGenerateCompletePaymentLink,
      action.data
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response);
    } else {
      action.onError();
    }
  } catch (error: any) {
    action.onError();
    handleMessage(error.response);
  }
}

function* getListUserAction(action: {
  type: string;
  payload: any;
  onSuccess: (data: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      userServices.getListUser,
      action.payload
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data);
    } else {
      handleMessage(response);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getUserDetail(action: { type: string; user_uuid: string }) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      userServices.getUserDetail,
      action.user_uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_USER_DETAIL_SUCCESS,
        data: response.data.data,
      });
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* stopRemindMail(action: {
  type: string;
  uuid: string;
  onSuccess: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      userServices.postStopRemindMail,
      action.uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      action.onSuccess();
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

export default function* manageUserSaga() {
  yield takeLatest(Types.FETCH_USER_ON_UPDATE_PAGE, fetchUserInfoUpdate);
  yield takeLatest(Types.UPDATE_INFO_USER, updateInfoUser);
  yield takeLatest(Types.FETCH_STATISTICS_USER, fetchDataStatisticsUser);
  yield takeLatest(Types.FETCH_MAIL_LIST, fetchDataMailList);
  yield takeLatest(Types.FETCH_ALL_PAYMENT_METHOD, fetchDataPaymentMethod);
  yield takeLatest(Types.EDIT_PAYMENT_METHOD, editPaymentMethod);
  yield takeLatest(Types.EDIT_PAYMENT_STATUS, editPaymentStatus);
  yield takeLatest(Types.EDIT_NAME_MAIL, changeNameMailList);
  yield takeLatest(Types.REMOVE_USER_FORM_MAIL_LIST, removeUserFormMailList);
  yield takeLatest(Types.STOP_SEND_STEP_MAIL, stopSendStepMail);
  yield takeLatest(Types.FETCH_DATA_BLACK_LIST, fetchBlackList);
  yield takeLatest(Types.ADD_MAIL_TO_BLACK_LIST, addMailToBlackList);
  yield takeLatest(Types.DELETE_MAIL_TO_BLACK_LIST, deleteMailToBlackList);
  yield takeLatest(Types.GET_MAILS_LIST, fetchMailsList);
  yield takeLatest(Types.GET_LIST_PRODUCTS, fetchListProducts);
  yield takeLatest(Types.GET_GROUP_NAME_PRODUCT, fetchGroupNameProduct);
  yield takeLatest(Types.GET_GROUP_NAME_MAIL, fetchGroupNameMail);
  yield takeLatest(
    Types.GET_LIST_PRODUCT_BY_GROUP,
    getProductListPageManageUser
  );
  yield takeLatest(Types.GET_LIST_MAIL_BY_GROUP, getMailListPageManageUser);
  yield takeLatest(
    Types.GENERATE_COMPLETE_PAYMENT_LINK,
    generateCompletePaymentLink
  );
  yield takeLatest(Types.GET_LIST_USER, getListUserAction);
  yield takeLatest(Types.GET_USER_DETAIL, getUserDetail);
  yield takeLatest(Types.STOP_REMIND_MAIL, stopRemindMail);
}
