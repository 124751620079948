import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { checkRoleEditButton } from '../helper/roleUser';
import { AppState } from '../store/reducers/rootReducer';
import { IEventOnSchedule, ISchedule } from '../store/reducers/event/interface';

export const usePageListFunnel = () => {
  const pageListFunnel = useSelector((state: AppState) => state.pageListFunnel);
  return [pageListFunnel];
};

export const usePageListMembersite = () => {
  const pageListMembersite = useSelector(
    (state: AppState) => state.pageListMembersite
  );
  return [pageListMembersite];
};

export const usePermissionControl = (path: string) => {
  const profile = useSelector((state: AppState) => state.myPage.profile.data);
  const { role } = profile;

  const hasPermission: boolean =
    role &&
    checkRoleEditButton(
      {
        isFullRole: role.is_full_role,
        isOwner: role.is_owner,
        role: role.role,
      },
      path
    );
  return [hasPermission];
};

export const useFunnelSummary = () => {
  const funnelSummary = useSelector((state: AppState) => state.summaryFunnel);
  return [funnelSummary];
};

export const useMembersiteSummary = () => {
  const membersiteSummary = useSelector(
    (state: AppState) => state.summaryMembersite
  );
  return [membersiteSummary];
};

export const useSelectedGroupFunnel = () => {
  const selectedGroup = useSelector(
    (state: AppState) => state.selectedGroupFunnel
  );
  return [selectedGroup];
};

export const useGroupFunnelsList = () => {
  const groupFunnels = useSelector((state: AppState) => state.groupFunnelsList);
  return [groupFunnels];
};

export const useGroupMembersitesList = () => {
  const groupMembersites = useSelector(
    (state: AppState) => state.groupMembersitesList
  );
  return [groupMembersites];
};

export const useLoadingGlobal = () => {
  const hasLoading = useSelector((state: AppState) => state.isLoading);
  return [hasLoading];
};

export const useListEvent = () => {
  const listEvent = useSelector((state: AppState) => state.event.listEvent);
  return [listEvent];
};

export const useListGroup = () => {
  const listGroup = useSelector((state: AppState) => state.event.listGroup);
  return [listGroup];
};

export const useSchedule = (scheduleUuid: string) => {
  const schedule = useSelector(
    (state: AppState) =>
      state.event.listSchedules.find(
        (scheduleOnList: ISchedule) =>
          scheduleOnList.schedule_uuid === scheduleUuid
      ),
    (oldValue, newValue) => isEqual(oldValue, newValue)
  );
  return [schedule];
};

export const useEvent = (eventUuid: string) => {
  const event = useSelector((state: AppState) =>
    state.event.listEventByGroups.data.find(
      (eventOnList: IEventOnSchedule) => eventOnList.uuid === eventUuid
    )
  );
  return [event];
};
