import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useRouteMatch } from 'react-router';
import useLongPress from '../../../hooks/useLongPress';
import { QuestionCircleOutlined } from '@ant-design/icons';
import iconAura from '../../../../assets/img/menu-icons/aura-icon.png';
import { getScreenName } from './config';
import './style.scss';
import { Link } from 'react-router-dom';
import { LINK_BLOG_FUNECY_MANUAL } from '../../../constants/link';
import { HELPER_PAGE } from '../../../constants/menus';

const ArticleFooter: React.FC<any> = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const [pathName, setPathName] = useState<string>('');
  const [isShowScreenName, setIsShowScrollName] = useState<boolean>(false);

  const onLongPress = () => {
    setIsShowScrollName(true);
  };

  const longPressOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const longPressEvent = useLongPress(onLongPress, longPressOptions);

  useEffect(() => {
    if (match?.path) setPathName(match?.path);
  }, [match]);

  return (
    <Row justify="space-between" className="footer-wrapped">
      <Col className="footer-wrapped_before">
        <p
          {...longPressEvent}
          className={`debug-screen-id m-0 p-0 ${
            isShowScreenName ? '' : 'hidden'
          }`}
        >
          {getScreenName(pathName)}
        </p>
        <p className="m-0 p-0">{t('global.common.mainCompany')}</p>
      </Col>
      <Col className="d-flex">
        <div className="aura">
          <a
            href={LINK_BLOG_FUNECY_MANUAL}
            target="_blank"
            className="m-0 p-0"
            rel="noreferrer"
          >
            <img src={iconAura} alt="" />
            <p>{t('global.common.manual')}</p>
          </a>
        </div>
        <div className="question">
          <Link to={HELPER_PAGE.CONTACT_US} target="_blank" className="m-0 p-0">
            <QuestionCircleOutlined />
            <p>{t('global.common.help')}</p>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default ArticleFooter;
