export const GET_SETTING_MEMBERSITE = 'GET_SETTING_MEMBERSITE';
export const POST_UPDATE_MEMBERSITE = 'POST_UPDATE_MEMBERSITE';
export const FETCH_LIST_SIGNATURE = 'FETCH_LIST_SIGNATURE';
export const FETCH_SETTING_MAIL = 'FETCH_SETTING_MAIL';
export const POST_UPDATE_MAIL = 'POST_UPDATE_MAIL';
export const POST_UPDATE_SEND_TEST_MAIL = 'POST_UPDATE_SEND_TEST_MAIL';
export const RENAME_MEMBERSITE_GROUP = 'RENAME_MEMBERSITE_GROUP';
export const RENAME_MEMBERSITE_LIST_ITEM = 'RENAME_MEMBERSITE_LIST_ITEM';
export const FETCH_LIST_MEMBERSITE = 'FETCH_LIST_MEMBERSITE';

export const FETCH_SUMMARY_MEMBERSITE = 'FETCH_SUMMARY_MEMBERSITE';
export const FETCH_SUMMARY_MEMBERSITE_SUCCESS =
  'FETCH_SUMMARY_MEMBERSITE_SUCCESS';
export const FETCH_SUMMARY_MEMBERSITE_FAILS = 'FETCH_SUMMARY_MEMBERSITE_FAILS';

export const FETCH_THE_MEMBERSITE_LIST = 'FETCH_THE_MEMBERSITE_LIST';
export const FETCH_THE_MEMBERSITE_LIST_SUCCESS =
  'FETCH_THE_MEMBERSITE_LIST_SUCCESS';
export const FETCH_THE_MEMBERSITE_LIST_FAILED =
  'FETCH_THE_MEMBERSITE_LIST_FAILED';

export const FETCH_THE_GROUP_MEMBERSITE_LIST =
  'FETCH_THE_GROUP_MEMBERSITE_LIST';
export const FETCH_THE_GROUP_MEMBERSITE_LIST_SUCCESS =
  'FETCH_THE_GROUP_MEMBERSITE_LIST_SUCCESS';
export const FETCH_THE_GROUP_MEMBERSITE_LIST_FAILED =
  'FETCH_THE_GROUP_MEMBERSITE_LIST_FAILED';

export const FETCH_SELECT_GROUP_MEMBERSITE = 'FETCH_SELECT_GROUP_MEMBERSITE';
export const FETCH_SELECT_GROUP_MEMBERSITE_SUCCESS =
  'FETCH_SELECT_GROUP_MEMBERSITE_SUCCESS';
export const FETCH_SELECT_GROUP_MEMBERSITE_FAILED =
  'FETCH_SELECT_GROUP_MEMBERSITE_FAILED';

export const FETCH_PAGE_LIST_MEMBERSITE = 'FETCH_PAGE_LIST_MEMBERSITE';
export const FETCH_PAGE_LIST_MEMBERSITE_SUCCESS =
  'FETCH_PAGE_LIST_MEMBERSITE_SUCCESS';
export const FETCH_PAGE_LIST_MEMBERSITE_FAILED =
  'FETCH_PAGE_LIST_MEMBERSITE_FAILED';
export const FETCH_HTML_PAGE_TEMPLATE = 'FETCH_HTML_PAGE_TEMPLATE';
export const POST_CREATE_COURSE = 'POST_CREATE_COURSE';
export const POST_UPDATE_COURSE = 'POST_UPDATE_COURSE';
export const POST_IMPORT_CSV = 'POST_IMPORT_CSV';
export const GET_SETTING_COURSE = 'GET_SETTING_COURSE';
export const POST_ADD_USER_COURSE = 'POST_ADD_USER_COURSE';
export const GET_LIST_GROUP_COURSE = 'GET_LIST_GROUP_COURSE';
export const GET_DATA_COURSE_ID = 'GET_DATA_COURSE_ID';
export const POST_SHARED_COURSE = 'POST_SHARED_COURSE';
