import { InfoUser } from '../../../store/reducers/manageUser/interface';

export const responseToDataUser = (response: any) => {
  return {
    email: response.email || '',
    phone: response.phone || '',
    zipCode: response.post_code || '',
    address: response.address || '',
    uuid: response.uuid || '',
    firstName: response.first_name || '',
    lastName: response.last_name || '',
    fullName: response.full_name || '',
  };
};

export const dataUserToParams = (data: InfoUser) => {
  return {
    params: {
      email: data.email,
      phone: data.phone,
      post_code: data.zipCode,
      address: data.address,
      full_name: data.fullName,
    },
    uuid: data.uuid,
  };
};

export const responseToErrorUser = (error: any) => {
  if (!error || error.length === 0) return false;
  return {
    email: error?.email,
    phone: error?.phone,
    zipCode: error?.post_code,
    address: error?.address,
    uuid: error?.uuid,
    firstName: error?.first_name,
    lastName: error?.last_name,
  };
};
