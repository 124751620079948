import i18next from 'i18next';
import {
  RemoveUserFormMailList,
  StopSendStepMail,
  EditNameMailList,
} from './../../../../../store/actions/manageUser/index';

export interface ItemTable {
  name: string;
  width: number;
  sort: boolean;
}

export const dataToParamsEditNameMailList = (data: EditNameMailList) => {
  return {
    email: data.nameEmail,
    mail_list_user_id: data.mailListUserId,
  };
};

export const dataToParamsRemoveUserFormMailList = (
  data: RemoveUserFormMailList
) => {
  return {
    uuid: data.uuid,
    user_uuid: data.userUuid,
  };
};

export const dataToParamsStopSendStepMail = (data: StopSendStepMail) => ({
  /* ------------- field data uuid ------------- */
  uuid: data.stepMailRunUuid,
});

export const handleTime = (time: any) => {
  time = time.slice(0, time.indexOf('.'));
  time = time.replace('T', ' ');
  return time;
};

export enum STATUS_SEND_STEP_MAIL {
  SENDING = 'SENDING',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  STOP = 'STOP',
}
export interface RowStepMailProps {
  data: any;
  handleStopSentMail: (stepMailRunUuid: string) => void;
  handleSubmitChangeEmail: (
    email: string,
    uuidMailList: string,
    uuidStepMail: string,
    mailListUserId: number
  ) => void;
}

export interface RowRemindMailProps {
  data: any;
  handleSubmitStopRemind: (remindMailRunUuid: string) => void;
  handleSubmitChangeEmail: (
    email: string,
    uuidMailList: string,
    uuidStepMail: string,
    mailListUserId: number
  ) => void;
}

export enum STATUS_REMIND_MAIL {
  STOP = 'STOP',
  SENDING = 'SENDING',
  COMPLETE = 'COMPLETE',
}

export const getTextStepMailStatus = (status: string) => {
  switch (status) {
    case STATUS_SEND_STEP_MAIL.COMPLETE:
      return i18next.t(
        'managerUser.mailList.body.rowStepMail.statusSendStepMail.finish'
      );

    case STATUS_SEND_STEP_MAIL.SENDING:
      return i18next.t(
        'managerUser.mailList.body.rowStepMail.statusSendStepMail.running'
      );

    case STATUS_SEND_STEP_MAIL.FAILED:
    case STATUS_SEND_STEP_MAIL.STOP:
      return i18next.t(
        'managerUser.mailList.body.rowStepMail.statusSendStepMail.stop'
      );

    default:
      return '';
  }
};

export const getTextStatusRemindMail = (status: string) => {
  switch (status) {
    case STATUS_REMIND_MAIL.STOP:
      return i18next.t('manageUser.mailList.body.table.tab3.stopped');
    case STATUS_REMIND_MAIL.COMPLETE:
    case STATUS_REMIND_MAIL.SENDING:
      return i18next.t('manageUser.mailList.body.table.tab3.stop');
    default:
      return '';
  }
};
