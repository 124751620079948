import { ParamsListUser } from '../../../../../Clients/users/interface';
import { EOptionRangeType } from '../../../../../config/common.config';
import { DataListUserBasic } from '../../common/constants';

export interface FilterListUserProps {
  payload: ParamsListUser;
  dateType: EOptionRangeType;
  meta: DataListUserBasic['meta'];
  funnelFilter: any;
  accessFunnelFilter: any;
  handleChangePerPage: (params: number) => void;
  handleFilterDate: (params: any) => void;
  handleSubmitTextFilter: () => void;
  handleChangeTextFilter: (params: string) => void;
  handleFilterMultipleSelect: (filed: string, value: any) => void;
  handleSubmitFunnelFilters: (keyword: string) => void;
  searchFunnelByKeyword: (value: any, page: number) => void;
  handleSubmitAccessFunnelFilters: (value: any) => void;
  searchAccessFunnelByKeyword: (keyword: string, page: number) => void;
}

export const dateFilter = {
  currentPage: 1,
  pageSize: 20,
  totalPage: 0,
  data: [],
  type: 0,
  dateFrom: '',
  dateTo: '',
  typeFilter: 0,
};

export const TYPE_FILTER = {
  ALL: 'All',
  PRODUCT: 'product',
  FUNNEL: 'funnel',
};

export const convertDataOptions = (data: any[], textOptionAll: string) => {
  const result = [
    {
      value: '',
      label: textOptionAll,
      title: textOptionAll,
    },
  ];
  const dataOption = data?.map((item: any) => ({
    title: item.name,
    value: item.uuid,
    label: item.name,
  }));
  return result.concat(dataOption);
};

export interface MSelectedProps {
  value: any;
  label: string;
  title: string;
}

export const defaultFilterGroupProduct: MSelectedProps[] = [
  {
    label: '全ての購入商品',
    title: '全ての購入商品',
    value: 'All',
  },
];

export const defaultFilterGroupMail: MSelectedProps[] = [
  {
    label: '全てのメールリスト',
    title: '全てのメールリスト',
    value: 'All',
  },
];

export const handleDataSelectMulti = (data: any, type: string) => {
  if (!data) return [];
  const result: Array<MSelectedProps> = [];
  data.map((item: any) => {
    const { uuid, name } = item;
    result.push({
      value: uuid,
      label: name,
      title: name,
    });
  });
  if (type === TYPE_FILTER.FUNNEL) return defaultFilterGroupMail.concat(result);
  return defaultFilterGroupProduct.concat(result);
};

export const handleParamFilterUser = (value: any[]) => {
  const filterAll = value.includes('All');
  if (filterAll) return '';
  return value.join(',');
};
