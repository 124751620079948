import i18n from '../../../../i18n/i18n';

export interface PartnerPaymentInfo {
  uuid?: string;
  partner_id?: number;
  partner_type?: number;
  bank_branch_id?: number;
  bank_account_number?: string;
  bank_account_name?: string;
  front_identify_path?: string;
  reverse_identify_path?: string;
  status_identify?: number;
  gender?: number;
  birthday?: string;
  tel_number?: string;
  zip_code?: string;
  first_name_kanji?: string;
  last_name_kanji?: string;
  first_name_kana?: string;
  last_name_kana?: string;
  prefecture_kanji?: string;
  city_kanji?: string;
  district_kanji?: string;
  block_number?: string;
  building_kanji?: string;
  prefecture_kana?: string;
  city_kana?: string;
  district_kana?: string;
  block_number_kana?: string;
  building_kana?: string;
  created_at?: string;
  tax_code?: string;
  bank_branch?: {
    id?: number;
    bank_id?: number;
    bank_branch_code?: string;
    bank_branch_name?: string;
    bank?: {
      id?: number;
      bank_code?: string;
      bank_name?: string;
    };
  };
  partner_corporation?: PartnerCorporation;
}

export interface PartnerCorporation {
  uuid?: string;
  c_number?: string;
  c_name_kanji?: string;
  c_name_kana?: string;
  c_prefecture_kanji?: string;
  c_city_kanji?: string;
  c_district_kanji?: string;
  c_block_number?: string;
  c_building_kanji?: string;
  c_prefecture_kana?: string;
  c_city_kana?: string;
  c_district_kana?: string;
  c_zip_code?: string;
  c_block_number_kana?: string;
  c_building_kana?: string;
}

export interface TableDetailPartner {
  uuid?: string;
  email?: string;
  full_name?: string;
  image_path?: string;
  active_flag?: number;
  created_at?: string;
  deleted_at?: string;
  affiliate_rank?: string;
  partner_type?: number;
  status_identify?: string;
  partner_payment_info?: PartnerPaymentInfo;
}

export enum PARTNER_TYPE {
  PERSONAL = 1,
  ORGANIZATION = 2,
}

export const convertPartnerType = (value?: number) => {
  switch (value) {
    case PARTNER_TYPE.PERSONAL:
      return i18n.t('affiliate.partner.detail.label.partnerType.personal');
    case PARTNER_TYPE.ORGANIZATION:
      return i18n.t('affiliate.partner.detail.label.partnerType.organization');
    default:
      return i18n.t('affiliate.partner.detail.label.partnerType.personal');
  }
};

export const getNamePartnerOrRepresentative = (value?: number) => {
  switch (value) {
    case PARTNER_TYPE.PERSONAL:
      return i18n.t('affiliate.partner.detail.label.personal_info');
    case PARTNER_TYPE.ORGANIZATION:
      return i18n.t('affiliate.partner.detail.label.representative_info');
    default:
      return i18n.t('affiliate.partner.detail.label.personal_info');
  }
};

export enum GENDER_TYPE {
  MALE = 1,
  FEMALE = 2,
}

export const convertGender = (value?: number) => {
  switch (value) {
    case GENDER_TYPE.MALE:
      return i18n.t('affiliate.partner.list.detailPartner.gender.male');
    case GENDER_TYPE.FEMALE:
      return i18n.t('affiliate.partner.list.detailPartner.gender.female');
    default:
      return i18n.t('affiliate.partner.list.detailPartner.unRegister');
  }
};
