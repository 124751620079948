// Project: 7612770651107e1659371833c56a9b25e733c4615968bb7c5582cdc92aa6249b
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import momentTimeZone from 'moment-timezone';
import '../assets/scss/global.scss';
import { AppState } from './store/reducers/rootReducer';
import { TMarkLoading } from './components/molecules/TMarkLoading';
import RenderRouter from '../ts/router/renderRouter';
import GlobalPopupContainer from './containers/GlobalUnit/PopupGlobal/PopupGlobal';
import { getCurrentTimeZone } from './utilities/common.utilities';

export const App = () => {
  const currentTimeZone = getCurrentTimeZone();
  momentTimeZone.tz.setDefault(currentTimeZone);

  const isLoading = useSelector((state: AppState) => state.isLoading);

  return (
    <React.Fragment>
      <BrowserRouter>
        <RenderRouter />
      </BrowserRouter>
      <TMarkLoading loading={isLoading} />
      <GlobalPopupContainer />
      <ToastContainer icon={false} />
    </React.Fragment>
  );
};
