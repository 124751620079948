import { IParamsFetchAffiliateBonus } from '../../store/reducers/affiliate/interface';

const getParamsFetchDataBonus = (information: IParamsFetchAffiliateBonus) => ({
  search: information.search,
  funnel_uuids: information.funnel_uuids,
  CV_start_date: information.start_date,
  CV_end_date: information.end_date,
  affiliate_rank_id: information.affiliate_rank_id,
  sort_name: !information.sort_type ? '' : information.sort_name,
  sort_type: !information.sort_name ? '' : information.sort_type,
  page: information.page,
  per_page: information.per_page,
  filter_deleted_at: information.filter_deleted_at,
});

const getParamsDownloadDataBonus = (
  information: IParamsFetchAffiliateBonus
) => ({
  search: information.search,
  funnel_uuids: information.funnel_uuids,
  CV_start_date: information.start_date,
  CV_end_date: information.end_date,
  affiliate_rank_id: information.affiliate_rank_id,
  sort_name: !information.sort_type ? '' : information.sort_name,
  sort_type: !information.sort_name ? '' : information.sort_type,
  filter_deleted_at: information.filter_deleted_at,
});

export { getParamsFetchDataBonus, getParamsDownloadDataBonus };
