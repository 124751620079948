import { put, takeLatest, call } from 'redux-saga/effects';

import { handleMessage } from '../../utilities/common.utilities';
import * as Types from '../actions/template/types';
import * as TypesLoading from './../actionTypes/loadingActionTypes';
import * as templateServices from '../../Clients/templates';
import { EStatusHttp } from '../../Clients/interfaces';

interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
}

function* getListBlockTemplates(action: any) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(() =>
      templateServices.getListBlockTemplates(action.params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data.data);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getAllCategoriesBlockTemplate(action: any) {
  try {
    const response: APIResponseType = yield call(() =>
      templateServices.getAllCategoriesBlockTemplate(action.params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_ALL_CATEGORIES_BLOCK_TEMPLATE_SUCCESS,
        data: response.data.data,
      });
    }
  } catch (error: any) {
    handleMessage(error.response);
  }
}

function* updateBlockTemplate(action: any) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(() =>
      templateServices.updateBlockTemplate(action.params)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
    handleMessage(response);
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getBlockTemplateDetail(action: any) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(() =>
      templateServices.getBlockTemplateDetail(action.uuid)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data.data);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* deleteBlockTemplate(action: any) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(() =>
      templateServices.deleteBlockTemplate(action.uuid)
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({ type: TypesLoading.HIDE_LOADING });
      action.onSuccess();
    }
    handleMessage(response);
  } catch (error: any) {
    yield put({ type: TypesLoading.HIDE_LOADING });
    handleMessage(error.response);
  }
}

export default function* templateSaga() {
  yield takeLatest(Types.GET_LIST_BLOCK_TEMPLATES, getListBlockTemplates);
  yield takeLatest(
    Types.GET_ALL_CATEGORIES_BLOCK_TEMPLATE,
    getAllCategoriesBlockTemplate
  );
  yield takeLatest(Types.UPDATE_BLOCK_TEMPLATE, updateBlockTemplate);
  yield takeLatest(Types.GET_BLOCK_TEMPLATE_DETAIL, getBlockTemplateDetail);
  yield takeLatest(Types.DELETE_BLOCK_TEMPLATE, deleteBlockTemplate);
}
