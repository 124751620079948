import { EStatusHttp } from './../../../Clients/interfaces';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as Types from '../../actions/mail/types';
import * as ActionTypes from '../../actionTypes/loadingActionTypes';
import { AlternativeParamsType } from '../../reducers/mail/alternativeCharacterInterface';
import { getErrorResponse } from '../../../helper/httpClient.helper';
import * as mailService from './../../../Clients/mail';
import { handleMessage } from '../../../utilities/common.utilities';
import { IParamsGetListMailList } from '../../../Clients/mail/interface';

interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
}

function* getAlternativeCharacters(action: {
  type: string;
  params: {
    type: AlternativeParamsType;
    step_mail_uuid?: string;
    mail_list_uuid?: string;
    remind_mail_uuid?: string;
  };
}) {
  const { params } = action;

  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      mailService.fetchListSignature,
      params
    );

    yield put({ type: ActionTypes.HIDE_LOADING });
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_ALTERNATIVE_CHARACTERS_SUCCESS,
        data: response.data.data,
      });
      return;
    }
  } catch (e: any) {
    yield put({ type: ActionTypes.HIDE_LOADING });
    if (e.response) {
      yield put({
        type: Types.GET_ALTERNATIVE_CHARACTERS_ERROR,
        error: getErrorResponse(e.response),
      });
    }
  }
}

function* getUserFormValidToken(action: {
  type: string;
  params: { token: string };
  onSuccess: (result: any) => void;
  onError: (error: string) => void;
}) {
  const { params } = action;

  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      mailService.getUserFormValidToken,
      params.token
    );

    yield put({ type: ActionTypes.HIDE_LOADING });
    if (response.status === EStatusHttp.HTTP_OK) {
      return action.onSuccess(response.data.data);
    }

    const error = getErrorResponse(response);
    action.onError(error.message || '');
  } catch (e: any) {
    yield put({ type: ActionTypes.HIDE_LOADING });
    if (e.response) {
      const error = getErrorResponse(e.response);
      action.onError(error.message || '');
      return;
    }
    action.onError(e.message);
  }
}

function* updateUserForm(action: {
  type: string;
  params: { token: string };
  data: any;
  onSuccess: (result: any) => void;
  onError: (error: string) => void;
}) {
  const { params, data } = action;

  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      mailService.updateUserFormValidToken,
      params.token,
      data
    );

    yield put({ type: ActionTypes.HIDE_LOADING });
    if (response.status === EStatusHttp.HTTP_OK) {
      return action.onSuccess(response.data.data);
    }

    const error = getErrorResponse(response);
    action.onError(error.message || '');
  } catch (e: any) {
    yield put({ type: ActionTypes.HIDE_LOADING });
    if (e.response) {
      const error = getErrorResponse(e.response);
      action.onError(error.message || '');
      return;
    }
    action.onError(e.message);
  }
}

function* getPaymentOrderInfo(action: {
  type: string;
  params: { token: string };
  onSuccess: (result: any) => void;
}) {
  const { params } = action;

  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      mailService.getPaymentOrderInfo,
      params.token
    );
    yield put({ type: ActionTypes.HIDE_LOADING });
    if (response.status === EStatusHttp.HTTP_OK) {
      return action.onSuccess(response.data.data);
    }

    handleMessage(response);
  } catch (e: any) {
    yield put({ type: ActionTypes.HIDE_LOADING });
    handleMessage(e.response);
  }
}

function* updatePaymentUnivaPay(action: {
  type: string;
  params: { orderID: string };
  data: any;
  onSuccess: (result: any) => void;
}) {
  const { params, data } = action;

  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      mailService.updatePaymentUnivaPay,
      params.orderID,
      data
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response);
    }
  } catch (e: any) {
    handleMessage(e.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* getSendNowStatus(action: {
  type: string;
  data: string;
  onSuccess: (result: any) => void;
}) {
  const { data } = action;

  try {
    const response: APIResponseType = yield call(
      mailService.sendNowStatus,
      data
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      return action.onSuccess(response.data.data);
    }
  } catch (e: any) {
    handleMessage(e?.response);
  }
}

function* updateMailSubcribe(action: {
  type: string;
  params: { id: number };
  onSuccess: (result: any) => void;
  onError: () => void;
}) {
  try {
    const response: APIResponseType = yield call(
      mailService.updateMailSubcribe,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      action.onSuccess(response);
    } else {
      action.onError();
    }
  } catch (e: any) {
    handleMessage(e.response);
    action.onError();
  }
}
//remind mail

function* getMailIntegration(action: {
  type: string;
  onSuccess: (result: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.getListMailIntegration
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      return action.onSuccess(response.data.data);
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* getListAllGroupRemindMail(action: {
  type: string;
  onSuccess: (result: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.getListAllGroupRemindMail
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      return action.onSuccess(response.data.data);
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* getRemindMailDetail(action: {
  type: string;
  params: string;
  onSuccess: (result: any) => void;
  onError?: () => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.getRemindMailDetail,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      return action.onSuccess(response?.data.data);
    }
  } catch (e: any) {
    handleMessage(e.response);
    if (action.onError) action.onError();
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* createRemindMail(action: {
  type: string;
  params: any;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.createRemindmail,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      action.onSuccess(response.data.data);
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* updateRemindMail(action: {
  type: string;
  params: any;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.updateRemindmail,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* getListFunnelSteps(action: {
  type: string;
  uuid: string;
  onSuccess: (result: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.getListFunnelSteps,
      action.uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data.data);
    }
  } catch (e: any) {
    handleMessage(e.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* getListRemindMail(action: {
  type: string;
  params: any;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.getListRemindMail,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      return action.onSuccess(response.data.data);
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* getListMailList(action: {
  type: string;
  params: IParamsGetListMailList;
  onSuccess: (result: any) => void;
  onError: () => void;
}) {
  try {
    const response: APIResponseType = yield call(
      mailService.getListMailList,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data);
    }
  } catch (e: any) {
    action.onError();
    handleMessage(e.response);
  }
}

function* copyGroupRemindMail(action: {
  type: string;
  uuid: string;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.copyGroupRemindMail,
      { uuid: action.uuid }
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* deleteGroupRemindMail(action: {
  type: string;
  uuid: string;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.deleteGroupRemindMail,
      { uuid: action.uuid }
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* renameRemindMail(action: {
  type: string;
  params: any;
  onSuccess: () => void;
  onError: () => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.renameGroup,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      action.onSuccess();
    }
  } catch (e: any) {
    handleMessage(e?.response);
    action.onError();
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* copyRemindMail(action: {
  type: string;
  uuid: string;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(mailService.copyRemindMail, {
      uuid: action.uuid,
    });
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* deleteRemindMail(action: {
  type: string;
  uuid: string;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(mailService.deleteRemindMail, {
      uuid: action.uuid,
    });
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* createRemindMailItem(action: {
  type: string;
  params: any;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.createRemindMailItem,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      action.onSuccess();
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* updateRemindMailItem(action: {
  type: string;
  params: any;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.updateRemindMailItem,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      action.onSuccess();
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* copyRemindMailItem(action: {
  type: string;
  uuid: string;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.copyRemindMailItem,
      {
        remind_mail_item_uuid: action.uuid,
      }
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* deleteRemindMailItem(action: {
  type: string;
  uuid: string;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.deleteRemindMailItem,
      {
        remind_mail_item_uuid: action.uuid,
      }
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* fetchFixedPhrase(action: {
  type: string;
  onSuccess: (result: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(mailService.fetchFixedPhrase);
    if (response.status === EStatusHttp.HTTP_OK) {
      return action.onSuccess(response.data.data);
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* getConditionItemPageForm(action: {
  type: string;
  params: any;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.getConditionItemPageForm,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      return action.onSuccess(response?.data?.data);
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* getDataMailListAndConditionMail(action: {
  type: string;
  params: any;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.getDataMailListAndConditionMail,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      return action.onSuccess(response?.data?.data);
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* remindMailContentDetail(action: {
  type: string;
  uuid: string;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.remindMailContentDetail,
      action.uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      return action.onSuccess(response.data.data);
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* sendMailTest(action: { type: string; params: any }) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.sendMailTest,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      return;
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* submitRemindMailContent(action: {
  type: string;
  params: any;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.submitRemindMailContent,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      handleMessage(response);
      action.onSuccess();
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* getMailListById(action: {
  type: string;
  uuid: string;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.getMailListById,
      action.uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response?.data?.data);
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* updateRemindMailPublish(action: {
  type: string;
  params: any;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.publishRemindMail,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
      return;
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* cancelScheduleEventDate(action: {
  type: string;
  params: any;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.cancelScheduleEventDate,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      return;
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* changeScheduleCalendar(action: {
  type: string;
  token: string;
  schedule_uuid: string;
  schedule_date: string;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.changeScheduleCalendar,
      {
        token: action.token,
        schedule_uuid: action.schedule_uuid,
        schedule_date: action.schedule_date,
      }
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      return;
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* updateMailList(action: {
  type: string;
  params: any;
  onSuccess: (result?: any) => void;
}) {
  yield put({ type: ActionTypes.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      mailService.updateMailList,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
      return;
    }
  } catch (e: any) {
    handleMessage(e?.response);
  } finally {
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* getStatusImportCSV(action: {
  type: string;
  uuid: string;
  onSuccess: (result?: any) => void;
  onError: () => void;
}) {
  try {
    const response: APIResponseType = yield call(
      mailService.getStatusImportCSV,
      action.uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data);
      return;
    }
  } catch (e: any) {
    handleMessage(e?.response);
    action.onError();
  }
}

function* createUserByImportCSV(action: {
  type: string;
  payload: FormData;
  onSuccess: (result?: any) => void;
  onError: () => void;
}) {
  try {
    const response: APIResponseType = yield call(
      mailService.createUserByImportCSV,
      action.payload
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data.data.uuid);
    } else {
      handleMessage(response);
      action.onError();
    }
  } catch (e: any) {
    handleMessage(e?.response);
    action.onError();
  }
}

export default function* alternativeCharacterSaga() {
  yield takeLatest(Types.GET_ALTERNATIVE_CHARACTERS, getAlternativeCharacters);
  yield takeLatest(
    Types.ALTERNATIVE_CHARACTER_GET_USER_FORM_TOKEN,
    getUserFormValidToken
  );
  yield takeLatest(
    Types.ALTERNATIVE_CHARACTER_UPDATE_USER_FORM,
    updateUserForm
  );
  yield takeLatest(
    Types.ALTERNATIVE_CHARACTER_GET_PAYMENT_ORDER_INFO,
    getPaymentOrderInfo
  );
  yield takeLatest(
    Types.ALTERNATIVE_CHARACTER_UPDATE_PAYMENT_UNIVAPAY,
    updatePaymentUnivaPay
  );
  yield takeLatest(Types.SEND_NOW_STATUS, getSendNowStatus);
  yield takeLatest(Types.UPDATE_MAIL_SUBCRIBE, updateMailSubcribe);
  yield takeLatest(Types.GET_LIST_FUNNEL_STEPS, getListFunnelSteps);
  yield takeLatest(Types.GET_LIST_MAIL_LIST, getListMailList);
  yield takeLatest(Types.GET_MAIL_INTEGRATION, getMailIntegration);
  yield takeLatest(
    Types.GET_LIST_ALL_GROUP_REMIND_MAIL,
    getListAllGroupRemindMail
  );
  yield takeLatest(Types.GET_REMIND_MAIL_DETAIL, getRemindMailDetail);
  yield takeLatest(Types.CREATE_REMIND_EMAIL, createRemindMail);
  yield takeLatest(Types.UPDATE_REMIND_MAIL, updateRemindMail);
  yield takeLatest(Types.GET_LIST_REMIND_MAIL, getListRemindMail);
  yield takeLatest(Types.COPY_GROUP_REMIND_MAIL, copyGroupRemindMail);
  yield takeLatest(Types.DELETE_GROUP_REMIND_MAIL, deleteGroupRemindMail);
  yield takeLatest(Types.RENAME_GROUP_REMIND_MAIL, renameRemindMail);
  yield takeLatest(Types.COPY_REMIND_MAIL, copyRemindMail);
  yield takeLatest(Types.DELETE_REMIND_MAIL, deleteRemindMail);
  yield takeLatest(Types.CREATE_REMIND_MAIL_ITEM, createRemindMailItem);
  yield takeLatest(Types.UPDATE_REMIND_MAIL_ITEM, updateRemindMailItem);
  yield takeLatest(Types.COPY_REMIND_MAIL_ITEM, copyRemindMailItem);
  yield takeLatest(Types.DELETE_REMIND_MAIL_ITEM, deleteRemindMailItem);
  yield takeLatest(Types.FETCH_FIXED_PHRASE, fetchFixedPhrase);
  yield takeLatest(
    Types.GET_CONDITION_ITEM_PAGE_FORM,
    getConditionItemPageForm
  );
  yield takeLatest(
    Types.GET_DATA_LIST_AND_CONDITION_MAIL,
    getDataMailListAndConditionMail
  );
  yield takeLatest(Types.REMIND_MAIL_CONTENT_DETAIL, remindMailContentDetail);
  yield takeLatest(Types.SEND_MAIL_TEST, sendMailTest);
  yield takeLatest(Types.SUBMIT_REMIND_MAIL_CONTENT, submitRemindMailContent);
  yield takeLatest(Types.GET_MAIL_LIST_BY_ID, getMailListById);
  yield takeLatest(Types.PUBLISH_REMIND_MAIL, updateRemindMailPublish);
  yield takeLatest(Types.CANCEL_SCHEDULE_EVENT_DATE, cancelScheduleEventDate);
  yield takeLatest(Types.CHANGE_SCHEDULE_CALENDAR, changeScheduleCalendar);
  yield takeLatest(Types.UPDATE_MAIL_LIST, updateMailList);
  yield takeLatest(Types.GET_STATUS_IMPORT_CSV, getStatusImportCSV);
  yield takeLatest(Types.CREATE_USER_BY_IMPORT_CSV, createUserByImportCSV);
}
