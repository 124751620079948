import axios from 'axios';
import { toast } from 'react-toastify';
import {
  redirectWhenExpire,
  redirectWhenLogout,
  redirectWhenMaintain,
} from '../config/common.config';
import { TIMEOUT_REQUEST_MESSAGE, TIMEOUT_REQUEST_SERVER } from './config';
import { store } from '../store/configureStore';
import { getCookiesByName, LOCAL_TOKEN_KEY } from '../utilities/cookies';
import { isDevelopmentEnv } from '../helper/commonTS.helper';

import { EStatusHttp } from './interfaces';
import { showPopupAction } from '../store/actions/showPopupSection';

const axiosClient = axios.create({
  headers: { 'content-type': 'application/json' },
  timeout: TIMEOUT_REQUEST_SERVER,
  timeoutErrorMessage: TIMEOUT_REQUEST_MESSAGE,
  withCredentials: !isDevelopmentEnv(),
});

if (isDevelopmentEnv()) {
  axiosClient.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${getCookiesByName(LOCAL_TOKEN_KEY)}`;
}

axiosClient.interceptors.response.use(
  (response: any) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (
      error.message === TIMEOUT_REQUEST_MESSAGE ||
      error.message === 'Network Error'
    ) {
      store.dispatch(showPopupAction('SECTION TIMEOUT!'));
      return error.message;
    }
    if (error && error.response) {
      switch (error.response.status) {
        case EStatusHttp.HTTP_TOKEN_INVALID:
        case EStatusHttp.HTTP_UNAUTHORIZED:
          redirectWhenLogout();
          break;
        case EStatusHttp.HTTP_FORBIDDEN:
          toast.error(error.response.data.alert);
          break;
        case EStatusHttp.HTTP_BAD_REQUEST:
          if (error.response.data.status === 90) {
            redirectWhenExpire();
          }
          break;
        case EStatusHttp.HTTP_SERVICE_UNAVAILABLE:
          redirectWhenMaintain();
          break;
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
