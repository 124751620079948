export const GET_LIST_GROUP_TIMER = 'GET_LIST_GROUP_TIMER';
export const GET_LIST_GROUP_TIMER_SUCCESS = 'GET_LIST_GROUP_TIMER_SUCCESS';
export const CREATE_TIMER = 'CREATE_TIMER';
export const GET_TIMER_DETAIL = 'GET_TIMER_DETAIL';
export const UPDATE_TIMER = 'UPDATE_TIMER';
export const GET_WHITELIST_USER = 'GET_WHITELIST_USER';
export const GET_WHITELIST_USER_SUCCESS = 'GET_WHITELIST_USER_SUCCESS';
export const UPDATE_WHITELIST_USER = 'UPDATE_WHITELIST_USER';
export const GET_CURRENT_MY_IP = 'GET_CURRENT_MY_IP';
export const GET_LIST_TIMER = 'GET_LIST_TIMER';
export const GET_LIST_TIMER_SUCCESS = 'GET_LIST_TIMER_SUCCESS';
export const PUBLISH_TIMER = 'PUBLISH_TIMER';
export const DUPLICATE_TIMER = 'DUPLICATE_TIMER';
export const DELETE_TIMER = 'DELETE_TIMER';
export const RENAME_TIMER = 'RENAME_TIMER';

export const DELETE_GROUP_TIMER = 'DELETE_GROUP_TIMER';
export const DUPLICATE_GROUP_TIMER = 'DUPLICATE_GROUP_TIMER';
export const RENAME_GROUP_TIMER = 'RENAME_GROUP_TIMER';

export const RESET_TRACKING = 'RESET_TRACKING';
