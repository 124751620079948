import i18next from 'i18next';
import moment from 'moment';
import { DTimeFormat } from '../../../constants/app.constants';
import { FORMAT_DATE_JP } from '../../../utilities/common.utilities';

enum ETypeOptionRanger {
  AllTime = 0,
  Today = 1,
  Last7days = 2,
  ThisMonth = 3,
  LastMonths = 4,
  Last2Months = 5,
  Last6Months = 6,
  LastYear = 7,
  Custom = 8,
  Yesterday = 9,
}

const TitleOptionRange = {
  AllTime: i18next.t('dropdownFilterRangerMinus.optionQuickly.allTime'),
  Today: i18next.t('dropdownFilterRangerMinus.optionQuickly.today'),
  Yesterday: i18next.t('common.list.filterDate.yesterday'),
  Last7days: i18next.t('dropdownFilterRangerMinus.optionQuickly.last7days'),
  ThisMonth: i18next.t('dropdownFilterRangerMinus.optionQuickly.thisMonth'),
  LastMonths: i18next.t('dropdownFilterRangerMinus.optionQuickly.lastMonths'),
  Last2Months: i18next.t('dropdownFilterRangerMinus.optionQuickly.last2Months'),
  Last6Months: i18next.t('dropdownFilterRangerMinus.optionQuickly.last6Months'),
  LastYear: i18next.t('dropdownFilterRangerMinus.optionQuickly.lastYear'),
  Custom: i18next.t('dropdownFilterRangerMinus.optionQuickly.custom'),
};

interface IOptionRanger {
  title: string;
  typeRanger: ETypeOptionRanger;
}
const formatDate = (date: moment.Moment) => moment(date).format(DTimeFormat);

const formatDateJP = (date: moment.Moment) =>
  moment(date).format(FORMAT_DATE_JP);

const OptionRanger: IOptionRanger[] = [
  {
    title: i18next.t('dropdownFilterRangerMinus.optionQuickly.today'),
    typeRanger: ETypeOptionRanger.Today,
  },
  {
    title: i18next.t('common.list.filterDate.yesterday'),
    typeRanger: ETypeOptionRanger.Yesterday,
  },
  {
    title: i18next.t('dropdownFilterRangerMinus.optionQuickly.last7days'),
    typeRanger: ETypeOptionRanger.Last7days,
  },
  {
    title: i18next.t('dropdownFilterRangerMinus.optionQuickly.thisMonth'),
    typeRanger: ETypeOptionRanger.ThisMonth,
  },
  {
    title: i18next.t('dropdownFilterRangerMinus.optionQuickly.lastMonths'),
    typeRanger: ETypeOptionRanger.LastMonths,
  },
  {
    title: i18next.t('dropdownFilterRangerMinus.optionQuickly.last2Months'),
    typeRanger: ETypeOptionRanger.Last2Months,
  },
  {
    title: i18next.t('dropdownFilterRangerMinus.optionQuickly.last6Months'),
    typeRanger: ETypeOptionRanger.Last6Months,
  },
  {
    title: i18next.t('dropdownFilterRangerMinus.optionQuickly.lastYear'),
    typeRanger: ETypeOptionRanger.LastYear,
  },
  {
    title: i18next.t('dropdownFilterRangerMinus.optionQuickly.allTime'),
    typeRanger: ETypeOptionRanger.AllTime,
  },
];

const START_TIME_OF_DAY = { hour: 0, minute: 0 };
const END_TIME_OF_DAY = { hour: 23, minute: 59 };

const selectQuicklyOption = (type: ETypeOptionRanger) => {
  switch (type) {
    case ETypeOptionRanger.Today: {
      return {
        dateFrom: moment().set(START_TIME_OF_DAY),
        dateTo: moment().set(END_TIME_OF_DAY),
      };
    }
    case ETypeOptionRanger.Yesterday: {
      return {
        dateFrom: moment().subtract(1, 'day').set(START_TIME_OF_DAY),
        dateTo: moment().subtract(1, 'day').set(END_TIME_OF_DAY),
      };
    }
    case ETypeOptionRanger.Last7days: {
      return {
        dateFrom: moment().set(START_TIME_OF_DAY).subtract(6, 'day'),
        dateTo: moment().set(END_TIME_OF_DAY),
      };
    }
    case ETypeOptionRanger.ThisMonth: {
      return {
        dateFrom: moment().startOf('month'),
        dateTo: moment().endOf('month'),
      };
    }
    case ETypeOptionRanger.LastMonths: {
      return {
        dateFrom: moment().subtract(1, 'month').startOf('month'),
        dateTo: moment().subtract(1, 'month').endOf('month'),
      };
    }
    case ETypeOptionRanger.Last2Months: {
      return {
        dateFrom: moment().subtract(2, 'month').startOf('month'),
        dateTo: moment().subtract(2, 'month').endOf('month'),
      };
    }
    case ETypeOptionRanger.Last6Months: {
      return {
        dateFrom: moment().subtract(6, 'month').startOf('month'),
        dateTo: moment().subtract(1, 'month').endOf('month'),
      };
    }
    case ETypeOptionRanger.LastYear: {
      return {
        dateTo: moment().subtract(1, 'month').endOf('month'),
        dateFrom: moment().subtract(1, 'year').startOf('month'),
      };
    }
    case ETypeOptionRanger.AllTime:
    default: {
      return {
        dateTo: moment(new Date()),
        dateFrom: moment(new Date()),
      };
    }
  }
};

const renderTitleDropdown = (
  type: ETypeOptionRanger,
  range?: [moment.Moment, moment.Moment]
) => {
  switch (type) {
    case ETypeOptionRanger.Today: {
      return TitleOptionRange.Today;
    }

    case ETypeOptionRanger.Yesterday: {
      return TitleOptionRange.Yesterday;
    }

    case ETypeOptionRanger.Last7days: {
      return TitleOptionRange.Last7days;
    }

    case ETypeOptionRanger.ThisMonth: {
      return TitleOptionRange.ThisMonth;
    }

    case ETypeOptionRanger.LastMonths: {
      return TitleOptionRange.LastMonths;
    }

    case ETypeOptionRanger.Last2Months: {
      return TitleOptionRange.Last2Months;
    }

    case ETypeOptionRanger.Last6Months: {
      return TitleOptionRange.Last6Months;
    }

    case ETypeOptionRanger.LastYear: {
      return TitleOptionRange.LastYear;
    }

    case ETypeOptionRanger.Custom: {
      if (range) return `${formatDateJP(range[0])} ~ ${formatDateJP(range[1])}`;
      return TitleOptionRange.AllTime;
    }

    case ETypeOptionRanger.AllTime:
    default: {
      return TitleOptionRange.AllTime;
    }
  }
};

export {
  OptionRanger,
  ETypeOptionRanger,
  selectQuicklyOption,
  formatDate,
  formatDateJP,
  renderTitleDropdown,
};
export type { IOptionRanger };
