import moment from 'moment';
import { DFormat } from '../../../../constants/app.constants';
import { MESSAGE_UPLOAD } from './config';

export const getErrorMessageWhenUpload = (
  status: MESSAGE_UPLOAD,
  nameCSV: string
): string => {
  switch (status) {
    case MESSAGE_UPLOAD.SUCCESS:
      return nameCSV;
    case MESSAGE_UPLOAD.NOT_FOUND:
      return 'mailList.list.csv.theCSVFileDataCannotBeFound';
    case MESSAGE_UPLOAD.ILLEGAL_FORMAT:
      return 'mailList.list.csv.illegalFormat';
    case MESSAGE_UPLOAD.MAX_BYTES_ERROR:
      return 'mailList.list.csv.fileSizeExceedsTheUpperLimit';
    case MESSAGE_UPLOAD.DEFAULT:
      return 'mailList.list.csv.pleaseSelectAFile';
    default:
      return 'mailList.list.csv.pleaseSelectAFile';
  }
};

export const getConditionUpload = (messageUpload: MESSAGE_UPLOAD) => {
  if (messageUpload === MESSAGE_UPLOAD.SUCCESS) {
    return false;
  } else {
    return true;
  }
};

export const getConditionStyleError = (messageUpload: MESSAGE_UPLOAD) => {
  if (
    messageUpload === MESSAGE_UPLOAD.SUCCESS ||
    messageUpload === MESSAGE_UPLOAD.DEFAULT
  ) {
    return false;
  } else {
    return true;
  }
};

export const downloadCSVData = (() => {
  const a: any = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  return (data: any, fileName: string) => {
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), data], {
        type: 'text/plain;charset=utf-8',
      }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

export const handleTimeDownload = () => {
  const date = moment(new Date()).format(DFormat);
  return `${date.replaceAll(' ', '').replaceAll('-', '').replaceAll(':', '')}`;
};
