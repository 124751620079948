// Profile
export const FETCH_USER_PROFILE = 'MY_PAGE_FETCH_USER_PROFILE';
export const FETCH_USER_PROFILE_LOADING = 'MY_PAGE_FETCH_USER_PROFILE_LOADING';
export const FETCH_USER_PROFILE_SUCCESS = 'MY_PAGE_FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_ERROR = 'MY_PAGE_FETCH_USER_PROFILE_ERROR';

export const FETCH_PREFECTURES = 'FETCH_JAPAN_PREFECTURES';
export const FETCH_PREFECTURES_SUCCESS = 'FETCH_JAPAN_PREFECTURES_SUCCESS';
export const FETCH_PREFECTURES_ERROR = 'FETCH_JAPAN_PREFECTURES_ERROR';

export const UPDATE_USER_PROFILE = 'MY_PAGE_UPDATE_USER_PROFILE';
export const UPDATE_PROFILE_PASSWORD = 'MY_PAGE_UPDATE_PROFILE_PASSWORD';
export const SEND_MAIL_TO_RESET_PASSWORD = 'SEND_MAIL_TO_RESET_PASSWORD';
export const VERIFY_CHANGE_EMAIL = 'VERIFY_CHANGE_EMAIL';
export const UPDATE_UNREAD_NOTIFICATIONS = 'UPDATE_UNREAD_NOTIFICATIONS';

// MY account - sub user
export const GET_SUB_USERS = 'MY_ACCOUNT_GET_SUB_USERS';
export const GET_SUB_USERS_SUCCESS = 'MY_ACCOUNT_GET_SUB_USERS_SUCCESS';
export const GET_SUB_USERS_ERROR = 'MY_ACCOUNT_GET_SUB_USERS_ERROR';

export const GET_SUB_USERS_INFORMATION_INVITE =
  'GET_SUB_USERS_INFORMATION_INVITE';
export const GET_SUB_USERS_INFORMATION_INVITE_SUCCESS =
  'GET_SUB_USERS_INFORMATION_INVITE_SUCCESS';
export const GET_SUB_USERS_INFORMATION_INVITE_ERROR =
  'GET_SUB_USERS_INFORMATION_INVITE_ERROR';

export const SUB_USER_GET_ALL_PERMISSIONS = 'SUB_USER_GET_ALL_PERMISSIONS';
export const SUB_USER_GET_ALL_PERMISSIONS_SUCCESS =
  'SUB_USER_GET_ALL_PERMISSIONS_SUCCESS';
export const SUB_USER_GET_ALL_PERMISSIONS_ERROR =
  'SUB_USER_GET_ALL_PERMISSIONS_ERROR';

export const SUB_USER_FOCUS_GET_PERMISSIONS = 'SUB_USER_FOCUS_GET_PERMISSIONS';
export const SUB_USER_FOCUS_GET_PERMISSIONS_LOADING =
  'SUB_USER_FOCUS_GET_PERMISSIONS_LOADING';
export const SUB_USER_FOCUS_GET_PERMISSIONS_SUCCESS =
  'SUB_USER_FOCUS_GET_PERMISSIONS_SUCCESS';
export const SUB_USER_FOCUS_GET_PERMISSIONS_ERROR =
  'SUB_USER_FOCUS_GET_PERMISSIONS_ERROR';

export const UPDATE_PERMISSIONS_SUB_USER =
  'MY_ACCOUNT_UPDATE_PERMISSIONS_SUB_USER';
export const REMOVE_SUB_USER = 'MY_ACCOUNT_REMOVE_SUB_USER';
export const SEND_INVITE_SUB_USER = 'MY_ACCOUNT_SEND_INVITE_SUB_USER';
export const RESEND_INVITE_SUB_USER = 'MY_ACCOUNT_RESEND_INVITE_SUB_USER';
export const CHECK_INVITATION_TOKEN_VALID =
  'SUB_USER_CHECK_INVITATION_TOKEN_VALID';
export const SUB_USER_ACCEPT_INVITATION = 'SUB_USER_ACCEPT_INVITATION';
export const SUB_USER_SIGN_UP = 'SUB_USER_SIGN_UP';
export const LOGOUT = 'USER_LOGOUT';

export const GET_ROLE_CURRENT_USER = 'USER_GET_ROLE_CURRENT_USER';
export const SET_ROLE_CURRENT_USER = 'USER_SET_ROLE_CURRENT_USER';

// company
export const GET_COMPANY_BY_ID = 'GET_COMPANY_BY_ID';
export const GET_ALL_COMPANY = 'GET_ALL_COMPANY';
export const UPDATE_COMPANY_SETTING = 'UPDATE_COMPANY_SETTING';
export const SWAP_COMPANY = 'SWAP_COMPANY';

// payment gateway
export const GET_PAYMENT_SETTING = 'GET_PAYMENT_SETTING';
export const DELETE_PAYMENT_SETTING = 'DELETE_PAYMENT_SETTING';
export const GET_DETAIL_PAYMENT_SETTING = 'GET_DETAIL_PAYMENT_SETTING';
export const CONNECT_STRIPE_ACCOUNT = 'CONNECT_STRIPE_ACCOUNT';
export const CONNECT_STRIPE_SUCCESS = 'CONNECT_STRIPE_SUCCESS';
export const UPDATE_METHOD_STRIPE = 'UPDATE_METHOD_STRIPE';
export const CREATE_GMO_ACCOUNT = 'CREATE_GMO_ACCOUNT';
export const UPDATE_METHOD_GMO = 'UPDATE_METHOD_GMO';
export const CREATE_PAYPAL_ACCOUNT = 'CREATE_PAYPAL_ACCOUNT';
export const UPDATE_METHOD_PAYPAL = 'UPDATE_METHOD_PAYPAL';
export const CREATE_UNIVAPAY_ACCOUNT = 'CREATE_UNIVAPAY_ACCOUNT';
export const UPDATE_METHOD_UNIVAPAY = 'UPDATE_METHOD_UNIVAPAY';
