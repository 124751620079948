// Types affiliate setting n001
export const FETCH_AFFILIATE_SETTING = 'FETCH_AFFILIATE_SETTING';
export const FETCH_AFFILIATE_SETTING_SUCCESS =
  'FETCH_AFFILIATE_SETTING_SUCCESS';

export const SUBMIT_AFFILIATE_SETTING = 'UPDATE_AFFILIATE_SETTING';
export const SUBMIT_AFFILIATE_SETTING_SUCCESS =
  'UPDATE_AFFILIATE_SETTING_SUCCESS';

export const SUBMIT_AFF_SETTING_UPDATE_RANK = 'SUBMIT_AFF_SETTING_UPDATE_RANK';
export const SUBMIT_AFF_SETTING_UPDATE_RANK_SUCCESS =
  'SUBMIT_AFF_SETTING_UPDATE_RANK_SUCCESS';

// Types affiliate partner n002
export const FETCH_PARTNER_LIST = 'FETCH_PARTNER_LIST';
export const FETCH_PARTNER_LIST_SUCCESS = 'FETCH_PARTNER_LIST_SUCCESS';

export const SUBMIT_PARTNER_LIST = 'SUBMIT_PARTNER_LIST';
export const SUBMIT_PARTNER_LIST_SUCCESS = 'SUBMIT_PARTNER_LIST_SUCCESS';

export const DELETE_PARTNER_LIST = 'DELETE_PARTNER_LIST';
export const DELETE_PARTNER_LIST_SUCCESS = 'DELETE_PARTNER_LIST_SUCCESS';

export const FETCH_DETAIL_PARTNER_LIST = 'FETCH_DETAIL_PARTNER_LIST';
export const FETCH_DETAIL_PARTNER_LIST_SUCCESS =
  'FETCH_DETAIL_PARTNER_LIST_SUCCESS';

export const UPDATE_PARTNER_LIST = 'UPDATE_PARTNER_LIST';

export const REDIRECT_AFFILIATE_PARTNER_SITE =
  'REDIRECT_AFFILIATE_PARTNER_SITE';

/*-------------------- SCREEN AFFILIATE BONUS * N004 --------------------------*/
export const FETCH_DATA_BONUS = 'FETCH_DATA_BONUS';
export const FETCH_DATA_BONUS_SUCCESS = 'FETCH_DATA_BONUS_SUCCESS';

export const DOWNLOAD_RANKING = 'DOWNLOAD_RANKING';
export const DOWNLOAD_CSV = 'DOWNLOAD_CSV';
export const DOWNLOAD_IDENTIFY = 'DOWNLOAD_IDENTIFY';

// Types affiliate subscriber n003
export const FETCH_SUBSCRIBER_LIST = 'FETCH_SUBSCRIBER_LIST';
export const FETCH_SUBSCRIBER_LIST_SUCCESS = 'FETCH_SUBSCRIBER_LIST_SUCCESS';
export const UPDATE_SUBSCRIBER_LIST = 'UPDATE_SUBSCRIBER_LIST';
export const UPDATE_SUBSCRIBER = 'UPDATE_SUBSCRIBER';
export const DOWNLOAD_SUBSCRIBER_PURCHARSE_CSV =
  'DOWNLOAD_SUBSCRIBER_PURCHARSE_CSV';
