import { call, put, takeLatest } from 'redux-saga/effects';

import * as TypesLoading from './../actionTypes/loadingActionTypes';
import * as Types from '../actions/tagManagement/types';
import * as tagService from '../../Clients/tags';
import {
  IParamsAddUsersIntoTag,
  IParamsCreateTag,
  IParamsDeleteGroupTag,
  IParamsDeleteUserFromTag,
  IParamsDownloadCSVListTagUser,
  IParamsDuplicateGroupTag,
  IParamsEditGroupTagName,
  IParamsFilterList,
  IParamsFitlerListTagUser,
  IParamsGetUserDetailTags,
  IParamsGetUsersNoTag,
  IParamsUpdateTagOfEndUser,
} from '../../Clients/tags/interface';
import { handleMessage } from '../../utilities/common.utilities';
import { EStatusHttp } from '../../Clients/interfaces';

interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
  error?: any;
}

function* createTag(action: {
  type: string;
  data: IParamsCreateTag;
  onSuccess: (data: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      tagService.createTag,
      action.data
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response);
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* editTag(action: {
  type: string;
  data: IParamsCreateTag;
  onSuccess: (data: any) => void;
  onError: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      tagService.editTag,
      action.data
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response);
    }
  } catch (error: any) {
    action.onError();
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getDetailTag(action: { type: string; uuid: string }) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      tagService.getDetailTag,
      action.uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_DETAIL_TAG_SUCCESS,
        data: response.data.data,
      });
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getListGroupTag() {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(tagService.getListGroupTag);
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_LIST_GROUP_TAG_SUCCESS,
        data: response.data?.data,
      });
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getListTag(action: {
  type: string;
  params: IParamsFilterList;
  onSuccess: (data: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      tagService.getListTag,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_LIST_TAG_SUCCESS,
        data: response.data?.data,
      });
      action.onSuccess(response.data?.data);
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* renameGroupTagName(action: {
  type: string;
  params: IParamsEditGroupTagName;
  onSuccess: () => void;
  onError: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      tagService.renameGroupTagName,
      action.params
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
  } catch (error: any) {
    action.onError();
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* deleteGroupTag(action: {
  type: string;
  params: IParamsDeleteGroupTag;
  onSuccess: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      tagService.deleteGroupTag,
      action.params
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* duplicateGroupTag(action: {
  type: string;
  params: IParamsDuplicateGroupTag;
  onSuccess: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      tagService.duplicateGroupTag,
      action.params
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* duplicateTag(action: {
  type: string;
  uuid: string;
  onSuccess: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      tagService.duplicateTag,
      action.uuid
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* deleteTag(action: {
  type: string;
  uuid: string;
  onSuccess: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      tagService.deleteTag,
      action.uuid
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* updateTagOfEndUser(action: {
  type: string;
  params: IParamsUpdateTagOfEndUser;
  onSuccess: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      tagService.updateTagOfEndUser,
      action.params
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getListTagUser(action: {
  type: string;
  tag_uuid: string;
  params: IParamsFitlerListTagUser;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      tagService.getListTagUser,
      action.tag_uuid,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_LIST_TAG_USER_SUCCESS,
        data: response.data?.data,
      });
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* deleteUserFromTag(action: {
  type: string;
  params: IParamsDeleteUserFromTag;
  onSuccess: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      tagService.deleteUserFromTag,
      action.params
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* downloadCSVListTagUser(action: {
  type: string;
  tag_uuid: string;
  params: IParamsDownloadCSVListTagUser;
  onSuccess: (data: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      tagService.downloadCSVListTagUser,
      action.tag_uuid,
      action.params
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data);
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getAllTags(action: { type: string; onSuccess: (data: any) => void }) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(tagService.getAllTags);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data);
      yield put({
        type: Types.GET_ALL_TAGS_SUCCESS,
        data: response.data.data,
      });
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getUsersNoTag(action: {
  type: string;
  params: IParamsGetUsersNoTag;
  onSuccess: (data: any) => void;
  onError: () => void;
}) {
  try {
    const response: APIResponseType = yield call(
      tagService.getUsersNoTag,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data);
    }
  } catch (error: any) {
    action.onError();
    handleMessage(error?.response);
  }
}

function* addUsersIntoTag(action: {
  type: string;
  tag_uuid: string;
  params: IParamsAddUsersIntoTag;
  onSuccess: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      tagService.addUsersIntoTag,
      action.tag_uuid,
      action.params
    );
    handleMessage(response);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* getUserDetailTags(action: {
  type: string;
  user_uuid: string;
  params: IParamsGetUserDetailTags;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      tagService.getUserDetailTags,
      action.user_uuid,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_USER_DETAIL_TAGS_SUCCESS,
        data: response.data.data,
      });
    }
  } catch (error: any) {
    handleMessage(error?.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

export default function* tagSaga() {
  yield takeLatest(Types.CREATE_TAG, createTag);
  yield takeLatest(Types.EDIT_TAG, editTag);
  yield takeLatest(Types.GET_DETAIL_TAG, getDetailTag);
  yield takeLatest(Types.GET_LIST_GROUP_TAG, getListGroupTag);
  yield takeLatest(Types.GET_LIST_TAG, getListTag);
  yield takeLatest(Types.RENAME_GROUP_TAG_NAME, renameGroupTagName);
  yield takeLatest(Types.DELETE_GROUP_TAG, deleteGroupTag);
  yield takeLatest(Types.DUPLICATE_GROUP_TAG, duplicateGroupTag);
  yield takeLatest(Types.DUPLICATE_TAG, duplicateTag);
  yield takeLatest(Types.DELETE_TAG, deleteTag);
  yield takeLatest(Types.UPDATE_TAG_OF_ENDUSER, updateTagOfEndUser);
  yield takeLatest(Types.GET_LIST_TAG_USER, getListTagUser);
  yield takeLatest(Types.DELETE_USER_FROM_TAG, deleteUserFromTag);
  yield takeLatest(Types.DOWNLOAD_CSV_LIST_TAG_USER, downloadCSVListTagUser);
  yield takeLatest(Types.GET_ALL_TAGS, getAllTags);
  yield takeLatest(Types.GET_USERS_NO_TAG, getUsersNoTag);
  yield takeLatest(Types.ADD_USERS_INTO_TAG, addUsersIntoTag);
  yield takeLatest(Types.GET_USER_DETAIL_TAGS, getUserDetailTags);
}
