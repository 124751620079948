export const CALL_API_ERROR = 'CALL_API_ERROR';

export const FETCH_DATA_FUNNEL_DOMAIN = 'FETCH_DATA_FUNNEL_DOMAIN';
export const FETCH_DATA_FUNNEL_DOMAIN_SUCCESS =
  'FETCH_DATA_FUNNEL_DOMAIN_SUCCESS';
export const FETCH_DATA_FUNNEL_DOMAIN_ERROR = 'FETCH_DATA_FUNNEL_DOMAIN_ERROR';

export const LOAD_MORE_FUNNEL_DOMAIN = 'LOAD_MORE_FUNNEL_DOMAIN';
export const LOAD_MORE_FUNNEL_DOMAIN_SUCCESS =
  'LOAD_MORE_FUNNEL_DOMAIN_SUCCESS';
export const LOAD_MORE_FUNNEL_DOMAIN_ERROR = 'LOAD_MORE_FUNNEL_DOMAIN_ERROR';

export const LOAD_MORE_FUNNEL_DOMAIN_SUCCESS_IN_UPDATE =
  'LOAD_MORE_FUNNEL_DOMAIN_SUCCESS_IN_UPDATE';

export const FETCH_DATA_LIST_EMAIL_INTEGRATIONS =
  'FETCH_DATA_LIST_EMAIL_INTEGRATIONS';
export const FETCH_DATA_LIST_EMAIL_INTEGRATIONS_SUCCESS =
  'FETCH_DATA_LIST_EMAIL_INTEGRATIONS_SUCCESS';
export const FETCH_DATA_LIST_EMAIL_INTEGRATIONS_ERROR =
  'FETCH_DATA_LIST_EMAIL_INTEGRATIONS_ERROR';

export const FETCH_DATA_EMAIL_INTEGRATIONS_DETAIL =
  'FETCH_DATA_EMAIL_INTEGRATIONS_DETAIL';
export const FETCH_DATA_EMAIL_INTEGRATIONS_DETAIL_SUCCESS =
  'FETCH_DATA_EMAIL_INTEGRATIONS_DETAIL_SUCCESS';
export const FETCH_DATA_EMAIL_INTEGRATIONS_DETAIL_ERROR =
  'FETCH_DATA_EMAIL_INTEGRATIONS_DETAIL_ERROR';

export const ADD_EMAIL_INTEGRATIONS = 'ADD_EMAIL_INTEGRATIONS';
export const ADD_EMAIL_INTEGRATIONS_SUCCESS = 'ADD_EMAIL_INTEGRATIONS_SUCCESS';
export const ADD_EMAIL_INTEGRATIONS_ERROR = 'ADD_EMAIL_INTEGRATIONS_ERROR';

export const DELETE_EMAIL_INTEGRATIONS = 'DELETE_EMAIL_INTEGRATIONS';
export const DELETE_EMAIL_INTEGRATIONS_SUCCESS =
  'DELETE_EMAIL_INTEGRATIONS_SUCCESS';
export const DELETE_EMAIL_INTEGRATIONS_ERROR =
  'DELETE_EMAIL_INTEGRATIONS_ERROR';

export const UPDATE_EMAIL_INTEGRATIONS = 'UPDATE_EMAIL_INTEGRATIONS';
export const UPDATE_EMAIL_INTEGRATIONS_SUCCESS =
  'UPDATE_EMAIL_INTEGRATIONS_SUCCESS';
export const UPDATE_EMAIL_INTEGRATIONS_ERROR =
  'UPDATE_EMAIL_INTEGRATIONS_ERROR';

export const FETCH_PAYMENT_INFORMATION = 'FETCH_PAYMENT_INFORMATION';
export const UPDATE_PAYMENT_INFORMATION = 'UPDATE_PAYMENT_INFORMATION';

export const FETCH_PAYMENT_HISTORY = 'FETCH_PAYMENT_HISTORY';
export const FETCH_PAYMENT_HISTORY_SUCCESS = 'FETCH_PAYMENT_HISTORY_SUCCESS';
export const FETCH_PAYMENT_HISTORY_ERROR = 'FETCH_PAYMENT_HISTORY_ERROR';
export const GET_ALL_PAGE = 'GET_ALL_PAGE';
export const GET_ALL_PAGE_SUCCESS = 'GET_ALL_PAGE_SUCCESS';
export const GET_ALL_PAGE_ERROR = 'GET_ALL_PAGE_ERROR';

export const VERIFY_FUNNEL_DOMAIN = 'VERIFY_FUNNEL_DOMAIN';
export const VERIFY_FUNNEL_DOMAIN_SUCCESS = 'VERIFY_FUNNEL_DOMAIN_SUCCESS';
export const VERIFY_FUNNEL_DOMAIN_ERROR = 'VERIFY_FUNNEL_DOMAIN_ERROR';

export const VERIFY_FUNNEL_DOMAIN_SSL = 'VERIFY_FUNNEL_DOMAIN_SSL';
export const VERIFY_FUNNEL_DOMAIN_SSL_SUCCESS =
  'VERIFY_FUNNEL_DOMAIN_SSL_SUCCESS';
export const VERIFY_FUNNEL_DOMAIN_SSL_ERROR = 'VERIFY_FUNNEL_DOMAIN_SSL_ERROR';

export const DELETE_FUNNEL_DOMAIN = 'DELETE_FUNNEL_DOMAIN';
export const DELETE_FUNNEL_DOMAIN_SUCCESS = 'DELETE_FUNNEL_DOMAIN_SUCCESS';
export const DELETE_FUNNEL_DOMAIN_ERROR = 'DELETE_FUNNEL_DOMAIN_ERROR';

export const UPDATE_FUNNEL_DOMAIN = 'UPDATE_FUNNEL_DOMAIN';
export const UPDATE_FUNNEL_DOMAIN_SUCCESS = 'UPDATE_FUNNEL_DOMAIN_SUCCESS';
export const UPDATE_FUNNEL_DOMAIN_ERROR = 'UPDATE_FUNNEL_DOMAIN_ERROR';

export const GET_FUNNEL_DOMAIN_DETAIL = 'GET_FUNNEL_DOMAIN_DETAIL';
export const GET_FUNNEL_DOMAIN_DETAIL_SUCCESS =
  'GET_FUNNEL_DOMAIN_DETAIL_SUCCESS';
export const GET_FUNNEL_DOMAIN_DETAIL_ERROR = 'GET_FUNNEL_DOMAIN_DETAIL_ERROR';

export const CREATE_FUNNEL_DOMAIN = 'CREATE_FUNNEL_DOMAIN';
export const ASSOCIATE_FUNNEL_TO_DOMAIN = 'ASSOCIATE_FUNNEL_TO_DOMAIN';
export const GET_ALL_FUNNEL = 'GET_ALL_FUNNEL';

export const GET_LIST_FACEBOOK_INTEGRATION = 'GET_LIST_FACEBOOK_INTEGRATION';
export const GET_LIST_FACEBOOK_INTEGRATION_SUCCESS =
  'GET_LIST_FACEBOOK_INTEGRATION_SUCCESS';

export const GET_LIST_FACEBOOK_PIXEL = 'GET_LIST_FACEBOOK_PIXEL';
export const GET_LIST_FACEBOOK_PIXEL_SUCCESS =
  'GET_LIST_FACEBOOK_PIXEL_SUCCESS';

export const REMOVE_FACEBOOK_PIXEL = 'REMOVE_FACEBOOK_PIXEL';

export const UPDATE_SUB_DOMAIN = 'UPDATE_SUB_DOMAIN';
export const COMPANY_INTEGRATIONS = 'COMPANY_INTEGRATIONS';
export const CREATE_INTEGRATION = 'CREATE_INTEGRATION';
export const GET_DETAIL_INTEGRATION = 'GET_DETAIL_INTEGRATION';
export const UPDATE_INTEGRATION = 'UPDATE_INTEGRATION';

export const GET_DOMAIN_RECORDS = 'GET_DOMAIN_RECORDS';
export const GET_DOMAIN_RECORDS_SUCCESS = 'GET_DOMAIN_RECORDS_SUCCESS';
export const UPDATE_LOADING_DOMAIN_RECORDS = 'UPDATE_LOADING_DOMAIN_RECORDS';

//stripeStripePromise
export const GET_STRIPE_PROMISE = 'GET_STRIPE_PROMISE';
export const POST_STRIPE_PAYMENT = 'POST_STRIPE_PAYMENT';
