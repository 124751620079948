import { all, fork } from 'redux-saga/effects';
import myPageSaga from './myPage';
import manageUserSaga from './manageUser';
import accountSettingSaga from './accountSetting';
import accountSubUserSaga from './accountSubUser';
import alternativeCharacterSaga from './mail/alternativeCharacter';
import mailConditionSaga from './mail/condition';
import uploadSaga from './upload';
import funnelManagement from './funnelManagement';
import membersiteManagement from './membersiteManagement';
import funnelSaga from './funnel';
import membersiteSaga from './membersite';
import productSaga from './product';
import authSaga from './auth';
import affiliateSaga from './affiliate';
import mailListUserSaga from './mail/mailListUser';
import reportsSaga from './reports/reports';
import tagSaga from './tag';
import commonSaga from './common';
import templateSaga from './template';
import timerSaga from './timer';
import eventSaga from './event';

export default function* rootSaga() {
  yield all([
    fork(myPageSaga),
    fork(manageUserSaga),
    fork(accountSettingSaga),
    fork(accountSubUserSaga),
    fork(alternativeCharacterSaga),
    fork(mailConditionSaga),
    fork(uploadSaga),
    fork(funnelManagement),
    fork(membersiteManagement),
    fork(funnelSaga),
    fork(membersiteSaga),
    fork(productSaga),
    fork(authSaga),
    fork(affiliateSaga),
    fork(mailListUserSaga),
    fork(reportsSaga),
    fork(tagSaga),
    fork(commonSaga),
    fork(templateSaga),
    fork(timerSaga),
    fork(eventSaga),
  ]);
}
