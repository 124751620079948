import { getPath } from '../helper';

export const API = {
  getPayMethod: () => getPath(`user/payment-methods`),
  getGroupList: () => getPath(`group/list/PRODUCT`),
  createProduct: () => getPath(`product/create`),
  getListGroupProducts: (
    currentPage: number,
    pageSize: number,
    groupUuids: string,
    sortName: string,
    sortType: string,
    sortGroup: string
  ) =>
    getPath(
      `products?page=${currentPage}&perPage=${pageSize}&group_uuids=${groupUuids}&sort_group=${sortGroup}&sort_type=${sortType}&sort_name=${sortName}`
    ),
  deleteProduct: () => getPath(`product/delete`),
  deleteGroupProduct: () => getPath(`group/delete`),
  copyGroupProduct: () => getPath(`group/copy`),
  copyProduct: () => getPath(`product/copy`),
  renameGroupProduct: () => getPath(`group/rename`),
  renameProduct: () => getPath(`product/rename`),
  getDetailProductByUUID: (uuid: string) => getPath(`product/${uuid}/detail`),
  updateProductByUUID: () => getPath(`product/update`),
  getStripePricesProduct: () => getPath(`product/stripe/prices`),
  getThinkificWithProduct: () => getPath(`thinkifics`),
  getBundleByThinkificId: (id: string) => getPath(`thinkific/${id}/products`),
  getOptionMemberSite: () => getPath(`funnel/step/page/by-type?type=13`),
};
