import { call, put, takeLatest } from 'redux-saga/effects';
import { handleMessage } from '../../../utilities/common.utilities';
import { EStatusHttp, IResponse } from '../../../Clients/interfaces';
import * as I from '../../../Clients/mail/interface';
import {
  deleteUserMailListItem,
  exportUserMailListCSV,
  getAccessFunnelsByFunnelAPI,
  getFunnelsByMailListAPI,
  getListEventDateFilter,
  getListUserMailList,
  postChangePurchaseDate,
  getMailListUsersSetting,
  putMailListUsersSetting,
  getMailListUsersSettingCalendar,
  getOptionsCalendar,
  putMailListUsersSettingCalendar,
  getSchedulesByToken,
  getExportConfig,
  updateExportConfig,
} from '../../../Clients/mail';
import * as Types from '../../actions/mailListUser/types';
import {
  HIDE_LOADING,
  SHOW_LOADING,
} from '../../actionTypes/loadingActionTypes';

function* getMailListUserAction(action: {
  type: string;
  uuid: string;
  payload: I.ParamsGetListUserMailList;
  onSuccess: (response: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: IResponse<any> = yield call(
      getListUserMailList,
      action.uuid,
      action.payload
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data);
    } else {
      handleMessage(response);
    }
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* getFunnelsByMailListAction(action: {
  type: string;
  params: I.ParamsGetFunnelsByMailList;
  isScroll: boolean;
}) {
  try {
    const response: IResponse<any> = yield call(
      getFunnelsByMailListAPI,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: action.isScroll
          ? Types.SCROLL_FUNNELS_BY_MAIL_LIST_SUCCESS
          : Types.FETCH_FUNNELS_BY_MAIL_LIST_SUCCESS,
        funnel: response.data?.data,
      });
    } else {
      handleMessage(response);
    }
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
  } finally {
    yield put({ type: Types.HIDE_LOADING_GET_DATA_COMPONENT });
  }
}

function* getAccessFunnelByFunnelAction(action: {
  type: string;
  uuid: string;
  params: I.ParamsGetAccessFunnelsByFunnel;
  isScroll: boolean;
}) {
  try {
    const response: IResponse<any> = yield call(
      getAccessFunnelsByFunnelAPI,
      action.uuid,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: action.isScroll
          ? Types.SCROLL_ACCESS_FUNNEL_BY_FUNNEL_SUCCESS
          : Types.FETCH_ACCESS_FUNNEL_BY_FUNNEL_SUCCESS,
        access_funnel: response.data?.data,
      });
    } else {
      handleMessage(response);
    }
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
  } finally {
    yield put({ type: Types.HIDE_LOADING_GET_DATA_COMPONENT });
  }
}

function* handleDeleteUserMailAction(action: {
  type: string;
  params: I.IParamsDeleteUserMailListItemInURL;
  onSuccess: (response?: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: IResponse<any> = yield call(
      deleteUserMailListItem,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
    }
    handleMessage(response);
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* fetchExportConfig(action: {
  type: string;
  uuid: string;
  onSuccess: (response: any) => void;
}) {
  try {
    const response: IResponse<any> = yield call(getExportConfig, action.uuid);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response?.data?.data);
    }
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* changeExportConfig(action: {
  type: string;
  uuid: string;
  params: I.ParamsExportConfig;
  onSuccess: (response?: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: IResponse<any> = yield call(
      updateExportConfig,
      action.uuid,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response?.data);
    }
    handleMessage(response);
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* handleDownloadCsvAction(action: {
  type: string;
  uuid: string;
  params: I.ParamsExportUserMailListCSV;
  onSuccess: (response: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: IResponse<any> = yield call(
      exportUserMailListCSV,
      action.uuid,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data);
    }
    handleMessage(response);
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* changePurchaseDate(action: {
  type: string;
  params: I.ParamsChangePurchaseDate;
  onSuccess: (response?: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: IResponse<any> = yield call(
      postChangePurchaseDate,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* getListEventDate(action: {
  type: string;
  uuid: string;
  onSuccess: (response: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: IResponse<any> = yield call(
      getMailListUsersSetting,
      action.uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response?.data?.data);
    }
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* putListEventDate(action: {
  type: string;
  uuid: string;
  params: I.ParamsMailListUsersSetting;
  onSuccess: (response?: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: IResponse<any> = yield call(
      putMailListUsersSetting,
      action.uuid,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
    }
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* getListEventDateFilters(action: {
  type: string;
  uuid: string;
  onSuccess: (response?: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: IResponse<any> = yield call(
      getListEventDateFilter,
      action.uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response?.data?.data);
    }
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* getDateCalendars(action: {
  type: string;
  uuid: string;
  onSuccess: (response?: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: IResponse<any> = yield call(
      getMailListUsersSettingCalendar,
      action.uuid
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data);
    }
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* getOptionsCalendars(action: {
  uuid: string;
  event_date: string;
  onSuccess: (response?: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: IResponse<any> = yield call(
      getOptionsCalendar,
      action.uuid,
      {
        event_date: action.event_date,
      }
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data?.data);
    }
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* updateScheduleCalendars(action: {
  uuid: string;
  schedule_uuid: string;
  schedule_date: string;
  onSuccess: (response?: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: IResponse<any> = yield call(
      putMailListUsersSettingCalendar,
      action.uuid,
      {
        schedule_uuid: action.schedule_uuid,
        schedule_date: action.schedule_date,
      }
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data?.data);
      handleMessage(response);
    }
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* getSchedulesByTokenMail(action: {
  token: string;
  event_date: string;
  onSuccess: (response?: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: IResponse<any> = yield call(getSchedulesByToken, {
      token: action.token,
      event_date: action.event_date,
    });
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data?.data);
    }
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

export default function* funnelPageSaga() {
  yield takeLatest(Types.GET_MAIL_LIST_USER, getMailListUserAction);
  yield takeLatest(Types.GET_FUNNELS_BY_MAIL_LIST, getFunnelsByMailListAction);
  yield takeLatest(
    Types.GET_ACCESS_FUNNEL_BY_FUNNEL,
    getAccessFunnelByFunnelAction
  );
  yield takeLatest(Types.DELETE_USER_MAIL_LIST, handleDeleteUserMailAction);
  yield takeLatest(Types.GET_EXPORT_CONFIG, fetchExportConfig);
  yield takeLatest(Types.UPDATE_EXPORT_CONFIG, changeExportConfig);
  yield takeLatest(Types.EXPORT_USER_MAIL_LIST_CSV, handleDownloadCsvAction);
  yield takeLatest(Types.CHANGE_PURCHASE_DATE, changePurchaseDate);
  yield takeLatest(Types.GET_LIST_EVENT_DATE, getListEventDate);
  yield takeLatest(Types.PUT_LIST_EVENT_DATE, putListEventDate);
  yield takeLatest(Types.LIST_EVENT_DATE_FILTER, getListEventDateFilters);
  yield takeLatest(Types.GET_DATE_CALENDAR, getDateCalendars);
  yield takeLatest(Types.GET_OPTIONS_CALENDAR, getOptionsCalendars);
  yield takeLatest(Types.PUT_SCHEDULE, updateScheduleCalendars);
  yield takeLatest(Types.GET_SCHEDULES_BY_TOKEN, getSchedulesByTokenMail);
}
