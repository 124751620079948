import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ConnectAuthen } from '../../../../hoc/ConnectAuthContainer';
import {
  getSharedTemPlate,
  postSharedTemPlate,
} from '../../../../store/actions/membersiteManagement';
import { MEMBERSITE } from '../../../../constants/menus';
import { AppState } from '../../../../store/reducers/rootReducer';
import { checkRoleButtonShare } from '../../../../helper/roleUser';

import './style.scss';

interface RouteParams {
  id: string;
}
export type SharedTemplateProps = RouteComponentProps<RouteParams>;

const SharedMembersiteTemplate: React.FC<SharedTemplateProps> = ({
  match,
  history,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [dataSetting, setDataSetting] = useState<any>(null);
  const profile = useSelector((state: AppState) => state.myPage.profile.data);
  const { role } = profile;

  const isEdit =
    role &&
    checkRoleButtonShare(
      {
        isFullRole: role?.is_full_role,
        isOwner: role?.is_owner,
        role: role?.role,
      },
      match.path
    );

  useEffect(() => {
    fetchAllSettingData();
  }, []);
  const fetchAllSettingData = async () => {
    dispatch(
      getSharedTemPlate(
        match.params.id,
        (res) => {
          setDataSetting(res);
        },
        () => {
          history.push(MEMBERSITE.LIST_MEMBERSITE_TEMPLATE);
        }
      )
    );
  };

  const handleCopyMembersiteItem = async () => {
    dispatch(
      postSharedTemPlate(match.params.id, () => {
        history.push(MEMBERSITE.LIST_MEMBERSITE_TEMPLATE);
      })
    );
  };
  return (
    <ConnectAuthen>
      <div id="shared-funnel-c006">
        <div className="row justify-content-center content-shared">
          <div className="col-12 content-wrapped">
            <div className="row text-center mt-2">
              <p className="txt-name">
                {t('templateDetail.memberSite.sharedTitle')}
                {dataSetting && dataSetting.name}
              </p>
              <p>{t('templateDetail.memberSite.sharedContent')}</p>
            </div>

            <div className="d-flex justify-content-center">
              <button
                className="button-share m-left"
                onClick={handleCopyMembersiteItem}
                disabled={isEdit}
              >
                {t('templateDetail.memberSite.shared.buttonShred')}
              </button>
              <button
                className="btn mkt-cannel-btn"
                onClick={() => {
                  history.push(MEMBERSITE.LIST_MEMBERSITE_TEMPLATE);
                }}
              >
                {t('cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ConnectAuthen>
  );
};

export default SharedMembersiteTemplate;
