import React from 'react';

interface ImageIconSkeletonProps {
  srcName: any;
  title?: string;
  alt?: string;
  className?: string;
  width?: number;
}

export const SkeletonImageIcon: React.FC<ImageIconSkeletonProps> = ({
  srcName,
  title,
  alt = '',
  className,
  width,
}) => {
  return (
    <img
      alt={alt}
      className={`${className}`}
      src={srcName}
      title={title}
      width={width}
    />
  );
};
