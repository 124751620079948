import { getPath } from '../helper';

export const API = {
  // Profile
  getUserProfile: () => getPath(`user/profile`),
  getPrefectures: () => getPath(`prefectures`),
  updateUserProfile: () => getPath(`user/profile/update`),
  updateProfilePassword: () => getPath(`user/password/update`),
  sendMailToResetPassword: () => getPath(`user/reset_password/send`),
  verifyChangeEmail: () => getPath(`user/email/change`),

  // Sub User setting
  getSubUsers: () => getPath(`user/sub`),
  getSubUserPermissions: (uuid?: string) => {
    const uuidParams = uuid ? `?uuid=${uuid}` : '';
    return getPath(`user/sub/roles${uuidParams}`);
  },
  getRoleCurrentuser: () => getPath(`user/sub/role`),

  inviteSubUser: () => getPath(`user/sub/invite`),
  resendInviteSubUser: () => getPath(`user/sub/resend/invite`),
  removeSubUser: () => getPath(`user/sub/delete`),
  updatePermissionsForSubUser: () => getPath(`user/sub/role/update`),
  checkInvitationTokenValid: () => getPath(`user/sub/valid-token`),
  getSubUserInformationInvite: (invitation_token: string) =>
    getPath(`user/sub/information-invite?invitation_token=${invitation_token}`),
  acceptSubUserInvitation: () => getPath(`user/sub/invitation/accept`),
  signUpSubUser: () => getPath(`user/signup`),
  logout: () => getPath(`user/logout`),

  // Acccount setting
  getDataListEmailIntegrations: (params: any) =>
    getPath(`mail-integrations?perPage=${params.perPage}&page=${params.page}`),
  getDataFunnelDomain: (params: any) =>
    getPath(
      `funnel/domains?perPage=${params.perPage}&page=${params.page}&search=${
        encodeURIComponent(params.search) || ''
      }`
    ),
  createEmailIntegrations: () => getPath(`mail-integrations/create`),
  deleteEmailIntegrations: () => getPath(`mail-integrations/delete`),
  getDataEmailIntegrationsDetail: (uuid: any) =>
    getPath(`mail-integrations/${uuid}`),
  updateEmailIntegrations: () => getPath(`mail-integrations/update`),
  fetchPaymentInformation: () => getPath(`user/payment`),
  updatePaymentInformation: () => getPath(`user/payment`),
  fetchPaymentHistory: (params: any) =>
    getPath(
      `user/payment/history?page=${params.page}&perPage=${params.perPage}&sort_type=${params.sortType}&sort_name=${params.sortName}`
    ),
  getAllPage: () => getPath(`funnel/pages-all`),
  getFunnelDetail: (uuid: string) => getPath(`funnel/domain/${uuid}`),
  deleteFunnelDomain: () => getPath(`funnel/domain/delete`),
  verifyFunnelDomain: () => getPath(`funnel/domain/verify`),
  verifyFunnelDomainSSL: () => getPath(`funnel/domain/verify-ssl`),
  updateFunnelDomain: () => getPath(`funnel/domain/update`),
  createFunnelDomain: () => getPath(`funnel/domain/create`),
  associateFunnelToDoMain: () => getPath(`funnel/domain/associate-funnel`),
  getAllFunnelInPageDomain: () => getPath(`funnels/by-sys-user`),
  getPaymentSetting: () => getPath(`user/payment-settings`),
  createPaypalAccount: () => getPath(`payment/paypal/connect`),
  createGMOAccount: () => getPath(`payment/gmo/connect`),
  createUnivapayAccount: () => getPath(`payment/univapay/connect`),
  connectStripeAccount: () => getPath(`payment/stripe/connect`),
  deletePaymentSetting: () => getPath(`user/payment-setting/delete`),
  updateMethodGMO: () => getPath(`user/payment-setting/gmo/update`),
  getDetailPaymentSetting: (uuid: string) =>
    getPath(`user/payment-setting/${uuid}`),
  updateMethodPaypal: () => getPath(`user/payment-setting/paypal/update`),
  updateMethodUnivapay: () => getPath(`user/payment-setting/univapay/update`),

  // facebook pixel
  deleteFacebookPixel: () => getPath(`funnel/domain/pixel/delete`),
  getListFacebookIntegration: () => getPath(`facebook-integrations`),
  getListFacebookPixel: (uuid: string) => getPath(`pixel?uuid=${uuid}`),

  //integrations
  getCompanyIntegrations: () => getPath(`company-integrations`),
  deleteIntegration: (companyIntegrationUuid: string) =>
    getPath(`company-integration/${companyIntegrationUuid}/delete`),
  createIntegration: () => getPath(`company-integration`),
  updateIntegration: (company_integration_uuid: string) =>
    getPath(`company-integration/${company_integration_uuid}/update`),
  getDetailIntegration: (uuid: string) =>
    getPath(`company-integration/${uuid}`),
  createFacebookIntegration: () => getPath(`company-integration`),
  updateFacebookIntegration: (uuid: string) =>
    getPath(`company-integration/${uuid}/update`),
  getDetailFacebookIntegration: (uuid: string) =>
    getPath(`company-integration/${uuid}/forms`),
  downLoadInvoicePdf: (uuid: string) => getPath(`user/payment/${uuid}/pdf`),

  // stripe
  getStripePromise: () => getPath(`user/payment`),
  postConnectStripe: () => getPath(`payment/stripe/connect`),
  updateMethodStripe: () => getPath(`user/payment-setting/stripe/update`),
};
