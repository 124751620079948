import * as Types from '../../actions/myPage/types';
import { SubUserReducer, RoleSubUser } from './accountSubUserInterface';

interface AccountSubUserState {
  subUsers: SubUserReducer;
  permissions: {
    data: any[];
    policies: any[];
    error?: string;
  };
  focusedSubUser: {
    isLoading: boolean;
    data?: any;
    error?: string;
  };
  informationInvite: {
    company: any;
    systemUser: any;
    error?: string;
  };
  roleCurrentUser: {
    role: RoleSubUser[];
    isFullRole: boolean;
    isOwner: boolean;
  };
}

const initialState: AccountSubUserState = {
  subUsers: {
    data: [],
    error: undefined,
  },
  permissions: {
    data: [],
    policies: [],
    error: undefined,
  },
  focusedSubUser: {
    isLoading: false,
    data: undefined,
    error: undefined,
  },
  informationInvite: {
    company: undefined,
    systemUser: undefined,
    error: undefined,
  },
  roleCurrentUser: {
    role: [],
    isFullRole: false,
    isOwner: false,
  },
};

export default function accountSubUserReducer(
  state: AccountSubUserState = initialState,
  action: any
): AccountSubUserState {
  switch (action.type) {
    // Get sub users
    case Types.GET_SUB_USERS_SUCCESS:
      return {
        ...state,
        subUsers: {
          ...state.subUsers,
          data: action.data,
        },
      };
    case Types.GET_SUB_USERS_ERROR:
      return {
        ...state,
        subUsers: {
          ...state.subUsers,
          error: action.error,
        },
      };
    // Get all permissions
    case Types.SUB_USER_GET_ALL_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          data: action.data.permissions,
          policies: action.data.policies,
        },
      };
    case Types.SUB_USER_GET_ALL_PERMISSIONS_ERROR:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          error: action.error,
        },
      };
    // Get permissions for sub user focus
    case Types.SUB_USER_FOCUS_GET_PERMISSIONS_LOADING:
      return {
        ...state,
        focusedSubUser: {
          ...state.focusedSubUser,
          isLoading: true,
        },
      };
    case Types.SUB_USER_FOCUS_GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        focusedSubUser: {
          ...state.focusedSubUser,
          data: action.data,
          isLoading: false,
        },
      };
    case Types.SUB_USER_FOCUS_GET_PERMISSIONS_ERROR:
      return {
        ...state,
        focusedSubUser: {
          ...state.focusedSubUser,
          error: action.error,
          isLoading: false,
        },
      };
    // Get sub user information invite
    case Types.GET_SUB_USERS_INFORMATION_INVITE_SUCCESS:
      return {
        ...state,
        informationInvite: {
          ...state.informationInvite,
          company: action.data.company,
          systemUser: action.data.system_user,
        },
      };
    case Types.GET_SUB_USERS_INFORMATION_INVITE_ERROR:
      return {
        ...state,
        informationInvite: {
          ...state.informationInvite,
          error: action.error,
        },
      };
    case Types.SET_ROLE_CURRENT_USER:
      return {
        ...state,
        roleCurrentUser: {
          ...state.roleCurrentUser,
          role: action.data.role,
          isFullRole: action.data.is_full_role,
          isOwner: action.data.is_owner,
        },
      };
    default:
      return state;
  }
}
