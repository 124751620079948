import { call, put, takeLatest } from 'redux-saga/effects';
import * as myPageService from '../../Clients/mypage';
import * as companyService from '../../Clients/companies';
import * as Types from '../actions/myPage/types';
import * as ActionTypes from '../actionTypes/loadingActionTypes';
import { ProfileEdit } from '../reducers/myPage/interface';
import {
  getErrorResponse,
  handleMessage,
} from '../../helper/httpClient.helper';
import { CACHE_TYPES } from '../../constants/app.constants';
import {
  IPayloadConnectStripe,
  IUnivapayAccount,
} from '../../Clients/mypage/interfaces';
import { HIDE_LOADING, SHOW_LOADING } from '../actionTypes/loadingActionTypes';
import { EStatusHttp, IResponse } from '../../Clients/interfaces';

interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
}

enum StatusCode {
  SUCCESS = 200,
  EXPIRED_PATH = 96,
  MAX_REQUEST = 429,
}

function* fetchUserProfile(actions: {
  type: string;
  success: (data: any) => void;
}) {
  try {
    yield put({ type: Types.FETCH_USER_PROFILE_LOADING });
    const response: APIResponseType = yield call(myPageService.getUserProfile);

    const timeZone = response.data.data?.current_company?.time_zone;
    localStorage.setItem(CACHE_TYPES.CURRENT_TIMEZONE, timeZone);

    yield put({
      type: Types.FETCH_USER_PROFILE_SUCCESS,
      data: response.data.data,
    });
    actions.success(response.data.data);
  } catch (e: any) {
    if (e.response) {
      handleMessage(e.response);
    }
  }
}

function* fetchPrefectures() {
  try {
    const response: APIResponseType = yield call(myPageService.getPrefectures);

    yield put({
      type: Types.FETCH_PREFECTURES_SUCCESS,
      data: response.data.data,
    });
  } catch (e: any) {
    handleMessage(e.response);
  }
}

function* updateUserProfile(action: {
  type: string;
  data: ProfileEdit;
  onSuccess: (data: boolean) => void;
  onError: (data: string) => void;
}) {
  const { data } = action;
  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      myPageService.updateUserProfile,
      data
    );

    yield put({ type: ActionTypes.HIDE_LOADING });
    if (response.data.code && response.data.code !== StatusCode.SUCCESS) {
      const error = getErrorResponse(response);
      return action.onError(error.message || '');
    }
    action.onSuccess(response.data.data);
  } catch (e: any) {
    yield put({ type: ActionTypes.HIDE_LOADING });
    handleMessage(e.response);
  }
}

function* updateProfilePassword(action: {
  type: string;
  data: ProfileEdit;
  onSuccess: (data: any) => void;
  onError: (data: string) => void;
}) {
  const { data } = action;
  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      myPageService.updateProfilePassword,
      data
    );

    yield put({ type: ActionTypes.HIDE_LOADING });
    if (response.data.code && response.data.code !== StatusCode.SUCCESS) {
      const error = getErrorResponse(response);
      return action.onError(error.message || '');
    }
    action.onSuccess(response.data.data);
  } catch (e: any) {
    yield put({ type: ActionTypes.HIDE_LOADING });
    action.onError(e.message);
  }
}

function* sendMailToResetPassword(action: {
  type: string;
  data: { email: string };
  onSuccess: (data: boolean) => void;
  onError: (data: string) => void;
}) {
  const { data } = action;
  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      myPageService.sendMailToResetPassword,
      data
    );

    yield put({ type: ActionTypes.HIDE_LOADING });
    action.onSuccess(response.data.data);
  } catch (e: any) {
    const error = getErrorResponse(e.response);
    action.onError(error.message || '');
    yield put({ type: ActionTypes.HIDE_LOADING });
  }
}

function* verifyChangeEmail(action: {
  type: string;
  data: { token: string };
  onSuccess: (data: any) => void;
  onError: (data: string) => void;
}) {
  const { data } = action;
  try {
    yield put({ type: ActionTypes.SHOW_LOADING });
    const response: APIResponseType = yield call(
      myPageService.verifyChangeEmail,
      data
    );

    yield put({ type: ActionTypes.HIDE_LOADING });
    if (response.data.code && response.data.code !== StatusCode.SUCCESS) {
      const error = getErrorResponse(response);
      return action.onError(error.message || '');
    }

    if (response.data.code && response.data.code === StatusCode.EXPIRED_PATH) {
      return action.onSuccess(StatusCode.EXPIRED_PATH);
    }

    action.onSuccess(response.data.data);
  } catch (e: any) {
    yield put({ type: ActionTypes.HIDE_LOADING });
    action.onError(e.message);
  }
}

function* getCompanyById(action: {
  type: string;
  data: any;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      companyService.getCompanyById,
      action.data
    );
    action.onSuccess(response);
  } catch (error: any) {
    action.onError(error.response);
  }
}

function* getAllCompany(action: {
  type: string;
  onSuccess: (data: any) => void;
  onError: () => void;
}) {
  try {
    const response: APIResponseType = yield call(
      companyService.getAllCompanies
    );
    action.onSuccess(response.data);
  } catch (error: any) {
    action.onError();
  }
}

function* updateCompanySetting(action: {
  type: string;
  data: any;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      companyService.updateCompanySetting,
      action.data
    );
    handleMessage(response);
    action.onSuccess(response.data);
  } catch (error: any) {
    action.onError(error.response);
  }
}

function* swapCompany(action: {
  type: string;
  data: any;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      companyService.swapCompany,
      action.data
    );
    action.onSuccess(response.data);
  } catch (error: any) {
    action.onError(error.response);
  }
}

function* getPaymentSetting(action: {
  type: string;
  onSuccess: (data: any) => void;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(myPageService.getPaymentSetting);
    if (result.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(result?.data?.data);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* deletePaymentSetting(action: {
  type: string;
  payload: any;
  onSuccess: () => void;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(
      myPageService.deletePaymentSetting,
      action.payload
    );
    if (result.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(result);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* getDetailPaymentSetting(action: {
  type: string;
  payload: string;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(
      myPageService.getDetailPaymentSetting,
      action.payload
    );
    if (result.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(result?.data?.data);
    }
  } catch (error: any) {
    action.onError(error.response);
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* connectStripeAccount(action: {
  type: string;
  payload: IPayloadConnectStripe;
  onSuccess: (data: any) => void;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(
      myPageService.postConnectStripe,
      action.payload
    );
    if (result.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(result?.data?.data);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* updateMethodStripe(action: {
  type: string;
  payload: IPayloadConnectStripe;
  onSuccess: () => void;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(
      myPageService.updateMethodStripe,
      action.payload
    );
    if (result.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(result);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* createGMOAccount(action: {
  type: string;
  payload: any;
  onSuccess: () => void;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(
      myPageService.createGMOAccount,
      action.payload
    );
    if (result.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(result);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* updateMethodGMO(action: {
  type: string;
  payload: any;
  onSuccess: () => void;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(
      myPageService.updateMethodGMO,
      action.payload
    );
    if (result.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(result);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* createPaypalAccount(action: {
  type: string;
  payload: any;
  onSuccess: () => void;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(
      myPageService.createPaypalAccount,
      action.payload
    );
    if (result.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(result);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* updateMethodPaypal(action: {
  type: string;
  payload: any;
  onSuccess: () => void;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(
      myPageService.updateMethodPaypal,
      action.payload
    );
    if (result.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(result);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* createUnivapayAccount(action: {
  type: string;
  payload: IUnivapayAccount;
  onSuccess: (data: any) => void;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(
      myPageService.createUnivapayAccount,
      action.payload
    );
    if (result.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(result?.data?.data);
      handleMessage(result);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* updateMethodUnivapay(action: {
  type: string;
  payload: IUnivapayAccount;
  onSuccess: (data: any) => void;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const result: IResponse<any> = yield call(
      myPageService.updateMethodUnivapay,
      action.payload
    );
    if (result.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(result?.data?.data);
      handleMessage(result);
    }
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

export default function* myPageSaga() {
  yield takeLatest(Types.FETCH_USER_PROFILE, fetchUserProfile);
  yield takeLatest(Types.FETCH_PREFECTURES, fetchPrefectures);
  yield takeLatest(Types.UPDATE_USER_PROFILE, updateUserProfile);
  yield takeLatest(Types.UPDATE_PROFILE_PASSWORD, updateProfilePassword);
  yield takeLatest(Types.SEND_MAIL_TO_RESET_PASSWORD, sendMailToResetPassword);
  yield takeLatest(Types.VERIFY_CHANGE_EMAIL, verifyChangeEmail);
  yield takeLatest(Types.GET_COMPANY_BY_ID, getCompanyById);
  yield takeLatest(Types.GET_ALL_COMPANY, getAllCompany);
  yield takeLatest(Types.UPDATE_COMPANY_SETTING, updateCompanySetting);
  yield takeLatest(Types.SWAP_COMPANY, swapCompany);
  yield takeLatest(Types.GET_PAYMENT_SETTING, getPaymentSetting);
  yield takeLatest(Types.DELETE_PAYMENT_SETTING, deletePaymentSetting);
  yield takeLatest(Types.GET_DETAIL_PAYMENT_SETTING, getDetailPaymentSetting);
  yield takeLatest(Types.CONNECT_STRIPE_ACCOUNT, connectStripeAccount);
  yield takeLatest(Types.UPDATE_METHOD_STRIPE, updateMethodStripe);
  yield takeLatest(Types.CREATE_GMO_ACCOUNT, createGMOAccount);
  yield takeLatest(Types.UPDATE_METHOD_GMO, updateMethodGMO);
  yield takeLatest(Types.CREATE_PAYPAL_ACCOUNT, createPaypalAccount);
  yield takeLatest(Types.UPDATE_METHOD_PAYPAL, updateMethodPaypal);
  yield takeLatest(Types.CREATE_UNIVAPAY_ACCOUNT, createUnivapayAccount);
  yield takeLatest(Types.UPDATE_METHOD_UNIVAPAY, updateMethodUnivapay);
}
