import React, { createRef, useEffect, useState } from 'react';
import { Col, Menu, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { COL_SIZE } from '../../../constants/app.constants';
import closeIcon from '../../../../assets/img/menu-icons/close-icon.png';
import { TButton } from '../../atoms/TButton';
import { EStatusHttp } from '../../../Clients/interfaces';
import { DefaultParam, ISearchParams, SearchScrollType } from './config';
import { getNotifications } from '../../../Clients/notification';
import ItemNotification from './ItemNotification';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { updateUnreadNotifications } from '../../../store/actions/myPage/profile';
import { AppState } from '../../../store/reducers/rootReducer';

interface PopupNotificationProps {
  toggleNotification?: boolean;
  handleToggleStatusNotification?: () => void;
}

const PopupNotification: React.FC<PopupNotificationProps> = ({
  toggleNotification,
  handleToggleStatusNotification,
}) => {
  const { t } = useTranslation();
  const notificationsHeader = createRef<HTMLDivElement>();
  const notificationsList = createRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const profile = useSelector((state: AppState) => state.myPage.profile.data);
  const [searchData, setSearchData] = useState<ISearchParams>(DefaultParam);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.addEventListener('event', handleScroll);
    return () => {
      window.removeEventListener('event', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (toggleNotification) {
      fetchNotificationAction(searchData, SearchScrollType.FETCH);
    } else {
      setSearchData(DefaultParam);
    }
    if (notificationsHeader.current && notificationsList.current) {
      notificationsList.current.scrollTo(
        0,
        notificationsHeader.current.offsetTop
      );
    }
  }, [toggleNotification]);

  const handleScroll = () => {
    if (notificationsList.current) {
      const clientHeight = notificationsList.current.clientHeight;
      const scrollHeight = notificationsList.current.scrollHeight;
      const scrollTop = notificationsList.current.scrollTop;
      if (Math.round(scrollTop) + clientHeight === scrollHeight && hasMore) {
        fetchNotifications();
        window.removeEventListener('scroll', handleScroll);
      }
    }
  };

  const fetchNotifications = async () => {
    if (hasMore) {
      fetchNotificationAction(
        {
          ...searchData,
          page: searchData.page + 1,
          per_page: 15,
        },
        SearchScrollType.HAS_MORE
      );
    }
  };

  const fetchNotificationAction = async (
    params: any,
    type: SearchScrollType
  ) => {
    setLoading(true);
    const res = await getNotifications({
      page: params.page,
      per_page: params.per_page,
    });
    if (res.status === EStatusHttp.HTTP_OK) {
      const dataProfile = profile;
      profile.unread_notification =
        res.data.data.unread_notification - params.per_page > 0
          ? res.data.data.unread_notification - params.per_page
          : 0;
      dispatch(updateUnreadNotifications(dataProfile));
      switch (type) {
        case SearchScrollType.FETCH:
          setSearchData((pre: any) => {
            return {
              ...pre,
              per_page: res.data.data.meta.per_page,
              page: res.data.data.meta.current_page,
              data: res.data.data.data,
            };
          });
        case SearchScrollType.HAS_MORE:
          setSearchData((pre: any) => {
            return {
              ...pre,
              per_page: res.data.data.meta.per_page,
              page: res.data.data.meta.current_page,
              data: searchData?.data.concat(res.data.data.data),
            };
          });
      }
      const maxPage = res.data.data.meta.total / 15;
      if (
        maxPage === res.data.data.meta.current_page ||
        maxPage < res.data.data.meta.current_page
      ) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    }
    setLoading(false);
  };

  return (
    <Menu id="notification-filters-dropdown">
      <Menu.Item className="notification-filter" key="notification-001">
        <Spin
          tip="Loading..."
          spinning={loading}
          style={{ color: 'var(--t-main-color)' }}
        >
          <Row
            className="notification-filter__header"
            ref={notificationsHeader}
          >
            <Col span={COL_SIZE.TWENTY_FOUR}>
              <p className="notification-filter__header-title">
                {t('global.common.title.notifications')}
              </p>
              <img
                className="notification-filter__header-img"
                onClick={handleToggleStatusNotification}
                src={closeIcon}
                alt="close-icon"
              />
            </Col>
          </Row>
          <Row
            className="notification-filter__search-box"
            ref={notificationsList}
            onScroll={handleScroll}
          >
            <Col span={COL_SIZE.TWENTY_FOUR}>
              {!searchData.data.length ? (
                <div className="notification-filter__search-box-no-data text-center w-100">
                  {t('common.text.noData')}
                </div>
              ) : (
                searchData.data.map((item: any, index: number) => {
                  return <ItemNotification data={item} key={index} />;
                })
              )}
            </Col>
          </Row>
          <Row>
            <Col span={COL_SIZE.TWENTY_FOUR} className="text-center">
              <TButton
                onClick={fetchNotifications}
                type="button"
                className="mkt-btn mt-customize"
                title={t('common.btnLoadMore')}
              />
            </Col>
          </Row>
        </Spin>
      </Menu.Item>
    </Menu>
  );
};

export default PopupNotification;
