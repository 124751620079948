import { deleteEmailIntegrationsInReducer } from '../../../utilities/common.utilities';
import * as Types from './../../actions/accountSetting/types';
import { AccountSetting, initialState } from './interfaces';

export default function accountSettingReducer(
  state: AccountSetting = initialState,
  action: any
): AccountSetting {
  switch (action.type) {
    case Types.FETCH_DATA_LIST_EMAIL_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        listEmailIntegrations: {
          ...state.listEmailIntegrations,
          currentPage: action.data.meta.current_page,
          totalPage: action.data.meta.last_page,
          totalItemInPage: action.data.meta.per_page,
          totalItem: action.data.meta.total,
          data: action.data.data,
        },
        error: {
          status: false,
          message: '',
        },
      };
    case Types.FETCH_DATA_FUNNEL_DOMAIN_SUCCESS:
      return {
        ...state,
        listFunnelDomain: {
          ...state.listFunnelDomain,
          loading: false,
          currentPage: action.data.custom_domain.meta.current_page,
          totalPage: action.data.custom_domain.meta.last_page,
          totalItemInPage: action.data.custom_domain.meta.per_page,
          totalItem: action.data.custom_domain.meta.total,
          data: action.data.custom_domain.data,
          system_domain: action.data.system_domain,
        },
        error: {
          status: false,
          message: '',
        },
      };
    case Types.FETCH_DATA_FUNNEL_DOMAIN:
      return {
        ...state,
        listFunnelDomain: {
          ...state.listFunnelDomain,
          loading: true,
        },
      };
    case Types.LOAD_MORE_FUNNEL_DOMAIN_SUCCESS:
      return {
        ...state,
        listFunnelDomain: {
          ...state.listFunnelDomain,
          loading: false,
          currentPage: action.data.custom_domain.meta.current_page,
          totalPage: action.data.custom_domain.meta.last_page,
          totalItemInPage: action.data.custom_domain.meta.per_page,
          totalItem: action.data.custom_domain.meta.total,
          data: state.listFunnelDomain.data.concat(
            action.data.custom_domain.data
          ),
          system_domain: action.data.system_domain,
        },
        error: {
          status: false,
          message: '',
        },
      };
    case Types.LOAD_MORE_FUNNEL_DOMAIN_SUCCESS_IN_UPDATE:
      return {
        ...state,
        listFunnelDomain: {
          ...state.listFunnelDomain,
          data: state.listFunnelDomain.data.concat(action.data),
        },
        error: {
          status: false,
          message: '',
        },
      };
    case Types.DELETE_EMAIL_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        listEmailIntegrations: {
          ...state.listEmailIntegrations,
          data: deleteEmailIntegrationsInReducer(
            state.listEmailIntegrations.data,
            action.data.uuidMail
          ),
        },
        error: {
          status: false,
          message: '',
        },
      };
    case Types.FETCH_DATA_EMAIL_INTEGRATIONS_DETAIL_SUCCESS:
      return {
        ...state,
        listEmailIntegrations: {
          ...state.listEmailIntegrations,
          emailIntegrationsDetail: action.data,
        },
        error: {
          status: false,
          message: '',
        },
      };
    case Types.FETCH_PAYMENT_HISTORY:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          loading: false,
        },
      };
    case Types.FETCH_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          loading: false,
          data: action.data.data,
          currentPage: action.data.meta.current_page,
          totalPage: action.data.meta.last_page,
          totalItemInPage: action.data.meta.per_page,
          totalItem: action.data.meta.total,
        },
      };
    case Types.GET_ALL_PAGE_SUCCESS:
      return {
        ...state,
        allPageFunnel: action.data,
        error: {
          status: false,
          message: '',
        },
      };
    case Types.GET_FUNNEL_DOMAIN_DETAIL_SUCCESS:
      return {
        ...state,
        funnelDomainDetail: {
          ...action.data,
          isIntegration: !!action.data.integration,
        },
        error: {
          status: false,
          message: '',
        },
      };
    case Types.UPDATE_FUNNEL_DOMAIN_SUCCESS:
      return {
        ...state,
        funnelDomainDetail: {
          ...state.funnelDomainDetail,
          default_page_uuid: action.data.defaultPage,
          not_found_page_uuid: action.data.notFoundPage,
        },
        error: {
          status: false,
          message: '',
        },
      };

    case Types.GET_LIST_FACEBOOK_INTEGRATION_SUCCESS:
      return {
        ...state,
        listFacebookIntegration: action.data,
      };

    case Types.GET_LIST_FACEBOOK_PIXEL_SUCCESS:
      return {
        ...state,
        listFacebookPixel: action.data,
        funnelDomainDetail: {
          ...state.funnelDomainDetail,
          integration: action.facebookIntegration,
        },
      };

    case Types.CALL_API_ERROR:
      return {
        ...state,
        error: {
          status: true,
          message: action.error,
        },
      };
    case Types.GET_DOMAIN_RECORDS_SUCCESS:
      return {
        ...state,
        domainDetailRecord: {
          ...state.domainDetailRecord,
          records: action.data,
        },
      };
    case Types.UPDATE_LOADING_DOMAIN_RECORDS:
      return {
        ...state,
        domainDetailRecord: {
          ...state.domainDetailRecord,
          isLoading: action.isLoading,
        },
      };
    default:
      return state;
  }
}
