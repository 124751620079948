import {
  SortField,
  SortOrder,
} from '../../../containers/Membersite/@DataTables/Components/types';
import { IMeta } from '../../reducers/membersite/interfaces';
import * as types from './types';

enum TypePageList {
  PAGE_RECYCLE = 0,
  PAGE_LIST = 1,
}

enum TypePageId {
  MEMBERSITE = '3',
  FUNNEL = '1',
}

interface PayloadPageListCourse {
  type: TypePageList;
  page: number;
  perPage: number;
  group_uuids: string;
  flag_favorite: number | string;
  sort_type: SortOrder;
  sort_name: SortField;
}

type IDataPageList = {
  group_name: string;
  uuid: string;
  group_uuid: string;
  flag_favorite: number;
  flag_publish: number;
  name: string;
  last_updated_time: string;
};

interface IStatePageListMembersite {
  data: IDataPageList[];
  meta: IMeta;
  error: string;
  payload: PayloadPageListCourse;
  domain: string;
}

interface ActionsCallBack {
  success: (data: string) => void;
  error: () => void;
}

const fetchPageListMembersite = (payload: PayloadPageListCourse) => ({
  type: types.FETCH_PAGE_LIST_MEMBERSITE,
  payload,
});

const fetchPageListMembersiteSuccess = (data: IStatePageListMembersite) => ({
  type: types.FETCH_PAGE_LIST_MEMBERSITE_SUCCESS,
  data,
});

const fetchPageListMembersiteFailed = (error: string) => ({
  types: types.FETCH_PAGE_LIST_MEMBERSITE_FAILED,
  error,
});

const fetchHtmlPageTemplate = (data: string, action: ActionsCallBack) => ({
  type: types.FETCH_HTML_PAGE_TEMPLATE,
  data,
  onSuccess: action.success,
  onError: action.error,
});

export {
  fetchPageListMembersite,
  fetchPageListMembersiteSuccess,
  fetchPageListMembersiteFailed,
  fetchHtmlPageTemplate,
  TypePageList,
  TypePageId,
};

export type { PayloadPageListCourse, IDataPageList, IStatePageListMembersite };
