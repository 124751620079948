import axiosClient from '..';
import { ParamFilterPartner } from '../../containers/Affiliate/Partner/List/config';
import {
  IParamsFetchAffiliateBonus,
  IParamsFetchAffiliateSubscriber,
  IParamsUpdateSubscriber,
} from '../../store/reducers/affiliate/interface';
import { API } from './api';
import { getParamsDownloadDataBonus, getParamsFetchDataBonus } from './config';

// Affiliate setting n001
export const fetchAffiliateSettingApi = () =>
  axiosClient.get(API.fetchAffiliateSettingApi);

export const submitAffiliateSettingApi = (params: any) =>
  axiosClient.post(API.fetchAffiliateSettingApi, params, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const submitSettingUpdateRankApi = (params: number) =>
  axiosClient.post(API.submitAffSettingUpdateRankApi, params);

// Affiliate partner n002
export const getPartnerListApi = (params: ParamFilterPartner) =>
  axiosClient.get(API.getAffiliatePartner, { params });

export const exportPartnerListCSV = (params: any) =>
  axiosClient.get(API.exportPartnerListCSV, { params });

export const getDetailPartnerApi = (params: string) =>
  axiosClient.get(API.getPartnerDetail(params));

export const deletePartnerApi = (params: any) =>
  axiosClient.post(API.deleleteAffiliatePartner, params);

export const updatePartnerApi = (params: any) =>
  axiosClient.post(API.updatePartner, params);

export const redirectAffiliatePartnerSiteApi = (params: string) =>
  axiosClient.get(API.redirectAffiliatePartnerSite(params));

export const fetchDataBonusApi = (meta: IParamsFetchAffiliateBonus) => {
  const params = getParamsFetchDataBonus(meta);
  return axiosClient.get(API.fetchDataBonus, { params });
};

export const downloadDataBonusCSV = (meta: IParamsFetchAffiliateBonus) => {
  const params = getParamsDownloadDataBonus(meta);
  return axiosClient.get(API.downloadDataBonusCSV, { params });
};

export const downloadDataBonusRanking = (meta: IParamsFetchAffiliateBonus) => {
  const params = getParamsDownloadDataBonus(meta);
  return axiosClient.get(API.downloadDataBonusRanking, { params });
};

export const downloadIdentifyApi = (params: string) =>
  axiosClient.get(API.downloadIdentify(params), {
    responseType: 'arraybuffer',
  });

// Affiliate subscribers n003
export const getAffiliateSubscribersApi = (
  params: IParamsFetchAffiliateSubscriber
) => axiosClient.get(API.getAffiliateSubscribers, { params });

export const updateAffiliateSubscriberApi = (params: IParamsUpdateSubscriber) =>
  axiosClient.post(API.updateAffiliateSubscriber, params);

export const downloadDataSubscriberCSVApi = (params: any) =>
  axiosClient.get(API.downloadDataSubscriberCSV, { params });
