import * as Types from './../../actions/funnelManagement/types';
import * as TypesFunnel from './../../actions/funnel/types';
import {
  FunnelManagement,
  initialFunnelManagement,
  FunnelDetailLoading,
  dataFunnelDetailDefault,
} from './interface';
import { changeObjectStepName } from '../../../utilities/common.utilities';

export default function funnelManagementReducer(
  state: FunnelManagement = initialFunnelManagement,
  action: any
): FunnelManagement {
  switch (action.type) {
    case Types.SHOW_lOADING:
      return {
        ...state,
        loadingComponent: action.data,
      };
    case Types.FETCH_LIST_TEMPLATE_SUCCESS:
      return {
        ...state,
        paramsFilter: action.params,
        listTemplate: action.data,
      };
    case Types.RENAME_FUNNEL_SUCCESS:
      return {
        ...state,
        funnelDetail: {
          ...state.funnelDetail,
          name: action.name,
        },
      };
    case Types.RENAME_STEP_FUNNEL_SUCCESS:
      return {
        ...state,
        funnelDetail: {
          ...state.funnelDetail,
          steps: changeObjectStepName(
            state.funnelDetail.steps,
            action.uuid,
            action.name,
            action.funnel_step_subcategory_id
          ),
        },
        stepFunnelDetail: {
          ...state.stepFunnelDetail,
          name: action.name,
        },
      };
    case Types.RENAME_FORM_FUNNEL_SUCCESS:
      return {
        ...state,
        stepFunnelDetail: {
          ...state.stepFunnelDetail,
          form_name: action.form_name,
        },
      };
    case Types.DELETE_STEP_FUNNEL:
    case TypesFunnel.FETCH_THE_GROUP_FUNNEL_LIST:
    case TypesFunnel.FETCH_LIST_FUNNEL:
    case Types.FETCH_FUNNEL_DETAIL:
      return {
        ...state,
        funnelDetail: dataFunnelDetailDefault,
      };

    case Types.FETCH_FUNNEL_DETAIL_SUCCESS:
      return {
        ...state,
        funnelDetail: action.data,
      };

    case Types.FETCH_STEP_FUNNEL_DETAIL:
      return {
        ...state,
        loading: true,
        stepFunnelDetail: null,
      };
    case Types.FETCH_STEP_FUNNEL_DETAIL_SUCCESS:
      return {
        ...state,
        stepFunnelDetail: action.data,
        loading: false,
      };

    case Types.FETCH_STEP_SETTING_SUCCESS:
      return {
        ...state,
        stepSettingParams: action.data,
      };

    case Types.HIDE_LOADING:
      return {
        ...state,
        loadingComponent: FunnelDetailLoading.HIDE,
      };
    case Types.UPDATE_STEP_FUNNEL:
      return {
        ...state,
        stepFunnelDetail: {
          ...state.stepFunnelDetail,
          pages: action.data,
        },
      };
    case Types.POST_START_AB_TEST_SUCCESS:
      return {
        ...state,
        stepFunnelDetail: {
          ...state.stepFunnelDetail,
          split_testing: true,
        },
      };

    // access link funnel
    case Types.FETCH_LIST_ACCESS_FUNNEL_SUCCESS:
      return {
        ...state,
        listFunnelAccess: action.data,
      };
    case Types.SHOW_LOADING_ACCESS_LINK:
      return {
        ...state,
        loadingModalAccessLink: true,
      };

    case Types.HIDDEN_LOADING_ACCESS_LINK:
      return {
        ...state,
        loadingModalAccessLink: false,
      };
    case Types.TOGGLE_MODAL_SETTING_ACCESS_LINK:
      return {
        ...state,
        isOpenModalAccessLink: action.flag,
      };

    // affiliate funnel
    case Types.GET_PRODUCT_FUNNEL_COMPLETE: {
      return {
        ...state,
        productFunnel: action.productFunnel,
      };
    }
    case Types.GET_AFFILIATE_FUNNEL_COMPLETE: {
      return {
        ...state,
        affiliateFunnel: action.affiliateFunnel,
      };
    }
    case Types.GET_PRODUCT_PLAN_COMPLETE: {
      return {
        ...state,
        productPlan: action.productPlan,
      };
    }

    case Types.SUBMIT_AFFILIATE_FUNNEL_COMPLETE: {
      return {
        ...state,
        funnelDetail: {
          ...state.funnelDetail,
          has_affiliate_funnel: true,
        },
      };
    }
    case Types.FUNNEL_STEP_UUID_SELECTED_SUCCESS: {
      return {
        ...state,
        funnelStepUuidSelected: action.funnelStepUuidSelected,
      };
    }

    case Types.GET_ALL_FUNNEL_STEP_BY_GROUP_FUNNEL_SUCCESS:
      return {
        ...state,
        allFunnelStepByGroupFunnel: action.data,
      };
    case Types.FETCH_LIST_ARCHIVED_PAGE:
      return {
        ...state,
        listArchivedPage: action.data,
        paramsFilterListArchivedPage: action.params,
      };
    case Types.SET_PARAMS_FILTER:
      return {
        ...state,
        paramsFilter: action.data,
      };
    default:
      return state;
  }
}
