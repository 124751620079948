import axiosClient from '..';
import { endPoints } from './config';
import {
  IChangeNameTemplates,
  IDuplicateTemplates,
  IParamsListBlockTemplates,
  IPageTemplates,
  IParamsTemplateDetail,
  ITemplateByIdType,
  IParamsCategoriesBlockTemplate,
  IParamsUpdateBlockTemplate,
} from './interface';

export const getPageListTemplates = (params: IPageTemplates) => {
  return axiosClient.get(endPoints.getPageListTemplates(), { params });
};

export const getPageListMembersiteTemplates = (params: IPageTemplates) => {
  return axiosClient.get(endPoints.getPageListMembersiteTemplates(), {
    params,
  });
};

export const duplicateTemplates = (params: IDuplicateTemplates) => {
  return axiosClient.post(endPoints.duplicateTemplates(), params);
};

export const duplicateMembersiteTemplates = (params: IDuplicateTemplates) => {
  return axiosClient.post(endPoints.duplicateMembersiteTemplates(), params);
};

export const changeNameTemplates = (params: IChangeNameTemplates) => {
  return axiosClient.post(endPoints.changeNameTemplates(), params);
};

export const changeNameMembersiteTemplates = (params: IChangeNameTemplates) => {
  return axiosClient.post(endPoints.changeNameMembersiteTemplates(), params);
};

export const getTemplateById = (params: IParamsTemplateDetail) => {
  return axiosClient.get(endPoints.getTemplateById(), { params });
};

export const getMembersiteTemplateById = (params: { uuid: string }) => {
  return axiosClient.get(endPoints.getMembersiteTemplateById(), { params });
};

export const deleteTemplateById = (params: any) => {
  return axiosClient.post(endPoints.deleteTemplateById(), params);
};

export const deleteMembersiteTemplateById = (params: { uuid: string }) => {
  return axiosClient.post(endPoints.deleteMembersiteTemplateById(), params);
};

export const sharedTemplates = (params: any) => {
  return axiosClient.post(endPoints.sharedTemplates(), params);
};

export const getTemplateByIdfunnel = (params: ITemplateByIdType) => {
  return axiosClient.get(endPoints.sharedTemplates(), { params });
};

export const sharedMembersiteTemplates = (params: ITemplateByIdType) => {
  return axiosClient.post(endPoints.sharedMembersiteTemplates(), params);
};

export const getTemplateByIdMembersite = (params: ITemplateByIdType) => {
  return axiosClient.get(endPoints.sharedMembersiteTemplates(), { params });
};

// Block templates
export const getListBlockTemplates = (params: IParamsListBlockTemplates) =>
  axiosClient.get(endPoints.getListBlockTemplates(), { params });

export const getAllCategoriesBlockTemplate = (
  params: IParamsCategoriesBlockTemplate
) => axiosClient.get(endPoints.getAllCategoriesBlockTemplate(), { params });

export const updateBlockTemplate = (params: IParamsUpdateBlockTemplate) =>
  axiosClient.post(endPoints.updateBlockTemplate(), params);

export const getBlockTemplateDetail = (uuid: string) =>
  axiosClient.get(endPoints.getBlockTemplateDetail(uuid));

export const deleteBlockTemplate = (uuid: string) =>
  axiosClient.post(endPoints.deleteBlockTemplate(uuid));
