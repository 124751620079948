export const GET_ALTERNATIVE_CHARACTERS = 'GET_ALTERNATIVE_CHARACTERS';
export const GET_ALTERNATIVE_CHARACTERS_SUCCESS =
  'GET_ALTERNATIVE_CHARACTERS_SUCCESS';
export const GET_ALTERNATIVE_CHARACTERS_ERROR =
  'GET_ALTERNATIVE_CHARACTERS_ERROR';

export const ALTERNATIVE_CHARACTER_GET_USER_FORM_TOKEN =
  'ALTERNATIVE_CHARACTER_GET_USER_FORM_TOKEN';

export const ALTERNATIVE_CHARACTER_UPDATE_USER_FORM =
  'ALTERNATIVE_CHARACTER_UPDATE_USER_FORM';

export const ALTERNATIVE_CHARACTER_GET_PAYMENT_ORDER_INFO =
  'ALTERNATIVE_CHARACTER_GET_PAYMENT_ORDER_INFO';

export const ALTERNATIVE_CHARACTER_UPDATE_PAYMENT_UNIVAPAY =
  'ALTERNATIVE_CHARACTER_UPDATE_PAYMENT_UNIVAPAY';

export const RENAME_STEP_MAIL = 'RENAME_STEP_MAIL';

export const GET_LIST_ALL_MAIL_LIST_WITH_TRASHED =
  'GET_LIST_ALL_MAIL_LIST_WITH_TRASHED';
export const GET_LIST_ALL_MAIL_LIST_WITH_TRASHED_SUCCESS =
  'GET_LIST_ALL_MAIL_LIST_WITH_TRASHED_SUCCESS';
export const GET_LIST_ALL_MAIL_LIST_WITH_TRASHED_ERROR =
  'GET_LIST_ALL_MAIL_LIST_WITH_TRASHED_ERROR';
export const SEND_NOW_STATUS = 'SEND_NOW_STATUS';
export const UPDATE_MAIL_SUBCRIBE = 'UPDATE_MAIL_SUBCRIBE';
export const GET_LIST_FUNNEL_STEPS = 'GET_LIST_FUNNEL_STEPS';
export const GET_LIST_MAIL_LIST = 'GET_LIST_MAIL_LIST';

export const GET_DATA_MAIL_LIST_AND_CONDITION_MAIL =
  'GET_DATA_MAIL_LIST_AND_CONDITION_MAIL';
//Remind mail
export const GET_MAIL_INTEGRATION = 'GET_MAIL_INTEGRATION';
export const GET_LIST_ALL_GROUP_REMIND_MAIL = 'GET_LIST_ALL_GROUP_REMIND_MAIL';
export const GET_REMIND_MAIL_DETAIL = 'GET_REMIND_MAIL_DETAIL';
export const CREATE_REMIND_EMAIL = 'CREATE_REMIND_EMAIL';
export const UPDATE_REMIND_MAIL = 'UPDATE_REMIND_MAIL';
export const GET_LIST_REMIND_MAIL = 'GET_LIST_REMIND_MAIL';
export const COPY_GROUP_REMIND_MAIL = 'COPY_GROUP_REMIND_MAIL';
export const DELETE_GROUP_REMIND_MAIL = 'DELETE_GROUP_REMIND_MAIL';
export const RENAME_GROUP_REMIND_MAIL = 'RENAME_GROUP_REMIND_MAIL';
export const COPY_REMIND_MAIL = 'COPY_REMIND_MAIL';
export const DELETE_REMIND_MAIL = 'DELETE_REMIND_MAIL';
export const CREATE_REMIND_MAIL_ITEM = 'CREATE_REMIND_MAIL_ITEM';
export const UPDATE_REMIND_MAIL_ITEM = 'UPDATE_REMIND_MAIL_ITEM';
export const COPY_REMIND_MAIL_ITEM = 'COPY_REMIND_MAIL_ITEM';
export const DELETE_REMIND_MAIL_ITEM = 'DELETE_REMIND_MAIL_ITEM';
export const FETCH_FIXED_PHRASE = 'FETCH_FIXED_PHRASE';
export const GET_CONDITION_ITEM_PAGE_FORM = 'GET_CONDITION_ITEM_PAGE_FORM';
export const GET_DATA_LIST_AND_CONDITION_MAIL =
  'GET_DATA_LIST_AND_CONDITION_MAIL';
export const REMIND_MAIL_CONTENT_DETAIL = 'REMIND_MAIL_CONTENT_DETAIL';
export const SEND_MAIL_TEST = 'SEND_MAIL_TEST';
export const SUBMIT_REMIND_MAIL_CONTENT = 'SUBMIT_REMIND_MAIL_CONTENT';
export const GET_MAIL_LIST_BY_ID = 'GET_MAIL_LIST_BY_ID';
export const PUBLISH_REMIND_MAIL = 'PUBLISH_REMIND_MAIL';
export const CANCEL_SCHEDULE_EVENT_DATE = 'CANCEL_SCHEDULE_EVENT_DATE';
export const CHANGE_SCHEDULE_CALENDAR = 'CHANGE_SCHEDULE_CALENDAR';
export const UPDATE_MAIL_LIST = 'UPDATE_MAIL_LIST';
export const GET_STATUS_IMPORT_CSV = 'GET_STATUS_IMPORT_CSV';
export const CREATE_USER_BY_IMPORT_CSV = 'CREATE_USER_BY_IMPORT_CSV';
