export const FETCH_LIST_TEMPLATE = 'FETCH_LIST_TEMPLATE';
export const FETCH_LIST_TEMPLATE_SUCCESS = 'FETCH_LIST_TEMPLATE_SUCCESS';

export const FETCH_FUNNEL_DETAIL = 'FETCH_FUNNEL_DETAIL';
export const FETCH_FUNNEL_DETAIL_SUCCESS = 'FETCH_FUNNEL_DETAIL_SUCCESS';

export const FETCH_STEP_FUNNEL_DETAIL = 'FETCH_STEP_FUNNEL_DETAIL';
export const FETCH_STEP_FUNNEL_DETAIL_SUCCESS =
  'FETCH_STEP_FUNNEL_DETAIL_SUCCESS';

export const RENAME_FUNNEL = 'RENAME_FUNNEL';
export const RENAME_FUNNEL_SUCCESS = 'RENAME_FUNNEL_SUCCESS';

export const RENAME_STEP_FUNNEL = 'RENAME_STEP_FUNNEL';
export const RENAME_STEP_FUNNEL_SUCCESS = 'RENAME_STEP_FUNNEL_SUCCESS';

export const DELETE_STEP_FUNNEL = 'DELETE_STEP_FUNNEL';
export const DELETE_STEP_FUNNEL_SUCCESS = 'DELETE_STEP_FUNNEL_SUCCESS';

export const CREATE_STEP_FUNNEL = 'CREATE_STEP_FUNNEL';
export const CREATE_STEP_FUNNEL_SUCCESS = 'CREATE_STEP_FUNNEL_SUCCESS';

export const INSTALL_TEMPLATE = 'INSTALL_TEMPLATE';
export const INSTALL_TEMPLATE_SUCCESS = 'INSTALL_TEMPLATE_SUCCESS';

export const CHANGE_TEMPLATE_URL = 'CHANGE_TEMPLATE_URL';
export const CHANGE_TEMPLATE_URL_SUCCESS = 'CHANGE_TEMPLATE_URL_SUCCESS';

export const RENAME_FORM_FUNNEL = 'RENAME_FORM_FUNNEL';
export const RENAME_FORM_FUNNEL_SUCCESS = 'RENAME_FORM_FUNNEL_SUCCESS';

export const SUBMIT_SETTING_STEP_FUNNEL = 'SUBMIT_SETTING_STEP_FUNNEL';
export const SUBMIT_SETTING_STEP_FUNNEL_SUCCESS =
  'SUBMIT_SETTING_STEP_FUNNEL_SUCCESS';

export const FETCH_STEP_SETTING = 'FETCH_STEP_SETTING';
export const FETCH_STEP_SETTING_SUCCESS = 'FETCH_STEP_SETTING_SUCCESS';

export const RESTORES_STEP_SETTING = 'RESTORES_STEP_SETTING';
export const RESTORES_STEP_SETTING_SUCCESS = 'RESTORES_STEP_SETTING_SUCCESS';

export const FETCH_LIST_RESTORES = 'FETCH_LIST_RESTORES';
export const FETCH_LIST_RESTORES_SUCCESS = 'FETCH_LIST_RESTORES_SUCCESS';

export const SHOW_lOADING = 'SHOW_lOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const FETCH_LIST_ACCESS_FUNNEL = 'FETCH_LIST_ACCESS_FUNNEL';
export const FETCH_LIST_ACCESS_FUNNEL_SUCCESS =
  'FETCH_LIST_ACCESS_FUNNEL_SUCCESS';

export const ADD_LINK_ACCESS_FUNNEL = 'ADD_LINK_ACCESS_FUNNEL';
export const ADD_LINK_ACCESS_FUNNEL_SUCCESS = 'ADD_LINK_ACCESS_FUNNEL_SUCCESS';

export const DELETE_LINK_ACCESS = 'DELETE_LINK_ACCESS';
export const DELETE_LINK_ACCESS_SUCCESS = 'DELETE_LINK_ACCESS_SUCCESS';

export const UPDATE_LINK_ACCESS_FUNNEL = 'UPDATE_LINK_ACCESS_FUNNEL';
export const UPDATE_LINK_ACCESS_FUNNEL_SUCCESS =
  'UPDATE_LINK_ACCESS_FUNNEL_SUCCESS';

export const SHOW_LOADING_ACCESS_LINK = 'SHOW_LOADING_ACCESS_LINK';
export const HIDDEN_LOADING_ACCESS_LINK = 'HIDDEN_LOADING_ACCESS_LINK';

export const TOGGLE_MODAL_SETTING_ACCESS_LINK =
  'TOGGLE_MODAL_SETTING_ACCESS_LINK';
export const HIDDEN_MODAL_SETTING_ACCESS_LINK =
  'HIDDEN_MODAL_SETTING_ACCESS_LINK';

export const GET_PRODUCT_FUNNEL = 'GET_PRODUCT_FUNNEL';
export const GET_PRODUCT_FUNNEL_COMPLETE = 'GET_PRODUCT_FUNNEL_COMPLETE';

export const GET_AFFILIATE_FUNNEL = 'GET_AFFILIATE_FUNNEL';
export const GET_AFFILIATE_FUNNEL_COMPLETE = 'GET_AFFILIATE_FUNNEL_COMPLETE';

export const SUBMIT_AFFILIATE_FUNNEL = 'SUBMIT_AFFILIATE_FUNNEL';
export const SUBMIT_AFFILIATE_FUNNEL_COMPLETE =
  'SUBMIT_AFFILIATE_FUNNEL_COMPLETE';

export const GET_PRODUCT_PLAN = 'GET_PRODUCT_PLAN';
export const GET_PRODUCT_PLAN_COMPLETE = 'GET_PRODUCT_PLAN_COMPLETE';

export const FUNNEL_STEP_UUID_SELECTED_SUCCESS =
  'FUNNEL_STEP_UUID_SELECTED_SUCCESS';
export const GET_ALL_FUNNEL_STEP_BY_GROUP_FUNNEL =
  'GET_ALL_FUNNEL_STEP_BY_GROUP_FUNNEL';
export const GET_ALL_FUNNEL_STEP_BY_GROUP_FUNNEL_SUCCESS =
  'GET_ALL_FUNNEL_STEP_BY_GROUP_FUNNEL_SUCCESS';
export const CREATE_PAGE_VARIATION = 'CREATE_PAGE_VARIATION';
export const DECLARE_PAGE_WINNER = 'DECLARE_PAGE_WINNER';
export const LIST_ARCHIVED_PAGE = 'LIST_ARCHIVED_PAGE';
export const FETCH_LIST_ARCHIVED_PAGE = 'FETCH_LIST_ARCHIVED_PAGE';
export const UPDATE_FUNNEL_STEP = 'UPDATE_FUNNEL_STEP';
export const RESTORE_PAGE = 'RESTORE_PAGE';
export const UPDATE_TRAFFIC = 'UPDATE_TRAFFIC';
export const SET_PARAMS_FILTER = 'SET_PARAMS_FILTER';

export const UPDATE_STEP_FUNNEL = 'UPDATE_STEP_FUNNEL';

export const POST_START_AB_TEST = 'POST_START_AB_TEST';
export const POST_START_AB_TEST_SUCCESS = 'POST_START_AB_TEST_SUCCESS';
export const POST_DELATE_PAGE = 'POST_DELATE_PAGE';

export const COMPONENT_UNMOUNTED = 'COMPONENT_UNMOUNTED';
