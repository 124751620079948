// use library node_modules
import { Pagination } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

// use helper and config, services
import { NextPrevWrapper, PaginationWrapper } from './style';

interface PaginationProps {
  totalPage: number;
  currentPage: number;
  pageSize: number;
  onChangePageTable: (params: number) => void;
}

export const PaginationComponent: React.FC<PaginationProps> = ({
  totalPage,
  currentPage,
  pageSize,
  onChangePageTable,
}) => {
  const { t } = useTranslation();

  function nextPrevComponent(
    page: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    originalElement: React.ReactElement<HTMLElement>
  ) {
    if (type === 'prev') {
      const isActive = page === 0 ? 'd-none' : 'd-block';
      return (
        <NextPrevWrapper className={`prev ${isActive}`}>
          <LeftOutlined />
        </NextPrevWrapper>
      );
    }

    if (type === 'next') {
      return (
        <NextPrevWrapper className="next">
          {t('global.common.nextPage')}
          <RightOutlined />
        </NextPrevWrapper>
      );
    }
    return originalElement;
  }

  return (
    <PaginationWrapper justify="center">
      <Pagination
        current={currentPage}
        total={totalPage}
        pageSize={pageSize}
        size="small"
        itemRender={nextPrevComponent}
        onChange={onChangePageTable}
        showSizeChanger={false}
      />
    </PaginationWrapper>
  );
};
