import { API_BASE_URL } from '../config';

export const API = {
  fetchAffiliateSettingApi: `${API_BASE_URL}/affiliate/company/setting-center`,
  submitAffSettingUpdateRankApi: `${API_BASE_URL}/affiliate/company/setting-center/update-rank-url`,
  getAffiliatePartner: `${API_BASE_URL}/affiliate/company/partners`,
  exportPartnerListCSV: `${API_BASE_URL}/affiliate/company/partners/download-csv`,
  getPartnerDetail: (partner_uuid: string) =>
    `${API_BASE_URL}/affiliate/company/partner/${partner_uuid}`,
  deleleteAffiliatePartner: `${API_BASE_URL}/affiliate/company/partner/delete`,
  updatePartner: `${API_BASE_URL}/affiliate/company/partner/update`,
  redirectAffiliatePartnerSite: (partner_uuid: string) =>
    `${API_BASE_URL}/partner/${partner_uuid}/view`,
  /* ---------------------- N004 - affiliate bonus ----------------- */
  fetchDataBonus: `${API_BASE_URL}/affiliate/company/partners/bonus`,
  downloadDataBonusCSV: `${API_BASE_URL}/affiliate/company/partners/bonus/download-csv`,
  downloadDataBonusRanking: `${API_BASE_URL}/affiliate/company/partners/bonus/download-ranking`,
  downloadIdentify: (partner_uuid: string) =>
    `${API_BASE_URL}/affiliate/company/partner/${partner_uuid}/downloadIdentify`,

  /* ---------------------- N003 - affiliate subscriber ----------------- */
  getAffiliateSubscribers: `${API_BASE_URL}/affiliate/company/partner/subscribers`,
  updateAffiliateSubscriber: `${API_BASE_URL}/affiliate/company/partner/subscriber/update`,
  downloadDataSubscriberCSV: `${API_BASE_URL}/affiliate/company/partner/subscribers/download-csv`,
};
