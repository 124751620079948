import { IPayLoadGroupFunnelList } from '../../actions/funnel/group';
import * as Types from '../../actions/funnel/types';

export interface IFirstStep {
  funnel_domain?: string;
  path: string;
  sub_domain: string;
}

export interface IFunnel {
  uuid: string;
  first_step: IFirstStep;
  flag_favorite: number;
  flag_publish: number;
  name: string;
  total_steps: number;
  updated_at: string;
}

interface IGroupFunnel {
  name: string;
  uuid: string;
  funnels: IFunnel[];
}

export interface IMeta {
  active_funnel_count: number;
  active_funnel_step_count: number;
  current_page: number;
  last_page: number;
  per_page: number;
  total: number;
}

interface IStateGroupFunnels {
  meta: IMeta;
  groups: IGroupFunnel[];
  payload: IPayLoadGroupFunnelList;
}

export const initialGroupFunnels: IStateGroupFunnels = {
  meta: {
    current_page: 1,
    last_page: 1,
    per_page: 1,
    total: 1,
  },
  groups: [],
  payload: {
    page: 1,
    perPage: 20,
    sort_type: 'desc',
    sort_name: '',
    sort_group: '',
    group_uuids: '',
    flag_favorite: '',
  },
};

export default function groupFunnelReducer(
  state: IStateGroupFunnels = initialGroupFunnels,
  action: any
): IStateGroupFunnels {
  switch (action.type) {
    case Types.FETCH_THE_GROUP_FUNNEL_LIST: {
      return { ...state };
    }
    case Types.FETCH_THE_GROUP_FUNNEL_LIST_SUCCESS: {
      return {
        ...state,
        ...action.data,
      };
    }
    case Types.FETCH_THE_GROUP_FUNNEL_LIST_FAILED: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
}

export type { IStateGroupFunnels };
