import { Row } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  IChangeNameTemplates,
  IPageTemplates,
} from '../../../../Clients/templates/interface';
import {
  DEFAULT_OPTIONS,
  TemplateTypeId,
} from '../../../../constants/app.constants';
import { ConnectAuthen } from '../../../../hoc/ConnectAuthContainer';
import { PaginationComponent } from '../../../Funnel/List/@DataTables/Components';
import ElementCardTemplate from './commons/Element';
import FilterHeaderTemplates from './commons/FilterHeader';
import HeaderPageTemplates from './commons/Header';
import {
  getListCompanyTemplates,
  updateTemplates,
} from '../../../../store/actions/membersiteManagement';
import { AppState } from '../../../../store/reducers/rootReducer';

const PageListMembersiteTemplates: React.FC<any> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const dataTables = useSelector(
    (state: AppState) => state.membersite.listCompanyTemplates
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const page = urlParams.get('page') || '';
    const perPage = urlParams.get('per_page') || '';
    const templateType = urlParams.get('page_template_type') || '';
    const name = urlParams.get('name') || '';

    const newParams = {
      page: Number(page) || DEFAULT_OPTIONS.page,
      per_page: Number(perPage) || 24,
      name: name || '',
      page_template_type: Number(templateType) || TemplateTypeId.allTemplate,
    };

    dispatch(getListCompanyTemplates(newParams));
  }, [search]);

  const redirectUrl = (params: IPageTemplates) => {
    history.push({
      search: `?page=${params.page}&per_page=${
        params.per_page
      }&page_template_type=${
        params.page_template_type || TemplateTypeId.allTemplate
      }&name=${params.name}`,
    });
  };

  const onChangeTemplateTypes = (pageType: TemplateTypeId) => {
    redirectUrl({
      ...dataTables.params,
      name: '',
      page_template_type: pageType,
      page: DEFAULT_OPTIONS.page,
    });
  };

  const onChangePageSize = (pageSize: number) => {
    redirectUrl({
      ...dataTables.params,
      per_page: pageSize,
      page: DEFAULT_OPTIONS.page,
    });
  };

  const handleFilterByName = (keyWord: string) => {
    redirectUrl({ ...dataTables.params, name: keyWord, page: 1 });
  };

  const updateNameTemplate = (value: IChangeNameTemplates) => {
    dispatch(
      updateTemplates(value, () => {
        const params = {
          ...dataTables.params,
          page: DEFAULT_OPTIONS.page,
          name: '',
          page_template_type: TemplateTypeId.allTemplate,
        };
        redirectUrl(params);
      })
    );
  };

  return (
    <ConnectAuthen>
      <HeaderPageTemplates
        handleFilterByName={handleFilterByName}
        valueNameDefault={dataTables.params.name}
      />
      <FilterHeaderTemplates
        onChangePageSize={onChangePageSize}
        onChangeTemplateTypes={onChangeTemplateTypes}
        option={{
          currentPage: dataTables.params.page,
          pageSize: dataTables.params.per_page,
          totalPage: dataTables.total,
        }}
        data={dataTables.params}
      />
      {!!dataTables.data.length && (
        <Row className="mt-3" gutter={[16, 16]}>
          {dataTables.data.map((el) => {
            return (
              <ElementCardTemplate
                data={el}
                key={el.uuid}
                updateNameTemplate={updateNameTemplate}
              />
            );
          })}
        </Row>
      )}

      {!dataTables.data.length && (
        <Row justify="center" align="middle" className="mt-3">
          {t('templateDetail.memberSite.list.noData')}
        </Row>
      )}
      {!!dataTables.data.length && (
        <Row justify="center" className="mt-3">
          <PaginationComponent
            totalPage={dataTables.total}
            currentPage={dataTables.params.page}
            pageSize={dataTables.params.per_page}
            onChangePageTable={(e) =>
              redirectUrl({ ...dataTables.params, page: e })
            }
          />
        </Row>
      )}
    </ConnectAuthen>
  );
};

export default PageListMembersiteTemplates;
