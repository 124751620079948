import { API_BASE_URL } from '../config';

const API = {
  login: `${API_BASE_URL}/user/login`,
  reSentVerifyAccount: `${API_BASE_URL}/user/resend/email/active`,
  logout: `${API_BASE_URL}/user/logout`,
  signup: `${API_BASE_URL}/user/signup`,
  forgotPassword: `${API_BASE_URL}/user/reset_password/send`,
  activeAccount: `${API_BASE_URL}/user/active`,
  loginTwoAuthen: `${API_BASE_URL}/user/login/socialite`,
  registerTwoAuthen: `${API_BASE_URL}/user/signup/socialite`,
  changeNewPassword: `${API_BASE_URL}/user/reset_password/change`,
  checkTokenInvalid: `${API_BASE_URL}/check/token`,
  getPackagePlans: `${API_BASE_URL}/plans`,
  getIncomingInvoice: `${API_BASE_URL}/plan/invoice/upcoming`,
  changeSubscriptionPlan: `${API_BASE_URL}/plan/subscription`,
  cancelSubscriptionPlan: `${API_BASE_URL}/plan/subscription/cancel`,
  cancelSubscriptionPlanNext: `${API_BASE_URL}/plan/subscription/change/cancel`,
  getMaintenanceDetail: `${API_BASE_URL}/maintain`,
};

export default API;
