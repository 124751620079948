import {
  LINK_BY_PAYMENT_METHODS,
  LINK_BY_MAIL_USER,
} from './../../../components/pages/ManageUser/common/constants';

export interface InfoUser {
  email?: string;
  phone?: string;
  zipCode?: string;
  address?: string;
  uuid?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
}

export enum TypeErrorUser {
  NOT_ERROR = 'NO_ERROR',
  FETCH_DATA = 'FETCH_DATA',
  UPDATE = 'UPDATE',
}

export interface DataUser {
  statistics?: {
    ltv?: number;
    product?: number;
    spent?: number;
    mail_list?: number;
    courses: number;
    tag?: number;
  };
  tags: Array<{ name: string; created_at: string; uuid: string }>;
  user?: {
    uuid?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    address?: string;
    phone?: number;
    post_code?: string;
    full_name?: string;
    user_name: string;
  };
}

export interface ICoursesNotEnrolled {
  uuid: string;
  name: string;
}

export interface ManageUser {
  userInfoUpdate: {
    data: InfoUser;
    error?: TypeErrorUser;
    messageError?: string;
    dataError?: any;
  };
  statisticsUser: any;
  mailList: {
    currentPage: number;
    totalPage: number;
    totalItemInPage: number;
    totalItem: number;
    data: any;
    userInfo?: any;
    typePage: string;
  };
  blackList: any;
  popupErrorAddEmailBlackList: {
    isShow: boolean;
    emailFails: any;
    numberEmail: number;
  };
  methodPayment: any;
  error: {
    status: boolean;
    message: string;
  };
  loadingGetPaymentMethod: boolean;
  mailsList: any[];
  listProduct: any[];
  groupNameProduct: any[];
  groupNameMail: any[];
  itemSelectFilterUser: any[];
  userDetail: DataUser;
}

export const initialState: ManageUser = {
  userInfoUpdate: {
    data: {},
    error: TypeErrorUser.NOT_ERROR,
    messageError: '',
    dataError: null,
  },
  statisticsUser: {
    currentPage: 1,
    totalPage: 10,
    totalItemInPage: 20,
    totalItem: 60,
    data: [],
    userInfo: null,
    perPage: 20,
    typePage: LINK_BY_PAYMENT_METHODS.ONE_TIME,
  },
  mailList: {
    currentPage: 1,
    totalPage: 10,
    totalItemInPage: 20,
    totalItem: 60,
    data: [],
    userInfo: null,
    typePage: LINK_BY_MAIL_USER.LIST,
  },
  blackList: {
    currentPage: 1,
    totalPage: 10,
    totalItemInPage: 20,
    totalItem: 60,
    data: [],
  },
  popupErrorAddEmailBlackList: {
    isShow: false,
    emailFails: [],
    numberEmail: 0,
  },
  methodPayment: [],
  error: {
    status: false,
    message: '',
  },
  loadingGetPaymentMethod: false,
  mailsList: [],
  listProduct: [],
  groupNameProduct: [],
  groupNameMail: [],
  itemSelectFilterUser: [],
  userDetail: {
    statistics: {
      ltv: 0,
      product: 0,
      spent: 0,
      mail_list: 0,
      courses: 0,
      tag: 0,
    },
    tags: [],
    user: {
      uuid: '',
      first_name: '',
      last_name: '',
      email: '',
      address: '',
      phone: 0,
      post_code: '',
      full_name: '',
      user_name: '',
    },
  },
};
