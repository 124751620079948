import i18next from 'i18next';
import * as Yup from 'yup';

declare module 'Yup' {
  interface ArraySchema<T> {
    unique(field: string, message: string): ArraySchema<T>;
  }
}

Yup.addMethod(Yup.array, 'unique', function (field, message) {
  return this.test('unique', message, function (array: any) {
    const uniqueData = Array.from(
      new Set(array.map((row: any) => row[field]?.toLowerCase()))
    );
    const isUnique = array.length === uniqueData.length;
    if (isUnique) {
      return true;
    }
    const index = array.findIndex(
      (row: any, i: number) => row[field]?.toLowerCase() !== uniqueData[i]
    );

    if (!array[index] || array[index][field] === '') {
      return true;
    }
    return this.createError({
      path: `${this.path}.${index}.${field}`,
      message,
    });
  });
});

// Messages definition
export const minMsg = (min: number) => `${min}文字以上で入力してください  `;
export const maxMsg = (max: number) => `${max}文字以内で入力してください。`;
// regex email
export const MSKNRegExp = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,63}$/g;
// regex domain
export const MSKNRegValidateDomain = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/;
export const MSKNMsgDomain = 'ドメイン名の形式が正しくありません。';
// messenger Alphabet
export const MSKNMsg = '英字を入力してください';
// regex password
export const PWDRegExp = /^[\w-\.!@#$%^&*()_+-=:;,.?/\\\|<>'" ]+$/g;
const stringNotNumber = /^[^0-9]+$/;
const specialCharacters = /^[^<>%$=]*$/;

//
const REGEX_PHONE_JAPAN = /^[-\d\s+()]{10,20}$/;
const REGEX_WITH_DASH_IN_PHONE = /^.*-{1,20}.*$/;

// Solution for manager enduser
const REGEX_PHONE_JAPAN_NEW = /^[0-9]+$/;
const REGEX_POST_CODE_NEW = /^[0-9]+$/;

// regex all space password
export const spaceAllRegExp = /^[^\s].*[^\s]$/;
export const spaceAllMsg =
  'パスワードの先頭や末尾にスペースを使用しないでください。';

// regex path url
export const urlPathRegExp =
  /^((http|https):\/\/)?(www.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+([\w\W]+)?/g;

export const REGEX_IP_ADDRESS =
  /^(\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b)$|^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;

export const stringNotValidationMax = () => Yup.string().max(100, maxMsg(100));

export const stringIsNotNumber = () =>
  stringNotValidationMax().matches(
    stringNotNumber,
    '数字が含まれてはいけません。'
  );

export const stringValidationTrim = ({
  required = 'この項目は入力が必須です。',
}) => Yup.string().required(required).trim(required);

export const inputValidationMaxLengthTrim = ({
  required = 'この項目は入力が必須です。',
}) => Yup.string().required(required).trim(required).max(100, maxMsg(100));

export const stringValidation = ({ required = 'この項目は入力が必須です。' }) =>
  Yup.string().required(required);

export const stringNotValidation = () => Yup.string();

export const maxLengthInput = (maxlength: any) =>
  stringNotValidation().max(maxlength, maxMsg(maxlength));

export const linkValidation = (length: any) =>
  // stringValidation({ required: 'この項目は入力が必須です。' })
  Yup.string().max(length, maxMsg(length)).matches(PWDRegExp, MSKNMsg);

export const MethodSCHEMA = Yup.object().shape({
  id: stringValidation({}),
  description: stringValidation({}),
});

// solution for manager enduser
export const phoneValidationNew = (maxLength = 100) =>
  Yup.string()
    .max(maxLength, maxMsg(maxLength))
    .matches(REGEX_PHONE_JAPAN_NEW, i18next.t('messageErrorNumberPhone'));

export const phoneValidation = () =>
  Yup.string()
    .max(100, maxMsg(100))
    .matches(REGEX_PHONE_JAPAN, i18next.t('messageErrorNumberPhone'))
    .matches(
      REGEX_WITH_DASH_IN_PHONE,
      i18next.t('messageErrorNumberPhoneWithDash')
    );

export const postCodeValidation = () => Yup.string().max(100, maxMsg(100));

// Solution for manager enduser
export const postCodeValidationNew = (maxLength = 100) =>
  Yup.string()
    .max(maxLength, maxMsg(maxLength))
    .matches(REGEX_POST_CODE_NEW, i18next.t('messageErrorPostCode'));

export const emailValidation = ({
  email = 'メールアドレスの形式が正しくありません。',
  required = 'この項目は入力が必須です。',
}) =>
  stringValidation({ required })
    .email(email)
    .max(100, maxMsg(100))
    .matches(MSKNRegExp, email);

export const confirmEmailValidation = ({
  ref = 'email',
  matched = '新しいメールアドレスと、確認用のメールアドレスが一致しません。',
  required = 'この項目は入力が必須です。',
}) => stringValidation({ required }).oneOf([Yup.ref(ref)], matched);

export const passwordValidation = ({
  required = 'この項目は入力が必須です。',
}) =>
  stringValidation({ required })
    .min(8, minMsg(8))
    .max(100, maxMsg(100))
    .matches(PWDRegExp, MSKNMsg)
    .matches(spaceAllRegExp, spaceAllMsg);

export const confirmPasswordValidation = ({
  ref = 'password',
  matched = 'パスワードと、確認用のパスワードが一致しません。',
  required = 'この項目は入力が必須です。',
}) =>
  stringValidation({ required })
    .oneOf([Yup.ref(ref)], matched)
    .matches(PWDRegExp, MSKNMsg);

export const inputValidation = ({ required = 'この項目は入力が必須です。' }) =>
  stringValidation({ required }).max(100, maxMsg(100));

export const createValidationSchema = (schema: any) =>
  Yup.object().shape(schema);

export const stringValidationWithMaxLength = (
  maxLength: any,
  { required = 'この項目は入力が必須です。' }
) => Yup.string().required(required).max(maxLength, maxMsg(maxLength));

export const stringValidationSpecialCharacters = (maxLength: any) =>
  stringValidationWithMaxLength(maxLength, {}).matches(
    specialCharacters,
    i18next.t('product.create.validateName')
  );

export const numberValidationMaxLengthTrim = (
  maxLength: number,
  titleMaxLength: number,
  { required = 'この項目は入力が必須です。' }
) => Yup.number().required(required).max(maxLength, maxMsg(titleMaxLength));

export const numberValidation = ({ required = 'この項目は入力が必須です。' }) =>
  Yup.number()
    .required(required)
    .min(0, '日数を0～1000の範囲で入力してください。')
    .max(1000, '日数を0～1000の範囲で入力してください。');

export const stringValidationMaxLengthTrim = (
  maxLength: any,
  { required = 'この項目は入力が必須です。' }
) =>
  Yup.string()
    .required(required)
    .max(maxLength, maxMsg(maxLength))
    .trim(required);

export const emailNotRequiredValidation = ({
  email = 'メールアドレスの形式が正しくありません。',
}) =>
  Yup.string().email(email).max(100, maxMsg(100)).matches(MSKNRegExp, MSKNMsg);

export const regexNumberPositive = /^(?:\d+)?$/g;

export const numberPositiveValidation = ({
  number = '数字形式が正しくありません。',
  required = 'この項目は入力が必須です。',
}) =>
  Yup.string()
    .required(required)
    .max(100, maxMsg(100))
    .matches(regexNumberPositive, number);

export const regexLinkURL = /(https?:\/\/[^\s]+)/g;
export const URLNotRequiredValidation = ({
  URL = 'URL形式が正しくありません。',
}) => Yup.string().matches(regexLinkURL, URL);

export const urlImageRegExp =
  /https?:\/\/[^\s]+\/[^\s]+\.(jpeg|jpg|gif|png|webp|svg+xml)/i;

export const imageUploadValidation = ({
  urlImageMsg = i18next.t('funnel.setting.warningUploadImageType'),
}) => Yup.string().matches(urlImageRegExp, urlImageMsg);

export const requiredValidation = ({
  required = 'この項目は入力が必須です。',
}) => Yup.string().required(required);

export const stringNotRequiredValidation = (characters = 100) =>
  Yup.string().max(characters, maxMsg(characters));

export const notRequiredValidation = (characters = 100) =>
  Yup.string().max(characters, maxMsg(characters)).nullable(true);

export const REGEX_DOMAIN =
  /^(www\.)?[-A-Za-z0-9+&@#\/%?=~_|!:,.;]+[\-\.]{1}[-A-Za-z0-9+&@#\/%=~_|]+$/;

export const domainValidation = ({ required = 'この項目は入力が必須です。' }) =>
  Yup.string()
    .trim()
    .matches(MSKNRegValidateDomain, MSKNMsgDomain)
    .required(required)
    .max(100, maxMsg(100));

export const REGEX_SUB_DOMAIN =
  /^[A-Za-z0-9](?:((?!--)[A-Za-z0-9\-]){0,59}[A-Za-z0-9])?$/;
export const subDomainValidation = ({
  required = 'この項目は入力が必須です。',
}) =>
  Yup.string()
    .trim()
    .required(required)
    .max(59, maxMsg(59))
    .matches(
      REGEX_SUB_DOMAIN,
      'サブドメイン名に使用できない文字が含まれています。'
    );
// .matches(REGEX_NO_UNICODE, 'サブドメイン名に使用できない文字が含まれています。');

export const requiredCheckbox = ({ required = 'この項目は入力が必須です。' }) =>
  Yup.bool().oneOf([true], required);

export const validateArrayString = ({
  required = 'この項目は入力が必須です。',
}) => Yup.array().of(inputValidationMaxLengthTrim({ required }));

export const validateArrayRequired = ({
  required = 'この項目は入力が必須です。',
}) => Yup.array().min(1, required);

export const numberPriceValidation = ({
  min = 1,
  max = 9999999,
  required = 'この項目は入力が必須です。',
}) =>
  Yup.number()
    .integer(i18next.t('funnel.affiliate.validate.price'))
    .required(required)
    .min(min, i18next.t('funnel.affiliate.validate.price'))
    .max(max, i18next.t('funnel.affiliate.validate.price'))
    .typeError(i18next.t('funnel.affiliate.validate.price'));

export const datetimeRequiredValidation = ({
  required = 'この項目は入力が必須です。',
}) => Yup.date().required(required);

export const numberMinMaxValidation = (
  { min = 1, max = 1000, required = 'この項目は入力が必須です。' },
  msgMinMax = i18next.t('common.validate.numberMinMax', { min: min, max: max })
) =>
  Yup.number()
    .integer(msgMinMax)
    .required(required)
    .min(min, msgMinMax)
    .max(max, msgMinMax);

export const numberMinValidation = (
  { min = 1, required = i18next.t('messageErrorRequire') || '' },
  msgMin = i18next.t('common.validate.numberMinMax', { min: min })
) => Yup.number().integer(msgMin).required(required).min(min, msgMin);

export const urlPathRequiredValidation = (
  { required = 'この項目は入力が必須です。' },
  RegExpMessage = 'URLの形式が正しくありません。'
) => Yup.string().required(required).matches(urlPathRegExp, RegExpMessage);

export const numberRequired = (required = i18next.t('messageErrorRequire')) =>
  Yup.number().required(required);

export const ipAddressValidation = () =>
  Yup.string()
    .trim()
    .required(i18next.t('messageErrorRequire'))
    .matches(REGEX_IP_ADDRESS, i18next.t('messageErrorIPAddress'));

export const urlPathValidation = (
  RegExpMessage = 'URLの形式が正しくありません。'
) => Yup.string().matches(urlPathRegExp, RegExpMessage);
