import API from './api';
import axiosClient from '..';
import * as I from './interface';
import { store } from '../../store/configureStore';
import { ParamDataMailListGroup } from '../../components/pages/MailList/MailList/MailList.interface';
import { showLoading } from './../../store/actions/loadingAction';
import { completedResponse, errorResponse } from './helper';
import { IParamsGetSchedulesByToken } from './interface';

/*--------------------mail list---------------------------- */
export const createMailList = async (params: I.ParamsCreateMailList) => {
  store.dispatch(showLoading());
  try {
    const response = await axiosClient.post(API.CREATE_MAIL_LIST, params);
    return completedResponse(response, {
      isAlertMessage: true,
      isStopAllLoading: true,
    });
  } catch (error: any) {
    return errorResponse(error?.response, {
      isAlertMessage: true,
      isStopAllLoading: true,
    });
  }
};

export const fetchMailListInPageCreate = async () => {
  try {
    const response = await axiosClient.get(API.FETCH_MAIL_LIST);
    return completedResponse(response);
  } catch (error: any) {
    return errorResponse(error?.response);
  }
};

export const createUserInMailList = async (
  params: I.ParamsCreateUserInMailList
) => {
  store.dispatch(showLoading());
  try {
    const response = await axiosClient.post(
      API.CREATE_USER_IN_MAIL_LIST,
      params
    );

    return completedResponse(response, {
      isAlertMessage: true,
      isStopAllLoading: true,
    });
  } catch (error: any) {
    return errorResponse(error?.response, {
      isAlertMessage: true,
      isStopAllLoading: true,
    });
  }
};

// import csv //
export const downloadCSV = async (params: I.ParamsDownLoadCSV) => {
  try {
    const res = await axiosClient.post(API.DOWN_LOAD_MAIL_LIST(params.uuid), {
      page_uuid: params.page_uuid === 'none' ? '' : params.page_uuid,
    });

    return completedResponse(res);
  } catch (error: any) {
    return errorResponse(error?.response);
  }
};

export const getAllStepMail = async (uuid: string) => {
  try {
    const response = await axiosClient.get(API.GET_ALL_STEP_MAILS(uuid));
    return completedResponse(response);
  } catch (error: any) {
    return errorResponse(error?.response);
  }
};

export const getMailListByIdMailList = async (uuid: string) => {
  try {
    const response = await axiosClient.get(API.GET_MAIL_LIST_BY_ID(uuid));
    return completedResponse(response);
  } catch (error: any) {
    return errorResponse(error?.response);
  }
};

export const getAllFormByIdMailList = async (uuid: string) => {
  try {
    const response = await axiosClient.get(API.GET_ALL_FORM_BY_ID(uuid));
    return completedResponse(response);
  } catch (error: any) {
    return errorResponse(error?.response);
  }
};

export const getStatusImportCSV = (uuid: string) =>
  axiosClient.get(API.GET_STATUS_IMPORT(uuid));

export const createUserByImportCSV = (payload: FormData) =>
  axiosClient.post(API.IMPORT_CSV_CREATE_USER_MAIL_LIST, payload);

// list mail list
export const fetchListMailList = async (params: ParamDataMailListGroup) => {
  const queryParams = {
    page: params.currentPage,
    perPage: params.pageSize,
    group_uuids: params.groupUuids,
    sort_group: params.sortGroup,
    sort_type: params.sortType,
    sort_name: params.sortName,
  };
  try {
    const response = await axiosClient.get(API.fetchListMailList, {
      params: queryParams,
    });
    return completedResponse(response, { isStopAllLoading: true });
  } catch (error: any) {
    return errorResponse(error?.response, { isStopAllLoading: true });
  }
};

export const fetchAllGroupMailList = async () => {
  try {
    const response = await axiosClient.get(API.fetchAllGroupMailList);
    return completedResponse(response);
  } catch (error: any) {
    return errorResponse(error?.response);
  }
};

export const removeGroupMailList = async (uuid: string) => {
  try {
    const response = await axiosClient.post(API.removeGroupMailList, { uuid });
    return completedResponse(response, {
      isStopAllLoading: true,
      isAlertMessage: true,
    });
  } catch (error: any) {
    return errorResponse(error?.response, {
      isAlertMessage: true,
      isStopAllLoading: true,
    });
  }
};

export const copyGroup = async (uuid: string) => {
  try {
    const response = await axiosClient.post(API.copyGroup, {
      uuid,
    });
    return completedResponse(response, {
      isAlertMessage: true,
      isStopAllLoading: true,
    });
  } catch (error: any) {
    return errorResponse(error?.response, {
      isAlertMessage: true,
      isStopAllLoading: true,
    });
  }
};

export const removeMailList = async (uuid: string) => {
  try {
    const response = await axiosClient.post(API.removeMailList, {
      uuid,
    });
    return completedResponse(response, {
      isAlertMessage: true,
      isStopAllLoading: true,
    });
  } catch (error: any) {
    return errorResponse(error?.response, {
      isAlertMessage: true,
      isStopAllLoading: true,
    });
  }
};

export const copyMailList = async (uuid: string) => {
  try {
    const response = await axiosClient.post(API.copyMailList, {
      uuid,
    });
    return completedResponse(response, {
      isAlertMessage: true,
      isStopAllLoading: true,
    });
  } catch (error: any) {
    return errorResponse(error?.response, {
      isAlertMessage: true,
      isStopAllLoading: true,
    });
  }
};

export const getListFunnelSteps = (uuid: string) =>
  axiosClient.get(API.getListFunnelSteps(uuid));

export const getListEventDateFilter = (uuid: string) =>
  axiosClient.get(API.getListEventDate(uuid));

// mail list detail

export const updateMailList = (params: I.ParamsUpdateMailList) =>
  axiosClient.post(API.updateMailList, params);

// create , update step mail
export const createStepMail = async (params: I.ParamsCreateStepMail) => {
  try {
    const response = await axiosClient.post(API.createStepMail, params);
    return completedResponse(response, {
      isAlertMessage: true,
      isStopAllLoading: true,
    });
  } catch (error: any) {
    return errorResponse(error?.response, {
      isAlertMessage: true,
      isStopAllLoading: true,
    });
  }
};

interface ParamsUpdateStepMail extends I.ParamsCreateStepMail {
  step_mail_uuid: string;
}

export const updateStepMail = async (params: ParamsUpdateStepMail) => {
  try {
    const response = await axiosClient.post(API.updateStepMail, params);
    return completedResponse(response, {
      isAlertMessage: true,
      isStopAllLoading: true,
    });
  } catch (error: any) {
    return errorResponse(error?.response, {
      isAlertMessage: true,
      isStopAllLoading: true,
    });
  }
};

export const fetchAllGroupStepMail = async () => {
  try {
    const response = await axiosClient.get(API.fetchAllGroupStepMail);
    return completedResponse(response, {
      isStopAllLoading: true,
    });
  } catch (error: any) {
    return errorResponse(error?.response, {
      isAlertMessage: true,
      isStopAllLoading: true,
    });
  }
};

export const fetchStepMailDetail = async (uuid: string) => {
  try {
    const response = await axiosClient.get(API.fetchStepMailDetail, {
      params: { uuid },
    });
    return completedResponse(response, {
      isStopAllLoading: true,
    });
  } catch (error: any) {
    return errorResponse(error?.response, {
      isAlertMessage: true,
      isStopAllLoading: true,
    });
  }
};

export const getMailIntegration = async () => {
  try {
    const response = await axiosClient.get(API.getMailIntegration);
    return completedResponse(response, {
      isStopAllLoading: true,
    });
  } catch (error: any) {
    return errorResponse(error?.response, {
      isAlertMessage: true,
      isStopAllLoading: true,
    });
  }
};

/*--------------------------------- list step mail ------------------------*/
export const fetchListStepMail = (params: I.ParamsFetchListStepMail) =>
  axiosClient.get(API.getListStepMail, { params: params });

export const publishStepMail = (params: I.ParamsPublishStepMail) =>
  axiosClient.post(API.publishStepMail, params);

export const copyStepMail = (uuid: string) =>
  axiosClient.post(API.copyStepMail, { uuid });

export const removeStepMail = (uuid: string) =>
  axiosClient.post(API.deleteStepMail, { uuid });

/*-------------------------------mail ----------------------------- */
export const copyMail = (uuid: string) =>
  axiosClient.post(API.copyMail, { uuid });

export const copyStepMailItem = (step_mail_item_uuid: string) =>
  axiosClient.post(API.copyStepMailItem, { step_mail_item_uuid });

export const fetchListMail = (params: I.ParamsFetchListMail) =>
  axiosClient.get(API.fetchListMail, { params: params });

export const updateMail = (params: I.ParamsUpdateMail) =>
  axiosClient.post(API.updateMail, params);

export const removeMail = (uuid: string) =>
  axiosClient.post(API.removeMail, { uuid });

export const removeStepMailItem = (step_mail_item_uuid: string) =>
  axiosClient.post(API.removeStepMailItem, { step_mail_item_uuid });

export const createMail = (params: I.PramsCreateMail) =>
  axiosClient.post(API.createMail, params);

export const connectStepMail = (params: I.ParamsConnectStepMail) =>
  axiosClient.post(API.connectStepMail, params);

/*----------------- mail detail -------------------------*/
export const mailDetail = (uuid: string) =>
  axiosClient.get(API.mailDetail(uuid));

export const fetchListSignature = (params: I.ParamsFetchListSignature) =>
  axiosClient.get(API.fetchListSignature, { params });

export const saveMail = (params: I.ParamsSaveMail) =>
  axiosClient.post(API.saveMail, params);

export const fetchFixedPhrase = () => axiosClient.get(API.fetchFixedPhrase);

/*-------------------- list mail bulk --------------------*/
export const fetchListMailBulk = (params: I.ParamsFetchListMailBulk) =>
  axiosClient.get(API.fetchListMailBulk, { params });

export const removeMailBulk = (uuid: string) =>
  axiosClient.post(API.removeMailBulk, { uuid });

/*-------------------- detail bulk mail -------------------*/
export const fetchDetailBulkMail = (uuid: string, type: string) =>
  axiosClient.get(API.fetchDetailBulkMail(uuid, type));

export const sendNowStatus = (uuid: string) =>
  axiosClient.get(API.sendNowStatus(uuid));

/*------------- create bulk mail --------------------*/
export const createBulkMail = (params: I.ParamsCreateBulkMail) =>
  axiosClient.post(API.createBulkMail, params);

export const fetchListBlackListUser = () =>
  axiosClient.get(API.fetchListBlackListUser);

export const fetchListAllMailList = () =>
  axiosClient.get(API.fetchListAllMailList);

export const fetchListAllMailListWithTrashed = () =>
  axiosClient.get(API.fetchListAllMailListWithTrashed);

export const sendMailTest = (params: I.ParamsSendBulkMailTest) =>
  axiosClient.post(API.sendMailTest, params);

export const fetchDataMailListInPageCreateBulkMail = (
  params: I.IFetchDataMailListInPageCreateBulkMail
) => axiosClient.get(API.fetchDataMailListInPageCreateBulkMail, { params });

/*----------------------update mail bulk------------------------------*/
export const updateMailBulk = (params: I.ParamsUpdateBulk) =>
  axiosClient.post(API.updateMailBulk, params);

/*----------------------------- Fixed Phrase-----------------------------*/
export const fetchListFixedPhase = (params: I.ParamsFetchListFixedPhase) =>
  axiosClient.get(API.fetchListFixedPhase, { params });

export const fetchFixedPhaseDetail = (uuid: string) =>
  axiosClient.get(API.fetchFixedPhaseDetail(uuid));

export const createFixedPhrase = (params: I.ParamsCreateFixedPhrase) =>
  axiosClient.post(API.createFixedPhrase, params);

export const updateFixedPhrase = (params: I.ParamsUpdateFixedPhrase) =>
  axiosClient.post(API.updateFixedPhrase, params);

export const deleteFixedPhrase = (uuid: string) =>
  axiosClient.post(API.deleteFixedPhrase, { uuid });

/*-------------------- Alternative character ---------------------------*/
export const getUserFormValidToken = (token: string) =>
  axiosClient.get(API.getUserFormValidToken(token));

export const updateUserFormValidToken = (token: string, params: any) =>
  axiosClient.post(API.updateUserFormValidToken(token), params);

export const getPaymentOrderInfo = (token: string) =>
  axiosClient.get(API.getPaymentOrderInfo(token));

export const cancelScheduleEventDate = (payload: I.typePayloadEventDate) =>
  axiosClient.post(API.cancelScheduleEventDate(payload.token), {
    event_date: payload.event_date,
  });

export const updatePaymentUnivaPay = (
  orderID: string,
  payload: I.IPayloadUnivaPay
) => axiosClient.post(API.updatePaymentUnivaPay(orderID), payload);

export const changeScheduleCalendar = (
  payload: I.IParamsChangeScheduleCalendar
) =>
  axiosClient.post(API.updateUserFormValidToken(payload.token), {
    schedule_uuid: payload.schedule_uuid,
    schedule_date: payload.schedule_date,
  });

/*---------------------user mail list-----------------------*/
export const getListUserMailList = (
  uuid: string,
  payload: I.ParamsGetListUserMailList
) => axiosClient.post(API.getUserMailList(uuid), payload);

export const getPaymentMethodByUser = (
  params: I.ParamsGetPaymentMethodByUser
) => axiosClient.get(API.getPaymentMethodByUser(params));

export const deleteUserMailListItem = (
  params: I.IParamsDeleteUserMailListItemInURL
) => axiosClient.post(API.deleteUserMailListItem(params));

export const updateMailSubcribe = (params: { id: number }) =>
  axiosClient.post(API.updateMailSubcribe, params);

export const getExportConfig = (uuid: string) =>
  axiosClient.get(API.configExportGoogleSheet(uuid));

export const updateExportConfig = (
  uuid: string,
  params: I.ParamsExportConfig
) => axiosClient.post(API.configExportGoogleSheet(uuid), params);

export const exportUserMailListCSV = (
  uuid: string,
  params: I.ParamsExportUserMailListCSV
) => axiosClient.post(API.exportUserMailListCSV(uuid), params);

export const updateUserMailListItem = (
  paramsURL: I.ParamsUpdateUserMailListItemInURL,
  paramsBody: I.BodyParamsUpdateUserMailListItem
) => axiosClient.post(API.updateUserMailListItem(paramsURL), paramsBody);

export const editPaymentMethodByUser = (
  paramsURL: I.ParamsEditPaymentMethodURL,
  paramsBody: any
) => axiosClient.post(API.editPaymentMethodByUser(paramsURL), paramsBody);

export const editPaymentStatusByUser = (
  paramsURL: I.ParamEditStatusPaymentMethodURL
) => axiosClient.post(API.editPaymentStatusByUser(paramsURL));

export const getGenerateCompletePaymentLink = (mailListUserId: any) =>
  axiosClient.get(API.getGenerateCompletePaymentLink(mailListUserId));

export const getFunnelsByMailListAPI = (params: I.ParamsGetFunnelsByMailList) =>
  axiosClient.get(API.getFunnelsByMailListURL, { params });

export const getAccessFunnelsByFunnelAPI = (
  uuid: string,
  params: I.ParamsGetAccessFunnelsByFunnel
) => axiosClient.get(API.getAccessFunnelsByFunnelURL(uuid), { params });

export const postChangePurchaseDate = (params: I.ParamsChangePurchaseDate) =>
  axiosClient.post(API.postChangePurchaseDate, params);

export const getMailListUsersSetting = (uuid: string) =>
  axiosClient.get(API.mailListUsersSetting(uuid));

export const putMailListUsersSetting = (
  uuid: string,
  params: I.ParamsMailListUsersSetting
) => axiosClient.put(API.mailListUsersSetting(uuid), params);

export const getMailListUsersSettingCalendar = (uuid: string) =>
  axiosClient.get(API.mailListUsersSettingCalendar(uuid));

export const getOptionsCalendar = (
  uuid: string,
  params: I.IParamsMailListUsersSchedules
) => axiosClient.post(API.mailListUsersSchedules(uuid), params);

export const putMailListUsersSettingCalendar = (
  uuid: string,
  params: I.ParamsMailListUsersSetting
) => axiosClient.put(API.mailListUsersSettingCalendar(uuid), params);

export const getSchedulesByToken = (params: IParamsGetSchedulesByToken) =>
  axiosClient.post(API.getSchedulesByToken(), params);

/*---------------------- group ---------------------------------*/
export const renameGroup = (params: I.ParamsRenameGroup) =>
  axiosClient.post(API.renameGroup, params);

export const getDataMailListAndConditionMail = (params: any) =>
  axiosClient.get(API.getDataMailListAndConditionMailRequest, { params });

export const getConditionItemPageForm = (params: any) =>
  axiosClient.get(API.getConditionItemPageForm(params));

// use this instead of calling it directly in component
export const getListMailList = (params: I.IParamsGetListMailList) =>
  axiosClient.get(API.fetchListMailList, { params });
/*---------------------remind mail --------------------------- */
export const getListMailIntegration = () =>
  axiosClient.get(API.getMailIntegration);

export const getListAllGroupRemindMail = () =>
  axiosClient.get(API.fetchAllGroupRemindMail);

export const getRemindMailDetail = (uuid: string) =>
  axiosClient.get(API.fetchRemindMailDetail, {
    params: { uuid },
  });

export const createRemindmail = (params: I.ParamsCreateRemindMail) =>
  axiosClient.post(API.createRemindMail, params);

export const updateRemindmail = (params: I.ParamsCreateRemindMail) =>
  axiosClient.post(API.updateRemindMail, params);

export const getListRemindMail = (params: I.ParamsListRemindMail) =>
  axiosClient.get(API.getListRemindMail, { params });

export const copyGroupRemindMail = (payload: I.ParamsUuidType) =>
  axiosClient.post(API.copyGroup, payload);

export const deleteGroupRemindMail = (payload: I.ParamsUuidType) =>
  axiosClient.post(API.removeGroupMailList, payload);

export const copyRemindMail = (payload: I.ParamsUuidType) =>
  axiosClient.post(API.copyRemindMail, payload);

export const deleteRemindMail = (payload: I.ParamsUuidType) =>
  axiosClient.post(API.deleteRemindMail, payload);

export const createRemindMailItem = (params: I.ParamsCreateRemindMailItem) =>
  axiosClient.post(API.createRemindMailItem, params);

export const updateRemindMailItem = (params: I.ParamsCreateRemindMailItem) =>
  axiosClient.post(API.updateRemindMailItem, params);

export const copyRemindMailItem = (uuid: I.ParamsUuidRemindItem) =>
  axiosClient.post(API.copyRemindMailItem, uuid);

export const deleteRemindMailItem = (uuid: I.ParamsUuidRemindItem) =>
  axiosClient.post(API.deleteRemindMailItem, uuid);

export const remindMailContentDetail = (uuid: string) =>
  axiosClient.get(API.remindMailContentDetail(uuid));

export const submitRemindMailContent = (
  params: I.ParamsSubmitRemindMailContent
) => axiosClient.post(API.submitRemindMailContent, params);

export const getMailListById = (uuid: string) =>
  axiosClient.get(API.GET_MAIL_LIST_BY_ID(uuid));

export const publishRemindMail = (params: I.typeRemindMailPublish) =>
  axiosClient.post(API.publishRemindMail, params);
