import * as Types from '../../actions/funnel/types';

export interface IFunnelStepPageVersion {
  uuid: string;
  landing_file_url: string;
  created_at: string;
}

export interface IFunnelStepPageVersions {
  requestStatus?: string;
  data: IFunnelStepPageVersion[];
  isUpdated: boolean;
}

export const funnelStepPageVersionsInitialsState: IFunnelStepPageVersions = {
  data: [],
  isUpdated: false,
  requestStatus: 'request',
};

export default function funnelStepPageVersionsReducer(
  state = funnelStepPageVersionsInitialsState,
  action: any
) {
  switch (action.type) {
    case Types.FETCH_FUNNEL_STEP_PAGE_VERSIONS_SUCCESS:
      return {
        data: action.payload,
        requestStatus: 'success',
        isUpdated: false,
      };
    case Types.APPLY_FUNNEL_STEP_PAGE_VERSIONS_SUCCESS:
      state.isUpdated = true;
    case Types.CLEAR_PAGE_VERSIONS:
      state.data = [];
      state.requestStatus = 'request';
    case Types.FETCH_FUNNEL_STEP_PAGE_VERSIONS_REQUESTED:
      state.requestStatus = 'requesting';
    case Types.APPLY_FUNNEL_STEP_PAGE_VERSIONS_REQUESTED:
    case Types.FETCH_FUNNEL_STEP_PAGE_VERSIONS_FAILED:
    case Types.APPLY_FUNNEL_STEP_PAGE_VERSIONS_FAILED:
    default:
      return { ...state };
  }
}
