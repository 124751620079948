import { Badge } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import alertNotificationOutline from '../../../../assets/img/menu-icons/alert-notification.png';
import TDropdown from '../../../components/atoms/TDropdown/TDropdown';
import PopupNotification from '../../../components/organisms/Notification';
import { AppState } from '../../../store/reducers/rootReducer';

const Notification = () => {
  const unread_notification = useSelector(
    (state: AppState) => state.myPage.profile.data?.unread_notification
  );
  const [toggleNotification, setToggleFunnel] = useState(false);
  const handleToggleStatusNotification = (params: any) =>
    setToggleFunnel(params);

  return (
    <TDropdown
      key="notification"
      onClick={handleToggleStatusNotification}
      isOpen={toggleNotification}
      handleOvelay={
        <PopupNotification
          toggleNotification={toggleNotification}
          handleToggleStatusNotification={() => setToggleFunnel(false)}
        />
      }
      iconButton={
        <Badge
          count={unread_notification ? unread_notification : 0}
          size="small"
        >
          <img
            src={alertNotificationOutline}
            alt=""
            className="notification-icon"
          />
        </Badge>
      }
      popupPlacement="bottomRight"
    />
  );
};

export default Notification;
