import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { AppState } from '../../../store/reducers/rootReducer';
import { fetchUserProfile } from '../../../store/actions/myPage/profile';
import SearchAllContainer from '../../features/SearchAllContainer';
import SettingUserComponent from '../../features/UserSetting';
import Notification from '../../features/Notification';
import './style.scss';
import { AUTHEN_PAGE } from '../../../constants/menus';

const logo = `${process.env.APP_DOMAIN}/upload-files/images/logo/logo.png`;

const ArticleHeader: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [onOffSearch, setOnOffSearch] = useState(false);

  const profile = useSelector((state: AppState) => state.myPage.profile.data);
  const { role, user_profile } = profile;

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, []);

  return (
    <Row className="article-header">
      <SearchAllContainer
        onOffSearch={onOffSearch}
        setOnOffSearch={setOnOffSearch}
        handleOffMenu={() => setOnOffSearch(true)}
      />
      <Col span={8} className="article-header_logo text-center">
        <Link to={AUTHEN_PAGE.DASHDOARD}>
          <img src={logo} width={300} alt="logo" />
        </Link>
      </Col>
      <Col span={8} className="article-header_notification">
        <Row justify="end" align="middle">
          <Col>
            <Notification />
            <SettingUserComponent />
          </Col>
          <Col className="wrapper-user_type">
            <div className="user-display">
              {user_profile?.user_name || ''}
              <p className="user-type">
                {role?.is_owner
                  ? t('header.title.type.owner')
                  : t('header.title.type.subUser')}
              </p>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ArticleHeader;
