import { IParamsGetAllCategoriesPageTemplate } from '../../../Clients/common/interfaces';
import { ICurrentCategoryAndSubCategory } from '../../reducers/common/interface';
import * as Types from './types';

export const getAllCategoriesPageTemplate = (
  params: IParamsGetAllCategoriesPageTemplate
) => ({
  type: Types.GET_ALL_CATEGORIES_PAGE_TEMPLATE,
  params,
});

export const handleCurrentCategoryAndSubCategory = (
  params: ICurrentCategoryAndSubCategory
) => ({
  type: Types.HANDLE_CURRENT_CATEGORY_AND_SUBCATEGORY,
  data: params,
});
