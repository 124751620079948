import React from 'react';
import { TSingleSelected } from '../../../atoms/TSingleSelected';
import './ContentPageSize.scss';
import nextIcon from '../../../../../assets/img/menu-icons/prev-icon.png';
import prevIcon from '../../../../../assets/img/menu-icons/next-icon.png';
import i18n from '../../../../i18n/i18n';
import { pageSizesNoGroup } from '../../../../config/common.config';

enum PageAction {
  PREV = 'prev',
  NEXT = 'next',
}

export interface DataPage {
  totalPage: number;
  pageSize: number;
  currentPage: number;
}

interface ContentPageSizeProps {
  data: DataPage;
  handleChangePageSize: (value: any) => void;
  handlePrevNextCallback?: (value: any) => void;
  className?: string;
  title?: string;
  pageSizesCustom?: any;
  total?: number;
}

const ContentPageSize: React.FC<ContentPageSizeProps> = ({
  data,
  handleChangePageSize,
  handlePrevNextCallback,
  className = '',
  title = i18n.t('fixedPhrase.list.pageSizeDescription'),
  pageSizesCustom = pageSizesNoGroup,
  total = 0,
}) => {
  const recordsFrom =
    total > 0 ? (data.currentPage - 1) * data.pageSize + 1 : 0;
  const recordsTo =
    data.currentPage * data.pageSize < total
      ? data.currentPage * data.pageSize
      : total;
  return (
    <div
      className={`m-0 pe-3 d-flex flex-column align-items-end core-content-pagesize ${className}`}
    >
      <div className="d-flex align-items-center core-content-pagesize__page-size">
        <p className="px-2 m-0 title">{title}</p>

        <TSingleSelected
          defaultValue={data.pageSize}
          width={70}
          data={pageSizesCustom}
          onChange={handleChangePageSize}
        />
      </div>
      <div className="pe-2 pt-2">
        {i18n.t('commonList.pageSize.contentPageSize.textContent', {
          total: total,
          recordsFrom: recordsFrom,
          recordsTo: recordsTo,
        })}
      </div>
      {handlePrevNextCallback && (
        <div className="m-0 px-2 core-content-pagesize__prev-next">
          <img
            className={`prev ${
              data.totalPage === 0 || data.totalPage === 1 ? 'disable' : ''
            }`}
            src={prevIcon}
            alt="prev page"
            onClick={() => {
              if (data.totalPage > 1) {
                handlePrevNextCallback(PageAction.PREV);
              }
            }}
          />
          <img
            className={`next ${
              data.totalPage === 0 || data.totalPage === 1 ? 'disable' : ''
            }`}
            src={nextIcon}
            alt="next page"
            onClick={() => {
              if (data.totalPage > 1) {
                handlePrevNextCallback(PageAction.NEXT);
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ContentPageSize;
