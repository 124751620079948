export interface ISearchParams {
  page: number;
  per_page: number;
  data: any;
  maxPage: number;
}

export const DefaultParam: ISearchParams = {
  page: 1,
  per_page: 15,
  data: [],
  maxPage: 0,
};

export enum SearchScrollType {
  FETCH = 'fetch',
  HAS_MORE = 'hasMore',
}
