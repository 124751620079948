import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const HeaderPageTemplates: React.FC<any> = () => {
  const { t } = useTranslation();
  return (
    <Wrapper align="middle" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col flex="auto" className="header">
        <div className="header_element">
          <div>{t('membersiteTemplateDetail.title')}</div>
          <div>Course template detail</div>
        </div>
      </Col>
    </Wrapper>
  );
};

const Wrapper = styled(Row)`
  background-color: white;
  border-radius: 15px;
  .header {
    padding: 15px 0;
    &_element {
      padding: 0px 10px;
      border-left: 4px solid var(--t-main-color);
      div {
        color: var(--t-main-color);
        font-weight: bold;
        :last-child {
          font-size: 0.85rem;
        }
      }
    }
  }
`;

export default HeaderPageTemplates;
