import * as Types from '../../actions/mailListUser/types';
import { initialState, IAccessChannelState } from './interface';

export default function accessFunnelReducer(
  state: IAccessChannelState = initialState,
  action: any
): IAccessChannelState {
  switch (action.type) {
    case Types.GET_FUNNELS_BY_MAIL_LIST:
    case Types.GET_ACCESS_FUNNEL_BY_FUNNEL:
      return {
        ...state,
        isLoading: true,
      };
    case Types.FETCH_FUNNELS_BY_MAIL_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        funnel: action.funnel,
      };
    case Types.SCROLL_FUNNELS_BY_MAIL_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        funnel: {
          data: state.funnel.data.concat(action.funnel.data),
          meta: action.funnel.meta,
        },
      };
    case Types.FETCH_ACCESS_FUNNEL_BY_FUNNEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        access_funnel: action.access_funnel,
      };
    case Types.SCROLL_ACCESS_FUNNEL_BY_FUNNEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        access_funnel: {
          data: state.access_funnel.data.concat(action.access_funnel.data),
          meta: action.access_funnel.meta,
        },
      };
    case Types.HIDE_LOADING_GET_DATA_COMPONENT:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
