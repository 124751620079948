import * as Types from '../../actions/membersite/types';
import { IStateGroupCourses, initialGroupCourses } from './interfaces';

export default function groupMembersiteReducer(
  state: IStateGroupCourses = initialGroupCourses,
  action: any
): IStateGroupCourses {
  switch (action.type) {
    case Types.FETCH_THE_GROUP_MEMBERSITE_LIST: {
      return { ...state };
    }
    case Types.FETCH_THE_GROUP_MEMBERSITE_LIST_SUCCESS: {
      return {
        ...state,
        ...action.data,
      };
    }
    case Types.FETCH_THE_GROUP_MEMBERSITE_LIST_FAILED: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
}

export type { IStateGroupCourses };
