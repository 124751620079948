import { EStatusHttp } from './../../Clients/interfaces';
import { IRegisterAccount } from './../../Clients/authentication/interface';
import { call, takeLatest, put } from 'redux-saga/effects';
import * as authServices from '../../Clients/authentication';
import * as Types from '../actions/auth/types';
import { handleMessage } from '../../utilities/common.utilities';
import * as TypesLoading from './../actionTypes/loadingActionTypes';
import { verifyRegisterToken } from '../../Clients/users';

interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
}

function* login(action: {
  type: string;
  data: any;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      authServices.login,
      action.data
    );
    action.onSuccess(response);
  } catch (error: any) {
    action.onError(error.response);
  }
}

function* loginTwoAuthen(action: {
  type: string;
  data: {
    token: string;
    provider: any;
    plan_uuid?: string;
    url_register: string;
  };
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      authServices.loginTwoAuthen,
      action.data.token,
      action.data.provider,
      action.data.url_register,
      action.data.plan_uuid
    );
    action.onSuccess(response);
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (error: any) {
    action.onError(error.response);
    handleMessage(error.response);
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

function* reSendVerifyAccount(action: {
  type: string;
  data: string;
  onSuccess: () => void;
  onError: () => void;
}) {
  try {
    yield call(authServices.reSendVerifyAccount, action.data);
    action.onSuccess();
  } catch (error: any) {
    action.onError();
  }
}

function* registerAccount(action: {
  type: string;
  params: IRegisterAccount;
  onSuccess: () => void;
  onError: () => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      authServices.registerAccount,
      action.params
    );
    yield put({ type: TypesLoading.HIDE_LOADING });
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      return;
    }
    action.onError();
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
    yield put({ type: TypesLoading.HIDE_LOADING });
    action.onError();
  }
}

function* verifyTokenRegister(action: {
  type: string;
  params: any;
  onSuccess: (data: any) => void;
  onError: (data?: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      verifyRegisterToken,
      action.params
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data.data);
      return;
    }
    yield put({ type: TypesLoading.HIDE_LOADING });
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
    yield put({ type: TypesLoading.HIDE_LOADING });
    action.onError();
  }
}

function* getMaintenanceDetail(action: {
  type: string;
  onSuccess: (data: any) => void;
  onError: (data?: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      authServices.getMaintenanceDetail
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data.data);
    }
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
    action.onError();
  }
}

function* verifyAccountSuccess(action: {
  type: string;
  token: string;
  onSuccess: (data?: any) => void;
  onError: (data?: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(authServices.activeAccount, {
      token: action.token,
    });
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      return;
    } else {
      action.onError(response?.status);
    }
  } catch (error: any) {
    action.onError(error?.response?.status);
  }
}

function* getSelectionPlan(action: {
  type: string;
  onSuccess: (data: any) => void;
}) {
  yield put({ type: TypesLoading.SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(authServices.getPackagePlans);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response?.data?.data);
    }
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
  } finally {
    yield put({ type: TypesLoading.HIDE_LOADING });
  }
}

export default function* authSaga() {
  yield takeLatest(Types.LOGIN, login);
  yield takeLatest(Types.LOGIN_TOW_AUTHEN, loginTwoAuthen);
  yield takeLatest(Types.RESEND_VERIFY_ACCOUNT, reSendVerifyAccount);
  yield takeLatest(Types.REGISTER_ACCOUNT, registerAccount);
  yield takeLatest(Types.VERIFY_TOKEN_REGISTER, verifyTokenRegister);
  yield takeLatest(Types.GET_MAINTENANCE_DETAIL, getMaintenanceDetail);
  yield takeLatest(Types.VERIFY_ACCOUNT_SUCCESS, verifyAccountSuccess);
  yield takeLatest(Types.GET_SELECTION_PLAN, getSelectionPlan);
}
