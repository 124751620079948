// Project: 7612770651107e1659371833c56a9b25e733c4615968bb7c5582cdc92aa6249b
import styled from 'styled-components';

interface ButtonDisabled {
  type?: string;
  isDisable?: boolean;
  isAddEmail?: boolean;
  title: string;
  onClick?: () => void;
  addClass?: string;
}

const ButtonWrapper = styled.button`
  background-color: var(--t-main-color);
  border: 2px solid var(--t-main-color);
  color: var(--t-white-color);
  border-radius: 999px;
  padding: 0.375rem 0.75rem;
  ${(props: any) => (props.isAddEmail ? 'width: 100%;' : '')}
  ${(props: any) =>
    props.disabled
      ? 'cursor: no-drop;'
      : '&:hover {background-color: var(--t-white-color);color: var(--t-main-color);border: 2px solid var(--t-main-color);}'}
`;

const Button: React.FC<ButtonDisabled> = (props) => {
  const { isDisable, onClick, title, type, isAddEmail, addClass = '' } = props;
  return (
    <ButtonWrapper
      isAddEmail={isAddEmail}
      type={type}
      disabled={isDisable}
      onClick={onClick}
      className={addClass}
    >
      {title}
    </ButtonWrapper>
  );
};

export default Button;
