import { usersAPI } from './api';
import axiosInstance from '../';
import { DataTablePage, ParamsListUser } from './interface';

export const getListUser = (payload: ParamsListUser) =>
  axiosInstance.post(usersAPI.getListUser(), payload);

export const deleteUser = (uuid: string) => {
  return axiosInstance.post(usersAPI.deleteUser(uuid), { uuid });
};

export const getUserDetail = (user_uuid: string) =>
  axiosInstance.get(usersAPI.getDataUserDetail(user_uuid));

export const getUserUpdate = (uuid: string) =>
  axiosInstance.get(usersAPI.getUserUpdate(uuid));

export const updateInfoUser = (params: any) =>
  axiosInstance.post(usersAPI.updateInfoUser(params.uuid), params.params);

export const getStatisticsUser = (params: {
  type: string;
  uuid: string;
  perPage: number;
  page: number;
}) => axiosInstance.get(usersAPI.getStatisticsUser(params));

export const getMailList = (params: {
  type: string;
  uuid: string;
  perPage: number;
  page: number;
}) => axiosInstance.get(usersAPI.getDataMailListInPageManageUser(params));

export const getAllPaymentMethod = (id: number, idUser: string) =>
  axiosInstance.get(usersAPI.getAllPaymentMethod(id, idUser));

export const editPaymentMethod = (
  params: {
    uuid: string;
    paymentMethodId: number;
    productPaymentMethodId: number;
    id: number;
  },
  data: { pm_id: number; ppm_id: number }
) => axiosInstance.post(usersAPI.editPaymentMethod(params), data);

export const editPaymentStatus = (params: {
  uuid: string;
  id: string;
  newStatus: string;
}) => axiosInstance.post(usersAPI.editPaymentStatus(params));

export const editNameMail = (params: any) =>
  axiosInstance.post(usersAPI.editNameMail(), params);

export const removeUserFormMailList = (params: any) =>
  axiosInstance.post(usersAPI.removeUserFormMailList(params));

export const stopSendStepMail = (params: any) =>
  axiosInstance.post(usersAPI.stopSendStepMail(), params);

export const getBlackListUser = (params: { perPage: number; page: number }) =>
  axiosInstance.get(usersAPI.getBlackListUser(params));

export const addMailToBlackListUser = (params: { mail: string }) =>
  axiosInstance.post(usersAPI.addMailToBlackListUser(), params);

export const deleteMailToBlackListUser = (params: { mail_uuid: string }) =>
  axiosInstance.post(usersAPI.deleteMailToBlackListUser(params));

export const getMailsList = () => axiosInstance.get(usersAPI.getMailsList());

export const getProductList = () =>
  axiosInstance.get(usersAPI.getListProduct());

export const getMailListPageManageUser = (uuid: string) =>
  axiosInstance.get(usersAPI.getMailListPageManageUser(uuid));

export const getProductListPageManageUser = (uuid: string) =>
  axiosInstance.get(usersAPI.getProductListPageManageUser(uuid));

export const getPageListUserMemberShip = (params: DataTablePage) =>
  axiosInstance.get(usersAPI.getPageListUserMemberShip(), { params });

export const deleteUserMemberShip = (params: string) =>
  axiosInstance.post(usersAPI.deleteUserMemberShip(), params);

export const updateExpiredDate = (params: any) =>
  axiosInstance.post(usersAPI.updateExpiredDate(), params);

export const verifyRegisterToken = (token: string) => {
  return axiosInstance.post(usersAPI.verifyRegisterToken(), { token: token });
};

export const postStopRemindMail = (uuid: string) =>
  axiosInstance.post(usersAPI.postStopRemindMail(), {
    remind_mail_run_uuid: uuid,
  });
