import { IPayLoadGroupCourseList } from '../../store/actions/membersite/group';
import { PayloadPageListCourse } from '../../store/actions/membersite/pageList';
import { getPath, getQueryParams } from '../helper';

export const endPoints = {
  getSetting: () => getPath(`membersite/setting`),
  update: () => getPath(`membersite/setting`),
  getListSignature: () => getPath(`membersite/get-list-signature`),
  getSettingMail: () => getPath(`membersite/mail`),
  updateMail: () => getPath(`membersite/mail`),
  updateSendTestMail: () => getPath(`membersite/send-test-mail`),
  getAllGroupCourse: () => getPath(`membersite/course/groups`),
  createCourse: () => getPath(`membersite/course/create`),
  getPageListGroupCourses: (params: IPayLoadGroupCourseList) =>
    getPath(`group/course?${getQueryParams(params)}`),
  delete: () => getPath(`membersite/course/destroy`),
  changeNameCourse: () => getPath(`membersite/course/rename`),
  changeStatusFavorite: () => getPath(`membersite/course/favorite`),
  changeStatusPublicCourse: () => getPath(`membersite/course/publish`),
  cloneCourse: () => getPath(`membersite/course/copy`),
  getPageListCourse: (params: PayloadPageListCourse) =>
    getPath(`membersite/course/list?${getQueryParams(params)}`),
  restoreCourse: () => getPath(`membersite/course/restore`),
  shareCourse: () => getPath(`membersite/course/share`),
  getSummaryCourse: () => getPath(`summary/group/course`),
  getCourseByGroupGarbage: () => getPath(`membersite/course/groups/garbage`),
  getCourseDetail: (id: string) => getPath(`membersite/course/show/${id}`),
  updateCourse: () => getPath(`membersite/course/setting`),
  getSettingCourse: (id?: string) => getPath(`membersite/course/setting/${id}`),
  createMembersiteGroup: () => getPath(`group/create?type=MEMBER_SITE`),
  changeNameGroupMembersite: () => getPath(`group/rename`),
  cloneGroupMembersite: () => getPath(`group/copy`),
  deleteGroupMembersite: () => getPath(`group/delete`),
  createUserInCourse: () => getPath(`membersite/course/add-user`),
  createUserByImportCSV: () => getPath(`membersite/course/import-csv-users`),
};
