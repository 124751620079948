import { IMultipleSelectedProps } from '../../../components/atoms/TMultipleSelected/TMultipleSelected';
import { IStateGroupCourses } from '../../reducers/membersite/groupMembersites';
import * as types from './types';

export interface IPayLoadGroupCourseList {
  page: number;
  perPage: number;
  group_uuids: string;
  flag_favorite: string;
  sort_group: string;
  sort_type: 'desc' | 'asc' | '';
  sort_name: 'course_name' | 'datetime' | 'group_name' | '';
}

const fetchGroupMembersiteList = (payload: IPayLoadGroupCourseList) => ({
  type: types.FETCH_THE_GROUP_MEMBERSITE_LIST,
  payload,
});

const fetchGroupMembersiteListSuccess = (data: IStateGroupCourses) => ({
  type: types.FETCH_THE_GROUP_MEMBERSITE_LIST_SUCCESS,
  data: data,
});

const fetchGroupMembersiteListFailed = (error: string) => ({
  type: types.FETCH_THE_GROUP_MEMBERSITE_LIST_FAILED,
  error: error,
});

const fetchSelectedGroupMembersite = () => ({
  type: types.FETCH_SELECT_GROUP_MEMBERSITE,
});

const fetchSelectedGroupMembersiteSuccess = (
  data: IMultipleSelectedProps[]
) => ({
  type: types.FETCH_SELECT_GROUP_MEMBERSITE_SUCCESS,
  data,
});
const fetchSelectedGroupMembersiteFailed = (error: string) => ({
  type: types.FETCH_SELECT_GROUP_MEMBERSITE_FAILED,
  error,
});

export {
  fetchGroupMembersiteList,
  fetchGroupMembersiteListSuccess,
  fetchGroupMembersiteListFailed,
  fetchSelectedGroupMembersiteSuccess,
  fetchSelectedGroupMembersiteFailed,
  fetchSelectedGroupMembersite,
};
