export interface IMeta {
  current_page: number;
  last_page: number;
  per_page: number;
  total: number;
}
export interface IGroupTag {
  name: string;
  uuid: string;
}

export interface ITagDetail {
  name: string;
  uuid: string;
  group_uuid: string;
  description?: string;
}

export interface TagItem {
  uuid: string;
  name: string;
  users_count?: number;
}

export interface IGroupWithTag {
  name: string;
  uuid: string;
  tag_list: TagItem[];
}

export interface IUserInfoFromTag {
  uuid: string;
  email: string;
  full_name: string;
  created_at: string;
}

export interface IListTagUserData {
  data: {
    tag_name: string;
    list_tag_user: IUserInfoFromTag[];
  };
  meta: IMeta;
}

export interface IListTagOfUser {
  data: {
    user: {
      email: string;
      full_name: string;
    };
    tags: Array<{ name: string; created_at: string; uuid: string }>;
  };
  meta: IMeta;
}

export interface IManagerTag {
  tagDetail: ITagDetail;
  listGroupTag: IGroupTag[];
  listTagByGroup: {
    data: {
      groups: IGroupWithTag[];
    };
    meta: IMeta;
  };
  listTagUser: IListTagUserData;
  allTag: TagItem[];
  listTagOfUser: IListTagOfUser;
}

export const initialState: IManagerTag = {
  tagDetail: {
    name: '',
    uuid: '',
    group_uuid: '',
    description: '',
  },
  listGroupTag: [],
  listTagByGroup: {
    data: {
      groups: [],
    },
    meta: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  },
  listTagUser: {
    data: {
      tag_name: '',
      list_tag_user: [],
    },
    meta: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  },
  allTag: [],
  listTagOfUser: {
    data: {
      user: {
        email: '',
        full_name: '',
      },
      tags: [],
    },
    meta: {
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    },
  },
};
