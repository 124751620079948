export const GET_LIST_BLOCK_TEMPLATES = 'GET_LIST_BLOCK_TEMPLATES';

export const GET_ALL_CATEGORIES_BLOCK_TEMPLATE =
  'GET_ALL_CATEGORIES_BLOCK_TEMPLATE';
export const GET_ALL_CATEGORIES_BLOCK_TEMPLATE_SUCCESS =
  'GET_ALL_CATEGORIES_BLOCK_TEMPLATE_SUCCESS';

export const GET_BLOCK_TEMPLATE_DETAIL = 'GET_BLOCK_TEMPLATE_DETAIL';

export const UPDATE_BLOCK_TEMPLATE = 'UPDATE_BLOCK_TEMPLATE';
export const DELETE_BLOCK_TEMPLATE = 'DELETE_BLOCK_TEMPLATE';
