import { call, put, takeLatest } from 'redux-saga/effects';
import { EStatusHttp } from '../../Clients/interfaces';
import * as ProductServices from '../../Clients/products';
import { handleMessage } from '../../utilities/common.utilities';
import * as Types from '../actions/product/types';
import { HIDE_LOADING, SHOW_LOADING } from '../actionTypes/loadingActionTypes';

interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
}

function* renameProductListItem(action: {
  type: string;
  data: any;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      ProductServices.renameProduct,
      action.data
    );
    action.onSuccess(response);
  } catch (error: any) {
    action.onError(error.response);
  }
}

function* getPayMethod(action: {
  type: string;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(ProductServices.getPayMethod);
    action.onSuccess(response.data.data);
  } catch (error: any) {
    action.onError(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* getGroupName(action: {
  type: string;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(ProductServices.getGroupName);
    action.onSuccess(response);
  } catch (error: any) {
    action.onError(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* createProduct(action: {
  type: string;
  data: any;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      ProductServices.createProduct,
      action.data
    );
    action.onSuccess(response);
    handleMessage(response);
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* deleteProduct(action: {
  type: string;
  data: any;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      ProductServices.deleteProduct,
      action.data
    );
    action.onSuccess(response);
  } catch (error: any) {
    action.onError(error.response);
  }
}

function* deleteGroupProduct(action: {
  type: string;
  data: any;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      ProductServices.deleteGroupProduct,
      action.data
    );
    action.onSuccess(response);
  } catch (error: any) {
    action.onError(error.response);
  }
}

function* copyGroupProduct(action: {
  type: string;
  data: any;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      ProductServices.copyGroupProduct,
      action.data
    );
    action.onSuccess(response);
  } catch (error: any) {
    action.onError(error.response);
  }
}

function* copyProduct(action: {
  type: string;
  data: any;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      ProductServices.copyProduct,
      action.data
    );
    action.onSuccess(response);
  } catch (error: any) {
    action.onError(error.response);
  }
}

function* renameGroupProduct(action: {
  type: string;
  data: any;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      ProductServices.renameGroupProduct,
      action.data
    );
    action.onSuccess(response);
  } catch (error: any) {
    action.onError(error.response);
  }
}

function* getDetailProductByUUID(action: {
  type: string;
  data: any;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      ProductServices.getDetailProductByUUID,
      action.data
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put({
        type: Types.GET_DETAIL_PRODUCT_SUCCESS,
        product_detail: response.data.data,
      });
      action.onSuccess(response);
    }
  } catch (error: any) {
    action.onError(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* updateProductByUUID(action: {
  type: string;
  data: any;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  yield put({ type: SHOW_LOADING });
  try {
    const response: APIResponseType = yield call(
      ProductServices.updateProductByUUID,
      action.data
    );
    action.onSuccess(response);
    handleMessage(response);
  } catch (error: any) {
    handleMessage(error.response);
  } finally {
    yield put({ type: HIDE_LOADING });
  }
}

function* getStripePricesProduct(action: {
  type: string;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      ProductServices.getStripePricesProduct
    );
    action.onSuccess(response);
  } catch (error: any) {
    action.onError(error.response);
  }
}

function* getListGroupProduct(action: {
  type: string;
  data: any;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  try {
    const response: APIResponseType = yield call(
      ProductServices.getListGroupProducts,
      action.data
    );
    action.onSuccess(response);
  } catch (error: any) {
    action.onError(error.response);
  }
}

export default function* productPageSaga() {
  yield takeLatest(Types.RENAME_PRODUCT_LIST, renameProductListItem);
  yield takeLatest(Types.GET_PAY_METHOR, getPayMethod);
  yield takeLatest(Types.GET_DETAIL_PRODUCT_BY_UUID, getDetailProductByUUID);
  yield takeLatest(Types.GET_STRIPE_PRICES_PRODUCT, getStripePricesProduct);
  yield takeLatest(Types.RENAME_GROUP_PRODUCT, renameGroupProduct);
  yield takeLatest(Types.CREATE_PRODUCT, createProduct);
  yield takeLatest(Types.UPDATE_PRODUCT_BY_UUID, updateProductByUUID);
  yield takeLatest(Types.DELETE_PRODUCT, deleteProduct);
  yield takeLatest(Types.DELETE_GROUP_PRODCUT, deleteGroupProduct);
  yield takeLatest(Types.COPY_GROUP_PRODCUT, copyGroupProduct);
  yield takeLatest(Types.COPY_PRODUCT, copyProduct);
  yield takeLatest(Types.GET_GROUP_NAME, getGroupName);
  yield takeLatest(Types.GET_LIST_GROUP_PRODUCT, getListGroupProduct);
}
