// Project: 7612770651107e1659371833c56a9b25e733c4615968bb7c5582cdc92aa6249b
// use library node_modules
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// use components common
import { useGroupFunnelsList } from '../../../../../../hooks';
import { TRate } from '../../../../../../components/atoms/TRate';
import { TSwitch } from '../../../../../../components/atoms/TSwitch';
import DropdownOverlay from './DropdownOverlay';
import FieldEditInLine from './FieldEditInline';

// use helper and config, services
import { fetchGroupFunnelList } from '../../../../../../store/actions/funnel/group';
import { IFunnel } from '../../../../../../store/reducers/funnel/groupFunnels';
import { convertCurrentTimeJapan } from '../../Utils/utils';
import {
  changeStatusFavorite,
  changeStatusPublicFunnel,
  StatusFavorite,
  StatusPublic,
} from '../../../../../../Clients/funnels';
import {
  EStatusHttp,
  IBaseParameter,
} from '../../../../../../Clients/interfaces';
import { errorResponse } from '../../../../../../Clients/helper';
import { TConfirmPopup } from '../../../../../../components/atoms/TConfirmPopup';

export interface ExtendsProps {
  groupId: string;
}

const TableGroupRow: React.FC<IFunnel & ExtendsProps> = ({
  name,
  total_steps,
  flag_favorite,
  flag_publish,
  updated_at,
  uuid,
  first_step,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [groupFunnels] = useGroupFunnelsList();
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [hasToggle, setHasToggle] = useState<boolean>(false);

  useEffect(() => {
    if (hasFocus) {
      setHasFocus(false);
    }
  }, [hasFocus]);

  const handleStartEventEdit = () => {
    setHasToggle(false);
    setHasFocus(!hasFocus);
  };

  const onChangeDropEvent = (flag: boolean) => {
    setHasToggle(flag);
  };

  const fetchGroupList = () => {
    dispatch(
      fetchGroupFunnelList({
        ...groupFunnels.payload,
      })
    );
  };

  const onChangeFavorite = async () => {
    const params: IBaseParameter<StatusFavorite> = {
      hasLoading: false,
      params: {
        uuid: uuid,
        favorite: flag_favorite === 1 ? 0 : 1,
      },
    };
    try {
      const response = await changeStatusFavorite(params);
      if (response.status === EStatusHttp.HTTP_OK) {
        dispatch(
          fetchGroupFunnelList({
            ...groupFunnels.payload,
          })
        );
      }
    } catch (error: any) {
      errorResponse(error.response, {
        notification: { error: false },
      });
    }
  };

  const onChangeSwitchPublic = async () => {
    const params: IBaseParameter<StatusPublic> = {
      hasLoading: false,
      params: {
        uuid: uuid,
        flag_publish: flag_publish === 1 ? 0 : 1,
      },
    };
    try {
      const response = await changeStatusPublicFunnel(params);
      if (response.status === EStatusHttp.HTTP_OK) {
        dispatch(
          fetchGroupFunnelList({
            ...groupFunnels.payload,
          })
        );
      }
    } catch (error: any) {
      errorResponse(error.response, {
        notification: { error: true },
      });
    }
  };

  const favorite = flag_favorite ? 1 : 0;
  const publish = flag_publish === 1;

  const handleOkeConfirm = async (_item: any, type: string) => {
    if (type === 'OKE') {
      await onChangeSwitchPublic();
    }
  };

  const onChangeHasQuestionPublic = async () => {
    if (publish) {
      TConfirmPopup(
        t('funnel.listing.common.questionPublic'),
        'OKE',
        {},
        handleOkeConfirm
      );
      return;
    }

    await onChangeSwitchPublic();
  };

  return (
    <tr>
      {/* ----------------------------------------------------------------------------------- */}
      <WrapperElement>
        <TRate count={1} value={favorite} onChange={onChangeFavorite} />
      </WrapperElement>

      {/* ----------------------------------------------------------------------------------- */}
      <WrapperElement className="over-text-dot dot-1">
        <FieldEditInLine
          evenOutside={hasFocus}
          data={{ name: name, id: uuid, childId: '' }}
          onChangeEvent={fetchGroupList}
        />
      </WrapperElement>

      {/* ----------------------------------------------------------------------------------- */}
      <WrapperElement>
        {total_steps}
        {t('funnel.listing.group.totalStep')}
      </WrapperElement>

      {/* ----------------------------------------------------------------------------------- */}
      <WrapperElement>{convertCurrentTimeJapan(updated_at)}</WrapperElement>

      {/* ----------------------------------------------------------------------------------- */}
      <WrapperElement className="switch">
        <TSwitch checked={publish} onChange={onChangeHasQuestionPublic} />
      </WrapperElement>

      {/* ----------------------------------------------------------------------------------- */}
      <WrapperElement>
        <DropdownOverlay
          hasVisible={hasToggle}
          onCannelEvent={onChangeDropEvent}
          handleFocusEdit={handleStartEventEdit}
          data={{
            name: name,
            uuid: uuid,
            first_step: first_step,
            flag_favorite: flag_favorite,
            flag_publish: flag_publish,
            updated_at: updated_at,
            total_steps: total_steps,
          }}
        />
      </WrapperElement>
    </tr>
  );
};

const WrapperElement = styled.td`
  &.switch {
    padding-left: 25px;
  }
`;

export default TableGroupRow;
