// Project: 7612770651107e1659371833c56a9b25e733c4615968bb7c5582cdc92aa6249b
import i18next from 'i18next';
import moment from 'moment';
import { convertToTimeJPNotSpace } from './common.utilities';

/**
 * @param {params : startDate, endDate} params is string date format of moment
 */
const getTimeTitleDownloadCSV = (startDate: string, endDate: string) => {
  if (!startDate && !endDate) {
    return i18next.t('affiliate.bonus.filename.params.allTime');
  }

  return `${convertToTimeJPNotSpace(
    moment(startDate)
  )}～${convertToTimeJPNotSpace(moment(endDate))}`;
};

export { getTimeTitleDownloadCSV };
