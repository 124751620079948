import React, { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Space, Tooltip } from 'antd';
import editIcon from '../../../../../../assets/img/menu-icons/edit-icon.png';
import { IChangeNameTemplates } from '../../../../../Clients/templates/interface';

export interface WrappedFieldNameProps {
  evenOutside: boolean;
  data: IStepDetail;
  handleOnBlurOutSide: (event: boolean) => void;
  updateNameTemplate: (value: IChangeNameTemplates) => void;
}

interface IStepDetail {
  id: string;
  name: string;
}

const WrappedFieldName: React.FC<WrappedFieldNameProps> = ({
  handleOnBlurOutSide,
  evenOutside,
  data,
  updateNameTemplate,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [eventInside, setEventInside] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(data.name);

  useEffect(() => {
    setTitle(data.name);
  }, [data.name]);

  const handleOnBlur = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setEventInside(false);
    handleOnBlurOutSide(false);
    if (isEmpty(title.trim())) {
      toast.error(t('funnel.list.message.funnelNameCannotBeLeftBlank'));
      setTitle(data.name);
    } else if (title.trim().length > 100) {
      setTitle(data.name);
      toast.error(t('common.text.invalidMax100'));
    } else {
      if (title.trim() !== data.name) {
        setTitle(title);
        updateNameTemplate({ uuid: data.id, name: title });
      } else {
        setTitle(data.name);
      }
    }
  };

  const handleOnchangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  useEffect(() => {
    if (eventInside) {
      inputRef.current?.focus();
    }
  }, [eventInside]);
  useEffect(() => {
    if (evenOutside) setEventInside(true);
  }, [evenOutside]);

  return (
    <>
      {eventInside && (
        <InputElement className="w-100">
          <input
            className="w-100"
            autoComplete="off"
            tabIndex={-1}
            type="text"
            value={title}
            ref={inputRef}
            onBlur={handleOnBlur}
            onChange={handleOnchangeTitle}
          />
        </InputElement>
      )}

      {!eventInside && (
        <>
          <WrapperElement>
            <Space align="start" direction="horizontal">
              <Tooltip title={title}>
                <div className="noselect over-text-dot dot-1">{title}</div>
              </Tooltip>
              <span className="icons" onClick={() => handleOnBlurOutSide(true)}>
                <img src={editIcon} alt="" />
              </span>
            </Space>
          </WrapperElement>
        </>
      )}
    </>
  );
};

const InputElement = styled.div`
  width: 100%;
  padding-right: 25px;
  input {
    padding: 3px 5px;
    border: 1px solid var(--t-border-color);
    border-radius: 6px;
  }
`;

const WrapperElement = styled.div`
  padding-left: 3px;
  width: 100%;
  .noselect {
    max-width: 150px;
    color: var(--t-main-color);
  }
  &:hover {
    .icons {
      transition: ease-in-out 0.5s;
    }
  }
  .icons {
    transition: ease-in-out 0.5s;
    margin-left: 0px !important;
    img {
      margin-bottom: 3px;
      margin-left: 0px !important;
      cursor: pointer;
    }
  }
`;

export default WrappedFieldName;
