import { IMultipleSelectedProps } from '../../../components/atoms/TMultipleSelected/TMultipleSelected';
import * as Types from '../../actions/funnel/types';

interface IStateSelectedGroup {
  data: IMultipleSelectedProps[];
}

const initialSelectedGroup: IStateSelectedGroup = {
  data: [],
};

export default function selectedGroupFunnelReducer(
  state: IStateSelectedGroup = initialSelectedGroup,
  action: any
): IStateSelectedGroup {
  switch (action.type) {
    case Types.FETCH_SELECT_GROUP_FUNNEL: {
      return { ...state };
    }
    case Types.FETCH_SELECT_GROUP_FUNNEL_SUCCESS: {
      return {
        ...state,
        data: action.data,
      };
    }
    case Types.FETCH_SELECT_GROUP_FUNNEL_FAILED: {
      return {
        ...state,
      };
    }
    default:
      return { ...state };
  }
}
