import TableCol from '../TableCol';
import { TableProps } from '../types';
import { defaultProps } from './config';
import { ResponsiveWrapper, WrapperTable } from './styled';

function DataTable(props: TableProps): JSX.Element {
  const {
    data = defaultProps.data,
    columns = defaultProps.columns,
    keyField = defaultProps.keyField,
    pageField = defaultProps.keyField,
    noDataString = defaultProps.noDataString,
    selectedColumn = '',
    onSortFunction,
    children,
  } = props;
  return (
    <ResponsiveWrapper key={keyField}>
      <WrapperTable>
        <thead className="thead">
          <tr>
            {columns.map((column, index) => (
              <TableCol
                key={index}
                {...column}
                pageId={keyField}
                sortPage={pageField}
                selectedColumn={selectedColumn}
                onSortFunction={onSortFunction}
              />
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </WrapperTable>
      {!data && <p className="text-center w-100 mt-3">{noDataString}</p>}
    </ResponsiveWrapper>
  );
}

export default DataTable;
