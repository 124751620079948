import momentTimeZone from 'moment-timezone';
import { CACHE_TYPES, DFormat } from '../../../../../constants/app.constants';

export function convertCurrentTimeJapan(date) {
  let timeZone =
    localStorage.getItem(CACHE_TYPES.CURRENT_TIMEZONE) || 'Asia/Tokyo';

  let seconds = Math.floor(
    (new Date(momentTimeZone().tz(timeZone).format(DFormat)) - new Date(date)) /
      1000
  );

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + '年前'; // last years
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + 'ヶ月前'; // last months
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + '日前'; // last day
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + '時間前'; // last hours
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + '分前'; // last minutes
  } else {
    return '1分未満前 ';
  }
}
