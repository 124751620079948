import * as Types from './../../actions/product/types';
import { initialProductState, ProductState } from './interface';

export default function productManagementReducer(
  state: ProductState = initialProductState,
  action: any
): ProductState {
  switch (action.type) {
    case Types.GET_DETAIL_PRODUCT_SUCCESS:
      return {
        ...state,
        product_detail: action.product_detail,
      };
    case Types.GET_DETAIL_PRODUCT_BY_UUID:
    default:
      return state;
  }
}
