import { IFunnelStepMetadataPayload } from '../../../Clients/funnels/interface';
import { IFunnelStepMetadata } from '../../reducers/funnel/funnelStepMetadata';
import { ISummaryFunnel } from '../../reducers/funnel/interfaces';
import * as types from './types';

const renameFunnelPage = (
  data: any,
  onSuccess: (data: any) => void,
  onError: (err: string) => void
) => ({
  type: types.RENAME_FUNNEL_GROUP,
  data,
  onSuccess,
  onError,
});

const renameFunnelListItem = (
  data: any,
  onSuccess: (data: any) => void,
  onError: (err: string) => void
) => ({
  type: types.RENAME_FUNNEL_LIST_ITEM,
  data,
  onSuccess,
  onError,
});

const fetchListFunnel = (
  data: any,
  onSuccess: (data: any) => void,
  onError: (err: string) => void
) => ({
  type: types.FETCH_LIST_FUNNEL,
  data,
  onSuccess,
  onError,
});

const fetchSummaryFunnel = () => ({
  type: types.FETCH_SUMMARY_FUNNEL,
});

const fetchAllTemplates = () => ({
  type: types.FETCH_ALL_TEMPLATES,
});

const fetchAllTemplatesSuccess = (payload: any) => ({
  type: types.FETCH_ALL_TEMPLATES_SUCCESS,
  payload: payload,
});

const fetchAllTemplatesFailed = (payload: any) => ({
  type: types.FETCH_ALL_TEMPLATES_FAILED,
  payload: payload,
});

const fetchSummaryFunnelSuccess = (payload: ISummaryFunnel) => ({
  type: types.FETCH_SUMMARY_FUNNEL_SUCCESS,
  payload: payload,
});

const fetchSummaryFunnelFails = (payload: string) => ({
  type: types.FETCH_SUMMARY_FUNNEL_FAILS,
  error: payload,
});

const fetchFunnelStepPageVersions = (payload: string) => ({
  type: types.FETCH_FUNNEL_STEP_PAGE_VERSIONS_REQUESTED,
  payload: payload,
});

const fetchFunnelStepPageVersionsSuccess = (payload: any) => ({
  type: types.FETCH_FUNNEL_STEP_PAGE_VERSIONS_SUCCESS,
  payload: payload,
});

const fetchFunnelStepPageVersionsFailed = (payload: any) => ({
  type: types.FETCH_FUNNEL_STEP_PAGE_VERSIONS_FAILED,
  payload: payload,
});

const applyFunnelStepPageVersions = (payload: { uuid: string }) => ({
  type: types.APPLY_FUNNEL_STEP_PAGE_VERSIONS_REQUESTED,
  payload: payload,
});

const applyFunnelStepPageVersionsSuccess = (payload: any) => ({
  type: types.APPLY_FUNNEL_STEP_PAGE_VERSIONS_SUCCESS,
  payload: payload,
});

const applyFunnelStepPageVersionsFailed = (payload: any) => ({
  type: types.APPLY_FUNNEL_STEP_PAGE_VERSIONS_FAILED,
  payload: payload,
});

const clearPageVersions = () => ({
  type: types.CLEAR_PAGE_VERSIONS,
});

const fetchFunnelStepMetadata = (payload: string) => ({
  type: types.FETCH_FUNNEL_STEP_METADATA_REQUESTED,
  payload: payload,
});

const fetchFunnelStepMetadataSuccess = (payload: IFunnelStepMetadata) => ({
  type: types.FETCH_FUNNEL_STEP_METADATA_SUCCESS,
  payload: payload,
});

const fetchFunnelStepMetadataFailed = (payload: string) => ({
  type: types.FETCH_FUNNEL_STEP_METADATA_FAILED,
  payload: payload,
});

const updateFunnelStepMetadata = (payload: IFunnelStepMetadataPayload) => ({
  type: types.UPDATE_FUNNEL_STEP_METADATA_REQUESTED,
  payload: payload,
});

const updateFunnelStepMetadataSuccess = (payload: IFunnelStepMetadata) => ({
  type: types.UPDATE_FUNNEL_STEP_METADATA_SUCCESS,
  payload: payload,
});

const updateFunnelStepMetadataFailed = (payload: IFunnelStepMetadata) => ({
  type: types.UPDATE_FUNNEL_STEP_METADATA_FAILED,
  payload: payload,
});

const fetchMembersiteMetadata = (payload: string) => ({
  type: types.FETCH_MEMBERSITE_METADATA_REQUESTED,
  payload: payload,
});

const fetchMembersiteMetadataSuccess = (payload: string) => ({
  type: types.FETCH_MEMBERSITE_METADATA_SUCCESS,
  payload: payload,
});

const fetchMembersiteMetadataFailed = (payload: string) => ({
  type: types.FETCH_MEMBERSITE_METADATA_FAILED,
  payload: payload,
});

const updateMembersiteMetadata = (payload: IFunnelStepMetadataPayload) => ({
  type: types.UPDATE_MEMBERSITE_METADATA_REQUESTED,
  payload: payload,
});

const updateMembersiteMetadataSuccess = (
  payload: IFunnelStepMetadataPayload
) => ({
  type: types.UPDATE_MEMBERSITE_METADATA_SUCCESS,
  payload: payload,
});

const updateMembersiteMetadataFailed = (
  payload: IFunnelStepMetadataPayload
) => ({
  type: types.UPDATE_MEMBERSITE_METADATA_FAILED,
  payload: payload,
});

export {
  renameFunnelPage,
  renameFunnelListItem,
  fetchListFunnel,
  fetchSummaryFunnel,
  fetchSummaryFunnelSuccess,
  fetchSummaryFunnelFails,
  fetchAllTemplates,
  fetchAllTemplatesSuccess,
  fetchAllTemplatesFailed,
  fetchFunnelStepPageVersions,
  fetchFunnelStepPageVersionsFailed,
  fetchFunnelStepPageVersionsSuccess,
  applyFunnelStepPageVersions,
  applyFunnelStepPageVersionsFailed,
  applyFunnelStepPageVersionsSuccess,
  clearPageVersions,
  fetchFunnelStepMetadata,
  fetchFunnelStepMetadataFailed,
  fetchFunnelStepMetadataSuccess,
  updateFunnelStepMetadata,
  updateFunnelStepMetadataFailed,
  updateFunnelStepMetadataSuccess,
  fetchMembersiteMetadata,
  fetchMembersiteMetadataFailed,
  fetchMembersiteMetadataSuccess,
  updateMembersiteMetadata,
  updateMembersiteMetadataFailed,
  updateMembersiteMetadataSuccess,
};
