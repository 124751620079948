import { OPTION_FILTER_GROUP } from '../../../../../constants/app.constants';
import { IFirstStep } from '../../../../../store/reducers/funnel/groupFunnels';

const getDataFilters = (
  data: string[],
  curData: string[],
  allData: string[]
): string[] => {
  if (data.includes(OPTION_FILTER_GROUP.ALL)) {
    if (data.length === curData.length + 1) {
      return [...allData];
    }
    if (data.length + 1 === allData.length) {
      return data.filter((x: string) => x !== OPTION_FILTER_GROUP.ALL);
    }
    return [];
  } else {
    if (data.length + 1 === allData.length) {
      if (curData.includes(OPTION_FILTER_GROUP.ALL)) {
        return [];
      }
      return [...allData];
    }
    return data;
  }
};

interface ResponseFunction {
  groupIds: string;
  favorite: string;
  hasAllow: boolean;
  stateIds: string[];
}

const getParameterFilter = (
  data: string[],
  preData: string[],
  allData: string[]
): ResponseFunction => {
  const output = getDataFilters(data, preData, allData);
  const hasAll = output.includes(OPTION_FILTER_GROUP.ALL);
  const hasFavorite = output.includes(OPTION_FILTER_GROUP.RATE);
  const groupIds = output
    .filter(
      (x) => x !== OPTION_FILTER_GROUP.ALL && x !== OPTION_FILTER_GROUP.RATE
    )
    .join(',');

  if (hasAll) {
    return {
      groupIds: '',
      favorite: '',
      hasAllow: true,
      stateIds: output,
    };
  } else {
    return {
      groupIds: groupIds,
      favorite: hasFavorite ? '1' : '',
      hasAllow: false,
      stateIds: output,
    };
  }
};

export const calcCurrentPage = (
  totalPage: number,
  pageSize: number,
  currentPage: number
) => {
  if (currentPage === (totalPage - 1) / pageSize + 1) return currentPage - 1;

  return currentPage;
};

export const calcNextPrevPage = (
  totalPage: number,
  pageSize: number,
  type: 'next' | 'prev',
  currentPage: number
) => {
  const calcPage = totalPage / pageSize;
  let prevORNextPage = Math.floor(calcPage);
  if (calcPage > prevORNextPage) {
    prevORNextPage += 1;
  }
  if (
    (currentPage === 1 && type === 'prev') ||
    (currentPage === prevORNextPage && type === 'next')
  ) {
    return currentPage;
  } else {
    return type === 'next' ? currentPage + 1 : currentPage - 1;
  }
};

const getURLShareFunnel = (first_step: IFirstStep) => {
  const domain = process.env.APP_FUNNEL_DOMAIN;
  const { sub_domain, funnel_domain, path } = first_step;

  if (funnel_domain) {
    return `https://${funnel_domain.replace('.www', '')}/${path}`;
  }

  return `https://${sub_domain}.${domain}/${path}`;
};

export interface TableOptions {
  currentPage: number;
  totalPage: number;
  pageSize: number;
}
export const calcDisableNextPrev = (
  params: TableOptions,
  type: 'next' | 'prev'
): boolean => {
  const { currentPage, pageSize, totalPage } = params;
  const calcPage = totalPage / pageSize;
  let page = Math.floor(calcPage);
  if (calcPage > page) {
    page += 1;
  }

  if (type === 'next' && page - currentPage > 0) {
    return false;
  }

  if (type === 'prev' && currentPage - 1 > 0) {
    return false;
  }

  return true;
};

export { getParameterFilter, getURLShareFunnel };
