import React from 'react';
import { Select } from 'antd';
const { Option } = Select;
import './style.scss';

export interface TMultipleSelectedProps {
  value?: any;
  onChange: (value: any) => void;
  className?: string;
  data: Array<ISelectedProps>;
  width?: number;
  placeholder?: any;
  disable?: boolean;
}

export interface ISelectedProps {
  value: any;
  hlTitle?: string;
  title?: string;
  disable?: boolean;
}
const SelectedOption: React.FC<TMultipleSelectedProps> = ({
  value,
  onChange,
  width,
  data,
  className,
  placeholder,
  disable = false,
}) => (
  <div className="t-single-selected">
    <Select
      className={className}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={{ width: width, color: 'var(--t-main-color)' }}
      disabled={disable}
    >
      {data.map((item, index) => {
        return (
          <Option
            key={index}
            value={item.value}
            style={{ color: 'var(--t-main-color)' }}
            disabled={item.disable}
          >
            <p className={`s-options-selected ${item.disable && 'disable'}`}>
              <span>{item.hlTitle}</span> {item.title}
            </p>
          </Option>
        );
      })}
    </Select>
  </div>
);

export default SelectedOption;
