import { IFileInfo } from 'react-csv-reader';
import { RouteComponentProps } from 'react-router';
import { SelectOption } from '../components/singleSelect';

export enum WORKFLOW_STEP {
  CONFIGURE = 1,
  MANAGE = 2,
}

export enum MESSAGE_UPLOAD {
  NOT_FOUND = 0,
  DEFAULT = 1,
  ILLEGAL_FORMAT = 2,
  MAX_BYTES_ERROR = 3,
  SUCCESS = 4,
}

export const UNIT_UPLOAD = {
  BYTES: 1048576,
  MAX_SIZE: 5,
};

export const UPLOAD_ID = '#current-field-upload';

export const parseOptions = {
  encoding: 'UTF-8',
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, ''),
};

export interface IDataMapField {
  fileName?: string;
}

export interface ConfigureUploadProps {
  handleFocusUpload: (data: Array<any>, fileInfo: IFileInfo) => void;
  messageUpload: MESSAGE_UPLOAD;
  fieldData: IDataMapField;
  optionsForm: SelectOption[];
  form: string;
  handleSelectForm: (value: string) => void;
  setOptions: any;
  options: IOptionUpload;
  handleDownLoadCSV: () => void;
  stepMails: IStepMails[];
  setStepMails: any;
  uploadLoading: boolean;
  hasNotEdit?: boolean;
  responseImport: IResponseImport;
  isFileError: boolean;
}

export interface IFail {
  message: string;
  row: number;
}

export enum STATUS_RESPONSE {
  IMPORTING = 'importing',
  DONE = 'done',
  DEFAULT = 'default',
}
export interface IResponseImport {
  row_fails: IFail[] | null;
  total_row: number;
  row_success: number;
  status: STATUS_RESPONSE;
  row_progress: number;
}

export const RESPONSE: IResponseImport = {
  row_fails: [],
  total_row: 0,
  row_success: 0,
  status: STATUS_RESPONSE.DEFAULT,
  row_progress: 0,
};
export interface IOptionUpload {
  register: number;
  overwrite: number;
}

export enum CHECK_TYPE {
  CHECK = 0,
  UN_CHECK = 1,
}

interface RouteParams {
  uuid: string;
}
export type UploadMailListProps = RouteComponentProps<RouteParams>;

export interface IStepMails {
  uuid: string;
  name: string;
  start_number: number;
  number_step_mail: number;
}

export interface IStatusImport {
  total_row: number;
  row_success: number;
  row_progress: number;
  start_progress: boolean;
}
