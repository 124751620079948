// Project: 7612770651107e1659371833c56a9b25e733c4615968bb7c5582cdc92aa6249b
import { call, put, takeLatest } from 'redux-saga/effects';
import * as Types from '../actions/funnel/types';
import { handleMessage } from '../../utilities/common.utilities';
import { EStatusHttp, IResponse } from '../../Clients/interfaces';
import {
  changeNameFunnel,
  funnelServices,
  getAllGroupFunnel,
  getPageListFunnel,
  getPageListGroupFunnels,
  getSummaryFunnel,
  renameGroupFunnel,
  getFunnelStepPageVersions,
  postFunnelStepPageVersions,
  getFunnelStepMetadata,
  updateFunnelStepMetadata,
} from '../../Clients/funnels';
import {
  fetchAllTemplatesSuccess,
  fetchSummaryFunnelSuccess,
  fetchFunnelStepPageVersionsSuccess,
  fetchFunnelStepPageVersionsFailed,
  applyFunnelStepPageVersionsSuccess,
  applyFunnelStepPageVersionsFailed,
  fetchFunnelStepMetadataSuccess,
  fetchFunnelStepMetadataFailed,
  updateFunnelStepMetadataSuccess,
  updateFunnelStepMetadataFailed,
} from '../actions/funnel/funnelList';
import { showMessageResponse } from '../../Clients/helper';
import { IMultipleSelectedProps } from '../../components/atoms/TMultipleSelected/TMultipleSelected';
import { DEFAULT_GROUPS } from '../../constants/app.constants';
import { HIDE_LOADING, SHOW_LOADING } from '../actionTypes/loadingActionTypes';
import {
  fetchPageListFunnelSuccess,
  IDataPageList,
  PayloadPageListFunnel,
} from '../actions/funnel/pageList';
import { initialPageListFunnel } from '../reducers/funnel/pageList';
import {
  fetchGroupFunnelListSuccess,
  fetchSelectedGroupFunnelSuccess,
  IPayLoadGroupFunnelList,
} from '../actions/funnel/group';
import { initialGroupFunnels } from '../reducers/funnel/groupFunnels';
interface APIResponseType {
  code: number;
  data: any;
  message: string;
  status: number;
}

function* renameFunnelGroup(action: {
  type: string;
  data: any;
  onSuccess: () => void;
  onError: () => void;
}) {
  try {
    const response: APIResponseType = yield call(
      renameGroupFunnel,
      action.data
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess();
      handleMessage(response);
    } else {
      action.onError();
      handleMessage(response);
    }
  } catch (error: any) {
    if (error.response) handleMessage(error.response);
    action.onError();
  }
}

function* renameFunnelListItem(action: {
  type: string;
  data: any;
  onSuccess: (data: any) => void;
  onError: () => void;
}) {
  try {
    const response: APIResponseType = yield call(changeNameFunnel, action.data);
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data);
    }
  } catch (error: any) {
    handleMessage(error.response);
    action.onError();
  }
}

function* fetchListFunnel(action: {
  type: string;
  data: any;
  onSuccess: (data: any) => void;
  onError: () => void;
}) {
  try {
    const response: APIResponseType = yield call(
      getPageListFunnel,
      action.data
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      action.onSuccess(response.data.data);
    } else {
      action.onError();
    }
  } catch (error) {
    action.onError();
  }
}

function* fetchSummaryFunnel() {
  try {
    const response: IResponse<any> = yield call(getSummaryFunnel);
    if (response && response.status === EStatusHttp.HTTP_OK) {
      yield put(fetchSummaryFunnelSuccess(response.data?.data));
    } else {
      showMessageResponse(response);
    }
  } catch (error: any) {
    showMessageResponse(error.response);
  }
}

function* fetchAllTemplates() {
  try {
    const response: IResponse<any> = yield call(funnelServices.getAllTemplates);
    if (response && response.status === EStatusHttp.HTTP_OK) {
      yield put(fetchAllTemplatesSuccess(response.data?.data));
    } else {
      showMessageResponse(response);
    }
  } catch (error: any) {
    showMessageResponse(error.response);
  }
}

function* fetchSelectedGroupFunnel() {
  try {
    const response: IResponse<any> = yield call(getAllGroupFunnel);
    if (response && response.status === EStatusHttp.HTTP_OK) {
      const data: IMultipleSelectedProps[] = DEFAULT_GROUPS.concat(
        response.data?.data.map((el: any) => {
          return {
            value: el.uuid,
            title: el.name,
            label: el.name,
          };
        })
      );
      yield put(fetchSelectedGroupFunnelSuccess(data));
    }
  } catch (error: any) {
    showMessageResponse(error.response);
    yield put({ type: HIDE_LOADING });
  }
}

function* fetchGroupFunnelList(action: {
  type: string;
  payload: IPayLoadGroupFunnelList;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const response: IResponse<any> = yield call(() =>
      getPageListGroupFunnels(action.payload)
    );
    yield put({ type: HIDE_LOADING });
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put(
        fetchGroupFunnelListSuccess({
          ...initialGroupFunnels,
          payload: action.payload,
          groups: response.data?.data.groups,
          meta: response.data?.data.meta,
        })
      );
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    yield put(
      fetchGroupFunnelListSuccess({
        ...initialGroupFunnels,
        groups: [],
      })
    );
    throw error;
  }
}

function* fetchPageListFunnel(action: {
  type: string;
  payload: PayloadPageListFunnel;
}) {
  try {
    yield put({ type: SHOW_LOADING });
    const response: IResponse<any> = yield call(() =>
      getPageListFunnel(action.payload)
    );
    yield put({ type: HIDE_LOADING });
    if (response.status === EStatusHttp.HTTP_OK) {
      const funnels: IDataPageList[] = response.data?.data.data.map(
        (el: any) => {
          return {
            group_name: el.group_name,
            uuid: el.uuid,
            first_step: el.first_step,
            flag_favorite: el.flag_favorite,
            flag_publish: el.flag_publish,
            name: el.name,
            total_steps: el.total,
            updated_at: el.last_updated_time,
          };
        }
      );
      yield put(
        fetchPageListFunnelSuccess({
          ...initialPageListFunnel,
          payload: action.payload,
          meta: response.data?.data.meta,
          data: funnels,
        })
      );
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    yield put(
      fetchPageListFunnelSuccess({
        ...initialPageListFunnel,
        data: [],
      })
    );
    throw error;
  }
}

function* fetchFunnelStepPageVersionsSaga({ payload }: any) {
  try {
    const response: APIResponseType = yield call(
      getFunnelStepPageVersions,
      payload
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put(fetchFunnelStepPageVersionsSuccess(response.data.data));
    } else {
      yield put(fetchFunnelStepPageVersionsFailed(payload));
    }
  } catch (error: any) {
    handleMessage(error.message);
    yield put(fetchFunnelStepPageVersionsFailed(payload));
  }
}

function* updateFunnelStepPageVersionsSaga({ payload }: any) {
  try {
    const response: APIResponseType = yield call(
      postFunnelStepPageVersions,
      payload
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put(applyFunnelStepPageVersionsSuccess(response.data.data));
      handleMessage(response);
    } else {
      yield put(applyFunnelStepPageVersionsFailed(payload));
    }
  } catch (error: any) {
    handleMessage(error.response);
    yield put(applyFunnelStepPageVersionsFailed(payload));
  }
}

function* fetchFunnelStepMetadataSaga({ payload }: any) {
  try {
    const response: APIResponseType = yield call(
      getFunnelStepMetadata,
      payload
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put(fetchFunnelStepMetadataSuccess(response.data.data));
    } else {
      yield put(fetchFunnelStepMetadataFailed(payload));
    }
  } catch (error: any) {
    handleMessage(error.message);
    yield put(fetchFunnelStepMetadataFailed(payload));
  }
}

function* updateFunnelStepMetadataSaga({ payload }: any) {
  try {
    const response: APIResponseType = yield call(
      updateFunnelStepMetadata,
      payload
    );
    if (response.status === EStatusHttp.HTTP_OK) {
      yield put(updateFunnelStepMetadataSuccess(payload));
      handleMessage(response);
    } else {
      yield put(updateFunnelStepMetadataFailed(payload));
    }
  } catch (error: any) {
    handleMessage(error.response);
    yield put(updateFunnelStepMetadataFailed(payload));
  }
}

export default function* funnelPageSaga() {
  yield takeLatest(Types.RENAME_FUNNEL_GROUP, renameFunnelGroup);
  yield takeLatest(Types.RENAME_FUNNEL_LIST_ITEM, renameFunnelListItem);
  yield takeLatest(Types.FETCH_LIST_FUNNEL, fetchListFunnel);
  yield takeLatest(Types.FETCH_SUMMARY_FUNNEL, fetchSummaryFunnel);
  yield takeLatest(Types.FETCH_SELECT_GROUP_FUNNEL, fetchSelectedGroupFunnel);
  yield takeLatest(Types.FETCH_ALL_TEMPLATES, fetchAllTemplates);
  yield takeLatest(Types.FETCH_THE_GROUP_FUNNEL_LIST, fetchGroupFunnelList);
  yield takeLatest(Types.FETCH_PAGE_LIST_FUNNEL, fetchPageListFunnel);
  yield takeLatest(
    Types.FETCH_FUNNEL_STEP_PAGE_VERSIONS_REQUESTED,
    fetchFunnelStepPageVersionsSaga
  );
  yield takeLatest(
    Types.APPLY_FUNNEL_STEP_PAGE_VERSIONS_REQUESTED,
    updateFunnelStepPageVersionsSaga
  );
  yield takeLatest(
    Types.FETCH_FUNNEL_STEP_METADATA_REQUESTED,
    fetchFunnelStepMetadataSaga
  );
  yield takeLatest(
    Types.UPDATE_FUNNEL_STEP_METADATA_REQUESTED,
    updateFunnelStepMetadataSaga
  );
}
