export const AUTHEN_MKT_KEY = 'mkt-authen';
export const LOCAL_TOKEN_KEY = 'mkt-token';
export const SYSTEM_USER_IDENTIFY =
  process.env.SYSTEM_USER_IDENTIFY_COOKIE_KEY ?? 'system_user_identify';
import Cookies from 'js-cookie';

export const getCookiesByName = (key: string) => {
  return JSON.parse(Cookies.get(key) || '{}');
};

export const removeCookiesByName = (key: string) => {
  Cookies.remove(key, { path: '', domain: `.${process.env.APP_SUB_DOMAIN}` });
};
export const removeCookiesByNameLocal = (key: string) => {
  Cookies.remove(key);
};
export const getAuthenticatorCookies = () => {
  return JSON.parse(Cookies.get(AUTHEN_MKT_KEY) || '{}');
};

export const setAuthenticatorCookies = (value: any) => {
  setSystemUserIdentify(value);
  const dayRememberme = new Date(value.expires_in);
  Cookies.set(AUTHEN_MKT_KEY, JSON.stringify(value), {
    domain: `.${process.env.APP_SUB_DOMAIN}`,
    expires: dayRememberme,
  });
};

export const setAuthenticatorCookiesLocal = (value: any) => {
  setSystemUserIdentify(value);
  const dayRememberme = new Date(value.expires_in);
  Cookies.set(AUTHEN_MKT_KEY, JSON.stringify(value), {
    expires: dayRememberme,
  });
};

export const setSystemUserIdentify = (value: any) => {
  if (value?.system_user_identify) {
    setCookie(
      SYSTEM_USER_IDENTIFY,
      value?.system_user_identify,
      value.expires_in,
      process.env.APP_FUNNEL_DOMAIN
    );
  }
};

export const setCookie = (
  key: string,
  value: any,
  expires_in: any,
  domain?: any
) => {
  Cookies.set(key, JSON.stringify(value), {
    expires: new Date(expires_in),
    domain: domain ?? null,
  });
};

export const setLocalAuthenToken = (value: any) => {
  if (value?.system_user_identify) {
    setCookie(
      SYSTEM_USER_IDENTIFY,
      value?.system_user_identify,
      value.expires_in,
      process.env.APP_FUNNEL_DOMAIN
    );
  }
};
